<template>
  <div class="page">
    <img src="./img/fywh.png" class="banner" />
    <div class="icon-list">
      <div class="item" @click="toPath('digitalExhibitionHall')">
        <img src="./img/icon1.png" alt="" />
        <div class="title">数字化展厅</div>
      </div>
      <div class="item" @click="toPath('intangibleCulturalHeritageHeadlines')">
        <img src="./img/icon2.png" alt="" />
        <div class="title">弥勒文化宣传</div>
      </div>
      <div class="item" @click="toPath('visitNotice')">
        <img src="./img/icon3.png" alt="" />
        <div class="title">参观须知</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="overViewBox">
      <div class="title-box">
        <div class="left"></div>
        <div class="right">弥勒文化</div>
      </div>
      <!-- <v-empty description="暂无数据"></v-empty> -->
      <div class="detail-content">
        <v-h5Mtml :content="textContent || '暂无'"></v-h5Mtml>
      </div>
      <!-- <img src="./img/banner1.png" alt="" />
      <img src="./img/banner2.png" alt="" />
      <img src="./img/banner3.png" alt="" />
      <img src="./img/banner4.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
import { gloabalCount } from "@/utils/common";
import { content } from "./map.js";
export default {
  name: "intangibleCulturalHeritage",
  data() {
    return {
      textContent: content,
    };
  },
  mounted() {
    gloabalCount("", 61, 1);
  },
  methods: {
    toPath(name) {
      if (name == "") {
        this.$toast("敬请期待！");
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  .overViewBox {
    padding: 46px 30px;
    box-sizing: border-box;
    img {
      width: 100%;
      margin-bottom: 18px;
    }
    .title-box {
      display: flex;
      align-items: center;
      margin-bottom: 34px;
      .right {
        font-size: 36px;
        font-weight: 600;
        color: #1a1c34;
      }
      .left {
        width: 10px;
        height: 36px;
        background: #3dad74;
        border-radius: 8px;
        margin-right: 8px;
      }
    }
  }
  .line {
    width: 100%;
    height: 22px;
    background-color: #f7f9f8;
  }
  .icon-list {
    width: 100%;
    height: 278px;
    padding: 32px 80px 0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .item {
      img {
        width: 152px;
      }
      .title {
        font-size: 24px;
        color: #1a1c34;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
  .banner {
    width: 100%;
    height: 320px;
  }
}
</style>

<template>
  <div class="section">
    <div class="section-form">
      <div class="section-item select">
        <div class="section-item--label">主体类型：</div>
        <div
          class="section-item--control"
          @click="clickPicker('principalType')"
        >
          <v-input
            :value="principalTypeMap[form.principalType]"
            readonly
            inputAlign="right"
            placeholder="请选择"
          ></v-input>
          <img src="../img/arrow_right_gray.png" alt="" />
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">商户名称：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.title"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item upload">
        <div class="section-item--label">营业执照照片：</div>
        <div class="section-item--control">
          <div class="upload">
            <v-upload
              :imgUrl.sync="form.businessLicense"
              :activityPicture.sync="form.businessLicense1"
              ref="upload"
              :maxCount="9"
            ></v-upload>
            <p>注：最多可上传9张图片</p>
          </div>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">注册号/统一社会信用代码：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.taxpayerRegistrationNumber"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">个体户经营者/法人姓名：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.legalPersonName"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">经营者/法人身份证件：</div>
        <div class="section-item--control">
          <v-input
            :maxlength="18"
            v-model.trim="form.idNumber"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item card">
        <div class="section-item--label">经营者/法人身份证件正反面：</div>
        <div class="section-item--control">
          <div class="card">
            <v-upload
              class="front"
              :imgUrl.sync="form.idCardPhotoFront"
              :activityPicture.sync="form.idCardPhotoFront1"
              ref="idCard_front"
              :maxCount="1"
            ></v-upload>
            <v-upload
              class="back"
              :imgUrl.sync="form.idCardPhotoBack"
              :activityPicture.sync="form.idCardPhotoBack1"
              ref="idCard_back"
              :maxCount="1"
            ></v-upload>
          </div>
          <div class="tip">
            <img src="../img/warning_icon.png" alt="" />
            <p>拍摄时确保证件<span>边框完整，字体清晰，亮度均匀。</span></p>
          </div>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">商户简称：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.titleAbbreviation"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">联系电话：</div>
        <div class="section-item--control">
          <v-input
            :maxlength="11"
            v-model.trim="form.mobileNum"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">所属行业：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.industryType"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>
      <div class="section-item select">
        <div class="section-item--label">账户类型：</div>
        <div class="section-item--control" @click="clickPicker('accountType')">
          <v-input
            :value="accountTypeMap[form.accountType]"
            readonly
            inputAlign="right"
            placeholder="请选择"
          ></v-input>
          <img src="../img/arrow_right_gray.png" alt="" />
        </div>
      </div>
      <div class="section-item">
        <div class="section-item--label">开户名称：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.accountName"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>
      <div class="section-item">
        <div class="section-item--label">开户银行：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.accountBank"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>
      <div class="section-item">
        <div class="section-item--label">开户银行省市编码：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.accountBankCityCode"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>
      <div class="section-item">
        <div class="section-item--label">开户银行全称（含支行）：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.accountBankName"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>
      <div class="section-item">
        <div class="section-item--label">银行账号：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.bankCard"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item select">
        <div class="section-item--label">街道：</div>
        <div class="section-item--control" @click="clickRouter">
          <v-input
            :value="form.street"
            readonly
            inputAlign="right"
            placeholder="请选择街道"
          ></v-input>
          <img src="../img/arrow_right_gray.png" alt="" />
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">详细地址：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.address"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">营业时间：</div>
        <div class="section-item--control">
          <v-input
            v-model.trim="form.openingHours"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item upload">
        <div class="section-item--label">商户封面图：</div>
        <div class="section-item--control">
          <div class="upload">
            <v-upload
              :imgUrl.sync="form.imageUrl"
              :activityPicture.sync="form.imageUrl1"
              ref="upload"
              :maxCount="1"
            ></v-upload>
            <!-- <p>注：最多可上传9张图片</p> -->
          </div>
        </div>
      </div>

      <div class="section-item textarea">
        <div class="section-item--label">商户活动：</div>
        <div class="section-item--control">
          <v-input
            type="textarea"
            v-model.trim="form.mallActivity"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="surebtn" @click="submitBefore">
        <div class="btn">提交</div>
      </div>
    </div>

    <v-picker
      :columns="columnsOps"
      :valueShow="pickerShow"
      valueKey="text"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>

    <!-- <v-dateTimePicker
      :value="dateTime"
      :valueShow.sync="dateTimeShow"
      :isAuto="true"
      type="datetime"
      title="选择完整时间"
      @confirmPicker="confirmDate"
    ></v-dateTimePicker> -->
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
// import moment from "moment";
import { applyURL, checkStatusURL, getMyMallShopURL } from "./api";
import {
  principalTypeMap,
  setPrincipalTypeOps,
  accountTypeMap,
  setAccountTypeOps,
} from "./map";

export default {
  data() {
    return {
      userInfo: {},
      principalTypeMap,
      principalTypeOps: setPrincipalTypeOps(),
      accountTypeMap,
      accountTypeOps: setAccountTypeOps(),
      form: {
        id: "",
        legalPersonName: "", // 法人姓名
        principalType: undefined, // 主体类型 1:个体户 2:企业
        title: "", // 商户名称
        businessLicense: "", // 营业执照
        businessLicense1: "", // 营业执照 适配upload组件
        taxpayerRegistrationNumber: "", // 纳税人识别号
        mobileNum: "", // 联系方式
        idNumber: "", // 身份证号
        idCardPhotoFront: "", // 身份证正面照片
        idCardPhotoFront1: "", // 身份证正面照片 适配upload组件
        idCardPhotoBack: "", // 身份证反面照片
        idCardPhotoBack1: "", // 身份证反面照片 适配upload组件
        titleAbbreviation: "", // 商户简称
        industryType: "", // 所属行业
        accountType: undefined, // 账户类型 1:对公银行账户 2:经营者个人银行卡
        accountName: "", // 开户名称
        accountBank: "", // 开户银行
        accountBankCityCode: "", // 开户银行市编码
        accountBankName: "", // 开户银行全称(含支行)
        bankCard: "", // 银行卡
        street: "", // 街道
        longitude: "", // 经度
        latitude: "", // 纬度
        address: "", // 详细地址
        openingHours: "", // 经营时间
        imageUrl: "", // 商户封面图
        imageUrl1: "", // 商户封面图 适配upload组件
        mallActivity: "",
      },
      /* v-picker */
      type: "", // 字段名 用于picker判断值该赋给哪个字段
      columnsOps: [],
      pickerShow: false,
      // /* v-dateTimePicker */
      // dateTime: new Date(),
      // dateTimeShow: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  mounted() {
    this.initData();
  },
  methods: {
    routerPushState() {
      // console.log(document.URL);
      // const _this = this;
      window.history.pushState(null, null, document.URL);
      // window.addEventListener(
      //   "popstate",
      //   () => {
      //     // this.$router.replace({
      //     //   name: "convenientService",
      //     // });
      //     console.log("触发了");
      //     // _this.$router.go(-1);
      //   },
      //   false
      // );
    },
    async initData() {
      const form_bcnServiceApply = localStorage.getItem("form_bcnServiceApply");
      if (form_bcnServiceApply) {
        this.initMap(form_bcnServiceApply);
        return;
      }
      this.getDetail();
    },
    getDetail() {
      this.$axios
        .get(getMyMallShopURL, {
          params: {
            userId: this.userId,
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.update(res.data);
            return;
          }
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        });
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
      this.form.businessLicense1 = this.form.businessLicense;
      this.form.idCardPhotoFront1 = this.form.idCardPhotoFront;
      this.form.idCardPhotoBack1 = this.form.idCardPhotoBack;
      this.form.imageUrl1 = this.form.imageUrl;
    },
    initMap(form_bcnServiceApply) {
      const form_bcnServiceAppl_decode =
        decodeURIComponent(form_bcnServiceApply);
      const { title, longitude, latitude, detailAddress } = this.$route.query;
      if (form_bcnServiceAppl_decode) {
        const data = JSON.parse(form_bcnServiceAppl_decode);
        Object.keys(this.form).forEach((key) => {
          this.form[key] = data[key];
        });
        if (title) {
          this.routerPushState();
          // 从地图页面跳转
          this.form.street = title;
          this.form.longitude = longitude; // 经度
          this.form.latitude = latitude; // 纬度
          this.form.address = detailAddress; // 详细地址
        }
        localStorage.removeItem("form_bcnServiceApply");
      }
    },
    submitBefore() {
      if (!this.valid()) {
        return;
      }
      this.submit();
    },
    submit() {
      this.$axios
        .post(applyURL, {
          ...this.form,
          businessLicense: this.form.businessLicense1,
          idCardPhotoFront: this.form.idCardPhotoFront1,
          idCardPhotoBack: this.form.idCardPhotoBack1,
          imageUrl: this.form.imageUrl1,
          houseId: this.houseId || this.communityId || undefined,
          tenantId: this.tenantId,
          optUserId: this.userId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$router.replace({
              name: "bcnServiceBusinessAudio",
            });
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
    // 选择街道
    clickRouter() {
      /** 适配上传组件 */
      this.form.businessLicense = this.form.businessLicense1;
      this.form.idCardPhotoFront = this.form.idCardPhotoFront1;
      this.form.idCardPhotoBack = this.form.idCardPhotoBack1;
      this.form.imageUrl = this.form.imageUrl1;
      localStorage.setItem(
        "form_bcnServiceApply",
        encodeURIComponent(JSON.stringify(this.form))
      );
      const path = "/futureService/bcnServiceApply";
      // wx.miniProgram.redirectTo({
      //   url: `/xubPages/chooseAddress/index?path=${path}`,
      // });
      if (this.$route.query.title) {
        wx.miniProgram.redirectTo({
          url: `/xubPages/chooseAddress/index?path=${path}`,
        });
        return;
      }
      wx.miniProgram.navigateTo({
        url: `/xubPages/chooseAddress/index?path=${path}`,
      });
    },
    clickPicker(type) {
      this.type = type;
      if (type == "principalType") {
        this.columnsOps = this.principalTypeOps;
      } else if (type == "accountType") {
        this.columnsOps = this.accountTypeOps;
      }
      this.pickerShow = true;
    },
    /* v-picker */
    clickPickOverlay() {
      this.pickerShow = false;
    },
    cancelPick() {
      this.pickerShow = false;
    },
    confirmPick(val) {
      this.form[this.type] = val.value;
      this.pickerShow = false;
    },
    // /* v-dateTimePicker */
    // confirmDate(val) {
    //   this.form[this.type] = moment(val).format("YYYY-MM-DD HH:mm:ss");
    // },

    // 验证必填项
    valid() {
      const {
        legalPersonName, // 法人姓名
        principalType, // 主体类型 1:个体户 2:企业
        title, // 商户名称
        // businessLicense, // 营业执照
        businessLicense1, // 营业执照 适配upload组件
        taxpayerRegistrationNumber, // 纳税人识别号
        mobileNum, // 联系方式
        idNumber, // 身份证号
        // idCardPhotoFront, // 身份证正面照片
        idCardPhotoFront1, // 身份证正面照片 适配upload组件
        // idCardPhotoBack, // 身份证反面照片
        idCardPhotoBack1, // 身份证反面照片 适配upload组件
        titleAbbreviation, // 商户简称
        industryType, // 所属行业
        accountType, // 账户类型 1:对公银行账户 2:经营者个人银行卡
        accountName, // 开户名称
        accountBank, // 开户银行
        accountBankCityCode, // 开户银行市编码
        accountBankName, // 开户银行全称(含支行)
        bankCard, // 银行卡
        street,
        address,
        openingHours,
        imageUrl1,
        // mallActivity
      } = this.form;
      let message = undefined;
      if (principalType === undefined || principalType === "") {
        message = "主体类型不可为空";
      } else if (title === "") {
        message = "商户名称不可为空";
      } else if (businessLicense1 === "") {
        message = "营业执照不可为空";
      } else if (taxpayerRegistrationNumber === "") {
        message = "社会信用代码不可为空";
      } else if (idNumber === "") {
        message = "身份证号不可为空";
      } else if (mobileNum === "") {
        message = "联系方式不可为空";
      } else if (legalPersonName === "") {
        message = "法人姓名不可为空";
      } else if (idCardPhotoFront1 === "") {
        message = "身份证正面照片不可为空";
      } else if (idCardPhotoBack1 === "") {
        message = "身份证反面照片不可为空";
      } else if (titleAbbreviation === "") {
        message = "商户简称不可为空";
      } else if (industryType === "") {
        message = "所属行业不可为空";
      } else if (accountType === undefined || accountType === "") {
        message = "账户类型不可为空";
      } else if (accountName === "") {
        message = "开户名称不可为空";
      } else if (accountBank === "") {
        message = "开户银行不可为空";
      } else if (accountBankCityCode === "") {
        message = "开户银行市编码不可为空";
      } else if (accountBankName === "") {
        message = "开户银行全称不可为空";
      } else if (bankCard === "") {
        message = "银行卡不可为空";
      } else if (street === "") {
        message = "街道不可为空";
      } else if (openingHours === "") {
        message = "经营时间不可为空";
      } else if (imageUrl1 == "") {
        message = "商户封面图片不可为空";
      }
      if (message) {
        this.$toast({
          message,
          duration: 1000,
        });
      }
      return !message;
    },
  },
};
</script>

<style lang="less" scoped>
.section {
  font-family: PingFangSC-Regular, PingFang SC;
  box-sizing: border-box;
  background: #f6f8fb;
  min-height: 100%;
  width: 100%;
  padding: 30px;
  padding-bottom: calc(
    32px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    32px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-form {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding: 32px 32px 50px 32px;
    background: #ffffff;
    .section-item {
      display: flex;
      border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      .section-item--label {
        flex: 1;
        line-height: 88px;
        color: #333333;
        font-size: 30px;
        text-align: left;
        font-weight: 400;
        white-space: nowrap;
      }
      .section-item--control {
        max-width: 400px;
      }
      &.radio {
        .section-item--control {
          display: flex;
          align-items: center;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      &.textarea {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          ::v-deep .van-cell {
            border-radius: 16px;
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
      &.upload {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          // ::v-deep .van-cell {
          //   background: rgba(0, 0, 0, 0.05);
          // }
          ::v-deep .van-uploader {
            padding-bottom: 28px;
            .van-uploader__preview {
              .van-uploader__preview-image {
                vertical-align: middle;
                width: 160px;
                height: 160px;
                img {
                  vertical-align: middle;
                  overflow: hidden;
                  margin: 0;
                  padding: 0;
                }
              }
              .van-uploader__preview-delete {
                width: 28px;
                height: 28px;
                border-radius: 0 0 0 24px;
                i {
                  position: absolute;
                  vertical-align: middle;
                  font-size: 32px;
                  top: 45%;
                  left: 55%;
                  transform: translateX(-50%) translateY(-50%) scale(0.5);
                }
              }
            }
            .van-uploader__upload {
              width: 160px;
              height: 160px;
            }
          }
          p {
            font-size: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.25);
            line-height: 34px;
            margin-bottom: 28px;
          }
        }
      }
      &.card {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          .card {
            padding-bottom: 28px;
            display: flex;
            ::v-deep .v-upload {
              flex: 1;
              height: 218px;
              &:not(:last-child) {
                margin-right: 30px;
              }
              &.front {
                .van-uploader__upload {
                  background: url(../img/idCard_front.png) no-repeat center
                    center;
                  background-size: 100% 100%;
                }
              }
              &.back {
                .van-uploader__upload {
                  background: url(../img/idCard_back.png) no-repeat center
                    center;
                  background-size: 100% 100%;
                }
              }
              .van-uploader {
                width: 100%;
                height: 100%;
                .van-uploader__wrapper {
                  width: 100%;
                  height: 100%;
                  .van-uploader__preview {
                    width: 100%;
                    height: 100%;
                    .van-uploader__preview-image {
                      width: 100%;
                      height: 100%;
                      img {
                        width: 100%;
                        height: 100%;
                        vertical-align: middle;
                        overflow: hidden;
                        margin: 0;
                        padding: 0;
                      }
                    }
                    .van-uploader__preview-delete {
                      width: 56px;
                      height: 56px;
                      border-radius: 0 0 0 56px;
                      i {
                        position: absolute;
                        vertical-align: middle;
                        font-size: 48px;
                        top: 45%;
                        left: 55%;
                        transform: translateX(-50%) translateY(-50%) scale(0.5);
                      }
                    }
                  }
                  .van-uploader__upload {
                    width: 100%;
                    height: 100%;
                    .van-uploader__upload-icon {
                      display: none;
                    }
                  }
                }
              }
            }
          }
          .tip {
            margin-bottom: 28px;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.25);
            > img {
              margin-right: 14px;
              width: 28px;
              height: 28px;
            }
            span {
              color: #db431d;
            }
          }
        }
      }
      &.select {
        .section-item--control {
          width: 400px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 28px;
          line-height: 40px;
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
          // color: rgba(0, 0, 0, 0.25);
          margin-left: 20px;
          ::v-deep .van-cell {
            padding-right: 0px;
          }
          img {
            margin-left: 8px;
            margin-right: 32px;
            width: 8px;
            height: 24px;
            vertical-align: middle;
          }
        }
      }
      &.date {
        border-bottom: 2px solid rgba(0, 0, 0, 0.04);
        .section-item--control {
          width: 400px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 28px;
          line-height: 40px;
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
          color: rgba(0, 0, 0, 0.25);
          margin-left: 20px;
          img {
            margin-left: 8px;
            width: 28px;
            height: 16px;
            vertical-align: middle;
          }
        }
      }
    }
    .surebtn {
      margin-top: 78px;
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 492px;
        height: 74px;
        background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
        border-radius: 39px;
        border: 8px solid #ecf7ff;
        font-weight: bold;
        font-size: 30px;
        color: #ffffff;
        letter-spacing: 4px;
      }
    }
  }
}
</style>

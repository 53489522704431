const activityStatus = [
  {
    key: 1,
    value: "报名未开始",
  },
  {
    key: 2,
    value: "报名进行中",
  },
  {
    key: 3,
    value: "活动未开始",
  },
  {
    key: 4,
    value: "活动进行中",
  },
  {
    key: 5,
    value: "活动结束",
  },
  {
    key: 6,
    value: "活动取消",
  },
];
export { activityStatus };

import { mapHelper } from "@/utils/utils.js";

// 	商圈类型
const businessTypeops = [
  {
    value: 1,
    text: "商铺",
  },
  {
    value: 2,
    text: "服务者",
  },
];

const { map: businessTypeopsMap, setOps: businessTypeopsOps } =
  mapHelper.setMap(businessTypeops);
// 	经营类型

const manageTypeops = [
  {
    value: '',
    text: "全部",
  },
  {
    value: 1,
    text: "运动健身",
  },
  {
    value: 2,
    text: "美食",
  },
  {
    value: 3,
    text: "生活服务",
  },
  {
    value: 4,
    text: "文化",
  },
  {
    value: 5,
    text: "学习培训",
  },
  {
    value: 6,
    text: "零售",
  },
  {
    value: 7,
    text: "美容美发",
  },
  {
    value: 8,
    text: "医药",
  },
  {
    value: 0,
    text: "其他",
  },
];

const { map: manageTypeopsMap, setOps: manageTypeopsOps } =
  mapHelper.setMap(manageTypeops);

export { businessTypeopsMap, businessTypeops, manageTypeopsMap, manageTypeops };

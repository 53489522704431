<template>
  <div class="page">
    <div class="contentBox">
      <div class="title">查看进度</div>
      <!-- 提议 -->
      <div class="item">
        <div class="top">
          <div class="point"><div class="inP"></div></div>
          <div class="name">提议：{{ info.proposeUserName }}</div>
          <div class="time">{{ info.proposeTime }}</div>
        </div>
        <div class="center">
          <div class="box">
            <div class="content">内容：{{ info.proposeContent }}</div>
          </div>
        </div>
      </div>
      <!-- 提议审核 -->
      <div class="item">
        <div class="top">
          <div class="point"><div class="inP"></div></div>
          <div class="name">提议审核：{{ info.shenheUserName }}</div>
          <div class="time">{{ info.shenheTime }}</div>
        </div>
        <div class="center">
          <div class="box">
            <div class="status" style="color: #80c59b">
              通过
              <img src="./img/tongguo.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 审议 -->
      <div class="item">
        <div class="top">
          <div class="point"><div class="inP"></div></div>
          <div class="name">审议：{{ info.shenyiUserName }}</div>
          <div class="time">{{ info.shenyiTime }}</div>
        </div>
        <div class="center">
          <div class="box">
            <div class="status" style="color: #80c59b">
              通过
              <img src="./img/tongguo.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 商议 -->
      <div class="item">
        <div class="top">
          <div class="point"><div class="inP"></div></div>
          <div class="name">商议：{{ info.shangyiUserName }}</div>
          <div class="time">{{ info.shangyiTime }}</div>
        </div>
        <div class="center">
          <div class="box">
            <div class="status" style="color: #80c59b">
              通过
              <img src="./img/tongguo.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 决议 -->
      <div class="item">
        <div class="top">
          <div class="now-point" v-if="info.status == 7">
            <div class="inP"></div>
          </div>
          <div class="point" v-else><div class="inP"></div></div>

          <div class="name">决议：{{ info.jueyiUserName }}</div>
          <div class="time">{{ info.jueyiTime }}</div>
        </div>
        <div class="center">
          <div class="box">
            <div class="status" style="color: #80c59b" v-if="info.status == 9">
              通过
              <img src="./img/tongguo.png" alt="" />
            </div>
            <div class="status" style="color: #e02020" v-if="info.status == 10">
              不通过
              <img src="./img/butongguo.png" alt="" />
            </div>
            <div class="status" style="color: #f7b500" v-if="info.status == 7">
              进行中
              <img src="./img/jinxingzhong.png" alt="" />
            </div>
            <div class="content">内容：{{ info.voteContent }}</div>
          </div>
        </div>
      </div>
      <!-- 公示 -->
      <div class="item" v-if="info.status == 9 || info.status == 10">
        <div class="top">
          <div class="now-point">
            <div class="inP"></div>
          </div>
          <div class="name">公示：{{ info.gongshiUserName }}</div>
          <div class="time">{{ info.gongshiTime }}</div>
        </div>
        <div class="center" v-if="info.gongshiContent">
          <div class="box" style="border: none">
            <div class="content">内容：{{ info.gongshiContent }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEventProcessUrl } from "./api.js";
import { mapState } from "vuex";
export default {
  name: "viewProgress",
  data() {
    return {
      info: {},
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id);
    }
  },
  methods: {
    getDetail(id) {
      let params = {
        id: id,
      };
      this.$axios
        .get(`${getEventProcessUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.info = res.data;
          }
        });
    },
  },
};
</script>
<style scoped lang='less'>
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
  padding: 52px 30px;
  .contentBox {
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(220, 220, 220, 0.5);
    border-radius: 16px;
    padding: 30px 30px 50px;
    box-sizing: border-box;
    margin-bottom: 22px;
    .item {
      padding: 0 0 0 22px;
      box-sizing: border-box;
      .center {
        padding-left: 12px;
        margin-top: 6px;
        .box {
          min-height: 112px;
          border-left: 2px solid #fe9514;
          padding: 8px 0 8px 22px;
          box-sizing: border-box;
          .content {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            word-break: break-all;
          }
          .status {
            display: flex;
            align-items: center;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-bottom: 14px;
            img {
              width: 28px;
              margin-left: 8px;
            }
          }
        }
      }
      .top {
        display: flex;
        align-items: center;
        .time {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
        .name {
          padding-left: 16px;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          flex: 1;
          color: #333333;
        }
        .point {
          width: 24px;
          height: 24px;
          position: relative;
          .inP {
            width: 16px;
            height: 16px;
            background: #fe9716;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .now-point {
          width: 24px;
          height: 24px;
          border: 3px solid #fe9716;
          border-radius: 50%;
          box-sizing: border-box;
          position: relative;
          .inP {
            width: 16px;
            height: 16px;
            background: #fe9716;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    .title {
      padding-bottom: 40px;
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
</style>
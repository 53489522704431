<template>
  <div class="smartParking">
    <div class="section-search">
      <div class="search-flex">
        <span>是否开启安全围栏</span>
        <van-switch v-model="checked" />
      </div>
      <div class="search-flex">
        <span>安全围栏生效时间段</span>
        <div class="flex-date" @click="isShowPopupStart = true">
          <span>{{
            `${searchParam.timeBegin} ${searchParam.timeBegin ? "~" : ""} ${
              searchParam.timeEnd
            }`
          }}</span>
          <img src="../../img/time.png" alt="" />
        </div>
      </div>
      <div class="search-tip">
        <span>提醒：开启安全围栏后，在地图上选择四个点形成围栏</span>
      </div>
    </div>

    <template>
      <v-dateTimePicker
        type="time"
        title="请选择开始时间"
        :value="searchParam.timeBegin"
        :valueShow.sync="isShowPopupStart"
        :isAuto="true"
        @confirmPicker="confirmStart"
      />

      <v-dateTimePicker
        type="time"
        title="请选择结束时间"
        :value="searchParam.timeEnd"
        :valueShow.sync="isShowPopupEnd"
        :isAuto="true"
        @confirmPicker="confirmEnd"
      />
    </template>

    <div class="map" :id="id"></div>
    <div class="section-footer">
      <div class="footer-cancel" @click="fenceReset">围栏重制</div>
      <div @click="fenceSave" class="footer-confirm">保存</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
import {
  getSecurityZoneURL,
  sendLocationCommandURL,
  settingSecurityZoneURL,
} from "../../api";

export default {
  data() {
    return {
      id: `container_${new Date().getTime()}_${Math.random * 1000}`,
      map: null, // 地图
      mouseTool: null, // 工具
      markers: [],
      polygon: [],
      // position: ["121.62454", "29.860258"], // 定位点默认为宁波市
      position: undefined,
      searchParam: {
        imei: undefined,
        deviceId: "",
        timeBegin: "",
        timeEnd: "",
        lon: "", // 经度
        lat: "", // 纬度
      },
      checked: false,
      popupTitle: "请选择开始时间",
      isShowPopupStart: false,
      isShowPopupEnd: false,
    };
  },
  filters: {
    dateFormat: function (value) {
      if (value) {
        return moment(value).toDate();
      }
      return new Date();
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    this.searchParam.lon = this.$route.query.lon;
    this.searchParam.lat = this.$route.query.lat;
    this.searchParam.deviceId = this.$route.query.deviceId;
    this.searchParam.imei = this.$route.query.imei;
    await Promise.all([this.sendLocationCommand(), this.getSecurityZone()]);
    this.$nextTick(() => {
      this.initMap();
    });
  },
  beforeDestory() {
    if (this.mouseTool) {
      this.mouseTool.close(true);
    }
    if (this.map) {
      this.map.destroy();
    }
  },
  methods: {
    initMap() {
      const _this = this;
      if (_this.map) {
        _this.map.destroy();
      }
      if (window.AMap) {
        _this.map = new window.AMap.Map(_this.id, {
          zoom: "16",
          center: this.position, // center 不传 默认定位所在城市
        });
        console.log(this.markers, "markers---");
        if (this.markers.length == 4) {
          this.addPolygon(this.markers);
        } else {
          this.fenceReset();
        }
      }
    },
    addPolygon(path) {
      path = _.cloneDeep(path);
      if (window.AMap && path.length > 0) {
        const _this = this;
        const polygon = new window.AMap.Polygon({
          map: _this.map,
          path,
          fillOpacity: 0.2,
          fillColor: "#00b0ff",
          strokeColor: "#80d8ff",
          strokeOpacity: 1, //线透明度
          strokeWeight: 2, //线宽
        });
        _this.polygon = [polygon];
        const getBounds = polygon.getBounds();
        const center = getBounds.getCenter();
        console.log(this.map.setBounds(getBounds));
        return polygon;
      }
    },
    // 围栏重置
    fenceReset() {
      const _this = this;
      if (this.map) {
        this.markers = [];
        this.polygon = [];
        this.map.clearMap();
      }
      if (this.mouseTool) {
        _this.mouseTool.close();
      }
      _this.mouseTool = new window.AMap.MouseTool(_this.map);
      _this.mouseTool.on("draw", function (e) {
        const position = e.obj.getPosition().toString().split(",");
        _this.markers.push(position);
        if (_this.markers.length == 4) {
          _this.mouseTool.close();
          _this.addPolygon(_this.markers);
          return;
        }
      });
      const icon = new window.AMap.Icon({
        size: new window.AMap.Size(22, 28),
        image: require("../../img/place_green.png"),
        imageSize: new window.AMap.Size(22, 28),
      });
      _this.mouseTool.marker({
        icon,
        // position: marker.position,
        offset: new window.AMap.Pixel(-10, -20),
      });
    },
    // 围栏保存
    fenceSave() {
      if (this.checked && this.markers.length != 4) {
        this.$toast({ message: "请先设置围栏！", duration: 1500 });
        return;
      }
      if (this.checked && !this.searchParam.timeBegin) {
        this.$toast({
          message: "开启安全围栏后，生效时间段开始时间不可为空！",
          duration: 2500,
        });
        return;
      }
      if (this.checked && !this.searchParam.timeEnd) {
        this.$toast({
          message: "开启安全围栏后，生效时间段结束时间不可为空！",
          duration: 2500,
        });
        return;
      }
      if (!this.timeVaild()) {
        return;
      }
      this.settingSecurityZone();
    },
    timeVaild() {
      let result = true;
      let time = moment(new Date()).format("YYYY/MM/DD");
      let timeBegin = `${time} ${this.searchParam.timeBegin}`;
      let timeEnd = `${time}  ${this.searchParam.timeEnd}`;
      console.log(
        moment(timeEnd).isBefore(timeBegin),
        moment(timeBegin).add(20, "m").isBefore(timeEnd)
      );
      if (moment(timeEnd).isBefore(timeBegin)) {
        result = false;
        this.$toast({
          message: "结束时间不能小于开始时间",
          duration: 2500,
        });
      } else if (!moment(timeBegin).add(19, "m").isBefore(timeEnd)) {
        result = false;
        this.$toast({
          message: "开始时间与结束时间最小间隔为20分钟",
          duration: 2500,
        });
      }
      return result;
    },

    // 开始时间
    confirmStart(value) {
      this.searchParam.timeBegin = value;
      this.isShowPopupEnd = true;
    },
    confirmEnd(value) {
      this.searchParam.timeEnd = value;
    },
    // 获取安全围栏
    async getSecurityZone() {
      let res = await this.$axios.get(getSecurityZoneURL, {
        params: {
          userId: this.userId,
          deviceId: this.searchParam.deviceId,
        },
      });
      if (res.code == 200 && res.success && res.data && res.data.id) {
        // this.checked = res.data.trackEnable == 1;
        const { enableSts, timeBegin, timeEnd, safeArea } = res.data;
        this.checked = enableSts == 1; // 0 未启用 1 已启用
        this.searchParam.timeBegin = timeBegin;
        this.searchParam.timeEnd = timeEnd;
        let markers = safeArea.split(";").map((v) => v.split(","));
        this.markers = markers.slice(0, 4);
        console.log(this.markers);
      }
      // this.$toast({ message: res.msg || "操作成功", duration: 1500 });
    },

    // 设置安全围栏
    async settingSecurityZone() {
      console.log([...this.markers, this.markers[0]]);
      let res = await this.$axios.post(settingSecurityZoneURL, {
        userId: this.userId,
        tenantId: this.tenantId,
        deviceId: this.searchParam.deviceId,
        enableSts: this.checked ? 1 : 0,
        freq: 1,
        timeBegin: this.searchParam.timeBegin,
        timeEnd: this.searchParam.timeEnd,
        safeArea: [...this.markers, this.markers[0]]
          .map((v) => v.join(","))
          .join(";"),
      });
      if (res.code == 200 && res.success) {
        console.log(res.msg, "res.msg---");
        this.$toast({ message: res.msg || "操作成功！", duration: 1500 });
      }
    },

    // 发送定位指令
    async sendLocationCommand() {
      let res = await this.$axios.post(
        sendLocationCommandURL,
        {
          deviceId: this.searchParam.deviceId,
          userId: this.userId,
        },
        {
          headers: {
            noLoading: true,
          },
        }
      );
      if (res.code == 200 && res.success) {
        if (res.data.lon && res.data.lat) {
          this.position = [res.data.lon, res.data.lat];
        }
      }
    },

    /** 算法 */
    // 计算四边形四个点的顺序
    // sortVertex(points) {
    //   /*
    //     格式：[[1,2], [4,1], [5,-1], [10, 3]]
    //     主要思想就是先按照横坐标排个序，
    //     然后确定最左边的顶点为起点，
    //     分别跟其他顶点画一条直线，
    //     然后判断剩下的两个点是否在这条直线的两侧，
    //     如果在两侧，那么排好序的四个坐标点就是：
    //     最左边的点，直线上侧的点，连线的点，跟直线下侧的点
    //    */
    //   let path = points;
    //   path.sort((a, b) => a[0] - b[0]);
    //   for (let j = 1; j < 4; j++) {
    //     let a = (path[0][1] - path[j][1]) / (path[0][0] - path[j][0]);
    //     let b = path[0][1] - a * path[0][0];
    //     let p0, oppo, p1;
    //     if (j == 1) {
    //       oppo = path[1];
    //       p0 = path[2];
    //       p1 = path[3];
    //     } else if (j == 2) {
    //       p0 = path[1];
    //       oppo = path[2];
    //       p1 = path[3];
    //     } else if (j == 3) {
    //       p0 = path[1];
    //       p1 = path[2];
    //       oppo = path[3];
    //     }
    //     let c0 = a * p0[0] + b - p0[1];
    //     let c1 = a * p1[0] + b - p1[1];
    //     if (c0 * c1 < 0) {
    //       if (c0 > 0) {
    //         path = [path[0], p0, oppo, p1];
    //       } else {
    //         path = [path[0], p1, oppo, p0];
    //       }
    //       break;
    //     }
    //   }
    //   return path;
    // },

    // async getTrackSwitch() {
    //   let res = await this.$axios.get(getTrackSwitchURL, {
    //     params: {
    //       imei: this.searchParam.imei,
    //     },
    //   });
    //   if (res.code == 200 && res.success) {
    //     this.checked = res.data.trackEnable == 1;
    //   }
    //   // this.$toast({ message: res.msg || "操作成功", duration: 1500 });
    // },

    // async getTrajectoryData() {
    //   let res = await this.$axios.get(getTrackListURL, {
    //     params: {
    //       deviceId: this.searchParam.deviceId,
    //       startTime: this.searchParam.startTime,
    //       userId: this.userId,
    //     },
    //   });
    //   if (res.code == 200 && res.data) {
    //     this.pointList[0].position = res.data
    //       .filter((v) => v.isTrack == 1)
    //       .map((v) => [v.lon, v.lat]);
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.smartParking {
  width: 100%;
  height: 100%;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-search {
    .search-flex {
      height: 92px;
      display: flex;
      align-items: center;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      padding: 0 30px;
      > span {
        flex: 1;
      }
      ::v-deep .van-switch {
        box-sizing: border-box;
        width: 96px;
        height: 44px;
        border: 6px solid #c7d8ff;
        border-radius: 22px;
        display: flex;
        align-items: center;
        .van-switch__node {
          bottom: 0;
          margin: auto 0;
          width: 24px;
          height: 24px;
        }
        &.van-switch--on .van-switch__node {
          transform: translateX(60px);
        }
      }
      .flex-date {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 62px;
        width: 320px;
        padding: 0 28px 0 18px;
        border: 2px solid rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        > span {
          flex: 1;
          color: rgba(0, 0, 0, 0.5);
          font-size: 24px;
        }
        img {
          width: 26px;
          height: 26px;
        }
      }
    }
    .search-tip {
      display: flex;
      align-items: center;
      height: 92px;
      padding: 0 30px;
      font-size: 24px;
      color: #3a75ff;
      span {
        line-height: 40px;
      }
    }
  }
  .map {
    height: calc(100% - 406px);
    width: 100%;
  }
  .section-footer {
    display: flex;
    justify-content: space-between;
    padding: 28px 60px;

    .footer-cancel,
    .footer-confirm {
      box-sizing: border-box;
      width: 290px;
      height: 74px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-weight: bold;
      color: #bddabd;
      border: 2px solid #bddabd;
      border-radius: 10px;
    }
    .footer-confirm {
      margin-left: 40px;
      background: #bddabd;
      color: #ffffff;
    }
  }
}
</style>

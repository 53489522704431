<template>
  <div class="page">
    <div class="banner" @click="toPath('applySouthStreamSister')">
      <img src="./img/index-banner.png" alt="" />
    </div>
    <div class="optBox">
      <div
        class="opt-item"
        @click="toPath(t.routerName)"
        v-for="(t, i) in optList"
        :key="i"
      >
        <img :src="t.url" alt="" />
        <div class="name">{{ t.name }}</div>
      </div>
    </div>
    <div class="main-title">
      <span>参加活动</span>
    </div>
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          v-for="(t, i) in listData"
          @click="toDetail(t)"
          :key="i"
        >
          <div class="item-top">
            <img :src="t.activityPicture" alt="" />
          </div>
          <div class="item-bottom">
            <div class="title">{{ t.activityName }}</div>
            <div class="arrdes">{{ t.activityAddress }}</div>
            <div class="place">地点：{{ t.activityAddress }}</div>
            <div class="endtime">报名结束时间：{{ t.applyEndDate }}</div>
            <div class="status blue" v-if="t.activityStatus == 2">
              报名进行中
            </div>
            <div class="status blue" v-else-if="t.activityStatus == 4">
              活动进行中
            </div>
            <div class="status yellow" v-else-if="t.activityStatus == 1">
              报名未开始
            </div>
            <div class="status yellow" v-else-if="t.activityStatus == 3">
              活动未开始
            </div>
            <div class="status grey" v-else-if="t.activityStatus == 5">
              活动结束
            </div>
            <div class="status grey" v-else-if="t.activityStatus == 6">
              活动取消
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getActivityListUrl, nanxiSisterRegistrationReviewUrl } from "./api.js";
import { getOption } from "@/utils/utils.js";
import { activityStatus } from "./map.js";
import { gloabalCount, toRegister } from "@/utils/common";
import { handleImg } from "@/utils/utils.js";
export default {
  data() {
    return {
      finishedText: "没有更多了",
      activityStatus,
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      optList: [
        {
          url: require("./img/wyfb.png"),
          name: "我要发布",
          routerName: "southStreamSisterPublishActivities",
        },
        {
          url: require("./img/pm.png"),
          name: "排名",
          routerName: "southStreamSisterRanking",
        },
      ],
      sisterStatus: null,
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async mounted() {
    gloabalCount("", 97, 1);
    if (await toRegister(this.$route.path, this.$route.query, "南溪阿姐")) {
      this.getSisterStatus();
    }
  },
  methods: {
    getSisterStatus() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(`${nanxiSisterRegistrationReviewUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.sisterStatus = res.msg;
          }
        });
    },
    async toDetail(item) {
      let params = {
        activityId: item.activityId,
      };
      if (this.sisterStatus == "0") {
        this.$toast({
          message: `该活动仅限阿姐可参加，请前往申请阿姐~`,
          duration: 2000,
        });
        return;
      } else {
        wx.miniProgram.navigateTo({
          url: `/xubPages/southStreamSisterActivityDetail/index?params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    async toPath(name) {
      if (name == "southStreamSisterRanking") {
        this.$router.push({
          name: name,
        });
      } else {
        if (await toRegister(this.$route.path, this.$route.query, "南溪阿姐")) {
          this.$router.push({
            name: name,
          });
        }
      }
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
        activityCategory: 104,
      };
      this.$axios
        .get(`${getActivityListUrl}`, { params: params })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.activityPicture) {
                item.activityPicture = handleImg(
                  686,
                  218,
                  item.activityPicture
                );
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    onLoad() {
      this.getActivityList();
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  padding-bottom: 90px;
  box-sizing: border-box;
  .list {
    margin-top: 32px;
    box-sizing: border-box;
    padding: 0 30px;
    .item {
      width: 100%;
      height: 468px;
      background: #ffffff;
      box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      margin-bottom: 20px;
      overflow: hidden;
      .item-bottom {
        height: calc(100% - 218px);
        padding: 10px 28px 0;
        box-sizing: border-box;
        position: relative;
        .status {
          position: absolute;
          width: 221px;
          height: 69px;
          background-color: pink;
          box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
          border-radius: 16px 0px 16px 0px;
          bottom: 0;
          right: 0;
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          text-align: center;
          line-height: 69px;
        }
        .blue {
          background: linear-gradient(180deg, #90cff3 0%, #57a8d6 100%);
        }
        .yellow {
          background: linear-gradient(180deg, #fdc76f 0%, #faae3e 100%);
        }
        .grey {
          color: rgba(0, 0, 0, 0.25);
          background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
        }
        .place,
        .endtime {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 4px;
        }
        .arrdes {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          padding-bottom: 36px;
        }
        .title {
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          padding-bottom: 2px;
        }
      }
      .item-top {
        width: 100%;
        height: 218px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .main-title {
    padding: 0 30px;
    box-sizing: border-box;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1c34;
    span {
      position: relative;
    }
    span::before {
      z-index: -1;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 180px;
      height: 14px;
      background: #e2e2e2;
      border-radius: 7px;
      content: "";
    }
  }
  .optBox {
    width: 100%;
    height: 188px;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    .opt-item {
      width: 334px;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(211, 215, 221, 0.5);
      border-radius: 16px;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1a1c34;
      display: flex;
      align-items: center;
      padding: 0 0 0 22px;
      box-sizing: border-box;
      img {
        width: 120px;
        height: 120px;
        margin-right: 20px;
      }
    }
  }
  .banner {
    width: 100%;
    height: 316px;
    margin-bottom: 32px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<template>
  <div class="page">
    <div class="tab">
      <div
        :class="index == tabAct ? 'tab-item-act' : 'tab-item'"
        @click="tabCheck(item, index)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item.name }}
        <div class="line" v-if="index == tabAct"></div>
      </div>
    </div>
    <div class="listBox">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toPath('proceedingsDetail', item)"
        >
          <img
            class="tag"
            src="./img/adopt.png"
            v-if="item.status == 9"
            alt=""
          />
          <img
            class="tag"
            src="./img/fail.png"
            v-else-if="item.status == 10"
            alt=""
          />
          <img class="tag" src="./img/resolution.png" v-else alt="" />
          <div class="top">
            <div class="userImg">
              <img
                :src="item.proposeUserAvatar || require('./img/user.png')"
                alt=""
              />
            </div>
            <div class="name">{{ item.proposeUserName }}</div>
            <div class="likeOrNot">
              <img
                @click.stop="likeOrNot(item, 0)"
                v-if="item.myPickType == 1"
                src="./img/like.png"
                alt=""
              />
              <img
                @click.stop="likeOrNot(item, 1)"
                v-else
                src="./img/no-like.png"
                alt=""
              />
            </div>
          </div>
          <div class="center">
            会议时间：{{ item.jueyiStartTime }}至{{ item.jueyiEndTime }}
          </div>
          <div class="bottom">
            <div class="name">{{ item.proposeTitle }}</div>
            <div class="infoBox">
              <span>会议地点：</span>{{ item.voteAddr }}
            </div>
            <div class="infoBox">
              <span>会议主题：</span>{{ item.voteTitle }}
            </div>
            <div class="infoBox">
              <span>表决主体：</span>{{ format(item.voteSubject) }}
            </div>
            <div class="infoBox">
              <span>会议内容：</span>{{ item.voteContent }}
            </div>
            <div class="btnList">
              <div class="green">同意：{{ item.agreeNumber }}人</div>
              <div class="red">不同意：{{ item.disagreeNumber }}人</div>
              <div class="grey">弃权：{{ item.abstainNumber }}人</div>
            </div>
            <div class="endTime">
              <img src="./img/message.png" alt="" />
              公示结束时间：{{ item.gongshiEndTime }}
            </div>
            <div class="checkLine" @click.stop="toPath('viewProgress', item)">
              <img src="./img/arrow3.png" alt="" />
              查看进度
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <img src="./img/add.png" class="add" @click="toPath('initiateProposal')" />
    <div class="myProposal" @click="toPath('myProposal')">我的提议</div>
  </div>
</template>

<script>
import { getVoteHallEventListUrl, pickEventUrl } from "./api.js";
import { mapState } from "vuex";
export default {
  name: "assemblyHall",
  data() {
    return {
      tabList: [
        {
          name: "全部",
          type: [7, 9, 10],
        },
        {
          name: "决议",
          type: [7],
        },
        {
          name: "公示",
          type: [9, 10],
        },
        {
          name: "关注",
          type: [7, 9, 10],
        },
      ],
      tabAct: 0,
      tabType: [7, 9, 10],
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    likeOrNot(item, type) {
      let params = {
        eventId: item.id,
        pickType: type,
        userId: this.userId,
      };
      this.$axios
        .post(`${pickEventUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.$toast(res.msg);
            this.listData = [];
            this.finished = false;
            this.curPage = 1;
            this.pageSize = 20;
            this.onLoad();
          }
        });
    },
    format(str) {
      let list = str.split(",");
      list.forEach((item, index) => {
        if (item == "1") {
          list[index] = "党员";
        } else if (item == "2") {
          list[index] = "干部";
        } else if (item == "3") {
          list[index] = "村民代表";
        } else if (item == "4") {
          list[index] = "全体居民";
        }
      });
      return list.join(",");
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getVoteHallEventListUrl, {
          params: {
            statusList: this.tabType,
            userId: this.userId,
            tenantId: this.tenantId,
            curPage: this.curPage,
            pageSize: this.pageSize,
            myPick: this.tabAct == 3 ? 1 : null,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.listData.forEach((ele) => {
                if (ele.proposeAnnexFiles) {
                  // ele.topicPicture = this.$handleImg(226, 150, ele.topicPicture);
                  ele.proposeAnnexFiles = ele.proposeAnnexFiles
                    .split(",")
                    .slice(0, 3);
                }
              });
              this.curPage++;
            }
          } else {
            this.finished = true;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    tabCheck(item, index) {
      this.tabAct = index;
      this.tabType = item.type;
      this.listData = [];
      this.finished = false;
      this.curPage = 1;
      this.pageSize = 20;
      this.onLoad();
    },
    toPath(name, item) {
      if (item) {
        this.$router.push({
          name: name,
          query: {
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
  .myProposal {
    text-align: center;
    line-height: 66px;
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
    border-radius: 10px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
  }
  .add {
    position: fixed;
    width: 184px;
    right: -15px;
    top: 650px;
  }
  .listBox {
    padding: 0 30px 120px;
    box-sizing: border-box;
    margin-top: 32px;
    .item {
      min-height: 608px;
      padding: 28px 32px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(220, 220, 220, 0.5);
      border-radius: 16px;
      position: relative;
      margin-bottom: 22px;
      .tag {
        position: absolute;
        right: 26px;
        top: 194px;
        width: 202px;
        height: 160px;
      }
      .bottom {
        .checkLine {
          margin-top: 16px;
          width: 176px;
          height: 50px;
          background: linear-gradient(180deg, #71a7ff 0%, #3781ff 100%);
          border-radius: 10px;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          justify-content: center;
          img {
            width: 28px;
            height: 18px;
            margin-right: 6px;
          }
        }
        .endTime {
          margin-top: 18px;
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e02020;
          img {
            margin-right: 10px;
            width: 18px;
            height: 20px;
          }
        }
        .btnList {
          display: flex;
          height: 50px;
          justify-content: space-between;
          margin-top: 20px;
          .grey {
            line-height: 50px;
            text-align: center;
            width: 176px;
            height: 50px;
            border-radius: 10px;
            border: 2px solid rgba(0, 0, 0, 0.5);
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            box-sizing: border-box;
          }
          .red {
            line-height: 50px;
            text-align: center;
            width: 176px;
            height: 50px;
            border-radius: 10px;
            border: 2px solid #e02020;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #e02020;
            box-sizing: border-box;
          }
          .green {
            line-height: 50px;
            text-align: center;
            width: 176px;
            height: 50px;
            border-radius: 10px;
            border: 2px solid #6dd400;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #6dd400;
            box-sizing: border-box;
          }
        }
        .infoBox {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 500;
          color: #000000;
          margin-bottom: 15px;
          span {
            color: rgba(0, 0, 0, 0.5);
            font-weight: 400;
          }
        }
        .name {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          padding-bottom: 10px;
          box-sizing: border-box;
        }
      }
      .center {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        padding: 10px 0 20px 0;
        box-sizing: border-box;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 22px;
      }
      .top {
        display: flex;
        align-items: center;
        .likeOrNot {
          width: 40px;
          height: 40px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          flex: 1;
          padding: 0 18px;
          box-sizing: border-box;
        }
        .userImg {
          width: 60px;
          height: 60px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .tab {
    width: 100%;
    height: 88px;
    display: flex;
    background: #fff;
    .tab-item-act {
      position: relative;
      flex: 1;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fe9514;
      text-align: center;
      line-height: 88px;
      .line {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 140px;
        height: 6px;
        background: #fe9514;
        border-radius: 3px;
      }
    }
    .tab-item {
      position: relative;
      flex: 1;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      line-height: 88px;
      .line {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 140px;
        height: 6px;
        background: #fe9514;
        border-radius: 3px;
      }
    }
  }
}
</style>

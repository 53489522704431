<template>
  <div class="page">
    <div class="title">参观须知</div>
    <div class="tag">
      <div class="left"></div>
      <div class="center">长汀未来社区</div>
      <div class="right"></div>
    </div>
    <!-- <p>
      1.参观对象为社会全体公众，其中身高1.2米以下的未成年人必须由成年人陪同。
    </p>
    <p>2.参观者须看管好随身携带物品，较重物品可免费保存。</p>
    <p>3.请勿在展馆内喧哗、打闹，保持良好的参观环境。</p>
    <p>4.馆内禁止吸烟，禁止参观者携带宠物、易燃易爆和管制械具等危险品。</p>
    <p>5.请保持馆内清洁，不随地吐痰，不乱扔果皮纸屑等废弃物。</p>
    <p>6.请勿触摸展品。</p>
    <p>7.参观者如遇突发事件，请服从馆内人员的安排，保持馆内秩序。</p>
    <p>8.请爱护展品、展览设施及其它公共服务设施，如有损坏照价赔偿。</p>
    <p>9.如遇重大活动和特殊接待工作，敬请参观者服从馆内的临时安排。</p> -->
   <v-empty description="暂无通知"></v-empty>
  </div>
</template>

<script>
export default {
  name: "visitNotice",
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #ffffff;
  padding: 36px 30px;
  p {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    padding-bottom: 20px;
  }
  .tag {
    display: flex;
    align-items: center;
    margin-bottom: 34px;
    .left,
    .right {
      flex: 1;
      width: 196px;
      height: 2px;
      background-color: #ecf0ee;
    }
    .center {
      width: 300px;
      height: 44px;
      background: rgba(193, 203, 199, 0.15);
      border-radius: 4px;
      text-align: center;
      line-height: 44px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(4, 40, 26, 0.45);
    }
  }
  .title {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #477f7d;
    text-align: center;
    padding-bottom: 22px;
  }
}
</style>

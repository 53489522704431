<template>
  <div class="partyBuilding">
    <div class="section-detail" @click="toRouteStage"></div>
    <div class="nav">
      <div class="nav-item" v-for="(item, index) in navList" :key="index">
        <div class="item-flex" @click="toRoute(item)">
          <img :src="require(`${item.imgUrl}`)" alt="" />
          <span class="nav-item--title">{{ item.title }}</span>
        </div>
      </div>
    </div>
    <!-- <div class="redRoomHouseKeeper" @click="toRoute('1')">
      <img src="./img/redRoomHouseKeeper.png" alt="" />
    </div>
    <div class="myPartyWay" @click="toRoute('2')">
      <img src="./img/myPartyWay.png" alt="" />
    </div> -->
    <div class="pioneerMission" @click="toRouteTitle('pioneerMission')">
      <img src="./img/pioneerMission.png" alt="" />
    </div>
    <div class="section">
      <div class="section-title">红色引领</div>
      <div class="section-more" @click="toRouteList">
        <span>更多</span><img src="./img/arrowRight.png" alt="" />
      </div>
    </div>

    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div
        class="list-content"
        v-for="(item, index) in listData"
        :key="index"
        @click="toRouteDetail(item)"
      >
        <div class="wrapper">
          <div class="wrapper-title">
            <span>{{ item.topicTitle }}</span>
          </div>
          <div class="wrapper-content">
            <div class="content-date">
              <span>{{ item.createTime }}</span>
            </div>
            <div class="content-tag">
              <div class="iconText">
                <div class="icon">
                  <img src="./img/icon-hot.png" />
                </div>
                <div class="text">
                  <span>{{ item.readCount }}</span>
                </div>
              </div>
              <!-- <div class="iconText">
                <div class="icon">
                  <img src="./img/icon-msg.png" />
                </div>
                <div class="text">
                  <span>{{ item.commentCount }}</span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="image">
          <img :src="item.topicPicture" alt="" />
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { gloabalCount, toRegister } from "@/utils/common";
import { mapState } from "vuex";
import {
  // getButlerInfoByMobileURL,
  // getButlerInfoByHouseIdURL,
  userInfoURL,
  getTopicListURL,
} from "./api";

export default {
  components: {},
  data() {
    return {
      navList: [
        {
          imgUrl: "./img/redNetMicroTreat.png",
          title: "红网微治",
          name: "redNetMicroTreat",
        },
        {
          imgUrl: "./img/neighborlyPool.png",
          title: "睦邻汇",
          name: "neighborlyPool",
        },
        {
          imgUrl: "./img/onlineLearn.png",
          title: "在线学习",
          name: "onlineStudy",
        },
      ],
      phone: undefined, // 用户手机号
      detail: null, // 接口返回详情
      finished: true, // 列表是否记载完成
      // finishedText: "", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 3,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
    finishedText() {
      // 存在数据为空格，已加载完成，反之，则为空，v-list会显示暂无数据文字以及图标
      return this.listData.length > 0 ? " " : "";
    },
  },
  created() {
    gloabalCount("", 95, 1);
    this.getUserInfo();
  },
  methods: {
    toRouteStage() {
      this.$router.push({
        name: "partyBuildingStage",
      });
    },
    // Nav路由跳转
    toRoute({ name }) {
      this.$router.push({
        name,
        query: {
          mobile: this.phone,
        },
      });
    },
    // 金领先锋路由跳转
    toRouteTitle(name) {
      this.$router.push({ name });
    },
    // 红色引领详情路由跳转
    toRouteDetail(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
        });
      }
    },
    // 红色引领更多
    toRouteList() {
      this.$router.push({
        name: "redLead",
      });
    },

    // 获取用户信息
    getUserInfo() {
      if (!this.userId || this.userId == "0") {
        this.onLoad();
        return;
      }
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(userInfoURL, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
            this.phone = this.userInfo.mobile;
          }
        })
        .finally(() => {
          this.onLoad();
        });
    },
    // 通过houseId获取手机号mobile
    // getButlerInfoByHouseId() {
    //   this.$axios
    //     .get(getButlerInfoByHouseIdURL, {
    //       params: {
    //         houseId: this.houseId,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.code == 200 && res.data) {
    //         this.detail = res.data;
    //         this.onLoad();
    //       }
    //       this.finished = true;
    //     })
    //     .catch((error) => {
    //       this.finished = true;
    //     });
    // },
    // 加载列表数据
    // onLoad() {
    //   // 详情未获取前，不调该接口
    //   if (this.detail === null) {
    //     return;
    //   }
    //   this.$axios
    //     .get(getTopicListURL, {
    //       params: {
    //         tenantId: this.tenantId,
    //         houseId: this.houseId || this.communityId || undefined,
    //         userId: this.userId,
    //         status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
    //         topicType: 31, // 31 红色引领
    //         curPage: this.curPage,
    //         pageSize: this.pageSize,
    //         sortField: "NEW", // 排序方式， 最热 or 最新
    //         createUserMobile: this.detail.mobile,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.code != 200) {
    //         this.finished = true;
    //       } else {
    //         if (this.curPage >= res.data.pages) {
    //           this.finished = true;
    //         } else {
    //           this.finished = false;
    //           this.$refs.list.loading = false;
    //         }
    //         this.finishedText = "没有更多了";
    //         if (res.data.records.length === 0) {
    //           this.listData = [];
    //           this.finishedText = "";
    //           return;
    //         }
    //         if (res.code === 200 && res.data && res.data.records) {
    //           if (this.curPage === 1) {
    //             res.data.records.forEach((item) => {
    //               if (item.topicPicture) {
    //                 item.topicPicture = this.$handleImg(
    //                   226,
    //                   150,
    //                   item.topicPicture
    //                 );
    //               }
    //             });
    //             this.listData = res.data.records;
    //           } else {
    //             const list = this.listData.map((v) => v.id);
    //             res.data.records.forEach((item) => {
    //               // 如果请求数据不重复，则添加进listData
    //               if (item.topicPicture) {
    //                 item.topicPicture = this.$handleImg(
    //                   226,
    //                   150,
    //                   item.topicPicture
    //                 );
    //               }
    //               list.includes(item.id) || this.listData.push(item);
    //             });
    //           }
    //           this.curPage++;
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.finished = true;
    //     });
    // },

    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 31, // 31 红色引领
            curPage: this.curPage,
            pageSize: this.pageSize,
            sortField: "NEW", // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data && res.data.records) {
            res.data.records.forEach((item) => {
              if (item.topicPicture) {
                item.topicPicture = this.$handleImg(
                  226,
                  150,
                  item.topicPicture
                );
              }
            });
            this.listData = res.data.records;
          } else {
            this.listData = [];
          }
        })
        .finally(() => {
          this.finished = true;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.partyBuilding {
  padding-bottom: calc(60px + constant(safe-area-inset-bottom));
  padding-bottom: calc(60px + env(safe-area-inset-bottom));
  .section-detail {
    width: 100%;
    height: 440px;
    background: url("./img/background.png") no-repeat top left;
    background-size: 100% 440px;
  }
  .nav {
    margin: -84px 30px 50px;
    padding: 30px 60px;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    box-shadow: 2px 0px 14px 4px rgba(207, 207, 207, 0.5);
    background: #fff;
    .nav-item {
      .item-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > img {
          width: 108px;
          height: 108px;
        }
        .nav-item--title {
          margin-top: 12px;
          font-size: 28px;
          font-weight: bold;
        }
      }
    }
  }
  .pioneerMission {
    margin: 0 30px 44px;
    > img {
      width: 100%;
      height: 194px;
    }
  }
  .list-content {
    display: flex;
    padding: 20px 0;
    margin: 0 30px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    .wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      .wrapper-title {
        flex: 1;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 32px;
        color: rgba(0, 0, 0, 0.85);
      }
      .wrapper-content {
        margin-top: 10px;
        font-size: 24px;
        color: rgba(180, 180, 180, 1);
        display: flex;
        .content-date {
          flex: 1;
          display: flex;
          align-items: center;
        }
        .content-tag {
          display: flex;
          .iconText {
            display: flex;
            color: #a8a8a8;
            align-items: center;

            &:not(:last-child) {
              margin-right: 32px;
            }
            .icon {
              display: flex;
              align-items: center;
              &.icon-up {
                > img {
                  margin-top: 4px;
                }
              }
              > img {
                width: 36px;
                height: 36px;
              }
            }

            .text {
              margin-left: 8px;
              display: flex;
              align-items: center;
              height: 36px;
              line-height: 36px;

              span {
                &.hasPraised {
                  color: #ff853a;
                }

                font-size: 24px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
    .image {
      box-sizing: border-box;
      // border: 1px solid #000;
      border-radius: 16px;
      margin-left: 44px;
      width: 226px;
      height: 150px;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .section {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 28px 40px;
    .section-title {
      font-size: 36px;
      color: rgba(26, 28, 52, 1);
      max-width: 80%;
      font-weight: bold;
    }
    .section-more {
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      > span {
        margin-right: 10px;
      }
      > img {
        width: 12px;
        height: 24px;
      }
    }
  }
}
</style>

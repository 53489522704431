<template>
  <div class="section">
    <div class="section-icon">
      <img src="./img/success_icon.png" alt="" />
      <span>支付成功</span>
    </div>
    <!-- <div class="section-detail" >
      <div class="detail-pic">
        <img :src="detail.picture" alt="" />
      </div>
      <div class="detail-title">{{ detail.title }}</div>
    </div>
    <div class="section-order">
      <div class="order-flex">
        <div class="order-flex-label">支付金额：</div>
        <div class="order-flex-value">{{ detail.price }}元</div>
      </div>
      <div class="order-flex">
        <div class="order-flex-label">兑换数量：</div>
        <div class="order-flex-value">{{ detail.exchangeGoodsNum }}</div>
      </div>
      <div class="order-flex">
        <div class="order-flex-label">兑换时间：</div>
        <div class="order-flex-value">{{ detail.insertTime }}</div>
      </div>
      <div class="order-flex">
        <div class="order-flex-label">订单号：</div>
        <div class="order-flex-value">{{ detail.orderSn }}</div>
      </div>
    </div> -->
    <div class="section-footer">
      <div class="footer-tip">
        <img src="./img/warning_icon.png" alt="" />
        <span>温馨提示：如有问题请及时联系商家</span>
      </div>
      <div class="footer-btn" @click="toRouter">查看更多订单</div>
    </div>
  </div>
</template>

<script>
export default {
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     if (from.name !== "bcnServiceGoodsMyOrder") {
  //       vm.routerPushState();
  //     }
  //   });
  // },
  data() {
    return {
      id: "",
      detail: {
        picture: "",
        title: "",
        price: "",
        exchangeGoodsNum: "",
        insertTime: "",
        orderSn: "",
      },
    };
  },
  methods: {
    // routerPushState() {
    //   window.history.pushState(null, null, document.URL);
    // },
    toRouter() {
      this.$router.replace({
        name: "bcnServiceGoodsMyOrder",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.section {
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 62px 32px 0;
  padding-bottom: calc(
    180px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    180px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    img {
      &:extend(.common-img);
      width: 252px;
      height: 152px;
      margin-bottom: 30px;
    }
    span {
      text-align: center;
      font-size: 32px;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
    }
  }
  .section-detail {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    padding: 18px 24px;
    border-radius: 16px;
    .detail-pic {
      width: 140px;
      min-width: 140px;
      height: 140px;
      margin-right: 32px;
      border-radius: 16px;
      overflow: hidden;
      img {
        &:extend(.common-img);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .detail-title {
      font-size: 32px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
  .section-order {
    margin-top: 102px;
    font-size: 32px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 48px;
    .order-flex {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      display: flex;
      .order-flex-label,
      .order-flex-value {
        display: inline-block;
      }
      .order-flex-label {
        width: 180px;
        min-width: 180px;
      }
      .order-flex-value {
        flex: 1;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
  .section-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding-bottom: calc(
      22px + constant(safe-area-inset-bottom)
    ); /* 兼容 iOS < 11.2 */
    padding-bottom: calc(
      22px + env(safe-area-inset-bottom)
    ); /* 兼容 iOS >= 11.2 */
    .footer-tip {
      margin-bottom: 32px;
      line-height: 40px;
      img {
        &:extend(.common-img);
        width: 28px;
        height: 28px;
      }
      span {
        margin-left: 10px;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .footer-btn {
      display: inline-block;
      width: 550px;
      height: 70px;
      line-height: 70px;
      background: #db431d;
      border-radius: 16px;
      font-size: 30px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>

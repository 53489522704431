<template>
  <div class="healthDetail">
    <div class="oItem">
      <div class="oItem-head">
        <!-- <div class="oItem-headName">
          <div class="oItem-avatar" v-if="oItemDetail.avatar">
            <img :src="oItemDetail.avatar" alt="" />
          </div>
          <div class="oItem-avatar" v-else>
            <img src="@/assets/img/default_avatar.png" alt="" />
          </div>
          <div class="oItem-tl oItem-name">
            {{
              oItemDetail.userName ? formatStrFunc(oItemDetail.userName) : ""
            }}
          </div>
        </div> -->
        <div class="oItem-t">
          <div class="oItem-tr oItem-time">{{ oItemDetail.insertTime }}</div>
          <div
            :class="['oItem-tr', 'oItem-status', getStatusClass(oItemDetail)]"
          >
            {{ getAuditStatus(oItemDetail) }}
          </div>
        </div>
      </div>

      <!-- <div class="oItem-b"><span></span>{{ oItemDetail.proposalContent }}</div> -->
    </div>
    <div class="img" v-if="oItemDetail.healthPic">
      <div
        class="item"
        v-for="(items, indexs) in oItemDetail.healthPic"
        :key="indexs"
      >
        <img :src="items" alt="" />
      </div>
    </div>
    <div class="clock" v-if="oItemDetail.auditStatus == 2" @click="clockIn">
      重新打卡
    </div>
  </div>
</template>

<script>
import { healthDetailUrl } from "./api.js";
import { getOption, formatStr } from "@/utils/utils.js";
import { auditStatusMap } from "./map.js";
var moment = require("moment");
import { handleImg } from "@/utils/utils.js";
export default {
  name: "healthDetail",
  data() {
    return {
      oItemDetail: {},
      id: "",
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
  },
  mounted() {
    this.getHealthDetailUrl();
  },
  created() {
    this.id = this.$route.query.id;
  },

  methods: {
    clockIn() {
      this.$router.replace({
        name: "health",
        query: { id: this.oItemDetail.id },
      });
    },
    getStatusClass(item) {
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, auditStatusMap, "key").class;
      } else {
        return "";
      }
    },
    getAuditStatus(item) {
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, auditStatusMap, "key").value;
      } else {
        return "";
      }
    },
    formatStrFunc(str) {
      if (str) {
        return formatStr(str);
      }
    },
    getHealthDetailUrl() {
      let params = {
        id: this.id,
      };
      this.$axios.get(`${healthDetailUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.oItemDetail = res.data;
          if (this.oItemDetail.avatar) {
            // this.oItemDetail.avatar = this.oItemDetail.avatar.ossResize(80, 80);
            this.oItemDetail.avatar = handleImg(
              80,
              80,
              this.oItemDetail.avatar
            );
          }
          if (this.oItemDetail.healthPic) {
            this.oItemDetail.healthPic = this.oItemDetail.healthPic.split(",");
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.healthDetail {
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .oItem {
    background: #fff;
    padding: 40px;

    margin-bottom: 10px;
    .oItem-t {
      display: flex;
      justify-content: space-between;
      font-size: 36px;
      margin-bottom: 10px;
    }
    .oItem-b {
      font-size: 34px;
      margin-top: 40px;
      span {
        color: salmon;
      }
    }

    .oItem-avatar {
      width: 80px;
      height: 80px;
      /*border-radius:30px;*/
      /*border: 0.5px solid #999;*/
    }
    .oItem-avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      /*border: 0.5px solid #999;*/
    }
    .oItem-name {
      margin-left: 15px;
      font-size: 32px !important;
      font-weight: bold;
    }
    .oItem-time {
      font-size: 28px !important;
      font-weight: normal !important;
      color: #999999;
    }
    .oItem-status {
      font-size: 28px !important;
      font-weight: normal !important;
      color: #178af7;
    }
    .out,
    .reback {
      color: #999999;
    }
    .oItem-head {
      font-weight: bold;
      margin-bottom: 10px;
      align-items: center;
    }
    .oItem-headName {
      display: flex;
      align-items: center;
    }
  }
  .img {
    padding: 0 40px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .clock {
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    height: 96px;
    background: #178af7;
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: #ffffff;
    line-height: 96px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>

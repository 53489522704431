<template>
  <div class="page">
    <div class="cover" v-if="detail.picture">
      <img :src="detail.picture" alt="" />
    </div>
    <div class="description">
      <div class="name">{{ detail.name }}</div>
      <div class="section">
        <div class="section-title">简介</div>
        <div class="section-content">{{ detail.description }}</div>
      </div>
      <div class="section">
        <div class="section-title">联系方式</div>
        <div class="section-content">
          <div class="content-name">
            <span>姓名：</span>
            <span>{{ detail.contactName }}</span>
          </div>
          <div class="content-phone">
            <span>电话：</span>
            <span>{{ detail.contactMobile }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetailsURL } from "../api";

export default {
  components: {},
  data() {
    return {
      id: "",
      detail: {
        // 联建单位详情
        name: "",
        description: "",
        contactName: "",
        contactMobile: "",
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetails();
  },
  methods: {
    getDetails() {
      this.$axios
        .get(getDetailsURL, { params: { id: this.id } })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.detail = res.data;
            return;
          }
          this.$toast({ message: res.msg || "服务器错误", duration: 2000 });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  box-sizing: border-box;
  min-height: 100%;
  background: #fafafa;
  word-wrap: break-word;
  word-break: break-all;
  padding-bottom: calc(64px + constant(safe-area-inset-bottom));
  padding-bottom: calc(64px + env(safe-area-inset-bottom));
  .cover {
    overflow: hidden;
    border-radius: 16px;
    height: 340px;
    margin: 0 30px;
    background: #fff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .description {
    border: 1px solid #fff;
    border-radius: 16px;
    background: #fff;
    margin: 24px 30px 0;
    padding: 40px 40px 0px 40px;
    .name {
      font-size: 32px;
      font-weight: bold;
      color: #1a1c34;
      margin-bottom: 40px;
    }
    .section {
      margin-bottom: 90px;
      .section-title {
        font-weight: bold;
        margin-bottom: 20px;
        color: #fe9514;
        font-size: 28px;
      }
      .section-content {
        font-size: 28px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 1.5;
      }
    }
  }
}
</style>

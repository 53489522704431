<template>
  <div :id="id" class="echartLine"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
  props: {
    // 图表数据
    echartData: {
      type: Object,
      default() {
        return {
          x: [],
          y: [],
        };
      },
    },
    curTab: Object,
  },
  data() {
    return {
      id: `echartLine_${new Date().getTime()}_${Math.random() * 10000}`,
      chart: null,
    };
  },
  watch: {
    echartData: {
      handler() {
        this.drawLineEchart();
      },
      deep: true,
    },
    curTab: {
      handler() {
        this.resizeAllCharts();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.drawLineEchart();
    window.addEventListener("resize", this.resizeAllCharts);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeAllCharts);
  },
  methods: {
    drawLineEchart() {
      const xAxisData = this.echartData.x;
      const yAxisData = this.echartData.y;
      let option = {
        color: ["#539AF8"],
        grid: {
          left: "5%",
          right: "5%",
          top: "20%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          textStyle: {
            color: "#539AF8",
            fontSize: 12,
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false, // 默认为 true，这时候刻度只是作为分隔线，标签和数据点都会在两个刻度中间
          name: "",
          data: xAxisData,
          // data: [
          //   "0:00",
          //   "6:00",
          //   "12:00",
          //   "18:00",
          //   "24:00",
          //   "0:00",
          //   "6:00",
          //   "12:00",
          //   "18:00",
          //   "24:00",
          // ],
          axisLine: {
            lineStyle: {
              color: "#539AF8",
            },
          },
          axisTick: {
            show: true,
            inside: true,
            lineStyle: {
              color: "#78B2FF",
            },
          },
          axisLabel: {
            show: true,
            color: "#78B2FF",
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#DDECFE",
            },
          },
          silent: false,
        },
        yAxis: {
          type: "value",
          name: "",
          min: 0,
          axisLabel: {
            show: true,
            color: "#78B2FF",
          },
          minInterval: 1,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#78B2FF",
            },
          },
          axisTick: {
            show: true,
            inside: true,
            lineStyle: {
              color: "#78B2FF",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#DDECFE",
            },
          },
        },
        dataZoom: [
          {
            show: true,
            type: "inside",
          },
        ],
        series: {
          name: "心率（次/分钟）",
          type: "line",
          symbol: "none",
          smooth: true,
          // symbolSize: 8,
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#DDECFF", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "rgba(221, 236, 255, 0)", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
          blur: {
            areaStyle: {
              color: "transparent",
            },
          },
          data: yAxisData,
          // data: [90, 75, 72, 90, 100],
        },
      };
      this.drawedChart(this.id, option);
    },
    drawedChart(eleId, option) {
      if (this.chart) {
        this.chart.dispose();
      }
      if (document.getElementById(eleId)) {
        this.chart = echarts.init(document.getElementById(eleId));
        this.chart.setOption(option);
      }
    },
    resizeAllCharts() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.echartLine {
  width: 100%;
  height: 100%;
}
</style>

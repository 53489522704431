<template>
  <div class="v-search">
    <van-search
      v-model="model"
      show-action
      :placeholder="placeholder"
      @search="onSearch"
    >
      <template #action>
        <div v-if="showAction" @click="onSearch">搜索</div>
      </template>
    </van-search>
  </div>
</template>

<script>
import Vue from "vue";
import { Search } from "vant";

Vue.use(Search);

export default {
  components: {},
  props: {
    finished: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    swiperData: {
      type: Array,
      default: function () {
        return [{}];
      },
    },
    // 默认使用#action插槽内的内容，以适配以前的代码
    showAction: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    onSearch() {
      this.$emit("onSearch");
    },
  },
};
</script>

<style lang="less">
.v-search {
  width: 100%;
}
</style>

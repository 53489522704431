import { render, staticRenderFns } from "./bcnOtherCompleteDetail.vue?vue&type=template&id=b42c9f80&scoped=true"
import script from "./bcnOtherCompleteDetail.vue?vue&type=script&lang=js"
export * from "./bcnOtherCompleteDetail.vue?vue&type=script&lang=js"
import style0 from "./bcnOtherCompleteDetail.vue?vue&type=style&index=0&id=b42c9f80&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b42c9f80",
  null
  
)

export default component.exports
//获取活动列表
const getActivityListUrl = `/gateway/hc-serve/miniapi/activity/activityList`;
//获取活动详情
const getActivityDetailUrl = `/gateway/hc-serve/miniapi/activity/activityDetail`;
//新增参与人
const addPeopleUrl = `/gateway/hc-serve/miniapi/activity/myApplicantsAdd`;
// 编辑参与人
const editPeopleUrl = `/gateway/hc-serve/miniapi/activity/myApplicantsEdit`;
//我的活动参与人列表
const addPeopleListUrl = `/gateway/hc-serve/miniapi/activity/myActivityApplicantsList`;
//我的活动参与人详情
const addPeopleDetailUrl = `/gateway/hc-serve/miniapi/activity/myActivityApplicantsDetail`;
//删除活动参与人
const deletePeopleUrl = `/gateway/hc-serve/miniapi/activity/myActivityApplicantsDelete`;
//活动报名
const applyActivityUrl = `/gateway/hc-serve/miniapi/activity/activityApply`;
// 取消报名
const cancelActivityUrl = `/gateway/hc-serve/miniapi/activity/cancelActivityApply`;
// 我报名的活动详情
const activityDetailUrl = `/gateway/hc-serve/miniapi/activity/myActivityDetail`;
//我的报名
const myActivityUrl = `/gateway/hc-serve/miniapi/activity/myActivityList`;
//发布活动共
const publishActivityUrl = `/gateway/hc-serve/miniapi/activity/postActivity`;
//发布的活动列表

const myPublishActivityUrl = `/gateway/hc-serve/miniapi/activity/myActivityApplyList`;
// 新接口 参与活动
// const applyActivityUrl = `/gateway/hc-serve/miniapi/activity/v1/activityApply`;

//场所列表
const areaListUrl = `/gateway/hc-serve/miniapi/activity/reservationPlaceList`;

//个人信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;

/** 时间银行 */
// 验证志愿者身份接口
const volunteerUrl = `/gateway/hc-serve/miniapi/volunteer/verificationVolunteer`;
// 申请志愿者接口
const appliyVolunteerUrl = `/gateway/hc-serve/miniapi/volunteer/volunteerApplication`;
// 承诺书详情接口
const getCommitmentLetterDetailsUrl = `/gateway/hc-serve/miniapi/volunteer/commitmentLetterDetails`;
// 是否签署承诺书
const ifCommitmentLetterUrl = `/gateway/hc-serve/miniapi/volunteer/ifCommitmentLetter`;
// 签署承诺书接口
const applyForCommitmentLetterUrl = `/gateway/hc-serve/miniapi/volunteer/applyForCommitmentLetter`;
// 我的家庭成员列表接口
const getMyFamilyMemberListUrl = `/gateway/hc-serve/miniapi/volunteer/myFamilyMemberList`;
// 添加家庭成员
const addFamilyMemberUrl = `/gateway/hc-serve/miniapi/volunteer/addFamilyMember`;
// 删除家庭成员接口
const deleteFamilyMemberUrl = `/gateway/hc-serve/miniapi/volunteer/deleteFamilyMember`;

// 参与人备注编辑接口
const compileApplicantsNoteUrl = `/gateway/hc-serve/miniapi/activity/compileApplicantsNote`;
// 参与人备注详情接口
const applicantsNoteDetailsUrl = `/gateway/hc-serve/miniapi/activity/applicantsNoteDetails`;
// 话题列表
const getTopicListUrl = `/gateway/hc-neighbour/topic/getTopicList`;
//验证志愿者身份，是否签署承诺书
const judgeVolunteerUrl = `/gateway/hc-serve/miniapi/volunteer/ifVolunteerAndCommitmentLetter`;
// 调查问卷详情
const getTopicInfo = `/gateway/hc-serve/miniapi/activity/activityQuTopicInfo`;
// 提交问卷
const getSubmitQuestionnaire = `/gateway/hc-serve/miniapi/activity/submitActivityQuestionnaire`;
const leaveWordsUrl = `/gateway/hc-serve/leaveWords/leaveWord`; //好物留言
const getDetailsListUrl = `/gateway/hc-serve/leaveWords/getDetailsList`; //留言对话框列表
const deleteLeaveWordUrl = `/gateway/hc-serve/leaveWords/deleteLeaveWord`; //留言撤回
export {
  getTopicInfo,
  getSubmitQuestionnaire,
  judgeVolunteerUrl,
  getActivityListUrl,
  getActivityDetailUrl,
  addPeopleUrl,
  editPeopleUrl,
  addPeopleListUrl,
  addPeopleDetailUrl,
  deletePeopleUrl,
  applyActivityUrl,
  cancelActivityUrl,
  activityDetailUrl,
  myActivityUrl,
  publishActivityUrl,
  myPublishActivityUrl,
  userInfoUrl,
  areaListUrl,
  volunteerUrl,
  appliyVolunteerUrl,
  getCommitmentLetterDetailsUrl,
  ifCommitmentLetterUrl,
  applyForCommitmentLetterUrl,
  getMyFamilyMemberListUrl,
  addFamilyMemberUrl,
  deleteFamilyMemberUrl,
  compileApplicantsNoteUrl,
  applicantsNoteDetailsUrl,
  getTopicListUrl,
  leaveWordsUrl,
  getDetailsListUrl,
  deleteLeaveWordUrl,
};

// 话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
// 问卷详情
const questionnaireDetailsUrl = `/gateway/hc-serve/miniapi/adolescent/questionnaireDetails`;
// 问卷提交
const questionnaireSubmitUrl = `/gateway/hc-serve/miniapi/adolescent/questionnaireSubmit`;
//获取活动列表
const getActivityListUrl = `/gateway/hc-serve/miniapi/activity/activityList`;
// 验证志愿者身份接口
const volunteerUrl = `/gateway/hc-serve/miniapi/volunteer/verificationVolunteer`;
// 是否签署承诺书
const ifCommitmentLetterUrl = `/gateway/hc-serve/miniapi/volunteer/ifCommitmentLetter`;
//获取活动详情
const getActivityDetailUrl = `/gateway/hc-serve/miniapi/activity/activityDetail`;
//我的活动参与人列表
const addPeopleListUrl = `/gateway/hc-serve/miniapi/activity/myActivityApplicantsList`;
//活动报名
const applyActivityUrl = `/gateway/hc-serve/miniapi/activity/activityApply`;
//删除活动参与人
const deletePeopleUrl = `/gateway/hc-serve/miniapi/activity/myActivityApplicantsDelete`;
// 参与人备注详情接口
const applicantsNoteDetailsUrl = `/gateway/hc-serve/miniapi/activity/applicantsNoteDetails`;
// 参与人备注编辑接口
const compileApplicantsNoteUrl = `/gateway/hc-serve/miniapi/activity/compileApplicantsNote`;
export {
  getTopicListURL,
  questionnaireDetailsUrl,
  questionnaireSubmitUrl,
  getActivityListUrl,
  volunteerUrl,
  ifCommitmentLetterUrl,
  getActivityDetailUrl,
  addPeopleListUrl,
  applyActivityUrl,
  deletePeopleUrl,
  applicantsNoteDetailsUrl,
  compileApplicantsNoteUrl,
};

import wx from "weixin-js-sdk";
import store from "@/store";
import axios from "@/axios";
import { Toast } from "vant";
var moment = require("moment");
//判断是否需要房号
async function getAuth(name) {
  let origin;
  if (window.location.origin == "https://mapi.xydata.cc") {
    origin = "https://test.zhuneng.cn"; //研发
    // origin = "https://wantou.zhuneng.cn";//生产
  } else {
    origin = "";
  }
  let api = `${origin}/gateway/blade-system/application/getFunctionSetting`;
  let params = {
    tenantId: store.state.tenantId,
    applicationName: name,
    functionName: "需要园区房号",
  };
  return await axios.get(api, { params: params }).then((res) => {
    if (res.code == 200) {
      return res.data.settingOn;
    } else {
      return false;
    }
  });
}
async function toRegister(toUrl, params, name) {
  let res = await getAuth(name);
  console.log(res);
  if (res == 2) {
    //需要园区房号 houseId
    if (!store.state.houseId || store.state.houseId == 0) {
      if (!store.state.userId || store.state.userId == 0) {
        if (!store.state.tenantId || store.state.tenantId == 0) {
          wx.miniProgram.redirectTo({
            url: `/pages/communitySelect/index?isRegister=2&isMust=1&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
              params
            )}`,
          });
        } else {
          wx.miniProgram.redirectTo({
            url: `/pages/register/index?isRegister=2&isMust=1&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
              params
            )}`,
          });
        }
        return false;
      } else {
        if (!store.state.communityId || store.state.communityId == 0) {
          wx.miniProgram.redirectTo({
            url: `/pages/register/index?isAddRoom=1&isMust=1&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
              params
            )}`,
          });
          return false;
        } else {
          let isRegister = 1;
          let assetId = store.state.assetId;
          wx.miniProgram.redirectTo({
            url: `/pages/register/index?isRegister=${isRegister}&assetId=${assetId}&isMust=1&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
              params
            )}`,
          });
          return false;
        }
      }
    } else {
      if (store.state.assetAuditStatus == 2) {
        Toast("你的房号被驳回,请重新提交");
        return false;
      }
      if (store.state.assetAuditStatus == 0) {
        Toast("你的房号待审核");
        return false;
      }
      return true;
    }
  } else if (res == 1) {
    //需要园区，不需要房号 communityId
    if (!store.state.communityId || store.state.communityId == 0) {
      if (!store.state.userId || store.state.userId == 0) {
        if (!store.state.tenantId || store.state.tenantId == 0) {
          wx.miniProgram.redirectTo({
            url: `/pages/communitySelect/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
              params
            )}`,
          });
        } else {
          wx.miniProgram.redirectTo({
            url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
              params
            )}`,
          });
        }
        return false;
      } else {
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isAddRoom=1&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
        return false;
      }
    } else {
      return true;
    }
  } else {
    //都不需要
    return true;
  }
}

async function isWxMiniPro() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
    try {
      await wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          return true;
        } else {
          return false;
        }
      });
    } catch (error) {
      return false;
    }
  } else {
    return false;
  }
}
//  埋点
async function gloabalCount(relationId, dataType, operationType) {
  let origin;
  if (window.location.origin == "https://mapi.xydata.cc") {
    origin = "https://test.zhuneng.cn"; //研发
    // origin = "https://wantou.zhuneng.cn";//生产
  } else {
    origin = "";
  }
  let api = `${origin}/gateway/hc-external/redisMQ/produce`;
  let params = {
    userId: store.state.userId,
    miniUserId: store.state.openId,
    tenantId: store.state.tenantId,
    communityId: store.state.communityId,
    roomId: store.state.houseId,
    relationId: relationId,
    dataType: dataType,
    operationType: operationType,
    operationTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  };
  let res = await axios.post(api, params);
  if (res.code == 200) {
  }
}
export { toRegister, isWxMiniPro, getAuth, gloabalCount };

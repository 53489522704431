<template>
  <div class="section">
    <div class="section-swiper">
      <swiper-preview
        :autoplay="2000"
        :swiperData="detail.imageUrl ? detail.imageUrl.split(',') : []"
      />
    </div>
    <div class="section-number">
      <div class="number-sell">
        <div class="number-sell-price">
          售价￥<span>{{ detail.price }}</span>
        </div>
        <div class="number-sell-sold">已售{{ detail.soldOutCount }}件</div>
      </div>
      <div class="number-stock" v-if="detail.goodsRemainCount !== ''">
        仅剩<span>{{ detail.goodsRemainCount }}</span>
      </div>
      <div class="number-stock" v-else>库存<span>不限量</span></div>
    </div>
    <div class="section-name">{{ detail.title }}</div>

    <div class="section-sku">
      <span>规格：</span>
      <div
        class="sku-item"
        v-for="(item, index) in detail.skuList"
        :key="index"
      >
        <span>{{ item.skuName }}</span>
      </div>
    </div>

    <div class="section-detail">
      <div class="detail-nav">
        <div class="common-title"><span>产品详情</span></div>
      </div>
      <!-- <div class="detail-content">{{ detail.introduce || "暂无" }}</div> -->
      <div class="detail-content">
        <v-h5Mtml :content="detail.detailContent || '暂无'"></v-h5Mtml>
      </div>
    </div>

    <div class="section-footer">
      <div class="footer-button">
        <div class="cart" @click="addShopCart">加入购物车</div>
        <div class="order" @click="orderNow">立即下单</div>
      </div>
    </div>

    <div :class="['popup', { delivery: flow == 1 }]" v-if="showPopup">
      <div class="popup-overlary" @click="showPopup = false"></div>
      <div class="popup-content" v-if="flow == 1">
        <div class="content-title">请选择提货方式：</div>
        <div class="content-select">
          <div
            :class="['select-option', currentIndex === index ? 'current' : '']"
            v-for="(item, index) in deliveryMethod"
            :key="index"
            @click="chooseType(item, index)"
          >
            <span class="icon"></span>
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="popup-footer">
          <div class="footer-button" @click="nextStep">下一步</div>
        </div>
      </div>
      <template v-if="flow == 2">
        <template v-if="step === 1">
          <div class="popup-content stepOne">
            <div class="content-title">选择提货点：</div>
            <div class="content-select">
              <div
                :class="['select-option', curAddress === o ? 'current' : '']"
                v-for="(o, index) in detail.getAddress"
                :key="index"
                @click="changeAddress(o)"
              >
                <span class="icon"></span>
                <span>{{ o }}</span>
              </div>
            </div>
            <div class="popup-footer">
              <div class="footer-button" @click="clickStep(1)">下一步</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="popup-content stepTwo">
            <div class="content-detail">
              <div class="detail-picture">
                <img :src="curSku.skuImageUrl" alt="" />
              </div>
              <div class="detail-flex">
                <div class="detail-flex-title">
                  <span>{{ detail.title }}</span>
                </div>
                <div class="detail-flex-price">
                  <span
                    >{{ curSku.price }}元（库存：{{
                      curSku.stock === "" ? "无限量" : curSku.stock
                    }}）</span
                  >
                </div>
              </div>
            </div>
            <div class="content-size">
              <div
                :class="[
                  'size-item',
                  item.id === curSku.id ? 'size-select' : '',
                ]"
                v-for="(item, index) in detail.skuList"
                :key="index"
                @click="setCurSku(item)"
              >
                <span>{{ item.skuName }}</span>
              </div>
            </div>
            <!-- <div class="content-num">
            <v-stepper
              v-model="count"
              :min="1"
              :max="curSku.stock === '' ? Infinity : curSku.stock"
            />
          </div> -->
            <div class="content-footer" @click="clickStep(2)">
              <span>立即支付</span>
            </div>
          </div>
        </template>
      </template>
      <div class="popup-content" v-if="flow == 3">
        <div class="content-title">填写配送地址：</div>
        <div class="content-select search-input">
          <v-input v-model="shippingAddress" placeholder="请输入"></v-input>
        </div>
        <div class="popup-footer">
          <div class="footer-button" @click="payNow">立即支付</div>
        </div>
      </div>
    </div>
    <div
      class="dialog"
      @click.prevent="isShowDialog = false"
      v-if="isShowDialog"
    >
      <div class="dialog-content" @click.stop>
        <div class="title">免责条款</div>
        <div class="text">
          1.1用户明确同意其使用“奉化丨家门口”小程序网络服务所存在的风险及后果将完全由用户本人承担，“奉化丨家门口”对此不承担任何责任。<br />
          1.2对于因不可抗力或“奉化丨家门口”不能控制的原因造成服务中断或其他缺陷，“奉化丨家门口”不承担任何责任，但将尽力减少因此给用户造成的影响。包括天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如小程序服务器环境主机或网络故障、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免、不能克服的客观情况。<br />
          1.3“奉化丨家门口”小程序不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该外部链接指向的内容，也不承担任何责任。<br />
          1.4“奉化丨家门口”小程序可能会调用第三方系统或通过第三方支持用户的使用或访问，“奉化丨家门口”不保证通过第三方提供服务及内容的合法性、安全性、准确性、有效性及其他不确定的风险，由此引发的任何争议及损害，“奉化丨家门口”不承担任何责。<br />
          1.5“奉化丨家门口”不保证加盟企业提供的服务一定能满足用户的要求和期望，也不保证服务不会中断，对服务的合法性、有效性、及时性、安全性、准确性都不作保证。加盟企业向用户提供的产品或者服务的质量问题本身及其引发的任何损失，“奉化丨家门口”无需承担任何责任。“奉化丨家门口”仅提供用户和加盟企业的交往交流等服务，不雇佣员工，也不承揽相关的服务工作，对加盟企业服务人员及用户双方过错不承担任何责任。<br />
          1.6“奉化丨家门口”小程序有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论其通知与否，“奉化丨家门口”对用户和任何第三人均无需承担任何责任。<br />
          1.7、如用户的行为使“奉化丨家门口”遭受损失
          (包括自身的直接经济损失、对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失)，用户应赔偿“奉化丨家门口”的上述全部损失。如用户的行为使“奉化丨家门口”遭受第三人主张权利，“奉化丨家门口”可在对第三人承担金钱给付等义务后就全部损失向用户追偿。
        </div>
        <div class="footer" @click="confirm">我知道了</div>
      </div>
    </div>

    <div class="section-refresh" v-if="isRefresh" @click="payResult">
      <span>点击查询订单状态，更新订单信息</span>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import {
  getGoodsDetailURL,
  addTrolleyURL,
  userInfoURL,
  addOrderURL,
  // initWxJsSdkURL,
  queryOrderURL,
} from "./api";
import swiperPreview from "../components/swiper-preview.vue";

export default {
  components: { swiperPreview },
  data() {
    return {
      isShowDialog: false,
      flow: 1,
      currentIndex: 0, //当前提货方式
      //提货方式
      deliveryMethod: [
        {
          name: "提货",
          value: 1,
        },
        {
          name: "配送",
          value: 2,
        },
      ],
      shippingAddress: "", //配送地址
      id: "",
      detail: {
        //   pictures: "",
        //   title: "",
        //   price: "",
        //   sold: "",
        //   stock: "",
        //   getAddress: [
        //     // "金钟农贸123号摊",
        //     // "世纪联华超市",
        //     // "同泰药店",
        //     // "金钟广场",
        //     // "菜鸟驿站",
        //     // "金钟农贸123号摊1",
        //     // "世纪联华超市2",
        //     // "同泰药店3",
        //     // "金钟广场4",
        //     // "菜鸟驿站5",
        //     // "金钟农贸123号摊6",
        //     // "世纪联华超市7",
        //     // "同泰药店8",
        //     // "金钟广场9",
        //     // "菜鸟驿站10",
        //   ],
      },
      userInfo: {},

      /** v-popup */
      showPopup: false,
      step: 1, // 1 第一步 选择地址
      curAddress: "",
      curSku: {
        pirce: "",
        stock: "",
        skuName: "",
        skuImageUrl: "",
        id: "",
      },
      count: 1, // 商品购买数量，最小为 1
      /* 按钮点击 */
      // 立即支付按钮是否可点击
      isClick: true,
      /* 立即下单 */
      isRefresh: false, // 遮罩层，点击刷新数据
      outTradeNo: "", // 订单编号
      receivingWay: 1, // 收货方式
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId", "openId"]),
  },
  created() {
    this.initData();
  },
  methods: {
    //确认
    confirm() {
      this.isShowDialog = false;
      if (!this.isClick) {
        return;
      }
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
        openId: this.openId,
        ownerId: this.detail.shopId,
        type: 3, // 普通商品
        goodsId: this.id,
        // orderGoodsNum: 1,
        orderGoodsNum: this.count,
        price: this.detail.price,
        receiverName: this.userInfo.userName,
        receiverPhone: this.userInfo.mobile,
        skuId: this.curSku.id,
        getAddress: this.curAddress || this.shippingAddress,
        receivingWay: this.receivingWay,
      };
      this.isClick = false;
      this.$axios
        .post(addOrderURL, params)
        .then((res) => {
          if (res.code === 200) {
            const result = res.data;
            const path = {
              to: "",
              from: "/futureService/bcnCategoryShopDetail",
            };
            if (wx.miniProgram) {
              wx.miniProgram.navigateTo({
                animationDuration: 10,
                url: `/xubPages/payDetail/index?query=${encodeURIComponent(
                  JSON.stringify(this.$route.query)
                )}&params=${encodeURIComponent(
                  JSON.stringify(result)
                )}&path=${JSON.stringify(path)}`,
                complete: () => {
                  this.isClick = true;
                  this.showPopup = false;
                  this.payResultDialog(res.outTradeNo);
                },
              });
            }
            // this.showPopup = true;
            // console.log(wx.chooseWXPay, result.appid, "wx.requestPayment");
            // const _this = this;
            // wx.chooseWXPay({
            //   // debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
            //   appId: result.appid, // 必填，公众号的唯一标识
            //   timestamp: result.timeStamp, // 必填，生成签名的时间戳
            //   package: result.package, // 订单详情扩展字符串
            //   paySign: result.paySign, // 必填，签名
            //   nonceStr: result.nonceStr, // 必填，生成签名的随机串
            //   signType: result.signType, // 签名方式
            //   success(r) {
            //     if (r.errMsg == "chooseWXPay:ok") {
            //       _this.payResult(result.outTradeNo);
            //     } else {
            //       _this.$toast({ message: "支付失败！", duration: 2000 });
            //     }
            //   },
            //   cancel() {
            //     console.log("取消支付");
            //   },
            //   fail() {
            //     _this.$toast({ message: "支付失败！", duration: 2000 });
            //   },
            // });
          }
        })
        .finally(() => {
          this.isClick = true;
        });
    },
    //选择提货方式
    chooseType(item, index) {
      this.curAddress = "";
      this.shippingAddress = "";
      this.receivingWay = item.value;
      this.currentIndex = index;
    },
    //下一步
    nextStep() {
      // this.isShowDeliveryMethod = false;
      this.currentIndex == 0
        ? ((this.flow = 2), (this.step = 1))
        : (this.flow = 3);
    },
    async initData() {
      const { id } = this.$route.query;
      this.id = id;
      this.getDetail();
      this.getUserInfo();
      // this.initWxConfig();
    },
    // // 微信授权H5可调用的 wx API功能
    // async initWxConfig() {
    //   const url =
    //     process.env.NODE_ENV == "production"
    //       ? location.href.split("#")[0]
    //       : "https://fenghua.zhuneng.cn";
    //   let res = await this.$axios.post(
    //     `${initWxJsSdkURL}`,
    //     this.$qs.stringify({
    //       url,
    //     })
    //   );
    //   if (res.code == 200) {
    //     const result = res.data;
    //     wx.config({
    //       debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
    //       appId: result.appId, // 必填，公众号的唯一标识
    //       timestamp: result.timestamp, // 必填，生成签名的时间戳
    //       nonceStr: result.nonceStr, // 必填，生成签名的随机串
    //       signature: result.signature, // 必填，签名
    //       jsApiList: ["chooseWXPay"], // 必填，需要使用的 JS 接口列表
    //     });
    //   }
    // },

    // 获取用户信息
    getUserInfo() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(userInfoURL, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
          }
        });
    },
    getDetail() {
      this.$axios
        .get(getGoodsDetailURL, {
          params: {
            id: this.id,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.detail = res.data;
            // 默认选中第一个规格
            this.curSku =
              res.data.skuList && res.data.skuList.length > 0
                ? res.data.skuList[0]
                : this.curSku;
            return;
          }
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        });
    },
    // update(data) {
    //   Object.keys(this.detail).forEach((key) => {
    //     this.detail[key] = data[key] || this.detail[key];
    //   });
    //   this.detail.completePicture1 = this.detail.completePicture;
    // },
    // 添加购物车
    async addShopCart() {
      let res = await this.$axios.post(addTrolleyURL, {
        goodsId: this.id,
        // goodsType: this.detail.goodsType,
        goodsType: 3,
        goodsNum: 1,
        insertUser: this.userId,
      });
      if (res.code == 200) {
        this.$toast({ message: "添加成功！", duration: 2000 });
      }
    },
    // 立即下单
    orderNow() {
      // this.step = 1; // 弹框重置为第一步
      this.flow = 1;
      this.count = 1;
      this.showPopup = true;
    },
    // 改变地址
    changeAddress(o) {
      this.curAddress = o;
    },
    // 选择规格
    setCurSku(item) {
      this.count = 1;
      this.curSku = item;
    },
    //
    clickStep(num) {
      if (num === 1) {
        if (!this.curAddress) {
          this.$toast({ message: "请选择提货点", duration: 2000 });
          return;
        }
        this.step = 2;
      } else if (num === 2) {
        this.payNow();
      }
    },
    // 立即支付
    payNow() {
      this.isShowDialog = true;
    },
    // 查询结果弹框
    payResultDialog(outTradeNo) {
      this.outTradeNo = outTradeNo;
      setTimeout(() => {
        this.isRefresh = true;
      }, 500);
    },
    // 查询结果参数
    async payResult() {
      this.isRefresh = false;
      this.initData();
      // let res = await this.$axios.get(queryOrderURL, {
      //   params: {
      //     outTradeNo: this.outTradeNo,
      //     userId: this.userId,
      //   },
      // });
      // if (res.code == 200 && res.success) {
      //   this.initData();
      //   // this.$toast({ message: "支付成功！", duration: 2000 });
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.section {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  font-family: PingFangSC-Medium, PingFang SC;
  display: flex;
  flex-direction: column;
  .section-swiper {
    width: 100%;
    height: 400px;
  }
  .section-number {
    display: flex;
    width: 100%;
    .number-sell {
      flex: 1;
      padding: 20px 30px;
      background: #c33030;
      .number-sell-price {
        font-size: 28px;
        font-weight: 400;
        color: #ffffff;
        span {
          font-size: 62px;
        }
      }
      .number-sell-sold {
        font-size: 26px;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
    .number-stock {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: -38px;
        top: 50%;
        transform: translateY(-50%);
        border-right: 20px solid #fef4d1;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid transparent;
      }
      padding: 30px 30px;
      font-size: 28px;
      font-weight: bold;
      color: #c33030;
      background: linear-gradient(315deg, #fde8a3 0%, #fef4d1 100%);
      span {
        font-size: 62px;
      }
    }
  }
  .section-name {
    padding: 36px 18px;
    font-size: 36px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 48px;
    background: #ffffff;
  }
  .section-sku {
    background: #ffffff;
    padding: 0 20px 20px;
    > span {
      font-size: 28px;
      vertical-align: middle;
    }
    .sku-item {
      vertical-align: middle;
      margin: 0 20px 0px 0;
      &:last-child {
        margin-right: 0px;
      }
      display: inline-block;
      box-sizing: border-box;
      font-size: 26px;
      font-weight: 400;
      line-height: 32px;
      padding: 6px 12px;
      border-radius: 4px;
      // color: rgba(0, 0, 0, 0.5);
      // border: 2px solid rgba(0, 0, 0, 0.5);
      color: #fe9616;
      border: 2px solid #fe9616;
    }
  }
  .section-detail {
    flex: 1;
    margin-top: 20px;
    padding: 32px 30px;
    padding-bottom: calc(
      132px + constant(safe-area-inset-bottom)
    ); /* 兼容 iOS < 11.2 */
    padding-bottom: calc(
      132px + env(safe-area-inset-bottom)
    ); /* 兼容 iOS >= 11.2 */
    background: #ffffff;
    .detail-nav {
      display: flex;
      font-size: 36px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      line-height: 38px;
    }
    .detail-content {
      margin-top: 44px;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
  .section-footer {
    position: fixed;
    bottom: 32px;
    right: 30px;
    height: 70px;
    .footer-button {
      display: flex;
      height: 100%;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
      border-radius: 16px;
      overflow: hidden;
      .cart,
      .order {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 210px;
      }
      .cart {
        background: #f29983;
      }
      .order {
        background: #db431d;
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    &.delivery {
      .content-select {
        display: flex;
        align-items: center;

        .select-option {
          flex: 1;
          justify-content: center;
          &.current {
            background-color: transparent !important;
          }
        }
      }
    }
    .popup-overlary {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    .popup-content {
      position: absolute;
      background: #fff;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      padding-bottom: calc(
        32px + constant(safe-area-inset-bottom)
      ); /* 兼容 iOS < 11.2 */
      padding-bottom: calc(
        32px + env(safe-area-inset-bottom)
      ); /* 兼容 iOS >= 11.2 */
      .content-title {
        padding: 40px 30px;
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        line-height: 42px;
      }
      .content-select {
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        &.search-input {
          flex: 1;
          padding: 0 20px;
          ::v-deep .van-cell {
            padding: 0 36px;
            background: #f7f7f7;
            // border-radius: 30px;
            line-height: 84px;
          }
        }
        .select-option {
          display: flex;
          align-items: center;
          padding: 24px 30px;
          font-size: 30px;
          font-weight: 400;
          color: #333333;
          line-height: 42px;
          .icon {
            margin-right: 14px;
            box-sizing: border-box;
            width: 30px;
            height: 30px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            border-radius: 50%;
          }
          &.current {
            background: #f9f9f9;
            .icon {
              border: 8px solid #db431d;
            }
          }
        }
      }
      .popup-footer {
        display: flex;
        justify-content: flex-end;
        .footer-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 210px;
          height: 70px;
          margin: 30px 30px 0 0;
          background: #db431d;
          border-radius: 16px;
          font-size: 30px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    .popup-content.stepOne {
      position: absolute;
      background: #fff;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      padding-bottom: calc(
        32px + constant(safe-area-inset-bottom)
      ); /* 兼容 iOS < 11.2 */
      padding-bottom: calc(
        32px + env(safe-area-inset-bottom)
      ); /* 兼容 iOS >= 11.2 */
      .content-title {
        padding: 40px 30px;
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        line-height: 42px;
      }
      .content-select {
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        .select-option {
          display: flex;
          align-items: center;
          padding: 24px 30px;
          font-size: 30px;
          font-weight: 400;
          color: #333333;
          line-height: 42px;
          .icon {
            margin-right: 14px;
            box-sizing: border-box;
            width: 30px;
            height: 30px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            border-radius: 50%;
          }
          &.current {
            background: #f9f9f9;
            .icon {
              border: 8px solid #db431d;
            }
          }
        }
      }
      .popup-footer {
        display: flex;
        justify-content: flex-end;
        .footer-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 210px;
          height: 70px;
          margin: 30px 30px 0 0;
          background: #db431d;
          border-radius: 16px;
          font-size: 30px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }

    .popup-content.stepTwo {
      position: absolute;
      background: #fff;
      padding: 40px 32px;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      padding-bottom: calc(
        32px + constant(safe-area-inset-bottom)
      ); /* 兼容 iOS < 11.2 */
      padding-bottom: calc(
        32px + env(safe-area-inset-bottom)
      ); /* 兼容 iOS >= 11.2 */
      .content-detail {
        display: flex;
        align-items: center;
        .detail-picture {
          width: 174px;
          min-width: 174px;
          height: 174px;
          margin-right: 26px;
          img {
            &:extend(.common-img);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .detail-flex {
          flex: 1;
          font-size: 32px;
          font-weight: 400;
          line-height: 44px;
          .detail-flex-title {
            white-space: normal;
            word-break: break-all;
            word-wrap: break-word;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 40px;
          }
          .detail-flex-price {
            color: #fe9616;
          }
        }
      }
      .content-size {
        margin: 30px 0 54px;
        .size-item {
          margin: 0 20px 10px 0;
          &:last-child {
            margin-right: 0px;
          }
          display: inline-block;
          box-sizing: border-box;
          font-size: 26px;
          font-weight: 400;
          line-height: 32px;
          padding: 6px 12px;
          border-radius: 4px;
          color: rgba(0, 0, 0, 0.5);
          border: 2px solid rgba(0, 0, 0, 0.5);
          &.size-select {
            color: #fe9616;
            border: 2px solid #fe9616;
          }
        }
      }
      .content-footer {
        margin: 84px auto 0;
        width: 550px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #db431d;
        border-radius: 16px;
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 4px;
      }
    }
  }
  .dialog {
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .dialog-content {
      width: 100%;
      height: 80%;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      padding: 42px 34px 52px 34px;
      .title {
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.85);
        line-height: 42px;
        margin-bottom: 32px;
      }
      .text {
        flex: 1;
        overflow-y: scroll;
        font-size: 26px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 36px;
      }
      .footer {
        text-align: center;
        width: 550px;
        height: 70px;
        margin: 52px auto auto;
        line-height: 70px;
        background: #db431d;
        border-radius: 16px;
        font-size: 32px;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .section-refresh {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #1989fa;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="page">
    <div class="tab">
      <div
        :class="['item', { active: curTab.type == item.type }]"
        v-for="(item, index) in tabList"
        :key="index"
        @click="changeTab(item, index)"
      >
        {{ item.name }}
      </div>
    </div>

    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <div v-show="curTab.type == 1">
        <v-list
          :finished="finished"
          :finishedText="finishedText"
          @onLoad="onLoad"
          ref="list"
        >
          <div
            class="list-content"
            v-for="(item, index) in listData"
            :key="index"
            @click="toRouteTitle(item)"
          >
            <div class="list-flex">
              <div class="image">
                <img :src="item.activityPicture" alt="" />
              </div>
              <div class="wrapper">
                <div class="wrapper-title">
                  <span>{{ item.activityName }}</span>
                </div>
                <div class="wrapper-date start">
                  <span>活动开始时间：{{ item.startDate }}</span>
                </div>
                <div class="wrapper-date end">
                  <span>活动结束时间：{{ item.endDate }}</span>
                </div>
                <div class="wrapper-content">
                  <div class="content-tag">
                    <div class="iconText">
                      <div class="icon">
                        <img src="../img/newPeople.png" />
                      </div>
                      <div class="text">
                        <span>{{ item.applyUserCount || 0 }}</span>
                      </div>
                    </div>
                    <div class="iconText">
                      <div class="icon">
                        <img src="../img/comment.png" />
                      </div>
                      <div class="text">
                        <span>{{ item.commentCount || 0 }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="operate">
                <div
                  :class="[
                    'btn',
                    {
                      applying: item.activityStatus == 2,
                      going: item.activityStatus == 4,
                      back: item.hasFeedback && item.hasFeedback == 1,
                    },
                  ]"
                >
                  <span>{{
                    getOption(item.activityStatus, item.hasFeedback, item)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
      <div v-show="curTab.type == 2">
        <v-list
          :finished="finished2"
          :finishedText="finishedText2"
          @onLoad="getTableData"
          ref="list2"
        >
          <div
            class="list-content"
            v-for="(item, index) in tableData"
            :key="index"
            @click="toRouteDetail(item)"
          >
            <div class="list-flex">
              <div class="image">
                <img :src="item.picture" alt="" />
              </div>
              <div class="wrapper">
                <div class="wrapper-title">
                  <span>{{ item.assignmentName }}</span>
                </div>
                <div class="wrapper-row">
                  预估所需资金：<span>{{ item.estimatePrice }} 元</span>
                </div>
                <div class="wrapper-row">
                  任务认领所需人数：<span>{{ item.confinePersons }} 人</span>
                </div>
                <!-- <div class="wrapper-content">
                  <div class="content-tag">
                    <div class="iconText">
                      <div class="icon">
                        <img src="../img/newPeople.png" />
                      </div>
                      <div class="text">
                        <span>{{ item.applyUserCount || 0 }}</span>
                      </div>
                    </div>
                    <div class="iconText">
                      <div class="icon">
                        <img src="../img/comment.png" />
                      </div>
                      <div class="text">
                        <span>{{ item.commentCount || 0 }}</span>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="operate">
                <div
                  :class="[
                    'applyBtn',
                    {
                      applying: item.status == 2, // 1 认领中 2 已完成认领
                    },
                  ]"
                >
                  <span>{{ statusMap[item.status] }}</span>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { getActivityCategoryURL, getAssignmentListURL } from "../api.js";
import { activityStatusMap, statusMap } from "../map.js";

export default {
  name: "pioneerMission",
  data() {
    return {
      curTab: {
        name: "先锋任务",
        type: 1,
      },
      tabList: [
        {
          name: "先锋任务",
          type: 1,
        },
        {
          name: "金邻在线",
          type: 2,
        },
      ],
      activityStatusMap,
      statusMap,
      isLoading: false,
      listData: [], // 列表数据集合
      finished: true, // 列表是否加载完成 true 的时候自动请求onLoad
      finishedText: "没有更多了", // 列表加载完成后的提示语
      curPage: 1,
      pageSize: 10,

      tableData: [], // 金邻在线数据
      finishedText2: "没有更多了", // 列表加载完成后的提示语
      finished2: true, // 列表是否加载完成
      curPage2: 1,
      pageSize2: 10,

      /* v-dialog */
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  beforeRouteEnter(to, form, next) {
    if (form.name === "batUnitDetail") {
      next((vm) => {
        vm.curTab = {
          name: "联建单位",
          type: 2,
        };
      });
    } else {
      next();
    }
  },
  created() {
    this.mobile = this.$route.query.mobile;
    this.onLoad();
    this.getTableData();
  },
  methods: {
    changeTab(item) {
      this.curTab = item;
    },
    // 先锋任务跳转详情页
    toRouteTitle(item) {
      let params = {
        activityId: item.activityId,
        type: "金钟党建",
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/xubPages/pioneerMissionDetail/index?params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    // 金邻在线跳转详情页
    toRouteDetail(item) {
      let params = {
        id: item.id,
        type: "金钟党建",
      };
      wx.miniProgram.navigateTo({
        url: `/xubPages/neighborOnlineDetail/index?params=${JSON.stringify(
          params
        )}`,
      });
    },
    onRefresh() {
      if (this.curTab.type == 1) {
        this.curPage = 1;
        // this.listData = [];
        this.onLoad();
      } else if (this.curTab.type == 2) {
        this.curPage2 = 1;
        // this.tableData = [];
        this.getTableData();
      }
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getActivityCategoryURL, {
          params: {
            // tenantId: this.tenantId,
            // houseId: this.houseId || this.communityId || undefined,
            // userId: this.userId,
            // status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            // topicType: 43, // 40 睦邻汇
            // curPage: this.curPage,
            // pageSize: this.pageSize,
            // sortField: "NEW", // 排序方式， 最热 or 最新

            // activityStatus: 2, // 活动状态
            activityCategory: 102, // 先锋任务
            userId: this.userId,
            houseId: this.houseId || this.communityId || undefined,
            tenantId: this.tenantId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                res.data.records.forEach((item) => {
                  if (item.activityPicture) {
                    item.activityPicture = this.$handleImg(
                      344,
                      240,
                      item.activityPicture
                    );
                  }
                });
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.activityId);
                res.data.records.forEach((item) => {
                  if (item.activityPicture) {
                    item.activityPicture = this.$handleImg(
                      344,
                      240,
                      item.activityPicture
                    );
                  }
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.activityId) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 按钮名称
    getOption(status, hasFeedback, item) {
      if (hasFeedback && hasFeedback == 1) {
        return "活动回顾";
      }
      if (item.applyUserCount >= item.limitCount && item.limitCount != 0) {
        return "报名已满";
      }
      console.log(status, activityStatusMap);
      return activityStatusMap[status] || "";
    },

    // getTableData() {
    //   this.$axios
    //     .get(getAssignmentListURL, {
    //       params: {
    //         tenantId: this.tenantId,
    //         houseId: this.houseId || this.communityId || undefined,
    //         // userId: this.userId,
    //         curPage: this.curPage2,
    //         pageSize: this.pageSize2,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.code != 200) {
    //         this.finishedText2 = "";
    //         this.finished2 = true;
    //       } else {
    //         if (this.curPage2 >= res.data.pages) {
    //           this.finished2 = true;
    //         } else {
    //           this.finished2 = false;
    //           this.$refs.list.loading = false;
    //         }
    //         this.finishedText2 = "没有更多了";
    //         if (res.data.records.length === 0) {
    //           this.tableData = [];
    //           // this.finishedText = "";
    //           return;
    //         }
    //         if (res.code === 200 && res.data && res.data.records) {
    //           if (this.curPage2 === 1) {
    //             this.tableData = res.data.records;
    //           } else {
    //             const list = this.tableData.map((v) => v.id);
    //             res.data.records.forEach((item) => {
    //               // 如果请求数据不重复，则添加进listData
    //               list.includes(item.id) || this.tableData.push(item);
    //             });
    //           }
    //           this.curPage2++;
    //         }
    //       }
    //     })
    //     .catch(() => {
    //       this.finishedText2 = "";
    //       this.finished2 = true;
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //     });
    // },

    // 金邻在线数据
    getTableData() {
      this.$axios
        .get(getAssignmentListURL, {
          params: {
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.success) {
            res.data.forEach((item) => {
              if (item.picture) {
                item.picture = this.$handleImg(344, 240, item.picture);
              }
            });
            this.tableData = res.data;
            if (res.data.length == 0) {
              this.finishedText2 = "";
            }
          }
        })
        .finally(() => {
          this.finished2 = true;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
// 隐藏所有滚动条
::-webkit-scrollbar {
  display: none;
}
.page {
  position: relative;
  // min-height: 100%;
  background: rgba(249, 249, 249, 1);
  box-sizing: border-box;
  // border: 1px solid rgba(249, 249, 249, 1); // 解决子元素margin-top问题
  // padding-top: 132px;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  ::v-deep .van-pull-refresh {
    box-sizing: border-box;
    min-height: 100vh;
    padding-top: 132px;
  }

  .tab {
    display: flex;
    justify-content: space-around;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    background: #fff;
    .item {
      padding: 30px 0 30px;
      height: 40px;
      font-size: 32px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
    }
    .active {
      font-weight: 600;
      color: rgba(254, 149, 20, 1);
      position: relative;
      &::after {
        content: "";
        width: 120px;
        height: 8px;
        background: rgba(254, 149, 20, 1);
        border-radius: 4px;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .manager {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 248px;
    margin: 0 32px 64px;
    padding: 0px 48px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    .avatar {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      object-fit: cover;
      overflow: hidden;
    }
    .name {
      margin-left: 32px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 36px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }
    .mobile {
      width: 40px;
      height: 52px;
    }
    .weixin {
      margin-left: 32px;
      width: 52px;
      height: 52px;
    }
  }

  .title {
    padding: 0 30px;
    font-size: 36px;
    font-weight: bold;
    color: rgba(26, 28, 52, 1);
  }
  .list-content {
    display: flex;
    align-items: center;
    overflow: auto;
    margin: 0 30px;
    .list-flex {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 688px;
      min-width: 688px;
      margin: 16px 0px 16px;
      border-radius: 16px;
      box-shadow: 0px 4px 16px 0px rgba(227, 277, 277, 0.5);
      background: #fff;
      .image {
        box-sizing: border-box;
        border-radius: 16px 16px 0px 0px;
        margin-bottom: 14px;
        height: 240px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 0px 40px 24px;
        .wrapper-title {
          flex: 1;
          width: 100%;
          max-width: 360px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 32px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 20px;
        }
        .wrapper-date {
          margin-top: 10px;
          display: flex;
          align-items: center;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 8px;
          &::before {
            content: "";
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #ffb92d;
            margin-right: 14px;
          }
          &.start {
            &::before {
              background: #ffb92d;
            }
          }
          &.end {
            &::before {
              background: #367bf4;
            }
          }
        }
        .wrapper-row {
          margin-top: 10px;
          display: flex;
          align-items: center;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 8px;
          span {
            color: #fdab3c;
          }
        }
        .wrapper-description {
          display: flex;
          word-wrap: break-word;
          word-break: break-all;
          margin: 10px 32px 24px 0;
          font-size: 24px;
          color: rgba(180, 180, 180, 1);
          /* start webkit 内核支持多行省略 */
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          /*  end */
        }
        .wrapper-content {
          margin-top: 60px;
          font-size: 24px;
          color: rgba(180, 180, 180, 1);
          display: flex;
          .content-tag {
            display: flex;
            .iconText {
              display: flex;
              color: #a8a8a8;
              align-items: center;

              &:not(:last-child) {
                margin-right: 48px;
              }
              .icon {
                display: flex;
                align-items: center;
                &.icon-up {
                  > img {
                    margin-top: 4px;
                  }
                }
                > img {
                  width: 24px;
                  height: 24px;
                }
              }

              .text {
                margin-left: 8px;
                display: flex;
                align-items: center;
                height: 36px;
                line-height: 36px;

                span {
                  &.hasPraised {
                    color: #ff853a;
                  }

                  font-size: 24px;
                  line-height: 1;
                }
              }
            }
          }
        }
      }
      .operate {
        position: absolute;
        bottom: 0;
        right: 0;
        .btn,
        .applyBtn {
          box-sizing: border-box;
          height: 64px;
          // border: 4px solid #fe6f16;
          border-radius: 16px 0 16px 0;
          // color: #fe6f16;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 24px;
          min-width: 194px;
          background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
          color: #a7a7a7ff;
          box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
          > span {
            font-size: 26px;
            font-weight: bold;
            line-height: 1;
          }
        }

        .applyBtn {
          background: linear-gradient(180deg, #ffbe53 0%, #fd9f2c 100%);
          color: #ffffff;
          &.applying {
            background: linear-gradient(180deg, #9ec1ff 0%, #367bf4 100%);
            color: #ffffff;
          }
        }
        .btn.applying {
          background: linear-gradient(180deg, #9ec1ff 0%, #367bf4 100%);
          color: #ffffff;
        }
        .btn.going {
          background: linear-gradient(180deg, #fdc76f 0%, #faae3e 100%);
          color: #ffffff;
        }
        .btn.back {
          background: linear-gradient(180deg, #fdc76f 0%, #faae3e 100%);
          color: #ffffff;
        }
      }
    }
    .remove {
      width: 68px;
      height: 68px;
      margin-left: 20px;
    }
  }

  .table {
    margin: 0 32px;
    padding: 44px 36px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    font-size: 24px;
    word-wrap: break-word;
    word-break: break-all;
    .thead {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      background: rgba(216, 216, 216, 1);
      padding: 12px 0;
      text-align: center;
      border-radius: 8px;
      white-space: normal;
    }
    .thead,
    .th {
      display: flex;
      align-items: center;
      padding: 12px 0;
      text-align: center;
      .name,
      .mobile,
      .area {
        box-sizing: border-box;
        padding: 0 10px;
      }
      .name {
        width: 20%;
      }
      .mobile {
        width: 35%;
      }
      .area {
        width: 45%;
      }
    }
    .tbody {
      .th .area {
        text-align: left;
      }
    }
  }
}
</style>

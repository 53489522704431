// 获取用户个人信息
const getUserInfoUrl = `/gateway/hc-mini/user/mini/user-info`;

// 查询 - 体检 - 列表
const getMedicalRecordListUrl = `/gateway/hc-health/physicalExamination/getPhysicalExaminationList`;

// 查询 - 体检 - 列表
const getMedicalRecordDetailUrl = `/gateway/hc-health/physicalExamination/getPhysicalExaminationDetail`;

export { getUserInfoUrl, getMedicalRecordListUrl, getMedicalRecordDetailUrl };

// 话题列表接口 32 资讯发布,33 树标杆,34 干实事,35 定制度
const getTopicListUrl = `/gateway/hc-neighbour/topic/getTopicList`;
// 查询用户提交过的实名认证信息
const getCertifiedRealNameInfoUrl = `/gateway/blade-user/user/getCertifiedRealNameInfo`;
// 获取用户个人信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
// 查询身份认证初始化信息
const getUserSubjectAuditInfoUrl = `/gateway/hc-govern/miniapi/votehall/getUserSubjectAuditInfo`;
// 提交身份认证信息
const saveUserSubjectAuditInfoUrl = `/gateway/hc-govern/miniapi/votehall/saveUserSubjectAuditInfo`;
// 发起提议
const proposeEventUrl = `/gateway/hc-govern/miniapi/votehall/proposeEvent`;
// 我的提议列表
const getMyProposeEventListUrl = `/gateway/hc-govern/miniapi/votehall/getMyProposeEventList`;
// 删除我的提议
const deleteMyProposeEventUrl = `/gateway/hc-govern/miniapi/votehall/deleteMyProposeEvent`;
// 我的提议详情
const getMyProposeEventDetailUrl = `/gateway/hc-govern/miniapi/votehall/getMyProposeEventDetail`;
// 议事厅列表
const getVoteHallEventListUrl = `/gateway/hc-govern/miniapi/votehall/getVoteHallEventList`;
// 收藏仪事
const pickEventUrl = `/gateway/hc-govern/miniapi/votehall/pickEvent`;
// 查看进度
const getEventProcessUrl = `/gateway/hc-govern/miniapi/votehall/getEventProcess`;
// 干部列表查询
const getCadreListUrl = `/gateway/hc-govern/miniapi/votehall/getCadreList`;
// 投票接口
const voteEventUrl = `/gateway/hc-govern/miniapi/votehall/voteEvent`;
export {
  getCadreListUrl,
  getTopicListUrl,
  getCertifiedRealNameInfoUrl,
  userInfoUrl,
  getUserSubjectAuditInfoUrl,
  saveUserSubjectAuditInfoUrl,
  proposeEventUrl,
  getMyProposeEventListUrl,
  deleteMyProposeEventUrl,
  getMyProposeEventDetailUrl,
  getVoteHallEventListUrl,
  pickEventUrl,
  getEventProcessUrl,
  voteEventUrl,
};

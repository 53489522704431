<template>
  <div class="health-hut-detail">
    <div class="tijian-info-item" v-for="(item, index) in details" :key="'xq' + index">
      <div class="label-value-box">体检项目名称：{{ item.projectName || '-' }}</div>
      <div class="label-value-box">检查结果：{{ item.result || '-' }}{{ item.unit || '-' }}</div>
      <div class="label-value-box">参考值：{{ item.reference || '-' }}{{ item.unit || '-' }}</div>
      <div class="label-value-box">检查日期：{{ item.checkDate || '-' }}</div>
    </div>
  </div>
</template>

<script>
import { getMedicalRecordDetailUrl } from './api.js';

export default {
  name: 'healthHutDetail',
  components: {},
  props: {},
  model: {},
  data() {
    return {
      details: []
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getDetail();
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    getDetail() {
      this.$axios.get(getMedicalRecordDetailUrl, { params: { checkinId: this.$route.query.checkinId } }).then(res => {
        if (res && res.code === 200) {
          res.data = Array.isArray(res.data) ? res.data : [];
          this.details = res.data;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.health-hut-detail {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px 30px;
  background-color: #f7f7f7;
  .tijian-info-item {
    box-sizing: border-box;
    margin-bottom: 30px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    padding: 16px 24px;
    font-size: 28px;
    line-height: 46px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>

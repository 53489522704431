<template>
  <div class="page">
    <div class="top_box">
      <div class="in_box">
        <img
          style="width: 100%; height: 100%"
          src="./img/teacherBanner.png"
          alt=""
        />
      </div>
    </div>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div
        class="item"
        @click="goDetail('teacherDetail', item)"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="top">
          <div class="infos">{{ item.name }}</div>
          <div class="imgs">
            <div v-if="item.sex == 2">
              <img class="icon" src="./img/female_icon.png" alt="" />
            </div>
            <div v-else>
              <img class="icon" src="./img/male_icon.png" alt="" />
            </div>
          </div>
        </div>
        <div class="txt" v-html="item.introduction"></div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { getTeacherList } from "./api.js";
export default {
  name: "teacherLibrary",
  data() {
    return {
      list: [],
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      status: "1", //发布状态0：待发布，1：已发布
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    // this.getList()
  },
  methods: {
    onLoad() {
      this.getList();
    },
    // 获取师资列表
    getList() {
      let params = {
        name: "",
        // communityId: this.communityId,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        // tenantId: this.tenantId,
        status: this.status,
      };
      this.$axios
        .get(`${getTeacherList}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.list.push(item);
            });
          } else {
          }
        });
    },
    // 跳转至师资详情页面
    goDetail(path, item) {
      this.$router.push({
        path: path,
        query: item,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  background: #fff;

  .item {
    margin: 40px auto 0;
    padding: 35px 0 35px 16px;
    box-sizing: border-box;
    width: 672px;
    height: 214px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 17px;

    .txt {
      margin-top: 12px;
      font-size: 29.66px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .top {
      width: 375px;

      .imgs {
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;

        .icon {
          width: 32px;
          height: 32px;
        }
      }

      .infos {
        display: inline-block;
        font-size: 34.61px;
        font-weight: 600;
        color: #1a1c34;
      }
    }
  }

  .top_box {
    width: 100%;
    height: 320px;
    margin-bottom: 12px;

    .in_box {
      background-color: #64a7fb;
      z-index: 1;
      width: 100%;
      height: 320px;
      position: fixed;
    }
  }
}
</style>

<template>
  <div class="section">
    <div class="section-info">
      <div class="info-picture">
        <img :src="detail.imageUrl" alt="" />
      </div>
      <div class="info-content">
        <div class="content-flex">
          <div class="content-title">{{ detail.title }}</div>
          <div class="content-type" @click="toRouter">
            <span>编辑</span>
          </div>
        </div>
        <div class="content-time">
          <span>已建立商圈{{ detail.days }}天</span>
        </div>
      </div>
    </div>

    <div class="section-detail">
      <div class="detail-item">
        <div class="item-label">交易笔数</div>
        <div class="item-value">{{ detail.total }}</div>
      </div>
      <div class="detail-item">
        <div class="item-label">销售额</div>
        <div class="item-value">{{ detail.totalPrice }}</div>
      </div>
      <div class="detail-item">
        <div class="item-label">月销量</div>
        <div class="item-value">{{ detail.monthlySales }}</div>
      </div>
    </div>
    <div class="section-title">我的商品</div>
    <div class="section-detail">
      <div class="detail-item">
        <div class="item-value">{{ detail.putawaying }}</div>
        <div class="item-label">销售中</div>
      </div>
      <div class="detail-item">
        <div class="item-value">{{ detail.noPutaway }}</div>
        <div class="item-label">待上架</div>
      </div>
      <div class="detail-item">
        <div class="item-value">{{ detail.putawayed }}</div>
        <div class="item-label">已下架</div>
      </div>
    </div>
    <div class="section-title">我的账号</div>
    <div class="section-account">
      <div class="address">
        <span>账号地址：</span>{{ detail.accountAddress }}
      </div>
      <div class="account"><span>账户名：</span> {{ detail.loginName }}</div>
      <!-- <div class="password"><span>默认密码：</span> {{ detail.loginPwd }}</div> -->
      <div class="password">
        <span>账号密码：</span>默认初始密码为首次申请时的身份证号后6位
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getMyMallShopURL } from "./api.js";

export default {
  name: "businessCircle",
  data() {
    return {
      detail: {
        consumedPonints: 0,
        noConsumedPonints: 0,
        total: 0,
        days: 0,
        title: "",
        putawayed: 0,
        noPutaway: 0,
        putawaying: 0,
      },
    };
  },
  async created() {
    this.getMyBusiness();
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    toRouter() {
      this.$router.push({
        name: "bcnServiceApply",
        query: {
          isEdit: 1,
        },
      });
    },
    async getMyBusiness() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
      };

      let res = await this.$axios.get(`${getMyMallShopURL}`, {
        params,
      });
      if (res.code == 200) {
        this.detail = res.data;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.section {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: PingFangSC-Regular, PingFang SC;
  background: #f9f9f9;
  .section-info {
    display: flex;
    // width: 100%;
    background: #ffffff;
    padding: 32px 36px 32px;
    margin-bottom: 36px;
    .info-picture {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      overflow: hidden;
      > img {
        &:extend(.common-img);
        width: 100%;
        height: 100%;
      }
    }
    .info-content {
      flex: 1;
      padding-left: 30px;
      box-sizing: border-box;
      .content-flex {
        white-space: normal;
        .content-title {
          display: inline;
          vertical-align: middle;
          font-size: 32px;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.85);
          margin-right: 14px;
        }
        .content-type {
          box-sizing: border-box;
          display: inline;
          white-space: nowrap;
          vertical-align: middle;
          padding: 4px 12px;
          font-size: 24px;
          line-height: 36px;
          border-radius: 6px;
          color: #fe9616;
          background: #fff4e7;
          border: 2px solid #fe9616;
        }
      }
      .content-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .section-detail {
    margin: 0 32px;
    // width: 100%;
    display: flex;
    .detail-item {
      &:not(:first-child) {
        margin-left: 2%;
      }
      width: 32%;
      min-width: 32%;
      background: #ffffff;
      box-shadow: 0px 4px 12px 0px rgba(236, 236, 236, 0.5);
      border-radius: 4px;
      padding: 24px 0;
      box-sizing: border-box;
      text-align: center;
      display: flex;
      flex-direction: column;
      .item-label {
        font-size: 26px;
        color: rgba(0, 0, 0, 0.25);
        margin: 6px 0;
      }
      .item-value {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        font-size: 38px;
        line-height: 48px;
        font-weight: bold;
        color: #fe9616;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
  .section-title {
    font-size: 34px;
    color: rgba(0, 0, 0, 0.85);
    margin: 30px 32px;
  }
  .section-account {
    min-height: 358px;
    background: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(236, 236, 236, 0.5);
    border-radius: 4px;
    padding: 30px;
    margin: 0 32px;
    font-size: 28px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 50px;
    white-space: normal;
    word-break: break-all;
    word-wrap: break-word;
    // > div {
    //   margin-bottom:
    // }
    span {
      font-weight: bold;
    }
  }
}
</style>

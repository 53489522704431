<template>
  <div class="section">
    <div class="section-tabs">
      <v-tabs v-model="status" sticky @change="changeTabs">
        <v-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :title="tab.title"
          :name="tab.name"
          titleClass="tab-title"
        >
          <div class="section-list">
            <v-list
              :finished="finished"
              @onLoad="onLoad"
              :finishedText="finishedText"
              ref="list"
            >
              <div
                class="section-flex-item"
                v-for="(item, index) in listData"
                :key="index"
                @click="toDetail(item)"
              >
                <div class="item-time">
                  <span>下单时间：{{ item.insertTime }}</span>
                  <span>{{ statusMap[item.status] }}</span>
                </div>
                <div class="item-flex">
                  <div class="item-picture">
                    <img :src="item.picture" alt="" />
                  </div>
                  <div class="item-detail">
                    <div class="item-detail-title">
                      <span>{{ item.title }}</span>
                    </div>
                    <div class="item-detail-price">
                      <span>{{ item.price }}元</span>
                      <span>x{{ item.orderGoodsNum }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </v-list>
          </div>
        </v-tab>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getLeaderAndShopOrderListURL } from "./api.js";

export default {
  data() {
    return {
      status: 1, // 1 待核销 2 已完成
      tabs: [
        {
          title: "待发货",
          name: "1",
        },
        {
          title: "已完成",
          name: "2",
        },
        {
          title: "已退款",
          name: "3",
        },
      ],
      statusMap: {
        1: "待发货",
        2: "已完成",
        3: "已退款",
      },
      /* v-list */
      finishedText: "没有更多了",
      finished: true,
      listData: [],
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.onRefresh();
  },
  methods: {
    changeTabs() {
      const _this = this;
      setTimeout(() => {
        _this.onRefresh();
      });
    },
    // 重置商户列表
    onRefresh() {
      this.curPage = 1;
      this.listData = [];
      this.finishedText = "没有更多了";
      this.onLoad();
    },
    // 获取商户订单列表
    onLoad() {
      let params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        type: 4,
        status: this.status,
        userId: this.userId,
      };
      this.$axios
        .get(getLeaderAndShopOrderListURL, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        });
    },
    // 点击前往商品详情
    toDetail(item) {
      this.$router.push({
        name: "bcnServiceBusinessOrderDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.section {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f9f9f9;
  padding-bottom: calc(
    20px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    20px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  font-family: PingFangSC-Regular, PingFang SC;
  .section-tabs {
    ::v-deep .tab-title {
      font-weight: bold;
    }

    .section-list {
      box-sizing: border-box;
      width: 100%;
      padding: 0 28px;
      .section-flex-item {
        margin-top: 30px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px;
        .item-time {
          padding: 22px 20px 16px;
          font-size: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
          display: flex;
          span:first-child {
            flex: 1;
          }
        }
        .item-flex {
          padding: 22px 20px;
          border-top: 2px solid rgba(0, 0, 0, 0.06);
          display: flex;
          align-items: center;
          .item-picture {
            width: 160px;
            min-width: 160px;
            height: 160px;
            border-radius: 16px;
            overflow: hidden;
            margin-right: 28px;
            > img {
              &:extend(.common-img);
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .item-detail {
            flex: 1;
            .item-detail-title {
              font-size: 32px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              line-height: 44px;
              word-break: break-all;
              word-wrap: break-word;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .item-detail-price {
              margin-top: 8px;
              font-size: 32px;
              font-weight: 400;
              line-height: 44px;
              color: rgba(0, 0, 0, 0.5);
              display: flex;
              justify-content: space-between;
              span:first-child {
                flex: 1;
                color: #fe9616;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="smartParking">
    <div class="map" :id="id"></div>
    <div class="overlay" v-if="loading">
      <img src="../../img/loading_blur.gif" alt="" />
      <span>定位中，30s左右...</span>
    </div>
  </div>
</template>

<script>
import { sendLocationCommandURL, getLocationDetailsURL } from "../../api";

export default {
  components: {},
  data() {
    return {
      id: `container_${new Date().getTime()}_${Math.random * 1000}`,
      map: null,
      loading: true,
      searchParam: {
        deviceId: "",
        time: "", // 定位时间
      },
      timer: null,
      pointList: [
        {
          name: "养老手环定位",
          des: "养老手环定位",
          position: [],
        },
      ],
      markers: [],
    };
  },
  created() {
    // this.sendLocationCommand();
  },
  beforeDestory() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  async mounted() {
    this.searchParam.deviceId = this.$route.query.deviceId;
    await this.sendLocationCommand();
    this.initMap();
  },
  methods: {
    // 发送定位指令
    async sendLocationCommand() {
      let res = await this.$axios.post(
        sendLocationCommandURL,
        {
          deviceId: this.searchParam.deviceId,
          userId: this.userId,
        },
        {
          headers: {
            noLoading: true,
          },
        }
      );
      if (res.code == 200 && res.success) {
        this.searchParam.time = res.data.time;
        if (this.searchParam.time) {
          this.timer = setInterval(() => {
            this.getLocationDetails();
          }, 15000);
        } else if (res.data.lon && res.data.lat) {
          this.loading = false;
          this.pointList[0].position = [res.data.lon, res.data.lat];
        }
      } else {
        this.loading = false;
      }
    },

    // 获取主动定位结果详情
    async getLocationDetails() {
      let res = await this.$axios.get(getLocationDetailsURL, {
        params: {
          deviceId: this.searchParam.deviceId,
          time: this.searchParam.time,
        },
        headers: {
          noLoading: true,
        },
      });
      if (res.code == 200 && res.success) {
        if (res.data.lon && res.data.lat) {
          this.loading = false;
          this.pointList[0].position = [res.data.lon, res.data.lat];
          clearInterval(this.timer);
          this.initMap();
        }
      } else {
        this.loading = false;
      }
    },
    initMap() {
      if (this.map) {
        this.map.destroy();
      }
      if (window.AMap) {
        // console.log(
        //   this.pointList[0].position.length > 0
        //     ? this.pointList[0].position
        //     : ["121.62454", "29.860258"]
        // );
        let params = {};
        if (this.pointList[0].position.length > 0) {
          params = {
            center: this.pointList[0].position,
          };
        }
        this.map = new window.AMap.Map(this.id, {
          zoom: "17",
          ...params,
          // center:
          //   this.pointList[0].position.length > 0
          //     ? this.pointList[0].position
          //     : ["121.62454", "29.860258"], // 宁波经纬度
        });
        this.pointList.forEach((data, index) => {
          this.addMarker(data, index);
        });
      }
    },
    addMarker(marker, index) {
      if (window.AMap) {
        const _this = this;
        const icon = new window.AMap.Icon({
          size: new window.AMap.Size(22, 28),
          image: require("../../img/place_green.png"),
          imageSize: new window.AMap.Size(22, 28),
        });
        _this.markers[index] = new window.AMap.Marker({
          icon,
          position: marker.position,
          offset: new window.AMap.Pixel(-10, -20),
        });
        // _this.markers[index].on("click", () => {
        //   this.markers.forEach((item) => {
        //     item.setContent("");
        //     item.setIcon(icon);
        //   });
        // });
        _this.map.add(_this.markers[index]);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.smartParking {
  width: 100%;
  height: 100%;
  .overlay {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    color: rgb(0, 148, 255);
    // color: #969799;
    img {
      width: 48px;
      height: 48px;
      margin-right: 20px;
    }
    span {
      font-size: 32px;
      // font-weight: bold;
    }
  }
  .map {
    height: 100%;
    width: 100%;
  }
}
</style>

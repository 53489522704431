<template>
  <div class="page">
    <div class="item">
      <div class="item-t">
        <p>恭喜你</p>
        <p>本次获得分数</p>
      </div>
      <div class="item-c">
        <span>{{ totalPoints ? totalPoints : 0 }}</span>
        <span>分</span>
      </div>
      <div class="item-b" @click="back">返回</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "totalPoints",
  components: {},
  props: {},
  data() {
    return {
      totalPoints: "",
    };
  },
  created() {},
  mounted() {
    const { totalPoints } = this.$route.params;
    this.totalPoints = totalPoints;
  },
  methods: {
    //返回
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-image: url("./img/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  padding: 26px 32px 0;
  .item {
    padding: 108px 66px 90px 70px;
    box-sizing: border-box;
    width: 100%;
    height: 798px;
    background: #ffffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .item-t {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
      p {
        &:first-child {
          margin-bottom: 12px;
        }
      }
    }
    .item-c {
      display: flex;
      align-items: baseline;
      span {
        font-size: 148px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffd036;
        line-height: 206px;
        &:last-child {
          font-size: 24px;
        }
      }
    }
    .item-b {
      width: 100%;
      height: 66px;
      background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 42px;
    }
  }
}
</style>

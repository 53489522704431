<!-- 公告栏组件 -->
<template>
  <div class="notice-bar" @click="tipClick">
    <!-- <div class="notice-bar__icon" v-if="showIcon">
      <img src="../assets/images/patient/homepage/tip.png" />
    </div> -->
    <div ref="wrap" class="notice-bar__wrap">
      <div ref="content" class="notice-bar__content" :style="contentStyle">
        <template v-if="Array.isArray(text)">
          <span v-for="(item, index) in text" :key="index">{{ item }}</span>
        </template>
        <template v-else>
          <span>{{ text }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoticeBar",
  props: {
    text: {
      type: [String, Array],
      default: "",
    },
    speed: {
      type: Number,
      default: 50,
    },
    defaultWidth: {
      type: Number,
      default: 375,
    },
  },
  data() {
    return {
      contentStyle: {
        transitionDuration: "0s",
        transform: "translateX(0px)",
      },
      wrapWidth: 0,
      contentWidth: 0,
      time: 0,
      timer: null,
      convertSpeed: 1,
    };
  },
  mounted() {
    if (this.text) {
      this.init();
    }
  },
  watch: {
    text(val) {
      this.init();
    },
  },
  methods: {
    init() {
      const _width = window.innerWidth; // 以像素为单位的窗口的内部宽度
      this.convertSpeed = (_width / this.defaultWidth) * this.speed; // 根据分辨率转化成不同的速度
      this.wrapWidth = this.$refs.wrap.offsetWidth; // 测量包含元素的border/padding/scrollbar/width的值
      this.contentWidth = this.$refs.content.offsetWidth; //
      this.startAnimate();
      this.timer = setInterval(() => {
        this.startAnimate();
      }, this.time * 1000);
      this.$once("hook:beforeDestroy", () => {
        clearInterval(this.timer);
        this.timer = null;
      });
    },
    startAnimate() {
      this.contentStyle.transitionDuration = "0s";
      this.contentStyle.transform = "translateX(" + this.wrapWidth + "px)";
      this.time = (this.wrapWidth + this.contentWidth) / this.convertSpeed;
      setTimeout(() => {
        this.contentStyle.transitionDuration = this.time + "s";
        this.contentStyle.transform =
          "translateX(-" + this.contentWidth + "px)";
      }, 200);
    },
    tipClick() {
      this.$emit("click");
    },
  },
};
</script>
<style scoped lang="less">
// @baseFontSize: 75; //基于视觉稿横屏尺寸/100得出的基准font-size
// .px2rem(@name, @px) {
//   @{name}: @px / @baseFontSize * 1rem;
// }
.notice-bar {
  position: relative;
  width: 100%;
  // .px2rem(height, 80);
  // .px2rem(padding-left, 0);
  // .px2rem(padding-right, 0);
  // .px2rem(font-size, 28);
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #fe9616;
  height: 40px;
  // line-height: 34px;
  // font-weight: 400;
  color: #868daa;
  display: flex;
  align-items: center;
  // .notice-bar__icon {
  //   .px2rem(width, 56);
  //   .px2rem(height, 28);
  //   .px2rem(margin-right, 20);
  //   img {
  //     width: 100%;
  //   }
  // }
  .notice-bar__wrap {
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    overflow: hidden;
    .notice-bar__content {
      position: absolute;
      white-space: nowrap;
      transition-timing-function: linear;
      span {
        display: inline-block;
      }
      span:not(:first-child) {
        margin-left: 60px;
      }
    }
  }
}
</style>

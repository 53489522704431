<template>
  <div class="page">
    <div class="checkLine" @click.stop="toPath('viewProgress')">
      <img src="./img/arrow3.png" alt="" />
      查看进度
    </div>
    <div class="contentBox">
      <div class="title">决议内容</div>
      <div class="item">
        <div class="left">时间</div>
        <div class="right">
          {{ detail.jueyiStartTime }}~{{ detail.jueyiEndTime }}
        </div>
      </div>
      <div class="item">
        <div class="left">地点</div>
        <div class="right">{{ detail.voteAddr }}</div>
      </div>
      <div class="item">
        <div class="left">主持人</div>
        <div class="right">{{ detail.voteHost }}</div>
      </div>
      <div class="item">
        <div class="left">应到人数</div>
        <div class="right">{{ detail.votePeopleNumber }}</div>
      </div>
      <div class="item">
        <div class="left">参会主体</div>
        <div class="right">{{ format(detail.voteSubject) }}</div>
      </div>
      <div class="item">
        <div class="left">会议议题</div>
        <div class="right">{{ detail.voteTitle }}</div>
      </div>
      <div class="item">
        <div class="left">记录人</div>
        <div class="right">{{ detail.voteRecorder }}</div>
      </div>
      <div class="item-content">
        <div class="title">会议内容</div>
        <v-input
          type="textarea"
          placeholder="请输入会议内容"
          :showWordLimit="true"
          :maxlength="200"
          disabled
          v-model="detail.voteContent"
        ></v-input>
      </div>
    </div>
    <div class="signBox">
      <div class="title">
        投票签字
        <div class="sfrzbtn" @click="toPath('identityAuthenticationTab')">
          身份认证
        </div>
      </div>
      <div class="center">
        决议结束时间
        <div>{{ detail.jueyiEndTime }}</div>
      </div>
      <div class="radioBox">
        <div
          class="item"
          @click="signSelect(item, index)"
          v-for="(item, index) in signList"
          :key="index"
        >
          <img v-if="item.checked" src="./img/select.png" alt="" />
          <img v-else src="./img/select-not.png" alt="" />
          {{ item.label }}
        </div>
      </div>
      <div class="in_sign_box" ref="canvasHW">
        <signCanvas
          ref="esign"
          :width="800"
          :height="354"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
        />
      </div>
    </div>
    <div class="resultBox" v-if="detail.status != 7">
      <div class="title">结果公示</div>
      <div class="item">
        <div class="left">公示结果</div>
        <div class="right" v-if="detail.status == 9" style="color: #67c403">
          通过
        </div>
        <div class="right" v-else style="color: red">不通过</div>
      </div>
      <div class="item-content" v-if="detail.gongshiContent">
        <div class="title">公示内容</div>
        <v-input
          v-model="detail.gongshiContent"
          type="textarea"
          placeholder="请输入公示内容"
          :showWordLimit="true"
          :maxlength="200"
          disabled
        ></v-input>
      </div>
      <div class="item" v-if="detail.gongshiEndTime">
        <div class="left">公示结束时间</div>
        <div class="right" style="color: rgba(0, 0, 0, 0.85)">
          {{ detail.gongshiEndTime }}
        </div>
      </div>
      <div
        class="item"
        style="justify-content: flex-start; border: none"
        v-if="detail.gongshiAnnexFiles"
      >
        <div class="left">公示附件</div>
        <div class="right" style="color: #0091ff; border: none">
          <div
            v-for="(item, index) in detail.gongshiAnnexFiles.split(',')"
            :key="index"
            @click="openFile(item)"
          >
            {{ getFlieName(item) }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="btn"
      v-if="detail.status == 7 && flag && detail.canVote"
      @click="submit"
    >
      {{ btnStr }}
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { base64toFile } from "@/utils/utils.js";
import signCanvas from "@/components/bussiness/signCanvas";
import { mapState } from "vuex";
import { getMyProposeEventDetailUrl, voteEventUrl } from "./api.js";
export default {
  name: "proceedingsDetail",
  data() {
    return {
      isCrop: false,
      lineWidth: 6,
      lineColor: "#979797",
      bgColor: "#D8D8D8",
      uploadUrl: "/gateway/blade-resource/oss/endpoint/put-file",
      signature: "",
      signList: [
        {
          label: "同意",
          checked: false,
          id: 1,
        },
        {
          label: "不同意",
          checked: false,
          id: 2,
        },
        {
          label: "弃权",
          checked: false,
          id: 3,
        },
      ],
      id: null,
      detail: {},
      info: {},
      voteType: null,
      flag: false,
      btnStr: "提交",
    };
  },
  components: {
    signCanvas,
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    async postSignData() {
      let params = {
        userId: this.userId,
        eventId: this.detail.id,
        signature: this.signature,
        voteType: this.voteType,
      };
      let res = await this.$axios.post(
        `${voteEventUrl}`,
        this.$qs.stringify(params)
      );
      if (res.code === 200) {
        this.$toast({
          message: res.msg,
          duration: 1000,
        });
        setTimeout(() => {
          this.getDetail();
        }, 1000);
      } else {
      }
    },
    submit() {
      if (!this.voteType) {
        this.$toast("请投票~");
        return;
      }
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          console.log(base64toFile(this.resultImg), "009");
          this.uploadImg(base64toFile(this.resultImg));
        })
        .catch((err) => {
          alert(err); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(`${this.uploadUrl}`, formData);
      if (res.code == 200) {
        this.signature = res.data.link;
        this.postSignData();
        console.log(this.signature);
      } else {
      }
    },
    openFile(fileUrl) {
      let domain = fileUrl.split("/");
      if (domain[2]) {
        domain = domain[0] + "//" + domain[2];
      } else {
        domain = "";
      }
      let url = fileUrl.replace(domain, window.location.origin);
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/pages/webview/index?url=${url}`,
        });
      }
    },
    getFlieName(fileUrl) {
      if (fileUrl) {
        let domain = fileUrl.split("/");
        console.log(domain);
        return domain[domain.length - 1];
      }
    },
    format(str) {
      if (str) {
        let list = str.split(",");
        list.forEach((item, index) => {
          if (item == "1") {
            list[index] = "党员";
          } else if (item == "2") {
            list[index] = "干部";
          } else if (item == "3") {
            list[index] = "村民代表";
          } else if (item == "4") {
            list[index] = "全体居民";
          }
        });
        return list.join(",");
      }
    },
    getDetail() {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      this.$axios
        .get(`${getMyProposeEventDetailUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.detail = res.data;
            let date = new Date().getTime();
            if (this.detail.jueyiStartTime && this.detail.jueyiEndTime) {
              if (
                new Date(this.detail.jueyiStartTime).getTime() < date &&
                date < new Date(this.detail.jueyiEndTime).getTime()
              ) {
                this.flag = true;
              }
            }
            // if (
            //   Date.parse(this.detail.jueyiStartTime) < Date.parse(date) &&
            //   Date.parse(date) < Date.parse(this.detail.jueyiEndTime)
            // ) {
            //   this.flag = true;
            // }
            if (this.detail.myVoteType) {
              this.btnStr = "再次提交";
              this.voteType = this.detail.myVoteType;
              this.signList.forEach((item) => {
                item.checked = false;
                if (item.id == this.detail.myVoteType) {
                  item.checked = true;
                }
              });
            }
          }
        });
    },
    signSelect(item, index) {
      if (this.detail.status == 7 && this.detail.canVote && this.flag) {
        this.signList.forEach((i) => {
          i.checked = false;
        });
        item.checked = !item.checked;
        this.voteType = item.id;
      }
    },
    toPath(name) {
      this.$router.push({
        name: name,
        query: {
          id: this.id,
        },
      });
    },
  },
};
</script>
<style lang="less">
.page {
  .van-field {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 16px;
  }
}
</style>
<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
  padding: 52px 30px;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
    border-radius: 10px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    line-height: 66px;
    margin: 56px auto;
  }
  .resultBox {
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(220, 220, 220, 0.5);
    border-radius: 16px;
    padding: 30px 30px 0;
    box-sizing: border-box;
    .item-content {
      padding-top: 50px;
      padding-bottom: 32px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      .title {
        padding-bottom: 20px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .item {
      display: flex;
      justify-content: space-between;
      height: 104px;
      line-height: 104px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      .left {
        padding-right: 50px;
        white-space: nowrap;
      }
      .right {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        text-align: right;
        flex: 1;
        overflow: hidden;
        div {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 2px solid #0091ff;
        }
      }
    }
    .title {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .signBox {
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(220, 220, 220, 0.5);
    border-radius: 16px;
    padding: 30px 30px 50px;
    box-sizing: border-box;
    margin-bottom: 22px;
    .in_sign_box {
      margin-top: 50px;
      width: 100%;
    }
    .radioBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        img {
          width: 34px;
          margin-right: 12px;
        }
      }
    }
    .center {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      display: flex;
      padding-bottom: 24px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      margin-bottom: 34px;
      div {
        flex: 1;
        text-align: right;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .title {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      padding-bottom: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sfrzbtn {
        color: red;
      }
    }
  }
  .contentBox {
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(220, 220, 220, 0.5);
    border-radius: 16px;
    padding: 30px 30px 50px;
    box-sizing: border-box;
    margin-bottom: 22px;
    .item-content {
      padding-top: 50px;
      .title {
        padding-bottom: 20px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .item {
      display: flex;
      height: 112px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      line-height: 112px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      .left {
        // width: 200px;
      }
      .right {
        white-space: nowrap;
        flex: 1;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        text-align: right;
      }
    }
    .title {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .checkLine {
    width: 176px;
    height: 50px;
    background: linear-gradient(180deg, #71a7ff 0%, #3781ff 100%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    padding: 0 22px 0 24px;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 26px;
    img {
      width: 28px;
    }
  }
}
</style>

<template>
  <div class="page">
    <div class="main-title">{{ questionnaireName }}</div>
    <div class="lastScore" v-if="lastTimeScore">
      上次得分：<span>{{ lastTimeScore }}</span>
    </div>
    <div class="item" v-for="(t, i) in list" :key="i">
      <div class="title">
        {{ i + 1 }}、{{ t.problemName }}{{ t.problemType == 2 ? "(多选)" : "" }}
      </div>
      <div
        @click="itemClick(t, t2, i2)"
        :class="['optionItem', { choice: t2.checked }]"
        v-for="(t2, i2) in t.optList"
        :key="i2"
      >
        {{ letterList[i2] }}、{{ t2.name }}
      </div>
    </div>
    <div class="btn" @click="submit()">提交</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { questionnaireDetailsUrl, questionnaireSubmitUrl } from "./api.js";
export default {
  data() {
    return {
      letterList: [],
      list: [],
      id: null,
      questionnaireName: null,
      lastTimeScore: null,
      flag: false,
    };
  },
  created() {
    this.getLetterList();
    this.getQuList();
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    getQuList() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(questionnaireDetailsUrl, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.id = res.data.id;
            this.questionnaireName = res.data.questionnaireName;
            this.lastTimeScore = res.data.lastTimeScore;
            res.data.detailsVOS.forEach((item) => {
              item.optList = [];
              item.optionValue = item.optionValue.split(",");
              item.optionValue.forEach((item2) => {
                item2 = { name: item2, checked: false };
                item.optList.push(item2);
              });
            });
            this.list = res.data.detailsVOS;
            console.log(this.list);
          }
        });
    },
    submit() {
      this.list.forEach((item) => {
        if (!JSON.stringify(item.optList).includes("true")) {
          this.flag = true;
        } else {
          this.flag = false;
        }
      });
      if (this.flag) {
        this.$toast({
          message: "请回答所有题目~",
        });
      } else {
        let params = {
          id: this.id,
          userId: this.userId,
          contentRequests: this.list,
        };
        this.$axios.post(`${questionnaireSubmitUrl}`, params).then((res) => {
          if (res.code == 200) {
            this.$toast({
              message: `本次评估${res.data.score}分`,
              duration: 1000,
            });
            setTimeout(() => {
              this.getQuList();
            }, 1000);
          }
        });
      }
    },
    getLetterList() {
      for (var i = 0; i < 26; i++) {
        this.letterList.push(String.fromCharCode(65 + i));
      }
    },
    itemClick(t, t2, i2) {
      if (t.problemType == 1) {
        t.optList.forEach((item) => {
          item.checked = false;
        });
        t2.checked = true;
        t.problemValue = t2.name;
      } else {
        if (t2.checked) {
          t2.checked = false;
        } else {
          t2.checked = true;
        }
        let list = [];
        t.optList.forEach((item) => {
          if (item.checked) {
            list.push(item.name);
          }
        });
        t.problemValue = list.join(",");
        console.log(t.problemValue);
      }
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  .btn {
    text-align: center;
    line-height: 66px;
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #90cff3 0%, #66afd9 100%);
    border-radius: 10px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    margin: 90px auto 0;
  }
  .item {
    .choice {
      background: #eef9ff !important;
      border: 2px solid #66afd9 !important;
      color: #66afd9 !important;
    }
    .optionItem {
      width: 100%;
      padding: 22px;
      box-sizing: border-box;
      border-radius: 8px;
      border: 2px solid rgba(0, 0, 0, 0.25);
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
      margin-bottom: 14px;
    }
    .title {
      margin-top: 50px;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #1b1a21;
      margin-bottom: 30px;
    }
  }
  .lastScore {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    span {
      color: #66afd9;
    }
  }
  .main-title {
    padding-top: 20px;
    text-align: center;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
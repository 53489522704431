const getIsBindingPersonsUrl = `/gateway/hc-health/miniapi/healthHeart/getIsBindingPersons`; //获取当前用户绑定的老人信息
const getMyCartUrl = `/gateway/hc-health/miniapi/healthHeart/getMyCart`; //获取我的购物车
const orderFoodUrl = `/gateway/hc-health/miniapi/healthHeart/orderFood`; //下单
const getFoodDetailsUrl = `/gateway/hc-health/miniapi/healthHeart/getFoodDetails`; //获取菜品详情
const checkIsBindingUrl = `/gateway/hc-health/miniapi/healthHeart/checkIsBinding`; //判断当前用户是否绑定过老人
const deleteCartFoodUrl = `/gateway/hc-health/miniapi/healthHeart/deleteCartFood`; //删除购物车商品
const getFoodListByTypeUrl = `/gateway/hc-health/miniapi/healthHeart/getFoodListByType`; //根据菜品类别获取菜品列表
const changeOrderStatusUrl = `/gateway/hc-health/miniapi/healthHeart/changeOrderStatus`; //取消/完成订单
const getOrderListUrl = `/gateway/hc-health/miniapi/healthHeart/getOrderList`; //获取订单列表/工作人员
const addCartUrl = `/gateway/hc-health/miniapi/healthHeart/addCart`; //加入购物车
const getMyOrderListUrl = `/gateway/hc-health/miniapi/healthHeart/getMyOrderList`; //获取我的订单
const clearMyCartUrl = `/gateway/hc-health/miniapi/healthHeart/clearMyCart`; //清空购物车
const addOrUpdateInfo = `/gateway/hc-health/manage/healthHeart/addOrUpdateInfo`; //老人认证
const getOrderDetailsUrl = `/gateway/hc-health/miniapi/healthHeart/getOrderDetails`; //获取订单详情
const getFoodTypeUrl = `/gateway/hc-health/manage/healthHeart/getFoodType`; //获取菜品类别
const userAssetListUrl = `gateway/hc-mini/user/mini/user-asset-list`; //获取用户房屋信息
const getFunctionSettingUrl = `/gateway/blade-system/application/getFunctionSetting`; //获取权限
// 获取用户个人信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
//判断当前用户此餐是否有优惠
const checkHaveDiscounts = `/gateway/hc-health/miniapi/healthHeart/checkHaveDiscounts`;
export {
  getIsBindingPersonsUrl,
  getMyCartUrl,
  orderFoodUrl,
  getFoodDetailsUrl,
  checkIsBindingUrl,
  deleteCartFoodUrl,
  getFoodListByTypeUrl,
  changeOrderStatusUrl,
  getOrderListUrl,
  addCartUrl,
  getMyOrderListUrl,
  clearMyCartUrl,
  addOrUpdateInfo,
  getOrderDetailsUrl,
  getFoodTypeUrl,
  userAssetListUrl,
  getFunctionSettingUrl,
  userInfoUrl,
  checkHaveDiscounts,
};

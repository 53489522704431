<template>
  <div class="pioneerMissionSign">
    <div class="title">
      <span>签名</span>
      <img @click="handleReset" src="../img/reset.png" alt="" />
    </div>
    <div class="sign">
      <signCanvas
        ref="esign"
        :width="614"
        :height="242"
        :isCrop="isCrop"
        :lineWidth="lineWidth"
        :lineColor="lineColor"
        :bgColor.sync="bgColor"
      />
    </div>
    <div class="submit" @click="handleGenerate">提交</div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import signCanvas from "@/components/bussiness/signCanvas";
import { base64toFile } from "@/utils/utils.js";
import { applyForCommitmentLetterUrl } from "../api";

export default {
  components: {
    signCanvas,
  },
  data() {
    return {
      activityId: "",
      isCrop: false,
      lineWidth: 6,
      postData: {
        commitmentLetter: "",
      },
      lineColor: "#979797",
      bgColor: "#D8D8D8",
      uploadUrl: "/gateway/blade-resource/oss/endpoint/put-file",
      signImageUrl: "",
      resultImg: "",
      result: undefined, // 用于记录承诺书是否已签署
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  async created() {
    this.activityId = this.$route.query.activityId;
  },
  methods: {
    // 提交承诺书签名
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          this.uploadImg(base64toFile(this.resultImg));
        })
        .catch((err) => {
          alert(err); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
    // 签名转为图片
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(this.uploadUrl, formData);
      if (res.code == 200) {
        this.signImageUrl = res.data.link;
        this.postSignData();
      }
    },
    // 清空签名
    handleReset() {
      this.$refs.esign.reset();
    },
    // 签署承诺书接口
    async postSignData() {
      let params = {
        userId: this.userId,
        activityId: this.activityId,
        signatureImage: this.signImageUrl,
      };
      let res = await this.$axios.get(applyForCommitmentLetterUrl, {
        params,
      });
      if (res.code === 200) {
        this.$toast({ message: "提交成功", duration: 2000 });
        wx.miniProgram.navigateBack({
          delta: 1,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pioneerMissionSign {
  padding: 28px 68px 138px 68px;
  margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .title {
    font-size: 36px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    text-align: left;
    display: flex;
    align-items: center;
    span {
      flex: 1;
    }
    > img {
      width: 28px;
      height: 26px;
    }
  }
  .sign {
    // width: 614px;
    width: 100%;
    margin: 24px auto 0;
    display: flex;
    justify-content: center;
  }
  .submit {
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 550px;
    height: 66px;
    font-size: 28px;
    line-height: 66px;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
    border-radius: 10px;
    margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
  .popup {
    .popup-warpper {
      height: 100%;
      background: #f8f8f8;
      .popup-title {
        padding: 32px;
        height: 44px;
        line-height: 40px;
        font-size: 32px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
      .popup-content {
        height: calc(100% - 108px);
      }
    }
  }
}
</style>

const routes = [
  //公交列表
  {
    path: "/futureTransport/busStop",
    name: "busStop",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/busStop/index"], resolve),
  },
  //公交详情
  {
    path: "/futureTransport/busStopDetail",
    name: "busStopDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/busStop/busStopDetail"], resolve),
  },
  // 智慧停车
  {
    path: "/futureTransport/smartParking",
    name: "smartParking",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/smartParking/index.vue"], resolve),
  },
];

export default routes;

// 根据类别和标题获取商品列表
const getGoodListByTypeAndTitleURL = `/gateway/hc-serve/miniapi/quarterMall/getGoodListByTypeAndTitle`;
// 根据商品ID获取详细信息
const getGoodsDetailURL = `/gateway/hc-serve/miniapi/quarterMall/getGoodsDetail`;
// 加入购物车
const addTrolleyURL = `/gateway/hc-serve/mini/communityBuy/addTrolley`;
//个人信息
const userInfoURL = `/gateway/hc-mini/user/mini/user-info`;
// 下单
const addOrderURL = `/gateway/hc-serve/mini/communityBuy/addOrder`;
// 查询下单状态
const queryOrderURL = `/gateway/hc-serve/mini/communityBuy/queryOrder`;

//获取微信jssdk初始化
const initWxJsSdkURL = `/gateway/hc-mini/qrcode/getPASignature`;

export {
  getGoodListByTypeAndTitleURL,
  getGoodsDetailURL,
  addTrolleyURL,
  addOrderURL,
  queryOrderURL,
  userInfoURL,
  initWxJsSdkURL,
};

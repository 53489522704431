<template>
  <div class="section" v-if="detail">
    <div class="section-info">
      <div class="info-picture">
        <img :src="detail.imageUrl" alt="" />
      </div>
      <div class="info-content">
        <div class="content-flex">
          <div class="content-title">{{ detail.title }}</div>
          <div class="content-type">
            <span>{{ detail.industryType }}</span>
          </div>
        </div>
        <div class="content-address" @click="goNavigation">
          <img src="../img/place_icon.png" alt="" />
          <span>位置：{{ `${detail.street} ${detail.address}` }}</span>
        </div>
        <div class="content-time">
          <span>营业时间：{{ detail.openingHours }}</span>
          <v-rate class="v-rate" readonly :value="Math.ceil(detail.avgScore)" />
        </div>
      </div>
    </div>
    <div class="section-detail">
      <div class="detail-item">
        <div class="item-label">交易笔数</div>
        <div class="item-value">{{ detail.total }}</div>
      </div>
      <div class="detail-item">
        <div class="item-label">商品数量</div>
        <div class="item-value">{{ detail.putawaying }}</div>
      </div>
      <div class="detail-item">
        <div class="item-label">月销量</div>
        <div class="item-value">{{ detail.monthlySales }}</div>
      </div>
    </div>

    <div class="section-activity" v-if="detail.mallActivity">
      <img src="./img/gift_icon.png" alt="" />
      <span>商铺活动：</span>
      <!-- <div class="broadcast">
        <span>{{ detail.mallActivity }}</span>
      </div> -->
      <notice-bar v-if="detail.mallActivity" :text="detail.mallActivity" />
    </div>

    <div class="section-nav">
      <div class="common-title"><span>我的商品</span></div>
      <div class="nav-more">
        <div class="more" @click="toRouter('bcnServiceGoodsMore')">
          <span>更多</span>
          <img src="../img/arrow_right_gray.png" alt="" />
        </div>
      </div>
    </div>
    <div class="section-list">
      <template
        v-if="detail && detail.goodsList && detail.goodsList.length > 0"
      >
        <div
          class="section-list-item"
          @click="toDetail(item)"
          v-for="(item, index) in detail.goodsList"
          :key="index"
        >
          <div class="item-picture">
            <img :src="item.imageUrl" alt="" />
          </div>
          <div class="item-title">
            {{ item.title }}
          </div>
          <div class="item-price">
            <span>{{ item.price }}</span
            >&nbsp;元
          </div>
        </div>
      </template>
      <template v-else>
        <div class="section-empty">
          <v-empty description="上架中..." />
        </div>
      </template>
    </div>

    <comment-rate :params="params" />
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import commentRate from "../components/comment-rate";
import noticeBar from "../components/notice-bar";
// import { manageTypeopsMap, manageTypeops } from "./map.js";
import { detailByIdURL, initWxJsSdkURL } from "./api.js";
// import { toRegister, gloabalCount } from "@/utils/common.js";

export default {
  components: {
    commentRate,
    noticeBar,
  },
  data() {
    return {
      // manageTypeopsMap,
      // manageTypeops,
      id: "",
      detail: null,
      // shopGoodsList: [],
    };
  },
  computed: {
    ...mapState(["userId", "tenantId"]),
    params() {
      return {
        id: this.id,
      };
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  async mounted() {
    // if (await toRegister(this.$route.path, this.$route.query, "新商城")) {
    this.getDetail();
    this.initWxConfig();
    // }
  },
  methods: {
    // // 获取商品列表
    // async getShopGoodsList() {
    //   let params = {
    //     shopId: this.id,
    //   };
    //   let res = await this.$axios.get(`${getShopGoodsListUrl}`, {
    //     params,
    //   });
    //   if (res.code === 200) {
    //     /** 这里注意最多只有两条 */
    //     this.shopGoodsList = res.data.records;
    //   }
    // },
    // 获取商户详情
    async getDetail() {
      let params = {
        id: this.id,
      };
      let res = await this.$axios.get(`${detailByIdURL}`, { params });
      if (res.code === 200) {
        this.detail = res.data;
      }
    },

    toRouter(name) {
      this.$router.push({
        name,
        query: { id: this.id },
      });
    },
    // 前往详情页面
    toDetail(item) {
      // let str = "";
      // if (item.goodsClassify == 1) {
      //   str = "珍珠贝商城";
      // } else {
      //   str = "志愿珍珠贝商城";
      // }
      this.$router.push({
        name: "bcnCategoryShopDetail",
        query: { id: item.id },
      });
    },

    /** 地址 */

    // // 微信授权H5可调用的 wx API功能
    // async initWxConfig() {
    //   let ourl;
    //   if (process.env.NODE_ENV == "production") {
    //     ourl = location.href.split("#")[0];
    //   } else {
    //     ourl = origin;
    //   }
    //   let params = {
    //     url: ourl,
    //   };
    //   let res = await this.$axios.post(`${initWxJsSdkUrl}`, this.$qs.stringify(params));
    //   if (res.code == 200) {
    //     this.wxData = res.data;
    //     wx.config({
    //       debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
    //       appId: this.wxData.appId, // 必填，公众号的唯一标识
    //       timestamp: this.wxData.timestamp, // 必填，生成签名的时间戳
    //       nonceStr: this.wxData.nonceStr, // 必填，生成签名的随机串
    //       signature: this.wxData.signature, // 必填，签名
    //       jsApiList: ["checkJsApi", "openLocation"],
    //     });
    //   }
    // },
    // 微信授权H5可调用的 wx API功能
    async initWxConfig() {
      const url =
        process.env.NODE_ENV == "production"
          ? location.href.split("#")[0]
          : "https://fenghua.zhuneng.cn";
      let res = await this.$axios.post(
        `${initWxJsSdkURL}`,
        this.$qs.stringify({
          url,
        })
      );
      if (res.code == 200) {
        // this.wxData = res.data;
        const result = res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: ["checkJsApi", "openLocation"], // 必填，需要使用的 JS 接口列表
        });
      }
    },
    goNavigation() {
      const { detail } = this;
      const addressDetail = `${detail.street}${detail.address}`;
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: ["openLocation"],
          success: (rest) => {
            //打开指定位置
            wx.openLocation({
              type: "gcj02",
              latitude: Number(detail.latitude),
              longitude: Number(detail.longitude),
              name: addressDetail, // 位置名
              address: addressDetail, // 地址详情说明
              scale: 18, // 地图缩放级别,整型值,范围从1~28。默认为最大
            });
          },
          fail: (res) => {
            console.log("res----->", res);
          },
        });
      });
      wx.error((error) => {
        console.log("error----->", error);
      });
    },
  },
};
</script>

<style scoped lang="less">
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.section {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  padding: 0 36px;
  padding-top: 34px;
  box-sizing: border-box;
  font-family: PingFangSC-Regular, PingFang SC;
  padding-bottom: calc(
    32px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    32px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-info {
    display: flex;
    width: 100%;
    // height: 112px;
    margin-bottom: 36px;
    .info-picture {
      width: 112px;
      height: 112px;
      border-radius: 50%;
      overflow: hidden;
      > img {
        &:extend(.common-img);
        width: 100%;
        height: 100%;
      }
    }
    .info-content {
      flex: 1;
      padding-left: 30px;
      box-sizing: border-box;
      .content-flex {
        white-space: normal;
        .content-title {
          display: inline;
          vertical-align: middle;
          font-size: 32px;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.85);
          margin-right: 14px;
        }
        .content-type {
          box-sizing: border-box;
          display: inline;
          white-space: nowrap;
          vertical-align: middle;
          padding: 4px 12px;
          font-size: 24px;
          line-height: 36px;
          border-radius: 6px;
          color: #fe9616;
          background: #fff4e7;
          border: 2px solid #fe9616;
        }
      }
      .content-address,
      .content-time {
        margin-top: 10px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
      }
      .content-address {
        // display: flex;
        // align-items: center;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
        line-height: 32px;
        img {
          &:extend(.common-img);
          width: 24px;
          height: 24px;
          margin-right: 8px;
        }
        span {
          vertical-align: middle;
        }
      }
      .content-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .v-rate {
          min-width: 232px;
          margin-left: 4px;
        }
      }
    }
  }

  .section-detail {
    width: 100%;
    // height: 142px;
    display: flex;
    margin: 0px 0 30px;
    .detail-item {
      &:not(:first-child) {
        margin-left: 2%;
      }
      width: 32%;
      min-width: 32%;
      box-shadow: 0px 4px 12px 0px rgba(236, 236, 236, 0.5);
      border-radius: 4px;
      padding: 24px 0;
      box-sizing: border-box;
      text-align: center;
      display: flex;
      flex-direction: column;
      .item-label {
        font-size: 26px;
        color: rgba(0, 0, 0, 0.25);
        margin-bottom: 6px;
      }
      .item-value {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px;
        font-size: 38px;
        line-height: 48px;
        font-weight: bold;
        color: #fe9616;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
  .section-activity {
    width: 100%;
    padding: 12px 16px 12px 28px;
    box-sizing: border-box;
    background: rgba(255, 249, 241, 0.82);
    color: #262626;
    display: flex;
    align-items: center;
    margin: 0px 0 30px;
    vertical-align: middle;
    font-size: 24px;
    font-weight: bold;
    line-height: 40px;
    color: #fe9616;
    white-space: nowrap;
    > img {
      &:extend(.common-img);
      width: 24px;
      min-width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
  .section-title {
    font-size: 36px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 30px;
  }
  .section-nav {
    display: flex;
    align-items: flex-end;
    padding: 0 0 24px;
    .nav-more {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .more {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > span {
          font-size: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
          margin-right: 14px;
        }
        > img {
          &:extend(.common-img);
          width: 12px;
          height: 22px;
        }
      }
    }
  }

  .section-list {
    display: flex;
    // justify-content: center;
    .section-empty {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .section-list-item {
      width: calc(50% - 15px);
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      overflow: hidden;
      &:first-child {
        margin-right: 30px;
      }
      .item-picture {
        width: 100%;
        height: 280px;
        > img {
          &:extend(.common-img);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .item-title {
        margin: 28px 20px 8px 20px;
        font-size: 32px;
        font-weight: 400;
        color: #333333;
        line-height: 44px;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        white-space: nowrap;
      }
      .item-price {
        padding: 0 22px 20px 22px;
        color: rgba(0, 0, 0, 0.5);
        font-size: 28px;
        font-weight: 500;
        line-height: 40px;
        span {
          color: #ffb92d;
        }
      }
    }
  }
}
</style>

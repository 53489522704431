<template>
  <div class="section">
    <div class="section-swiper">
      <swiper-preview
        :autoplay="2000"
        :swiperData="detail.imageUrl ? detail.imageUrl.split(',') : []"
      />
    </div>
    <div class="section-title">{{ detail.title }}</div>

    <div class="section-detail">
      <!-- <div class="detail-nav">
        <div class="common-title"><span>产品详情</span></div>
      </div> -->
      <div class="detail-content">
        <v-h5Mtml :content="detail.detailContent || '暂无'"></v-h5Mtml>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsDetailURL } from "./api";
import swiperPreview from "../components/swiper-preview.vue";

export default {
  components: { swiperPreview },
  data() {
    return {
      id: "",
      detail: {
        // pictures: "",
        // title: "【三优蔬菜】香葱 约50g",
        // price: "0.85",
        // sold: "100",
        // stock: "20",
        // detailContent: "",
      },

      /** v-popup */
      showPopup: false,
      curAddress: "",
    };
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.$axios
        .get(getGoodsDetailURL, {
          params: {
            id: this.id,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.detail = res.data;
            return;
          }
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        });
    },
    // // 添加购物车
    // addShopCart() {},
    // // 立即下单
    // orderNow() {
    //   this.showPopup = true;
    // },
    // // 改变地址
    // changeAddress(o) {
    //   this.curAddress = o;
    // },
    // // 立即支付
    // payNow() {},
  },
};
</script>

<style lang="less" scoped>
.section {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  font-family: PingFangSC-Medium, PingFang SC;
  display: flex;
  flex-direction: column;
  padding-bottom: calc(
    32px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    32px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-swiper {
    width: 100%;
    height: 400px;
  }
  .section-title {
    padding: 36px 18px 0;
    font-size: 36px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 48px;
    background: #ffffff;
  }
  .section-detail {
    flex: 1;
    padding: 16px 30px 0px;
    .detail-content {
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
}
</style>

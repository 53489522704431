<template>
  <van-swipe-cell
    ref="swipeCell"
    :name="name"
    :left-width="leftWidth"
    :right-width="rightWidth"
    :before-close="beforeClose"
    :disabled="disabled"
    :stop-propagation="stopPropagation"
    @click="onClick"
    @open="onOpen"
    @close="onClose"
  >
    <slot></slot>
    <template #right>
      <slot name="right"></slot>
    </template>
    <template #left>
      <slot name="left"></slot>
    </template>
  </van-swipe-cell>
</template>

<script>
import { SwipeCell } from "vant";

export default {
  components: {
    vanSwipeCell: SwipeCell,
  },
  props: {
    // 	标识符，可以在事件参数中获取到
    name: [Number, String],
    // 底部条宽度，默认单位为2倍px - 自动转为rem
    leftWidth: {
      type: [Number, String],
      default: "auto",
    },
    // 底部条高度，默认单位为2倍px - 自动转为rem
    rightWidth: {
      type: [Number, String],
      default: "auto",
    },
    // 关闭前的回调函数
    beforeClose: Function,
    // 是否禁用滑动
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否阻止滑动事件冒泡
    stopPropagation: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 点击标签时触发
    onClick(...arg) {
      this.$emit("click", arg);
    },

    // 点击标签时触发
    onOpen(...arg) {
      this.$emit("open", arg);
    },

    // 点击标签时触发
    onClose(...arg) {
      this.$emit("close", arg);
    },
    open(position) {
      this.$refs.swipeCell.open(position);
    },
    close() {
      this.$refs.swipeCell.close();
    },
  },
};
</script>

<style lang="less" scoped></style>

<template>
  <div class="page">
    <div class="infoBox">
      <div class="left">
        <img src="./img/home-banner.png" alt="" />
      </div>
      <div class="right">
        <div class="name"><span>姓名：</span>{{ "刘德华" }}</div>
        <div class="post"><span>职务：</span>{{ "法师" }}</div>
        <div class="phoneNum"><span>联系方式：</span>{{ "13093746913" }}</div>
      </div>
    </div>
    <div class="title">风采展示</div>
    <div class="content">曾获得宁波市优秀共产党员称号</div>
  </div>
</template>

<script>
export default {
  name: "cadreInformation",
};
</script>

<style scoped  lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  background: #fafafa;
  padding: 30px;
  .content {
    margin-top: 22px;
    padding: 54px 40px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    border-radius: 16px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1a1c34;
  }
  .title {
    padding-top: 20px;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1c34;
  }
  .infoBox {
    display: flex;
    .right {
      padding-left: 32px;
      padding-top: 12px;
      box-sizing: border-box;
      .name,
      .post,
      .phoneNum {
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 20px;
        span {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .left {
      width: 200px;
      img {
        width: 200px;
        height: 200px;
        border-radius: 10px;
      }
    }
  }
}
</style>
<template>
  <van-popover
    v-model="isShowPopover"
    :actions="actions"
    :placement="placement"
    :trigger="trigger"
    :overlay="overlay"
    :close-on-click-action="closeOnClickAction"
    :close-on-click-outside="closeOnClickOutside"
    @select="select"
    @open="open"
    @close="close"
  >
    <template #default>
      <slot></slot>
    </template>
    <template #reference>
      <slot name="reference"></slot>
    </template>
  </van-popover>
</template>

<script>
import Vue from "vue";
import { Popover } from "vant";
Vue.use(Popover);
export default {
  components: {},
  props: {
    //选项列表
    actions: {
      type: Array,
      default: () => [],
    },
    //弹出位置
    placement: {
      type: String,
      default: "bottom",
    },
    //触发方式
    trigger: {
      type: String,
      default: "click",
    },
    //是否显示遮罩层
    overlay: {
      type: Boolean,
      default: false,
    },
    //是否在点击选项后关闭
    closeOnClickAction: {
      type: Boolean,
      default: true,
    },
    //是否在点击外部元素后关闭菜单
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    //双向绑定的值
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    isShowPopover: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    //点击选项时触发
    select(actions, index) {
      this.$emit("select", { actions, index });
    },
    //打开弹出层时触发
    open() {
      this.$emit("open");
    },
    //关闭菜单时触发
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style></style>

<template>
  <div class="page">
    <div class="section-text">请确认已经准备好以下物品</div>
    <div class="section-picture">
      <img src="../img/stepsFour.png" alt="" />
    </div>
    <div class="section-footer">
      <div class="footer-btn" @click="confirm">
        <span>去扫码</span>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { initWxJsSdkURL } from "../api";

export default {
  data() {
    return {};
  },
  created() {
    this.initWxConfig();
  },

  methods: {
    // 微信授权H5可调用的 wx API功能
    async initWxConfig() {
      const url =
        process.env.NODE_ENV == "production"
          ? location.href.split("#")[0]
          : "https://fenghua.zhuneng.cn";
      // if (process.env.NODE_ENV == "production") {
      //   // url = location.href.split("#")[0];
      // } else {
      //   url = origin;
      // }
      // let params = {
      //   url: ourl,
      // };
      let res = await this.$axios.post(
        `${initWxJsSdkURL}`,
        this.$qs.stringify({
          url,
        })
      );
      if (res.code == 200) {
        // this.wxData = res.data;
        const result = res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: ["scanQRCode"], // 必填，需要使用的 JS 接口列表
        });
      }
    },
    confirm() {
      wx.ready(() => {
        console.log("wx ready---");
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
          success: (res) => {
            console.log(res, "res---");
            if (res.resultStr) {
              let stepsDetail = JSON.parse(
                sessionStorage.getItem("stepsDetail")
              );
              stepsDetail.stepFour.status = true;
              stepsDetail.stepFour.deviceCode = res.resultStr;
              sessionStorage.setItem(
                "stepsDetail",
                JSON.stringify(stepsDetail)
              );
              this.$router.go(-1);
            }
          },
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  min-height: 100%;
  background: #fff;
  /* 兼容 iOS < 11.2 */
  padding-bottom: calc(72px + constant(safe-area-inset-bottom));
  /* 兼容 iOS >= 11.2 */
  padding-bottom: calc(72px + env(safe-area-inset-bottom));
  .section-text {
    padding: 30px 30px 50px 30px;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
  }
  .section-picture {
    margin-bottom: 134px;
    > img {
      width: 100%;
    }
  }
  .section-footer {
    background: #bddabd;
    margin: 0px 64px 0px;
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    border-radius: 10px;
    line-height: 72px;
    text-align: center;
  }
}
</style>

<template>
  <div class="section">
    <div class="list">
      <v-list
        description="上架中..."
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="section-flex-item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="item-picture"><img :src="item.imageUrl" alt="" /></div>
          <div class="item-detail">
            <div class="item-detail-title">
              <span>{{ item.title }}</span>
              <!-- <v-rate class="v-rate" readonly v-model="item.rate" /> -->
            </div>
            <div class="item-detail-price">
              <!-- <img src="../img/place_icon.png" alt="" />
              <span>地址：{{ item.detailedAddress }}</span> -->
              <span>{{ item.price }}</span
              >元
            </div>
            <!-- <div class="item-detail-active">
              <img src="../img/active_icon.png" alt="" />
              <span>优惠活动：{{ item.discountInfo }}</span>
            </div> -->
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getQuarterGoodsListURL } from "./api.js";

export default {
  data() {
    return {
      /* v-list */
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.id = this.$route.query.id;
  },
  methods: {
    // 重置商户列表
    onRefresh() {
      this.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    // 获取商户列表
    onLoad() {
      let params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        id: this.id,
        // userId: this.userId,
        // tenantId: this.tenantId,
        // houseId: this.houseId || this.communityId || undefined,
      };
      this.$axios
        .get(getQuarterGoodsListURL, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        });
    },
    // 点击前往商品详情
    toDetail(item) {
      this.$router.push({
        name: "bcnCategoryShopDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.section {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #ffffff;
  padding-bottom: calc(
    90px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    90px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .list {
    box-sizing: border-box;
    width: 100%;
    padding: 0 28px;
    .section-flex-item {
      &:not(:first-child) {
        margin-top: 30px;
      }
      padding: 22px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      display: flex;
      align-items: center;
      .item-picture {
        width: 200px;
        min-width: 200px;
        height: 200px;
        border-radius: 16px;
        overflow: hidden;
        margin-right: 28px;
        > img {
          &:extend(.common-img);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .item-detail {
        flex: 1;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        .item-detail-title {
          flex: 1;
          padding-top: 10px;
          font-size: 28px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
          display: flex;
          // align-items: center;
          justify-content: space-between;
          span {
            width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
          }
          .v-rate {
            margin-left: 4px;
          }
        }
        .item-detail-price {
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-weight: 400;
          line-height: 40px;
          font-size: 30px;
          > span {
            font-size: 32px;
            color: #ffb92d;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="talentDialog">
    <div class="dialog">
      <div class="title">奉化〡家门口小程序达人申请责任须知</div>
      <div class="body">
        <p>尊敬的奉化〡家门口小程序用户：</p>
        <p>感谢您对社区达人活动的支持与信任！在我们共同开启这段达人旅程之前，我们有责任向您明确达人活动中的安全注意事项及责任分担原则。请仔细阅读以下内容，确保您和所有参与者的安全与权益得到妥善保护。</p>
        <p><span>风险认知：</span>组织或参与达人活动前，请充分了解活动性质及可能存在的风险，包括但不限于身体伤害、意外事故等，参与即视为接受这些风险。</p>
        <p><span>组织者责任：</span>遵守活动规则，按时组织活动开展，如遇特殊情况无法正常开展活动，需提前告知参与者。作为活动组织者，需依法依规组织活动，确保活动方案符合安全标准。
提供必要的安全培训、指导和装备。设立应急响应机制，应对突发情况。如有必要需为参与者提供必要的个人意外险。</p>
        <p><span>参与者责任：</span>自愿参与，对自己的健康状况负责，如有特殊健康问题需提前告知。不进行危险行为，对自己及他人安全负责。</p>
        <p><span>重要提示：</span>申请社区达人，即视为您已阅读并同意上述安全责任说明。我们诚挚邀请您与我们共同努力，携手共创美好回忆！</p>
      </div>
      <div class="sure" @click="closeTalent">好的</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "talentDialog",
  data() {
    return {};
  },
  components: {},
  destroyed() {},
  computed: {},
  mounted() {},
  methods: {
    closeTalent() {
      this.$emit("closeTalent");
    },
  },
};
</script>

<style lang="less" scoped>
.talentDialog {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  .dialog {
    width: 90%;
    height: 80%;
    background: #fff;
    box-shadow: 0px 0px 40px 12px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  .title {
    background: rgba(0, 126, 255, 1);
    height: 120px;
    text-align: center;
    border-radius: 20px 20px 0 0;
    font-size: 30px;
    line-height: 120px;
    color: #fff;
  }

  .body {
    flex: 1;
    padding: 40px 40px;
    color: rgba(0, 126, 255, 1);
    font-size: 30px;
    box-sizing: border-box;
    overflow: scroll;
    p{text-indent: 2em;
    span{
      font-weight: bold;
    }}
  }

  .sure {
    width: 80%;
    height: 90px;
    background: rgba(0, 126, 255, 1);
    border-radius: 48px;
    text-align: center;
    color: #fff;
    font-size: 32px;
    line-height: 90px;
    margin: 50px auto;
  }
}
</style>
<style lang="less">
.talentDialog {
}
</style>

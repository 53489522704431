<template>
  <div class="page">
    <div class="title">参观须知</div>
    <div class="tag">
      <div class="left"></div>
      <div class="center">松岙未来社区</div>
      <div class="right"></div>
    </div>
    <p>
      今年是建党100周年，松岙红色旅游基地参观学习的团队比较多，希望各个单位部门请提前三天进行电话预约，合理安排线路并且有秩序的进行参观学习，感谢大家配合！
    </p>
    <p>
      1.党建馆参观请至少提前三天预约；参观时间为工作日上午9:00-16:00，周末及节假日闭馆。
    </p>
    <p>
      2.根据党建馆内空间条件，同时在馆人数上限为50人。
      如人数过多请分场次参观，每场需单独预约。
    </p>
    <p>
      3.请写明参观人员身份等情况，如国籍、单位、职务、职称、年级、专业等信息，以便有针对性地讲解。
    </p>
    <p>
      4.如涉及外宾参观，请在备注处注明是否自带翻译。
      预约热线：0574-88793686（松岙集散中心）
    </p>
  </div>
</template>

<script>
export default {
  name: "visitNotice",
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #ffffff;
  padding: 36px 30px;
  p {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    padding-bottom: 20px;
  }
  .tag {
    display: flex;
    align-items: center;
    margin-bottom: 34px;
    .left,
    .right {
      flex: 1;
      width: 196px;
      height: 2px;
      background-color: #ecf0ee;
    }
    .center {
      width: 300px;
      height: 44px;
      background: rgba(193, 203, 199, 0.15);
      border-radius: 4px;
      text-align: center;
      line-height: 44px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(4, 40, 26, 0.45);
    }
  }
  .title {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #477f7d;
    text-align: center;
    padding-bottom: 22px;
  }
}
</style>

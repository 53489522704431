<template>
  <div class="appointList">
    <div class="tabBox">
      <div class="tab">
        <div class="tab-l" @click="select(1)">
          <div class="title">租赁申请</div>
          <div class="block" v-if="oIndex == 1"></div>
        </div>
        <div class="tab-r" @click="select(2)">
          <div class="title">场馆预约</div>
          <div class="block" v-if="oIndex == 2"></div>
        </div>
      </div>
    </div>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div class="box_1" v-if="oIndex == 1">
        <div
          class="box"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="areaAppointItem">
            <div class="pic">
              <img :src="item.picture" />
            </div>
            <div class="name">{{ item.typeName }} {{ item.spaceName }}</div>
            <div class="address">{{ item.size }}m² | {{ item.direction }}</div>
            <div class="time">
              提交时间：<span>{{ item.applyDate }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="box_2" v-else>
        <div
          class="box"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="areaAppointItem">
            <div class="appointList-t">
              <div class="id">工单号：{{ item.serialNumber }}</div>
              <div class="state">{{ getStatus(item) }}</div>
            </div>
            <div class="appointList-c">
              <div class="name">{{ item.placeName }}</div>
              <div class="address">{{ item.address }}</div>
              <div
                :class="[
                  'status',
                  { end: item.scheduleList[0].strTimeStatus == '已结束' },
                ]"
              >
                {{ item.scheduleList[0].strTimeStatus }}
              </div>
            </div>
            <div class="appointList-b">
              <div class="timeBox">
                <div class="title">下单时间</div>
                <div class="time">{{ item.referTime }}</div>
              </div>
              <div class="timeBox">
                <div class="title">预约场次</div>
                <div class="time">
                  <div
                    class="scheduleList"
                    v-for="(oItem, oIndex) in item.scheduleList"
                    :key="oIndex"
                  >
                    {{ oItem.strDateTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-list>
    <!-- <div v-else class="itemBox2">
        <v-list :finished="finished" @onLoad="onLoad" ref="list" :finishedText="finishedText">
            <div v-for="(item, index) in listData" :key="index" class="areaAppointItem" @click="toDetail(item)">
                <div class="listData-l" v-if="oIndex == 2">
                    <div class="appointList-top">
                        <div class="order">
                            <div class="item-l">工单号：</div>
                            <div class="item-r">{{ item.orderId }}</div>
                        </div>
                        <div :class="('status', { active: item.status == 2 })">
                            {{ getStatus(item) }}
                        </div>
                    </div>
                    <div class="appointList-t">
                        <div class="appointList-tl"><img :src="item.picUrl" alt="" /></div>
                        <div class="appointList-tr">
                            <div class="name">
                                {{ item.placeName }}
                            </div>
                            <div class="address">{{ item.address }}</div>
                        </div>
                    </div>
                    <div class="appointList-b">
                        <div class="orderTime">
                            <div class="item-l">下单时间：</div>
                            <div class="item-r">{{ item.referTime }}</div>
                        </div>
                        <div class="times">
                            <div class="item-l">预约场次：</div>
                            <div class="item-r">
                                <div class="scheduleList" v-for="(oItem, oIndex) in item.scheduleList" :key="oIndex">
                                    <p class="p-l">{{ oItem.strDateTime }}</p>
                                    <p class="p-r">{{ oItem.strTimeStatus }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="listData-r" v-if="oIndex == 1">
                  <div class="appointList-top">
            <div class="order">
              <div class="item-l">工单号：</div>
              <div class="item-r">{{ item.orderId }}</div>
            </div>
            <div :class="('status', { active: item.status == 2 })">
              {{ getStatus(item) }}
            </div>
          </div>
                    <div class="appointList-t">
                        <div class="appointList-tl"><img :src="item.picture" alt="" /></div>
                        <div class="appointList-tr">
                            <div class="name">{{ item.typeName }} {{ item.spaceName }}</div>
                            <div class="address">
                                {{ item.size }}m² | {{ item.direction }}
                            </div>
                        </div>
                    </div>
                    <div class="appointList-b">
                        <div class="orderTime">
                            <div class="item-l">提交时间：</div>
                            <div class="item-r">{{ item.applyDate }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </v-list>
    </div> -->
  </div>
</template>

<script>
import { myAppointUrl, myrentUrl } from "./api.js";
import { appointStatus } from "./map.js";
import { getOption } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "appointList",
  data() {
    return {
      oIndex: 1,
      finishedText: "没有更多了",
      finished: false,
      loading: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
      // userId: '1435123978587934721'
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  mounted() {},
  methods: {
    select(data) {
      this.oIndex = data;
      this.listData = [];
      this.requestData.curPage = 1;
      this.getList();
    },
    getStatus(item) {
      if (item.status) {
        console.log(
          88,
          item,
          getOption(item.status, appointStatus, "key").value
        );
        return getOption(item.status, appointStatus, "key").value;
      } else {
        return "";
      }
    },
    getList() {
      if (this.oIndex == 1) {
        this.getMyRent();
      } else {
        this.getMyAppointList();
      }
    },
    onLoad() {
      this.getList();
    },
    getMyRent() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        openId: this.openId,
        tenantId: this.tenantId,
      };
      this.$axios
        .get(`${myrentUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.referTime) {
                item.referTime = moment(item.referTime).format("YYYY-MM-DD");
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    getMyAppointList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios
        .get(`${myAppointUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.referTime) {
                item.referTime = moment(item.referTime).format("YYYY-MM-DD");
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    toDetail(item) {
      if (this.oIndex == 2) {
        this.$router.push({
          name: "appointDetail",
          query: {
            orderId: item.orderId,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.appointList {
  box-sizing: border-box;
  min-height: 100vh;
  background: #f7f7f7;
  padding-top: 136px;

  .box_2 {
    .box {
      width: 100%;
      min-height: 402px;
      box-sizing: border-box;
      padding: 0 28px;
      margin-bottom: 28px;

      .areaAppointItem {
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
        border-radius: 16px;
        height: 100%;
      }

      .appointList-t {
        box-sizing: border-box;
        height: 72px;
        width: 100%;
        position: relative;
        color: rgba(0, 0, 0, 0.25);
        font-size: 24px;

        .id {
          position: absolute;
          left: 38px;
          top: 50%;
          transform: translateY(-50%);
        }

        .state {
          position: absolute;
          right: 32px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .appointList-c {
        box-sizing: border-box;
        width: 100%;
        height: 150px;
        border-top: 2px solid rgba(0, 0, 0, 0.05);
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        position: relative;

        .name {
          font-size: 36px;
          font-weight: 600;
          color: #333333;
          position: absolute;
          left: 40px;
          top: 34px;
        }

        .address {
          font-size: 26px;
          color: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 40px;
          top: 82px;
        }

        .status {
          font-size: 30px;
          font-weight: 600;
          color: #5e5aff;
          position: absolute;
          top: 36px;
          right: 32px;
        }

        .end {
          color: rgba(0, 0, 0, 0.5);
        }
      }

      .appointList-b {
        width: 100%;
        padding: 42px 32px 24px 40px;
        box-sizing: border-box;

        .timeBox {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .title {
            width: 200px;
            font-size: 32px;
            color: rgba(0, 0, 0, 0.5);
          }

          .time {
            flex: 1;
            font-size: 30px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            text-align: right;
          }
        }
      }
    }
  }

  .box_1 {
    .box {
      width: 100%;
      padding: 0 28px;
      box-sizing: border-box;
      height: 234px;
      margin-bottom: 32px;

      .areaAppointItem {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background: #fff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
        border-radius: 16px;
        position: relative;

        .time {
          font-size: 30px;
          color: rgba(0, 0, 0, 0.85);
          position: absolute;
          bottom: 24px;
          left: 34px;

          span {
            font-size: 28px;
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .address {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 94px;
          left: 186px;
        }

        .name {
          font-size: 36px;
          font-weight: 600;
          color: #333333;
          position: absolute;
          top: 42px;
          left: 186px;
        }

        .pic {
          position: absolute;
          top: 22px;
          left: 34px;
          width: 132px;
          height: 128px;

          img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .tabBox {
    width: 100%;
    box-sizing: border-box;
    margin-top: 22px;
    padding: 0 28px;
    height: 92px;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;

    .tab {
      width: 100%;
      height: 92px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      border-radius: 16px;
      position: relative;

      .tab-l {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        .title {
          position: absolute;
          top: 24px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 30px;
          font-weight: 600;
          color: #333333;
        }

        .block {
          width: 24px;
          height: 6px;
          background: #5e5aff;
          border-radius: 3px;
          position: absolute;
          bottom: 18px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .tab-r {
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;

        .title {
          position: absolute;
          top: 24px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 30px;
          font-weight: 600;
          color: #333333;
        }

        .block {
          width: 24px;
          height: 6px;
          background: #5e5aff;
          border-radius: 3px;
          position: absolute;
          bottom: 18px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>

let origin;
if (window.location.origin == "https://mapi.xydata.cc") {
  origin = "https://wantou.zhuneng.cn"; //湾头研发
} else {
  origin = "";
}
// 提交申请
const applyUrl = `${origin}/gateway/hc-health/miniapi/safetyEquipment/apply`;
//获取区级联动接口
const areaLinkUrl = `${origin}/gateway/hc-space/space/getSpaceLinkList`;
// 获取剩余可申请设备数量
const getResidueSumsUrl = `${origin}/gateway/hc-health/miniapi/safetyEquipment/getResidueSums`;
export { applyUrl, areaLinkUrl, getResidueSumsUrl };

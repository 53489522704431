import { mapHelper } from "@/utils/utils.js";

const activityStatus = [
  {
    value: 1,
    text: "报名未开始",
  },
  {
    value: 2,
    text: "报名进行中",
  },
  {
    value: 3,
    text: "活动未开始",
  },
  {
    value: 4,
    text: "活动进行中",
  },
  {
    value: 5,
    text: "活动结束",
  },
  {
    value: 6,
    text: "活动取消",
  },
];

const { map: activityStatusMap, setOps: activityStatusOps } =
  mapHelper.setMap(activityStatus);

const status = [
  {
    value: 1,
    text: "认领中",
  },
  {
    value: 2,
    text: "已完成认领",
  },
];

const { map: statusMap, setOps: statusOps } = mapHelper.setMap(status);

export { activityStatusMap, activityStatusOps, statusMap, statusOps };

<template>
  <div class="page">
    <div class="search">
      <div class="searchBox">
        <div class="search-l">
          <input
            v-model="requestData.activityName"
            type="text"
            placeholder="请输入关键字"
          />
        </div>
        <div class="search-r" @click="search()">搜索</div>
      </div>
    </div>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
      :commonOperaParams="commonOperaParams"
    >
      <div v-for="(item, index) in listData" :key="index">
        <div class="activityItem" @click="toDetail(item)">
          <div class="activityItem-t">
            <img :src="item.activityPicture" alt="" />
          </div>
          <div class="activityItem-c">
            <div class="title">
              <div class="title-l" v-if="item.isApplied == 1">
                <img src="../applyActivity/img/signUp.png" alt="" /><span
                  >已报名</span
                >
              </div>
              <div class="title-r">{{ item.activityName }}</div>
              <div class="status-green" v-if="item.activityCategory == 1">
                达人活动
              </div>
              <!-- <div class="status-blue" v-else-if="item.activityCategory == 20">
                时间银行
              </div>
              <div class="status-blue" v-else-if="item.activityCategory == 19">
                幸福学堂
              </div> -->
            </div>
            <div class="address">{{ item.activityAddress }}</div>
            <p class="p-apply">报名开始时间：{{ item.applyStartDate }}</p>
            <p class="p-activity">活动开始时间：{{ item.startDate }}</p>
          </div>
          <div class="activityItem-b">
            <div class="operate">
              <div class="operate-l">
                <div class="people">
                  <div class="people-l">
                    <img src="../applyActivity/img/newPeople.png" alt="" />
                  </div>
                  <div class="people-r">{{ item.applyUserCount || 0 }}</div>
                </div>
                <div class="comment">
                  <div class="comment-l">
                    <img src="../applyActivity/img/comment.png" alt="" />
                  </div>
                  <div class="comment-r">{{ item.commentCount || 0 }}</div>
                </div>
              </div>
              <div class="operate-r">
                <div
                  :class="[
                    'btn',
                    {
                      applying: item.activityStatus == 2,
                      going: item.activityStatus == 4,
                      back: item.hasFeedback && item.hasFeedback == 1,
                    },
                  ]"
                >
                  <span>{{
                    getOption(item.activityStatus, item.hasFeedback, item)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-list>
    <footer>
      <div class="btn" @click="apply">
        <span>{{ formatText(isApply) }}</span>
      </div>
    </footer>
    <v-dialog v-model="confirmDialog" @confirm="confirm" @cancel="cancel">
      <div class="confirm-content">
        <div class="confirm-title">提示</div>
        <div class="confirm-text">
          您还不是达人无法发布达人活动，请前往申请成为达人
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getTopicListURL,
  cancelPraiseURL,
  addPraiseURL,
  addCommentURL,
  getUserTalentStatusURL,
  getActivityListUrl,
} from "./api.js";
import wx from "weixin-js-sdk";
import { gloabalCount } from "@/utils/common";
import { handleImg, getOption } from "@/utils/utils.js";
import { activityStatus } from "../applyActivity/map.js";
export default {
  name: "partnerStyleListTab2",
  data() {
    return {
      isShowComments: true,
      isApply: false, // 是否已经申请
      text: "", // 文本内容
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      activityStatus,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
        activityName: "",
      },
      type: "热门活动",
      listData: [],
      confirmDialog: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.getIsApply();
  },
  mounted() {
    gloabalCount("", 7, 1);
  },
  methods: {
    confirm() {
      this.$router.push({
        name: "talent",
      });
      this.confirmDialog = false;
    },
    cancel() {
      this.confirmDialog = false;
    },
    getOption(status, hasFeedback, item) {
      if (hasFeedback && hasFeedback == 1) {
        return "活动回顾";
      }
      if (item.applyUserCount >= item.limitCount && item.limitCount != 0) {
        return "报名已满";
      }
      if (getOption(status, activityStatus, "key")["value"]) {
        return getOption(status, activityStatus, "key")["value"];
      } else {
        return "";
      }
    },
    getActivityList() {
      let activityCategories = "1,19,20";
      let params = {};
      params = {
        curPage: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
        activityName: this.requestData.activityName,
        activityCategories: "1,19",
        activityType: 0,
      };
      this.$axios
        .get(`${getActivityListUrl}`, { params: params })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            if (this.requestData.curPage == 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.activityId);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.activityId) || this.listData.push(item);
              });
              this.listData.forEach((item) => {
                if (item.hasFeedback && item.hasFeedback == 1) {
                  if (item.fbCoverUrl) {
                    item.activityPicture = handleImg(686, 238, item.fbCoverUrl);
                  } else {
                    item.activityPicture = handleImg(
                      686,
                      238,
                      item.activityPicture
                    );
                  }
                } else {
                  item.activityPicture = handleImg(
                    686,
                    238,
                    item.activityPicture
                  );
                }
              });
            }
          } else {
          }
          this.requestData.curPage++;
        });
    },
    onLoad() {
      this.getActivityList();
    },
    async toDetail(item) {
      /** start 时间银行特殊处理 */
      // if (item.activityCategory === 20) {
      //   let result = await toRegister(
      //     this.$route.path,
      //     this.$route.query,
      //     "热门活动报名"
      //   );
      //   if (!result) {
      //     return;
      //   }
      //   if (item.activityCategory === 20 && this.errorMsg) {
      //     this.showDialog = true;
      //     return;
      //   }
      //   if (item.activityCategory === 20 && !this.errorMsg) {
      //     const result = await this.ifCommitmentLetter(item.activityId);
      //     if (!result) {
      //       this.$router.push({
      //         name: "undertaking",
      //         query: {
      //           activityId: item.activityId,
      //         },
      //         replace: true,
      //       });
      //       return;
      //     }
      //   }
      // }
      /** end */
      let params = {
        activityId: item.activityId,
        type: this.type,
      };
      wx.miniProgram.navigateTo({
        url: `/xubPages/activityDetail/index?params=${JSON.stringify(params)}`,
      });
      // this.$router.push({
      //   name: "activityDetail",
      //   query: { activityId: item.activityId },
      // });
    },
    search() {
      this.requestData.curPage = 1;
      this.onLoad();
    },
    async selectMem(item, index) {
      this.requestData.activityCategory = item.key;
      this.type = item.label;
      this.finished = false;
      this.finishedText = "没有更多了";
      this.$refs.list._data.loading = true;
      this.requestData.curPage = 1;
      this.listData = [];
      // if (item.key === "20") {
      //   await this.getVolunteer();
      // }
      this.getActivityList();
    },
    formatText(key) {
      const text = {
        "-1": "我要申请",
        0: "审核中",
        1: "发布活动",
        2: "我要申请",
      };
      return text[key];
    },
    getIsApply() {
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "请求中...",
      });
      this.$axios
        .get(getUserTalentStatusURL, {
          params: {
            userId: this.userId, // 评论人ID
            tenantId: this.tenantId,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.isApply = res.data;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 我要申请按钮
    apply() {
      if (this.isApply == 0) {
        return;
      }
      if (this.isApply == 1) {
        this.$router.push({
          name: "publishActivity",
        });
      } else {
        this.confirmDialog = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #fafafaff;
  min-height: 100vh;
  padding: 40px 32px;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  .search {
    height: 74px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 46px;
    .searchBox {
      width: 100%;
      height: 100%;
      background: #ffffff;
      border-radius: 37px;
      border: 4px solid #2d77f7;
      overflow: hidden;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      .search-r {
        width: 150px;
        height: 100%;
        background: #2d77f7;
        border: 2px solid #2d77f7;
        text-align: center;
        // border-radius: 37px;
        border-top-left-radius: 37px;
        border-bottom-left-radius: 37px;
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 66px;
      }
      .search-l {
        flex: 1;
        height: 100%;
        padding: 0 48px;
        box-sizing: border-box;
        input {
          box-sizing: border-box;
          display: block;
          width: 100%;
          height: 66px;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
      }
    }
    .code {
      width: 50px;
      height: 44px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  footer {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding-bottom: constant(safe-area-inset-bottom);
    /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom);

    /* 兼容 iOS >= 11.2 */
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 96px;
      color: #ffffff;
      background: #007eff;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
    }
  }
}
.tab {
  // display: flex;
  // justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // box-sizing: border-box;
  // z-index: 999;
  // box-sizing: border-box;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  z-index: 2;
  // padding: 32px 0 0;
  color: #71a4ffff;
  > div {
    flex: 1;
    position: relative;
    height: 50px;
    display: flex;
    font-size: 36px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    line-height: 50px;
    span {
      vertical-align: middle;
      // line-height: 1;
    }
    .round {
      display: none;
    }
  }
  .active {
    font-weight: bold;
    color: #367bf4ff;
    .round {
      position: absolute;
      top: 0px;
      right: 40px;
      display: block;
      height: 10px;
      width: 10px;
      background: #ffb92dff;
      border-radius: 50%;
    }
  }
}
.activityItem {
  position: relative;
  // height: 604px;
  border-radius: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
  margin-bottom: 32px;
  .activityItem-t {
    width: 100%;
    height: 280px;
    overflow: hidden;
    position: relative;
    img {
      border-radius: 16px 16px 0 0;
      width: 100%;
      //height: 100%;
    }
  }
  .activityItem-c {
    padding: 20px 24px 22px;
    box-sizing: border-box;
    .title {
      display: flex;
      align-items: center;
      .title-l {
        width: 102px;
        height: 32px;
        margin-right: 8px;
        position: relative;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
        span {
          width: 100%;
          height: 100%;
          text-align: center;
          position: absolute;
          top: 0;
          left: 0;
          font-size: 20px;
          line-height: 32px;
          color: #fff;
          font-weight: bold;
        }
      }
      .title-r {
        width: 480px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 32px;
        font-weight: bold;
        color: #323334;
        line-height: 44px;
      }
      .status-green {
        width: 124px;
        height: 42px;
        border-radius: 4px;
        border: 2px solid rgb(196, 228, 219);
        font-size: 24px;
        color: rgb(196, 228, 219);
        text-align: center;
        line-height: 42px;
      }
      .status-red {
        width: 124px;
        height: 42px;
        background: rgba(224, 32, 32, 0.1);
        border-radius: 4px;
        border: 2px solid #e02020;
        font-size: 24px;
        color: #e02020;
        text-align: center;
        line-height: 42px;
      }
      .status-blue {
        width: 124px;
        height: 42px;
        background: rgba(42, 114, 113, 0.11);
        border-radius: 4px;
        border: 2px solid #2a7271;
        font-size: 24px;
        color: #2a7271;
        text-align: center;
        line-height: 42px;
      }
    }
    .address {
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 36px;
      margin: 8px 0 14px;
    }
    p {
      font-size: 24px;
      padding-left: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      margin-bottom: 6px;
    }
    .p-apply,
    .p-activity {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: #1ebb70;
        border-radius: 50%;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .p-activity {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        background: #3a69ed;
        border-radius: 50%;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .activityItem-b {
    padding: 18px 24px 18px;
    box-sizing: border-box;
    .operate {
      display: flex;
      // padding: 14px 0 0 0;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      .operate-l,
      .operate-r {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .people {
        width: 160px;
      }
      .people,
      .comment {
        display: flex;
        align-items: center;
      }
      .people-l,
      .comment-l {
        width: 28px;
        height: 28px;
        font-size: 0;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .people-r,
      .comment-r {
        padding-top: 2px;
        line-height: 1;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
      .btn {
        position: absolute;
        bottom: 0;
        right: 0;
        box-sizing: border-box;
        height: 64px;
        // border: 4px solid #fe6f16;
        border-radius: 16px 0 16px 0;
        // color: #fe6f16;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        min-width: 194px;
        background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
        color: #a7a7a7ff;
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        > span {
          font-size: 26px;
          font-weight: bold;
          line-height: 1;
        }
      }
      .btn.applying {
        background: linear-gradient(180deg, #b0e2dd 0%, #54a2ac 100%);
        color: #ffffff;
      }
      .btn.going {
        background: linear-gradient(180deg, #fdc76f 0%, #faae3e 100%);
        color: #ffffff;
      }
      .btn.back {
        background: linear-gradient(180deg, #fdc76f 0%, #faae3e 100%);
        color: #ffffff;
      }
    }
  }
}
.confirm-title {
  font-size: 38px;
  line-height: 48px;
  text-align: center;
}
.confirm-content {
  padding: 40px 20px;
}
.confirm-text {
  font-size: 30px;
  padding: 20px;
  text-indent: 2em;
}
</style>

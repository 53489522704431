import { mapHelper } from "@/utils/utils.js";

// // 	主体类型
// const principalType = [
//   {
//     value: 1,
//     text: "个体",
//   },
//   {
//     value: 2,
//     text: "企业",
//   },
// ];

// const { map: principalTypeMap, setOps: setPrincipalTypeOps } =
//   mapHelper.setMap(principalType);

// // 	经营类型
// const accountType = [
//   {
//     value: 1,
//     text: "对公银行账户",
//   },
//   {
//     value: 2,
//     text: "经营者个人银行卡",
//   },
// ];

// const { map: accountTypeMap, setOps: setAccountTypeOps } =
//   mapHelper.setMap(accountType);

// // 团购类型
// const buyType = [
//   {
//     value: 1,
//     text: "社区团购",
//   },
//   {
//     value: 2,
//     text: "限时秒杀",
//   },
// ];

// const { map: buyTypeMap, setOps: setBuyTypeOps } = mapHelper.setMap(buyType);

// 商品类型
const type = [
  {
    value: 1,
    text: "社区团购",
  },
  {
    value: 2,
    text: "限时秒杀",
  },
  {
    value: 3,
    text: "普通商品",
  },
];

const { map: typeMap, setOps: setTypeOps } = mapHelper.setMap(type);

// 完成订单状态
const status = [
  {
    text: "待发货",
    value: 1,
  },
  {
    text: "已发货",
    value: 2,
  },
  // {
  //   text: "已取消",
  //   value: 3,
  // },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

// 我的订单状态
const myStatus = [
  {
    text: "待付款",
    value: 1,
  },
  {
    text: "待发货",
    value: 2,
  },
  {
    text: "已完成",
    value: 3,
  },
];

const { map: myStatusMap, setOps: setMyStatusOps } = mapHelper.setMap(myStatus);

export {
  // principalTypeMap,
  // setPrincipalTypeOps,
  // accountTypeMap,
  // setAccountTypeOps,
  // buyTypeMap,
  // setBuyTypeOps,
  typeMap,
  setTypeOps,
  statusMap,
  setStatusOps,
  myStatusMap,
  setMyStatusOps,
};

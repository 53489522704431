<template>
  <div class="page">
    <div class="infoBox">
      <div class="left">
        <img :src="headImg" alt="" />
      </div>
      <div class="center">
        <div class="name">{{ goodsName }}</div>
        <div class="price"><span>¥</span>{{ goodsPrice }}</div>
        <div class="userName">发布人：{{ nickName }}</div>
      </div>
      <div class="right">
        <img
          v-if="isCollect === 1"
          @click="likeOrNot(false)"
          src="./img/like.png"
          alt=""
        />
        <img v-else @click="likeOrNot(true)" src="./img/no-like.png" alt="" />
      </div>
    </div>
    <div class="msgBox" @click="toPath('disclaimers')">
      <img class="left" src="./img/message.png" alt="" />
      <div class="center">免责说明：“奉化丨家门口”小程序提醒您：在使用…</div>
      <img class="right" src="./img/right-icon.png" alt="" />
    </div>
    <div class="content">
      {{ goodsContent }}
    </div>
    <div class="img">
      <img
        v-for="(item, index) in goodsPhoto"
        :key="index"
        :src="item"
        alt=""
      />
    </div>
    <div class="btn" v-if="msg == '0'" @click="toPath('leaveMsgForSaleMen')">
      给卖家留言
    </div>
    <div class="btn" v-else @click="toPath('goodsLeaveMessage')">留言记录</div>
    <v-dialog
      v-model="isDialog"
      title=""
      :confirmButtonColor="'#347EFFFF'"
      @confirm="confirm"
    >
      <div class="disclaimer-container">
        <div class="title">奉化〡家门口小程序闲置交易平台免责声明</div>
        <p>尊敬的奉化〡家门口用户：</p>
        <p>
          欢迎您使用奉化〡家门口小程序闲置交易平台（以下简称“本平台”）。为了保护您的权益以及维护良好的交易秩序，请您在使用本平台服务前仔细阅读并理解以下免责声明：
        </p>
        <p>1. 用户责任声明</p>
        <p>
          用户须对其在本平台的一切行为负责，包括但不限于所发布信息的真实性、完整性、合法性，以及进行交易活动的合规性。对于因用户提供的信息虚假、违规操作、侵犯他人权益等行为引发的一切后果，均由该用户自行承担全部责任。
        </p>
        <p>2. 信息准确性与真实性</p>
        <p>
          本平台仅提供信息展示服务，对用户发布的商品信息、评价内容等不做实质性审查，亦无法保证其绝对准确无误。用户在交易时应自行判断信息的真实性、有效性，并独立承担因此产生的风险。
        </p>
        <p>3. 交易风险</p>
        <p>
          本平台仅为用户提供交易场所和交易工具，交易行为完全由买卖双方自行完成，交易风险由交易双方自行承担。如发生交易纠纷，本平台将提供必要的协助，但不对交易结果承担任何直接或间接的责任。
        </p>
        <p>4. 知识产权</p>
        <p>
          用户承诺不在本平台上传或交易侵犯他人知识产权的商品，否则因此产生的法律责任由该用户独自承担。一旦发现此类情况，本平台有权立即删除相关信息，并保留追究侵权者法律责任的权利。
        </p>
        <p>5. 服务稳定性</p>
        <p>
          尽管本平台致力于提供稳定、高效的网络服务，但由于网络环境、设备故障、系统维护升级等原因导致的服务中断或延迟，本平台对此类不可抗力因素引起的损失不承担责任。
        </p>
        <p>6. 争议解决</p>
        <p>
          如用户之间发生交易争议，建议双方首先友好协商解决。如协商不成，可提交至本平台调解，但本平台的调解意见并不具有法律约束力。必要时，用户可以诉诸司法途径解决争议。
        </p>
        <p>7. 免责声明的变更与解释权</p>
        <p>
          本平台保留在任何时候对免责声明进行修订、补充和完善的权利，修订后的免责声明自公布之日起生效。本免责声明的最终解释权归奉化〡家门口小程序闲置交易平台所有。
        </p>
        <p>
          请您务必审慎阅读并接受以上免责声明，继续使用本平台服务即表示您已充分理解并同意以上所有条款。感谢您的支持与信任！
        </p>
        <van-checkbox v-model="isChecked" class="checkbox"
          >我已阅读并同意该协议</van-checkbox
        >
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  goodThingsDetailsUrl,
  collectGoodsUrl,
  cancelCollectUrl,
  permissionOfSellerUrl,
} from "./api";
import { handleImg } from "@/utils/utils.js";
import Vue from "vue";
import { Checkbox } from "vant";
Vue.use(Checkbox);
export default {
  name: "goodsDetail",
  data() {
    return {
      isDialog: false,
      isChecked: false,
      goodsName: null,
      goodsContent: null,
      goodsPhoto: null,
      goodsPrice: null,
      goodsId: null,
      nickName: null,
      headImg: null,
      isCollect: 0,
      msg: null,
      leaveWordId: null,
    };
  },

  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    if (this.$route.query.goodsId) {
      this.goodsId = this.$route.query.goodsId;
    }
  },
  mounted() {
    this.getDetail();
    this.isSeller();
    if (JSON.parse(sessionStorage.getItem("showCheckedDisclaimer"))) {
      return;
    }
    this.isDialog = true;
  },
  methods: {
    toPath(name) {
      this.$router.push({
        name: name,
        query: {
          goodsId: this.goodsId,
          leaveWordId: this.leaveWordId,
        },
      });
    },
    isSeller() {
      let params = {
        userId: this.userId,
        goodsId: this.goodsId,
      };
      this.$axios
        .get(`${permissionOfSellerUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.msg = res.msg;
          }
        });
    },
    likeOrNot(flag) {
      let url = flag ? collectGoodsUrl : cancelCollectUrl;
      let params = {
        goodsId: this.goodsId,
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios.post(`${url}`, params).then((res) => {
        if (res.code == 200) {
          this.getDetail();
        }
      });
    },
    getDetail() {
      let params = {
        goodsId: this.goodsId,
        userId: this.userId,
      };
      this.$axios
        .get(`${goodThingsDetailsUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.goodsContent = res.data.goodsContent;
            this.goodsPhoto = res.data.goodsPhoto.split(",");
            this.headImg = handleImg(220, 220, this.goodsPhoto[0]);
            this.goodsPrice = res.data.goodsPrice;
            this.nickName = res.data.nickName;
            this.isCollect = res.data.isCollect;
            this.leaveWordId = res.data.leaveWordId;
          }
        });
    },
    confirm() {
      if (!this.isChecked) {
        this.$toast("请滚动阅读到最底部，同意该免责说明");
      } else {
        this.isDialog = false;
        sessionStorage.setItem("showCheckedDisclaimer", JSON.stringify(true));
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 160px 30px;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }
  .img {
    width: 100%;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .content {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    margin-bottom: 32px;
  }
  .msgBox {
    width: 100%;
    padding: 16px 20px;
    box-sizing: border-box;
    background: rgba(255, 237, 190, 0.21);
    border-radius: 8px;
    line-height: 34px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    margin: 58px 0 42px 0;
    .left {
      width: 30px;
      margin-right: 10px;
    }
    .center {
      flex: 1;
    }
    .right {
      width: 16px;
    }
  }
  .infoBox {
    display: flex;
    .left {
      width: 220px;
      height: 220px;
      border-radius: 14px;
      overflow: hidden;
      margin-right: 36px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .center {
      flex: 1;
      padding-top: 16px;
      box-sizing: border-box;
      .userName {
        margin-top: 48px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .price {
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #e02020;
        span {
          font-size: 20px;
        }
      }
      .name {
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 12px;
      }
    }
    .right {
      margin-left: 36px;
      width: 44px;
      img {
        width: 100%;
      }
    }
  }
}
.disclaimer-container {
  max-height: 600px;
  padding: 60px 40px;
  overflow-y: scroll;
  .title {
    font-weight: 700;
  }
  p {
    margin: 10px 0;
  }
  .name {
    text-align: right;
  }
  .time {
    text-align: right;
  }
  .checkbox {
    margin: 10px;
    margin-bottom: none;
    &:deep(.van-checkbox__label) {
      color: #387df8 !important;
    }
  }
}
</style>

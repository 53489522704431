const routes = [
  //   运动打卡
  {
    path: "/futureHealth/health",
    name: "health",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/futureHealth/health"], resolve),
  },
  //   运动打卡列表
  {
    path: "/futureHealth/healthList",
    name: "healthList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/health/healthList.vue"], resolve),
  },
  //   运动打卡详情
  {
    path: "/futureHealth/healthDetail",
    name: "healthDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/health/healthDetail.vue"], resolve),
  },
  //   运动打卡审核列表
  {
    path: "/futureHealth/verifySport",
    name: "verifySport",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/verifySport/index.vue"], resolve),
  },
  //   运动打卡审核详情
  {
    path: "/futureHealth/verifySportDetail",
    name: "verifySportDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/verifySport/verifySportDetail.vue",
      ], resolve),
  },
  // 金钟健康心
  {
    path: "/futureHealth/jinzhongHealthyMind",
    name: "jinzhongHealthyMind",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/jinzhongHealthyMind/index.vue"], resolve),
  },
  // 菜品详情
  {
    path: "/futureHealth/foodDetail",
    name: "foodDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/foodDetail.vue",
      ], resolve),
  },
  // 身份认证
  {
    path: "/futureHealth/identityAuth",
    name: "identityAuth",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/identityAuth.vue",
      ], resolve),
  },
  // 我的订单
  {
    path: "/futureHealth/myFoodOrderList",
    name: "myFoodOrderList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/myFoodOrderList.vue",
      ], resolve),
  },
  // 我的订单-管理员
  {
    path: "/futureHealth/foodOrderList",
    name: "foodOrderList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/foodOrderList.vue",
      ], resolve),
  },
  // 我的订单-详情
  {
    path: "/futureHealth/myFoodOrderDetail",
    name: "myFoodOrderDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/myFoodOrderDetail.vue",
      ], resolve),
  },
  // 支付成功
  {
    path: "/futureHealth/deliverySuccess",
    name: "deliverySuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/deliverySuccess.vue",
      ], resolve),
  },
  // 选择就餐方式
  {
    path: "/futureHealth/chooseDiningMethod",
    name: "chooseDiningMethod",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/chooseDiningMethod.vue",
      ], resolve),
  },
  // 订单结算
  {
    path: "/futureHealth/payFoodOrder",
    name: "payFoodOrder",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/jinzhongHealthyMind/payFoodOrder.vue",
      ], resolve),
  },

  /* 金钟、青云社区 养老手环 */
  // 首页
  {
    path: "/futureHealth/elderCareBraceletList",
    name: "elderCareBraceletList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureHealth/elderCareBracelet/index.vue"], resolve),
  },
  // 添加设备
  {
    path: "/futureHealth/elderCareBraceletAdd",
    name: "elderCareBraceletAdd",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderCareBracelet/elderCareBraceletAdd.vue",
      ], resolve),
  },
  // 添加设备步骤一
  {
    path: "/futureHealth/elderCareBraceletStepsOne",
    name: "elderCareBraceletStepsOne",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderCareBracelet/steps/stepsOne.vue",
      ], resolve),
  },
  // 添加设备步骤二

  {
    path: "/futureHealth/elderCareBraceletStepsTwo",
    name: "elderCareBraceletStepsTwo",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderCareBracelet/steps/stepsTwo.vue",
      ], resolve),
  },
  // 添加设备步骤三

  {
    path: "/futureHealth/elderCareBraceletStepsThree",
    name: "elderCareBraceletStepsThree",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderCareBracelet/steps/stepsThree.vue",
      ], resolve),
  },
  // 添加设备步骤四

  {
    path: "/futureHealth/elderCareBraceletStepsFour",
    name: "elderCareBraceletStepsFour",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderCareBracelet/steps/stepsFour.vue",
      ], resolve),
  },

  // 个人详情-状态检查、异常查看、设备设置
  {
    path: "/futureHealth/elderCareBraceletDetailsTabs",
    name: "elderCareBraceletDetailsTabs",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderCareBracelet/details/detailsTabs.vue",
      ], resolve),
  },

  // 个人详情-状态检查、异常查看、设备设置
  {
    path: "/futureHealth/elderCareBraceletMapLocation",
    name: "elderCareBraceletMapLocation",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderCareBracelet/details/deviceSettings/mapLocation.vue",
      ], resolve),
  },

  // 个人详情-状态检查、异常查看、设备设置
  {
    path: "/futureHealth/elderCareBraceletMapTrajectory",
    name: "elderCareBraceletMapTrajectory",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderCareBracelet/details/deviceSettings/mapTrajectory.vue",
      ], resolve),
  },

  // 个人详情-状态检查、异常查看、设备设置
  {
    path: "/futureHealth/elderCareBraceletMapFence",
    name: "elderCareBraceletMapFence",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderCareBracelet/details/deviceSettings/mapFence.vue",
      ], resolve),
  },

  // 个人详情-状态检查、异常查看、设备设置
  {
    path: "/futureHealth/elderCareBraceletEmergencyNum",
    name: "elderCareBraceletEmergencyNum",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureHealth/elderCareBracelet/details/deviceSettings/emergencyNum.vue",
      ], resolve),
  },
  // // 个人详情-状态检查
  // {
  //   path: "/futureHealth/elderCareBraceletStatusCheck",
  //   name: "elderCareBraceletStatusCheck",
  //   meta: {
  //     title: "",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureHealth/elderCareBracelet/details/statusCheck.vue",
  //     ], resolve),
  // },
  // // 个人详情-异常查看
  // {
  //   path: "/futureHealth/elderCareBraceletCheckingExceptions",
  //   name: "elderCareBraceletCheckingExceptions",
  //   meta: {
  //     title: "",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureHealth/elderCareBracelet/details/checkingExceptions.vue",
  //     ], resolve),
  // },
  // // 个人详情-设备设置
  // {
  //   path: "/futureHealth/elderCareBraceletDeviceSettings",
  //   name: "elderCareBraceletDeviceSettings",
  //   meta: {
  //     title: "",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureHealth/elderCareBracelet/details/deviceSettings.vue",
  //     ], resolve),
  // },
  /* end */
  // 健康小屋（蓝绿健康）
  {
    path: "/futureHealth/healthHut",
    name: "healthHut",
    meta: {
      title: "体检报告",
    },
    component: (resolve) => require(["@/views/futureHealth/healthHut/index.vue"], resolve),
  },
  // 健康小屋（蓝绿健康）
  {
    path: "/futureHealth/healthHutDetail",
    name: "healthHutDetail",
    meta: {
      title: "体检详情",
    },
    component: (resolve) => require(["@/views/futureHealth/healthHut/healthHutDetail.vue"], resolve),
  },
];

export default routes;

<template>
  <div class="section">
    <div class="section-form">
      <div class="search-select" @click="clickPicker('buyType')">
        <span>{{ buyTypeMap[form.buyType] }}</span>
        <img src="../img/arrow_down.png" alt="" />
      </div>
      <div class="section-item">
        <div class="section-item--label">商品标题：</div>
        <div class="section-item--control">
          <v-input
            :readonly="isView"
            v-model.trim="form.goodsTitle"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item select">
        <div class="section-item--label">微信支付商家：</div>
        <div class="section-item--control" @click="clickPicker('subMchInfoId')">
          <v-input
            :value="subMchInfoIdMap[form.subMchInfoId]"
            readonly
            inputAlign="right"
            placeholder="请选择"
          ></v-input>
          <img src="../img/arrow_right_gray.png" alt="" />
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">商品数量：</div>
        <div class="section-item--control">
          <v-input
            type="number"
            :readonly="isView"
            v-model.trim="form.goodsCount"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">原价：</div>
        <div class="section-item--control">
          <v-input
            type="number"
            :readonly="isView"
            v-model.trim="form.originalCost"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">现价：</div>
        <div class="section-item--control">
          <v-input
            type="number"
            :readonly="isView"
            v-model.trim="form.nowCost"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item">
        <div class="section-item--label">产品规格：</div>
        <div class="section-item--control">
          <v-input
            :readonly="isView"
            v-model.trim="form.goodsSpecification"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item date">
        <div class="section-item--label">{{ buyTypeTxt }}开始时间：</div>
        <div class="section-item--control" @click="clickPicker('buyStartTime')">
          <v-input
            :value="form.buyStartTime"
            readonly
            inputAlign="right"
            placeholder="请选择"
          ></v-input>
          <img src="../img/arrow_right_gray.png" alt="" />
        </div>
      </div>

      <div class="section-item date">
        <div class="section-item--label">{{ buyTypeTxt }}结束时间：</div>
        <div class="section-item--control" @click="clickPicker('buyEndTime')">
          <v-input
            :value="form.buyEndTime"
            readonly
            inputAlign="right"
            placeholder="请选择"
          ></v-input>
          <img src="../img/arrow_right_gray.png" alt="" />
        </div>
      </div>

      <div class="section-item date">
        <div class="section-item--label">提货开始时间：</div>
        <div class="section-item--control" @click="clickPicker('getStartTime')">
          <v-input
            :value="form.getStartTime"
            readonly
            inputAlign="right"
            placeholder="请选择"
          ></v-input>
          <img src="../img/arrow_right_gray.png" alt="" />
        </div>
      </div>

      <div class="section-item date">
        <div class="section-item--label">提货结束时间：</div>
        <div class="section-item--control" @click="clickPicker('getEndTime')">
          <v-input
            :value="form.getEndTime"
            readonly
            inputAlign="right"
            placeholder="请选择"
          ></v-input>
          <img src="../img/arrow_right_gray.png" alt="" />
        </div>
      </div>

      <div class="section-item address">
        <div class="section-item--label">提货地点：</div>
        <div class="section-item--control">
          <div
            class="address"
            v-for="(item, index) in form.getAddress"
            :key="index"
          >
            <v-input
              :readonly="isView"
              v-model="form.getAddress[index]"
              inputAlign="right"
              placeholder="请输入"
            ></v-input>
            <template v-if="!isView">
              <img
                v-if="index == 0"
                src="../img/add_address_icon.png"
                alt=""
                @click="setAddress(item, index, form.getAddress)"
              />
              <img
                v-else
                src="../img/sub_address_icon.png"
                alt=""
                @click="setAddress(item, index, form.getAddress)"
              />
            </template>
          </div>
        </div>
      </div>

      <div class="section-item upload">
        <div class="section-item--label">封面图片：</div>
        <div class="section-item--control">
          <div class="upload">
            <template v-if="!isView">
              <v-upload
                :key="key"
                :imgUrl.sync="form.imageUrl"
                :activityPicture.sync="form.imageUrl1"
                ref="upload"
                :maxCount="1"
              ></v-upload>
            </template>
            <template v-else>
              <show-list :picList="form.imageUrl.split(',')"></show-list>
            </template>
          </div>
        </div>
      </div>

      <div class="section-item upload">
        <div class="section-item--label">介绍图片：</div>
        <div class="section-item--control">
          <div class="upload">
            <template v-if="!isView">
              <v-upload
                :key="key"
                :imgUrl.sync="form.detailsUrl"
                :activityPicture.sync="form.detailsUrl1"
                ref="upload"
                :maxCount="9"
              ></v-upload>
              <p>注：最多可上传9张图片</p>
            </template>
            <template v-else>
              <show-list :picList="form.detailsUrl.split(',')"></show-list>
            </template>
          </div>
        </div>
      </div>

      <div class="section-item" v-if="form.buyType == 1">
        <div class="section-item--label">拼团所需人数：</div>
        <div class="section-item--control">
          <v-input
            type="number"
            :readonly="isView"
            v-model.trim="form.groupBookingNeed"
            inputAlign="right"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="section-item textarea">
        <div class="section-item--label">商品介绍：</div>
        <div class="section-item--control">
          <v-input
            type="textarea"
            v-model.trim="form.content"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>

      <div class="surebtn" @click="submitBefore" v-if="!isView">
        <div class="btn">提交</div>
      </div>
    </div>

    <v-picker
      :columns="columnsOps"
      :valueShow="pickerShow"
      valueKey="text"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>

    <v-dateTimePicker
      :value="dateTime"
      :valueShow.sync="dateTimeShow"
      :isAuto="true"
      type="datetime"
      title="选择完整时间"
      @confirmPicker="confirmDate"
    ></v-dateTimePicker>

    <v-dialog
      class="section-dialog"
      v-model="isDialog"
      title="提示"
      confirmButtonText="我的发布"
      :cancelButtonText="`继续${form.id ? '编辑' : '发布'}`"
      confirmButtonColor="#FDA02DFF"
      @confirm="confirm"
    >
      <p>您的{{ form.id ? "编辑" : "发布" }}成功！</p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import {
  issueGroupBuyingURL,
  getGroupBuyingDetailsForMiniURL,
  getSubMchIdsURL,
} from "./api";
import {
  buyTypeMap,
  setBuyTypeOps,
  principalTypeMap,
  setPrincipalTypeOps,
  accountTypeMap,
  setAccountTypeOps,
} from "./map";
import showList from "../components/show-list.vue";
import { mapHelper } from "@/utils/utils.js";

export default {
  components: {
    showList,
  },
  data() {
    return {
      isView: false,
      // type: 1, // 团购类型 1:社区团购 2:限时秒杀
      buyTypeMap,
      buyTypeOps: setBuyTypeOps(),
      principalTypeMap,
      principalTypeOps: setPrincipalTypeOps(),
      accountTypeMap,
      accountTypeOps: setAccountTypeOps(),
      subMchInfoIdOps: [],
      subMchInfoIdMap: {},
      form: {
        id: "",
        buyType: 1,
        goodsTitle: "", // 商品标题
        goodsCount: undefined, // 商品数量
        originalCost: undefined, // 原价
        nowCost: undefined, // 现价
        goodsSpecification: "", // 商品规格
        buyStartTime: "", // 购买开始时间
        buyEndTime: "", // 购买结束时间
        getAddress: [""], // 提货地点
        getStartTime: "", // 提货开始时间
        getEndTime: "", // 提货结束时间
        imageUrl: "", // 封面图片地址
        imageUrl1: "", // 封面图片地址 适配upload组件
        detailsUrl: "", // 详情介绍图片
        detailsUrl1: "", // 详情介绍图片 适配upload组件
        groupBookingNeed: undefined, // 拼团所需人数
        content: "", // 商品介绍
        subMchInfoId: "", // 微信支付商户
      },
      initForm: {
        id: "",
        buyType: 1,
        goodsTitle: "", // 商品标题
        goodsCount: undefined, // 商品数量
        originalCost: undefined, // 原价
        nowCost: undefined, // 现价
        goodsSpecification: "", // 商品规格
        buyStartTime: "", // 购买开始时间
        buyEndTime: "", // 购买结束时间
        getAddress: [""], // 提货地点
        getStartTime: "", // 提货开始时间
        getEndTime: "", // 提货结束时间
        imageUrl: "", // 封面图片地址
        imageUrl1: "", // 封面图片地址 适配upload组件
        detailsUrl: "", // 详情介绍图片
        detailsUrl1: "", // 详情介绍图片 适配upload组件
        groupBookingNeed: undefined, // 拼团所需人数
        content: "", // 商品介绍
        subMchInfoId: "", // 微信支付商户
      },
      /* v-picker */
      type: "", // 字段名 用于picker判断值该赋给哪个字段
      columnsOps: [],
      pickerShow: false,
      /* v-dateTimePicker */
      dateTime: new Date(),
      dateTimeShow: false,
      /** v-dialog */
      isDialog: false,

      once: true,
      key: 0,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),

    buyTypeTxt() {
      return this.form.buyType == 1 ? "团购" : "秒杀";
    },

    addressValid() {
      return this.form.getAddress.filter((v) => !!v);
    },
  },
  watch: {
    isDialog(val) {
      if (!val && !this.form.id) {
        this.form = { ...this.initForm };
        // 刷新上传控件
        this.key++;
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const { id, isView } = this.$route.query;
      this.form.id = id;
      this.isView = !!isView;
      this.getSubMchInfoId();
      if (id) {
        this.getDetail();
      }
      this.once = !!id;
    },
    getDetail() {
      this.$axios
        .get(getGroupBuyingDetailsForMiniURL, {
          params: {
            id: this.form.id,
            userId: this.userId,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.update(res.data);
            return;
          }
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        });
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
      this.form.imageUrl1 = this.form.imageUrl;
      this.form.detailsUrl1 = this.form.detailsUrl;

      this.$nextTick(() => {
        this.once = false;
      });
    },
    submitBefore() {
      if (!this.valid()) {
        return;
      }
      this.submit();
    },
    submit() {
      this.$axios
        .post(issueGroupBuyingURL, {
          ...this.form,
          imageUrl: this.form.imageUrl1,
          detailsUrl: this.form.detailsUrl1,
          getAddress: this.addressValid,
          // houseId: this.houseId || this.communityId || undefined,
          tenantId: this.tenantId,
          optUserId: this.userId,
        })
        .then((res) => {
          if (res.code === 200) {
            // this.$router.replace({
            //   name: "bcnWelfarGroupManAudio",
            // });
            this.isDialog = true;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
    // 跳转我的发布页面
    confirm() {
      this.$router.go(-1);
    },

    clickPicker(type) {
      // 如果status 为true 则，表示点击不弹出弹框
      if (this.isView) {
        return;
      }
      this.type = type;
      // picker 逻辑
      if (type == "buyType") {
        this.pickerShow = true;
        this.columnsOps = this.buyTypeOps;
        return;
      } else if (type == "subMchInfoId") {
        this.pickerShow = true;
        this.columnsOps = this.subMchInfoIdOps;
        return;
      }
      // datePicker 逻辑
      this.dateTimeShow = true;
    },
    setAddress(item, index, arr) {
      // 点击第0个元素图片会新增地址，反之删除点击的地址
      if (index == 0) {
        arr.push("");
      } else {
        arr.splice(index, 1);
      }
    },
    /* v-picker */
    clickPickOverlay() {
      this.pickerShow = false;
    },
    cancelPick() {
      this.pickerShow = false;
    },
    confirmPick(val) {
      this.form[this.type] = val.value;
      this.pickerShow = false;
    },
    /* v-dateTimePicker */
    confirmDate(val) {
      this.form[this.type] = moment(val).format("YYYY-MM-DD HH:mm:ss");
    },

    // 验证必填项
    valid() {
      const {
        buyType,
        goodsTitle, // 商品标题
        goodsCount, // 商品数量
        originalCost, // 原价
        nowCost, // 现价
        goodsSpecification, // 商品规格
        buyStartTime, // 购买开始时间
        buyEndTime, // 购买结束时间
        // getAddress, // 提货地点
        getStartTime, // 提货开始时间
        getEndTime, // 提货结束时间
        // imageUrl, // 封面图片地址
        imageUrl1, // 封面图片 适配upload组件
        // detailsUrl, // 详情介绍图片
        detailsUrl1, // 详情介绍图片 适配upload组件
        groupBookingNeed, // 拼团所需人数
        // content,
        subMchInfoId,
      } = this.form;
      let message = undefined;
      if (goodsTitle === "") {
        message = "商品标题不可为空";
      } else if (subMchInfoId === "" || subMchInfoId === undefined) {
        message = "微信支付商家不可为空";
      } else if (goodsCount === "" || goodsCount === undefined) {
        message = "商品数量不可为空";
      } else if (!originalCost) {
        message = "原价不可为空";
      } else if (!nowCost) {
        message = "现价不可为空";
      } else if (goodsSpecification === "") {
        message = "商品规格不可为空";
      } else if (buyStartTime === "") {
        message = `${this.buyTypeTxt}开始时间不可为空`;
      } else if (buyEndTime === "") {
        message = `${this.buyTypeTxt}结束时间不可为空`;
      } else if (this.addressValid.length === 0) {
        message = "提货地点不可为空";
      } else if (getStartTime === "") {
        message = "提货开始时间不可为空";
      } else if (getEndTime === "") {
        message = "提货结束时间不可为空";
      } else if (imageUrl1 === "") {
        message = "封面图片不可为空";
      } else if (detailsUrl1 === "") {
        message = "介绍图片不可为空";
      } else if (buyType == 1 && !groupBookingNeed) {
        message = "拼团所需人数不可为空";
      } else if (
        moment(buyEndTime.replace(/-/g, "/")).isBefore(
          buyStartTime.replace(/-/g, "/")
        )
      ) {
        message = `${this.buyTypeTxt}开始时间不能大于${this.buyTypeTxt}结束时间`;
      } else if (
        moment(getEndTime.replace(/-/g, "/")).isBefore(
          getStartTime.replace(/-/g, "/")
        )
      ) {
        message = "提货开始时间不能大于提货结束时间";
      } else if (
        moment(getStartTime.replace(/-/g, "/")).isBefore(
          buyEndTime.replace(/-/g, "/")
        )
      ) {
        `${this.buyTypeTxt}结束时间不能大于提货开始时间`;
      }

      if (message) {
        this.$toast({
          message,
          duration: 1000,
        });
      }
      return !message;
    },

    /** 接口 */
    async getSubMchInfoId() {
      let res = await this.$axios.get(getSubMchIdsURL, {
        params: {
          userId: this.userId,
        },
      });
      if (res.code == 200 && res.success) {
        this.subMchInfoIdOps = res.data.map((v) => ({
          value: v.id,
          text: v.shopName,
        }));
      }
      console.log(this.subMchInfoIdOps, "this.sub---");
      const { map: subMchInfoIdMap } = mapHelper.setMap(this.subMchInfoIdOps);
      this.subMchInfoIdMap = subMchInfoIdMap;
    },
  },
};
</script>

<style lang="less" scoped>
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.section {
  font-family: PingFangSC-Regular, PingFang SC;
  box-sizing: border-box;
  background: #f6f8fb;
  min-height: 100%;
  width: 100%;
  padding: 30px;
  padding-bottom: calc(
    32px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    32px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-form {
    .search-select {
      box-sizing: border-box;
      width: 200px;
      height: 58px;
      border-radius: 10px;
      border: 2px solid rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      padding: 8px 12px 8px 16px;
      margin-bottom: 20px;
      > img {
        &:extend(.common-img);
        width: 28px;
        height: 16px;
      }
      span {
        flex: 1;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding: 32px 32px 50px 32px;
    background: #ffffff;
    .section-item {
      display: flex;
      border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      .section-item--label {
        flex: 1;
        line-height: 88px;
        color: #333333;
        font-size: 30px;
        text-align: left;
        font-weight: 400;
        white-space: nowrap;
      }
      .section-item--control {
        max-width: 400px;
      }
      &.radio {
        .section-item--control {
          display: flex;
          align-items: center;
          font-size: 28px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      &.textarea {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          ::v-deep .van-cell {
            border-radius: 16px;
            background: rgba(0, 0, 0, 0.05);
          }
        }
      }
      &.upload {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          ::v-deep .van-uploader {
            padding-bottom: 28px;
            .van-uploader__preview {
              .van-uploader__preview-image {
                vertical-align: middle;
                width: 160px;
                height: 160px;
                img {
                  vertical-align: middle;
                  overflow: hidden;
                  margin: 0;
                  padding: 0;
                }
              }
              .van-uploader__preview-delete {
                width: 28px;
                height: 28px;
                border-radius: 0 0 0 24px;
                i {
                  position: absolute;
                  vertical-align: middle;
                  font-size: 32px;
                  top: 45%;
                  left: 55%;
                  transform: translateX(-50%) translateY(-50%) scale(0.5);
                }
              }
            }
            .van-uploader__upload {
              width: 160px;
              height: 160px;
            }
          }
          ::v-deep .pic-box {
            margin-bottom: 28px;
          }
          p {
            font-size: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.25);
            line-height: 34px;
            margin-bottom: 28px;
          }
        }
      }
      &.card {
        display: block;
        .section-item--control {
          box-sizing: border-box;
          max-width: none;
          .card {
            padding-bottom: 28px;
            display: flex;
            ::v-deep .v-upload {
              flex: 1;
              height: 218px;
              &:not(:last-child) {
                margin-right: 30px;
              }
              &.front {
                .van-uploader__upload {
                  background: url(../img/idCard_front.png) no-repeat center
                    center;
                  background-size: 100% 100%;
                }
              }
              &.back {
                .van-uploader__upload {
                  background: url(../img/idCard_back.png) no-repeat center
                    center;
                  background-size: 100% 100%;
                }
              }
              .van-uploader {
                width: 100%;
                height: 100%;
                .van-uploader__wrapper {
                  width: 100%;
                  height: 100%;
                  .van-uploader__preview {
                    width: 100%;
                    height: 100%;
                    .van-uploader__preview-image {
                      width: 100%;
                      height: 100%;
                      img {
                        width: 100%;
                        height: 100%;
                        vertical-align: middle;
                        overflow: hidden;
                        margin: 0;
                        padding: 0;
                      }
                    }
                    .van-uploader__preview-delete {
                      width: 56px;
                      height: 56px;
                      border-radius: 0 0 0 56px;
                      i {
                        position: absolute;
                        vertical-align: middle;
                        font-size: 48px;
                        top: 45%;
                        left: 55%;
                        transform: translateX(-50%) translateY(-50%) scale(0.5);
                      }
                    }
                  }
                  .van-uploader__upload {
                    width: 100%;
                    height: 100%;
                    .van-uploader__upload-icon {
                      display: none;
                    }
                  }
                }
              }
            }
          }
          .tip {
            margin-bottom: 28px;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.25);
            > img {
              margin-right: 14px;
              width: 28px;
              height: 28px;
            }
            span {
              color: #db431d;
            }
          }
        }
      }
      &.select {
        .section-item--control {
          width: 400px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 28px;
          line-height: 40px;
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
          // color: rgba(0, 0, 0, 0.25);
          margin-left: 20px;
          ::v-deep .van-cell {
            padding-right: 0px;
          }
          img {
            margin-left: 8px;
            margin-right: 32px;
            width: 8px;
            height: 24px;
            vertical-align: middle;
          }
        }
      }
      &.date {
        .section-item--control {
          width: 400px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 28px;
          line-height: 40px;
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
          // color: rgba(0, 0, 0, 0.25);
          margin-left: 20px;
          ::v-deep .van-cell {
            padding-right: 0px;
          }
          img {
            &:extend(.common-img);
            margin-left: 8px;
            margin-right: 32px;
            width: 8px;
            height: 24px;
            vertical-align: middle;
          }
        }
      }
      &.address {
        .section-item--control {
          width: 400px;
          display: flex;
          flex-direction: column;
          font-size: 28px;
          margin-left: 20px;
          .address {
            display: flex;
            align-items: center;
            ::v-deep .van-cell {
              padding-right: 0px;
            }
            img {
              &:extend(.common-img);
              margin-left: 8px;
              margin-right: 32px;
              width: 42px;
              height: 42px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .surebtn {
      margin-top: 78px;
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 492px;
        height: 74px;
        background: linear-gradient(270deg, #c4e2ff 0%, #9ccdff 100%);
        border-radius: 39px;
        border: 8px solid #ecf7ff;
        font-weight: bold;
        font-size: 30px;
        color: #ffffff;
        letter-spacing: 4px;
      }
    }
  }
  .section-dialog {
    ::v-deep .van-dialog {
      .van-dialog__header {
        font-weight: bold;
      }
      p {
        padding: 0 132px;
        margin: 34px 0 74px;
        text-align: center;
        span {
          color: #f7b500;
        }
      }
    }
  }
}
</style>

<template>
  <div class="v-video-player" style="{ width, height }">
    <video-player
      ref="videoPlayer"
      class="video-player vjs-custom-skin"
      :playsinline="playsinline"
      :options="videoOption"
      @play="onPlayerPlay"
      @ready="onPlayerReady"
      @pause="onPlayerPause"
      @ended="onPlayerEnded"
      @timeupdate="onPlayerTimeUpdate"
    >
    </video-player>
  </div>
</template>

<script>
// import hls from "videojs-contrib-hls";
import { videoPlayer } from "vue-video-player";
import "videojs-contrib-hls";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

export default {
  components: {
    // hls,
    videoPlayer,
  },
  props: {
    videoOption: {
      type: Object,
      required: true,
    },
    playsinline: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  methods: {
    onPlayerPlay() {},
    onPlayerReady() {},
    onPlayerPause() {},
    onPlayerEnded() {},
    onPlayerTimeUpdate() {},
  },
};
</script>

<style scoped lang="less">
.v-video-player {
  .video-player {
    width: 100%;
    height: 100%;
  }
}
// ::v-deep .video-js .vjs-tech {
//   object-fit: cover;
// }
::v-deep .vjs-poster {
  background-size: cover;
}
</style>

const routes = [
  //活动签到-详情
  {
    path: "/futureNeighbourhood/activitySignInDetail",
    name: "activitySignInDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activitySignInDetail.vue",
      ], resolve),
  },
  //活动签到
  {
    path: "/futureNeighbourhood/activitySignIn",
    name: "activitySignIn",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activitySignIn.vue",
      ], resolve),
  },
  //志愿金钟
  {
    path: "/futureNeighbourhood/volunteerJinZhong",
    name: "volunteerJinZhong",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/volunteerJinZhong/index.vue",
      ], resolve),
  },
  // 活动管理
  //活动列表
  {
    path: "/futureNeighbourhood/activityList",
    name: "activityList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activityManage/activityList.vue",
      ], resolve),
  },
  //活动参与人列表
  {
    path: "/futureNeighbourhood/activityUserList",
    name: "activityUserList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activityManage/activityUserList.vue",
      ], resolve),
  },
  // 活动报名
  //活动列表
  {
    path: "/futureNeighbourhood/applyActivity",
    name: "applyActivity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/index"], resolve),
  },
  //青少年志愿活动
  {
    path: "/futureNeighbourhood/applyActivityQSN",
    name: "applyActivityQSN",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/index2.vue",
      ], resolve),
  },
  // 时间银行->志愿者申请 applyVolunteer
  {
    path: "/futureNeighbourhood/applyVolunteer",
    name: "applyVolunteer",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/applyVolunteer",
      ], resolve),
  },
  // 时间银行->承诺书
  {
    path: "/futureNeighbourhood/undertaking",
    name: "undertaking",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/undertaking",
      ], resolve),
  },

  // 时间银行->预约完成页面
  {
    path: "/futureNeighbourhood/complete",
    name: "complete",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/complete"], resolve),
  },
  {
    path: "/futureNeighbourhood/completexx",
    name: "completexx",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/completexx",
      ], resolve),
  },
  {
    path: "/futureNeighbourhood/completeqsn",
    name: "completeqsn",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/completeqsn",
      ], resolve),
  },
  // 时间银行->我的家庭
  {
    path: "/futureNeighbourhood/myFamilyList",
    name: "myFamilyList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/myFamilyList",
      ], resolve),
  },

  // 时间银行-新增家庭成员
  {
    path: "/futureNeighbourhood/addFamily",
    name: "addFamily",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/addFamily"], resolve),
  },
  //活动详情
  {
    path: "/futureNeighbourhood/activityDetail",
    name: "activityDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activityDetail",
      ], resolve),
  },
  // 发布活动
  {
    path: "/futureNeighbourhood/publishActivity",
    name: "publishActivity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/publishActivity",
      ], resolve),
  },
  //报名提交页面
  {
    path: "/futureNeighbourhood/sureSubmit",
    name: "sureSubmit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/sureSubmit",
      ], resolve),
  },
  //报名提交页面
  {
    path: "/futureNeighbourhood/sureSubmitxx",
    name: "sureSubmitxx",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/sureSubmitxx",
      ], resolve),
  }, //报名提交页面
  {
    path: "/futureNeighbourhood/sureSubmitqsn",
    name: "sureSubmitqsn",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/sureSubmitqsn",
      ], resolve),
  },
  //活动工单详情
  {
    path: "/futureNeighbourhood/orderDetail",
    name: "orderDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/orderDetail",
      ], resolve),
  },
  //新增联系人
  {
    path: "/futureNeighbourhood/addUser",
    name: "addUser",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/addUser"], resolve),
  },
  //我的报名
  {
    path: "/futureNeighbourhood/myActivityList",
    name: "myActivityList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/myActivityList",
      ], resolve),
  },
  //我发布的活动
  {
    path: "/futureNeighbourhood/myPublish",
    name: "myPublish",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/myPublish"], resolve),
  },
  // 提交社区共建
  {
    path: "/futureNeighbourhood/commonCreate",
    name: "commonCreate",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/commonCreate/index"], resolve),
  },
  //我的共建建议
  {
    path: "/futureNeighbourhood/myCreate",
    name: "myCreate",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/commonCreate/myCreate.vue",
      ], resolve),
  },
  //共建建议详情
  {
    path: "/futureNeighbourhood/createDetail",
    name: "createDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/commonCreate/createDetail.vue",
      ], resolve),
  },
  //社区共建墙
  {
    path: "/futureNeighbourhood/createList",
    name: "createList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/commonCreate/createList.vue",
      ], resolve),
  },
  // 公益捐赠
  {
    path: "/futureNeighbourhood/donate",
    name: "donate",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/index"], resolve),
  },
  //我的捐赠
  {
    path: "/futureNeighbourhood/myDonate",
    name: "myDonate",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/myDonate.vue"], resolve),
  },
  //捐赠详情
  {
    path: "/futureNeighbourhood/donateDetail",
    name: "donateDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/donateDetail.vue"], resolve),
  },
  //我的捐赠详情
  {
    path: "/futureNeighbourhood/myDonateDetail",
    name: "myDonateDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/donate/myDonateDetail.vue",
      ], resolve),
  },
  //捐赠墙
  {
    path: "/futureNeighbourhood/donateList",
    name: "donateList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/donateList.vue"], resolve),
  },
  //社区达人
  {
    path: "/futureNeighbourhood/talent",
    name: "talent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/talent/index"], resolve),
  },
  //申请社区达人
  {
    path: "/futureNeighbourhood/applyTalent",
    name: "applyTalent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/talent/applyTalent"], resolve),
  },
  // 和伙人风采列表页
  {
    path: "/futureNeighbourhood/partnerStyleList",
    name: "partnerStyleList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/partner/partnerStyleList.vue",
      ], resolve),
  },
  // 社区达人标题
  {
    path: "/futureNeighbourhood/partnerStyleTitle",
    name: "partnerStyleTitle",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/partner/partnerStyleTitle.vue",
      ], resolve),
  },
  // 回复
  {
    path: "/futureNeighbourhood/replyDetail",
    name: "replyDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/partner/replyDetail.vue"], resolve),
  },
  // 提交心愿
  {
    path: "/futureNeighbourhood/wishes",
    name: "wishes",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/index"], resolve),
  },
  //我的心愿
  {
    path: "/futureNeighbourhood/myWishes",
    name: "myWishes",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/myWishes.vue"], resolve),
  },
  //心愿详情
  {
    path: "/futureNeighbourhood/wishDetail",
    name: "wishDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/wishDetail.vue"], resolve),
  },
  //心愿墙列表
  {
    path: "/futureNeighbourhood/wishList",
    name: "wishList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/wishList.vue"], resolve),
  },
  //发布居民超话
  {
    path: "/futureNeighbourhood/releaseTopic",
    name: "releaseTopic",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/releaseTopic.vue"], resolve),
  },
  //话题列表
  {
    path: "/futureNeighbourhood/topicList",
    name: "topicList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/topicList.vue"], resolve),
  },
  //社区议事厅
  {
    path: "/futureNeighbourhood/communityDiscussion",
    name: "communityDiscussion",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/topic/communityDiscussion.vue",
      ], resolve),
  },
  //话题详情
  {
    path: "/futureNeighbourhood/topicTitle",
    name: "topicTitle",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/topicTitle.vue"], resolve),
  },
  //话题回复
  {
    path: "/futureNeighbourhood/topicReplyDetail",
    name: "topicReplyDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/topic/topicReplyDetail.vue",
      ], resolve),
  },
  //明珠互动
  {
    path: "/futureNeighbourhood/interaction",
    name: "interaction",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/interaction/index.vue"], resolve),
  },
  //社群
  {
    path: "/futureNeighbourhood/association",
    name: "association",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/association/index.vue"], resolve),
  },
  //创建社群
  {
    path: "/futureNeighbourhood/createCommunity",
    name: "createCommunity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/createCommunity.vue",
      ], resolve),
  },
  //社群动态
  {
    path: "/futureNeighbourhood/communitDynamic",
    name: "communitDynamic",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communitDynamic.vue",
      ], resolve),
  },
  //我的社群
  {
    path: "/futureNeighbourhood/myCommunity",
    name: "myCommunity",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/myCommunity.vue",
      ], resolve),
  },
  //编辑社群信息
  {
    path: "/futureNeighbourhood/communityInformationEdit",
    name: "communityInformationEdit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityInformationEdit.vue",
      ], resolve),
  },
  //活动详情
  {
    path: "/futureNeighbourhood/communityActivityDetail",
    name: "communityActivityDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityActivityDetail.vue",
      ], resolve),
  },
  //申请消息
  {
    path: "/futureNeighbourhood/communityApplicationInformation",
    name: "communityApplicationInformation",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityApplicationInformation.vue",
      ], resolve),
  },
  //申请消息详情
  {
    path: "/futureNeighbourhood/communityApplicationInformationDetail",
    name: "communityApplicationInformationDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityApplicationInformationDetail.vue",
      ], resolve),
  },
  //社群成员
  {
    path: "/futureNeighbourhood/communityMembers",
    name: "communityMembers",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityMembers.vue",
      ], resolve),
  },
  //发布活动
  {
    path: "/futureNeighbourhood/releaseActivities",
    name: "releaseActivities",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/releaseActivities.vue",
      ], resolve),
  },
  //发布成功
  {
    path: "/futureNeighbourhood/releaseSuccess",
    name: "releaseSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/releaseSuccess.vue",
      ], resolve),
  },
  //活动列表
  {
    path: "/futureNeighbourhood/communityActivityList",
    name: "communityActivityList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/communityActivityList.vue",
      ], resolve),
  },
  //提交资料
  {
    path: "/futureNeighbourhood/applyJoin",
    name: "applyJoin",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/applyJoin.vue",
      ], resolve),
  },
  //发表动态
  {
    path: "/futureNeighbourhood/publishingTrends",
    name: "publishingTrends",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/publishingTrends.vue",
      ], resolve),
  },
  //社群详情
  {
    path: "/futureNeighbourhood/associationDetail",
    name: "associationDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/associationDetail.vue",
      ], resolve),
  },
  //心愿审核列表
  {
    path: "/futureNeighbourhood/verifyWish",
    name: "verifyWish",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/verifyWish/index.vue"], resolve),
  },
  //心愿审核详情
  {
    path: "/futureNeighbourhood/verifyWishDetail",
    name: "verifyWishDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/verifyWish/verifyWishDetail.vue",
      ], resolve),
  },
  // 安全智护
  {
    path: "/futureNeighbourhood/safetyShield",
    name: "safetyShield",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/safetyShield/index.vue"], resolve),
  },
  // 安全智护申请成功
  {
    path: "/futureNeighbourhood/safetyShieldApplySuccess",
    name: "safetyShieldApplySuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/safetyShield/safetyShieldApplySuccess.vue",
      ], resolve),
  },
  // 安全智护申请
  {
    path: "/futureNeighbourhood/safetyShieldApply",
    name: "safetyShieldApply",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/safetyShield/safetyShieldApply.vue",
      ], resolve),
  },
  //明珠管家
  {
    path: "/futureNeighbourhood/xiaoheHouseKeeper",
    name: "xiaoheHouseKeeper",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/xiaoheHouseKeeper/index.vue",
      ], resolve),
  },
  //点餐
  {
    path: "/futureNeighbourhood/orderMeal",
    name: "orderMeal",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/orderMeal/index.vue"], resolve),
  },
  //点餐确认订单
  {
    path: "/futureNeighbourhood/toOrderMeal",
    name: "toOrderMeal",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/toOrderMeal.vue",
      ], resolve),
  },
  //点餐成功
  {
    path: "/futureNeighbourhood/orderMealSuccess",
    name: "orderMealSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/orderMealSuccess.vue",
      ], resolve),
  },
  //商家管理订单
  {
    path: "/futureNeighbourhood/bussinessMeal",
    name: "bussinessMeal",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/bussinessMeal.vue",
      ], resolve),
  },
  //点餐订单
  {
    path: "/futureNeighbourhood/orderMealList",
    name: "orderMealList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/orderMealList.vue",
      ], resolve),
  },
  // 明珠生活通首页
  {
    path: "/futureNeighbourhood/lifeExpert",
    name: "lifeExpert",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/lifeExpert/index.vue"], resolve),
  },
  // 明珠生活通列表
  {
    path: "/futureNeighbourhood/lifeExpertList",
    name: "lifeExpertList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/lifeExpert/lifeExpertList.vue",
      ], resolve),
  },
  // 明珠生活通查询页面
  {
    path: "/futureNeighbourhood/lifeExpertSearch",
    name: "lifeExpertSearch",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/lifeExpert/lifeExpertSearch.vue",
      ], resolve),
  },
  // 装修贴士-首页
  {
    path: "/futureNeighbourhood/decorationTips",
    name: "decorationTips",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/decorationTips/index.vue",
      ], resolve),
  },
  // 装修贴士-详情页
  {
    path: "/futureNeighbourhood/decorationTipsDetail",
    name: "decorationTipsDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/decorationTips/decorationTipsDetail.vue",
      ], resolve),
  },
  // 楼宇管理-我的走访
  {
    path: "/futureNeighbourhood/myVisit",
    name: "myVisit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/buildingManagement/myVisit.vue",
      ], resolve),
  },
  // 楼宇管理-新增走访
  {
    path: "/futureNeighbourhood/addMyVisit",
    name: "addMyVisit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/buildingManagement/addMyVisit.vue",
      ], resolve),
  },
  // 楼宇管理-走访详情
  {
    path: "/futureNeighbourhood/myVisitDetail",
    name: "myVisitDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/buildingManagement/myVisitDetail.vue",
      ], resolve),
  },
  // 楼宇管理-发布成功
  {
    path: "/futureNeighbourhood/addMyVisitSuccess",
    name: "addMyVisitSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/buildingManagement/addMyVisitSuccess.vue",
      ], resolve),
  },
  // 物业服务首页
  {
    path: "/futureNeighbourhood/propertyServices",
    name: "propertyServices",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/propertyServices/index.vue",
      ], resolve),
  },
  // 物业服务-公告列表
  {
    path: "/futureNeighbourhood/propertyNoticeList",
    name: "propertyNoticeList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/propertyServices/propertyNoticeList.vue",
      ], resolve),
  },
  // 物业服务-公告详情
  {
    path: "/futureNeighbourhood/propertyNoticeDetail",
    name: "propertyNoticeDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/propertyServices/propertyNoticeDetail.vue",
      ], resolve),
  },
  // 物业服务-评分
  {
    path: "/futureNeighbourhood/propertyRat",
    name: "propertyRat",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/propertyServices/propertyRat.vue",
      ], resolve),
  },
  // 勤学专线
  {
    path: "/futureNeighbourhood/studyExpertLine",
    name: "studyExpertLine",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/studyExpertLine/index.vue",
      ], resolve),
  },
  // 勤学专线-签到列表
  {
    path: "/futureNeighbourhood/studySignList",
    name: "studySignList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/studyExpertLine/studySignList.vue",
      ], resolve),
  },
  // 勤学专线-新增签到数据
  {
    path: "/futureNeighbourhood/addStudySignInfo",
    name: "addStudySignInfo",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/studyExpertLine/addStudySignInfo.vue",
      ], resolve),
  },
  // 乡贤文化-首页
  {
    path: "/futureNeighbourhood/villageScholarCulture",
    name: "villageScholarCulture",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/villageScholarCulture/index.vue",
      ], resolve),
  },
  // 乡贤文化-乡贤风采详情
  {
    path: "/futureNeighbourhood/villageScholarStyleDetail",
    name: "villageScholarStyleDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/villageScholarCulture/villageScholarStyleDetail.vue",
      ], resolve),
  },
  // 空间预约-首页
  {
    path: "/futureNeighbourhood/spaceReservation",
    name: "spaceReservation",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/spaceReservation/index.vue",
      ], resolve),
  },
  // 空间预约-空间详情
  {
    path: "/futureNeighbourhood/spaceDetail",
    name: "spaceDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/spaceReservation/spaceDetail.vue",
      ], resolve),
  },
  // 空间预约-我的预约
  {
    path: "/futureNeighbourhood/myReservation",
    name: "myReservation",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/spaceReservation/myReservation.vue",
      ], resolve),
  },
  // 社区点单
  {
    path: "/futureNeighbourhood/communityOrdering",
    name: "communityOrdering",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/communityOrdering/index.vue",
      ], resolve),
  },
  // 社区点单-确认报名
  {
    path: "/futureNeighbourhood/communityOrderingSureSubmit",
    name: "communityOrderingSureSubmit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/communityOrdering/sureSubmit.vue",
      ], resolve),
  },
  // 社区点单-确认报名
  {
    path: "/futureNeighbourhood/communityOrderingAddUser",
    name: "communityOrderingAddUser",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/communityOrdering/addUser.vue",
      ], resolve),
  },
  // 社区点单-完成报名
  {
    path: "/futureNeighbourhood/communityOrderingComplete",
    name: "communityOrderingComplete",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/communityOrdering/complete.vue",
      ], resolve),
  },
  //我的点单
  {
    path: "/futureNeighbourhood/myCommunityOrdering",
    name: "myCommunityOrdering",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/communityOrdering/myCommunityOrdering.vue",
      ], resolve),
  },
  // 红色旅游
  {
    path: "/futureNeighbourhood/visitRedNotice",
    name: "visitRedNotice",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/intangibleCulturalHeritage/visitRedNotice.vue",
      ], resolve),
  },
  // 红色旅游
  {
    path: "/futureNeighbourhood/peopleIntro",
    name: "peopleIntro",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/intangibleCulturalHeritage/peopleIntro.vue",
      ], resolve),
  },
  // 红色旅游
  {
    path: "/futureNeighbourhood/redTour",
    name: "redTour",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/intangibleCulturalHeritage/redTour.vue",
      ], resolve),
  },
  // 弥勒文化宣传-首页
  {
    path: "/futureNeighbourhood/intangibleCulturalHeritage",
    name: "intangibleCulturalHeritage",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/intangibleCulturalHeritage/index.vue",
      ], resolve),
  },
  // 弥勒文化宣传-头条
  {
    path: "/futureNeighbourhood/intangibleCulturalHeritageHeadlines",
    name: "intangibleCulturalHeritageHeadlines",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/intangibleCulturalHeritage/intangibleCulturalHeritageHeadlines.vue",
      ], resolve),
  },
  // 弥勒文化宣传-头条详情
  {
    path: "/futureNeighbourhood/headlinesDetail",
    name: "headlinesDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/intangibleCulturalHeritage/headlinesDetail.vue",
      ], resolve),
  },
  // 弥勒文化宣传-参观须知
  {
    path: "/futureNeighbourhood/visitNotice",
    name: "visitNotice",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/intangibleCulturalHeritage/visitNotice.vue",
      ], resolve),
  },
  // 弥勒文化宣传-数字化展厅
  {
    path: "/futureNeighbourhood/digitalExhibitionHall",
    name: "digitalExhibitionHall",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/intangibleCulturalHeritage/digitalExhibitionHall.vue",
      ], resolve),
  },
  //三彩课堂
  {
    path: "/futureNeighbourhood/threeColorClassroom",
    name: "threeColorClassroom",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/threeColorClassroom/index.vue",
      ], resolve),
  },
  //爱心集市
  {
    path: "/futureNeighbourhood/loveMarket",
    name: "loveMarket",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/loveMarket/index.vue"], resolve),
  },
];

export default routes;

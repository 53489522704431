<template>
  <div class="section">
    <!-- <div class="section-detail">
      <div class="detail-pic">
        <img :src="detail.picture" alt="" />
      </div>
      <div class="detail-title">{{ detail.title }}</div>
    </div> -->

    <div class="section-detail">
      <div class="detail-time">
        <span>下单时间：{{ detail.joinTime }}</span>
        <span>{{ statusMap[detail.status] }}</span>
      </div>
      <div class="detail-flex">
        <div class="flex-picture">
          <img :src="detail.picture" alt="" />
        </div>
        <div class="flex-item">
          <div class="item-detail-title">
            <span>{{ detail.title }}</span>
          </div>
          <div class="item-detail-price">
            <span>{{ detail.price }}元</span>
            <span>x{{ detail.orderGoodsNum }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="section-total">
      <span>总{{ detail.totalFee }}</span>
    </div>

    <div class="section-order">
      <div class="order-flex">
        <div class="order-flex-label">下单用户：</div>
        <div class="order-flex-value">{{ detail.userName }}</div>
      </div>
      <div class="order-flex">
        <div class="order-flex-label">联系电话：</div>
        <div class="order-flex-value">{{ detail.userMobileNumber }}</div>
      </div>
      <!-- <div class="order-flex">
        <div class="order-flex-label">所属社区：</div>
        <div class="order-flex-value">{{ detail.path }}</div>
      </div> -->
      <!-- <div class="order-flex">
        <div class="order-flex-label">所属小区：</div>
        <div class="order-flex-value">{{ detail.communityName }}</div>
      </div> -->
      <div class="order-flex">
        <div class="order-flex-label">订单号：</div>
        <div class="order-flex-value">{{ detail.outTradeNo }}</div>
      </div>
    </div>

    <div class="section-footer" v-if="detail.status == 1">
      <div class="refund" @click="openDialog('2')">退款</div>
      <div class="complete" @click="openDialog('3')">完成</div>
    </div>

    <v-dialog
      class="section-dialog"
      v-model="isDialog"
      title="提示"
      confirmButtonColor="#FDA02DFF"
      @confirm="submit"
    >
      <p>{{ dialogTxt }}</p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getLeaderAndShopOrderDetailsURL,
  submitGoodsGetInfoURL,
  refundOrderURL,
  queryRefundOrderURL,
} from "./api";
// import { statusMap } from "./map";

export default {
  data() {
    return {
      id: "",
      statusMap: {
        1: "待发货",
        2: "已完成",
        3: "已退款",
      },
      detail: {
        // status: 1,
        // picture: "",
        // title: "",
        // price: "",
        // exchangeGoodsNum: "",
        // insertTime: "",
        // outTradeNo: "",
      },

      /** v-dialog */
      isDialog: false,
      curItem: null,
      dialogTxt: "",
      dialogStatus: undefined,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    async getDetail() {
      let params = {
        id: this.id,
        type: 4,
      };
      let res = await this.$axios.get(getLeaderAndShopOrderDetailsURL, {
        params,
      });
      if (res.code === 200) {
        this.detail = res.data;
      }
    },
    openDialog(status) {
      let txt = "";
      this.dialogStatus = status;
      if (status == 2) {
        txt = "是否确认退款";
      } else if (status == 3) {
        txt = "是否确认完成订单";
      }
      this.dialogTxt = txt;
      this.isDialog = true;
    },
    submit() {
      if (this.dialogStatus == 2) {
        this.refund();
      } else if (this.dialogStatus == 3) {
        this.complete();
      }
    },
    // 完成
    complete() {
      this.$axios
        .post(submitGoodsGetInfoURL, {
          id: this.id,
          type: 4,
          userId: this.userId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.getDetail();
            this.isDialog = false;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        });
    },
    // 退款
    async refund() {
      let res = await this.$axios.post(refundOrderURL, null, {
        params: {
          id: this.id,
          orderSn: this.detail.outTradeNo,
        },
      });
      if (res.code === 200) {
        await this.queryRefund(res.data.outTradeNo);
        this.isDialog = false;
        this.$toast("操作成功，退款进行中。。。");
        return;
      }
      this.$toast(res.msg || "操作失败，请稍后重试！");
    },
    async queryRefund(outTradeNo) {
      let res = await this.$axios.get(queryRefundOrderURL, {
        params: {
          outTradeNo,
          userId: this.userId,
        },
      });
      if (res.code === 200) {
        return;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.section {
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 30px 32px 0;
  padding-bottom: calc(
    140px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    140px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-detail {
    margin-top: 30px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    border-radius: 16px;
    .detail-time {
      display: flex;
      align-items: center;
      padding: 22px 20px 16px;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
      span:first-child {
        flex: 1;
      }
    }
    .detail-flex {
      padding: 22px 20px;
      border-top: 2px solid rgba(0, 0, 0, 0.06);
      display: flex;
      align-items: center;
      .flex-picture {
        width: 160px;
        min-width: 160px;
        height: 160px;
        border-radius: 16px;
        overflow: hidden;
        margin-right: 28px;
        > img {
          &:extend(.common-img);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .flex-item {
        flex: 1;
        .item-detail-title {
          font-size: 32px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 44px;
          word-break: break-all;
          word-wrap: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .item-detail-price {
          margin-top: 8px;
          font-size: 32px;
          font-weight: 400;
          line-height: 44px;
          color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: space-between;
          span:first-child {
            flex: 1;
            color: #fe9616;
          }
        }
      }
    }
  }
  .section-total {
    text-align: right;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    padding: 32px 0;
    border-bottom: 2px solid #f5f5f5;
  }
  .section-order {
    margin-top: 102px;
    font-size: 32px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 48px;
    .order-flex {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      display: flex;
      .order-flex-label,
      .order-flex-value {
        display: inline-block;
      }
      .order-flex-label {
        width: 180px;
        min-width: 180px;
      }
      .order-flex-value {
        flex: 1;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
  .section-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: calc(
      40px + constant(safe-area-inset-bottom)
    ); /* 兼容 iOS < 11.2 */
    padding-bottom: calc(
      40px + env(safe-area-inset-bottom)
    ); /* 兼容 iOS >= 11.2 */
    .refund,
    .complete {
      box-sizing: border-box;
      display: inline-block;
      text-align: center;
      width: 550px;
      height: 70px;
      line-height: 70px;
      border-radius: 16px;
      font-size: 30px;
      font-weight: bold;
      // background: #db431d;
      // color: #ffffff;
    }
    .refund {
      margin-right: 30px;
      width: 200px;
      border: 2px solid #db431d;
      color: #db431d;
    }
    .complete {
      width: 400px;
      background: #db431d;
      color: #ffffff;
    }
  }
  .section-dialog {
    ::v-deep .van-dialog {
      .van-dialog__header {
        font-weight: bold;
      }
      p {
        margin: 34px 0 74px;
        text-align: center;
      }
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"tab"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,class:['item', { active: _vm.curTab.type == item.type }],on:{"click":function($event){return _vm.changeTab(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('v-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.curTab.type == 1),expression:"curTab.type == 1"}]},[_c('v-list',{ref:"list",attrs:{"finished":_vm.finished,"finishedText":_vm.finishedText},on:{"onLoad":_vm.onLoad}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"list-content",on:{"click":function($event){return _vm.toRouteTitle(item)}}},[_c('div',{staticClass:"list-flex"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":item.activityPicture,"alt":""}})]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrapper-title"},[_c('span',[_vm._v(_vm._s(item.activityName))])]),_c('div',{staticClass:"wrapper-date start"},[_c('span',[_vm._v("活动开始时间："+_vm._s(item.startDate))])]),_c('div',{staticClass:"wrapper-date end"},[_c('span',[_vm._v("活动结束时间："+_vm._s(item.endDate))])]),_c('div',{staticClass:"wrapper-content"},[_c('div',{staticClass:"content-tag"},[_c('div',{staticClass:"iconText"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../img/newPeople.png")}})]),_c('div',{staticClass:"text"},[_c('span',[_vm._v(_vm._s(item.applyUserCount || 0))])])]),_c('div',{staticClass:"iconText"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../img/comment.png")}})]),_c('div',{staticClass:"text"},[_c('span',[_vm._v(_vm._s(item.commentCount || 0))])])])])])]),_c('div',{staticClass:"operate"},[_c('div',{class:[
                  'btn',
                  {
                    applying: item.activityStatus == 2,
                    going: item.activityStatus == 4,
                    back: item.hasFeedback && item.hasFeedback == 1,
                  },
                ]},[_c('span',[_vm._v(_vm._s(_vm.getOption(item.activityStatus, item.hasFeedback, item)))])])])])])}),0)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.curTab.type == 2),expression:"curTab.type == 2"}]},[_c('v-list',{ref:"list2",attrs:{"finished":_vm.finished2,"finishedText":_vm.finishedText2},on:{"onLoad":_vm.getTableData}},_vm._l((_vm.tableData),function(item,index){return _c('div',{key:index,staticClass:"list-content",on:{"click":function($event){return _vm.toRouteDetail(item)}}},[_c('div',{staticClass:"list-flex"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":item.picture,"alt":""}})]),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"wrapper-title"},[_c('span',[_vm._v(_vm._s(item.assignmentName))])]),_c('div',{staticClass:"wrapper-row"},[_vm._v(" 预估所需资金："),_c('span',[_vm._v(_vm._s(item.estimatePrice)+" 元")])]),_c('div',{staticClass:"wrapper-row"},[_vm._v(" 任务认领所需人数："),_c('span',[_vm._v(_vm._s(item.confinePersons)+" 人")])])]),_c('div',{staticClass:"operate"},[_c('div',{class:[
                  'applyBtn',
                  {
                    applying: item.status == 2, // 1 认领中 2 已完成认领
                  },
                ]},[_c('span',[_vm._v(_vm._s(_vm.statusMap[item.status]))])])])])])}),0)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
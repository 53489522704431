<template>
  <div class="page">
    <img src="./img/ranking-list.png" alt="" class="banner" />
    <div class="list">
      <div class="headLine">
        <div style="flex: 1">排名</div>
        <div style="flex: 1">名称</div>
        <div style="flex: 2; text-align: right">参与活动次数</div>
      </div>
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div class="item" v-for="(t, i) in listData" :key="i">
          <div class="index" style="flex: 1">
            <div class="box" v-if="i + 1 == 1 || i + 1 == 2 || i + 1 == 3">
              <img v-if="i + 1 == 1" src="./img/no1.png" alt="" />
              <img v-if="i + 1 == 2" src="./img/no2.png" alt="" />
              <img v-if="i + 1 == 3" src="./img/no3.png" alt="" />
              <div class="ranking">{{ i + 1 }}</div>
            </div>
            <div v-else class="box2">
              {{ i + 1 }}
            </div>
          </div>
          <div class="name" style="flex: 1">{{ t.name }}</div>
          <div class="num" style="flex: 2; text-align: right">
            {{ t.activityNumber }}
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { nanxiSisterRankingListUrl } from "./api.js";
export default {
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {},

  methods: {
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios
        .get(nanxiSisterRankingListUrl, { params: params })
        .then((res) => {
          if (res.code == 200) {
            if (res.code === 200) {
              if (res.data.pages <= res.data.current) {
                this.finished = true;
              } else {
                this.$refs.list._data.loading = false;
              }
              this.finishedText = "没有更多了";
              if (res.data.records.length === 0) {
                this.finishedText = "";
                return;
              }
              res.data.records.forEach((item) => {
                this.listData.push(item);
              });
            } else {
            }
          }
        });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 0 90px;
  .list {
    padding: 0 42px 0 48px;
    box-sizing: border-box;
    .item {
      display: flex;
      margin-bottom: 60px;
      align-items: center;
      .num {
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #317dff;
      }
      .name {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
      .index {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.5);
        .box2 {
          width: 54px;
          text-align: center;
        }
        .box {
          width: 54px;
          height: 62px;
          position: relative;
          .ranking {
            position: absolute;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .headLine {
      display: flex;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      padding-bottom: 60px;
      padding-top: 42px;
    }
  }
  .banner {
    width: 100%;
  }
}
</style>
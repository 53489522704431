<template>
  <div class="page">
    <div class="section-text">请确保在完成步骤一的情况下开始以下操作！</div>
    <div class="section-picture">
      <img src="../img/stepsTwoFirst.png" alt="" />
      <img src="../img/stepsTwoSecond.png" alt="" />
    </div>
    <div class="section-footer">
      <div class="footer-btn" @click="confirm">
        <span>我已确认完成以上步骤</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    confirm() {
      let stepsDetail = JSON.parse(sessionStorage.getItem("stepsDetail"));
      stepsDetail.stepTwo.status = true;
      sessionStorage.setItem("stepsDetail", JSON.stringify(stepsDetail));
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  min-height: 100%;
  background: #fff;
  /* 兼容 iOS < 11.2 */
  padding-bottom: calc(72px + constant(safe-area-inset-bottom));
  /* 兼容 iOS >= 11.2 */
  padding-bottom: calc(72px + env(safe-area-inset-bottom));
  .section-text {
    padding: 30px 30px 50px 30px;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
  }
  .section-picture {
    margin-bottom: 64px;
    > img {
      width: 100%;
    }
  }
  .section-footer {
    background: #bddabd;
    margin: 0px 64px 0px;
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    border-radius: 10px;
    line-height: 72px;
    text-align: center;
  }
}
</style>

<template>
  <div class="page">
    <img src="./img/banner.png" alt="" class="banner" />
    <div class="opt-box">
      <div
        class="opt-item"
        @click="toPath(t.routerName)"
        v-for="(t, i) in optList"
        :key="i"
      >
        <img :src="t.url" alt="" />
        {{ t.name }}
      </div>
    </div>
    <div class="main-title" @click="toPath('informationRelease')">
      <div>资讯发布</div>
      <div class="more">更多</div>
      <img src="./img/arrow.png" alt="" />
    </div>
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toRouteTitle(item)"
        >
          <div class="left">
            <img :src="item.topicPicture" alt="" />
          </div>
          <div class="right">
            <div class="name">{{ item.topicTitle }}</div>
            <div class="time">{{ item.createTime }}</div>
            <div class="bottom-box">
              <img src="./img/look-icon.png" alt="" />
              <div>{{ item.readCount }}</div>
              <img src="./img/message-icon.png" alt="" />
              <div>{{ item.commentCount }}</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getTopicListUrl } from "./api.js";
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { toRegister, gloabalCount } from "@/utils/common.js";
export default {
  name: "ruleOfLawClassroom",
  data() {
    return {
      optList: [
        {
          url: require("./img/kcbm.png"),
          name: "课程报名",
          routerName: "courseRegistration",
        },
        {
          url: require("./img/ly.png"),
          name: "留言",
          routerName: "leavingMessage",
        },
      ],
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 10,
    };
  },
  mounted() {
    gloabalCount("", 92, 1);
  },
  methods: {
    async toPath(name) {
      if (name == "leavingMessage") {
        if (await toRegister(this.$route.path, this.$route.query, "法制留言")) {
          this.$router.push({
            name: name,
          });
        }
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListUrl, {
          params: {
            tenantId: this.tenantId,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 41, //32 资讯发布,33 树标杆,34 干实事,35 定制度
            curPage: this.curPage,
            pageSize: this.pageSize,
            houseId: this.houseId || this.communityId || undefined,
            sortField: "NEW", // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.listData.forEach((ele) => {
              if (ele.topicPicture) {
                ele.topicPicture = this.$handleImg(256, 256, ele.topicPicture);
              }
            });
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 点击跳转话题详情页
    toRouteTitle(item) {
      let params = {
        id: item.id,
        topicType: 41,
        readEarnPoints: item.readEarnPoints,
        relationId: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  background: #fafafa;
  padding-bottom: 86px;
  .list {
    padding: 0 30px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 256px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      margin-bottom: 30px;
      overflow: hidden;
      display: flex;
      .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 38px 44px 0 20px;
        box-sizing: border-box;
        .bottom-box {
          display: flex;
          align-items: center;
          margin-top: 10px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          img {
            width: 32px;
            margin-right: 8px;
          }
          div {
            margin-right: 32px;
          }
        }
        .time {
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
        }
        .name {
          width: 366px;
          white-space: nowrap;
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-bottom: 72px;
          box-sizing: border-box;
        }
      }
      .left {
        width: 256px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
  .main-title {
    display: flex;
    align-items: center;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1c34;
    padding: 60px 30px 20px 30px;
    box-sizing: border-box;
    .more {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      flex: 1;
      text-align: right;
      padding-right: 10px;
      box-sizing: border-box;
    }
    img {
      width: 12px;
    }
  }
  .opt-box {
    width: 100%;
    height: 188px;
    margin-top: -80px;
    position: relative;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .opt-item {
      width: 330px;
      height: 188px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0f0e47;
      display: flex;
      align-items: center;
      padding-left: 32px;
      box-sizing: border-box;
      img {
        width: 122px;
        margin-right: 18px;
      }
    }
  }
  .banner {
    width: 100%;
    height: 368px;
    display: block;
  }
}
</style>

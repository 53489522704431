<template>
  <div class="section">
    <div class="section-tabs">
      <v-dropDownMenu
        :key="`key_status_${once}`"
        :disabled="once"
        v-model="status"
        :option1="statusOps"
        @change="changeTabs"
      ></v-dropDownMenu>
      <v-dropDownMenu
        :key="`key_type_${once}`"
        :disabled="once"
        v-model="type"
        :option1="typeOps"
        @change="changeTabs"
      ></v-dropDownMenu>
    </div>
    <div class="section-list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="section-flex-item"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item-time">
            <div class="type">
              <span>{{ typeMap[item.type] }}</span>
            </div>
            <div class="time">
              <template
                v-if="
                  item.type != 3 &&
                  (item.status == 1 || item.status == 3) &&
                  item.buyEndTime &&
                  getTime(item.buyEndTime) > 0
                "
              >
                <span class="label">距离结束：</span>
                <v-count-down
                  :time="getTime(item.buyEndTime)"
                  @finish="finish(item)"
                >
                  <template #default="timeData">
                    <div class="timeData">
                      <span class="block">{{ timeData.days }}</span>
                      <span class="colon">天</span>
                      <span class="block">{{ timeData.hours }}</span>
                      <span class="colon">:</span>
                      <span class="block">{{ timeData.minutes }}</span>
                      <span class="colon">:</span>
                      <span class="block">{{ timeData.seconds }}</span>
                    </div>
                  </template>
                </v-count-down>
              </template>
            </div>
          </div>
          <div class="item-flex" @click="toDetail(item)">
            <div class="item-picture">
              <img :src="item.picture" alt="" />
            </div>
            <div class="item-detail">
              <div class="item-detail-title">
                <span>{{ item.title }}</span>
              </div>
              <div class="item-detail-time">
                <span>参加时间：{{ item.orderTime }}</span>
              </div>
              <div class="item-detail-count">
                <span></span>
                <span>￥{{ item.price }}</span>
              </div>
            </div>
          </div>
          <div class="item-button">
            <template v-if="status == 1">
              <div class="btn cancel" @click="cancelOrder(item)">取消订单</div>
              <div
                class="btn pay"
                v-if="
                  item.type == 3 ||
                  (item.buyEndTime && getTime(item.buyEndTime) > 0)
                "
                @click="payOrder(item)"
              >
                立即付款
              </div>
            </template>
            <template v-if="status == 2">
              <div class="btn pay" v-if="item.status == 2">待发货</div>
              <div class="btn pending" v-else>进行中</div>
            </template>
            <template v-if="status == 3">
              <div class="btn complete" v-if="item.status == 4">待收货</div>
              <div class="btn over" v-else-if="item.status == 5">
                取消已退款
              </div>
              <div class="btn over" v-else>已收货</div>
            </template>
          </div>
        </div>
      </v-list>
    </div>
    <v-dialog
      class="section-dialog"
      v-model="isDialog"
      title="提示"
      confirmButtonColor="#FDA02DFF"
      @confirm="submit"
    >
      <p>确认取消订单吗？</p>
    </v-dialog>

    <div class="popup" v-if="showPopup">
      <div class="popup-overlary" @click="showPopup = false"></div>
      <!-- <div class="popup-content">
        <div class="content-title">选择提货点：</div>
        <div class="content-select">
          <div
            :class="['select-option', curAddress === o ? 'current' : '']"
            v-for="(o, index) in curItem.getAddresses"
            :key="index"
            @click="changeAddress(o)"
          >
            <span class="icon"></span>
            <span>{{ o }}</span>
          </div>
        </div>
        <div class="popup-footer">
          <div class="footer-button" @click="payNow">立即支付</div>
        </div>
      </div> -->

      <template v-if="step === 1">
        <div class="popup-content stepOne">
          <div class="content-title">选择提货点：</div>
          <div class="content-select">
            <div
              :class="['select-option', curAddress === o ? 'current' : '']"
              v-for="(o, index) in curItem.getAddresses"
              :key="index"
              @click="changeAddress(o)"
            >
              <span class="icon"></span>
              <span>{{ o }}</span>
            </div>
          </div>
          <div class="popup-footer">
            <div class="footer-button" @click="clickStep(1, curItem)">
              {{ curItem.type == 3 ? "下一步" : "立即支付" }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="popup-content stepTwo">
          <div class="content-detail">
            <div class="detail-picture">
              <img :src="curSku.skuImageUrl" alt="" />
            </div>
            <div class="detail-flex">
              <div class="detail-flex-title">
                <span>{{ curItem.title }}</span>
              </div>
              <div class="detail-flex-price">
                <span
                  >{{ curSku.price }}元（库存：{{
                    curSku.stock === "" ? "无限量" : curSku.stock
                  }}）</span
                >
              </div>
            </div>
          </div>
          <div class="content-size">
            <div
              :class="['size-item', item.id === curSku.id ? 'size-select' : '']"
              v-for="(item, index) in curItem.skuList"
              :key="index"
              @click="setCurSku(item)"
            >
              <span>{{ item.skuName }}</span>
            </div>
          </div>
          <!-- <div class="content-num">
            <v-stepper
              v-model="count"
              :min="1"
              :max="curSku.stock === '' ? Infinity : curSku.stock"
            />
          </div> -->
          <div class="content-footer" @click="clickStep(2, curItem, curSku)">
            <span>立即支付</span>
          </div>
        </div>
      </template>
    </div>

    <div class="section-refresh" v-if="isRefresh" @click="payResult">
      <span>点击查询订单状态，更新订单信息</span>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import moment from "moment";
import {
  myOrderListURL,
  // myOrderDetailsURL,
  trolleyOrderURL,
  cancelOrderURL,
  // queryOrderURL,
  initQueryParamsURL,
} from "./api";
import { typeMap, setTypeOps, myStatusMap, setMyStatusOps } from "./map.js";

export default {
  data() {
    return {
      once: true, // 第一次进入该页面
      myStatusMap,
      statusOps: setMyStatusOps(),
      typeMap,
      typeOps: setTypeOps(),
      /** v-drop-down-menu */
      status: undefined, // 1待付款（1-待付款） 2待发货（2-2 待发货 2-3 进行中） 3已完成（3-4 已完成 3-5 取消已退款）
      type: undefined, // 1:团购 2:秒杀 3:普通商品 4:一刻钟商品
      /* v-list */
      finishedText: "没有更多了",
      finished: true,
      listData: [
        // {
        //   title: "妈妈壹选洗洁精",
        //   type: "1",
        //   status: 1,
        //   orderTime: "2023-06-28 00:00:00",
        //   buyEndTime: "2023-07-4 00:00:00",
        //   price: "27",
        //   getAddresses: [
        //     "金钟农贸123号摊",
        //     "世纪联华超市",
        //     "同泰药店",
        //     "金钟广场",
        //     "菜鸟驿站",
        //     "金钟农贸123号摊1",
        //     "世纪联华超市2",
        //     "同泰药店3",
        //     "金钟广场4",
        //     "菜鸟驿站5",
        //     "金钟农贸123号摊6",
        //     "世纪联华超市7",
        //     "同泰药店8",
        //     "金钟广场9",
        //     "菜鸟驿站10",
        //   ],
        // },
        // {
        //   title: "妈妈壹选洗洁精",
        //   type: 2,
        //   status: 1,
        //   orderTime: "2023-06-28 00:00:00",
        //   buyEndTime: "2023-07-4 18:53:30",
        //   price: "27",
        //   getAddresses: [
        //     "金钟农贸123号摊",
        //     "世纪联华超市",
        //     "同泰药店",
        //     "金钟广场",
        //     "菜鸟驿站",
        //     "金钟农贸123号摊1",
        //     "世纪联华超市2",
        //     "同泰药店3",
        //     "金钟广场4",
        //     "菜鸟驿站5",
        //     "金钟农贸123号摊6",
        //     "世纪联华超市7",
        //     "同泰药店8",
        //     "金钟广场9",
        //     "菜鸟驿站10",
        //   ],
        // },
        // {
        //   title: "妈妈壹选洗洁精",
        //   type: "3",
        //   status: 1,
        //   orderTime: "2023-06-28 00:00:00",
        //   buyEndTime: "2023-07-4 16:51:50",
        //   price: "27",
        //   getAddresses: [
        //     "金钟农贸123号摊",
        //     "世纪联华超市",
        //     "同泰药店",
        //     "金钟广场",
        //     "菜鸟驿站",
        //     "金钟农贸123号摊1",
        //     "世纪联华超市2",
        //     "同泰药店3",
        //     "金钟广场4",
        //     "菜鸟驿站5",
        //     "金钟农贸123号摊6",
        //     "世纪联华超市7",
        //     "同泰药店8",
        //     "金钟广场9",
        //     "菜鸟驿站10",
        //   ],
        // },
        // {
        //   title: "妈妈壹选洗洁精",
        //   type: "3",
        //   orderTime: "2023-06-28 00:00:00",
        //   buyEndTime: "2023-07-4 00:00:00",
        //   price: "27",
        //   getAddresses: [
        //     "金钟农贸123号摊",
        //     "世纪联华超市",
        //     "同泰药店",
        //     "金钟广场",
        //     "菜鸟驿站",
        //     "金钟农贸123号摊1",
        //     "世纪联华超市2",
        //     "同泰药店3",
        //     "金钟广场4",
        //     "菜鸟驿站5",
        //     "金钟农贸123号摊6",
        //     "世纪联华超市7",
        //     "同泰药店8",
        //     "金钟广场9",
        //     "菜鸟驿站10",
        //   ],
        // },
        // {
        //   title: "妈妈壹选洗洁精",
        //   type: "1",
        //   orderTime: "2023-06-28 00:00:00",
        //   buyEndTime: "2023-07-4 00:00:00",
        //   price: "27",
        //   getAddresses: [
        //     "金钟农贸123号摊",
        //     "世纪联华超市",
        //     "同泰药店",
        //     "金钟广场",
        //     "菜鸟驿站",
        //     "金钟农贸123号摊1",
        //     "世纪联华超市2",
        //     "同泰药店3",
        //     "金钟广场4",
        //     "菜鸟驿站5",
        //     "金钟农贸123号摊6",
        //     "世纪联华超市7",
        //     "同泰药店8",
        //     "金钟广场9",
        //     "菜鸟驿站10",
        //   ],
        // },
        // {
        //   title: "妈妈壹选洗洁精",
        //   type: "3",
        //   orderTime: "2023-06-28 00:00:00",
        //   buyEndTime: "2023-07-4 00:00:00",
        //   price: "27",
        //   getAddresses: [
        //     "金钟农贸123号摊",
        //     "世纪联华超市",
        //     "同泰药店",
        //     "金钟广场",
        //     "菜鸟驿站",
        //     "金钟农贸123号摊1",
        //     "世纪联华超市2",
        //     "同泰药店3",
        //     "金钟广场4",
        //     "菜鸟驿站5",
        //     "金钟农贸123号摊6",
        //     "世纪联华超市7",
        //     "同泰药店8",
        //     "金钟广场9",
        //     "菜鸟驿站10",
        //   ],
        // },
      ],
      curPage: 1,
      pageSize: 10,
      curItem: null,
      /** v-dialog */
      isDialog: false,
      /** v-popup */
      showPopup: false,
      step: 1, // 1 第一步 选择地址
      curAddress: "",
      curSku: {
        pirce: "",
        stock: "",
        skuName: "",
        skuImageUrl: "",
        id: "",
      },
      // count: 1, // 商品购买数量，最小为 1
      /* 按钮点击 */
      // 立即支付按钮是否可点击
      isClick: true,
      /* 立即下单 */
      isRefresh: false, // 遮罩层，点击刷新数据
      outTradeNo: "", // 订单编号
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId", "openId"]),
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      this.once = true;
      await this.queryTabs();
      this.onRefresh();
      this.once = false;
    },
    changeTabs() {
      if (this.once) {
        return;
      }
      const _this = this;
      setTimeout(() => {
        _this.onRefresh();
      });
    },
    // 重置我的订单列表
    onRefresh() {
      this.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    // 请求tabs
    async queryTabs() {
      let res = await this.$axios.get(initQueryParamsURL, {
        params: {
          userId: this.userId,
        },
      });
      let defType = 1;
      let defStatus = 1;
      if (res.code == 200 && res.data) {
        const { type, status } = res.data;
        defType = type;
        defStatus = status;
      }
      this.type = defType;
      this.status = defStatus;
    },
    // 获取我的订单列表
    onLoad() {
      let params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        type: this.type,
        status: this.status,
        userId: this.userId,
        // tenantId: this.tenantId,
        // houseId: this.houseId || this.communityId || undefined,
      };
      this.$axios
        .get(myOrderListURL, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        });
    },
    // 点击前往商品详情
    toDetail(item) {
      if (this.status == 1) {
        return;
      }
      this.$router.push({
        name: "bcnOtherMyOrderDetail",
        query: {
          id: item.id,
          type: item.type,
        },
      });
    },
    // 获取时间戳差额
    getTime(time) {
      const curTime = moment(time.replace(/-/g, "/"));
      const nowTime = moment();
      return curTime.diff(nowTime);
    },
    // 倒计时结束
    finish(item) {
      // 数据改变，虚拟dom更新，引起页面重绘
      this.$set(item, "isFinish", true);
    },
    /** v-popup */
    // 选择提货点弹框
    payOrder(item) {
      this.curItem = item;
      this.curSku = {
        pirce: "",
        stock: "",
        skuName: "",
        skuImageUrl: "",
        id: "",
      };
      this.step = 1; // 弹框重置为第一步
      this.count = 1;
      this.curAddress = "";
      this.showPopup = true;
    },
    // 改变地址
    changeAddress(o) {
      this.curAddress = o;
    },
    // 选择规格
    setCurSku(item) {
      this.count = 1;
      this.curSku = item;
    },
    clickStep(num, curItem, curSku) {
      if (!this.curAddress) {
        this.$toast({ message: "请选择提货点", duration: 2000 });
        return;
      }
      // 团购和秒杀
      if (curItem.type == 1 || curItem.type == 2) {
        this.payNow();
        return;
      }
      // 普通商品
      if (num === 1) {
        // if (!this.curAddress) {
        //   this.$toast({ message: "请选择提货点", duration: 2000 });
        //   return;
        // }
        if (curItem.skuList && curItem.skuList.length > 0) {
          this.curSku = curItem.skuList[0];
        }
        this.step = 2;
      } else if (num === 2) {
        this.payNow(curSku);
      }
    },
    // 立即支付
    payNow(curSku) {
      if (!this.isClick) {
        return;
      }
      if (!this.curAddress) {
        this.$toast({ message: "请选择提货点", duration: 2000 });
        return;
      }
      if (!this.curAddress) {
        this.$toast({ message: "请选择提货点", duration: 2000 });
        return;
      }
      let params = {
        id: this.curItem.id,
        getAddress: this.curAddress,
        openId: this.openId,
      };
      if (curSku) {
        params.skuId = curSku.id;
        // params.orderGoodsNum = this.count;
      }
      this.isClick = false;
      this.$axios
        .post(trolleyOrderURL, null, { params })
        .then((res) => {
          if (res.code === 200) {
            const result = res.data;
            const path = {
              to: "",
              from: "/futureService/bcnOtherMyOrder",
            };
            // this.isClick = true;
            // this.showPopup = false;
            if (wx.miniProgram) {
              wx.miniProgram.navigateTo({
                animationDuration: 10,
                url: `/xubPages/payDetail/index?query=${encodeURIComponent(
                  JSON.stringify(this.$route.query)
                )}&params=${encodeURIComponent(
                  JSON.stringify(result)
                )}&path=${JSON.stringify(path)}&trolleyId=${this.curItem.id}`,

                complete: () => {
                  this.isClick = true;
                  this.showPopup = false;
                  this.payResultDialog(res.outTradeNo);
                },
              });
            }
          }
        })
        .finally(() => {
          this.isClick = true;
        });
    },
    // 查询结果弹框
    payResultDialog(outTradeNo) {
      this.outTradeNo = outTradeNo;
      setTimeout(() => {
        this.isRefresh = true;
      }, 500);
    },
    // 查询结果参数
    async payResult() {
      this.isRefresh = false;
      this.onRefresh();
      // let res = await this.$axios.get(queryOrderURL, {
      //   params: {
      //     outTradeNo: this.outTradeNo,
      //     userId: this.userId,
      //   },
      // });
      // if (res.code == 200 && res.success) {
      //   this.isRefresh = false;
      //   this.initData();
      //   // this.$toast({ message: "支付成功！", duration: 2000 });
      // }
    },
    /** v-dialog */
    // 取消订单弹框
    cancelOrder(item) {
      this.curItem = item;
      this.isDialog = true;
    },
    // 取消订单
    submit() {
      let params = {
        // num: this.count,
        id: this.curItem.id,
        // communityId: this.communityId,
        // roomId: this.houseId || this.communityId || undefined,
        // userId: this.userId,
        // tenantId: this.tenantId,
        // skuId: this.curSku.id,
        // type: "scene",
      };
      this.$axios.post(cancelOrderURL, null, { params }).then((res) => {
        if (res.code === 200) {
          this.isDialog = false;
          this.onRefresh();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.section {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  // background: #f9f9f9;
  padding-bottom: calc(
    20px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    20px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  font-family: PingFangSC-Regular, PingFang SC;
  .section-tabs {
    display: flex;
    box-shadow: 0 2px 12px rgba(100, 101, 102, 0.12);
    ::v-deep .v-dropDownMenu {
      flex: 1;
      .van-dropdown-menu__bar {
        box-shadow: none;
      }
    }
    // ::v-deep .tab-title {
    //   font-weight: bold;
    // }
  }
  .section-list {
    box-sizing: border-box;
    width: 100%;
    padding: 0 28px;
    .section-flex-item {
      margin-top: 30px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      .item-time {
        display: flex;
        padding: 0 22px;
        margin-bottom: 20px;
        .type {
          flex: 1;
          display: flex;
          // align-items: center;
          span {
            width: 200px;
            height: 56px;
            background: linear-gradient(180deg, #fa8d51 0%, #f56b38 100%);
            border-radius: 0px 0px 16px 16px;
            line-height: 56px;
            font-size: 30px;
            font-weight: bold;
            color: #ffeee4;
            vertical-align: middle;
            text-align: center;
          }
        }
        .time {
          padding: 20px 0 0;
          display: flex;
          align-items: center;
          .label {
            font-size: 24px;
            font-weight: bold;
            color: #1a1c34;
            line-height: 34px;
          }
          .timeData {
            display: flex;
            align-items: center;
            .colon {
              display: inline-block;
              margin: 0 8px;
              color: #ee0a24;
            }
            .block {
              display: inline-block;
              width: 44px;
              height: 44px;
              color: #fff;
              font-size: 24px;
              text-align: center;
              border-radius: 5px;
              background-color: #ee0a24;
              vertical-align: middle;
              line-height: 44px;
            }
          }
        }
      }
      .item-flex {
        padding: 22px 20px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #f6f6f6;
        .item-picture {
          width: 160px;
          min-width: 160px;
          height: 160px;
          border-radius: 16px;
          overflow: hidden;
          margin-right: 28px;
          > img {
            &:extend(.common-img);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .item-detail {
          flex: 1;
          .item-detail-title {
            font-size: 32px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 44px;
            word-break: break-all;
            word-wrap: break-word;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .item-detail-time {
            margin-top: 12px;
            font-size: 24px;
            font-weight: 400;
            color: rgba(128, 59, 19, 0.85);
            line-height: 34px;
          }
          .item-detail-count {
            margin-top: 30px;
            font-size: 32px;
            font-weight: bold;
            color: #d9634d;
            line-height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span:first-child {
              flex: 1;
            }
            // span:last-child {
            //   width: 142px;
            //   height: 54px;
            //   background: #d9634d;
            //   border-radius: 8px;
            //   line-height: 54px;
            //   text-align: center;
            //   font-size: 26px;
            //   font-weight: 400;
            //   color: #ffffff;
            // }
          }
        }
      }
      .item-button {
        display: flex;
        justify-content: flex-end;
        padding: 22px;
        .btn {
          margin-left: 32px;
          box-sizing: border-box;
          padding: 0 24px;
          height: 54px;
          font-size: 26px;
          font-weight: 400;
          line-height: 54px;
          border-radius: 8px;
          overflow: hidden;
          &.cancel {
            color: #d9634d;
            border: 2px solid #d9634d;
          }
          &.pay {
            background: #d9634d;
            color: #ffffff;
          }
          &.pending {
            background: #f56c39;
            color: #ffffff;
          }
          &.complete {
            background: #f56c39;
            color: #ffffff;
          }
          &.over {
            background: #e6e6e6;
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
  .section-dialog {
    ::v-deep .van-dialog {
      .van-dialog__header {
        font-weight: bold;
      }
      p {
        margin: 34px 0 74px;
        text-align: center;
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .popup-overlary {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    .popup-content.stepOne {
      position: absolute;
      background: #fff;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      padding-bottom: calc(
        32px + constant(safe-area-inset-bottom)
      ); /* 兼容 iOS < 11.2 */
      padding-bottom: calc(
        32px + env(safe-area-inset-bottom)
      ); /* 兼容 iOS >= 11.2 */
      .content-title {
        padding: 40px 30px;
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        line-height: 42px;
      }
      .content-select {
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        .select-option {
          display: flex;
          align-items: center;
          padding: 24px 30px;
          font-size: 30px;
          font-weight: 400;
          color: #333333;
          line-height: 42px;
          .icon {
            margin-right: 14px;
            box-sizing: border-box;
            width: 30px;
            height: 30px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            border-radius: 50%;
          }
          &.current {
            background: #f9f9f9;
            .icon {
              border: 8px solid #db431d;
            }
          }
        }
      }
      .popup-footer {
        display: flex;
        justify-content: flex-end;
        .footer-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 210px;
          height: 70px;
          margin: 30px 30px 0 0;
          background: #db431d;
          border-radius: 16px;
          font-size: 30px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
    .popup-content.stepTwo {
      position: absolute;
      background: #fff;
      padding: 40px 32px;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      padding-bottom: calc(
        32px + constant(safe-area-inset-bottom)
      ); /* 兼容 iOS < 11.2 */
      padding-bottom: calc(
        32px + env(safe-area-inset-bottom)
      ); /* 兼容 iOS >= 11.2 */
      .content-detail {
        display: flex;
        align-items: center;
        .detail-picture {
          width: 174px;
          min-width: 174px;
          height: 174px;
          margin-right: 26px;
          img {
            &:extend(.common-img);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .detail-flex {
          flex: 1;
          font-size: 32px;
          font-weight: 400;
          line-height: 44px;
          .detail-flex-title {
            white-space: normal;
            word-break: break-all;
            word-wrap: break-word;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 40px;
          }
          .detail-flex-price {
            color: #fe9616;
          }
        }
      }
      .content-size {
        margin: 30px 0 54px;
        .size-item {
          margin: 0 20px 10px 0;
          &:last-child {
            margin-right: 0px;
          }
          display: inline-block;
          box-sizing: border-box;
          font-size: 26px;
          font-weight: 400;
          line-height: 32px;
          padding: 6px 12px;
          border-radius: 4px;
          color: rgba(0, 0, 0, 0.5);
          border: 2px solid rgba(0, 0, 0, 0.5);
          &.size-select {
            color: #fe9616;
            border: 2px solid #fe9616;
          }
        }
      }
      .content-footer {
        margin: 84px auto 0;
        width: 550px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #db431d;
        border-radius: 16px;
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 4px;
      }
    }
  }

  .section-refresh {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #1989fa;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="page">
    <div class="top_box">
      <div class="title"><span>*</span>现场拍照上传:</div>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.sceneImage"
        :activityPicture.sync="form.sceneImage"
        :maxCount="9"
        ref="load"
      ></v-upload>
      <div class="title">备注:</div>
      <div class="content_box">
        <van-field
          rows="6"
          v-model="form.remark"
          style="background: rgba(0, 0, 0, 0.04); border-radius: 16px"
          type="textarea"
          placeholder="填写备注"
          maxlength="500"
          show-word-limit
        />
      </div>
    </div>
    <div class="submit" @click="submit">提交</div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { submitPicture } from "../api.js";
import { toRegister } from "@/utils/common.js";

export default {
  name: "redNetMicroTreatAdd",
  data() {
    return {
      form: {
        id: "",
        sceneImage: "",
        remark: "",
      },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    await toRegister(this.$route.path, this.$route.query, "金钟党建");
    this.form.id = this.$route.query.id;
  },
  methods: {
    submit() {
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.form.sceneImage || this.form.sceneImage == "") {
        this.$toast("请上传内容图片!");
        return;
      }
      let params = {
        id: this.form.id,
        userId: this.userId,
        sceneImage: this.form.sceneImage,
        remark: this.form.remark,
      };
      this.$axios.post(`${submitPicture}`, null, { params }).then((res) => {
        if (res.code === 200) {
          this.$toast("提交成功~");
          if (wx.miniProgram) {
            wx.miniProgram.navigateBack({
              delta: 1,
            });
          }

          return;
        }
        this.$toast("失败了，请重试！");
      });
    },
  },
};
</script>

<style lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  // padding-bottom: 82px;
  box-sizing: border-box;
  padding-bottom: calc(
    64px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    64px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */

  .submit {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    margin: 0 auto;
    margin-top: 76px;
  }

  .top_box {
    width: 100%;
    padding: 0 44px;
    box-sizing: border-box;

    .name_box,
    .status_box {
      width: 100%;
      height: 112px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      line-height: 112px;
      display: flex;

      .box_l {
        width: 240px;
        font-size: 32px;
        color: #333333;

        span {
          color: #ff602a;
        }
      }

      .box_r {
        flex: 1;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.5);

        .message {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
          text-align: right;
        }

        input {
          width: 100%;
          height: 100%;
          text-align: right;
        }

        input::placeholder {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }

    .message {
      margin-top: 12px;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.5);
    }

    .title {
      font-size: 32px;
      color: #333333;
      padding: 40px 0 18px 0;

      span {
        color: #ff602a;
      }
    }
  }
}
</style>

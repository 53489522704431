<template>
  <div class="page">
    <img src="./img/banner.png" class="banner" />
    <div class="tab-box">
      <div class="tab">
        <div
          :class="index == tabActivate ? 'tab-item-activate' : 'tab-item'"
          @click="tabItemSelect(item, index)"
          v-for="(item, index) in tabList"
          :key="index"
        >
          <span>{{ item }}<i v-if="index == tabActivate"></i></span>
        </div>
      </div>
    </div>
    <!-- 乡贤风采 -->
    <div class="styleBox" v-show="tabActivate == 0">
      <v-list
        :finished="finished"
        @onLoad="onLoad2"
        :finishedText="finishedText"
        ref="list"
      >
        <div class="list">
          <div
            class="item"
            @click="toStyleDetail(item, index)"
            v-for="(item, index) in listData2"
            :key="index"
          >
            <div class="item-top"><img :src="item.topicPicture" /></div>
            <div class="name">{{ item.topicTitle }}</div>
            <div class="occupation">{{ format(item.topicType) }}</div>
          </div>
        </div>
      </v-list>
    </div>
    <!-- 乡贤活动 -->
    <div class="activityBox" v-show="tabActivate == 1">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
        :commonOperaParams="commonOperaParams"
      >
        <div v-for="(item, index) in listData" :key="index">
          <div class="activityItem" @click="toDetail(item)">
            <div class="activityItem-t">
              <img :src="item.activityPicture" alt="" />
            </div>
            <div class="activityItem-c">
              <div class="title">
                <div class="title-l" v-if="item.isApplied == 1">
                  <img src="./img/signUp.png" alt="" /><span>已报名</span>
                </div>
                <div class="title-r">{{ item.activityName }}</div>
                <div class="status-red" v-if="item.activityCategory == 1">
                  热门活动
                </div>
                <div
                  class="status-blue"
                  v-else-if="item.activityCategory == 20"
                >
                  时间银行
                </div>
                <div
                  class="status-blue"
                  v-else-if="item.activityCategory == 19"
                >
                  幸福学堂
                </div>
              </div>
              <div class="address">{{ item.activityAddress }}</div>
              <p class="p-apply">报名开始时间：{{ item.applyStartDate }}</p>
              <p class="p-activity">活动开始时间：{{ item.startDate }}</p>
            </div>
            <div class="activityItem-b">
              <div class="operate">
                <div class="operate-l">
                  <div class="people">
                    <div class="people-l">
                      <img src="./img/newPeople.png" alt="" />
                    </div>
                    <div class="people-r">{{ item.applyUserCount || 0 }}</div>
                  </div>
                  <div class="comment">
                    <div class="comment-l">
                      <img src="./img/comment.png" alt="" />
                    </div>
                    <div class="comment-r">{{ item.commentCount || 0 }}</div>
                  </div>
                </div>
                <div class="operate-r">
                  <div
                    :class="[
                      'btn',
                      {
                        applying: item.activityStatus == 2,
                        going: item.activityStatus == 4,
                        back: item.hasFeedback && item.hasFeedback == 1,
                      },
                    ]"
                  >
                    <span>{{
                      getOption(item.activityStatus, item.hasFeedback, item)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import {
  getActivityListUrl,
  volunteerUrl,
  ifCommitmentLetterUrl,
  getTopicListURL,
} from "./api.js";
import { getOption } from "@/utils/utils.js";
import { activityStatus } from "./map.js";
import { gloabalCount, toRegister } from "@/utils/common";
import { handleImg } from "@/utils/utils.js";
export default {
  name: "villageScholarCulture",
  data() {
    return {
      tabActivate: 0,
      tabList: ["乡贤风采", "乡贤活动"],
      // ————————————
      finishedText: "没有更多了",
      activityStatus,
      finished: false,
      commonOperaParams: {
        showMy: false,
        isCommonOpera: false,
      },
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      type: "热门活动",
      listData: [],
      listData2: [],
    };
  },
  mounted() {
    gloabalCount("", 88, 1);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {
    format(type) {
      if (type == 36) {
        return "离休干部";
      } else if (type == 37) {
        return "贤人志士";
      } else if (type == 38) {
        return "各界精英";
      } else if (type == 39) {
        return "优秀典范";
      }
    },
    onLoad2() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
        sortField: "NEW",
        topicTypes: "36,37,38,39",
      };
      this.$axios.get(`${getTopicListURL}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.topicPicture) {
              item.topicPicture = handleImg(218, 200, item.topicPicture);
            }
            this.listData2.push(item);
          });
        }
      });
    },
    toStyleDetail(item, index) {
      this.$router.push({
        name: "villageScholarStyleDetail",
        query: {
          id: item.id,
        },
      });
    },
    tabItemSelect(item, index) {
      this.tabActivate = index;
      if (this.tabActivate == 1) {
        this.finished = false;
        this.finishedText = "没有更多了";
        this.$refs.list._data.loading = true;
        this.requestData.curPage = 1;
        this.listData = [];
        this.onLoad();
      } else if (this.tabActivate == 0) {
        this.finished = false;
        this.finishedText = "没有更多了";
        this.$refs.list._data.loading = true;
        this.requestData.curPage = 1;
        this.listData2 = [];
        this.onLoad2();
      }
    },
    getOption(status, hasFeedback, item) {
      if (hasFeedback && hasFeedback == 1) {
        return "活动回顾";
      }
      if (item.applyUserCount >= item.limitCount && item.limitCount != 0) {
        return "报名已满";
      }
      if (getOption(status, activityStatus, "key")["value"]) {
        return getOption(status, activityStatus, "key")["value"];
      } else {
        return "";
      }
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
        activityCategory: 21,
      };
      this.$axios
        .get(`${getActivityListUrl}`, { params: params })
        .then((res) => {
          console.log(res, 333);
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.activityPicture) {
                item.activityPicture = handleImg(
                  686,
                  238,
                  item.activityPicture
                );
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    onLoad() {
      this.getActivityList();
    },
    async toDetail(item) {
      let params = {
        activityId: item.activityId,
        type: this.type,
      };
      wx.miniProgram.navigateTo({
        url: `/xubPages/activityDetailxx/index?params=${JSON.stringify(
          params
        )}`,
      });
      // this.$router.push({
      //   name: "activityDetail",
      //   query: { activityId: item.activityId },
      // });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  background: #f9f9f9;
  padding-bottom: 30px;
  .activityBox {
    background: #fafafaff;
    padding: 20px 32px;
    box-sizing: border-box;
    .activityItem {
      position: relative;
      // height: 604px;
      border-radius: 16px;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      margin-bottom: 32px;
      .activityItem-t {
        width: 100%;
        height: 280px;
        overflow: hidden;
        position: relative;
        img {
          border-radius: 16px 16px 0 0;
          width: 100%;
          //height: 100%;
        }
      }
      .activityItem-c {
        padding: 20px 24px 22px;
        box-sizing: border-box;
        .title {
          display: flex;
          align-items: center;
          .title-l {
            width: 102px;
            height: 32px;
            margin-right: 8px;
            position: relative;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
            span {
              width: 100%;
              height: 100%;
              text-align: center;
              position: absolute;
              top: 0;
              left: 0;
              font-size: 20px;
              line-height: 32px;
              color: #fff;
              font-weight: bold;
            }
          }
          .title-r {
            width: 480px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 32px;
            font-weight: bold;
            color: #323334;
            line-height: 44px;
          }
          .status-red {
            width: 124px;
            height: 42px;
            background: rgba(224, 32, 32, 0.1);
            border-radius: 4px;
            border: 2px solid #e02020;
            font-size: 24px;
            color: #e02020;
            text-align: center;
            line-height: 42px;
          }
          .status-blue {
            width: 124px;
            height: 42px;
            background: rgba(42, 114, 113, 0.11);
            border-radius: 4px;
            border: 2px solid #2a7271;
            font-size: 24px;
            color: #2a7271;
            text-align: center;
            line-height: 42px;
          }
        }
        .address {
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 36px;
          margin: 8px 0 14px;
        }
        p {
          font-size: 24px;
          padding-left: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 36px;
          margin-bottom: 6px;
        }
        .p-apply,
        .p-activity {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background: #1ebb70;
            border-radius: 50%;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .p-activity {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            background: #3a69ed;
            border-radius: 50%;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
      }
      .activityItem-b {
        padding: 18px 24px 18px;
        box-sizing: border-box;
        .operate {
          display: flex;
          // padding: 14px 0 0 0;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: center;
          .operate-l,
          .operate-r {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .people {
            width: 160px;
          }
          .people,
          .comment {
            display: flex;
            align-items: center;
          }
          .people-l,
          .comment-l {
            width: 28px;
            height: 28px;
            font-size: 0;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .people-r,
          .comment-r {
            padding-top: 2px;
            line-height: 1;
            font-size: 28px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
          }
          .btn {
            position: absolute;
            bottom: 0;
            right: 0;
            box-sizing: border-box;
            height: 64px;
            // border: 4px solid #fe6f16;
            border-radius: 16px 0 16px 0;
            // color: #fe6f16;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 24px;
            min-width: 194px;
            background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
            color: #a7a7a7ff;
            box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
            > span {
              font-size: 26px;
              font-weight: bold;
              line-height: 1;
            }
          }
          .btn.applying {
            background: linear-gradient(180deg, #b0e2dd 0%, #54a2ac 100%);
            color: #ffffff;
          }
          .btn.going {
            background: linear-gradient(180deg, #fdc76f 0%, #faae3e 100%);
            color: #ffffff;
          }
          .btn.back {
            background: linear-gradient(180deg, #fdc76f 0%, #faae3e 100%);
            color: #ffffff;
          }
        }
      }
    }
  }
  .styleBox {
    padding: 28px 28px 0 28px;
    box-sizing: border-box;
    .list {
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 218px;
        height: 308px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        border-radius: 8px;
        margin-bottom: 28px;
        margin-right: 18px;
        overflow: hidden;
        .name {
          padding: 14px 0 0 20px;
          font-size: 28px;
          font-weight: 500;
          color: #333333;
          box-sizing: border-box;
          white-space: nowrap;
        }
        .occupation {
          padding: 0 0 0 20px;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          box-sizing: border-box;
          white-space: nowrap;
        }
        .item-top {
          width: 100%;
          height: 200px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .item:nth-child(3n) {
        margin-right: 0px;
      }
    }
  }
  .tab-box {
    padding: 0 28px;
    box-sizing: border-box;
    height: 92px;
    .tab {
      width: 100%;
      height: 92px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      border-radius: 8px;
      line-height: 92px;
      display: flex;
      align-items: center;
      .tab-item-activate {
        flex: 1;
        font-size: 32px;
        font-weight: 600;
        color: #77beba;
        text-align: center;
        span {
          position: relative;
          i {
            position: absolute;
            top: -5px;
            right: -5px;
            width: 8px;
            height: 8px;
            background: #ffd675;
            border-radius: 50%;
          }
        }
      }
      .tab-item {
        flex: 1;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.5);
        text-align: center;
        span {
          position: relative;
          i {
            position: absolute;
            top: -5px;
            right: -5px;
            width: 8px;
            height: 8px;
            background: #ffd675;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .banner {
    width: 100%;
  }
}
</style>
<template>
  <div class="mall">
    <div class="mall-t">
      <div class="mall-tc">
        <div class="totalPoints">
          <div class="item-t">
            <count-to
              :start-val="0"
              :end-val="pointsData.totalTokenPoint || 0"
              :duration="1000"
              class="card-panel-num"
            />
          </div>
          <div class="item-b">总积分</div>
        </div>
        <div class="usePoints">
          <div class="item-t">
            <count-to
              :start-val="0"
              :end-val="pointsData.availableTokenPoint || 0"
              :duration="1000"
              class="card-panel-num"
            />
          </div>
          <div class="item-b">可用积分</div>
        </div>
      </div>
    </div>
    <div class="mall-c">
      <div class="mallIcon">
        <div class="item" @click="toList">
          <div class="item-t"><img src="@/assets/img/points.png" alt="" /></div>
          <div class="item-b">收支明细</div>
        </div>
        <div class="item" @click="toRank">
          <div class="item-t"><img src="@/assets/img/ranks.png" alt="" /></div>
          <div class="item-b">积分排行榜</div>
        </div>
        <div class="item" @click="toOrder">
          <div class="item-t">
            <img src="@/assets/img/myorder.png" alt="" />
          </div>
          <div class="item-b">我的订单</div>
        </div>
      </div>
    </div>
    <div class="mall-title">
      <span class="more" @click="toRule">福利获取规则</span>
    </div>
    <div class="mall-nav">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        :class="['item', { active: curTab.value === tab.value }]"
        @click="selectMem(tab, index)"
      >
        <span>{{ tab.label }}</span>
      </div>
    </div>
    <div class="mall-b">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div class="mallList">
          <div
            class="item"
            v-for="(item, index) in listData"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="quan">
              <img v-if="item.goodsType == 1" src="./img/icon.png" alt="" />
              <img v-else src="./img/icon1.png" alt="" />
            </div>
            <div class="item-t"><img :src="item.imageUrl" alt="" /></div>
            <div class="item-b">
              <div class="title">{{ item.title }}</div>
              <div class="price">
                {{ item.pointsPrice
                }}{{ curTab.label == "积分商城" ? "积分" : "积分" }}
              </div>
              <div v-if="item.stock === 0" class="loot-all-text">待补货</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <div class="toHome" v-if="fromGetpoint == 1" @click="toHome">
      <img src="./img/home.png" alt="" />
    </div>
    <v-dialog
      class="dialog"
      v-model="showDialog"
      :showConfirmButton="false"
      :showCancelButton="false"
      :closeOnClickOverlay="true"
    >
      <p class="dialog-content">您还未申请志愿者</p>
      <div class="dialog-btn" @click="apply">
        <div class="btn">立即申请</div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import CountTo from "vue-count-to";
import { getGoodsListUrl, pointsUrl, volunteerUrl } from "./api.js";
import { gloabalCount } from "@/utils/common.js";
import { handleImg } from "@/utils/utils.js";

export default {
  name: "points",
  components: { CountTo },
  data() {
    return {
      curTab: {
        label: "实物",
        value: "1",
      },
      tabs: [
        {
          label: "实物",
          value: "1",
        },
        {
          label: "优惠券",
          value: "2",
        },
      ],
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      pointsData: {
        totlePointCount: 0,
        availablePoint: 0,
      },
      listData: [],
      fromGetpoint: "",

      /* v-dialog */
      showDialog: false,
      errorMsg: "",
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.fromGetpoint = this.$route.query.fromGetpoint;
  },
  mounted() {
    if (this.userId) {
      this.getPoints();
    }
    gloabalCount("", 17, 1);
  },
  methods: {
    async selectMem(tab) {
      this.curTab = tab;
      // if (tab.label === "志愿积分商城") {
      //   await this.getVolunteer();
      // }
      this.finished = false;
      this.requestData.curPage = 1;
      this.listData = [];
      this.getGoodsList();
    },
    // 判断当前用户是否志愿者,若不是跳转申请志愿者界面
    async getVolunteer() {
      this.errorMsg = "";
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.post(`${volunteerUrl}`, null, {
        params: params,
      });
      if (res.code === 200 && res.success) {
        return;
      }
      this.errorMsg = res.msg;
      /** start 时间银行特殊处理 */
      if (this.curTab.label === "实物" && this.errorMsg) {
        this.showDialog = true;
        return;
      }
      /** end */
    },
    // 申请
    apply() {
      this.$router.push({
        name: "applyVolunteer",
        query: {
          userId: this.userId,
        },
      });
    },

    toHome() {
      wx.miniProgram.redirectTo({
        url: `/pages/index/index`,
      });
    },
    toOrder() {
      if (this.userId && this.userId != 0) {
        this.$router.push({
          name: "mallOrderList",
        });
      } else {
        let toUrl = this.$route.path;
        let params = this.$route.query;
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    toList() {
      if (this.userId && this.userId != 0) {
        this.$router.push({
          name: "pointsList",
        });
      } else {
        let toUrl = this.$route.path;
        let params = this.$route.query;
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    toRank() {
      if (this.userId && this.userId != 0) {
        this.$router.push({
          name: "pointRankList",
        });
      } else {
        let toUrl = this.$route.path;
        let params = this.$route.query;
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    toVolunteer() {
      if (this.userId && this.userId != 0) {
        this.$router.push({
          name: "pointVolunteerList",
        });
      } else {
        let toUrl = this.$route.path;
        let params = this.$route.query;
        wx.miniProgram.redirectTo({
          url: `/pages/register/index?isRegister=2&ish5=1&toUrl=${toUrl}&params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
    toRule() {
      // this.$toast({ message: "敬请期待！", duration: 2000 });
      this.$router.push({
        name: "pointsRule",
      });
    },
    getPoints() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios
        .post(`${pointsUrl}`, this.$qs.stringify(params), {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data) {
              this.pointsData = res.data;
            }
          } else {
          }
        });
    },
    toDetail(item) {
      // if (this.curTab.label === "志愿积分商城" && this.errorMsg) {
      //   this.showDialog = true;
      //   return;
      // }
      this.$router.push({
        name: "mallDetail",
        query: { id: item.id, type: this.curTab.label },
      });
    },
    onLoad() {
      this.getGoodsList();
    },
    getGoodsList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        houseId: this.roomId || this.communityId || undefined,
        tenantId: this.tenantId,
        goodsType: this.curTab.value,
        // userId: this.userId,
        // tenantId: this.tenantId,
        // roomId: this.roomId,
        // communityId: this.communityId,
      };
      this.$axios.get(`${getGoodsListUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.imageUrl) {
              item.imageUrl = handleImg(300, 300, item.imageUrl);
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.mall {
  min-height: 100vh;
  position: relative;
  .mall-t {
    height: 328px;
    padding: 0 32px;
    background: url("./img/topBack.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 52px;
    box-sizing: border-box;
    .mall-tc {
      display: flex;
      align-items: center;
      text-align: center;
      color: #fff;
      justify-content: space-around;
      .totalPoints,
      .usePoints {
        height: 140px;
        .item-t {
          font-size: 60px;
          font-weight: 700;
          margin-bottom: 10px;
        }
        .item-b {
          font-size: 28px;
        }
      }
      .btnPoints {
        width: 200px;
        height: 64px;
        background: #fff;
        font-size: 28px;
        line-height: 64px;
        color: rgba(0, 126, 255, 1);
        border-radius: 30px;
      }
    }
  }
  .mall-c {
    padding: 0 30px;
    margin: -96px 0 0;
    .mallIcon {
      background: #fff;
      box-sizing: border-box;
      padding: 44px 36px;
      border-radius: 20px;
      box-shadow: 0px 0px 40px 12px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-around;

      .item-t {
        width: 87px;
        height: 87px;
        margin: 0 auto 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-b {
        text-align: center;
        font-size: 26px;
        color: #333333;
      }
    }
  }
  .mall-title {
    // padding: 0 40px 48px;
    margin: 16px 42px 0px 42px;
    text-align: right;
    line-height: 48px;
    span {
      &.more {
        font-size: 24px;
        font-weight: 400;
        color: #347eff;
      }
    }
  }
  .mall-nav {
    // display: flex;
    // justify-content: space-between;
    // position: fixed;
    // top: 0;
    // left: 0;
    width: 100%;
    // box-sizing: border-box;
    // z-index: 999;
    // box-sizing: border-box;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    z-index: 2;
    // padding: 32px 0 0;
    color: #71a4ffff;
    > div {
      flex: 1;
      height: 50px;
      display: flex;
      font-size: 32px;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      line-height: 50px;
      span {
        vertical-align: middle;
      }
    }
    .active {
      font-weight: bold;
      color: #367bf4ff;
      span {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: -5px;
          right: -15px;
          display: block;
          height: 10px;
          width: 10px;
          background: #ffb92dff;
          border-radius: 50%;
        }
      }
    }
  }
  .mallList {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 32px 0;
    justify-content: space-between;
    .item {
      padding-top: 40px;
      position: relative;
      width: 310px;
      margin-bottom: 20px;
      .quan {
        width: 110px;
        height: 64px;
        position: absolute;
        top: 0;
        right: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &:nth-of-type(1),
      &:nth-of-type(2) {
        padding-top: 0;
      }
      // height: 470px;
      box-sizing: border-box;
      .item-t {
        width: 310px;
        height: 310px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          border-radius: 20px;
        }
      }
      .item-b {
        width: 310px;
        margin-top: 20px;
        font-size: 32px;
        font-weight: bold;
        line-height: 36px;
        padding-left: 10px;
        .title {
          margin: 10px 0;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .price {
          color: #347eff;
        }
        .loot-all-text {
          box-sizing: border-box;
          font-size: 26px;
          height: 44px;
          line-height: 44px;
          color: #cccccc;
          border: 1px solid #cccccc;
          border-radius: 18px;
          width: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          float: right;
          margin-top: -44px;
        }
      }
    }
  }
  .toHome {
    width: 120px;
    height: 120px;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 10%);
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    img {
      width: 80%;
      height: 80%;
      vertical-align: middle;
    }
    &::after {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }
}
.dialog {
  font-size: 34px;
  font-weight: bold;
  color: #000000d9;
  p.dialog-content {
    text-align: center;
    padding: 56px 0 92px 0;
  }
  div.dialog-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 56px;
    .btn {
      width: 440px;
      height: 66px;
      text-align: center;
      line-height: 66px;
      color: #ffffff;
      font-weight: bold;
      font-size: 30px;
      background: linear-gradient(180deg, #73a8ff 0%, #307cff 100%);
      border-radius: 10px;
      &.close {
        background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
        color: #a7a7a7ff;
      }
    }
  }
}
</style>

<template>
  <div class="section">
    <div class="section-search">
      <div class="search-select" @click="chooseSortType">
        <span>{{ sortMap[selectType] }}</span>
        <img src="../img/arrow_down.png" alt="" />
      </div>
      <div class="search-input">
        <v-input v-model="title" placeholder="请输入商品名称" />
      </div>
      <div class="search-button" @click="onRefresh">
        <img src="../img/search_icon.png" alt="" />
      </div>
    </div>
    <div class="section-title">
      <span>{{ typeName }}</span>
    </div>
    <div class="list">
      <v-list
        description="上架中..."
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="section-flex-item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="item-flex">
            <div class="item-picture">
              <img :src="item.imageUrl" alt="" />
            </div>
            <div class="item-detail">
              <div class="item-detail-title">
                <span>{{ item.title }}</span>
              </div>
              <div class="item-detail-busines">
                商家：<span>{{ item.shopName }}</span>
              </div>
              <div class="item-detail-price">
                <span>￥{{ item.price }}</span>
                <img
                  src="../img/shop_icon.png"
                  alt=""
                  @click.stop="addShopCart(item)"
                />
              </div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <v-picker
      :columns="sortOps"
      :valueShow="showPicker"
      :valueKey="valueKey"
      @clickOverlay="overlayPick"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
// import {
//   businessTypeopsMap,
//   businessTypeops,
//   manageTypeopsMap,
//   manageTypeops,
// } from "./map.js";
import {
  getGoodListByTypeAndTitleURL,
  addTrolleyURL,
  initWxJsSdkURL,
} from "./api.js";
// import { gloabalCount, toRegister } from "@/utils/common";
// import { handleImg } from "@/utils/utils.js";
export default {
  data() {
    return {
      selectType: "", // 排序类型
      showPicker: false,
      sortMap: {
        "": "全部",
        1: "最新发布",
        2: "商家距离",
        3: "销售量",
      },
      valueKey: "text",
      sortOps: [
        {
          text: "全部",
          value: "",
        },
        {
          text: "最新发布",
          value: 1,
        },
        {
          text: "商家距离",
          value: 2,
        },
        {
          text: "销售量",
          value: 3,
        },
      ],
      latitude: "",
      longitude: "",
      title: "", // 关键字查询
      goodsType: "",
      typeName: "",
      /* v-list */
      finishedText: "没有更多了",
      finished: false,
      listData: [
        // {
        //   title: "【当季现挖】土豆 约500g",
        //   price: 5.13,
        // },
        // {
        //   title: "【本地鲜】千岛湖青甘蓝800g/份",
        //   price: 7.19,
        // },
        // {
        //   title: "【三优蔬菜】香葱 约50g",
        //   price: 0.99,
        // },
      ],
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId", "openId"]),
  },
  async created() {
    const { goodsType, typeName } = this.$route.query;
    this.goodsType = goodsType;
    await this.initWxConfig();
    this.getLocation();
    this.typeName = typeName;
  },
  methods: {
    // 微信授权H5可调用的 wx API功能
    async initWxConfig() {
      const url =
        process.env.NODE_ENV == "production"
          ? location.href.split("#")[0]
          : "https://fenghua.zhuneng.cn";
      let res = await this.$axios.post(
        `${initWxJsSdkURL}`,
        this.$qs.stringify({
          url,
        })
      );
      if (res.code == 200) {
        // this.wxData = res.data;
        const result = res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: ["checkJsApi", "getLocation"], // 必填，需要使用的 JS 接口列表
        });
      }
    },
    getLocation() {
      let _this = this;
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: ["getLocation"],
          success: (rest) => {
            //获取当前位置
            wx.getLocation({
              type: "gcj02", // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
              success: function (res) {
                console.log("res----->", res);
                _this.latitude = res.latitude;
                _this.longitude = res.longitude;
              },
            });
          },
          fail: (res) => {
            console.log("res----->", res);
          },
        });
      });
      wx.error((error) => {
        console.log("error----->", error);
      });
    },
    // 选择排序类型
    chooseSortType() {
      this.showPicker = true;
    },
    // 点击遮罩层
    overlayPick() {
      this.showPicker = false;
    },
    // 点击关闭
    cancelPick() {
      this.showPicker = false;
    },
    // 点击确认
    confirmPick(val) {
      this.selectType = val.value;
      this.showPicker = false;
      this.onRefresh();
    },
    // 重置商户列表
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 获取商户列表
    onLoad() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
        curPage: this.curPage,
        pageSize: this.pageSize,
        // userId: this.userId,
        title: this.title,
        goodsType: this.goodsType,
        sortType: this.selectType,
        latitude: this.latitude,
        longitude: this.longitude,
      };
      this.$axios
        .get(getGoodListByTypeAndTitleURL, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        });
    },
    // 点击前往详情
    toDetail(item) {
      this.$router.push({
        name: "bcnCategoryShopDetail",
        query: {
          id: item.id,
        },
      });
    },
    // 添加购物车
    async addShopCart(item) {
      let res = await this.$axios.post(addTrolleyURL, {
        goodsId: item.id,
        goodsType: 3,
        goodsNum: 1,
        insertUser: this.userId,
      });
      if (res.code == 200) {
        this.$toast({ message: "添加成功！", duration: 2000 });
      }
    },
  },
};
</script>

<style scoped lang="less">
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.section {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #ffffff;
  padding-bottom: calc(
    20px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    20px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-search {
    display: flex;
    align-items: center;
    padding: 0 28px;
    margin: 30px 0;
    .search-select {
      box-sizing: border-box;
      width: 200px;
      height: 58px;
      border-radius: 10px;
      border: 2px solid rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      padding: 8px 12px 8px 16px;
      > img {
        &:extend(.common-img);
        width: 28px;
        height: 16px;
      }
      span {
        flex: 1;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .search-input {
      flex: 1;
      padding: 0 20px;
      ::v-deep .van-cell {
        padding: 0 36px;
        background: #f7f7f7;
        border-radius: 31px;
        line-height: 58px;
      }
    }
    .search-button {
      width: 60px;
      text-align: center;
      > img {
        width: 36px;
        height: 40px;
        &:extend(.common-img);
      }
    }
  }
  .section-title {
    margin: 40px 30px 0;
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 42px;
  }
  .list {
    box-sizing: border-box;
    width: 100%;
    padding: 0 28px;
    .section-flex-item {
      margin-top: 30px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      .item-flex {
        padding: 22px 20px;
        border-top: 2px solid rgba(0, 0, 0, 0.06);
        display: flex;
        align-items: center;
        .item-picture {
          width: 170px;
          min-width: 170px;
          height: 170px;
          border-radius: 16px;
          overflow: hidden;
          margin-right: 28px;
          > img {
            &:extend(.common-img);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .item-detail {
          flex: 1;
          .item-detail-title {
            font-size: 32px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            line-height: 44px;
            word-break: break-all;
            word-wrap: break-word;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .item-detail-busines {
            margin-top: 4px;
            // display: none;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.5);
            word-break: break-all;
            word-wrap: break-word;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .item-detail-price {
            margin-top: 24px;
            font-size: 32px;
            font-weight: 400;
            line-height: 44px;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: space-between;
            span:first-child {
              flex: 1;
              color: #fe9616;
            }
            img {
              &:extend(.common-img);
              width: 42px;
              height: 42px;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <van-rate
    :value="+value"
    :count="count"
    :icon="icon"
    :void-icon="voidIcon"
    :color="color"
    :void-color="voidColor"
    :disabled-color="disabledColor"
    :readonly="readonly"
    :disabled="disabled"
    :touchable="touchable"
    :size="sizeRem"
    :gutter="gutterRem"
    @input="onInput"
    @change="onChange"
  >
    <slot></slot>
  </van-rate>
</template>

<script>
import { Rate } from "vant";

export default {
  model: {
    prop: "value",
    event: "input",
  },
  components: {
    vanRate: Rate,
  },
  props: {
    // v-model 绑定值
    value: [String, Number],
    // 图标总数
    count: {
      type: Number,
      default: 5,
    },
    // 图标大小，默认单位为2倍px - 自动转为rem
    size: {
      type: Number,
      default: 40,
    },
    // 图标间距，默认单位为2倍px - 自动转为rem
    gutter: {
      type: Number,
      default: 8,
    },
    // 是否为只读状态
    readonly: {
      type: Boolean,
      default: false,
    },
    // 是否禁用评分
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否可以通过滑动手势选择评分
    touchable: {
      type: Boolean,
      default: true,
    },
    // 选中时的图标名称或图片链接
    icon: {
      type: String,
      default: "star",
    },
    // 未选中时的图标名称或图片链接
    voidIcon: {
      type: String,
      default: "star-o",
    },
    // 选中时的颜色
    color: {
      type: String,
      default: "#ffd21e",
    },
    // 未选中时的颜色
    voidColor: {
      type: String,
      default: "#c8c9cc",
    },
    // 禁用时的颜色
    disabledColor: {
      type: String,
      default: "#c8c9cc",
    },
  },
  data() {
    return {};
  },
  computed: {
    sizeRem() {
      return `${this.size / this.$baseUnit}rem`;
    },
    gutterRem() {
      return `${this.gutter / this.$baseUnit}rem`;
    },
  },
  methods: {
    // 当前分值变化时触发的事件
    onChange(val) {
      // val 当前分值
      this.$emit("change", val);
    },
    onInput(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="less" scoped></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionnaireDetail"},[_c('div',{staticClass:"scroll-div"},[(_vm.listData.length == 1 && _vm.listData[0] && _vm.listData[0].quType == 11)?_c('div',{staticClass:"against"},[_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"against-item",attrs:{"id":`questionnaireDetail_${index}`}},[_c('div',{class:['title', { titlediv: _vm.isdiv }]},[_vm._v(_vm._s(item.title))]),(_vm.isdiv)?_c('div',{staticClass:"info"},[_vm._v("感谢您的参与～")]):_vm._e(),_c('div',{staticClass:"detail"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.description)}})]),_c('div',{staticClass:"content"},_vm._l((item.optionList),function(opt,oIndex){return _c('div',{key:oIndex,staticClass:"item",class:{ optionSelect: _vm.getIsSelect(opt, item) },on:{"click":function($event){return _vm.setOptAnswer(opt, item)}}},[(opt.imageUrls)?_c('div',{staticClass:"content-t"},[_c('img',{attrs:{"src":opt.imageUrls}})]):_vm._e(),_c('div',{staticClass:"content-b"},[_vm._v(_vm._s(opt.optionTitle))]),(!_vm.isdiv)?_c('div',{staticClass:"btn"},[_vm._v("投票")]):_vm._e()])}),0),(_vm.isdiv)?_c('div',{staticClass:"result"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"result-l",style:({
                width: `${_vm.getWidth(item.optionList[0], item.optionList)}px`,
              })},[(item.answer == item.optionList[0].optionKey)?_c('div',{staticClass:"select"},[_c('img',{attrs:{"src":require('./img/check.png')}})]):_vm._e(),_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s((item.optionList && item.optionList[0].checkedCount) || 0)+" ")])]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require('./img/result.png')}})]),_c('div',{staticClass:"result-r",style:({
                width: `${_vm.getWidth(item.optionList[1], item.optionList)}px`,
              })},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s((item.optionList && item.optionList[1].checkedCount) || 0)+" ")]),(item.answer == item.optionList[1].optionKey)?_c('div',{staticClass:"select"},[_c('img',{attrs:{"src":require('./img/check-right.png')}})]):_vm._e()])])]):_vm._e()])}),_c('div',{staticClass:"remark"},[_c('span',{staticClass:"span"},[_vm._v(_vm._s(_vm.answerCount))]),_vm._v("人参与 "),(_vm.endDay != -1)?_c('span',[_vm._v("还有 "),_c('span',{staticClass:"span"},[_vm._v(_vm._s(_vm.endDay))]),_vm._v(" 结束")]):_c('span',[_vm._v("已结束")])])],2):[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-title"},[_vm._v(" "+_vm._s(_vm.activityName ? `${_vm.activityName}小测试` : "")+" ")]),_c('div',{staticClass:"detail"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.description)}})])]),_c('div',{staticClass:"main"},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"content-wrap",attrs:{"id":`questionnaireDetail_${index}`}},[_c('div',{staticClass:"content-wrap_title"},[_vm._v(" "+_vm._s(`${index + 1}、${item.title}`)+" "),(item.quType === 2)?[_vm._v(_vm._s(`${ item.minSelection ? `最少选择${item.minSelection}项，` : "" }${ item.maxSelection ? `最多选择${item.maxSelection}项` : "" }（多选）`))]:_vm._e(),(item.quType === 5)?[_vm._v(" "+_vm._s("【矩阵量表题】从1-5进行打分")+" ")]:_vm._e()],2),(item.quType)?_c('div',{staticClass:"content-wrap_type"},[(item.quType === 1)?_c('div',{staticClass:"single"},_vm._l((item.optionList),function(opt,oIndex){return _c('div',{key:oIndex,staticClass:"option-wrap",class:{ optionSelect: _vm.getIsSelect(opt, item) },style:({
                  'background-image': !_vm.isdiv ? 'none' : 'none',
                }),on:{"click":function($event){return _vm.setOptAnswer(opt, item)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isdiv),expression:"isdiv"}],staticClass:"option-progress",style:({
                    width: `${100 - _vm.getPercent(opt, item)}%`,
                  })}),_c('div',{staticClass:"option-text_copy"},[_vm._v(" "+_vm._s(`${opt.optionKey}、${opt.optionTitle || ""}`)+" ")]),_c('div',{staticClass:"option-text"},[_vm._v(" "+_vm._s(`${opt.optionKey}、${opt.optionTitle || ""}`)+" ")]),_c('div',{staticClass:"option-icon"},[_c('img',{attrs:{"src":require('./img/check.png')}})])])}),0):_vm._e(),(item.quType === 2)?_c('div',{staticClass:"multi"},_vm._l((item.optionList),function(opt,oIndex){return _c('div',{key:oIndex,staticClass:"option-wrap",class:{ optionSelect: _vm.getIsSelect(opt, item) },style:({
                  'background-image': !_vm.isdiv ? 'none' : 'none',
                }),on:{"click":function($event){return _vm.setOptAnswer(opt, item)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isdiv),expression:"isdiv"}],staticClass:"option-progress",style:({
                    width: `${100 - _vm.getPercent(opt, item)}%`,
                  })}),_c('div',{staticClass:"option-text_copy"},[_vm._v(" "+_vm._s(`${opt.optionKey}、${opt.optionTitle || ""}`)+" ")]),_c('div',{staticClass:"option-text"},[_vm._v(" "+_vm._s(`${opt.optionKey}、${opt.optionTitle || ""}`)+" ")]),_c('div',{staticClass:"option-icon"},[_c('img',{attrs:{"src":require('./img/check.png')}})])])}),0):_vm._e(),(item.quType === 3)?_c('div',{staticClass:"input"},[_c('v-input',{attrs:{"type":"textarea","disabled":_vm.isdiv,"placeholder":_vm.isdiv ? '' : '请输入内容',"maxlength":200,"show-word-limit":"","rows":"2"},model:{value:(item.answer),callback:function ($$v) {_vm.$set(item, "answer", $$v)},expression:"item.answer"}})],1):_vm._e(),(item.quType === 5)?_c('div',{staticClass:"scale"},_vm._l((item.optionList),function(opt,oIndex){return _c('div',{key:oIndex,staticClass:"option-wrap"},[_c('div',{staticClass:"option-text"},[_vm._v(" "+_vm._s(`${opt.optionKey}、${opt.optionTitle || ""}`)+" ")]),_c('div',{staticClass:"option-block"},_vm._l((_vm.scaleColorOps),function(v,i){return _c('div',{key:i,staticClass:"block-color",class:{ 'no-border': opt.point >= i + 1 },style:({
                      backgroundColor: opt.point >= i + 1 ? v : '',
                    }),on:{"click":function($event){return _vm.setOptAnswer(opt, item, i)}}},[(!_vm.isdiv)?_c('span',[_vm._v(_vm._s(i + 1)+"分")]):_vm._e(),(_vm.isdiv)?_c('div',{staticClass:"option-data"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(opt.checkedCountExt ? opt.checkedCountExt.split(",")[i] : 0)+"人 ")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(`${_vm.getPercent( opt.checkedCountExt ? opt.checkedCountExt.split(",")[i] : 0, item.optionList, 5 )}%`)+" ")])]):_vm._e()])}),0)])}),0):_vm._e()]):_vm._e()])}),0)]],2),(_vm.listData.length > 0)?_c('div',{staticClass:"footer",on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.subLoading ? "提交中..." : "提交")+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
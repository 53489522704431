<template>
  <div class="health-hut">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
      :immediate-check="false"
      @load="getList"
    >
      <div class="tijian-item" v-for="(item, index) in list" :key="'tjjl' + index">
        <img v-show="item.detailShow" class="zkzd-icon" src="./img/icon_up.png" alt="">
        <img v-show="!item.detailShow" class="zkzd-icon" src="./img/icon_down.png" alt="">
        <div class="jilu-box" @click="tijianOnClick(item)">
          <div class="label-value-box">姓名：{{ item.name || '-' }}</div>
          <div class="label-value-box">性别：{{ item.sex || '-' }}</div>
          <div class="label-value-box">年龄：{{ item.age || '-' }}岁</div>
          <div class="label-value-box">检查时间：{{ item.createTime || '-' }}</div>
        </div>
        <div class="tijianxiang-box" :class="['tjid-' + item.checkinId]" :style="{ height: item.detailShow ? item.detailH + 'px' : '0px' }">
          <div class="tijian-info-item" v-for="(detail, index2) in item.details" :key="'xq' + index + '-' + index2">
            <div class="label-value-box">项目：{{ detail.projectName || '-' }}</div>
            <div class="label-value-box">检查结果：{{ detail.result || '-' }}{{ detail.unit || '-' }}</div>
            <div class="label-value-box">参考值：{{ detail.reference || '-' }}{{ detail.unit || '-' }}</div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { List } from 'vant';
import { toRegister } from '@/utils/common.js';
import { getUserInfoUrl, getMedicalRecordListUrl, getMedicalRecordDetailUrl } from './api.js';

export default {
  name: 'healthHut',
  components: {
    [List.name]: List
  },
  props: {},
  model: {},
  data() {
    return {
      loading: false,
      finished: false,
      error: false,
      list: [],
      total: 0,
      params: {
        idCard: '',
        curPage: 1,
        pageSize: 10
      }
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId'])
  },
  watch: {},
  async created() {
    if (await toRegister(this.$route.path, this.$route.query, '每日签到')) {
      this.$axios.get(getUserInfoUrl, { params: { userId: this.userId } }).then(res => {
        if (res && res.code === 200) {
          res.data = res.data || {};
          if (['', null, void 0, NaN, -1, 0, 2, '-1', '0', '2'].includes(res.data.realNameAuditStatus)) {
            this.$dialog.alert({
              message: '您未实名，或实名未通过',
              showConfirmButton: false,
              showCancelButton: false
            });
            return;
          }
          this.params.idCard = res.data.idCardNum;
          this.getList();
        }
      });
    }
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    getList() {
      this.$axios
        .get(getMedicalRecordListUrl, { params: this.params })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.records = Array.isArray(res.data.records) ? res.data.records : [];
            res.data.records.forEach(item => {
              item.details = Array.isArray(item.details) ? item.details : [];
              item.detailShow = false;
              item.detailed = false;
              item.detailH = 0;
            });
            this.list.push(...res.data.records);
            this.params.curPage++;
            this.total = res.data.total;
            this.loading = false;
            if (this.list.length >= this.total) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.error = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    tijianOnClick(value) {
      if (value.detailed && value.details.length === 0) return; // 已加载并不存在体检数据
      if (!value.detailed) {
        value.detailed = true;
        this.$axios.get(getMedicalRecordDetailUrl, { params: { checkinId: value.checkinId } }).then(res => {
          if (res && res.code === 200) {
            res.data = Array.isArray(res.data) ? res.data : [];
            value.details = res.data;
            value.detailShow = true;
            this.$nextTick(() => {
              value.detailH = document.querySelector('.tjid-' + value.checkinId).scrollHeight;
              console.log('detailH => ', value.detailH);
            });
          } else {
            value.details = [{}, {}];
            value.detailShow = true;
            this.$nextTick(() => {
              value.detailH = document.querySelector('.tjid-' + value.checkinId).scrollHeight;
              console.log('detailH => ', value.detailH);
            });
          }
        });
      } else {
        value.detailShow = !value.detailShow;
      }

      // this.$router.push({
      //   name: 'healthHutDetail',
      //   query: {
      //     checkinId: value.checkinId
      //   }
      // });
    }
  }
};
</script>

<style lang="less" scoped>
.health-hut {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  overflow-x: hidden;
  overflow-y: auto;
  padding: 30px 30px;
  background-color: #f7f7f7;
  .tijian-item {
    box-sizing: border-box;
    margin-bottom: 30px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    padding: 16px 24px;
    position: relative;
    .zkzd-icon {
      box-sizing: border-box;
      position: absolute;
      right: 24px;
      top: 16px;
      width: 26px;
      height: 26px;
    }
    .jilu-box {
      box-sizing: border-box;
      .label-value-box {
        font-size: 28px;
        line-height: 46px;
        color: #1a1c34;
      }
    }
    .tijianxiang-box {
      box-sizing: border-box;
      margin-top: 10px;
      overflow: hidden;
      transition: all 0.3s linear 0s;
      .tijian-info-item {
        box-sizing: border-box;
        margin-top: 20px;
        background-color: #F6FBFF;
        // border: 1px solid #ccc;
        border-radius: 16px;
        padding: 16px 24px;
        font-size: 28px;
        line-height: 46px;
        &:first-child {
          margin-top: 0px;
        }
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>

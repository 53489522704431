<template>
  <div class="comment-rate">
    <div class="section-header">
      <span>评价</span>
      <div class="header-button" @click="toComment">评价</div>
    </div>
    <div class="section-list" v-if="listData.length > 0">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="section-flex-item"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="item-info">
            <div class="info-picture">
              <img
                :src="item.avatar ? item.avatar : require('../img/avatar.png')"
                alt=""
              />
            </div>
            <div class="info-detail">
              <div class="info-detail-title">
                <span>{{ item.nickName }}</span>
                <span>{{ item.createTime }}</span>
              </div>
              <div class="info-detail-rate">
                <v-rate class="v-rate" readonly v-model="item.score" />
                <!-- <span>{{ item.rate }}</span> -->
              </div>
            </div>
          </div>
          <div class="item-text" v-if="item.commentContent">
            {{ item.commentContent }}
          </div>
        </div>
      </v-list>
    </div>
    <div class="popup" v-if="showPopup">
      <div class="popup-overlary" @click="showPopup = false"></div>
      <div class="popup-content">
        <div class="popup-rate">
          <span>对商家的评价</span>
          <v-rate class="v-rate" v-model="comment.score" />
        </div>
        <div class="popup-textarea">
          <v-input
            type="textarea"
            placeholder="请输入"
            :showWordLimit="true"
            :maxlength="500"
            v-model.trim="comment.commentContent"
          />
        </div>
        <div class="popup-footer">
          <div class="footer-button" @click="sendComment">发布</div>
        </div>
      </div>
    </div>
    <!-- </v-popup> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { addEvaluateRecordURL, getEvaluateRecordListURL } from "../service/api";

export default {
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showPopup: false,
      /* v-list */
      finishedText: "没有更多了",
      finished: true, // 默认完成
      listData: [
        // {
        //   userName: "小马（用户昵称）",
        //   imageUrl: "",
        //   createTime: "2023-02-20 17:47",
        //   rate: 3,
        //   remark: "服务非常满意，很周到",
        // },
        // {
        //   userName: "小马（用户昵称）",
        //   imageUrl: "",
        //   createTime: "2023-02-20 17:47",
        //   rate: 3,
        //   remark: "服务非常满意，很周到",
        // },
        // {
        //   userName: "小马（用户昵称）",
        //   imageUrl: "",
        //   createTime: "2023-02-20 17:47",
        //   rate: 3,
        //   remark: "服务非常满意，很周到",
        // },
        // {
        //   userName: "小马（用户昵称）",
        //   imageUrl: "",
        //   createTime: "2023-02-20 17:47",
        //   rate: 3,
        //   remark: "服务非常满意，很周到",
        // },
      ],
      curPage: 1,
      pageSize: 10,
      /* v-popup */
      comment: {
        score: "",
        commentContent: "",
      },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  watch: {
    params: {
      handler(val) {
        if (val.id) {
          this.onRefresh();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    toComment() {
      this.comment = {
        score: "",
        commentContent: "",
      };
      this.showPopup = true;
    },
    async sendComment() {
      const { score, commentContent } = this.comment;
      if (!score) {
        this.$toast({ message: "请选择评分", duration: 2000 });
        return;
      } else if (!commentContent) {
        this.$toast({ message: "请输入评价内容", duration: 2000 });
        return;
      }
      let res = await this.$axios.post(addEvaluateRecordURL, {
        mallId: this.params.id,
        ...this.comment,
        createUser: this.userId,
      });
      if (res.code == 200) {
        this.$toast({ message: "评价成功", duration: 2000 });
        this.showPopup = false;
        this.onRefresh();
      }
    },
    // 重置商户列表
    onRefresh() {
      this.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    // 获取商户列表
    onLoad() {
      let params = {
        id: this.params.id,
        curPage: this.curPage,
        pageSize: this.pageSize,
      };
      this.$axios.get(getEvaluateRecordListURL, { params }).then((res) => {
        if (res.code != 200) {
          this.finished = true;
        } else {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.curPage++;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.comment-rate {
  font-family: PingFangSC-Regular, PingFang SC;
  padding-top: 32px;
  padding-bottom: calc(
    32px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    32px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-header {
    display: flex;
    span {
      flex: 1;
      font-size: 36px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 50px;
    }
    .header-button {
      box-sizing: border-box;
      width: 122px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 2px solid #ffb92d;
      font-size: 26px;
      font-weight: 400;
      color: #ffb92d;
    }
  }
  .section-list {
    box-sizing: border-box;
    width: 100%;
    .section-flex-item {
      // &:not(:first-child) {
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      // }
      padding: 32px 0;
      .item-info {
        display: flex;
        align-items: center;
        .info-picture {
          width: 92px;
          min-width: 92px;
          height: 92px;
          // overflow: hidden;
          margin-right: 22px;
          > img {
            &:extend(.common-img);
            border-radius: 50%;
            width: 92px;
            height: 92px;
            object-fit: cover;
          }
        }
        .info-detail {
          max-width: calc(100% - 114px);
          flex: 1;
          .info-detail-title {
            margin-top: 10px;
            font-size: 26px;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            span:first-child {
              max-width: calc(100% - 230px);
              flex: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              word-break: break-all;
              overflow: hidden;
            }
            span:last-child {
              margin-left: 10px;
              white-space: nowrap;
              color: rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
      .item-text {
        margin-top: 12px;
        font-size: 26px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .popup-overlary {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    .popup-content {
      position: absolute;
      background: #fff;
      padding: 40px 32px;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      padding-bottom: calc(
        32px + constant(safe-area-inset-bottom)
      ); /* 兼容 iOS < 11.2 */
      padding-bottom: calc(
        32px + env(safe-area-inset-bottom)
      ); /* 兼容 iOS >= 11.2 */
      .popup-rate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 50px;
        margin-bottom: 30px;
      }
      .popup-textarea {
        margin-bottom: 30px;
        ::v-deep .van-cell {
          background: rgba(0, 0, 0, 0.05);
          border-radius: 16px;
          textarea {
            height: 240px !important;
          }
        }
      }
      .popup-footer {
        display: flex;
        justify-content: flex-end;
        .footer-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 210px;
          height: 70px;
          background: #db431d;
          border-radius: 16px;
          font-size: 30px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
}
</style>

<template>
  <div class="home">
    测试支付
    <!-- <van-button type="danger">危险按钮</van-button> -->
    <!-- <div>{{ path }}</div>
    <br />
    <div>{{ data }}</div>
    <div>结果:{{ result }}</div> -->
  </div>
</template>

<script>
import store from "@/store";
export default {
  name: "Home",
  data() {
    return {
      data: "",
      search: "",
      path: "",
      userInfo: null,
      result: {},
    };
  },
  async created() {
    // this.path = window.location.href;
    // this.search = window.location.search;
    // const query = this.getSearchData(window.location.search);
    // this.data = query;
    // await this.getInfo(query);
    // if (Object.keys(this.data).length > 0 && this.userInfo) {
    //   this.toRouter(this.userInfo);
    //   return;
    // }
  },
  mounted() {
    setTimeout(() => {
      this.getLocationData();
    }, 800);
  },
  methods: {
    getLocationData() {
      window.xy.ready(() => {
        window.xy.getLocation((res) => {
          this.result = res;
        });
      });
    },
    toRouter(userInfo) {
      if (
        userInfo.extraParameter.indexOf("latitude") != -1 &&
        userInfo.extraParameter.indexOf("longitude") != -1
      ) {
        // 需要携带额外参数
        if (
          JSON.stringify(this.result) != "{}" &&
          this.result.latitude &&
          this.result.longitude
        ) {
          this.$router.replace({
            name: userInfo.routerName,
            query: {
              latitude: this.result.latitude,
              longitude: this.result.longitude,
              path: this.path,
            },
          });
        } else {
          this.toRouter(userInfo);
        }
      } else {
        // 无额外参数
        this.$router.replace({
          name: userInfo.routerName,
        });
      }
    },
    // 获取所需参数
    async getInfo(data) {
      let params = data;
      let url = `https://test.zhuneng.cn/gateway/jb-future/jbfuture/getCaseInfoByTicket`;
      let res = await this.$axios.post(`${url}`, params, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });
      if (res.code == 200) {
        this.userInfo = res.data;
        let user = {
          assetId: res.data.assetId,
          communityId: res.data.communityId,
          houseId: res.data.houseId,
          orgId: res.data.orgId,
          tenantId: res.data.tenantId,
          userId: res.data.userId,
        };
        this.extraOneMore(user);
      }
    },
    // 更新vuex参数
    extraOneMore(user) {
      sessionStorage.setItem("userInfo", JSON.stringify(user));
      store.commit("setAssetId", user.assetId);
      store.commit("setOpenId", user.openId);
      store.commit("setDefOrgId", user.defOrgId);
      store.commit("setHouseId", user.houseId);
      store.commit("setCommunityId", user.communityId);
      store.commit("setUserId", user.userId);
      store.commit("setTenantId", user.tenantId);
    },
    // location.search 转换为 对象
    getSearchData(search) {
      let obj = {};
      let arr = search.slice(1).split("&");
      arr.forEach((item) => {
        let newArr = item.split("=");
        obj[newArr[0]] = newArr[1];
      });
      return obj;
    },
  },
};
</script>

<style lang="less" scoped></style>

<template>
  <div class="shareFarmlandDetail">
    <div class="title">社区托幼简介</div>
    <div class="body">
      <h4>
        在未来社区共享农田项目中，我们旨在打造一个集生态、教育、休闲与自给自足于一体的社区公共空间。该项目通过整合社区内闲置土地资源鼓励居民共同参与农田的种植与管理，促进邻里间的交流与合作，同时增强居民对自然环境的认识与保护意识。项目旨在实现土地的可持续利用，丰富社区文化生活，提升居民的生活品质共同构建一个绿色、和谐、共享的社区环境。
      </h4>
      <h3>土地使用规则说明</h3>
      <p>
        <b>1、合法合规:</b
        >所有农田使用活动必须遭守国家法律法规，不得擅自改变士地用途或进行违法建设。
      </p>
      <p>
        <b>2、轮作休耕:</b
        >鼓励实施轮作制度，保持土壤肥力，部分地块实行季节性休耕，促进生态平衡。
      </p>
      <p>
        <b>3、环境保护:</b
        >严禁使用有害农药和化肥推广有机种植，保护农田生态环境。
      </p>
      <p>
        <b>4、公共设施维护:</b
        >共同维护农田周边的灌溉系统、道路等公共设施，确保其正常运行。
      </p>
    </div>
    <!-- <div class="img"><img src="./img/detail.png" alt="" /></div> -->
  </div>
</template>

<script>
export default {
  name: "shareFarmland",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.shareFarmlandDetail {
  padding: 18px 32px;
  .title {
    font-weight: 700;
    font-size: 48px;
    color: #000000;
    line-height: 66px;
  }
  .img {
    img {
      width: 100%;
    }
  }
  .body {
    margin: 30px 0;
    h3 {
      font-weight: 700;
      font-size: 28px;
      color: #333333;
      line-height: 52px;
    }
    h4 {
      font-size: 28px;
      color: #333333;
      line-height: 52px;
    }
    p {
      font-weight: 400;
      font-size: 28px;
      color: #333333;
      line-height: 52px;
    }
  }
}
</style>

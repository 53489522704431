<template>
  <div>
    <van-stepper v-model="model" :min="min" :max="max" :disabled="disabled" />
  </div>
</template>

<script>
import Vue from "vue";
import { Stepper } from "vant";

Vue.use(Stepper);
export default {
  watch: {
    value(newValue, oldValue) {
      this.model = newValue;
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  components: {},
  props: {
    min: {
      type: [Number, String],
      default: 0,
    },
    max: [Number, String],
    value: {
      type: [Number, String],
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style></style>

<template>
  <van-count-down
    ref="vanCountDown"
    :time="time"
    :format="format"
    :autoStart="autoStart"
    :millisecond="millisecond"
    @finish="onFinish"
    @change="onChange"
  >
    <template #default="timeData">
      <slot v-bind="timeData"></slot>
    </template>
  </van-count-down>
</template>

<script>
import { CountDown } from "vant";

export default {
  components: {
    vanCountDown: CountDown,
  },
  props: {
    // 倒计时时长，单位毫秒
    time: {
      type: [Number, String],
      required: true,
    },
    /* 时间格式
      DD	天数
      HH	小时
      mm	分钟
      ss	秒数
      S	毫秒（1 位）
      SS	毫秒（2 位）
      SSS	毫秒（3 位）
    */
    format: {
      type: String,
      default: "HH:mm:ss",
    },
    // 是否自动开始倒计时
    autoStart: {
      type: Boolean,
      default: true,
    },
    // 	是否开启毫秒级渲染
    millisecond: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onFinish() {
      this.$emit("finish");
    },
    onChange(timeData) {
      /* 
        days	剩余天数	number
        hours	剩余小时	number
        minutes	剩余分钟	number
        seconds	剩余秒数	number
        milliseconds	剩余毫秒	number
      */
      this.$emit("change", timeData);
    },
    // 开始倒计时
    start() {
      this.$refs.vanCountDown.start();
    },
    // 暂停倒计时
    pause() {
      this.$refs.vanCountDown.pause();
    },
    // 重设倒计时，若 auto-start 为 true，重设后会自动开始倒计时
    reset() {
      this.$refs.vanCountDown.reset();
    },
  },
};
</script>

<style lang="less" scoped></style>

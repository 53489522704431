<template>
  <div class="page">
    <img src="./img/redTour.png" class="banner" />
    <div class="icon-list">
      <div class="item" @click="toPath('digitalExhibitionHall')">
        <img src="./img/icon1.png" alt="" />
        <div class="title">数字化展厅</div>
      </div>
      <div class="item" @click="toPath('peopleIntro')">
        <img src="./img/icon2.png" alt="" />
        <div class="title">红色文化宣传</div>
      </div>
      <div class="item" @click="toPath('visitRedNotice')">
        <img src="./img/icon3.png" alt="" />
        <div class="title">参观须知</div>
      </div>
    </div>
    <div class="line"></div>
    <div class="overViewBox">
      <div class="title-box">
        <div class="left"></div>
        <div class="right">红色文化园</div>
      </div>
      <!-- <v-empty description="暂无数据"></v-empty> -->
      <div class="detail-content">
        <!-- <v-h5Mtml :content="textContent || '暂无'"></v-h5Mtml> -->
        <div class="item">
          <div class="item-t">一、园区背景</div>
          <div class="item-b">
            松岙是著名的红色故里，是宁波第一个农村党支部的诞生地，涌现了卓兰芳、卓恺泽、裘古怀、卓崇德等一大批革命志士。松岙红色文化园曾是卓恺泽公园，如今已焕然一新，成为红色教育的基地。
            松岙红色文化园是传承红色精神的摇篮，也是进行党性教育和红色教育的重要场所。
          </div>
          <img src="./img/red1.jpg" alt="" />
        </div>
        <div class="item">
          <div class="item-t">二、设施与功能</div>
          <div class="item-b">
            园区占地面积为7700平方米，建筑内设有党课课堂、图书馆等，为游客提供丰富的学习资源和舒适的学习环境。
            园区内设有红色课堂，为机关企事业单位的党员提供培训、红色党课等，园区内收藏有丰富的红色历史资料，供游客学习和了解党的历史和思想。
          </div>
          <img src="./img/red2.jpg" alt="" />
        </div>
        <div class="item">
          <div class="item-t">三、特色与亮点</div>
          <div class="item-b">
            松岙红色文化园的建筑风格独特，具有浓厚的红色氛围和历史印记，仿佛能让游客穿越时空，回到那个火红的年代。漫步在园区的小径上，游客可以感受到那份浓厚的红色氛围，每一处都充满了历史的印记。
          </div>
        </div>
      </div>
      <!-- <img src="./img/banner1.png" alt="" />
        <img src="./img/banner2.png" alt="" />
        <img src="./img/banner3.png" alt="" />
        <img src="./img/banner4.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
import { gloabalCount } from "@/utils/common";
import { content } from "./map.js";
export default {
  name: "intangibleCulturalHeritage",
  data() {
    return {
      textContent: content,
    };
  },
  mounted() {
    gloabalCount("", 61, 1);
  },
  methods: {
    toPath(name) {
      if (name == "") {
        this.$toast("敬请期待！");
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  .overViewBox {
    padding: 46px 30px;
    box-sizing: border-box;
    img {
      width: 100%;
      margin-bottom: 18px;
    }
    .title-box {
      display: flex;
      align-items: center;
      margin-bottom: 34px;
      .right {
        font-size: 36px;
        font-weight: 600;
        color: #1a1c34;
      }
      .left {
        width: 10px;
        height: 36px;
        background: #3dad74;
        border-radius: 8px;
        margin-right: 8px;
      }
    }
  }
  .line {
    width: 100%;
    height: 22px;
    background-color: #f7f9f8;
  }
  .icon-list {
    width: 100%;
    height: 278px;
    padding: 32px 80px 0;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .item {
      img {
        width: 152px;
      }
      .title {
        font-size: 24px;
        color: #1a1c34;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
  .item-b {
    font-size: 30px;
    color: #1a1c34;
    text-indent: 2em;
    margin: 10px 0;
  }
  .banner {
    width: 100%;
    height: 320px;
  }
}
</style>

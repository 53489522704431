<template>
  <div :class="['page', { isOld: isOld == 1 ? true : false }]">
    <div class="optiton-box">
      <div class="optiton-box-left" @click="toPath('identityAuth')">
        <img src="./img/renzheng-icon.png" alt="" />
        <div>老年人认证</div>
      </div>
      <div class="optiton-box-right" @click="toPath('myFoodOrderList')">
        <img src="./img/dingdan-icon.png" alt="" />
        <div>我的订单</div>
      </div>
    </div>
    <div class="main-title">
      <v-popover
        v-model="isShowPop"
        :actions="actions"
        placement="bottom-start"
        @select="OnSelect"
      >
        <template #reference>
          <div class="title">
            <span>{{ foodTime == 1 ? "明日午餐菜品" : "明日晚餐菜品" }}</span>
            <van-icon :class="{ titleIcon: isShowPop }" name="arrow-down" />
          </div>
        </template>
      </v-popover>
      <img src="./img/switch.png" @click="switchOld" />
      <div class="label" v-if="isOld == 1 ? false : true" @click="switchOld">
        切换老年版
      </div>
      <div class="label" v-else @click="switchOld">切换标准版</div>
    </div>
    <div class="main">
      <!-- 侧边栏 -->
      <div class="main-left">
        <div
          @click="tabCheck(item, index)"
          :class="['left-item', { 'left-item-act': index == tabAct }]"
          v-for="(item, index) in tabList"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="main-right">
        <div
          class="list"
          :ref="item.code"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="title">{{ item.title }}</div>
          <div
            class="item"
            @click="toPath('foodDetail', item2.id)"
            v-for="(item2, index2) in item.list"
            :key="index2"
          >
            <div class="item-left">
              <img :src="item2.image" alt="" />
            </div>
            <div class="item-right">
              <div class="name">{{ item2.foodName }}</div>
              <div class="discounts" v-if="isDiscounts == 1">
                <span> 长者优惠价 </span>
                <span>¥</span><span>{{ item2.discount }}</span>
              </div>
              <div class="btn">
                <div class="price"><span>¥</span>{{ item2.price }}</div>
                <div class="optBox">
                  <div
                    class="addBtn-gray"
                    @click.stop
                    v-if="checkSelect(item2)"
                  >
                    已选
                  </div>
                  <div v-else class="addBtn" @click.stop="addToCar(item2)">
                    选取
                  </div>
                </div>
                <!-- <img
                  src="./img/subtraction.png"
                  v-if="item2.num > 0"
                  @click.stop="sub(item2, index2)"
                />
                <div class="num" v-if="item2.num > 0">{{ item2.num }}</div>
                <img
                  src="./img/Addition.png"
                  @click.stop="add(item2, index2)"
                /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-box">
      <div class="box" @click="showDialog = true">
        <div class="left">
          <img src="./img/rice-icon.png" alt="" />
          <div class="redBox" v-if="dialogList != []">
            {{ dialogList.length }}
          </div>
        </div>
        <div class="center">
          <span>¥</span
          >{{
            dialogList.length > 0 && isDiscounts == 1 ? discountPrice : sumPrice
          }}
        </div>
        <div class="right" @click.stop="toPath('chooseDiningMethod')">
          去结算
        </div>
      </div>
    </div>
    <div class="dialog" v-if="showDialog">
      <div class="dialog-top" @click="clearShopCar()">
        <img src="./img/clear-icon.png" alt="" />
        <div>清空购物车</div>
      </div>
      <div class="dialog-bottom">
        <div class="item" v-for="(item, index) in dialogList" :key="index">
          <div class="name">
            <span>{{ item.foodName }}</span>
            <span class="required" v-if="item.isRequired == 1">必选</span>
          </div>
          <div class="price">
            <span>¥</span
            >{{ isDiscounts == 1 ? item.discount : item.countPrice }}
          </div>
          <div
            :class="['delBtn', { isShowCancelBtn: item.isRequired === 1 }]"
            @click="delFood(item)"
          >
            删除
          </div>
        </div>
      </div>
    </div>
    <div class="blackbg" v-if="showDialog" @click="showDialog = false"></div>
  </div>
</template>

<script>
import { handleImg } from "@/utils/utils.js";
import { mapState } from "vuex";
import {
  getIsBindingPersonsUrl,
  getMyCartUrl,
  checkIsBindingUrl,
  deleteCartFoodUrl,
  getFoodListByTypeUrl,
  getFoodTypeUrl,
  addCartUrl,
  clearMyCartUrl,
  checkHaveDiscounts,
} from "./api";
export default {
  name: "jinzhongHealthyMind",
  data() {
    return {
      isShowPop: false,
      foodTime: 1,
      actions: [
        {
          value: 1,
          text: "明日午餐菜品",
        },
        {
          value: 2,
          text: "明日晚餐菜品",
        },
      ],
      isOld: localStorage.getItem("isOld") || 2,
      sumPrice: 0,
      discountPrice: 0,
      showDialog: false,
      tabAct: 0,
      dialogList: [],
      tabList: [],
      listData: [],
      scrollFlag: true,
      priceList: [],
      checkIsBindingFlag: null,
      isDiscounts: 0,
      mobile: "",
      notRequiredFoodList: [],
      requiredFoodList: [],
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.checkHaveDiscounts();
    const { mobile } = this.$route.query;
    if (![null, undefined, ""].includes(mobile)) {
      this.mobile = mobile;
      this.checkIsBinding();
    }
    this.getDialogList();
  },
  mounted() {
    this.getFootTypeList();
    window.addEventListener("scroll", this.handleScroll, true);
    this.tabList.forEach((t, i) => {
      t.top = this.$refs[this.listData[i].code][0].offsetTop;
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    //判断是否已选菜品
    checkSelect(item) {
      return this.dialogList.some((node) => node.foodId == item.id);
    },
    //判断当前用户此餐是否有优惠
    checkHaveDiscounts() {
      this.$axios
        .get(checkHaveDiscounts, {
          params: {
            userId: this.userId,
            foodTime: this.foodTime,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            console.log("data----->", data);
            this.isDiscounts = data;
          }
        });
    },
    OnSelect({ actions, index }) {
      this.foodTime = actions.value;
      this.listData = [];
      this.checkHaveDiscounts();
      this.getFootTypeList();
      this.getDialogList();

      console.log("actions----->", actions);
      console.log("index>", index);
    },
    switchOld() {
      if (this.isOld == 1) {
        this.isOld = 2;
      } else {
        this.isOld = 1;
      }
      localStorage.setItem("isOld", this.isOld);
    },
    delFood(item) {
      let params = {
        id: item.id,
      };
      this.$axios
        .post(`${deleteCartFoodUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.getDialogList();
          }
        });
    },
    // 选取至购物车
    addToCar(item) {
      if (this.checkIsBindingFlag == 1) {
        if (
          item.foodType == "荤菜" &&
          this.notRequiredFoodList.filter((node) => node.foodType == "荤菜")
            .length == 1
        ) {
          this.$toast("只能选取一个荤菜搭配！");
        } else if (
          item.foodType == "素菜" &&
          this.notRequiredFoodList.filter((node) => node.foodType == "素菜")
            .length == 2
        ) {
          this.$toast("只能选取两个素菜搭配！");
        } else {
          let params = {
            userId: this.userId,
            foodId: item.id,
            foodNum: 1,
            tenantId: this.tenantId,
          };
          this.$axios.post(`${addCartUrl}`, params).then((res) => {
            if (res.code == 200) {
              this.getDialogList();
            }
          });
        }
      } else {
        this.$toast("请进行老年人认证！");
      }
    },
    // 获取购物车列表
    getDialogList() {
      let params = {
        userId: this.userId,
        foodTime: this.foodTime,
        tenantId: this.tenantId,
      };
      this.$axios.get(`${getMyCartUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          // this.dialogList = res.data;
          this.requiredFoodList = res.data.filter(
            (item) => item.isRequired == 1
          );
          this.notRequiredFoodList = res.data.filter(
            (item) => item.isRequired == 0
          );
          this.dialogList = [
            ...this.notRequiredFoodList,
            ...this.requiredFoodList,
          ];
          this.sumPrice = this.dialogList.reduce(
            (accumulator, currentValue) => {
              return accumulator + Number(currentValue.countPrice);
            },
            0
          );
          this.discountPrice = this.dialogList.reduce(
            (accumulator, currentValue) => {
              return accumulator + Number(currentValue.discount);
            },
            0
          );
        }
      });
    },
    // 判断是否绑定过老人
    checkIsBinding() {
      let params = {
        mobiles: this.mobile,
      };
      this.$axios
        .get(`${checkIsBindingUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.checkIsBindingFlag = res.data;
          }
        });
    },
    // 获取菜品类型
    async getFootTypeList() {
      let params = {
        tenantId: this.tenantId,
      };
      this.$axios.get(`${getFoodTypeUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          this.tabList = res.data;
          const PromiseList = this.tabList.map((item) =>
            this.getFoodList(item)
          );
          Promise.all(PromiseList).then((res) => {
            this.listData = res;
          });
        }
      });
    },
    // 获取菜品列表数据
    getFoodList(type, index) {
      let params = {
        foodType: type,
        tenantId: this.tenantId,
        foodTime: this.foodTime,
      };
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`${getFoodListByTypeUrl}`, {
            params: params,
          })
          .then((res) => {
            res.data.forEach((item) => {
              item.num = 0;
              if (item.image) {
                item.image = handleImg(184, 184, item.image);
              }
              item.isSelect = true;
            });
            resolve({ title: type, list: res.data, code: type });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 清空购物车
    clearShopCar() {
      if (this.dialogList.length === 0) {
        return;
      }
      let params = {
        userId: this.userId,
      };
      this.$axios
        .post(`${clearMyCartUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.getDialogList();
          }
        });
    },
    // 加
    add(item, index) {
      if (item.num == undefined) {
        item.num = 0;
      }
      item.num = item.num + 1;
      if (!JSON.stringify(this.dialogList).includes(item.foodName)) {
        this.dialogList.push(item);
      }
      this.priceList = [];
      this.dialogList.forEach((t) => {
        this.priceList.push(t.num * Number(t.price));
      });
      this.sumPrice = this.sum(this.priceList);
    },
    // 减
    sub(item, index) {
      if (item.num == 1) {
        item.num = item.num - 1;
        this.dialogList.forEach((t, i) => {
          if (t.foodName == item.foodName) {
            this.dialogList.splice(i, 1);
          }
        });
      } else {
        item.num = item.num - 1;
      }
      this.priceList = [];
      this.dialogList.forEach((t) => {
        if (t.num >= 1) {
          this.priceList.push(t.num * Number(t.price));
        }
      });
      this.sumPrice = this.sum(this.priceList);
    },
    // 数组求和
    sum(arr) {
      return arr.reduce(function (total, value) {
        return total + value;
      }, 0);
    },
    // 监听滚动条
    handleScroll(e) {
      if (this.scrollFlag) {
        this.tabList.forEach((t, i) => {
          if (Number(e.target.scrollTop.toFixed()) > t.top) {
            this.tabAct = i;
          }
        });
      }
    },
    // 侧边栏分类点击切换
    tabCheck(item, index) {
      if (this.tabAct == index) {
        return;
      }
      this.scrollFlag = false;
      this.tabAct = index;
      this.$nextTick(() => {
        this.$refs[this.listData[index].code][0].scrollIntoView();
      });
      setTimeout(() => {
        this.scrollFlag = true;
      });
    },
    toPath(name, id) {
      if (name == "chooseDiningMethod") {
        console.log(this.dialogList.length);
        if (this.dialogList.length == 0) {
          this.$toast("请选取菜品！");
          return;
        } else if (this.checkIsBindingFlag === 1) {
          if (
            this.notRequiredFoodList.filter((node) => node.foodType == "素菜")
              .length == 2 &&
            this.notRequiredFoodList.filter((node) => node.foodType == "荤菜")
              .length == 1
          ) {
            this.$router.push({
              name: name,
              query: {
                foodTime: this.foodTime,
                isDiscounts: this.isDiscounts,
                mobile: this.mobile,
              },
            });
          } else {
            this.$toast("请选择一荤两素搭配！");
          }
        } else {
          this.$toast("请进行老年人认证！");
          return;
        }
      } else if (name == "foodDetail") {
        this.$router.push({
          name: name,
          query: {
            checkIsBindingFlag: this.checkIsBindingFlag,
            id: id,
          },
        });
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  padding: 30px 0 0 0;
  min-height: 100vh;
  .optiton-box {
    display: flex;
    justify-content: space-between;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 30px;
    box-sizing: border-box;
    margin-bottom: 42px;
    img {
      width: 50px;
      height: 50px;
      margin-right: 12px;
    }
    .optiton-box-left {
      display: flex;
      align-items: center;
    }
    .optiton-box-right {
      display: flex;
      align-items: center;
    }
  }
  .blackbg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
  }
  .dialog {
    z-index: 100;
    width: 100%;
    background: #ffffff;
    border-radius: 30px 30px 0px 0px;
    height: 632px;
    position: fixed;
    bottom: 232px;
    .dialog-bottom {
      width: 100%;
      height: calc(100% - 116px);
      overflow-y: scroll;
      .item {
        width: 100%;
        box-sizing: border-box;
        padding: 40px 28px 0 28px;
        display: flex;
        align-items: center;
        .delBtn {
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          margin-left: 20px;
          &.isShowCancelBtn {
            visibility: hidden;
          }
        }
        .name {
          font-size: 40px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          flex: 1;
          .required {
            margin-left: 8px;
            font-size: 28px;
            color: #e02020;
          }
        }
        .price {
          font-size: 40px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #e02020;
          span {
            font-size: 24px;
          }
        }
      }
    }
    .dialog-bottom::-webkit-scrollbar {
      display: none;
    }
    .dialog-top {
      border-bottom: 2px solid #f5f5f5;
      width: 100%;
      height: 116px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      padding: 0 30px;
      box-sizing: border-box;
      img {
        display: block;
        width: 24px;
        height: 28px;
        margin-right: 8px;
      }
    }
  }
  .bottom-box {
    z-index: 100;
    width: 100%;
    position: fixed;
    height: 232px;
    bottom: 0;
    background: #ffffff;
    box-sizing: border-box;
    padding: 40px 30px 96px;
    .box {
      width: 100%;
      height: 96px;
      display: flex;
      border-radius: 51px;
      background: #0e0d0c;
      align-items: center;
      padding-left: 26px;
      box-sizing: border-box;
      overflow: hidden;
      .center {
        flex: 1;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        height: 100%;
        line-height: 96px;
        padding-left: 32px;
        box-sizing: border-box;
        span {
          font-size: 28px;
        }
      }
      .right {
        width: 196px;
        height: 100%;
        background: linear-gradient(180deg, #fed143 0%, #ffca43 100%);
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        text-align: center;
        line-height: 96px;
      }
      .left {
        width: 106px;
        height: 100%;
        position: relative;
        .redBox {
          position: absolute;
          right: -4px;
          top: 8px;
          width: 40px;
          height: 40px;
          background: #fe3c00;
          border-radius: 50%;
          text-align: center;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
        img {
          display: block;
          margin-top: 6px;
          width: 100%;
          height: 90px;
        }
      }
    }
  }
  .main {
    width: 100%;
    position: fixed;
    bottom: 232px;
    left: 0;
    right: 0;
    top: 200px;
    display: flex;
    .main-right {
      width: calc(100% - 166px);
      height: 100%;
      overflow-y: scroll;
      padding: 0 30px 0 20px;
      box-sizing: border-box;
      .list {
        .title {
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 12px;
        }
        .item {
          width: 100%;
          // height: 184px;
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          .item-right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            padding-left: 22px;
            box-sizing: border-box;
            position: relative;
            padding-top: 18px;
            .btn {
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
            }
            .discounts {
              span {
                font-size: 28px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 40px;
                &:nth-child(2) {
                  font-size: 20px;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 600;
                  color: #e02020;
                  line-height: 28px;
                }
                &:nth-child(3) {
                  font-size: 32px;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 600;
                  color: #e02020;
                  line-height: 44px;
                }
              }
            }
            .optBox {
              // position: absolute;
              // bottom: 0;
              // right: 0;
              display: flex;
              align-items: center;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              .addBtn {
                width: 118px;
                height: 62px;
                background: linear-gradient(180deg, #fee163 0%, #fec44d 100%);
                border-radius: 8px;
                text-align: center;
                line-height: 62px;
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
              }
              .addBtn-gray {
                width: 118px;
                height: 62px;
                background: linear-gradient(180deg, #bbbbbb 0%, #bbbbbb 100%);
                border-radius: 8px;
                text-align: center;
                line-height: 62px;
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
              }
              .num {
                padding: 0 25px;
              }
              img {
                width: 40px;
              }
            }
            .price {
              // position: absolute;
              // bottom: 0;
              // left: 22px;
              font-size: 40px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #e02020;
              span {
                font-size: 24px;
              }
            }
            .name {
              margin-bottom: 10px;
              font-size: 40px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 40px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
          .item-left {
            width: 184px;
            height: 184px;
            border-radius: 12px;
            overflow: hidden;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .main-left {
      width: 166px;
      background: #f8f8fa;
      height: 100%;
      font-size: 32px;
      overflow-y: scroll;
      .left-item-act {
        font-weight: 600;
        background: #ffffff;
      }
      .left-item {
        width: 100%;
        height: 110px;
        color: #333333;
        text-align: center;
        line-height: 110px;
      }
    }
    .main-left::-webkit-scrollbar {
      display: none;
    }
    .main-right::-webkit-scrollbar {
      display: none;
    }
  }
  .main-title {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    padding: 0 30px 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    .label {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
    }
    img {
      width: 50px;
      margin-right: 18px;
    }
    .title,
    .van-popover__wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      .van-icon {
        transition: all 0.2s;
        transform: rotate(0);
        &.titleIcon {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
.isOld {
  box-sizing: border-box;
  padding: 30px 0 0 0;
  min-height: 100vh;
  .optiton-box {
    display: flex;
    justify-content: space-between;
    font-size: 46px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 30px;
    box-sizing: border-box;
    margin-bottom: 42px;
    img {
      width: 50px;
      height: 50px;
      margin-right: 12px;
    }
    .optiton-box-left {
      display: flex;
      align-items: center;
    }
    .optiton-box-right {
      display: flex;
      align-items: center;
    }
  }
  .blackbg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
  }
  .dialog {
    z-index: 100;
    width: 100%;
    background: #ffffff;
    border-radius: 30px 30px 0px 0px;
    height: 632px;
    position: fixed;
    bottom: 232px;
    .dialog-bottom {
      width: 100%;
      height: calc(100% - 116px);
      overflow-y: scroll;
      .item {
        width: 100%;
        box-sizing: border-box;
        padding: 40px 28px 0 28px;
        display: flex;
        align-items: center;
        .delBtn {
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          margin-left: 20px;
        }
        .name {
          font-size: 40px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          flex: 1;
          .required {
            font-size: 28px;
            color: #e02020;
          }
        }
        .price {
          font-size: 40px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #e02020;
          span {
            font-size: 24px;
          }
        }
      }
    }
    .dialog-bottom::-webkit-scrollbar {
      display: none;
    }
    .dialog-top {
      border-bottom: 2px solid #f5f5f5;
      width: 100%;
      height: 116px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      padding: 0 30px;
      box-sizing: border-box;
      img {
        display: block;
        width: 24px;
        height: 28px;
        margin-right: 8px;
      }
    }
  }
  .bottom-box {
    z-index: 100;
    width: 100%;
    position: fixed;
    height: 232px;
    bottom: 0;
    background: #ffffff;
    box-sizing: border-box;
    padding: 40px 30px 96px;
    .box {
      width: 100%;
      height: 106px;
      display: flex;
      border-radius: 51px;
      background: #0e0d0c;
      align-items: center;
      padding-left: 26px;
      box-sizing: border-box;
      overflow: hidden;
      .center {
        flex: 1;
        font-size: 50px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        height: 100%;
        line-height: 106px;
        padding-left: 32px;
        box-sizing: border-box;
        span {
          font-size: 38px;
        }
      }
      .right {
        width: 196px;
        height: 100%;
        background: linear-gradient(180deg, #fed143 0%, #ffca43 100%);
        font-size: 42px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        text-align: center;
        line-height: 106px;
      }
      .left {
        width: 106px;
        height: 100%;
        position: relative;
        .redBox {
          position: absolute;
          right: -4px;
          top: 8px;
          width: 40px;
          height: 40px;
          background: #fe3c00;
          border-radius: 50%;
          text-align: center;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
        img {
          display: block;
          margin-top: 6px;
          width: 100%;
          height: 90px;
        }
      }
    }
  }
  .main {
    width: 100%;
    position: fixed;
    bottom: 232px;
    left: 0;
    right: 0;
    top: 240px;
    display: flex;
    .main-right {
      width: calc(100% - 166px);
      height: 100%;
      overflow-y: scroll;
      padding: 0 30px 0 20px;
      box-sizing: border-box;
      .list {
        .title {
          font-size: 42px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-bottom: 22px;
        }
        .item {
          width: 100%;
          // height: 210px;
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          .item-right {
            flex: 1;
            padding-left: 22px;
            box-sizing: border-box;
            position: relative;
            padding-top: 8px;
            .btn {
              display: flex;
              justify-content: space-between;
            }
            .discounts {
              margin: 18px 0;
              span {
                font-size: 36px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 40px;
                &:nth-child(2) {
                  font-size: 28px;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 600;
                  color: #e02020;
                  line-height: 28px;
                }
                &:nth-child(3) {
                  font-size: 40px;
                  font-family: PingFangSC, PingFang SC;
                  font-weight: 600;
                  color: #e02020;
                  line-height: 44px;
                }
              }
            }
            .optBox {
              // position: absolute;
              // bottom: 0;
              // right: 0;
              display: flex;
              align-items: center;
              font-size: 28px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              .addBtn {
                width: 118px;
                height: 62px;
                background: linear-gradient(180deg, #fee163 0%, #fec44d 100%);
                border-radius: 8px;
                text-align: center;
                line-height: 62px;
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
              }
              .num {
                padding: 0 25px;
              }
              img {
                width: 40px;
              }
            }
            .price {
              // position: absolute;
              // bottom: 0;
              // left: 22px;
              font-size: 48px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #e02020;
              span {
                font-size: 34px;
              }
            }
            .name {
              font-size: 50px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 50px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
          .item-left {
            width: 210px;
            height: 210px;
            border-radius: 12px;
            overflow: hidden;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .main-left {
      width: 166px;
      background: #f8f8fa;
      height: 100%;
      font-size: 42px;
      overflow-y: scroll;
      .left-item-act {
        font-weight: 600;
        background: #ffffff;
      }
      .left-item {
        width: 100%;
        height: 110px;
        color: #333333;
        text-align: center;
        line-height: 110px;
      }
    }
    .main-left::-webkit-scrollbar {
      display: none;
    }
    .main-right::-webkit-scrollbar {
      display: none;
    }
  }
  .main-title {
    font-size: 46px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    padding: 0 30px 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .label {
      font-size: 42px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
    }
    img {
      width: 50px;
      margin-right: 18px;
    }
    .title {
      flex: 1;
    }
  }
}
</style>

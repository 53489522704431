<template>
  <div>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedValue"
      @load="onLoad"
      :immediate-check="immediateCheck"
      :offset="offset"
    >
      <slot></slot>
    </van-list>
    <!-- 空数据组件 -->
    <v-empty v-if="isNoData" :description="description"></v-empty>
    <!-- 列表公共操作型跳转组件 -->
    <common-opera
      v-if="commonOperaParams.isCommonOpera"
      :commonOperaParams="commonOperaParams"
      @beforeMy="beforeMy"
    ></common-opera>
  </div>
</template>

<script>
import Vue from "vue";
import commonOpera from "@/components/bussiness/commonOpera.vue";
import { List } from "vant";
Vue.use(List);
export default {
  components: {
    commonOpera,
  },
  watch: {
    finishedText(newValue, oldName) {
      if (newValue == "") {
        this.finishedValue = "";
        this.isNoData = true;
      } else {
        this.isNoData = false;
        this.finishedValue = newValue;
      }
    },
  },
  props: {
    finished: {
      type: Boolean,
      default: false,
    },
    /** start 列表通用按钮 commonOpera */
    createText: {
      type: String,
      default: "",
    },
    listText: {
      type: String,
      default: "",
    },
    imgUrl: {
      type: String,
      default: "",
    },
    commonOperaParams: {
      type: Object,
      default: function () {
        return {};
      },
    },
    beforeMyFunction: Function, //点击我的按钮之前的回调函数
    isCommonOpera: {
      type: Boolean,
      default: false,
    },
    /** end */
    immediateCheck: {
      type: Boolean,
      default: true,
    },
    finishedText: {
      type: String,
      default: "没有更多了",
    },
    offset: {
      type: Number,
      default: 50,
    },
    description: {
      type: String,
      default: "暂无数据",
    },
  },
  data() {
    return {
      finishedValue: this.finishedText,
      isNoData: false,
      loading: false,
    };
  },
  methods: {
    beforeMy() {
      this.beforeMyFunction ? this.beforeMyFunction() : "";
    },
    onLoad() {
      this.$emit("onLoad");
    },
  },
};
</script>

<style></style>

<template>
  <div class="page">
    <div class="box">
      <p>
        “杨海定基层工作经验十条”以“经验+海定说+案例”的形式，生动有力，娓娓道来，是杨海定同志以只争朝夕、忘我工作的精神用生命书写而成。<br />
        “杨海定基层工作经验十条”<br />
        经验一：树起一个先进的标杆。<br />
        海定说：群众看党员、党员看干部、干部看书记。这个书记要么不当，要当就当顶好。<br />
        经验二：建成一座坚强的堡垒。<br />
        海定说：上梁不正，下梁错拼；班子不正，做事不成。班子硬气，党员正气，群众服气。<br />
        经验三：打造一支能干的队伍。<br />
        海定说：让群众当家，发动群众做群众工作。党员有党性，群众有良心，村干部做事有信心。
        经验四：制定一套管用的制度。<br />
        海定说：村干部要以制度服人，按制度做事。<br />
        经验五：严格一套议事的程序。<br />
        海定说：村干部做事不能随心所欲，要做到一事一议一签，公平公正公开。<br />
        经验六：找准一条发展的道路。<br />
        海定说：谋发展不能停下脚步，原地踏步也是退步。吃不穷，用不穷，打算不好一世穷。<br />
        经验七：建好一批增收的项目。<br />
        海定说：村里有钱好办事，自身“造血”更重要。一定要强村富民，一定要让百姓过上好日子。<br />
        经验八：心有一份爱民的情怀。<br />
        海定说：当干部，就要一心想着群众。老百姓有怨气，当干部要忍得住气。<br />
        经验九：坚守一份执着的信念。<br />
        海定说：要敢于磨破脚皮、说破嘴皮、硬着头皮地做工作。对发展有利的事，要义无反顾地去做。<br />
        经验十：恪守一条严明的规矩。<br />
        海定说：当干部不是来发财的，要发财不要来当干部。宁可吃点亏，也不能让老百姓戳脊梁骨。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "introduceTab",
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-image: url("./img/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 150px 104px 250px;
  box-sizing: border-box;
  .box::-webkit-scrollbar {
    display: none;
  }
  .box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    p {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fff2f2;
      line-height: 42px;
    }
  }
}
</style>

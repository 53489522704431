<template>
  <div class="page">
    <!-- 在线学习列表 - 从 futurServe/partyBuilding 复制-->
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div class="online_learning_list">
        <div
          class="item"
          @click="toDetail(item, index)"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="img_box">
            <img class="bg" :src="item.topicPicture" alt="" />
            <div class="box">
              <div class="quantity">{{ item.readCount }}次播放</div>
              <div class="duration">
                <div class="imgs">
                  <img class="duration_icon" src="../img/good.png" alt="" />
                </div>
                <div class="infos">{{ item.praiseCount }}</div>
              </div>
            </div>
          </div>
          <div class="txt_box">{{ item.topicTitle }}</div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { getTopicListURL } from "../api.js";
export default {
  name: "onlineLearn",
  data() {
    return {
      isLoading: false,
      fromFlag: "",
      listData: [],
      total: 0,
      finishedText: "没有更多了",
      finished: false,
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    // 用于判断H5用在微信、钉钉或者其他
    this.fromFlag = this.$route.query.fromFlag;
  },
  methods: {
    onLoad() {
      this.getList();
    },
    // 获取课程列表
    getList() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            // name: "", // 功能未知
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 18, // 18 党建学习
            curPage: this.curPage,
            pageSize: this.pageSize,
            // sortField: "NEW", // 排序方式， 最热 or 最新
            // createUserMobile: this.detail.mobile,
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finishedText = "";
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              // this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                res.data.records.forEach((item) => {
                  if (item.topicPicture) {
                    item.topicPicture = this.$handleImg(
                      333,
                      214,
                      item.topicPicture
                    );
                  }
                });
                this.listData = res.data.records;
                console.log(this.listData);
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  if (item.topicPicture) {
                    item.topicPicture = this.$handleImg(
                      333,
                      214,
                      item.topicPicture
                    );
                  }
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finishedText = "";
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    toDetail(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/xubPages/onLineStudyDetail/index?params=${JSON.stringify(
            params
          )}`,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background-color: #fff;

  .online_learning_list {
    // height: 100vh;
    // overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    padding: 28px 32px 0;
    justify-content: space-between;

    .item {
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      box-sizing: border-box;
      margin-top: 16px;
      border-radius: 12px;
      overflow: hidden;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        padding-top: 0;
        margin-top: 0;
      }

      .txt_box {
        box-sizing: border-box;
        width: 333px;
        height: 102px;
        line-height: 45px;
        background-color: #fff;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        padding: 12px;
        font-size: 28px;
        color: #323334;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis; //显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 2; //块元素显示的文本行数
        -webkit-box-orient: vertical;
      }

      .img_box {
        width: 333px;
        height: 214px;
        position: relative;

        .box {
          width: 100%;
          height: 50px;
          line-height: 50px;
          position: absolute;
          bottom: 0;
          left: 0;

          .duration {
            font-size: 22px;
            color: #fff;
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);

            .imgs {
              display: inline-block;
              vertical-align: middle;
              margin-right: 6px;

              .duration_icon {
                width: 24px;
                height: 24px;
              }
            }

            .infos {
              display: inline-block;
            }
          }

          .quantity {
            font-size: 20px;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 14px;
            transform: translateY(-50%);
          }
        }

        .bg {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>

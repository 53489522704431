// 点击红色楼管家，先根据手机号查询是否金钟管家用户身份
const getButlerInfoByMobileURL = `/gateway/hc-neighbour/butler/getButlerInfoByMobile`;
// 通过houseId获取手机号
const getButlerInfoByHouseIdURL = `/gateway/hc-neighbour/butler/getButlerInfoByHouseId`;
// 红色引领
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
// 红网展示
const getButlerListURL = `/gateway/hc-neighbour/butler/getTenantButlerList`;
// 新增接口
const addTopicURL = `/gateway/hc-neighbour/topic/addTopic`;
// 删除接口
const deleteTopicURL = `/gateway/hc-neighbour/topic/deleteTopic`;

// 联建单位
const getListURL = `/gateway/hc-neighbour/batUnit/getList`;
// 联建单位详情
const getDetailsURL = `/gateway/hc-neighbour/batUnit/getDetails`;

// 查询活动列表
const getActivityCategoryURL = `/gateway/hc-serve/miniapi/activity/activityList`;
// 签到签名接口
const applyForCommitmentLetterUrl = `/gateway/hc-serve/miniapi/volunteer/applyForCommitmentLetter`;
// 金邻在线列表
const getAssignmentListURL = `/gateway/hc-serve/mini/assignment/getList`;
// 拍照上传
const submitPicture = `/gateway/hc-serve/mini/assignment/submitPicture`;

// 获取用户个人信息
const userInfoURL = `/gateway/hc-mini/user/mini/user-info`;

/* 
活动相关
*/
//获取活动详情
const getActivityDetailUrl = `/gateway/hc-serve/miniapi/activity/activityDetail`;
//活动报名
const applyActivityUrl = `/gateway/hc-serve/miniapi/activity/activityApply`;
// 参与人备注详情接口
const applicantsNoteDetailsUrl = `/gateway/hc-serve/miniapi/activity/applicantsNoteDetails`;
// 参与人备注编辑接口
const compileApplicantsNoteUrl = `/gateway/hc-serve/miniapi/activity/compileApplicantsNote`;
//我的活动参与人列表
const addPeopleListUrl = `/gateway/hc-serve/miniapi/activity/myActivityApplicantsList`;

export {
  getButlerInfoByMobileURL,
  getButlerInfoByHouseIdURL,
  getTopicListURL,
  getButlerListURL,
  addTopicURL,
  deleteTopicURL,
  getListURL,
  getDetailsURL,
  getActivityCategoryURL,
  getAssignmentListURL,
  applyForCommitmentLetterUrl,
  submitPicture,
  getActivityDetailUrl,
  applyActivityUrl,
  applicantsNoteDetailsUrl,
  compileApplicantsNoteUrl,
  addPeopleListUrl,
  userInfoURL,
};

toRouteDetail
<template>
  <div class="page">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="list-content"
          v-for="(item, index) in listData"
          :key="index"
          @click="toRouteTitle(item)"
        >
          <div class="image">
            <img :src="item.topicPicture" alt="" />
          </div>
          <div class="wrapper">
            <div class="wrapper-title">
              <span>{{ item.topicTitle }}</span>
            </div>
            <div class="wrapper-date">
              <span>{{ item.createTime }}</span>
            </div>
            <div class="wrapper-content">
              <div class="content-tag">
                <div class="iconText">
                  <div class="icon">
                    <img src="./img/icon-hot.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.readCount }}</span>
                  </div>
                </div>
                <div class="iconText">
                  <div class="icon">
                    <img src="./img/icon-msg.png" />
                  </div>
                  <div class="text">
                    <span>{{ item.commentCount }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-list>
    </v-refresh>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { getTopicListURL } from "./api.js";
import { toRegister } from "@/utils/common.js";

export default {
  name: "redLead",
  data() {
    return {
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    onRefresh() {
      this.curPage = 1;
      // this.listData = [];
      this.onLoad();
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 31, // 31 红色引领
            curPage: this.curPage,
            pageSize: this.pageSize,
            sortField: "NEW", // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              this.listData = [];
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                res.data.records.forEach((item) => {
                  if (item.topicPicture) {
                    item.topicPicture = this.$handleImg(
                      256,
                      256,
                      item.topicPicture
                    );
                  }
                });
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  if (item.topicPicture) {
                    item.topicPicture = this.$handleImg(
                      256,
                      256,
                      item.topicPicture
                    );
                  }
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    // 点击跳转话题详情页
    toRouteTitle(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: rgba(249, 249, 249, 1);
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  ::v-deep .van-pull-refresh {
    min-height: 100vh;
  }

  .list-content {
    display: flex;
    margin: 30px 30px 0;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(227, 277, 277, 0.5);
    .wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 40px 0 24px 0;
      .wrapper-title {
        flex: 1;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 32px;
        color: rgba(0, 0, 0, 0.85);
      }
      .wrapper-date {
        margin-top: 10px;
        display: flex;
        align-items: center;
        font-size: 24px;
        color: rgba(180, 180, 180, 1);
      }
      .wrapper-content {
        margin-top: 10px;
        font-size: 24px;
        color: rgba(180, 180, 180, 1);
        display: flex;
        .content-tag {
          display: flex;
          .iconText {
            display: flex;
            color: #a8a8a8;
            align-items: center;

            &:not(:last-child) {
              margin-right: 32px;
            }
            .icon {
              display: flex;
              align-items: center;
              &.icon-up {
                > img {
                  margin-top: 4px;
                }
              }
              > img {
                width: 36px;
                height: 36px;
              }
            }

            .text {
              margin-left: 8px;
              display: flex;
              align-items: center;
              height: 36px;
              line-height: 36px;

              span {
                &.hasPraised {
                  color: #ff853a;
                }

                font-size: 24px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
    .image {
      box-sizing: border-box;
      // border: 1px solid #000;
      border-radius: 16px 0 0 16px;
      margin-right: 44px;
      width: 256px;
      min-height: 256px;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>

import { mapHelper } from "@/utils/utils.js";

// 	主体类型
const principalType = [
  {
    value: 1,
    text: "个体",
  },
  {
    value: 2,
    text: "企业",
  },
];

const { map: principalTypeMap, setOps: setPrincipalTypeOps } =
  mapHelper.setMap(principalType);

// 	经营类型
const accountType = [
  {
    value: 1,
    text: "对公银行账户",
  },
  {
    value: 2,
    text: "经营者个人银行卡",
  },
];

const { map: accountTypeMap, setOps: setAccountTypeOps } =
  mapHelper.setMap(accountType);

export {
  principalTypeMap,
  setPrincipalTypeOps,
  accountTypeMap,
  setAccountTypeOps,
};

<template>
  <div class="verifyWish">
    <div class="listbody">
      <v-list
        :finished="finished"
        :finishedText="finishedText"
        @onLoad="onLoad"
        ref="list"
      >
        <div
          class="order"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="order-t">
            <div class="order-tl">
              <div class="text">姓名：</div>
              <div class="txt">{{ item.userName }}</div>
            </div>
            <div :class="['order-tr', { active: item.auditStatus == 3 }]">
              {{ setAuditStatusMap[item.auditStatus] }}
            </div>
          </div>
          <div class="order-b">
            <div class="order-bl" v-if="item.healthPic">
              <div
                class="item"
                v-for="(items, indexs) in item.healthPic"
                :key="indexs"
              >
                <img :src="items" alt="" />
              </div>
            </div>
            <!-- <div class="handle" @click.stop="toHandle(item)">审核</div> -->
          </div>
          <div class="people">
            <div :class="['tel', { active: item.status == 3 }]">
              <div class="text">房号：</div>
              <div class="txt">{{ item.roomName }}</div>
            </div>
            <div class="time">
              <div class="text">打卡时间：</div>
              <div class="txt">{{ item.insertTime }}</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <!-- <v-dialog v-model="isDialog" @confirm="confirm">
      <div class="oDetail">
        <div class="item">
          <div class="item-l">用户名：</div>
          <div class="item-r">{{ oDetail.userName }}</div>
        </div>
        <div class="item">
          <div class="item-l">审核备注：</div>
          <div class="item-r">
            <v-input
              type="textarea"
              v-model="form.auditRemarks"
              placeholder="请输入活动地点"
            ></v-input>
          </div>
        </div>
        <div class="item">
          <div class="item-l">审核结果：</div>
          <div class="item-r">
            <v-dropDownMenu
              v-model="form.status"
              :option1="auditStatusMap"
              @change="change"
            ></v-dropDownMenu>
          </div>
        </div>
        <div class="item te">
          <div class="item-l">打卡详情：</div>
          <div class="item-r">
            <div
              class="imgList"
              v-for="(items, indexs) in oDetail.healthPic"
              :key="indexs"
            >
              <img :src="items" alt="" />
            </div>
          </div>
        </div>
      </div>
    </v-dialog> -->
  </div>
</template>

<script>
import { getSportListUrl, handleOrderUrl } from "./api.js";
import {
  auditStatusMap,
  setAuditStatusMap,
  setAuditStatusMapOps,
} from "./map.js";
import { getOption } from "@/utils/utils.js";
import { handleImg } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "verifyWish",
  data() {
    return {
      setAuditStatusMap,
      auditStatusMap,
      isDialog: false,
      value: "",
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        status: "1",
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
      id: "",
      oDetail: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    console.log(setAuditStatusMap, setAuditStatusMapOps);
  },
  methods: {
    returnStatus(status) {
      if (status || status == 0) {
        return getOption(status, auditStatusMap, "value")["text"];
      } else {
        return "待核销";
      }
    },
    confirm() {
      this.isDialog = false;
      this.handle();
    },
    handle() {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      this.$axios
        .post(`${handleOrderUrl}`, this.$qs.stringify(params))
        .then((res) => {
          console.log(res, 5555);
          if (res.code === 200) {
            this.$toast({ message: "核销成功", duration: 2000 });
          } else {
          }
        });
    },
    toDetail(item) {
      this.$router.push({
        name: "verifySportDetail",
        query: { id: item.id },
      });
    },
    onLoad() {
      this.getActivityList();
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        communityId: null,
        auditStatus: 0,
      };
      this.$axios.get(`${getSportListUrl}`, { params: params }).then((res) => {
        console.log(res, 333);
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }

          res.data.records.forEach((item) => {
            if (item.healthPic) {
              item.healthPic = item.healthPic.split(",");
              item.healthPic.forEach((items, index) => {
                // console.log(items, 88988);
                items = handleImg(128, 128, items);
              });
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.verifyWish {
  background: #f5f5f5;
  min-height: 100vh;
  .listbody {
    padding: 32px 32px;
  }
  .order {
    height: 396px;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 32px;
    .order-t {
      font-size: 30px;
      padding: 26px 32px;
      display: flex;
      justify-content: space-between;
      box-shadow: 0px -1px 0px 0px rgba(245, 245, 245, 1);
      border-bottom: 1px solid rgba(245, 245, 245, 1);
      .order-tl {
        display: flex;
        font-size: 32px;
        font-weight: 700;
        color: #323334;
        line-height: 44px;
        .text {
          margin-right: 32px;
        }
      }
      .order-tr {
        color: #fe6f16;
      }
      .active {
        color: #007eff;
      }
    }
    .order-b {
      display: flex;
      position: relative;
      align-items: center;
      padding: 12px 32px;
      .order-bl {
        display: flex;
        justify-content: space-between;
        .item {
          width: 128px;
          height: 128px;
          margin-right: 16px;
          background: #f6f6f6;
          border-radius: 10px;
          text-align: center;
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
      }
      .order-br {
        .title {
          margin: 6px 0 20px 0;
          font-weight: 600;
          font-size: 32px;
          color: #323334;
          margin-bottom: 22px;
        }
        .price {
          color: rgba(0, 126, 255, 1);
          font-size: 24px;
          span {
            font-size: 40px;
            font-weight: 600;
            color: #fe6f16;
            line-height: 56px;
            margin-right: 8px;
          }
        }
      }
      .handle {
        width: 160px;
        position: absolute;
        right: 32px;
        bottom: 20px;
        background: rgba(0, 126, 255, 1);
        color: #fff;
        text-align: center;
        font-size: 24px;
        height: 60px;
        border-radius: 40px;
        line-height: 60px;
      }
    }
    .people {
      padding: 20px 32px 0px;
      .tel,
      .time {
        display: flex;
        font-size: 28px;
        font-weight: 400;
        color: #646668;
        line-height: 40px;
      }
      .tel {
        margin-bottom: 16px;
      }
      .active {
        margin: 0;
      }
    }
    .people.active {
      padding: 10px 32px 0px;
    }
  }
  .oDetail {
    height: 60vh;
    overflow: auto;
    padding: 32px;
    box-sizing: border-box;
    .item {
      display: flex;
      margin-bottom: 10px;
      .item-l {
        width: 160px;
        font-size: 32px;
      }
      .item-r {
        font-size: 30px;
        flex: 1;
      }
      .imgList {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .te {
      display: block;
    }
  }
}
</style>
<style lang="less">
.verifySport {
  .oDetail {
    .van-field {
      border: 2px solid #f5f5f5;
    }
  }
}
</style>

<template>
  <div :class="['page', { isOld: isOld == 1 ? true : false }]">
    <div class="main">
      <div class="box">
        <div class="title">身份认证</div>
        <div class="item">
          <div class="label">姓名:</div>
          <div class="input">
            <input type="text" v-model="personName" placeholder="请输入" />
          </div>
        </div>
        <div class="item">
          <div class="label">身份证号:</div>
          <div class="input">
            <input type="text" v-model="idCard" placeholder="请输入" />
          </div>
        </div>
        <div class="item">
          <div class="label">联系方式:</div>
          <div class="input">
            <input type="text" v-model="mobiles" placeholder="请输入" />
          </div>
        </div>
        <div class="item" style="border: none">
          <div class="label">亲属联系方式:</div>
          <div class="input">
            <input type="text" v-model="kinsfolkMobiles" placeholder="请输入" />
          </div>
        </div>
      </div>
    </div>
    <div class="btn" @click="submit">提交</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { addOrUpdateInfo } from "./api.js";
import { toRegister } from "@/utils/common.js";

export default {
  name: "identityAuth",
  data() {
    return {
      personName: null,
      idCard: null,
      mobiles: null,
      kinsfolkMobiles: null,
      isOld: localStorage.getItem("isOld"),
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async mounted() {
    await toRegister(this.$route.path, this.$route.query, "金钟健康心");
  },
  methods: {
    submit() {
      if (!this.personName) {
        this.$toast("请输入姓名！");
        return;
      }
      if (!this.idCard) {
        this.$toast("请输入身份证号！");
        return;
      }
      if (!this.mobiles) {
        this.$toast("请输入联系方式！");
        return;
      }
      // if (this.mobiles.length != 11) {
      //   this.$toast("请输入正确的联系方式！");
      //   return;
      // }
      if (!this.kinsfolkMobiles) {
        this.$toast("请输入亲属联系方式！");
        return;
      }
      if (this.kinsfolkMobiles.length != 11) {
        this.$toast("请输入正确的亲属联系方式！");
        return;
      }
      let params = {
        auditStatus: 0,
        userId: this.userId,
        tenantId: this.tenantId,
        personName: this.personName,
        idCard: this.idCard,
        mobiles: this.mobiles,
        kinsfolkMobiles: this.kinsfolkMobiles,
      };
      this.$axios.post(`${addOrUpdateInfo}`, params).then((res) => {
        if (res.code == 200) {
          this.$toast(res.msg);
          this.timer = setTimeout(() => {
            this.$router.back();
          }, 200);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  background-color: #f9f9f8;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
  }
  .main {
    width: 100%;
    height: 982px;
    background-image: url("./img/yellow-bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: border-box;
    padding: 26px 30px 0;
    .box {
      width: 100%;
      height: 664px;
      background: #ffffff;
      border-radius: 16px;
      padding: 60px 34px 0;
      box-sizing: border-box;
      .item {
        border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        width: 100%;
        height: 118px;
        display: flex;
        align-items: center;
        .input {
          flex: 1;
        }
        input {
          width: 100%;
          height: 100%;
          text-align: right;
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .label {
          width: 226px;
          font-size: 36px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          white-space: nowrap;
        }
      }
      .title {
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        text-align: center;
      }
    }
  }
}
.isOld {
  .btn {
    width: 550px;
    height: 86px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    line-height: 86px;
    text-align: center;
    font-size: 46px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 110px;
    left: 50%;
    transform: translateX(-50%);
  }
  .main {
    width: 100%;
    height: 982px;
    background-image: url("./img/yellow-bg.png");
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: border-box;
    padding: 26px 30px 0;
    .box {
      // width: 100%;
      height: 664px;
      background: #ffffff;
      border-radius: 16px;
      padding: 60px 34px 0;
      box-sizing: border-box;

      .item {
        border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        height: 118px;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        input {
          flex: 1;
          height: 100%;
          text-align: right;
          font-size: 42px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }
        .label {
          width: 229px;
          flex-shrink: 0;
          font-size: 46px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          white-space: nowrap;
        }
      }
      .title {
        font-size: 46px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        text-align: center;
      }
    }
  }
}
</style>

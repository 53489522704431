<template>
  <div class="page">
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toPath(item)"
        >
          <div
            class="tag"
            style="
              background: linear-gradient(180deg, #9ee257 0%, #6dd400 100%);
            "
            v-if="item.status == 7 || item.status == 9 || item.status == 10"
          >
            被采纳
          </div>
          <div
            class="tag"
            style="
              background: linear-gradient(180deg, #fa5c5c 0%, #e02020 100%);
            "
            v-if="item.status == 8"
          >
            未被采纳
          </div>
          <div
            class="tag"
            style="
              background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
            "
            v-if="
              item.status == 0 ||
              item.status == 1 ||
              item.status == 2 ||
              item.status == 3 ||
              item.status == 4 ||
              item.status == 5 ||
              item.status == 6
            "
          >
            进行中
          </div>
          <div class="top">
            <div class="left"><img src="./img/user.png" alt="" /></div>
            <div class="right">
              <div class="name">{{ item.proposeUserName }}</div>
              <div class="time">发起时间：{{ item.createTime }}</div>
            </div>
          </div>
          <div class="bottom">
            <div class="title">{{ item.proposeTitle }}</div>
            <div class="content">
              <div class="label">提议内容：</div>
              {{ item.proposeContent }}
            </div>
            <div class="content">
              <div class="label">附议：</div>
              <div class="imgList">
                <img
                  v-for="(i, x) in item.proposeAnnexFiles"
                  :key="x"
                  :src="i"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            class="del"
            v-if="item.status != 7 && item.status != 9 && item.status != 10"
          >
            <div class="delBtn" @click.stop="deleteItem(item)">删除</div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getMyProposeEventListUrl, deleteMyProposeEventUrl } from "./api.js";
import { mapState } from "vuex";
export default {
  name: "myProposal",
  data() {
    return {
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getMyProposeEventListUrl, {
          params: {
            userId: this.userId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.listData.forEach((ele) => {
              if (ele.proposeAnnexFiles) {
                // ele.topicPicture = this.$handleImg(226, 150, ele.topicPicture);
                ele.proposeAnnexFiles = ele.proposeAnnexFiles
                  .split(",")
                  .slice(0, 3);
              }
            });
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    deleteItem(item) {
      this.$axios
        .post(`${deleteMyProposeEventUrl}?id=${item.id}`)
        .then((res) => {
          if (res.code == 200) {
            this.$toast(res.msg);
            this.listData = [];
            this.tabType = item.type;
            this.finished = false;
            this.curPage = 1;
            this.pageSize = 10;
            this.onLoad();
          }
        });
    },
    toPath(item) {
      if (item.status != 7 && item.status != 9 && item.status != 10) {
        this.$router.push({
          name: "initiateProposal",
          query: {
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          name: "proceedingsDetail",
          query: {
            id: item.id,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #fafafa;
  padding: 30px;
  .list {
    .item {
      width: 100%;
      min-height: 352px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(220, 220, 220, 0.5);
      border-radius: 16px;
      margin-bottom: 20px;
      padding: 38px 27px 20px;
      box-sizing: border-box;
      position: relative;
      .del {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .delBtn {
          width: 120px;
          height: 50px;
          text-align: center;
          line-height: 50px;
          background-color: #fa5c5c;
          font-size: 24px;
          border-radius: 50px;
          color: #fff;
        }
      }

      .tag {
        position: absolute;
        top: 0;
        right: 0;
        width: 140px;
        height: 50px;
        border-radius: 0px 16px 0px 16px;
        text-align: center;
        line-height: 50px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .bottom {
        padding-top: 28px;
        .content {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 10px;
          display: flex;
          word-break: break-all;
          .imgList {
            display: flex;
            img {
              width: 160px;
              height: 160px;
              margin-right: 20px;
            }
          }
          .label {
            color: rgba(0, 0, 0, 0.5);
            white-space: nowrap;
          }
        }
        .title {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          margin-bottom: 10px;
        }
      }
      .top {
        display: flex;
        padding-bottom: 34px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        .right {
          padding-left: 22px;
          .name {
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
          }
          .time {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
          }
        }
        .left {
          img {
            width: 60px;
          }
        }
      }
    }
  }
}
</style>

// 校验团长状态
const getCheckIsLeaderURL = `/gateway/hc-serve/mini/communityBuy/checkIsLeader`;
// 申请成为团长
const applyBeGroupLeaderURL = `/gateway/hc-serve/mini/communityBuy/applyBeGroupLeader`;
// 我的(团长)团购/秒杀列表
const myGroupBuyingListURL = `/gateway/hc-serve/mini/communityBuy/myGroupBuyingList`;
// 个人信息
const userInfoURL = `/gateway/hc-mini/user/mini/user-info`;
// 发布团购/秒杀
const issueGroupBuyingURL = `/gateway/hc-serve/mini/communityBuy/issueGroupBuying`;
//团购/秒杀列表
const issueGroupBuyingForMiniURL = `/gateway/hc-serve/mini/communityBuy/issueGroupBuyingForMini`;
// 下单
const addOrderURL = `/gateway/hc-serve/mini/communityBuy/addOrder`;
// 加入购物车
const addTrolleyURL = `/gateway/hc-serve/mini/communityBuy/addTrolley`;
// 团购/秒杀详情
const getGroupBuyingDetailsForMiniURL = `/gateway/hc-serve/mini/communityBuy/getGroupBuyingDetailsForMini`;
// 团购/秒杀删除
const delGroupInfoURL = `/gateway/hc-serve/manage/communityBuy/delGroupInfo`;

// // 获取首页商品icon图
// const getMallTypeIconListURL = ` /gateway/hc-serve/miniapi/quarterMall/getMallTypeIconList`;
// 根据商品ID获取详细信息
// const getGoodsDetailURL =
//   "/gateway/hc-serve/miniapi/quarterMall/getGoodsDetail";
// // 根据类别和标题获取商品列表
// const getGoodListByTypeAndTitleURL = `/gateway/hc-serve/miniapi/quarterMall/getGoodListByTypeAndTitle`;
// 获取可用的sub_mch_id
const getSubMchIdsURL = "/gateway/hc-serve/mini/communityBuy/getSubMchIds";

export {
  userInfoURL,
  getCheckIsLeaderURL,
  applyBeGroupLeaderURL,
  myGroupBuyingListURL,
  issueGroupBuyingURL,
  issueGroupBuyingForMiniURL,
  addOrderURL,
  addTrolleyURL,
  getGroupBuyingDetailsForMiniURL,
  delGroupInfoURL,
  getSubMchIdsURL,
};

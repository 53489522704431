<template>
  <div class="page">
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="item"
          v-for="(t, i) in listData"
          :key="i"
          @click="toPath('leaveMsgForSaleMen', t.leaveWordId)"
        >
          <div class="left">
            <img
              :src="t.avatar || require('@/assets/img/default_avatar.png')"
              alt=""
            />
          </div>
          <div class="center">
            <div class="name">{{ t.nickName }}</div>
            <div class="txt" v-if="t.leaveWordContent">
              {{ JSON.parse(t.leaveWordContent).text }}
            </div>
            <div class="time">{{ t.createTime }}</div>
          </div>
          <div class="right">
            <img :src="t.goodsPhotoList[0]" alt="" />
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { myGoodsLeaveWordRecordUrl } from "./api";
import { handleImg } from "@/utils/utils.js";
import { mapState } from "vuex";
export default {
  name: "goodsLeaveMessage",
  data() {
    return {
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      goodsId: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    if (this.$route.query.goodsId) {
      this.goodsId = this.$route.query.goodsId;
    }
  },
  mounted() {},
  methods: {
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        goodsId: this.goodsId,
      };
      this.$axios
        .get(`${myGoodsLeaveWordRecordUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.goodsPhotoList.length > 0) {
                item.goodsPhotoList = item.goodsPhotoList[0].split(",");
                item.goodsPhotoList[0] = handleImg(
                  128,
                  128,
                  item.goodsPhotoList[0]
                );
              }
              this.listData.push(item);
            });
          } else {
            this.finished = true;
          }
        });
    },
    toPath(name, leaveWordId) {
      this.$router.push({
        name: name,
        query: {
          leaveWordId: leaveWordId,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 60px 30px;
  .list {
    .item {
      display: flex;
      margin-bottom: 54px;
      .right {
        width: 128px;
        height: 128px;
        border-radius: 14px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .center {
        flex: 1;
        .name {
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
        }
        .txt {
          width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          padding-bottom: 10px;
        }
        .time {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .left {
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 14px;
        img {
          width: 100%;
          height: 100;
        }
      }
    }
  }
}
</style>

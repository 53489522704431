<template>
  <div class="introduce">
    <div class="topImg">
      <img src="./img/pointRule.png" alt="" />
    </div>
    <div class="body">
      <div class="body-center">
        <div class="body-c">
          <div class="item">
            <div class="item-t">1. 用户注册：</div>
            <div class="item-b">获得5积分，完成注册即可。</div>
          </div>
          <div class="item">
            <div class="item-t">2. 用户完善资料：</div>
            <div class="item-b">
              获得10积分，必须填写完整房号，并审核通过才能获得，且只能获得一次。
            </div>
          </div>
          <div class="item">
            <div class="item-t">3. 每日打卡高频应用：</div>
            <div class="item-b">每个应用可获得2积分；</div>
          </div>
          <div class="item">
            <div class="item-t">
              4. 参加活动（含热门活动、达人活动、志愿活动等）:
            </div>
            <div class="item-b">
              用户报名参与活动后可以获得5-15积分（扫描活动签到二维码获得）。
            </div>
          </div>
          <div class="item">
            <div class="item-t">5. 发布活动：</div>
            <div class="item-b">达人成功发布有效活动，一次获10积分。</div>
          </div>
          <div class="item">
            <div class="item-t">6. 特殊人群帮扶：</div>
            <div class="item-b">
              特殊人群参与社区活动可额外获得10积分，社区居民帮扶特殊人群，核实后可获得30积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">7. 优秀通讯员、达人：</div>
            <div class="item-b">评为优秀通讯员、达人能手等奖励50-200积分。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "introduce",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.introduce {
  min-height: 100%;
  padding: 0 0 68px;
  // background: #126cfe; // #3288FF
  background: linear-gradient(90deg, #126cfe 0%, #3288ff 100%);
  .topImg {
    // height: 600px;
    height: 336px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .body {
    padding: 0 32px;
    margin-top: -18px;
    z-index: 99;
    position: relative;
    .body-center {
      background: #ffffff;
      border-radius: 16px;
      padding: 32px 32px 0px;
      .body-c {
        padding-bottom: 40px;
      }
      .item {
        margin-bottom: 48px;
        font-size: 32px;
        // font-weight: 400;
        // color: #646668;
        line-height: 44px;
        .item-t {
          font-size: 32px;
          font-weight: bold;
          color: #323334;
          line-height: 44px;
          margin-bottom: 8px;
        }
        .item-b {
          color: #9d9d9d;
          font-size: 28px;
        }
      }
      .title {
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        color: #2992fd;
        margin-bottom: 22px;
        line-height: 44px;
        span {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 150px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #2992fd 0%,
              rgba(41, 146, 253, 0) 100%
            );
            border-radius: 2px;
            left: -170px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &::after {
            content: "";
            position: absolute;
            width: 150px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #2992fd 0%,
              rgba(41, 146, 253, 0) 100%
            );
            border-radius: 2px;
            right: -170px;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(180deg);
          }
        }
      }
      .body-b {
        padding-bottom: 66px;
        p {
          font-size: 28px;
          font-weight: 400;
          color: #646668;
          line-height: 56px;
          b {
            font-weight: 700;
            color: #000;
          }
        }
      }
    }
  }
}
</style>

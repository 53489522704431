<template>
  <div class="page">
    <img src="./img/signin-bg.png" class="bg" />
    <div class="main">
      <div class="tab">
        <div
          class="tab-item"
          @click="changeType(item)"
          v-for="(item, index) in typeList"
          :key="index"
        >
          <span :class="{ active: typeId == item.id }"
            >{{ item.name }}<i v-if="typeId == item.id"></i
          ></span>
        </div>
      </div>
      <div class="list">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="list-item"
            @click="itemClick(item)"
            v-for="(item, index) in listData"
            :key="index"
          >
            <div class="name">{{ item.activityName }}</div>
            <div class="opt-line">
              <div class="time">活动开始时间：{{ item.startDate }}</div>
              <div :class="['btn', format('className', item.activityStatus)]">
                {{ format("str", item.activityStatus) }}
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { getActivityListUrl } from "./api.js";
export default {
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
      typeId: "20",
      typeList: [
        { name: "志愿服务", id: "20" },
        { name: "热门活动", id: "1" },
        { name: "幸福学堂", id: "19" },
      ],
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {
    itemClick(item) {
      this.$router.push({
        path: "/futureNeighbourhood/activitySignInDetail",
        query: {
          activityId: item.activityId,
        },
      });
    },
    format(type, status) {
      if (type == "className") {
        if (status == 2 || status == 4) {
          return "blue";
        } else if (status == 1 || status == 3) {
          return "grey";
        } else if (status == 5 || status == 6) {
          return "black";
        }
      } else if (type == "str") {
        if (status == 1) {
          return "报名未开始";
        } else if (status == 2) {
          return "报名进行中";
        } else if (status == 3) {
          return "活动待开始";
        } else if (status == 4) {
          return "活动进行中";
        } else if (status == 5) {
          return "活动已结束";
        } else if (status == 6) {
          return "活动已取消";
        }
      }
    },
    getActivityList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
        activityCategory: this.typeId,
      };
      this.$axios
        .get(`${getActivityListUrl}`, { params: params })
        .then((res) => {
          console.log(res, 333);
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    onLoad() {
      this.getActivityList();
    },
    changeType(item) {
      this.typeId = item.id;
      this.finished = false;
      this.finishedText = "没有更多了";
      this.$refs.list._data.loading = true;
      this.requestData.curPage = 1;
      this.listData = [];
      this.getActivityList();
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 0 60px;
  .main {
    position: relative;
    padding: 36px 30px 0;
    box-sizing: border-box;
    .list {
      .list-item {
        height: 170px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 4px 16px 0px rgba(236, 240, 237, 0.5);
        border-radius: 8px;
        margin-bottom: 20px;
        box-sizing: border-box;
        padding: 36px 20px 36px 36px;
        .opt-line {
          display: flex;
          align-items: center;
          margin-top: 20px;
          .blue {
            color: #ffffff;
            background: #538ef4;
          }
          .grey {
            color: #aeaeae;
            background: #e5e5e5;
          }
          .black {
            color: #ffffff;
            background: #c8c8c8;
          }
          .btn {
            width: 150px;
            height: 50px;
            border-radius: 48px;
            font-size: 24px;
            text-align: center;
            font-weight: 600;
            line-height: 50px;
            vertical-align: middle;
          }
          .time {
            flex: 1;
            font-size: 26px;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .name {
          font-size: 30px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
    .tab {
      height: 88px;
      line-height: 88px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 4px 16px 0px rgba(236, 240, 237, 0.5);
      border-radius: 8px;
      display: flex;
      margin-bottom: 30px;
      .tab-item {
        flex: 1;
        text-align: center;
        span {
          font-size: 24px;
          font-weight: 500;
          color: #71a4ff;
          position: relative;
          i {
            width: 8px;
            height: 8px;
            background: #f7b500;
            border-radius: 50%;
            position: absolute;
            top: 0;
            right: -10px;
          }
        }
        .active {
          font-size: 30px;
          font-weight: 600;
          color: rgba(54, 123, 244, 0.85);
        }
      }
    }
  }
  .bg {
    width: 100%;
    position: absolute;
  }
}
</style>

<template>
  <div class="smartParking">
    <div class="section-search">
      <div class="search-flex">
        <span>是否进行轨迹记录</span>
        <van-switch v-model="checked" @change="switchChange" />
      </div>
      <div class="search-flex">
        <span>选择日期</span>
        <div class="flex-date" @click="isShowPopup = true">
          <span>{{ searchParam.startTime }}</span>
          <img src="../../img/date.png" alt="" />
        </div>
      </div>
    </div>
    <v-dateTimePicker
      type="date"
      :value="searchParam.startTime | dateFormat"
      :maxDate="new Date()"
      :valueShow.sync="isShowPopup"
      :isAuto="true"
      @confirmPicker="confirm"
    />
    <div class="map" :id="id"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import {
  sendLocationCommandURL,
  getTrackListURL,
  getTrackSwitchURL,
  onOffTrackEnableURL,
} from "../../api";

export default {
  data() {
    return {
      id: `container_${new Date().getTime()}_${Math.random * 1000}`,
      map: null,
      // position: ["121.62454", "29.860258"], // 定位点默认为宁波市
      position: undefined,
      pointList: [
        {
          name: "路线轨迹",
          des: "养老手环路线轨迹回放",
          position: [], // [116.478935, 39.997761],
        },
      ],
      polyline: [],
      markers: [],
      searchParam: {
        imei: undefined,
        deviceId: "",
        startTime: moment().format("YYYY/MM/DD").replace(/\//g, "-"),
      },
      checked: false,
      isShowPopup: false,
    };
  },
  filters: {
    dateFormat: function (value) {
      if (value) {
        return moment(value).toDate();
      }
      return new Date();
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.searchParam.deviceId = this.$route.query.deviceId;
    this.searchParam.imei = this.$route.query.imei;
  },
  async mounted() {
    await this.sendLocationCommand();
    this.getTrackSwitch();
    await this.getTrajectoryData();
    this.initMap();
  },
  methods: {
    initMap() {
      if (this.map) {
        this.map.destroy();
      }
      if (window.AMap) {
        let { position } = this.pointList[0];
        this.map = new window.AMap.Map(this.id, {
          zoom: "12",
          center: position.length > 0 ? position[0] : this.position,
        });
        this.addPolyline(position);

        position.forEach((data, index) => {
          this.addMarker(data, index);
        });
      }
    },
    addMarker(marker, index) {
      if (window.AMap) {
        const _this = this;
        const icon = new window.AMap.Icon({
          size: new window.AMap.Size(22, 28),
          image: require("../../img/place_green.png"),
          imageSize: new window.AMap.Size(22, 28),
        });
        _this.markers[index] = new window.AMap.Marker({
          icon,
          position: marker,
          offset: new window.AMap.Pixel(-10, -20),
        });
        // _this.markers[index].on("click", () => {
        //   this.markers.forEach((item) => {
        //     item.setContent("");
        //     item.setIcon(icon);
        //   });
        // });
        _this.map.add(_this.markers[index]);
      }
    },
    addPolyline(path) {
      if (window.AMap && path.length > 0) {
        const _this = this;
        const polyline = new window.AMap.Polyline({
          map: this.map,
          path,
          showDir: true,
          strokeColor: "#28F", //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
        });
        this.polyline = [polyline];
      }
    },
    // 调取接口
    confirm(value) {
      this.searchParam.startTime = moment(value)
        .format("YYYY/MM/DD")
        .replace(/\//g, "-");
      this.getTrajectoryData();
    },

    // 发送定位指令
    async sendLocationCommand() {
      let res = await this.$axios.post(sendLocationCommandURL, {
        deviceId: this.searchParam.deviceId,
        userId: this.userId,
      });
      if (res.code == 200 && res.success) {
        if (res.data.lon && res.data.lat) {
          this.position = [res.data.lon, res.data.lat];
        }
      }
    },

    async getTrackSwitch() {
      let res = await this.$axios.get(getTrackSwitchURL, {
        params: {
          imei: this.searchParam.imei,
        },
      });
      if (res.code == 200 && res.success) {
        this.checked = res.data.trackEnable == 1;
      }
      // this.$toast({ message: res.msg || "操作成功", duration: 1500 });
    },

    // 是否开启轨迹记录
    async switchChange(val) {
      let res = await this.$axios.post(onOffTrackEnableURL, {
        userId: this.userId,
        deviceId: this.searchParam.deviceId,
        trackEnable: val ? 1 : 0,
      });
      if (res.code == 200 && res.success) {
        console.log(res.msg, "res.msg---");
        this.$toast({ message: res.msg || "操作成功！", duration: 1500 });
      }
    },

    // 获取轨迹数据
    async getTrajectoryData() {
      let res = await this.$axios.get(getTrackListURL, {
        params: {
          deviceId: this.searchParam.deviceId,
          startTime: this.searchParam.startTime,
          userId: this.userId,
        },
      });
      if (res.code == 200 && res.data) {
        this.pointList[0].position = res.data
          .filter((v) => v.isTrack == 1)
          .map((v) => [v.lon, v.lat]);
        this.initMap();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.smartParking {
  width: 100%;
  height: 100%;
  .section-search {
    .search-flex {
      height: 92px;
      display: flex;
      align-items: center;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      padding: 0 30px;
      > span {
        flex: 1;
      }
      ::v-deep .van-switch {
        box-sizing: border-box;
        width: 96px;
        height: 44px;
        border: 6px solid #c7d8ff;
        border-radius: 22px;
        display: flex;
        align-items: center;
        .van-switch__node {
          bottom: 0;
          margin: auto 0;
          width: 24px;
          height: 24px;
        }
        &.van-switch--on .van-switch__node {
          transform: translateX(60px);
        }
      }
      .flex-date {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 62px;
        width: 320px;
        padding: 0 28px 0 18px;
        border: 2px solid rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        > span {
          flex: 1;
          color: rgba(0, 0, 0, 0.5);
          font-size: 24px;
        }
        img {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
  .map {
    height: calc(100% - 184px);
    width: 100%;
  }
}
</style>

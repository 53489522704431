<template>
  <div class="page">
    <v-chatRoom
      :chatRoomType="chatRoomType"
      :sendParams="sendParams"
      :getListParams="getListParams"
      :delMsgParams="delMsgParams"
    >
    </v-chatRoom>
  </div>
</template>

<script>
export default {
  name: "leavingMessage",
  data() {
    return {
      chatRoomType: 2,
      sendParams: {},
      getListParams: {},
      delMsgParams: {},
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>

<style scoped lang="less">
.page {
  min-height: 100%;
  box-sizing: border-box;
  background: #f9f9f9;
}
</style>

<template>
  <div class="activityDetail">
    <div class="introImg">
      <img :src="activityDetail.activityPicture" alt="" />
    </div>
    <div class="titleIntro">
      <div class="h3">{{ activityDetail.activityName }}</div>
    </div>
    <div class="activityDetail-t">
      <div class="title">活动规则</div>
      <div class="time">
        <div class="item">
          <div class="item-l">报名开始时间</div>
          <div class="item-r">
            {{ activityDetail.applyStartDate }}
          </div>
        </div>
        <div class="item">
          <div class="item-l">活动开始时间</div>
          <div class="item-r">{{ activityDetail.startDate }}</div>
        </div>
      </div>
      <div class="peopleNum">
        <div class="item">
          <div class="item-l">活动人数</div>
          <div class="item-r">
            <span>{{ activityDetail.applyUserCount }}</span
            >/{{ activityDetail.limitCount }}
          </div>
        </div>
      </div>
      <div class="address">
        <div class="item">
          <div class="item-l">活动地点</div>
          <div class="item-r">
            {{ activityDetail.activityAddress }}
          </div>
        </div>
      </div>
    </div>
    <div class="activityDetail-c" v-if="activityDetail.hasFeedback == 1">
      <div class="title">活动回顾</div>
      <div class="body" v-html="activityDetail.feedback"></div>
    </div>
    <div class="activityDetail-c" v-else>
      <div class="title">活动内容</div>
      <div class="body" v-html="activityDetail.content"></div>
    </div>
    <template
      v-if="activityDetail.activityStatus == 2 && activityDetail.isApplied != 1"
    >
      <div
        class="activityDetail-b"
        @click="toSubmit"
        v-if="activityDetail.limitCount > activityDetail.applyUserCount"
      >
        <div class="btn-l">
          <img src="./img/numberOfPeopleBlue.png" alt="" />
        </div>
        <div class="btn-r">活动报名</div>
      </div>
      <div class="activityDetail-b activityDetail-gray" v-else>
        <div class="btn-r">报名人数已满</div>
      </div>
    </template>
    <div
      class="activityDetail-b activityDetail-gray"
      v-if="activityDetail.isApplied == 1"
    >
      <div class="btn-r">已报名</div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getActivityDetailUrl } from "./api.js";
import { toRegister, isWxMiniPro, gloabalCount } from "@/utils/common";
import { handleImg } from "@/utils/utils.js";
export default {
  name: "activityDetail",
  data() {
    return {
      activityDetail: {},
    };
  },
  created() {
    this.activityId = this.$route.query.activityId;
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  mounted() {
    gloabalCount(this.activityId, 3, 1);
    this.getActivityDetail();
  },
  destroyed() {
    if (isWxMiniPro()) {
      wx.miniProgram.postMessage({
        data: JSON.stringify({ activityId: "", title: "", img: "" }),
      });
    }
    clearTimeout(this.timer);
  },
  methods: {
    async toSubmit() {
      if (
        await toRegister(this.$route.path, this.$route.query, "热门活动报名")
      ) {
        this.$router.push({
          name: "sureSubmit",
          query: { activityId: this.activityId, addSelf: 1 },
        });
      }
    },
    getActivityDetail() {
      let params = {
        activityId: this.activityId,
        userId: this.userId,
      };
      this.$axios.get(`${getActivityDetailUrl}`, { params }).then((res) => {
        console.log(res, 5555);
        if (res.code === 200) {
          this.activityDetail = res.data;
          if (this.activityDetail.activityPicture) {
            this.activityDetail.activityPicture = handleImg(
              375,
              211,
              this.activityDetail.activityPicture
            );
          }
          if (isWxMiniPro()) {
            wx.miniProgram.postMessage({
              data: JSON.stringify({
                activityId: this.activityId,
                title: this.activityDetail.activityName,
                img: this.activityDetail.activityPicture,
              }),
            });
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.activityDetail {
  min-height: 100vh;
  padding-bottom: 100px;

  .title {
    position: relative;
    padding-left: 24px;
    font-size: 32px;
    font-weight: bold;
    color: #323334;
    margin-bottom: 32px;
    line-height: 44px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 8px;
      height: 32px;
      background: #fe6f16;
      border-radius: 4px;
    }
  }
  .introImg {
    width: 100%;
    height: 56.25vw;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .titleIntro {
    padding: 32px 32px 0 32px;
    .h3 {
      font-size: 36px;
      font-weight: bold;
      color: #323334;
      line-height: 52px;
    }
  }
  .activityDetail-t {
    margin-top: 80px;
    padding: 0 32px;
  }
  .activityDetail-c {
    margin-top: 80px;
    padding: 0 40px;
    .body {
      font-size: 30px !important;
      span {
        font-size: 30px !important;
      }
    }
  }

  .actiName,
  .time,
  .address,
  .peopleNum {
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
    .item-l {
      width: 168px;
      margin-right: 48px;
      font-size: 28px;
      font-weight: 400;
      color: #323334;
      line-height: 40px;
    }
    .item-r {
      flex: 1;
      font-size: 28px;
      font-weight: 400;
      color: #97999b;
      line-height: 40px;
      span {
        color: #fe6f16;
      }
    }
  }
  .address {
    .item {
      align-items: flex-start;
    }
  }
  .activityDetail-b {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #009aff;
    height: 96px;
    position: fixed;
    bottom: 0;
    left: 0;
    color: #fff;
    .btn-l {
      width: 60px;
      height: 60px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .btn-r {
      color: #fff;
      font-size: 32px;
      line-height: 96px;
    }
  }
  .activityDetail-gray {
    background: #cccccc;
  }
}
</style>
<style lang="less">
.activityDetail {
  .activityDetail-c {
    .body {
      padding: 20px 0;
      font-size: 30px;
      img {
        width: 100%;
      }
      font-size: 30px !important;
      span {
        font-size: 30px !important;
      }
    }
  }
}
</style>

<template>
  <div class="page">
    <div class="tab">
      <div
        :class="index == tabAct ? 'tab-item-act' : 'tab-item'"
        @click="tabCheck(item, index)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item }}
        <div class="line" v-if="index == tabAct"></div>
      </div>
    </div>
    <div class="banner" v-if="tabAct == 0">
      <img src="./img/photostage-banner.png" alt="" />
    </div>
    <p>{{ tabAct == 0 ? "荣誉发布" : "干部信息" }}</p>
    <div class="listBox">
      <div class="list" v-show="tabAct == 0">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="item"
            v-for="(item, index) in listData"
            :key="index"
            @click="toRouteTitle(item)"
          >
            <div class="left">
              <div class="title">{{ item.topicTitle || "" }}</div>
              <div class="bottom">
                <div class="time">{{ item.createTime }}</div>
                <div class="sum">
                  <img src="./img/look-icon.png" alt="" />
                  <span>{{ item.readCount }}</span>
                </div>
              </div>
            </div>
            <div class="right">
              <img :src="item.topicPicture" />
            </div>
          </div>
        </v-list>
      </div>
      <div class="list2" v-show="tabAct == 1">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="left">
            <img
              :src="
                item.pictures.split(',')[0] ||
                require('./img/morentouxiang.png')
              "
              alt=""
            />
          </div>
          <div class="right">
            <div class="name">{{ item.realName }}</div>
            <div class="identity">{{ item.post }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTopicListUrl, getCadreListUrl } from "./api.js";
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
export default {
  name: "photostage",
  data() {
    return {
      tabList: ["树标杆", "建堡垒"],
      tabAct: 0,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 10,
      list: [],
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        curPage: 1,
        pageSize: 500,
      };
      this.$axios.get(`${getCadreListUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          this.list = res.data.records;
        }
      });
    },
    tabCheck(item, index) {
      this.tabAct = index;
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListUrl, {
          params: {
            tenantId: this.tenantId,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 33, //32 资讯发布,33 树标杆,34 干实事,35 定制度
            curPage: this.curPage,
            pageSize: this.pageSize,
            houseId: this.houseId || this.communityId || undefined,
            sortField: "NEW", // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.listData.forEach((ele) => {
              if (ele.topicPicture) {
                ele.topicPicture = this.$handleImg(226, 150, ele.topicPicture);
              }
            });
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 点击跳转话题详情页
    toRouteTitle(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 30px;
  background: #fafafa;
  .listBox {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    .list2 {
      // padding: 30px 0;
      box-sizing: border-box;
      .item {
        width: 100%;
        height: 204px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px;
        margin-bottom: 20px;
        overflow: hidden;
        display: flex;
        .right {
          flex: 1;
          padding: 32px 26px;
          box-sizing: border-box;
          .name {
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            padding-bottom: 10px;
          }
          .identity {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .left {
          width: 224px;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .list {
      padding: 30px 0;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(220, 220, 220, 0.5);
      border-radius: 16px;
      overflow: hidden;
      .item {
        width: 100%;
        height: 194px;
        display: flex;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        padding: 16px 28px 0;
        box-sizing: border-box;
        .left {
          flex: 1;
          padding-right: 12px;
          box-sizing: border-box;
          .bottom {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #b4b4b4;
            width: 100%;
            display: flex;
            align-items: center;
            .sum {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 28px;
              }
            }
          }
          .title {
            font-size: 32px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 28px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .right {
          width: 226px;
          height: 100%;
          img {
            width: 100%;
            border-radius: 10px;
          }
        }
      }
    }
  }
  p {
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1c34;
    padding: 0px 30px 22px;
  }
  .banner {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    margin: 34px 0;
    img {
      width: 100%;
    }
  }
  .tab {
    width: 100%;
    height: 88px;
    display: flex;
    background: #fff;
    .tab-item-act {
      position: relative;
      flex: 1;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #fe9514;
      text-align: center;
      line-height: 88px;
      .line {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 140px;
        height: 6px;
        background: #fe9514;
        border-radius: 3px;
      }
    }
    .tab-item {
      position: relative;
      flex: 1;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      line-height: 88px;
      .line {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 140px;
        height: 6px;
        background: #fe9514;
        border-radius: 3px;
      }
    }
  }
}
</style>

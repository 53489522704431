<template>
  <div class="section">
    <div class="section-result">
      <img src="../img/success_icon.png" alt="" />
    </div>
    <div class="section-text">您已成功提交发货信息！</div>
    <div class="section-footer" @click="goBack">返回</div>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.section {
  font-family: PingFangSC-Regular, PingFang SC;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-result {
    padding: 192px 200px 170px;
    > img {
      &:extend(.common-img);
      width: 100%;
      // height: 272px;
    }
  }
  .section-text {
    font-size: 32px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    text-align: center;
  }
  .section-footer {
    width: 550px;
    height: 70px;
    background: #db431d;
    border-radius: 16px;
    margin: 48px auto 0px;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    line-height: 70px;
    text-align: center;
  }
}
</style>

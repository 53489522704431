<template>
  <div class="section">
    <!-- 更多类别 -->
    <div class="section-flex category">
      <div class="section-flex-nav">
        <div class="nav-more">
          <div class="more" @click="toShopCart">
            <span>前往购物车</span>
            <img src="./img/arrow_right.png" alt="" />
          </div>
        </div>
      </div>
      <div class="section-flex-list">
        <div
          class="section-flex-item"
          v-for="(item, index) in categoryList"
          :key="index"
          @click="categoryDetail(item)"
        >
          <img :src="item.icon" alt="" />
          <div class="item-text">{{ item.typeName }}</div>
        </div>
      </div>
    </div>
    <!-- 滚动公告 -->
    <div
      class="section-flex notice"
      v-if="broadcastList.length > 0"
      @click="toBroadcast"
    >
      <img src="./img/notice.png" alt="" />
      <notice-bar :text="broadcastList" />
    </div>

    <!-- 福利 -->
    <div class="section-flex welfare">
      <div class="section-flex-nav">
        <div class="common-title"><span>福利</span></div>
        <div class="nav-more">
          <div class="more" @click="toGroup">
            <span>发布团购</span>
            <img src="./img/arrow_right.png" alt="" />
          </div>
        </div>
      </div>
      <div class="section-flex-content">
        <div
          class="content-flex"
          v-for="(item, index) in welfareOps"
          :key="index"
          @click="toGroupList(item.router)"
        >
          <div class="content-header">
            <span>{{ item.label }}</span>
          </div>
          <div class="content-main">
            <template v-if="item.list && item.list.length > 0">
              <div
                class="content-item"
                v-for="(o, i) in item.list"
                :key="`${index}_${i}`"
              >
                <img :src="o.imageUrl" alt="" />
                <p class="content-item-title">{{ o.goodsTitle }}</p>
                <p class="content-item-inventory">
                  仅剩{{ o.goodsRemainCount }}件
                </p>
                <p class="content-item-price">￥{{ o.nowCost }}</p>
              </div>
            </template>
            <template v-else>
              <div class="content-empty">
                <span>暂无数据</span>
              </div>
            </template>
          </div>
          <div class="content-footer">
            <span>进入会场</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 一刻钟生活服务圈 -->
    <div class="section-flex service">
      <div class="section-flex-nav">
        <div class="common-title"><span>线上店铺</span></div>
        <div class="nav-more">
          <div class="more" @click="toMore">
            <span>更多</span>
            <img src="./img/arrow_right_gray.png" alt="" />
          </div>
        </div>
      </div>
      <div class="section-flex-list">
        <div
          class="section-flex-item"
          v-for="(item, index) in serviceList"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="item-picture"><img :src="item.imageUrl" alt="" /></div>
          <div class="item-detail">
            <div class="item-detail-title">
              <span>{{ item.title }}</span>
            </div>
            <div class="item-detail-place">
              <img src="./img/place_icon.png" alt="" />
              <span>地址：{{ `${item.street} ${item.address}` }}</span>
            </div>
            <div class="item-detail-active">
              <img src="./img/active_icon.png" alt="" />
              <span>优惠活动：{{ item.mallActivity }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import {
  getMallTypeIconListURL,
  issueGroupBuyingForMiniURL,
  getShopInfoListURL,
  checkIsLeaderURL,
  getTopicListURL,
} from "./api";
import { gloabalCount, toRegister } from "@/utils/common";
import noticeBar from "./components/notice-bar";

export default {
  components: { noticeBar },
  data() {
    return {
      categoryList: [
        // 类别列表
        // {
        //   icon: "https://fenghua-dev.zhuneng.cn/imgForMini/static/index/fitup-/chaigai.png"
        //   id: 1
        //   isDelete: 0
        //   isShow: 0
        //   typeName: "更多"
        // },
        // {
        //   label: "美食",
        //   type: 1,
        //   value: "2",
        //   picture: "",
        // },
        // {
        //   label: "商超",
        //   type: 2,
        //   value: "3",
        //   picture: "",
        // },
        // {
        //   label: "百货",
        //   type: 1,
        //   value: "4",
        //   picture: "",
        // },
        // {
        //   label: "旅游",
        //   type: 3,
        //   value: "5",
        //   picture: "",
        // },
        // {
        //   label: "休闲玩乐",
        //   type: 1,
        //   value: "6",
        //   picture: "",
        // },
        // {
        //   label: "本地时令",
        //   type: 1,
        //   value: "7",
        //   picture: "",
        // },
        // {
        //   label: "本地制造",
        //   type: 1,
        //   value: "8",
        //   picture: "",
        // },
        // {
        //   label: "福利商城",
        //   type: 1,
        //   value: "9",
        //   picture: "",
        // },
        // {
        //   label: "更多",
        //   type: 1,
        //   value: "99",
        //   picture: "",
        // },
      ],
      broadcastList: [],
      welfareOps: [
        // 福利结构
        {
          // 拼团
          label: "拼团",
          router: "bcnWelfarGroupOrder",
          list: [
            // {
            //   title: "特仑苏纯牛奶12盒",
            //   picture: "",
            //   inventory: "7",
            //   price: "5.00",
            // },
            // {
            //   title: "六神去痱粉盒装",
            //   picture: "",
            //   inventory: "7",
            //   price: "5.00",
            // },
          ],
        },
        {
          // 秒杀
          label: "限时秒杀",
          router: "bcnWelfarSeckillOrder",
          list: [
            // {
            //   title: "特仑苏纯牛奶12盒",
            //   picture: "",
            //   inventory: "7",
            //   price: "5.00",
            // },
            // {
            //   title: "六神去痱粉盒装",
            //   picture: "",
            //   inventory: "7",
            //   price: "5.00",
            // },
          ],
        },
      ],
      serviceList: [
        // {
        //   title: "美味水果店",
        //   place: "斗门西路201号",
        //   active: "3月26日19点至21点橘子7折",
        // },
        // {
        //   title: "美味水果店",
        //   place: "斗门西路201号",
        //   active: "3月26日19点至21点橘子7折",
        // },
      ],
      headerman: {}, // 团长信息
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    /* 补上逻辑 toRegister,  */
    gloabalCount("", 100, 1);
    if (await toRegister(this.$route.path, this.$route.query, "新商城")) {
      await Promise.all([
        this.checkIsLeader(),
        this.getMallTypeIconList(),
        this.getShopInfoList(),
        this.issueGroupBuyingForMini(1),
        this.issueGroupBuyingForMini(2),
        this.getBroadcastList(),
      ]);
    }
  },
  methods: {
    // 获取团长状态
    async checkIsLeader() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(checkIsLeaderURL, {
        params,
      });
      if (res.code == 200) {
        this.headerman = res.data || {};
      }
    },
    // 获取商品icon图
    async getMallTypeIconList() {
      let res = await this.$axios.get(getMallTypeIconListURL);
      if (res.code == 200) {
        this.categoryList = res.data.filter((v) => !!v.isShow);
      }
    },
    // 获取商户列表
    async getShopInfoList() {
      let params = {
        selectType: 2, // 最近发布
        status: 1, // 审核通过
        curPage: 1,
        pageSize: 10,
        // userId: this.userId,
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
      };
      let res = await this.$axios.get(getShopInfoListURL, {
        params,
      });
      if (res.code == 200) {
        this.serviceList = res.data.records;
      }
    },
    // 获取团购/秒杀列表
    async issueGroupBuyingForMini(buyType) {
      let params = {
        buyType, // 1:社区团购 2:限时秒杀
        curPage: 1,
        pageSize: 2,
        // userId: this.userId,
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
      };
      let res = await this.$axios.get(issueGroupBuyingForMiniURL, {
        params,
      });
      if (res.code == 200) {
        this.welfareOps[buyType - 1].list = res.data.records;
      }
    },
    categoryDetail(item) {
      if (item.category == 3) {
        let params = {
          userId: this.userId,
          tenantId: this.tenantId,
          communityId: this.communityId,
          houseId: this.houseId,
          goodsType: item.id,
          typeName: item.typeName,
        };
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/bcnCategoryLink/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
        return;
      }
      let nameMap = {
        1: "bcnCategoryShop",
        2: "bcnCategoryBroadcast",
        // 3: "bcnCategoryLink",
      };
      this.$router.push({
        name: nameMap[item.category],
        query: {
          goodsType: item.id,
          typeName: item.typeName,
        },
      });
    },
    // 前往购物车
    toShopCart() {
      this.$router.push({
        name: "bcnOtherMyOrder",
        query: this.$route.query,
      });
    },

    // 加载列表数据
    getBroadcastList() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 45, // 45 新商城广播
            curPage: 1,
            pageSize: 10,
            sortField: "NEW", // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data && res.data.records) {
            this.broadcastList = res.data.records.map((v) => v.topicTitle);
          }
        });
    },
    // 去公告列表页面
    toBroadcast() {
      this.$router.push({
        name: "bcnBroadcast",
      });
    },
    // 发布团购按钮
    toGroup() {
      // 审核通过
      const result = this.headerman.auditStatus == 1;
      let name = "";
      if (result) {
        name = "bcnWelfareMyPublish";
      } else {
        name = "bcnWelfarGroupManApply";
      }
      this.$router.push({
        name,
        query: {
          auditStatus: this.headerman.auditStatus,
        },
      });
    },
    // 进入团购/秒杀列表
    toGroupList(name) {
      this.$router.push({
        name,
      });
    },
    // 一刻钟生活服务圈
    toMore() {
      this.$router.push({
        name: "businessCircleNewService",
        query: this.$route.query,
      });
    },
    // 商户详情页面
    toDetail(item) {
      this.$router.push({
        name: "bcnServiceDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  display: none;
}
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.common-title {
  span {
    display: block;
    padding: 0 22px;
    font-size: 36px;
    font-weight: bold;
    color: #1a1c34;
    line-height: 50px;
  }
  &::after {
    content: "";
    margin-top: -14px;
    display: block;
    width: 100%;
    height: 14px;
    background: #e2e2e2;
    border-radius: 7px;
  }
}
.section {
  font-family: PingFangSC-Regular, PingFang SC;
  padding-top: 30px;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .section-flex {
    .section-flex-nav {
      display: flex;
      align-items: flex-end;
      padding: 0 48px;
      // .common-img {
      //   flex: 1;
      // }
      .nav-more {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        .more {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          > span {
            font-size: 28px;
            font-weight: 400;
            color: #d9634d;
            line-height: 40px;
            margin-right: 14px;
          }
          > img {
            width: 12px;
            height: 22px;
            &:extend(.common-img);
          }
        }
      }
    }
    &.category {
      .section-flex-list {
        padding: 0 48px;
        margin: 28px 0 0 0;
        &::after {
          content: "";
          display: block;
          clear: both;
          height: 0;
          width: 0;
          padding: 0;
          margin: 0;
          overflow: hidden;
        }
        .section-flex-item {
          float: left;
          width: 100px;
          &:not(:nth-child(5n + 1)) {
            margin-left: calc(25% - 125px);
          }
          > img {
            width: 100px;
            height: 100px;
            &:extend(.common-img);
          }
          .item-text {
            height: 60px;
            padding: 10px 0;
            width: 100px;
            line-height: 30px;
            font-weight: 400;
            color: #1a1c34;
            font-size: 24px;
            text-align: center;
          }
        }
      }
    }
    &.notice {
      // width: 100%;
      padding: 12px 16px 12px 28px;
      box-sizing: border-box;
      background: rgba(255, 249, 241, 0.82);
      color: #262626;
      display: flex;
      align-items: center;
      margin: 0 24px 30px;
      vertical-align: middle;
      font-size: 24px;
      font-weight: bold;
      line-height: 40px;
      color: #fe9616;
      white-space: nowrap;
      border-radius: 8px;
      > img {
        &:extend(.common-img);
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 10px;
        padding: 8px 0;
      }
    }
    &.welfare {
      padding: 0;
      .section-flex-content {
        padding: 44px 48px;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        > .content-flex {
          display: inline-block;
          &:first-child {
            margin-right: 30px;
          }
          width: 552px;
          min-width: 552px;
          height: 572px;
          background: #ffffff;
          box-shadow: 0px 4px 20px 0px rgba(226, 226, 226, 0.5);
          border-radius: 16px;
          .content-header {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            height: 56px;
            margin: 0 0 24px 22px;
            background: linear-gradient(180deg, #fa8d51 0%, #f56b38 100%);
            border-radius: 0px 0px 16px 16px;
            line-height: 56px;
            span {
              font-size: 30px;
              font-weight: bold;
              line-height: unset;
              color: #ffeee4;
              vertical-align: middle;
            }
          }
          .content-main {
            white-space: nowrap;

            .content-item {
              box-sizing: border-box;
              display: inline-block;
              width: calc(50% - 12px);
              max-width: calc(50% - 12px);
              padding: 0 12px;
              &:not(:first-child) {
                margin-left: 24px;
              }
              img {
                &:extend(.common-img);
                width: 100%;
                height: 160px;
                border-radius: 16px;
                margin-bottom: 12px;
                text-align: center;
                object-fit: cover;
              }
              p.content-item-title {
                width: 100%;
                height: 80px;
                font-size: 28px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.85);
                line-height: 40px;
                white-space: normal;
                word-break: break-all;
                word-wrap: break-word;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 2px;
              }
              p.content-item-inventory {
                font-size: 24px;
                font-weight: 400;
                color: #d9634d;
                line-height: 34px;
                margin-bottom: 12px;
              }
              p.content-item-price {
                font-size: 36px;
                font-weight: bold;
                color: #d9634d;
                line-height: 50px;
              }
            }
            .content-empty {
              width: 100%;
              height: 345px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 32px;
              color: rgba(181, 181, 181, 0.5);
            }
          }
          .content-footer {
            &::before {
              content: "";
              position: absolute;
              left: 16px;
              top: 6px;
              display: block;
              width: 50px;
              height: 22px;
              background: linear-gradient(176deg, #febf9c 0%, #fb8e52 100%);
              border-radius: 56px 16px 56px 0;
              opacity: 0.5;
            }
            position: relative;
            width: 450px;
            height: 84px;
            margin: 32px auto 0;
            background: linear-gradient(270deg, #f46836 0%, #fb8f53 100%);
            box-shadow: 0px 4px 8px 0px #f9c5aa;
            border-radius: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              font-size: 36px;
              font-weight: 500;
              color: rgba(255, 255, 255, 0.88);
              line-height: 50px;
            }
          }
        }
      }
    }
    &.service {
      .section-flex-nav .nav-more .more > span {
        color: rgba(0, 0, 0, 0.5);
      }

      .section-flex-list {
        padding: 44px 48px;
        .section-flex-item {
          &:not(:first-child) {
            margin-top: 30px;
          }
          padding: 22px;
          background: #ffffff;
          box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
          border-radius: 16px;
          display: flex;
          align-items: center;
          .item-picture {
            width: 200px;
            min-width: 200px;
            height: 200px;
            border-radius: 16px;
            overflow: hidden;
            margin-right: 28px;
            > img {
              &:extend(.common-img);
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .item-detail {
            flex: 1;
            min-height: 200px;
            .item-detail-title {
              margin-top: 10px;
              font-size: 28px;
              font-weight: 500;
              color: rgba(0, 0, 0, 0.85);
              line-height: 40px;
              white-space: normal;
              word-break: break-all;
              word-wrap: break-word;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .item-detail-place,
            .item-detail-active {
              margin-top: 10px;
              white-space: normal;
              word-break: break-all;
              word-wrap: break-word;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              > img {
                width: 34px;
                height: 34px;
                &:extend(.common-img);
                margin-right: 14px;
              }
              > span {
                font-size: 24px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
                line-height: 34px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

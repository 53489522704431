<template>
  <div class="cloudSchool">
    <div class="cloudSchool-t">
      <div class="search">
        <div class="search-l">
          <v-dropDownMenu
            v-model="requestData.gradeType"
            :option1="stageSts"
            @change="change"
          ></v-dropDownMenu>
        </div>
        <div class="search-r">
          <v-search
            v-model="value"
            @onSearch="onSearch"
            placeholder="请输入课程名称"
          ></v-search>
        </div>
      </div>
      <div class="main">
        <div class="banner">
          <v-swiper :swiperData="bannerList">
            <template #default="{ rows }">
              <img :src="rows.url" alt="" />
            </template>
          </v-swiper>
        </div>
      </div>
    </div>
    <div class="cloudSchool-c">
      <div
        class="subject"
        v-for="(item, index) in subjectList"
        :key="index"
        @click="toKind(item)"
      >
        <div class="item-t"><img :src="item.url" alt="" /></div>
        <div class="item-b">{{ item.txt }}</div>
      </div>
    </div>
    <div class="cloudSchool-b">
      <div class="title">精选好课</div>
      <div class="cloudSchool-b-body">
        <div class="item" v-if="chineseList.length != 0">
          <div class="item-t">
            <div class="item-tl">语文</div>
            <div class="item-tr" @click="toKind({ key: 1 })">
              <div class="txt">更多</div>
              <div class="img"><img src="./img/more.png" alt="" /></div>
            </div>
          </div>
          <div class="item-b">
            <div
              class="sub"
              v-for="(item, index) in chineseList"
              :key="index"
              @click="toDetail(item, 1)"
            >
              <div class="sub-l">
                <div class="img"><img :src="item.imageUrl" alt="" /></div>
              </div>
              <div class="sub-r">
                <div class="subTitle">
                  {{ item.chapterTitle }}
                </div>
                <div class="courseTitle">
                  {{ item.courseTitle }}
                </div>
                <div class="info">{{ item.viewCount }}人学习</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item-t">
            <div class="item-tl">数学</div>
            <div class="item-tr" @click="toKind({ key: 2 })">
              <div class="txt">更多</div>
              <div class="img"><img src="./img/more.png" alt="" /></div>
            </div>
          </div>
          <div class="item-b">
            <div
              class="sub"
              v-for="(item, index) in mathList"
              :key="index"
              @click="toDetail(item, 2)"
            >
              <div class="sub-l">
                <div class="img"><img :src="item.imageUrl" alt="" /></div>
              </div>
              <div class="sub-r">
                <div class="subTitle">
                  {{ item.chapterTitle }}
                </div>
                <div class="courseTitle">
                  {{ item.courseTitle }}
                </div>
                <div class="info">{{ item.viewCount }}人学习</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="item-t">
            <div class="item-tl">英语</div>
            <div class="item-tr" @click="toKind({ key: 3 })">
              <div class="txt">更多</div>
              <div class="img"><img src="./img/more.png" alt="" /></div>
            </div>
          </div>
          <div class="item-b">
            <div
              class="sub"
              v-for="(item, index) in englishList"
              :key="index"
              @click="toDetail(item, 3)"
            >
              <div class="sub-l">
                <div class="img"><img :src="item.imageUrl" alt="" /></div>
              </div>
              <div class="sub-r">
                <div class="subTitle">
                  {{ item.chapterTitle }}
                </div>
                <div class="courseTitle">
                  {{ item.courseTitle }}
                </div>
                <div class="info">{{ item.viewCount }}人学习</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRegister, gloabalCount } from "@/utils/common.js";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
Vue.use(Swipe);
Vue.use(SwipeItem);
import { stageSts } from "./map.js";
import { courseListUrl } from "./api.js";
export default {
  name: "cloudSchool",
  data() {
    return {
      stageSts,
      statusOps: [],
      subjectList: [
        { url: require("./img/yuwen.png"), txt: "语文", key: 1 },
        { url: require("./img/shuxue.png"), txt: "数学", key: 2 },
        { url: require("./img/english.png"), txt: "英语", key: 3 },
      ],
      bannerList: [{ url: require("./img/banner.png") }],
      requestData: {
        gradeType: "1",
      },
      value: "",
      chineseList: [],
      mathList: [],
      englishList: [],
      pageSize: 3,
      curPage: 1,
    };
  },
  destroyed() {},
  created() {
    console.log();
  },
  mounted() {
    this.getCourseList("1");
    this.getCourseList("2");
    this.getCourseList("3");
    gloabalCount("", 31, 1);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {
    toDetail(item, type) {
      this.$router.push({
        name: "courseDetail",
        query: {
          courseId: item.id,
          gradeType: "",
          courseType: type,
          title: "",
        },
      });
    },
    toKind(item) {
      this.$router.push({
        name: "courseList",
        query: { courseType: item.key, gradeType: this.requestData.gradeType },
      });
    },
    getCourseList(courseType) {
      let params = {
        userId: this.userId,
        curPage: this.curPage,
        pageSize: this.pageSize,
        courseType: courseType,
        gradeType: this.requestData.gradeType,
      };
      this.$axios.get(`${courseListUrl}`, { params: params }).then((res) => {
        if (res.code === 200) {
          if (courseType == "1") {
            res.data.records.forEach((item) => {
              this.chineseList.push(item);
            });
          }
          if (courseType == "2") {
            res.data.records.forEach((item) => {
              this.mathList.push(item);
            });
          }
          if (courseType == "3") {
            res.data.records.forEach((item) => {
              this.englishList.push(item);
            });
          }
        } else {
        }
      });
    },
    change(value) {
      this.requestData.gradeType = value;
      this.chineseList = [];
      this.englishList = [];
      this.mathList = [];
      this.getCourseList("1");
      this.getCourseList("2");
      this.getCourseList("3");
    },
    onSearch() {
      if (!this.value) {
        this.$toast({ message: "请输入关键字", duration: 2000 });
        return;
      }
      this.$router.push({
        name: "courseList",
        query: { gradeType: this.requestData.gradeType, title: this.value },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.cloudSchool {
  min-height: 100vh;
  background: #f7f7f7;
  overflow: hidden;
  .search {
    height: 96px;
    background: #fff;

    width: 100%;
    box-sizing: border-box;
    z-index: 99;
    display: flex;
    .search-l {
      width: 224px;
    }
    .search-r {
      display: flex;
      align-items: center;
    }
  }
  .banner {
    width: 100%;
    ::v-deep .my-swipe {
      width: 100%;
      height: 100%;
      .oSwiper {
        width: 100%;
        .van-swipe-item {
          img {
            width: 100%;
            height: 100%;
          }
          img[src=""],
          img:not([src]) {
            opacity: 0;
          }
        }
      }
    }
  }
  .cloudSchool-c {
    padding: 40px 36px;
    display: flex;
    justify-content: space-between;
    .subject {
      width: 182px;
      height: 194px;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 20px;
      padding-top: 34px;
      box-sizing: border-box;
      .item-t {
        width: 98px;
        height: 98px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-b {
        font-size: 32px;
        text-align: center;
        font-weight: 700;
        color: #1a1c34;
        line-height: 44px;
        margin: 4px 0;
      }
    }
  }
  .cloudSchool-b {
    padding: 0 36px;

    .title {
      font-size: 36px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.85);
      line-height: 50px;
      margin-bottom: 42px;
    }
    .cloudSchool-b-body {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .item {
        width: 540px;
        flex-shrink: 0;
        height: 558px;
        background: #ffffff;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        border-radius: 12px;
        padding: 0 20px;
        box-sizing: border-box;
        margin-right: 30px;
        .item-t {
          display: flex;
          padding: 18px 0;
          justify-content: space-between;
          align-items: center;
          .item-tl {
            font-size: 32px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.85);
            line-height: 40px;
          }
          .item-tr {
            width: 100px;
            height: 36px;
            border-radius: 21px;
            border: 1px solid #d8d8d8;
            display: flex;
            align-items: center;
            justify-content: center;
            .txt {
              font-size: 26px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              line-height: 36px;
              margin-right: 4px;
            }
            .img {
              width: 20px;
              height: 20px;
              font-size: 0;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .item-b {
          .sub {
            height: 132px;
            display: flex;
            margin-bottom: 20px;
            .sub-l {
              width: 220px;
              height: 132px;
              .img {
                width: 100%;
                height: 100%;
                border-radius: 12px;
                img {
                  width: 100%;
                  border-radius: 12px;
                  height: 100%;
                }
              }
            }
            .sub-r {
              flex: 1;
              margin-left: 16px;
              overflow: hidden;
              padding-top: 4px;

              .subTitle,
              .courseTitle {
                font-size: 30px;
                line-height: 36px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-bottom: 10px;
              }
              .info {
                font-size: 28px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
                line-height: 32px;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.cloudSchool {
  .van-dropdown-menu__bar {
    box-shadow: none;
    .van-dropdown-menu__title {
      font-size: 32px;
      font-weight: 700;
      color: #323334;
    }
  }
  .van-search {
    padding: 0;
  }
  .van-search .van-cell {
    align-items: center;
  }
  .van-search__content {
    width: 494px;
    height: 72px;
    background: #f5f5f5;
    border-radius: 40px;
  }
  .van-icon-search::before {
    content: "";
    width: 32px;
    background: url("./img/search.png") no-repeat;
    height: 32px;
    background-size: 100% 100%;
  }
  .van-dropdown-menu__title::after {
    border-color: transparent transparent #323334 #323334;
  }
  .van-dropdown-menu__title--down::after {
    border-color: transparent transparent #007eff #007eff;
  }
}
</style>

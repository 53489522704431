<template>
  <div class="main">
    <div class="section-info">
      <div class="section-info--text">
        <div class="info-text--right">
          <div class="info-text--status">
            <span class="label">手环状态：</span>
            <span class="yellow" v-if="detail.switchStatus == 1">
              未联网运行
            </span>
            <span class="green" v-else-if="detail.switchStatus == 2">
              联网运行
            </span>
            <span v-else-if="detail.switchStatus == 0">离线</span>
          </div>
          <div class="info-text--title">
            <img src="../img/place.png" alt="" />
            <span>当前定位</span>
          </div>
          <div class="info-text--position">
            <img v-show="loading" src="../img/loading.gif" alt="" />
            <!-- <img v-show="!loading" src="../img/loading.png" alt="" /> -->
            {{ loading ? "定位中..." : addressDetail.address || "暂无定位！" }}
          </div>
        </div>
      </div>
      <div class="section-info--back">
        <div class="section-info--flex">
          <div class="section-info--data">
            <div class="info-data--pressure">
              <div class="data-pressure--value">
                <span class="value">
                  {{
                    detail.bpInfo && detail.bpInfo.sbp
                      ? `${detail.bpInfo.sbp}/${detail.bpInfo.dbp}`
                      : "--"
                  }}
                </span>
                <span class="unit">
                  {{
                    detail.bpInfo && detail.bpInfo.bpUnit
                      ? detail.bpInfo.bpUnit
                      : ""
                  }}
                </span>
              </div>
              <div class="data-pressure--text">
                <img src="../img/pressure_green.png" alt="" />
                <span>血压</span>
              </div>
            </div>
            <div class="info-data--oxygen">
              <div class="data-oxygen--value">
                <span class="value">
                  {{
                    detail.bloodoxygenInfo &&
                    detail.bloodoxygenInfo.bloodoxygen !== undefined
                      ? `${detail.bloodoxygenInfo.bloodoxygen}`
                      : "--"
                  }}{{
                    detail.bloodoxygenInfo &&
                    detail.bloodoxygenInfo.bloodoxygenUnit !== undefined
                      ? detail.bloodoxygenInfo.bloodoxygenUnit
                      : ""
                  }}
                </span>
              </div>
              <div class="data-oxygen--text">
                <img src="../img/oxygen_red.png" alt="" />
                <span>血氧</span>
              </div>
            </div>
            <div class="info-data--side">
              <div class="data-side--inside" v-if="detail.inFences == 1">
                <img src="../img/inside_blue.png" alt="" />
                <span>在围栏内</span>
              </div>
              <div class="data-side--outside" v-else>
                <img src="../img/outside.png" alt="" />
                <span>在围栏外</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-echart">
      <div class="echart-container">
        <div class="echart-title">
          <img src="../img/heart_blue.png" alt="" />
          <span>心率</span>
        </div>
        <echart-line :curTab="curTab" :echartData="echartData" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapState } from "vuex";
import {
  fetchDeviceInfoByIdURL,
  sendLocationCommandURL,
  getLocationDetailsURL,
} from "../api";
import echartLine from "./echartLine";

export default {
  components: {
    echartLine,
  },
  props: {
    deviceId: [Number, String],
    curTab: Object,
  },
  data() {
    return {
      detail: {
        switchStatus: 0, // 0 未运行 1 已运行
        inFences: undefined, // 1 在围栏内 其他 在围栏外
        frequencyLocation: 0,
        heartrateInfo: {
          heartrateUnit: "",
          heartrate: "",
          thesholdHeartrateH: "",
          thesholdHeartrateL: "",
        },
        bpInfo: {
          bpUnit: "",
          dbp: "",
          dbpL: "",
          sbp: "",
          sbpH: "",
        },
        bloodoxygenInfo: {
          bloodoxygenUnit: "",
          bloodoxygen: "",
          bloodoxygenH: "",
          bloodoxygenL: "",
        },
      },
      echartData: {
        x: [],
        y: [],
      },
      time: "", // 发动定位指令的时间
      timer: null,
      loading: true,
      addressDetail: {
        id: "",
        imei: "",
        timeBegin: "",
        isReply: undefined,
        isTrack: undefined,
        city: "",
        address: "",
        lon: "",
        lat: "",
        type: undefined,
        createTime: "",
        updateTime: "",
      },
      // switchStatusMap: {
      //   0: "离线",
      //   1: "运行中",
      // },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  watch: {
    addressDetail: {
      handler(val) {
        this.$emit("update:positionDetail", val);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.sendLocationCommand();
    this.getfetchDeviceInfoById();
  },
  beforeDestory() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 获取设备详情
    async getfetchDeviceInfoById() {
      let res = await this.$axios.get(fetchDeviceInfoByIdURL, {
        params: { deviceId: this.deviceId },
      });
      console.log(res.data, "res.data---");
      if (res.code == 200 && res.success) {
        this.detail = res.data;
        let y = res.data.heartrateList.map((v) => v.heartrate);
        let x = res.data.heartrateList.map((v) =>
          v.timeBegin ? v.timeBegin.slice(-8, -3) : ""
        );
        this.echartData = {
          x,
          y,
        };
      }
    },

    // 发送定位指令
    async sendLocationCommand() {
      let res = await this.$axios.post(sendLocationCommandURL, {
        deviceId: this.deviceId,
        userId: this.userId,
      });
      if (res.code == 200 && res.success) {
        this.time = res.data.time;
        if (this.time) {
          this.timer = setInterval(() => {
            this.getLocationDetails();
          }, 15000);
        } else {
          this.loading = false;
          this.addressDetail.address = res.data.address;
        }
      } else {
        this.loading = false;
        this.addressDetail.address = "定位失败！";
      }
    },

    // 获取主动定位结果详情
    async getLocationDetails() {
      this.loading = true;
      let res = await this.$axios.get(getLocationDetailsURL, {
        params: { deviceId: this.deviceId, time: this.time },
        headers: {
          noLoading: true,
        },
      });
      if (res.code == 200 && res.success) {
        this.addressDetail = _.cloneDeep(res.data);
        this.addressDetail.address = res.data.address || res.msg;
        console.log(res.data.address, "res.data.address");
        if (res.data.address) {
          clearInterval(this.timer);
          this.timer = null;
        }
      }
      if (this.addressDetail.address !== "定位中...") {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  .section-info {
    .section-info--text {
      height: 288px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-align: left;
      background: url("../img/personal.png") no-repeat 0% 0%;
      background-size: 428px 410px;
      .info-text--right {
        padding-right: 30px;
        width: 40%;
        .info-text--status {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 36px;
          span {
            &.label {
              color: rgba(0, 0, 0, 0.85);
            }
            &.green {
              color: #21c6a2;
            }
            &.yellow {
              color: #f9a52a;
            }
          }
        }
        .info-text--title {
          display: flex;
          font-size: 24px;
          line-height: 34px;
          color: rgba(0, 0, 0, 0.5);
          > img {
            width: 22px;
            height: 22px;
            margin: 4px 8px 8px 0px;
          }
        }
        .info-text--position {
          display: flex;
          margin-top: 14px;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.5);
          line-height: 36px;
          word-wrap: break-word;
          word-break: break-all;
          > img {
            width: 22px;
            height: 22px;
            margin: 6px 8px 6px 0px;
          }
        }
      }
    }
    .section-info--back {
      background: #fff;
      .section-info--flex {
        border-radius: 0 0 101px 0;
        background: linear-gradient(
          180deg,
          rgba(220, 236, 220, 0.45),
          rgba(220, 236, 220, 0.15)
        );
        padding-bottom: 36px;
      }
    }

    .section-info--data {
      box-sizing: border-box;
      display: flex;
      padding: 24px 30px 18px 50px;
      margin: 0 30px;
      border-radius: 30px 30px 71px 30px;
      background: #fff;
      .info-data--pressure,
      .info-data--oxygen {
        margin-right: 70px;
        color: #1bc59c;
        .data-pressure--value,
        .data-oxygen--value {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 140px;
          height: 140px;
          border-radius: 50%;
          border: 4px solid #dbf5f5;
          font-size: 32px;
          span.unit {
            font-size: 24px;
          }
        }
        .data-pressure--text,
        .data-oxygen--text {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 12px;
          font-size: 26px;
          line-height: 44px;
          img {
            margin-right: 6px;
            width: 26px;
            height: 26px;
          }
        }
      }

      .info-data--oxygen {
        color: #ef7f6e;
        .data-oxygen--value {
          border: 4px solid #fce6e2;
        }
      }

      .info-data--side {
        display: flex;
        align-items: center;
        .data-side--inside,
        .data-side--outside {
          display: flex;
          align-items: center;
          font-size: 32px;
          img {
            margin-right: 10px;
            width: 30px;
            height: 30px;
          }
        }
        .data-side--inside {
          color: #539af8;
        }
        .data-side--outside {
          color: #e02020;
        }
      }
    }
  }
  .section-echart {
    background: #ffffff;
    padding: 36px 30px 36px;
    position: relative;
    .echart-container {
      position: relative;
      width: 100%;
      height: 628px;
      background: #f7fcfd;
      border-radius: 30px;
      .echart-title {
        display: flex;
        align-items: center;
        position: absolute;
        top: 48px;
        left: 40px;
        color: #539af8;
        font-size: 26px;
        font-weight: bold;
        > img {
          width: 26px;
          height: 26px;
          margin-right: 6px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="entrustChild">
    <div class="banner">
      <img src="./img/banner.png" alt="" />
      <!-- <div class="detail">查看详情</div> -->
    </div>
    <div class="title">
      <div class="title-l">
        <div class="title-text">活动预约</div>
      </div>
      <div class="title-r" @click="myAppoint">
        <div class="txt">我要报名</div>
        <div class="img"><img src="./img/arrow.png" alt="" /></div>
      </div>
    </div>
    <div class="time" ref="time">
      <div
        v-for="(item, index) in timeList"
        :key="index"
        :class="['item', { active: index == oIndex }]"
        @click="selectTime(item, index)"
      >
        <div class="item-t">{{ item.key }}</div>
        <div class="item-b">{{ item.value }}</div>
      </div>
    </div>
    <div class="main">
      <div class="main-t">
        <div class="item" v-if="amList.length != 0">
          <div class="item-l">
            <div>
              <p>上</p>
              <p>午</p>
            </div>
          </div>
          <div class="item-c">
            <div
              class="list"
              v-for="(item, index) in postData"
              :key="index"
              v-show="item.timeDuring == '上午'"
            >
              <div class="list-l">
                <div class="list-l-c">
                  <p>{{ item.activityName }}</p>
                  <h3>
                    {{ `${item.activityBeginTime}~${item.activityEndTime}` }}
                  </h3>
                </div>
              </div>
              <div class="list-r">{{ item.activityDetail }}</div>
            </div>
          </div>
          <div class="item-r">
            <div class="item-r-c">
              <div class="btn">
                <!-- <div class="btn-l">预约结束</div> -->
                <div class="btn-r">
                  <div
                    :class="[
                      'opera1',
                      {
                        full:
                          amList[0].applicantsNum == amList[0].applicantsMax,
                      },
                    ]"
                    @click="toAppoint(amList[0].scheduleId)"
                    v-if="
                      amList[0].applicationStatus == '' &&
                      amList[0].applicantsNum != amList[0].applicantsMax &&
                      amList[0].orderStatus != '1'
                    "
                  >
                    预约
                  </div>
                  <div
                    class="opera1 full"
                    v-if="
                      amList[0].applicationStatus == '' &&
                      amList[0].applicantsNum != amList[0].applicantsMax &&
                      amList[0].orderStatus == '1'
                    "
                  >
                    已过期
                  </div>
                  <div class="opera2" v-if="amList[0].applicationStatus == '0'">
                    待审核
                  </div>
                  <div
                    class="opera3"
                    v-if="
                      amList[0].applicationStatus == '1' &&
                      returnTime(amList[0].dayName) != indexTime
                    "
                  >
                    已预约
                  </div>
                  <div
                    class="opera3"
                    v-if="
                      amList[0].applicationStatus == '1' &&
                      returnTime(amList[0].dayName) == indexTime
                    "
                    @click="toLook(amList[0].scheduleId)"
                  >
                    查看视频
                  </div>
                  <div
                    class="opera1"
                    v-if="amList[0].applicationStatus == '3'"
                    @click="evaluate(amList[0].scheduleId)"
                  >
                    评价
                  </div>
                  <div class="opera3" v-if="amList[0].applicationStatus == '4'">
                    已评价
                  </div>
                </div>
              </div>
              <div class="txt">
                <div
                  class="txt-c"
                  v-if="amList[0].applicantsNum != amList[0].applicantsMax"
                >
                  已预约{{ amList[0].applicantsNum }}人,剩{{
                    amList[0].applicantsMax - amList[0].applicantsNum
                  }}人
                </div>
                <div class="txt-c" v-else>已约满</div>
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="pmList.length != 0">
          <div class="item-l">
            <div>
              <p>下</p>
              <p>午</p>
            </div>
          </div>
          <div class="item-c">
            <div
              class="list"
              v-for="(item, index) in postData"
              :key="index"
              v-show="item.timeDuring == '下午'"
            >
              <div class="list-l">
                <div class="list-l-c">
                  <p>{{ item.activityName }}</p>
                  <h3>
                    {{ `${item.activityBeginTime}~${item.activityEndTime}` }}
                  </h3>
                </div>
              </div>
              <div class="list-r">{{ item.activityDetail }}</div>
            </div>
          </div>
          <div class="item-r">
            <div class="item-r-c">
              <div class="btn">
                <!-- <div class="btn-l">预约结束</div> -->
                <div class="btn-r">
                  <div
                    :class="[
                      'opera1',
                      {
                        full:
                          pmList[0].applicantsNum == pmList[0].applicantsMax,
                      },
                    ]"
                    @click="toAppoint(pmList[0].scheduleId)"
                    v-if="
                      pmList[0].applicationStatus == '' &&
                      pmList[0].applicantsNum != pmList[0].applicantsMax &&
                      pmList[0].orderStatus != '1'
                    "
                  >
                    预约
                  </div>
                  <div
                    class="opera1 full"
                    v-if="
                      pmList[0].applicationStatus == '' &&
                      pmList[0].applicantsNum != pmList[0].applicantsMax &&
                      pmList[0].orderStatus == '1'
                    "
                  >
                    已过期
                  </div>
                  <div
                    class="opera3"
                    v-if="
                      pmList[0].applicationStatus == '1' &&
                      returnTime(pmList[0].dayName) != indexTime
                    "
                  >
                    已预约
                  </div>
                  <div class="opera2" v-if="pmList[0].applicationStatus == '0'">
                    待审核
                  </div>
                  <div
                    class="opera3"
                    v-if="
                      pmList[0].applicationStatus == '1' &&
                      returnTime(pmList[0].dayName) == indexTime
                    "
                    @click="toLook(pmList[0].scheduleId)"
                  >
                    查看视频
                  </div>
                  <div
                    class="opera1"
                    v-if="pmList[0].applicationStatus == '3'"
                    @click="evaluate(pmList[0].scheduleId)"
                  >
                    评价
                  </div>
                  <div class="opera3" v-if="pmList[0].applicationStatus == '4'">
                    已评价
                  </div>
                </div>
              </div>
              <div class="txt">
                <div
                  class="txt-c"
                  v-if="pmList[0].applicantsNum != pmList[0].applicantsMax"
                >
                  已预约{{ pmList[0].applicantsNum }}人,剩{{
                    pmList[0].applicantsMax - pmList[0].applicantsNum
                  }}人
                </div>
                <div class="txt-c" v-else>已约满</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-empty v-if="amList.length == 0 && pmList.length == 0"></v-empty>
    </div>
    <div class="evaluateDialog" v-if="isShowEvaluate">
      <div class="dialogMain">
        <div class="mainBody">
          <div class="mainTitle">请为本次托幼活动打分（必填）</div>
          <div class="mainScore">
            <div class="rate" v-for="i in 5" :key="i">
              <div class="rateIcon">
                <img
                  @click="form.grade = i"
                  :src="
                    require(form.grade >= i
                      ? './img/starSelect.png'
                      : './img/star.png')
                  "
                  alt=""
                />
              </div>
              <div class="rateTxt">{{ scoreMap[i] }}</div>
            </div>
          </div>
          <div class="mainUpload">
            <v-upload
              class="oItem-b_upload"
              :imgUrl.sync="form.feedbackPicture"
              :activityPicture.sync="form.feedbackPicture"
              :maxCount="6"
              ref="load"
            ></v-upload>
            <div class="txt">最多可上传6张照片</div>
          </div>
          <div class="mainInput">
            <v-input
              v-model="form.feedbacksSuggestion"
              placeholder="评价理由"
              type="textarea"
              :maxlength="30"
              :showWordLimit="true"
            ></v-input>
          </div>
          <div class="submitBtn">
            <div class="btn" @click="evaluateSubmit">提交</div>
          </div>
        </div>
        <div class="mainClose" @click="evaluateClose">
          <img src="./img/close.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { weekArrMap } from "./map.js";
import { getClassDataByDayUrl, appraiseUrl } from "./api.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "entrustChild",
  data() {
    return {
      oIndex: 14,
      postData: [],
      amList: [],
      pmList: [],
      timeList: [],
      scheduleId: "",
      isShowEvaluate: false,
      indexTime: moment(new Date()).format("YYYY-MM-DD"),
      /* dialog */
      form: {
        grade: undefined,
        feedbackPicture: "",
        feedbacksSuggestion: "",
      },
      scoreMap: {
        1: "非常差",
        2: "差",
        3: "一般",
        4: "满意",
        5: "非常满意",
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async created() {
    // this.oIndex = this.$route.query.oIndex || this.oIndex;
    // console.log(this.oIndex, "oIndex");
    this.getTimeList();
    this.getPartyList(this.timeList[this.oIndex].goal);
    // if (await toRegister(this.$route.path, this.$route.query, "场馆预约")) {
    // }
  },
  mounted() {
    gloabalCount("", 57, 1);
    if (this.$refs.time && document.querySelector(".active")) {
      this.$refs.time.scrollTo(
        document.querySelector(".active").offsetLeft * 0.835,
        0
      );
    }
  },
  methods: {
    toDetail() {
      this.$router.push({
        name: "entrustChildDetail",
      });
    },
    // 我的预约
    myAppoint() {
      this.$router.push({
        name: "myEntrustChild",
      });
    },
    // 选择日期
    selectTime(item, index) {
      this.oIndex = index;
      this.postData = [];
      this.amList = [];
      this.pmList = [];
      this.getPartyList(item.goal);
    },
    // 查看视频
    toLook(scheduleId) {
      this.$router.push({
        name: "entrustChildVideo",
        query: {
          scheduleId: scheduleId,
        },
      });
    },
    // 评价
    evaluate(scheduleId) {
      this.scheduleId = scheduleId;
      this.isShowEvaluate = true;
    },
    // 去预约
    toAppoint(scheduleId) {
      this.$router.push({
        name: "entrustChildList",
        query: {
          scheduleId: scheduleId,
        },
      });
    },
    // 关闭评价弹框
    evaluateClose() {
      console.log(JSON.stringify(this.form), "--this.form");
      this.isShowEvaluate = false;
      this.form = {
        grade: undefined,
        feedbackPicture: "",
        feedbacksSuggestion: "",
      };
    },
    /* 辅助方法 */
    // 返回固定格式的时间
    returnTime(data) {
      if (data) {
        return moment(data).format("YYYY-MM-DD");
      }
    },
    // 获取前后15天内的日期信息
    getTimeList() {
      const timeList = [];
      let num = 15;
      // 往前15天
      for (let i = 1; i < num; i++) {
        let obj = {
          key: weekArrMap[
            moment(new Date())
              .subtract(num - i, "days")
              .weekday()
          ],
          value: moment(new Date())
            .subtract(num - i, "days")
            .format("MM.DD"),
          goal: moment(new Date())
            .subtract(num - i, "days")
            .format("YYYY-MM-DD"),
        };
        timeList.push(obj);
      }
      // 往后15天
      for (let i = 0; i < num; i++) {
        let obj = {
          key: weekArrMap[moment(new Date()).add(i, "days").weekday()],
          value: moment(new Date()).add(i, "days").format("MM.DD"),
          goal: moment(new Date()).add(i, "days").format("YYYY-MM-DD"),
        };
        timeList.push(obj);
      }
      this.timeList = timeList;
      console.log(this.timeList);
    },
    /* 接口 */
    // 查询对应日期的课程
    async getPartyList(dayData) {
      let params = {
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
        userId: this.userId,
        dayData,
      };
      this.amList = [];
      this.pmList = [];
      let res = await this.$axios.get(getClassDataByDayUrl, { params });
      if (res.code === 200) {
        this.postData = res.data;
        this.postData.forEach((ele) => {
          if (ele.timeDuring == "上午") {
            this.amList.push(ele);
          }
          if (ele.timeDuring == "下午") {
            this.pmList.push(ele);
          }
        });
      }
    },
    // 提交评价内容
    async evaluateSubmit() {
      if (!this.form.grade) {
        this.$toast({ message: "请先打分！", duration: 2000 });
        return;
      }
      let params = {
        userId: this.userId,
        scheduleId: this.scheduleId,
        ...this.form,
      };
      let res = await this.$axios.post(appraiseUrl, params);

      if (res.code === 200) {
        // 关闭评价弹框
        this.evaluateClose();
        this.getPartyList(this.timeList[this.oIndex].goal);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.entrustChild {
  height: 100vh;
  .banner {
    height: 319px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .detail {
      position: absolute;
      bottom: 52px;
      left: 42px;
      width: 200px;
      height: 60px;
      background: #ff9614;
      border-radius: 10px;
      font-weight: 500;
      text-align: center;
      font-size: 32px;
      color: #ffffff;
      line-height: 60px;
    }
  }
  .title {
    padding: 32px;
    display: flex;
    justify-content: space-between;
    .title-l {
      height: 44px;
      position: relative;
      font-size: 32px;
      font-weight: bold;
      color: #000000d9;
      line-height: 44px;
      .title-text {
        width: 174px;
        text-align: center;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 174px;
        height: 16px;
        background: #e2e2e2;
        border-radius: 7px;
      }
    }
    .title-r {
      display: flex;
      align-items: center;
      .txt {
        font-size: 28px;
        font-weight: 400;
        color: #1a1c34;
        line-height: 40px;
      }
      .img {
        width: 42px;
        height: 42px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .time {
    margin: 0 34px 50px;
    display: flex;
    // justify-content: center;
    text-align: center;
    height: 134px;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .item {
      box-sizing: border-box;
      position: relative;
      min-width: 104px;
      height: 100%;
      padding: 14px;
      border-radius: 8px;
      white-space: nowrap;
      &:not(:last-child) {
        margin-right: 32px;
      }
    }
    .item-t {
      font-size: 26px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
    }
    .item-b {
      margin-top: 14px;
      font-size: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 34px;
    }
    .active {
      background: #ff9614ff;
      .item-t,
      .item-b {
        color: #ffffffff;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 14px;
        left: 0;
        right: 0;
        margin: auto;
        width: 10px;
        height: 10px;
        background: #ffffff;
        border-radius: 50%;
      }
    }
  }
  .main {
    padding: 0 34px 30px;
    .item {
      display: flex;
      background: #ffffff;
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      margin-bottom: 30px;
      .item-l {
        width: 84px;
        border-right: 1px solid rgba(0, 0, 0, 0.06);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-weight: 600;
        color: #151c39;
        line-height: 50px;
      }
      .item-c {
        flex: 1;
        .list {
          display: flex;
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
          .list-l {
            flex: 1;
            flex-shrink: 0;
            padding: 14px 16px;
            box-sizing: border-box;
          }
          .list-r {
            flex: 1;
            flex-shrink: 0;
            padding: 14px 16px;
            box-sizing: border-box;
          }
          .list-l {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 400;
            color: #151c39;
            line-height: 34px;
            border-right: 1px solid rgba(0, 0, 0, 0.06);
          }
          .list-r {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: 400;
            color: #151c39;
            line-height: 34px;
          }
        }
      }
      .item-r {
        width: 182px;
        border-left: 1px solid rgba(0, 0, 0, 0.06);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .btn {
          .btn-l {
            font-size: 28px;
            font-weight: 400;
            color: #a8a8a8;
            line-height: 40px;
          }
          .btn-r {
            .opera1 {
              width: 124px;
              background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
              border-radius: 10px;
              font-size: 24px;
              font-weight: 500;
              color: #ffffff;
              line-height: 52px;
              margin: 0 auto;
              &.full {
                background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
                color: #a7a7a7;
              }
            }
            .opera2 {
              width: 124px;
              border: 2px solid #ff9614;
              border-radius: 10px;
              font-size: 28px;
              font-weight: bold;
              color: #ff9614;
              line-height: 48px;
              margin: 0 auto;
            }
            .opera3 {
              font-size: 24px;
              font-weight: bold;
              color: #ff9614;
              line-height: 52px;
            }
          }
        }
        .txt {
          margin-top: 8px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 28px;
        }
      }
    }
  }
}
.evaluateDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  .dialogMain {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    white-space: nowrap;
    box-sizing: border-box;
    width: 656px;
    .mainBody {
      padding: 74px 34px 46px;
      border-radius: 16px;
      background: #fff;
      overflow: auto;
      max-height: calc(100vh - 286px);
      .mainTitle {
        font-size: 32px;
        // font-weight: bold;
        color: #333333;
        line-height: 44px;
      }
      .mainScore {
        display: flex;
        padding: 18px 14px 24px;
        white-space: nowrap;
        .rate {
          margin-right: 48px;
          text-align: center;
          .rateIcon {
            // width: 48px;
            height: 48px;
            margin-bottom: 12px;
            img {
              width: 48px;
              height: 48px;
              overflow: hidden;
              vertical-align: middle;
            }
          }
          .rateTxt {
            display: flex;
            justify-content: center;
            width: 48px;
            font-size: 24px;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
          }
        }
      }
      .mainUpload {
        ::v-deep .van-uploader__upload {
          width: 140px;
          height: 140px;
        }
        ::v-deep .van-uploader__preview {
          border: 0;
          .van-uploader__preview-image {
            width: 140px;
            height: 140px;
          }
          .van-uploader__preview-delete {
            width: 28px;
            height: 28px;
            border-radius: 0 0 0 24px;
            .van-uploader__preview-delete-icon {
              position: absolute;
              top: -7px;
              right: -7px;
              display: block;
            }
          }
        }
        .txt {
          margin-top: 14px;
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
        }
      }
      .mainInput {
        margin-top: 26px;
        ::v-deep .van-field {
          background: rgba(0, 0, 0, 0.05);
          border-radius: 16px;
          .van-field__word-limit {
            color: rgba(0, 0, 0, 0.25);
          }
        }
      }
      .submitBtn {
        margin-top: 90px;
        width: 100%;
        display: flex;
        justify-content: center;
        .btn {
          width: 550px;
          height: 66px;
          font-size: 28px;
          line-height: 66px;
          text-align: center;
          font-weight: bold;
          border-radius: 10px;
          color: #ffffff;
          background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
          &.light {
          }
        }
      }
    }
    .mainClose {
      width: 76px;
      height: 76px;
      margin: 62px auto 0;
      > img {
        width: 76px;
        height: 76px;
      }
    }
  }
}
</style>

<template>
  <div class="page" ref="page" @click="nowIndex = undefined">
    <div class="message-box" ref="content">
      <div class="item" v-for="(t, i) in list" :key="i">
        <div class="time">{{ t.createTime }}</div>
        <!-- 自己 -->
        <div
          class="item-blue"
          @touchstart="gtouchstart(t, i)"
          @touchmove="gtouchmove()"
          @touchend="showDeleteButton(t, i)"
          v-if="t.direction == '1'"
        >
          <div class="content">
            <div class="text">{{ t.leaveWordContent.text }}</div>
            <div
              class="imgBox"
              v-if="
                t.leaveWordContent.imgs.length > 0 &&
                t.leaveWordContent.imgs[0] != ''
              "
            >
              <img
                class="textImg"
                v-for="(item, index) in t.textImgs"
                :src="item"
                :key="index"
                @click="showImgClick(t.leaveWordContent.imgs, index)"
              />
            </div>
            <img class="arrow" src="./img/blue-arrow.png" alt="" />
            <img
              v-if="i == nowIndex"
              class="arrow2"
              src="./img/black-arrow.png"
              alt=""
            />
            <div v-if="i == nowIndex" class="operation-box">
              <div class="txt" @click.stop="messageWithdraw(t, i)">删除</div>
            </div>
          </div>
          <img
            class="headImg"
            :src="t.avatar || require('@/assets/img/default_avatar.png')"
            alt=""
          />
        </div>
        <!-- 对方 -->
        <div class="item-grey" v-else>
          <img
            class="headImg"
            :src="t.avatar || require('@/assets/img/default_avatar.png')"
            alt=""
          />
          <div class="content">
            <div class="text">{{ t.leaveWordContent.text }}</div>
            <div
              class="imgBox"
              v-if="
                t.leaveWordContent.imgs.length > 0 &&
                t.leaveWordContent.imgs[0] != ''
              "
            >
              <img
                class="textImg"
                v-for="(item, index) in t.textImgs"
                :src="item"
                :key="index"
                @click="showImgClick(t.leaveWordContent.imgs, index)"
              />
            </div>
            <img class="arrow" src="./img/grey-arrow.png" alt="" />
            <img
              v-if="i == nowIndex"
              class="arrow2"
              src="./img/black-arrow.png"
              alt=""
            />
            <div v-if="i == nowIndex" class="operation-box">
              <div class="txt" @click.stop="messageWithdraw(t, i)">删除</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 最底部box -->
      <div class="bottomBox" ref="bottomBox"></div>
    </div>
    <v-comments
      btnColor="#fed661"
      ref="vComments"
      v-model="text"
      isAutoClose
      :show.sync="isShowComments"
      @acceptSend="acceptSend(arguments)"
      :showUploadImgBtn="showUploadImgBtn"
      :isShowOverlay="false"
      maxlength="50000"
      :autosize="autoSizeObj"
      zIndex="0"
      top="none"
    ></v-comments>
    <v-picList
      :startPosition="startPosition"
      v-model="showImg"
      :imgList="picList"
    >
    </v-picList>
  </div>
</template>

<script>
import { handleImg } from "@/utils/utils.js";
import { mapState } from "vuex";
import { vComments } from "@/components/control/index";
import { getDetailsListUrl, deleteLeaveWordUrl, leaveWordsUrl } from "./api";
export default {
  name: "v-chatRoom",
  components: {
    vComments,
  },
  data() {
    return {
      autoSizeObj: { maxHeight: "100", minHeight: "" }, //留言文本框高度
      startPosition: 0, //图片预览组件默认图片索引
      showImg: false, //是否展示图片组件zhanshi
      picList: [], //图片预览组件图片list
      isShowComments: true, //是否展示评论组件，默认展示
      showUploadImgBtn: true, //是否展示上传图片按钮
      text: "", //留言发送文字内容
      list: [], //聊天记录
      nowIndex: undefined, //当前长按数据索引

      id: null,

      // 鼠标长按/获取聊天记录接口 定时器
      timeOutEvent: null,
      timeClose: null,
    };
  },
  props: {
    //聊天室type：1好物，2法制课堂
    chatRoomType: {
      type: Number,
      default: null,
    },
    // 留言发送额外接口传参
    sendParams: {
      type: [Object],
      default: () => {
        return {};
      },
    },
    // 获取聊天记录额外接口传参
    getListParams: {
      type: [Object],
      default: () => {
        return {};
      },
    },
    // 留言删除额外接口传参
    delMsgParams: {
      type: [Object],
      default: () => {
        return {};
      },
    },
  },
  created() {
    sessionStorage.setItem("loadingFlag", true);
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async mounted() {
    await this.getList();
    let that = this;
    that.timeClose = setInterval(function () {
      that.getList(); //每5秒调用一次获取聊天记录接口
    }, 5000);
    that.scrollToBottom(); //一进入页面滚动条滚动到底部
  },
  beforeDestroy() {
    sessionStorage.removeItem("loadingFlag");
    clearInterval(this.timeClose);
  },
  methods: {
    // 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        this.$refs.bottomBox.scrollIntoView();
      });
    },
    // 对话中的点击展示图片预览组件
    showImgClick(list, index) {
      this.showImg = true;
      this.picList = list;
      this.startPosition = index;
    },
    // 留言发送
    async acceptSend(data) {
      // 留言只有一个字段，将leaveWordContent(留言内容字段)转为json字符串，展示时额外处理
      let obj = {
        text: "",
        imgs: "",
      };
      if (data[1].length > 0) {
        obj.imgs = data[1]; //图片
      }
      obj.text = this.text; //文字
      if (data[0].length == 0 && data[1].length == 0) {
        this.$toast({
          message: "请输入内容或上传图片",
          duration: 1500,
        });
        return;
      }
      let params1 = {
        id: this.id,
        userId: this.userId,
        tenantId: this.tenantId,
        leaveWordContent: JSON.stringify(obj),
        leaveWordType: this.chatRoomType,
      };
      let params2 = this.sendParams;
      let params = { ...params1, ...params2 };
      let res = await this.$axios.post(`${leaveWordsUrl}`, params);
      if (res.code == 200) {
        this.text = "";
        await this.getList();
        this.scrollToBottom(); //一进入页面滚动条滚动到底部
      }
    },
    // 获取聊天列表数据
    async getList(type) {
      let params1 = {
        userId: this.userId,
        tenantId: this.tenantId,
        leaveWordType: this.chatRoomType,
      };
      let params2 = this.getListParams;
      let params = { ...params1, ...params2 };
      if (this.chatRoomType == 1) {
        //闲置交易模块的额外处理
        params.id = params2.id || this.id;
      }
      let res = await this.$axios.get(`${getDetailsListUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        if (type == 1) {
          res.data.details.forEach((item) => {
            item.leaveWordContent = JSON.parse(item.leaveWordContent);
            item.textImgs = handleImg(
              120,
              120,
              item.leaveWordContent.imgs
            ).split(",");
          });
          this.list = res.data.details;
        } else {
          const list = this.list.map((v) => v.id);
          res.data.details.forEach((item) => {
            item.leaveWordContent = JSON.parse(item.leaveWordContent);
            item.textImgs = handleImg(
              120,
              120,
              item.leaveWordContent.imgs
            ).split(",");
            item.leaveWordContent.imgs = item.leaveWordContent.imgs.split(",");
            list.includes(item.id) || this.list.push(item);
          });
        }
        this.id = res.data.id;
      }
    },
    // 删除
    messageWithdraw(item, index) {
      let params1 = {
        type: 2,
        leaveWordId: item.id,
      };
      let params2 = this.delMsgParams;
      let params = { ...params1, ...params2 };
      this.$axios.post(`${deleteLeaveWordUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.nowIndex = undefined;
          this.getList(1);
        }
      });
    },
    //==============================================================
    //长按事件（起始）
    gtouchstart(item, index) {
      var self = this;
      this.timeOutEvent = setTimeout(function () {
        self.longPress(item, index);
      }, 500); //这里设置定时器，定义长按500毫秒触发长按事件
      return false;
    },
    //手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
    showDeleteButton(item, index) {
      clearTimeout(this.timeOutEvent); //清除定时器
      if (this.timeOutEvent != 0) {
        //这里写要执行的内容（如onclick事件）
        console.log("点击但未长按");
      }
      return false;
    },
    //如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按
    gtouchmove() {
      clearTimeout(this.timeOutEvent); //清除定时器
      this.timeOutEvent = 0;
    },
    //真正长按后应该执行的内容
    longPress(item, index) {
      this.timeOutEvent = 0;
      //执行长按要执行的内容，如弹出菜单
      console.log("长按");
      this.nowIndex = index;
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 300px;
  padding-top: 30px;
  background: #f9f9f9;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }
  .message-box {
    padding: 0 32px;
    padding-bottom: 80px;
    box-sizing: border-box;
    .item {
      .time {
        text-align: center;
        border-radius: 17px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-bottom: 30px;
      }
      .item-grey {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 32px;
        .content {
          max-width: 434px;
          padding: 16px 26px;
          background: #f5f5f5;
          box-sizing: border-box;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border-radius: 12px;
          line-height: 28px;
          position: relative;
          .text {
            word-break: break-all;
          }
          .imgBox {
            margin-top: 15px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .textImg {
              margin-right: 5px;
              width: 120px;
              height: 120px;
              display: block;
            }
          }

          .operation-box {
            position: absolute;
            width: 83px;
            height: 47px;
            background: rgba(0, 0, 0, 0.85);
            bottom: -58px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 12px;
            z-index: 100;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            .line {
              width: 2px;
              height: 16px;
              background: #ffffff;
              margin: 0 20px;
            }
          }
          .arrow {
            position: absolute;
            left: -12px;
            top: 12px;
            width: 15px;
            height: 28px;
          }
          .arrow2 {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -12px;
            width: 24px;
            height: 12px;
          }
        }
        .headImg {
          margin-right: 20px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }
      .item-blue {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 32px;
        .content {
          max-width: 434px;
          padding: 16px 26px;
          background: #dcedfe;
          box-sizing: border-box;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          border-radius: 12px;
          line-height: 28px;
          position: relative;
          .text {
            word-break: break-all;
          }
          .imgBox {
            margin-top: 15px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            .textImg {
              margin-right: 5px;
              width: 120px;
              height: 120px;
              display: block;
            }
          }
          .operation-box {
            position: absolute;
            width: 83px;
            height: 47px;
            background: rgba(0, 0, 0, 0.85);
            bottom: -58px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 12px;
            z-index: 100;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            .line {
              width: 2px;
              height: 16px;
              background: #ffffff;
              margin: 0 20px;
            }
          }
          .arrow {
            position: absolute;
            right: -12px;
            top: 12px;
            width: 15px;
            height: 28px;
          }
          .arrow2 {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -12px;
            width: 24px;
            height: 12px;
          }
        }
        .headImg {
          margin-left: 20px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }
    }
  }
  .bottom-box {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    height: 170px;
    padding: 20px 26px 76px 32px;
    box-sizing: border-box;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    img {
      width: 50px;
      margin-left: 24px;
    }
    input {
      flex: 1;
      height: 100%;
      padding: 0 34px;
      box-sizing: border-box;
      background: #f9f9f9;
      border-radius: 37px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

import { mapHelper } from "@/utils/utils.js";

// 团购类型
const buyType = [
  {
    value: 1,
    text: "社区团购",
  },
  {
    value: 2,
    text: "限时秒杀",
  },
];

const { map: buyTypeMap, setOps: setBuyTypeOps } = mapHelper.setMap(buyType);

// 发布商品状态
const status = [
  {
    value: 1,
    text: "未开始",
  },
  {
    value: 2,
    text: "进行中",
  },
  {
    value: 3,
    text: "已结束",
  },
  {
    value: 99,
    text: "未成团",
  },
];

const { map: statusMap, setOps: statusOps } = mapHelper.setMap(status);

// 	主体类型
const principalType = [
  {
    value: 1,
    text: "个体",
  },
  {
    value: 2,
    text: "企业",
  },
];

const { map: principalTypeMap, setOps: setPrincipalTypeOps } =
  mapHelper.setMap(principalType);

// 	经营类型
const accountType = [
  {
    value: 1,
    text: "对公银行账户",
  },
  {
    value: 2,
    text: "经营者个人银行卡",
  },
];

const { map: accountTypeMap, setOps: setAccountTypeOps } =
  mapHelper.setMap(accountType);

export {
  buyTypeMap,
  setBuyTypeOps,
  statusMap,
  statusOps,
  principalTypeMap,
  setPrincipalTypeOps,
  accountTypeMap,
  setAccountTypeOps,
};

<template>
  <div class="page">
    <v-tabs v-model="status" sticky @change="changeTabs">
      <v-tab v-for="(tab, index) in tabs" :key="index" :title="tab.title" :name="tab.name" titleClass="tab-title">
        <activity v-if="tab.name === '1'"></activity>
        <graceful v-else></graceful>
      </v-tab></v-tabs>
  </div>
</template>

<script>
import graceful from "./expert-graceful";
import activity from "./expert-activity";
export default {
  name: "partnerStyleList",
  data() {
    return {
      status: 1,
      tabs: [
        {
          title: "达人活动",
          name: "1",
        },
        {
          title: "达人风采",
          name: "2",
        },
      ],
    };
  },
  components: {
    graceful,
    activity
  },
  created() { },
  mounted() { },
  methods: {
    changeTabs() { },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f5f5f5;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
}
</style>

<template>
  <div class="page">
    <div class="section-device">
      <div class="section-flex" @click="toAddRouter">
        <span>添加设备</span>
        <img src="./img/addDevice.png" alt="" />
      </div>
    </div>
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="section-list">
        <v-list
          :finished="finished"
          :finishedText="finishedText"
          @onLoad="onLoad"
          ref="list"
        >
          <div
            class="list-content"
            v-for="(item, index) in listData"
            :key="index"
            @click="toDetailRouter(item)"
          >
            <div class="list-flex">
              <div class="section-info">
                <div class="section-name">
                  <span>{{ item.name }}</span>
                  <img
                    v-if="item.inFences != 1"
                    src="./img/notice.png"
                    alt=""
                  />
                </div>
                <div class="section-status">
                  <div class="status-inside" v-if="item.inFences == 1">
                    <img src="./img/inside.png" alt="" />
                    <span>围栏内</span>
                  </div>
                  <div class="status-outside" v-else>
                    <img src="./img/outside.png" alt="" />
                    <span>围栏外</span>
                  </div>
                </div>
              </div>
              <div class="section-detail">
                <div class="section-detail--heart">
                  <div class="detail-flex">
                    <img src="./img/heart.png" alt="" />
                    <div class="detail-name">心率</div>
                  </div>
                  <div class="detail-data">
                    <span>
                      {{
                        item.heartrateInfo &&
                        item.heartrateInfo.heartrate !== undefined
                          ? item.heartrateInfo.heartrate
                          : "--"
                      }}{{
                        item.heartrateInfo &&
                        item.heartrateInfo.heartrateUnit !== undefined
                          ? item.heartrateInfo.heartrateUnit
                          : ""
                      }}
                    </span>
                  </div>
                </div>
                <div class="section-detail--pressure">
                  <div class="detail-flex">
                    <img src="./img/pressure.png" alt="" />
                    <div class="detail-name">血压</div>
                  </div>
                  <div class="detail-data">
                    <span>
                      {{
                        item.bpInfo && item.bpInfo.sbp !== undefined
                          ? `${item.bpInfo.sbp}/${item.bpInfo.dbp}`
                          : "--"
                      }}{{
                        item.bpInfo && item.bpInfo.bpUnit !== undefined
                          ? item.bpInfo.bpUnit
                          : ""
                      }}
                    </span>
                  </div>
                </div>
                <div class="section-detail--oxygen">
                  <div class="detail-flex">
                    <img src="./img/oxygen.png" alt="" />
                    <div class="detail-name">血氧</div>
                  </div>
                  <div class="detail-data">
                    <span>
                      {{
                        item.bloodoxygenInfo &&
                        item.bloodoxygenInfo.bloodoxygen !== undefined
                          ? `${item.bloodoxygenInfo.bloodoxygen}`
                          : "--"
                      }}{{
                        item.bloodoxygenInfo &&
                        item.bloodoxygenInfo.bloodoxygenUnit !== undefined
                          ? item.bloodoxygenInfo.bloodoxygenUnit
                          : ""
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-unbind" @click.stop="unbind(item)">
              <span>解绑</span>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>

    <div class="dialog-tips" v-if="isUnbindDialog">
      <div class="section-tips">
        <div class="section-tips--icon" @click="isUnbindDialog = false">
          <img src="./img/cancal.png" alt="" />
        </div>
        <div class="section-tips--content">
          解绑后手表将会重置，是否确认解除绑定？
        </div>
        <div class="section-tips--footer">
          <div class="footer-cancel" @click="isUnbindDialog = false">取 消</div>
          <div @click="unbindConfirm" class="footer-confirm">确 定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { fetchDeviceListURL, unbindURL } from "./api";

export default {
  data() {
    return {
      isLoading: false,
      finished: false, // 列表是否加载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curPage: 1,
      pageSize: 10,
      isUnbindDialog: false,
      curItem: null, // 当前需要解绑数据
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    // sessionStorage.setItem("loadingFlag", true);
  },
  methods: {
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 添加设备
    toAddRouter() {
      this.$router.push({
        name: "elderCareBraceletAdd",
      });
    },
    // 设备详情
    toDetailRouter(item) {
      this.$router.push({
        name: "elderCareBraceletDetailsTabs",
        query: {
          imei: item.imei,
          deviceId: item.id,
          name: item.name,
        },
      });
    },
    // 加载列表数据
    onLoad() {
      if (!this.userId || this.userId == "0") {
        this.finishedText = "";
        this.isLoading = false;
        this.finished = true;
        return;
      }
      let params = {
        userId: this.userId,
        curPage: this.curPage,
        pageSize: this.pageSize,
      };
      this.$axios
        .get(fetchDeviceListURL, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                // res.data.records.forEach((item) => {
                //   if (item.topicPicture) {
                //     item.topicPicture = this.$handleImg(
                //       256,
                //       256,
                //       item.topicPicture
                //     );
                //   }
                // });
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // if (item.topicPicture) {
                  //   item.topicPicture = this.$handleImg(
                  //     256,
                  //     256,
                  //     item.topicPicture
                  //   );
                  // }
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 解除绑定
    unbind(item) {
      this.curItem = item;
      this.isUnbindDialog = true;
    },
    unbindConfirm() {
      this.$axios
        .post(`${unbindURL}`, {
          userId: this.userId,
          deviceId: this.curItem.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.isUnbindDialog = false;
            this.$toast({
              message: "解绑成功",
              duration: 1500,
            });
            // setTimeout(() => {
            this.onRefresh();
            // }, 1500);
            return;
          }
          this.$toast({
            message: res.msg || "解绑失败，请稍后重试！",
            duration: 2000,
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  position: relative;
  min-height: 100%;
  padding: 130px 30px 0;
  background: #f9f9f9;
  /* 兼容 iOS < 11.2 */
  padding-bottom: calc(64px + constant(safe-area-inset-bottom));
  /* 兼容 iOS >= 11.2 */
  padding-bottom: calc(64px + env(safe-area-inset-bottom));
  ::v-deep .van-pull-refresh__track {
    min-height: calc(100vh - 256px);
  }
  .section-device {
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    background: #fff;
    height: 100px;
    width: 100%;
    padding: 0 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .section-flex {
      display: flex;
      align-items: center;
      span {
        font-size: 32px;
        color: #89ae89;
      }
      img {
        margin-left: 12px;
        width: 40px;
        height: 40px;
        overflow: hidden;
      }
    }
  }
  .section-list {
    .list-content {
      position: relative;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 4px 16px 0px rgba(228, 228, 228, 0.5);
      overflow: hidden;
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      .list-flex {
        padding: 40px 36px 40px;
        .section-info {
          .section-name {
            display: flex;
            align-items: center;
            width: 50%;
            height: 60px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 36px;
            font-weight: bold;
            line-height: 60px;
            color: rgba(0, 0, 0, 0.85);
            img {
              margin-left: 14px;
              width: 32px;
              height: 32px;
            }
          }
          .section-status {
            font-size: 26px;
            color: #48b17c;
            width: 100%;
            .status-inside,
            .status-outside {
              height: 44px;
              display: flex;
              align-items: center;
              line-height: 44px;
              color: #e02020;
            }
            .status-inside {
              color: #48b17c;
            }
            img {
              width: 22px;
              height: 22px;
              margin-right: 8px;
            }
          }
        }
        .section-detail {
          margin-top: 50px;
          display: flex;
          .section-detail--heart,
          .section-detail--pressure,
          .section-detail--oxygen {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:not(:first-child) {
              margin-left: 6%;
            }
            .detail-flex {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #8f8f8f;
              font-size: 28px;
              height: 40px;
              img {
                margin-top: 4px;
                width: 28px;
                height: 28px;
              }
              .detail-name {
                margin-left: 6px;
              }
            }
            .detail-data {
              flex: 1;
              width: 100%;
              margin-top: 20px;
              color: #000000;
              font-size: 32px;
              display: flex;
              align-items: flex-end;
              justify-content: center;
            }
          }
          // .section-detail--heart {
          //   width: 180px;
          // }
          // .section-detail--pressure {
          //   flex: 1;
          // }
          // .section-detail--oxygen {
          //   width: 90px;
          // }
        }
      }
      .list-unbind {
        box-sizing: border-box;
        position: absolute;
        right: 0;
        top: 0;
        height: 60px;
        width: 150px;
        padding: 0 34px 0 54px;
        letter-spacing: 2px;
        background: rgba(236, 241, 238, 1);
        line-height: 60px;
        font-size: 28px;
        color: rgba(137, 174, 137, 1);
        border-radius: 0 16px 0 60px;
      }
    }
  }

  .dialog-tips {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(0, 0, 0, 0.17);
    display: flex;
    align-items: center;
    justify-content: center;
    .section-tips {
      position: relative;
      margin: 0 38px;
      background: #ffffff;
      border-radius: 18px;
      // position: absolute;
      // top: 0;
      // bottom: 0;
      // left: 0;
      // right: 0;
      // margin: auto;
      padding: 144px 48px 68px;
      .section-tips--icon {
        position: absolute;
        right: 32px;
        top: 34px;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .section-tips--content {
        box-sizing: border-box;
        width: 100%;
        min-height: 140px;
        margin-bottom: 10px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 36px;
        font-weight: bold;
        // white-space: nowrap;
        // text-align: center;
      }
      .section-tips--footer {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .footer-cancel,
        .footer-confirm {
          box-sizing: border-box;
          width: 230px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 28px;
          font-weight: bold;
          color: #bddabd;
          border: 2px solid #bddabd;
          border-radius: 10px;
        }
        .footer-confirm {
          margin-left: 40px;
          background: #bddabd;
          color: #ffffff;
        }
      }
    }
  }
}
</style>

// 通过用户ID查询设备分页列表接口
const fetchDeviceListURL = `/gateway/hc-health/miniapi/watchDevice/fetchDeviceList`;
// 通过设备ID解除绑定接口
const unbindURL = `/gateway/hc-health/miniapi/watchDevice/unbind`;
// 通过设备IMEI绑定设备接口
const bindURL = `/gateway/hc-health/miniapi/watchDevice/bind`;

// 通过设备ID查询用户详情接口
const fetchDeviceInfoByIdURL = `/gateway/hc-health/miniapi/watchDevice/fetchDeviceInfoById`;
// 发送定位指令
const sendLocationCommandURL = `/gateway/hc-health/miniapi/watchDevice/sendLocationCommand`;
// 获取主动定位结果详情
const getLocationDetailsURL = `/gateway/hc-health/miniapi/watchDevice/getLocationResult`;

// 通过设备ID查询设备的异常记录f分页列表接口
const fetchDeviceAbnormalListURL = `/gateway/hc-health/miniapi/watchDevice/fetchDeviceAbnormalList`;

// 获取轨迹开关接口
const getTrackSwitchURL = `/gateway/hc-health/miniapi/watchDevice/getTrackSwitch`;
// 打开/关闭 活动轨迹接口
const onOffTrackEnableURL = `/gateway/hc-health/miniapi/watchDevice/onOffTrackEnable`;
// 获取轨迹列表接口
const getTrackListURL = `/gateway/hc-health/miniapi/watchDevice/getTrackList`;
// 紧急号码列表查看接口
const getEmergencyContactListURL = `/gateway/hc-health/miniapi/watchDevice/getEmergencyContactList`;
// 紧急号码新增接口
const addEmergencyContactURL = `/gateway/hc-health/miniapi/watchDevice/addEmergencyContact`;
// 紧急号码新增接口
const deleteEmergencyContactURL = `/gateway/hc-health/miniapi/watchDevice/deleteEmergencyContact`;

// 查看安全围栏接口
const getSecurityZoneURL = `/gateway/hc-health/miniapi/watchDevice/getSecurityZone`;
// 设置安全围栏接口
const settingSecurityZoneURL = `/gateway/hc-health/miniapi/watchDevice/settingSecurityZone`;

//获取微信jssdk初始化
const initWxJsSdkURL = `/gateway/hc-mini/qrcode/getPASignature`;

export {
  fetchDeviceListURL,
  unbindURL,
  bindURL,
  fetchDeviceInfoByIdURL,
  sendLocationCommandURL,
  getLocationDetailsURL,
  fetchDeviceAbnormalListURL,
  getTrackSwitchURL,
  onOffTrackEnableURL,
  getTrackListURL,
  getEmergencyContactListURL,
  addEmergencyContactURL,
  deleteEmergencyContactURL,
  initWxJsSdkURL,
  getSecurityZoneURL,
  settingSecurityZoneURL,
};

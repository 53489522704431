<template>
  <div class="page">
    <div class="banner">
      <img src="./img/banner.png" alt="" />
    </div>
    <div class="list">
      <div class="item" v-for="(t, i) in list" :key="i">
        <div class="left"><img :src="t.stallPhoto" alt="" /></div>
        <div class="right">
          <div class="txt">{{ t.stallName }}</div>
          <div class="txt2">主营：{{ t.laserLevel }}</div>
          <div class="txt3">联系人：{{ t.contact }}</div>
          <div class="txt4">联系方式：{{ t.contactNumber }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handleImg } from "@/utils/utils.js";
import { getListUrl } from "./api.js";
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  name: "jinzhongAgriculturalTrade",
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    gloabalCount("", 94, 1);
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        curPage: 1,
        pageSize: 500,
      };
      this.$axios.get(`${getListUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          res.data.records.forEach((item) => {
            if (item.stallPhoto) {
              item.stallPhoto = handleImg(256, 256, item.stallPhoto);
            }
          });
          this.list = res.data.records;
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100%;
  box-sizing: border-box;
  padding: 30px 30px 68px;
  .list {
    .item {
      width: 100%;
      height: 256px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      display: flex;
      overflow: hidden;
      margin-bottom: 20px;
      .right {
        flex: 1;
        height: 100%;
        padding: 28px 22px 26px;
        box-sizing: border-box;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        .txt,
        .txt2,
        .txt3 {
          margin-bottom: 14px;
        }
      }
      .left {
        width: 256px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .banner {
    width: 100%;
    height: 334px;
    border-radius: 14px;
    margin-bottom: 26px;
    img {
      width: 100%;
    }
  }
}
</style>
const routes = [
  //防疫登记
  //首页
  {
    path: "/futureTreat/epidemicPrevent",
    name: "epidemicPrevent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/epidemicPrevent/index.vue"], resolve),
  },
  //防疫资讯
  {
    path: "/futureTreat/epidemicNews",
    name: "epidemicNews",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicNews.vue",
      ], resolve),
  },
  //防疫登记
  {
    path: "/futureTreat/epidemicRegister",
    name: "epidemicRegister",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/epidemicPrevent/epidemicRegister.vue",
      ], resolve),
  },
  //防疫登记
  {
    path: "/futureTreat/epidemicRes",
    name: "epidemicRes",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/epidemicPrevent/epidemicRes.vue"], resolve),
  },

  /* 金钟党建-仅金钟社区 */
  {
    path: "/futureTreat/partyBuildingNew",
    name: "partyBuildingNew",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/partyBuildingNew/index.vue"], resolve),
  },
  {
    path: "/futureTreat/partyBuildingStage",
    name: "partyBuildingStage",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/partyBuildingStage.vue",
      ], resolve),
  },
  // 红色引领
  {
    path: "/futureTreat/redLead",
    name: "redLead",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTreat/partyBuildingNew/redLead.vue"], resolve),
  },
  // 红网微治
  {
    path: "/futureTreat/redNetMicroTreat",
    name: "redNetMicroTreat",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/redNetMicroTreat/index.vue",
      ], resolve),
  },
  // 添加页面
  {
    path: "/futureTreat/redNetMicroTreatAdd",
    name: "redNetMicroTreatAdd",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/redNetMicroTreat/redNetMicroTreatAdd.vue",
      ], resolve),
  },
  // 睦邻汇
  {
    path: "/futureTreat/neighborlyPool",
    name: "neighborlyPool",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/neighborlyPool/index.vue",
      ], resolve),
  },
  // 联建单位详情
  {
    path: "/futureTreat/batUnitDetail",
    name: "batUnitDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/neighborlyPool/batUnitDetail.vue",
      ], resolve),
  },
  // 在线学习
  {
    path: "/futureTreat/onlineLearn",
    name: "onlineLearn",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/onlineLearn/index.vue",
      ], resolve),
  },
  // 先锋任务
  {
    path: "/futureTreat/pioneerMission",
    name: "pioneerMission",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/pioneerMission/index.vue",
      ], resolve),
  },
  // 先锋任务签到签名
  {
    path: "/futureTreat/pioneerMissionSign",
    name: "pioneerMissionSign",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/pioneerMission/pioneerMissionSign.vue",
      ], resolve),
  },
  // 报名
  {
    path: "/futureTreat/pioneerMissionSure",
    name: "pioneerMissionSure",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/pioneerMission/pioneerMissionSure.vue",
      ], resolve),
  },
  // 报名成功
  {
    path: "/futureTreat/pioneerMissionComplete",
    name: "pioneerMissionComplete",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/pioneerMission/pioneerMissionComplete.vue",
      ], resolve),
  },
  // 金邻在线拍照
  {
    path: "/futureTreat/neighborOnlinePictures",
    name: "neighborOnlinePictures",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureTreat/partyBuildingNew/pioneerMission/neighborOnlinePictures.vue",
      ], resolve),
  },
  /* end */
  {
    path: "/futureTreat/aCommunityMap",
    name: "ACommunityMap",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/futureTreat/communityAMap/aCommunityMap.vue"], resolve),
  },
];

export default routes;

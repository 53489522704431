<template>
  <div class="areaAppoint">
    <div class="areaAppoint-t">
      <div class="tips">
        <div class="tips-l"><img src="./img/sound-fill.png" alt="" /></div>
        <div class="tips-r">如遇法定节假日，请先与管理员确认场地是否开放</div>
      </div>
      <div class="searchBox">
        <div class="search">
          <div class="search-l" @click="showDate">
            <div class="item-l">{{ requestData.rentSpaceTypeTxt }}</div>
            <div class="item-r">
              <img
                :class="['arrow', { downArrow: show }]"
                src="./img/downarrow.png"
                alt=""
              />
            </div>
          </div>
          <div class="search-c" @click="showType">
            <div class="item-l">{{ requestData.spaceSizeTxt }}</div>
            <div class="item-r">
              <img
                :class="['arrow', { downArrow: pickShow }]"
                src="./img/downarrow.png"
                alt=""
              />
            </div>
          </div>
          <div class="search-r" @click="showPeople">
            <div class="item-l">{{ requestData.directionTxt }}</div>
            <div class="item-r">
              <img
                :class="['arrow', { downArrow: peopleShow }]"
                src="./img/downarrow.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="oList">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          v-for="(item, index) in listData"
          :key="index"
          class="areaAppointItem"
          @click="toDetail(item)"
        >
          <div class="areaAppointItem-t">
            <div class="areaAppointItem-tl">
              <img :src="item.picture" alt="" />
            </div>
            <div class="areaAppointItem-tr">
              <div class="name">{{ item.name }}</div>
              <div class="num">
                <div class="num-l">{{ item.size }}㎡</div>
                |
                <div class="num-r">{{ item.direction }}</div>
              </div>
              <div class="type">{{ item.rent }}</div>
            </div>
          </div>
          <div class="areaAppointItem-b">
            <div class="areaAppointItem-bl"></div>
            <div class="areaAppointItem-br">立即预定</div>
          </div>
        </div>
      </v-list>
    </div>
    <v-picker
      :columns="columns"
      :valueShow="pickShow"
      :valueKey="valueKey"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
    <v-picker
      :columns="directionColumns"
      :valueShow="peopleShow"
      :valueKey="peopleKey"
      @clickOverlay="clickPeopleOverlay"
      @cancel="cancelPeoplePick"
      @confirmPicker="confirmPeople"
    ></v-picker>
    <v-picker
      :columns="roomColumns"
      :valueShow="show"
      :valueKey="valueDate"
      @clickOverlay="clickOverlay"
      @cancel="cancel"
      @confirmPicker="confirmPicker"
    ></v-picker>
  </div>
</template>

<script>
import { getRentListUrl, getSearchListUrl } from "./api.js";
import { peopleRange } from "./map.js";
import { gloabalCount } from "@/utils/common";
var moment = require("moment");
import { handleImg } from "@/utils/utils.js";
export default {
  name: "areaAppoint",
  data() {
    return {
      finishedText: "没有更多了",
      peopleKey: "label",
      valueKey: "label",
      columns: [],
      roomColumns: [],
      directionColumns: [],
      pickShow: false,
      valueDate: "label",
      show: false,
      peopleShow: false,
      finished: false,
      loading: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
        rentSpaceType: "",
        spaceSize: "",
        direction: "",
        rentSpaceTypeTxt: "所属地区",
        spaceSizeTxt: "面积",
        directionTxt: "朝向",
      },
      listData: [],
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // this.getActivityList();
    this.getSearchInfo();
    gloabalCount("", 34, 1);
  },
  methods: {
    showPeople() {
      this.peopleShow = true;
    },
    cancelPeoplePick() {
      this.peopleShow = false;
    },
    init() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.$refs.list._data.loading = true;
      this.finished = false;
      this.getRentPlaceList();
    },

    cancelPick() {
      this.pickShow = false;
    },
    clickPeopleOverlay() {
      this.peopleShow = false;
    },
    clickPickOverlay() {
      this.pickShow = false;
    },
    confirmPick(oValue) {
      this.pickShow = false;
      this.requestData.spaceSize = oValue.value;
      this.requestData.spaceSizeTxt = oValue.label;
      this.init();
    },
    confirmPeople(oValue) {
      this.peopleShow = false;
      this.requestData.direction = oValue.value;
      this.requestData.directionTxt = oValue.label;
      this.init();
    },
    confirmPicker(oValue) {
      this.show = false;
      this.requestData.rentSpaceType = oValue.value;
      this.requestData.rentSpaceTypeTxt = oValue.label;
      this.init();
    },
    cancel() {
      this.show = false;
    },
    clickOverlay() {
      this.show = false;
    },
    showType() {
      this.pickShow = true;
    },
    showDate() {
      this.show = true;
    },
    onLoad() {
      this.getRentPlaceList();
    },

    getSearchInfo() {
      let params = {
        tenantId: this.tenantId,
      };
      this.directionColumns = [{ label: "全部", value: "" }];
      this.roomColumns = [{ label: "全部", value: "" }];
      this.columns = [{ label: "全部", value: "" }];
      this.$axios.get(`${getSearchListUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.directionList) {
            res.data.directionList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.directionColumns.push(item);
            });
          }
          if (res.data && res.data.rentSpaceTypeList) {
            res.data.rentSpaceTypeList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.roomColumns.push(item);
            });
          }
          if (res.data && res.data.roomSizeList) {
            res.data.roomSizeList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.columns.push(item);
            });
          }
        }
      });
    },
    getRentPlaceList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        rentSpaceType: this.requestData.rentSpaceType,
        spaceSize: this.requestData.spaceSize,
        direction: this.requestData.direction,
        rent: "未出租",
        tenantId: this.tenantId,
      };
      this.$axios.get(`${getRentListUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.picture && item.picture.length > 0) {
              item.picture = handleImg(400, 400, item.picture);
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "rentPlaceDetail",
        query: { spaceId: item.spaceId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.areaAppoint {
  box-sizing: border-box;
  min-height: 100vh;
  background: #f7f7f7;
  .downArrow {
    transform: rotate(180deg);
  }
  .areaAppoint-t {
    // background: #fff;
  }
  .tips {
    font-size: 28px;
    display: flex;
    padding: 26px 0 26px 36px;
    background: #f4f5ff;
    align-items: center;
    .tips-l {
      width: 38px;
      height: 36px;
      margin-right: 16px;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tips-r {
      width: 616px;
      height: 40px;
      font-size: 24px;
      font-weight: 400;
      color: #5e5aff;
      line-height: 44px;
    }
  }
  .searchBox {
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    margin-top: 22px;
    .search {
      display: flex;
      justify-content: space-between;
      padding: 26px 32px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      border-radius: 16px;
      font-size: 30px;
      font-weight: 600;
      color: #5e5aff;
    }
  }
  .search-l {
    display: flex;
    align-items: center;
    .item-l {
      font-size: 32px;
      // color: #323334;
      line-height: 44px;
    }
    .item-r {
      width: 28px;
      height: 12px;
      font-size: 0;
      margin-left: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .search-c {
    display: flex;
    align-items: center;
    .item-l {
      font-size: 32px;
      // color: #323334;
      line-height: 44px;
    }
    .item-r {
      width: 28px;
      height: 12px;
      font-size: 0;
      margin-left: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .search-r {
    display: flex;
    align-items: center;
    .item-l {
      font-size: 32px;
      // color: #323334;
      line-height: 44px;
    }
    .item-r {
      width: 28px;
      height: 12px;
      font-size: 0;
      margin-left: 8px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .oList {
    padding: 32px;
  }
  .areaAppointItem {
    position: relative;
    margin-bottom: 32px;
    height: 220px;
    background: #ffffff;
    border-radius: 16px;
    .areaAppointItem-t {
      display: flex;
      .areaAppointItem-tl {
        width: 224px;
        height: 220px;
        border-radius: 16px 0 0 16px;
        margin-right: 16px;
        img {
          border-radius: 16px 0 0 16px;
          width: 100%;
          height: 100%;
        }
      }
      .areaAppointItem-tr {
        flex: 1;
        font-size: 30px;
        padding-top: 38px;
        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          font-size: 32px;
          font-weight: bold;
          color: #333333;
          line-height: 44px;
        }
        .num {
          display: flex;
          font-size: 24px;
          font-weight: 400;
          color: #646668;
          line-height: 36px;
          margin: 16px 0 30px;
          .num-l {
            margin-right: 10px;
          }
          .num-r {
            margin-left: 10px;
          }
        }
        .type {
          font-size: 24px;
          font-weight: 400;
          color: #646668;
          line-height: 36px;
        }
      }
    }
    .areaAppointItem-b {
      /*align-items: center;*/
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      margin-top: 40px;
      font-size: 28px;

      .areaAppointItem-bl {
        color: sandybrown;
      }
      .areaAppointItem-br {
        color: #fff;
        text-align: center;
        font-size: 26px;
        line-height: 62px;
        width: 194px;
        height: 62px;
        background: linear-gradient(180deg, #9793ff 0%, #5e5aff 100%);
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px 0px 16px 0px;
      }
    }
  }
}
</style>

// //查询我的商铺申请状态
// const getBusinessCircleStatusUrl = `/gateway/hc-serve/miniapi/trading/checkStatus`;
// //获取我的商铺信息
// const getMyBusinessUrl = `/gateway/hc-serve/miniapi/trading/getMyMallShop`;
// //提交商圈申请
// const postApplyUrl = `/gateway/hc-serve/miniapi/trading/apply`;
// //个人信息
// const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
// //证件类型下拉框列表查询
// const getInitInfoUrl = `/gateway/blade-system/userManagement/getInitInfo`;
// //订单列表
// const getOrderListUrl = `/gateway/hc-serve/miniapi/trading/getGoodsOrderInfo`;
// //订单详情
// const getOrderIdUrl = `/gateway/hc-serve/miniapi/trading/getGoodsOrderDetailsById`;
// // 获取全部商户信息
// const getShopInfoListUrl = `/gateway/hc-serve/manageapi/pointsMallShop/getShopInfoList`;
// // 根据商圈id获取详情
// const detailByIdUrl = `/gateway/hc-serve/manageapi/pointsMallShop/detailById`;
// // 获取商品列表
// const getShopGoodsListUrl = ` /gateway/hc-serve/miniapi/pointsMall/list`;
// //获取区级联动接口
// const areaLinkUrl = "gateway/hc-space/space/getSpaceLinkList";

// //获取商品详情
// const getGoodsDetailUrl = `/gateway/hc-serve/miniapi/pointsMall/goodsDetailById`;
// //积分兑换商品
// const getChangeUrl = `/gateway/hc-serve/miniapi/pointsMall/exchangeGoods`;

// 提交申请一刻钟商户
const applyURL = `/gateway/hc-serve/miniapi/quarterMall/apply`;
// 判断一刻钟商户申请状态
const checkStatusURL = `/gateway/hc-serve/miniapi/quarterMall/checkStatus`;
// 获取我的商铺信息
const getMyMallShopURL = `/gateway/hc-serve/miniapi/quarterMall/getMyMallShop`;
// 查询商户列表
const getShopInfoListURL = `/gateway/hc-serve/miniapi/quarterMall/getShopInfoList`;
// 根据店铺ID获取详细信息
const detailByIdURL = `/gateway/hc-serve/miniapi/quarterMall/detailById`;
// 评价
const addEvaluateRecordURL = `/gateway/hc-serve/miniapi/quarterMall/addEvaluateRecord`;
// 根据店铺ID获取评价列表列表
const getEvaluateRecordListURL = `/gateway/hc-serve/miniapi/quarterMall/getEvaluateRecordList`;
// 根据店铺ID获取商品列表
const getQuarterGoodsListURL = `/gateway/hc-serve/miniapi/quarterMall/getQuarterGoodsList`;
// 根据商品ID获取详细信息
const getGoodsDetailURL = `/gateway/hc-serve/miniapi/quarterMall/getGoodsDetail`;
// 下单
const addOrderURL = `/gateway/hc-serve/mini/communityBuy/addOrder`;
//个人信息
const userInfoURL = `/gateway/hc-mini/user/mini/user-info`;
// 一刻钟我的订单
const myOrderListURL = `/gateway/hc-serve/mini/communityBuy/myOrderList`;
// 一刻钟我的订单详情
const myOrderDetailsURL = `/gateway/hc-serve/mini/communityBuy/myOrderDetails`;
// 一刻钟商家订单-提交
const submitGoodsGetInfoURL = `/gateway/hc-serve/mini/communityBuy/submitGoodsGetInfo`;
//  一刻钟商家订单详情
const getLeaderAndShopOrderDetailsURL = `/gateway/hc-serve/mini/communityBuy/getLeaderAndShopOrderDetails`;
// 一刻钟商家订单-列表
const getLeaderAndShopOrderListURL = `/gateway/hc-serve/mini/communityBuy/getLeaderAndShopOrderList`;
// 退款
const refundOrderURL = `/gateway/hc-serve/mini/communityBuy/refundOrder`;
// 查询退款订单状态
const queryRefundOrderURL = `/gateway/hc-serve/mini/communityBuy/queryRefundOrder`;
//获取微信jssdk初始化
const initWxJsSdkURL = `/gateway/hc-mini/qrcode/getPASignature`;

export {
  applyURL,
  checkStatusURL,
  getMyMallShopURL,
  getShopInfoListURL,
  detailByIdURL,
  addEvaluateRecordURL,
  getEvaluateRecordListURL,
  getQuarterGoodsListURL,
  getGoodsDetailURL,
  addOrderURL,
  userInfoURL,
  myOrderListURL,
  myOrderDetailsURL,
  submitGoodsGetInfoURL,
  getLeaderAndShopOrderDetailsURL,
  getLeaderAndShopOrderListURL,
  refundOrderURL,
  queryRefundOrderURL,
  initWxJsSdkURL,
};

//获取微信jssdk初始化
const initWxJsSdkUrl = `/gateway/hc-mini/qrcode/getPASignature`;
//获取便民服务列表
const getServiceList = `/gateway/hc-serve/miniapi/convenience/getServiceList`;
//获取便民服务列表
const getServiceInfoById = `/gateway/hc-serve/miniapi/convenience/getServiceInfoById`;
//评价列表
const getServiceEvaluateList = `/gateway/hc-serve/miniapi/convenience/getServiceEvaluateList`;
//评价保存
const addServiceEvaluateRecord = `/gateway/hc-serve/miniapi/convenience/addServiceEvaluateRecord`;
//新增
const saveServiceInfo = `/gateway/hc-serve/miniapi/convenience/saveServiceInfo`;
//获取服务区域以及街道数据
const getInitInfo = `/gateway/hc-serve/miniapi/convenience/getInitInfo`;
//本人发布的便民服务详情查询
const getOwnServiceInfo = `/gateway/hc-serve/miniapi/convenience/getOwnServiceInfo`;
//获取服务小类接口
const getServiceTypeLv2 = `/gateway/hc-serve/miniapi/convenience/getServiceTypeLv2`;
export {
  initWxJsSdkUrl,
  getServiceList,
  getServiceInfoById,
  getServiceEvaluateList,
  addServiceEvaluateRecord,
  saveServiceInfo,
  getInitInfo,
  getOwnServiceInfo,
  getServiceTypeLv2,
};

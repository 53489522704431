let origin;
if (window.location.origin == "https://mapi.xydata.cc") {
  origin = "https://test.zhuneng.cn"; //研发
  // origin = "https://wantou.zhuneng.cn";//生产
} else {
  origin = "";
}
//签到状态
const signStatusUrl = `${origin}/gateway/hc-accumulated/dailyPerf/getSignInStatus`;
//签到
const signUrl = `${origin}/gateway/hc-accumulated/dailyPerf/doSignIn`;
//每日任务
const dayMaskUrl = `${origin}/gateway/hc-accumulated/dailyPerf/getSignInStatus`;
//是否显示评论
const isShowSign = `${origin}/gateway/blade-system/application/getFunctionSetting`;
export { signStatusUrl, signUrl, dayMaskUrl, isShowSign };

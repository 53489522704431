//查询托幼课程
const getClassDataByDayUrl = `/gateway/hc-external/nursery/getClassDataByDay`;
//提交预约申请申请
const nurseryApplyUrl = `/gateway/hc-external/nursery/nurseryApply`;
//查询我的预约申请列表
const selectMyApplyUrl = `/gateway/hc-external/nursery/getUserApplyData`;
//托幼审核待审核数据
const getApplyDataUrl = `/gateway/hc-external/nursery/getApplyData`;
//审核申请
const handelStatusUrl = `/gateway/hc-external/nursery/doExamine`;
// 提交评价
const appraiseUrl = `/gateway/hc-external/nursery/appraise`;
// 获取上一次预约信息
const getLastOrderUrl = `/gateway/hc-external/nursery/getLastOrder`;
//查询是否有权限查看监控
const lookPower = `/gateway/hc-external/nursery/getVideoData`;

//审核申请
const judgeApplyUrl = `/gateway/hc-external/nursery/doExamine`;

export {
  getClassDataByDayUrl,
  lookPower,
  nurseryApplyUrl,
  // entrustChildUrl,
  handelStatusUrl,
  selectMyApplyUrl,
  getApplyDataUrl,
  judgeApplyUrl,
  appraiseUrl,
  getLastOrderUrl,
};

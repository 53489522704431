<template>
  <div class="bestBeautifulRes">
    <div class="box">
      <div class="box-t">
        <div class="banner"><img src="./img/banner.png" alt="" /></div>
      </div>
      <div class="box-b">
        <div class="title">沿途监控</div>
        <div class="video-list">
          <div
            class="video"
            v-for="(vItem, index) in videoList"
            :key="index"
            :id="'box' + index"
            width="100%"
            height="100%"
          ></div>
          <!-- <v-video-player
            class="video"
            v-for="(vItem, index) in videoList"
            :key="index"
            :videoOption="vItem.url | videoOption"
          ></v-video-player> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getVideoListURL } from "./api";

export default {
  name: "bestBeautifulRes",
  data() {
    return {
      form: {
        parentName: "",
      },
      player: {},
      videoList: [],
    };
  },
  filters: {
    videoOption(val) {
      return {
        autoplay: false, //如果true,浏览器准备好时开始回放
        preload: "none", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        poster: require("./img/banner.png"), //你的封面地址
        sources: [
          {
            src: val, //url地址
          },
        ],
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
        flash: {
          hls: {
            withCredentials: false,
          },
        },
      };
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    // this.getVideoList();
  },
  beforeDestroy() {
    for (var i in this.player) {
      if (this.player[i]) {
        this.player[i] = null;
      }
    }
  },
  async mounted() {
    for (var i in this.player) {
      if (this.player[i]) {
        this.player[i] = null;
      }
    }
    await this.getVideoList();
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    init() {
      let _this = this;
      for (var i = 0; i <= this.videoList.length - 1; i++) {
        let id = "box" + i;
        document.getElementById(id).innerHTML = `<div
            id="J_prismPlayer${i}"
            width="100%"
            height="100%"
            class="prism-player"
          ></div>`;
        this.player[i] = new window.Aliplayer(
          {
            id: `J_prismPlayer${i}`, // 容器id
            source: this.videoList[i].url, // 视频url
            width: "100%", // 播放器宽度
            height: "100%", // 播放器高度
            autoplay: true,
            isLive: true,
            rePlay: false,
            playsinline: true,
            preload: true,
            controlBarVisibility: "hover",
            useH5Prism: true,
          },
          function (player) {
            console.log("播放器创建了");
          }
        );
      }
      console.log(this.player);
    },
    async getVideoList() {
      let res = await this.$axios.get(`${getVideoListURL}`, {
        params: {
          tenantId: this.tenantId,
        },
      });
      if (res.code == 200 && res.success && res.data.videoList) {
        this.videoList = JSON.parse(res.data.videoList) || [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bestBeautifulRes {
  min-height: 100vh;
  background: url("./img/back.png") no-repeat;
  background-size: 100% 100%;
  .box {
    padding: 50px 30px;
    .box-t {
      height: 320px;
      border-radius: 16px;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box-b {
      // height: 364px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      padding: 0 30px 30px;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        line-height: 44px;
        padding: 20px 0 24px;
      }
      .video-list {
        &::after {
          display: block;
          content: "";
          width: 0;
          height: 0;
          padding: 0;
          margin: 0;
          clear: both;
        }
        .video {
          width: 100%;
          height: 300px;
          border-radius: 32px;
          box-sizing: border-box;
          position: relative;
          #J_prismPlayer video {
            border-radius: 32px;
          }
          video {
            box-sizing: border-box;
            border-radius: 32px;
          }
        }
        .video {
          float: left;
          width: 98%;
          // height: 266px;
          margin: 10px 1%;
        }
      }
    }
  }
}
</style>
<style lang="less">
.prism-player .prism-big-play-btn {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
}
</style>

<template>
  <div class="page">
    <div class="banner">
      <div class="headImg"><img :src="detail.topicPicture" /></div>
      <div class="infoBox">
        <div class="name">{{ detail.topicTitle || "" }}</div>
        <div class="occupation">{{ format(detail.topicType) }}</div>
      </div>
    </div>
    <div class="main" v-html="detail.topicContent"></div>
  </div>
</template>

<script>
import { getTopicInfoByIdURL } from "./api.js";
export default {
  name: "villageScholarStyleDetail",
  data() {
    return {
      detail: {},
      id: null,
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    format(type) {
      if (type == 36) {
        return "离休干部";
      } else if (type == 37) {
        return "贤人志士";
      } else if (type == 38) {
        return "各界精英";
      } else if (type == 39) {
        return "优秀典范";
      }
    },
    // 获取详情
    getDetail() {
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast0
        forbidClick: true,
        message: "请求中...",
      });
      this.$axios
        .get(getTopicInfoByIdURL, {
          params: {
            id: this.id,
            userId: this.userId,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.detail = res.data;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 30px;
  .main {
    width: 100%;
    padding: 34px 50px 0 50px;
    box-sizing: border-box;
    font-size: 30px;
    color: #333333;
    line-height: 42px;
    ::v-deep img {
      width: 100%;
    }
    ::v-deep video {
      width: 100%;
    }
  }
  .banner {
    width: 100%;
    height: 272px;
    background-image: url("./img/style-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    padding: 0 54px;
    box-sizing: border-box;
    .infoBox {
      flex: 1;
      box-sizing: border-box;
      padding-top: 80px;
      padding-left: 24px;
      .name {
        padding: 0 0 0 0;
        font-size: 36px;
        font-weight: 500;
        color: #333333;
        box-sizing: border-box;
        white-space: nowrap;
      }
      .occupation {
        padding: 10px 0 0 0;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        white-space: nowrap;
      }
    }
    .headImg {
      margin-top: 58px;
      width: 132px;
      height: 132px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
<template>
  <div class="pointVolunteerList">
    <div class="header">
      <img src="./img/pointVolunteer.png" alt="" />
      <div class="detailInfo">
        <div class="avatar">
          <img
            :src="
              detail.avatar
                ? detail.avatar
                : require('@/assets/img/default_avatar.png')
            "
            alt=""
          />
        </div>
        <div class="name">{{ detail.nickName || "--" }}</div>
        <div class="info">
          <div class="infoList">
            <div class="title">志愿积分排行榜</div>
            <div class="text">
              第<span>{{ detail.pointRanking }}</span
              >名
            </div>
          </div>
          <div class="infoTotal">
            <div class="title">志愿总积分</div>
            <div class="text">{{ detail.volunteerCount }}</div>
          </div>
        </div>
        <div class="tip">
          <div class="text">志愿可用积分</div>
        </div>
        <div class="point">{{ detail.volunteerAvailablePoint }}</div>
      </div>
    </div>
    <div class="main">
      <div class="main-title">
        <div class="aside">前.<span>100</span></div>
        <div class="title">
          <!-- <img src="./img/volunteerTitle.png" alt="" /> -->
          <span>志愿积分排行榜</span>
        </div>
      </div>
      <div class="main-top">
        <div class="field rank">排名</div>
        <div class="field name">名称</div>
        <div class="field point">志愿积分</div>
      </div>
      <div class="main-bottom">
        <div class="list">
          <v-list
            :finished="finished"
            @onLoad="onLoad"
            :finishedText="finishedText"
            ref="list"
          >
            <div class="item" v-for="(item, index) in listData" :key="index">
              <div class="oItem">
                <div class="oItemRank">
                  <img v-if="index === 0" src="./img/gold.png" alt="" />
                  <img v-else-if="index === 1" src="./img/silver.png" alt="" />
                  <img v-else-if="index === 2" src="./img/copper.png" alt="" />
                  <span>{{ index + 1 }}</span>
                </div>
                <div class="oItemName">
                  <div class="img">
                    <img
                      :src="
                        item.avatar
                          ? item.avatar
                          : require('@/assets/img/default_avatar.png')
                      "
                      alt=""
                    />
                  </div>
                  <div class="name">{{ item.nickName }}</div>
                </div>
                <div class="oItemPoint">
                  <div class="pointNum">{{ item.totalVolunteerPoint }}</div>
                </div>
              </div>
            </div>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  volunteerPointDetailsUrl,
  volunteerPointRankingListUrl,
} from "./api.js";
import { handleImg } from "@/utils/utils.js";
export default {
  name: "pointVolunteerList",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      form: {},
      detail: {
        avatar: "",
        nickName: "",
        volunteerCount: "",
        volunteerAvailablePoint: "",
        pointRanking: "",
      },
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    this.getPoints();
  },
  methods: {
    onLoad() {
      this.getPointsList();
    },
    getPoints() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios.get(`${volunteerPointDetailsUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            this.detail = res.data;
            if (this.detail.avatar) {
              this.detail.avatar = handleImg(104, 104, this.detail.avatar);
            }
          }
        } else {
        }
      });
    },
    getPointsList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        tenantId: this.tenantId,
      };
      this.$axios
        .get(`${volunteerPointRankingListUrl}`, { params })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.avatar) {
                item.avatar = handleImg(96, 96, item.avatar);
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.pointVolunteerList {
  min-height: 100%;
  box-sizing: border-box;
  .header {
    position: relative;
    width: 100%;
    height: 306px;
    > img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
    .detailInfo {
      position: absolute;
      left: 50%;
      top: 168px;
      transform: translateX(-50%);
      width: 644px;
      height: 434px;
      line-height: 82px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      .avatar {
        margin-top: -89px;
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 158px;
          height: 158px;
          vertical-align: middle;
        }
      }
      .name {
        font-size: 36px;
        font-weight: 600;
        color: #333333;
        line-height: 50px;
        margin: 16px 0;
      }
      .info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: center;
        .infoList {
          flex: 1;
          .title {
            font-size: 28px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
          }
          .text {
            font-size: 40px;
            font-weight: 600;
            color: #333333;
            line-height: 56px;
            margin: 18px 0 24px;
            span {
              color: #317dffff;
              display: inline-block;
              min-width: 100px;
            }
          }
        }
        .infoTotal {
          flex: 1;
          .title {
            font-size: 28px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 40px;
          }
          .text {
            font-size: 40px;
            font-weight: 600;
            color: #317dff;
            line-height: 56px;
            margin: 18px 0 24px;
          }
        }
      }
      .tip {
        position: relative;
        width: calc(100% - 48px);
        margin: 0 24px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        .text {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          white-space: nowrap;
          padding: 0 24px;
          font-size: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
          background: #fff;
        }
      }
      .point {
        margin-top: 54px;
        width: 100%;
        height: 88px;
        text-align: center;
        line-height: 88px;
        background: #347eff;
        border-radius: 0px 0px 16px 16px;
        font-size: 40px;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
  .main {
    padding: 344px 0px 0;
    .main-title {
      position: relative;
      display: flex;
      height: 82px;
      width: calc(100% - 112px);
      padding: 0 56px;
      .aside {
        font-size: 32px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
        span {
          color: #f7b500;
        }
      }
      .title {
        position: absolute;
        top: 0;
        left: 50%;
        width: 372px;
        height: 82px;
        transform: translateX(-50%);
        text-align: center;
        line-height: 60px;
        background: url("./img/volunteerTitle.png") 0% 0% no-repeat;
        background-size: 100% 100%;
        // > img {
        //   width: 372px;
        //   height: 82px;
        // }
        span {
          font-size: 32px;
          font-weight: 600;
          color: #ffffff;
          line-height: 44px;
        }
      }
    }
    .main-top {
      display: flex;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
      padding: 32px 0 48px;
      text-align: center;
      .rank {
        flex: 1;
      }
      .name {
        box-sizing: border-box;
        flex: 2;
        text-align: left;
        padding-left: 56px;
      }
      .point {
        flex: 1;
      }
    }
    .main-bottom {
      border-top: 2px solid #fff;
      .list {
        .item {
          .oItem {
            display: flex;
            margin-bottom: 26px;
            align-items: center;
            justify-content: center;
            .oItemRank {
              position: relative;
              flex: 1;
              font-size: 28px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.5);
              text-align: center;
              line-height: 82px;
              > img {
                position: absolute;
                top: 51%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: 55px;
                height: 62px;
                vertical-align: middle;
                z-index: -1;
              }
            }
            .oItemName {
              flex: 2;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .img {
                width: 82px;
                height: 82px;
                border: 1px solid rgba(0, 126, 255, 1);
                margin: 0 16px;
                border-radius: 50%;
                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
              .name {
                width: calc(100% - 120px);
                text-overflow: ellipsis;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 28px;
                color: rgba(102, 102, 102, 1);
              }
            }
            .oItemPoint {
              flex: 1;
              .pointNum {
                text-align: center;
                font-size: 40px;
                font-weight: 600;
                color: #317dff;
                // font-size: 32px;
                // color: rgba(51, 51, 51, 1);
                // margin-right: 8px;
              }
              .pointImg {
                width: 40px;
                height: 40px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="page">
    <div class="input-box">
      <div class="label">姓名：</div>
      <div class="left">
        <input
          style="background: transparent"
          type="text"
          :disabled="auditStatus == 0"
          v-model="form.name"
          placeholder="请输入姓名"
        />
      </div>
    </div>
    <div class="input-box">
      <div class="label">性别：</div>
      <div class="left">
        <div class="sexBox" @click="showPicker('sex')">
          {{ sexformat(form.sex) }}
          <img src="./img/down-arrow.png" alt="" />
        </div>
      </div>
    </div>
    <div class="input-box">
      <div class="label">出生日期：</div>
      <div class="left" @click="showPicker('birthday')">
        <div class="dateBox">
          {{ form.birthday || "请选择日期" }}
          <img src="./img/date-icon.png" alt="" />
        </div>
      </div>
    </div>
    <div class="main-title">服务特长：</div>
    <div class="goodAtBox">
      <van-field
        rows="6"
        style="background: rgba(0, 0, 0, 0.04); border-radius: 16px"
        type="textarea"
        placeholder="请输入"
        maxlength="300"
        v-model="form.serviceSpecialty"
        :disabled="auditStatus == 0"
      />
    </div>
    <div class="main-title">照片上传：</div>
    <v-upload
      class="oItem-b_upload"
      :imgUrl.sync="form.photo"
      :activityPicture.sync="form.photo"
      :maxCount="9"
      ref="load"
      :disabled="auditStatus == 0"
    ></v-upload>
    <!-- 未通过显示 -->
    <div class="main-title" v-if="auditStatus == 2">未通过原因：</div>
    <div class="goodAtBox" v-if="auditStatus == 2">
      <van-field
        rows="2"
        style="background: rgba(0, 0, 0, 0.04); border-radius: 16px"
        type="textarea"
        placeholder=""
        maxlength="300"
        disabled
        v-model="auditRemarks"
      />
    </div>
    <v-picker
      :columns="sexList"
      :valueShow="show"
      valueKey="text"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
    <v-dateTimePicker
      type="date"
      :value="form.birthday"
      :minDate="minDate"
      :maxDate="maxDate"
      :isAuto="true"
      :valueShow.sync="show2"
      @confirmPicker="confirmActiEnd"
    ></v-dateTimePicker>
    <div class="btn blue" @click="submit" v-if="auditStatus == 1">
      审核通过，如变化再次提交
    </div>
    <div class="btn grey" @click="submit" v-else-if="auditStatus == 2">
      审核未通过，请再次提交
    </div>
    <div class="btn white" v-else-if="auditStatus == 0">
      已提交，审核中。。。
    </div>
    <div class="btn blue" v-else @click="submit">提交</div>
  </div>
</template>

<script>
import { applyForNanxiSisterUrl, nanxiSisterDetailsUrl } from "./api.js";
var moment = require("moment");
export default {
  data() {
    return {
      minDate: new Date(1940, 0, 1),
      maxDate: new Date(),
      show: false,
      show2: false,
      sexList: [
        {
          text: "男",
          value: 1,
        },
        {
          text: "女",
          value: 2,
        },
      ],
      auditStatus: null,
      auditRemarks: null,
      form: {
        name: null,
        sex: null,
        birthday: null,
        serviceSpecialty: null,
        photo: null,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    showPicker(type) {
      if (this.auditStatus == 0) {
        return;
      } else {
        if (type == "sex") {
          this.show = true;
        } else {
          this.show2 = true;
        }
      }
    },
    // 提交申请
    submit() {
      if (!this.form.name) {
        this.$toast("请输入姓名");
        return;
      }
      if (!this.form.sex) {
        this.$toast("请选择性别");
        return;
      }
      if (!this.form.birthday) {
        this.$toast("请选择出生日期");
        return;
      }
      if (!this.form.serviceSpecialty) {
        this.$toast("请输入服务特长");
        return;
      }
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.form.photo) {
        this.$toast("请上传照片");
        return;
      }
      let obj1 = this.form;
      let obj2 = { tenantId: this.tenantId, userId: this.userId };
      let params = { ...obj1, ...obj2 };
      this.$axios.post(`${applyForNanxiSisterUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.$toast(res.msg);
          this.getDetail();
        }
      });
    },
    // 获取详情
    getDetail() {
      let params = {
        userId: this.userId,
      };
      this.$axios.get(nanxiSisterDetailsUrl, { params: params }).then((res) => {
        if (res.code == 200) {
          this.auditStatus = res.data.auditStatus;
          this.auditRemarks = res.data.auditRemarks;
          this.form.name = res.data.name;
          this.form.sex = res.data.sex;
          this.form.birthday = res.data.birthday;
          this.form.serviceSpecialty = res.data.serviceSpecialty;
          this.form.photo = res.data.photo;
        }
      });
    },
    // 出生日期
    confirmActiEnd(val) {
      this.form.birthday = moment(val).format("YYYY-MM-DD");
      console.log(this.form.birthday);
    },
    // 性别
    sexformat(num) {
      if (num == 1) {
        return "男";
      } else if (num == 2) {
        return "女";
      } else {
        return "请选择";
      }
    },
    clickOverlay() {
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    confirm(value) {
      this.form.sex = value.value;
      this.show = false;
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 250px;
  .blue {
    color: #ffffff;
    background: linear-gradient(180deg, #90cff3 0%, #57a8d6 100%);
  }
  .grey {
    background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
    color: rgba(0, 0, 0, 0.25);
  }
  .white {
    color: #57a8d6;
    border: 2px solid #57a8d6;
  }
  .btn {
    width: 550px;
    height: 66px;
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    position: fixed;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
    bottom: 120px;
  }
  .goodAtBox {
    width: 100%;
  }
  .main-title {
    height: 50px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding: 40px 0 20px;
  }
  .input-box {
    display: flex;
    align-items: center;
    height: 120px;
    box-sizing: border-box;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    justify-content: space-between;
    .left {
      .dateBox {
        width: 260px;
        height: 62px;
        background: #ffffff;
        border-radius: 8px;
        border: 2px solid rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        padding: 0 20px;
        box-sizing: border-box;
        justify-content: space-between;
        img {
          width: 25px;
        }
      }
      .sexBox {
        width: 192px;
        height: 62px;
        background: #ffffff;
        border-radius: 8px;
        border: 2px solid rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        padding: 0 20px;
        box-sizing: border-box;
        justify-content: space-between;
        img {
          width: 20px;
        }
      }
      input {
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        text-align: right;
      }
    }
    .label {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
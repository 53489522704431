<template>
  <div class="page">
    <div class="info">
      <div class="item">
        <div class="left"><span>*</span>姓名</div>
        <div class="right">
          <input
            type="text"
            style="background: #fff"
            placeholder="请输入姓名"
            :disabled="disabled1"
            v-model="form.realName"
          />
        </div>
      </div>
      <div class="item">
        <div class="left"><span>*</span>联系方式</div>
        <div class="right">
          <input
            type="text"
            style="background: #fff"
            placeholder="请输入联系方式"
            v-model="form.mobileNum"
            :disabled="disabled2"
          />
        </div>
      </div>
      <div class="item">
        <div class="left"><span>*</span>身份证</div>
        <div class="right">
          <input
            type="text"
            style="background: #fff"
            placeholder="请输入身份证"
            :disabled="disabled1"
            v-model="form.idCardNum"
          />
        </div>
      </div>
      <div class="item">
        <div class="left"><span>*</span>是否为党员</div>
        <div class="right">
          <div class="selectBox" @click="showPicker(1)">
            {{ format(form.isPartyMember) || "请选择"
            }}<img src="./img/down-arrow.png" alt="" />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="left"><span>*</span>是否为干部</div>
        <div class="right">
          <div class="selectBox" @click="showPicker(2)">
            {{ format(form.isCadre) || "请选择"
            }}<img src="./img/down-arrow.png" alt="" />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="left"><span>*</span>是否为村民代表</div>
        <div class="right">
          <div class="selectBox" @click="showPicker(3)">
            {{ format(form.isVillagerRepresentatives) || "请选择"
            }}<img src="./img/down-arrow.png" alt="" />
          </div>
        </div>
      </div>
      <div class="item" v-if="form.isCadre == '1'">
        <div class="left"><span>*</span>职务</div>
        <div class="right">
          <input type="text" placeholder="请输入职务" v-model="form.post" />
        </div>
      </div>
      <div class="item-upload">
        <div class="left"><span>*</span>上传照片</div>
        <div class="right">
          <v-upload
            :imgUrl.sync="form.pictures"
            :activityPicture.sync="form.pictures"
            ref="load"
            :limt="1"
          ></v-upload>
        </div>
      </div>
      <img v-if="status === 1" src="./img/certified.png" class="tag" />
      <img v-if="status === 0" src="./img/underReview.png" class="tag" />
    </div>
    <div class="btn" @click="submit">未提交或有变动，点击提交</div>
    <v-picker
      :columns="pickerList"
      :valueShow="show"
      valueKey="text"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import {
  getCertifiedRealNameInfoUrl,
  userInfoUrl,
  getUserSubjectAuditInfoUrl,
  saveUserSubjectAuditInfoUrl,
} from "./api.js";
import { mapState } from "vuex";
export default {
  name: "identityAuthenticationTab",
  data() {
    return {
      show: false,
      pickerList: [
        { text: "否", value: "0" },
        { text: "是", value: "1" },
      ],
      pickerFlag: null,
      form: {
        realName: null,
        mobileNum: null,
        idCardNum: null,
        isPartyMember: null,
        isCadre: null,
        isVillagerRepresentatives: null,
        post: null,
        pictures: null,
        userId: null,
      },
      userInfo: {},
      disabled1: false,
      disabled2: false,
      lastInfo: {},
      status: null,
    };
  },
  created() {
    this.getRealNameInfo();
  },
  mounted() {
    this.getUserInfo();
    this.getlastInfo();
    this.form.userId = this.userId;
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    submit() {
      if (!this.form.realName) {
        this.$toast("请输入姓名");
        return;
      }
      if (!this.form.mobileNum) {
        this.$toast("请输入联系方式");
        return;
      }
      if (!this.form.idCardNum) {
        this.$toast("请输入身份证");
        return;
      }
      if (!this.form.isPartyMember) {
        this.$toast("请选择是否为党员");
        return;
      }
      if (!this.form.isCadre) {
        this.$toast("请选择是否为干部");
        return;
      }
      if (!this.form.isVillagerRepresentatives) {
        this.$toast("请选择是否为村民代表");
        return;
      }
      if (this.form.isCadre == "1" && !this.form.post) {
        this.$toast("请输入职务");
        return;
      }
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.form.pictures) {
        this.$toast("请上传图片");
        return;
      }
      let params = this.form;
      this.$axios.post(`${saveUserSubjectAuditInfoUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.$toast(res.msg);
          this.getlastInfo();
        }
      });
    },
    getlastInfo() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(getUserSubjectAuditInfoUrl, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.status = res.data.auditStatus;
            if (!this.disabled1) {
              this.form.realName = res.data.realName;
              this.form.idCardNum = res.data.idCardNum;
            }
            if (!this.disabled2) {
              this.form.mobileNum = res.data.mobileNum;
            }
            this.form.isPartyMember = String(res.data.isPartyMember);
            this.form.isCadre = String(res.data.isCadre);
            this.form.isVillagerRepresentatives = String(
              res.data.isVillagerRepresentatives
            );
            this.form.post = res.data.post;
            this.form.pictures = res.data.pictures;
          }
        });
    },
    getRealNameInfo() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(getCertifiedRealNameInfoUrl, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.realName != "" && res.data.idCardNum != "") {
              this.form.realName = res.data.realName;
              this.form.idCardNum = res.data.idCardNum;
              this.disabled1 = true;
            }
          }
        });
    },
    showPicker(type) {
      this.pickerFlag = type;
      this.show = true;
    },
    format(num) {
      if (num === 0 || num === "0") {
        return "否";
      } else if (num === 1 || num === "1") {
        return "是";
      }
    },
    confirm(item) {
      console.log(item);
      if (this.pickerFlag == 1) {
        this.form.isPartyMember = item.value;
      } else if (this.pickerFlag == 2) {
        this.form.isCadre = item.value;
      } else if (this.pickerFlag == 3) {
        this.form.isVillagerRepresentatives = item.value;
      }
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    clickOverlay() {
      this.show = false;
    },
    // 获取用户信息
    getUserInfo() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(userInfoUrl, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
            if (res.data.mobile && res.data.mobile != "")
              this.form.mobileNum = res.data.mobile;
            this.disabled2 = true;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
  padding: 20px 30px 60px;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    margin: 84px auto;
  }
  .info {
    width: 100%;
    padding: 10px 30px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(220, 220, 220, 0.5);
    border-radius: 16px;
    position: relative;
    .tag {
      position: absolute;
      width: 270px;
      top: 222px;
      right: 80px;
    }
    .item-upload {
      width: 100%;
      display: flex;
      padding-top: 44px;
      padding-bottom: 30px;
      .left {
        flex: 1;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        span {
          color: #ff602a;
          font-size: 28px;
        }
      }
    }
    .item {
      width: 100%;
      height: 112px;
      line-height: 112px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      .right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        .selectBox {
          width: 208px;
          height: 58px;
          border-radius: 10px;
          border: 2px solid rgba(0, 0, 0, 0.25);
          display: flex;
          align-items: center;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          text-align: center;
          line-height: 58px;
          padding: 0 10px 0 22px;
          box-sizing: border-box;
          justify-content: space-between;
          img {
            width: 28px;
          }
        }
        input {
          text-align: right;
          width: 100%;
          height: 100%;
          border: none;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .left {
        flex: 1;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        span {
          color: #ff602a;
          font-size: 28px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="page">
    <!-- <iframe
      v-if="show"
      :src="url"
      frameborder="0"
      name="myFrameName"
      id="myFrameId"
    >
    </iframe> -->
     <v-empty description="暂无展厅"></v-empty>
  </div>
</template>

<script>
export default {
  name: "digitalExhibitionHall",
  data() {
    return {
      show: true,
      // url: `${window.location.origin}/d3mdoc/#/iframe/vrmore`,
      url: `https://tsfuture.zhuneng.cn/d3mdoc/#/iframe/vrmore`,
    };
  },
  created() {
    console.log(this.url);
  },
};
</script>

<style scoped lang='less'>
.page {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  iframe {
    width: 100%;
    height: 100vh;
  }
}
</style>
<template>
  <div class="page">
    <div class="people">
      <div class="txt">
        <div class="title">卓兰芳烈士（1900年-1930年）</div>
        <div class="content">
          卓兰芳，1900年生，谱名祥和，字培卿，化名李品三、李安德，奉化松岙海沿村人。宁波省立四中肄业后，在奉化、宁波等地教书。1924年加入中国社会主义青年团，次年暑期转为中共党员，曾任中共宁波地委委员、奉化忠义部委书记、浙江省委书记、中央巡视员、浙北行委书记等职，从事工人和农民运动。为配合北伐军入浙，在奉东建立党组织、农民协会和农民武装，赶跑了军阀县知事：巡视浙东、浙西、浙北等地，致力于党的思想建设和组织建设：为推翻国民党反动统治，奉命发起奉化、诸暨、建德等武装暴动。1930年9月8日在杭州被捕，10月5日牺牲于浙江陆军监狱，年仅30岁。
        </div>
      </div>
      <div class="img"><img src="./img/zlf1.jpeg" alt="" /></div>
      <div class="txt">
        <div class="title">卓兰芳纪念馆</div>
        <div class="content">
          <div class="h3">一、基本概况</div>
          <div class="box">
            位置：卓兰芳纪念馆位于浙江省宁波市奉化区松岙镇海沿村。
            占地面积：1120平方米。 建筑面积：572平方米。
          </div>
          <div class="h3">二、历史沿革</div>
          <div class="box">
            卓兰芳是浙江工农运动的先驱，曾任中共浙江省委第四任书记，是宁波人民的杰出代表和共产党员的光辉典范。为弘扬卓兰芳的崇高精神，松岙镇党委政府对卓兰芳故居进行了全面整修，并在1993年将其扩建成“卓兰芳烈士故居陈列室”。2003年，宁波、奉化、松岙三级政府又先后拨款对故居进行改扩建，并于2004年3月竣工，易名为“卓兰芳纪念馆”。2014年，奉化投入200余万元对纪念馆再次进行较大规模修缮、扩建，并将观影厅和主体展厅重新布局，增设敬仰碑、宣誓广场及候游广场等。
          </div>
          <div class="h3">三、建筑布局与特点</div>
          <div class="box">
            纪念馆正面中西合璧，围墙高耸，大门飞檐翘角，庄重典雅。悬挂在门口的“卓兰芳纪念馆”匾额由张爱萍题写。纪念馆内设有多个展厅和烈士故居陈列室，通过丰富的图片、资料和遗物，展示了卓兰芳烈士的生平事迹和革命精神。中央草坪中矗立着卓兰芳烈士半身雕像，庄重肃穆、形象逼真。
          </div>
          <div class="h3">四、参观与教育功能</div>
          <div class="box">
            卓兰芳纪念馆是一处党员干部、群众及青少年进行爱国主义教育和我市中共党史教育的重要场所。每年都有大量游客和参观者前来缅怀革命先烈，追忆中国共产党为民族独立和人民解放而不懈奋斗的历史。纪念馆内还设有观影厅等设施，方便参观者更加深入地了解卓兰芳烈士的生平事迹和革命精神。
          </div>
          <div class="h3">五、荣誉与地位</div>
          <div class="box">
            卓兰芳纪念馆在1995年2月就被列入宁波市爱国主义教育基地。
            2008年4月，被中共浙江省委、浙江省人民政府公布为省爱国主义教育基地。
            2009年7月，被中共浙江省委党史研究室公布为省党史教育基地。
          </div>
        </div>
      </div>
      <div class="img"><img src="./img/zlf2.jpg" alt="" /></div>
      <div class="img"><img src="./img/zlf3.jpg" alt="" /></div>
      <div class="img"><img src="./img/zlf4.jpg" alt="" /></div>
      <div class="img"><img src="./img/zlf5.jpg" alt="" /></div>
    </div>
    <div class="people">
      <div class="txt">
        <div class="title">卓恺泽烈士（1905年-1928年）</div>
        <div class="content">
          卓恺泽，1905年生，乳名阿培，笔名砍石，奉化松岙后山村人。1923年考入北京华北大学，加入中国社会主义青年团（后改称共青团）；12月，转入中国共产党。曾任团北方区委和江浙区委委员，团四届中央委员、中共上海闸北部委书记、中共浙江省委委员兼团省委书记、团中央特派员兼团湖北省委书记等职。在家乡一带传播马列主义，帮助同乡好友走上革命道路；编著《青年平民读本》四册，供各地工农夜校作教材；编辑《政治生活》、《中国青年》
          刊物，发表许多抨击帝国主义、军阀及各种反动派别的文章；还动员父亲变卖家产，资助革命，为奉化暴动筹集经费和枪支。1928年4月26日在武汉被捕牺牲，年仅23岁。
        </div>
      </div>
      <div class="img"><img src="./img/zkz1.jpeg" alt="" /></div>
      <div class="txt">
        <div class="title">卓恺泽烈士故居</div>
        <div class="content">
          <div class="h3">一、基本概况</div>
          <div class="box">
            位置：位于浙江省宁波市奉化区松岙镇后山村后宅32号 占地面积：232平方米
            建筑面积：240平方米
          </div>
          <div class="h3">二、历史沿革</div>
          <div class="box">
            卓恺泽是奉化第一位共产党员，也是一位革命烈士。1926年春，卓兰芳、卓子英在松岙当地介绍发展了庄宏生、卓阿杨等6人入党，入党宣誓就在卓恺泽家里进行。随后，几名党员在方桌前开会，成立了宁波第一个农村党支部，由卓兰芳担任党支部书记。卓恺泽故居作为中共松岙支部旧址所在地，见证了宁波地区早期党组织的建立和发展。
          </div>
          <div class="h3">三、建筑布局与特点</div>
          <div class="box">
            故居为二层木结构建筑，有四间一弄和一间侧室，故居一楼堂屋墙上悬挂着裱框的卓恺泽夫妇遗像。二楼当中一间房里摆放着一张红木方桌和一张年代久远的木床。整个故居保存完好，具有较高的艺术文化价值。
          </div>
          <div class="h3">四、参观与教育功能</div>
          <div class="box">
            卓恺泽烈士故居作为宁波市爱国主义教育基地，吸引了大量游客和参观者前来学习和缅怀革命先烈。这里不仅是缅怀卓恺泽烈士的重要场所，更是进行爱国主义教育的重要资源。
            通过参观卓恺泽烈士故居，人们可以更加深入地了解中国共产党的历史、学习革命先烈的崇高精神。这对于广大党员干部、群众及青少年来说具有重要的教育意义。
          </div>
          <div class="h3">五、荣誉与地位</div>
          <div class="box">
            卓恺泽故居作为重要的红色纪念地，被列为了宁波市爱国主义教育基地和宁波市中共党史教育基地。
          </div>
        </div>
      </div>
      <div class="img"><img src="./img/zkz2.jpeg" alt="" /></div>
      <div class="img"><img src="./img/zkz3.jpeg" alt="" /></div>
      <div class="img"><img src="./img/zkz4.png" alt="" /></div>
    </div>
    <div class="people">
      <div class="txt">
        <div class="title">裘古怀烈士（1905年-1930年）</div>
        <div class="content">
          裘古怀（古槐），1905年生，乳名永良，化名周梧秋、周乃秋、周啸秋，奉化松岙大埠村人。宁波省立四中师范部读书时，领导学生运动，后考入黄埔军校第四期。1926年加入中国共产党，曾任国民革命军第十一军二十四师政治部宣传科长、共青团萧山县委书记、中共浙西特委常委、团浙江省委代书记等职。参加北伐战争和“八一”南昌起义，进军潮州、汕头负伤；辗转到宁波养伤后，为奉化暴动训练军事人才；在浙西指挥兰溪秋收暴动。1929年1月在杭州被捕，关入浙江陆军监狱，被推举为中共狱中特支宣传委员，参与和领导狱中斗争，于1930年8月27日英勇就义，年仅25岁。
        </div>
      </div>
      <div class="img"><img src="./img/qgh1.jpeg" alt="" /></div>
      <div class="txt">
        <div class="title">裘古怀烈士纪念馆</div>
        <div class="content">
          <div class="h3">一、基本概况</div>
          <div class="box">
            位置：浙江省宁波市奉化区松岙镇大埠村 占地面积：1350平方米。
            建筑面积：400平方米
          </div>
          <div class="h3">二、历史沿革</div>
          <div class="box">
            裘古怀，浙江宁波奉化人，1905年出生。他毕业于黄埔军校第四期，1926年加入中国共产党，后参与南昌起义并在潮汕战斗中负伤。1929年在杭州被捕，狱中秘密成立中共特别支部并担任宣传委员。1930年8月27日，在浙江陆军监狱英勇就义，年仅25岁。裘古怀烈士纪念馆的建成，不仅是对裘古怀烈士的缅怀和敬仰，更是对革命精神的传承和弘扬。纪念馆通过展示裘古怀烈士的生平事迹和革命精神，激励着一代又一代中华儿女为祖国发展繁荣而不懈奋斗。
          </div>
          <div class="h3">三、建筑布局与特点</div>
          <div class="box">
            纪念馆整体以白墙黑瓦的当地传统民居为设计蓝本，体现了厚重的历史文化气息和庄严肃穆的整体环境。纪念馆以“信仰之光”为主题，由“信仰·召唤”
            “信仰·确立” “信仰·追求” “信仰·坚定” “信仰·如磐”
            “信仰·升华”六个部分组成。通过感人至深的英雄事迹、鲜活生动的战斗场景和详细厚重的历史资料，以时间为轴完整地介绍了裘古怀烈士短暂而光荣的一生。
          </div>
          <div class="h3">四、参观与教育功能</div>
          <div class="box">
            裘古怀烈士纪念馆作为宁波市第三批党员教育示范基地暨红色基地，吸引了大量游客和参观者前来学习和缅怀革命先烈。这里不仅是缅怀裘古怀烈士的重要场所，更是进行爱国主义教育的重要资源。
            通过参观裘古怀烈士纪念馆，人们可以更加深入地了解中国共产党的历史、学习革命先烈的崇高精神。这对于广大党员干部、群众及青少年来说具有重要的教育意义。
          </div>
        </div>
      </div>
      <div class="img"><img src="./img/qgh2.jpeg" alt="" /></div>
      <div class="img"><img src="./img/qgh3.jpg" alt="" /></div>
      <div class="img"><img src="./img/qgh4.jpeg" alt="" /></div>
      <div class="img"><img src="./img/qgh5.jpeg" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "peopleIntro",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
.page {
  padding: 30px 10px;
  .people {
    .img {
      img {
        width: 100%;
        display: block;
      }
    }
    .title {
      text-align: center;
      font-weight: 600;
      font-size: 48px;
      color: #000000;
      line-height: 66px;
    }
    .content {
      font-weight: 400;
      font-size: 28px;
      color: #333333;
      line-height: 52px;
      margin: 20px 0;
    }
  }
}
</style>

import { render, staticRenderFns } from "./mallFinished.vue?vue&type=template&id=9b7578ac&scoped=true"
import script from "./mallFinished.vue?vue&type=script&lang=js"
export * from "./mallFinished.vue?vue&type=script&lang=js"
import style0 from "./mallFinished.vue?vue&type=style&index=0&id=9b7578ac&prod&lang=less&scoped=true"
import style1 from "./mallFinished.vue?vue&type=style&index=1&id=9b7578ac&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b7578ac",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"page",staticClass:"page",on:{"click":function($event){_vm.nowIndex = undefined}}},[_c('div',{ref:"content",staticClass:"message-box"},[_vm._l((_vm.list),function(t,i){return _c('div',{key:i,staticClass:"item"},[_c('div',{staticClass:"time"},[_vm._v(_vm._s(t.createTime))]),(t.direction == '1')?_c('div',{staticClass:"item-blue",on:{"touchstart":function($event){return _vm.gtouchstart(t, i)},"touchmove":function($event){return _vm.gtouchmove()},"touchend":function($event){return _vm.showDeleteButton(t, i)}}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(t.leaveWordContent.text))]),(
              t.leaveWordContent.imgs.length > 0 &&
              t.leaveWordContent.imgs[0] != ''
            )?_c('div',{staticClass:"imgBox"},_vm._l((t.textImgs),function(item,index){return _c('img',{key:index,staticClass:"textImg",attrs:{"src":item},on:{"click":function($event){return _vm.showImgClick(t.leaveWordContent.imgs, index)}}})}),0):_vm._e(),_c('img',{staticClass:"arrow",attrs:{"src":require("./img/blue-arrow.png"),"alt":""}}),(i == _vm.nowIndex)?_c('img',{staticClass:"arrow2",attrs:{"src":require("./img/black-arrow.png"),"alt":""}}):_vm._e(),(i == _vm.nowIndex)?_c('div',{staticClass:"operation-box"},[_c('div',{staticClass:"txt",on:{"click":function($event){$event.stopPropagation();return _vm.messageWithdraw(t, i)}}},[_vm._v("删除")])]):_vm._e()]),_c('img',{staticClass:"headImg",attrs:{"src":t.avatar || require('@/assets/img/default_avatar.png'),"alt":""}})]):_c('div',{staticClass:"item-grey"},[_c('img',{staticClass:"headImg",attrs:{"src":t.avatar || require('@/assets/img/default_avatar.png'),"alt":""}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(t.leaveWordContent.text))]),(
              t.leaveWordContent.imgs.length > 0 &&
              t.leaveWordContent.imgs[0] != ''
            )?_c('div',{staticClass:"imgBox"},_vm._l((t.textImgs),function(item,index){return _c('img',{key:index,staticClass:"textImg",attrs:{"src":item},on:{"click":function($event){return _vm.showImgClick(t.leaveWordContent.imgs, index)}}})}),0):_vm._e(),_c('img',{staticClass:"arrow",attrs:{"src":require("./img/grey-arrow.png"),"alt":""}}),(i == _vm.nowIndex)?_c('img',{staticClass:"arrow2",attrs:{"src":require("./img/black-arrow.png"),"alt":""}}):_vm._e(),(i == _vm.nowIndex)?_c('div',{staticClass:"operation-box"},[_c('div',{staticClass:"txt",on:{"click":function($event){$event.stopPropagation();return _vm.messageWithdraw(t, i)}}},[_vm._v("删除")])]):_vm._e()])])])}),_c('div',{ref:"bottomBox",staticClass:"bottomBox"})],2),_c('v-comments',{ref:"vComments",attrs:{"btnColor":"#fed661","isAutoClose":"","show":_vm.isShowComments,"showUploadImgBtn":_vm.showUploadImgBtn,"isShowOverlay":false,"maxlength":"50000","autosize":_vm.autoSizeObj,"zIndex":"0","top":"none"},on:{"update:show":function($event){_vm.isShowComments=$event},"acceptSend":function($event){return _vm.acceptSend(arguments)}},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('v-picList',{attrs:{"startPosition":_vm.startPosition,"imgList":_vm.picList},model:{value:(_vm.showImg),callback:function ($$v) {_vm.showImg=$$v},expression:"showImg"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
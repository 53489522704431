<template>
  <div class="section">
    <div class="section-search">
      <div class="search-input">
        <v-input v-model="goodsTitle" placeholder="请输入商品名称" />
      </div>
      <div class="search-button" @click="onRefresh">
        <img src="../img/search_icon.png" alt="" />
      </div>
    </div>
    <div class="section-list">
      <div class="list">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          :finishedText="finishedText"
          ref="list"
        >
          <div class="section-icon">限时秒杀</div>
          <div
            class="section-flex-item"
            v-for="(item, index) in listData"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="item-flex">
              <div class="item-picture">
                <img :src="item.imageUrl" alt="" />
              </div>
              <div class="item-detail">
                <div class="item-detail-title">
                  <span>{{ item.goodsTitle }}</span>
                </div>
                <div class="item-detail-now">
                  <span>￥{{ item.nowCost }}</span>
                  <span>仅剩{{ item.goodsRemainCount }}件</span>
                </div>

                <div class="item-detail-origin">
                  <span>￥{{ item.originalCost }}</span>
                </div>

                <div class="item-detail-time">
                  <div class="count-down">
                    <v-count-down
                      v-if="
                        item.status == 2 &&
                        item.getEndTime &&
                        getTime(item.getEndTime) >= 0
                      "
                      :time="getTime(item.getEndTime)"
                      format="DD 天 HH:mm:ss 后结束"
                    >
                    </v-count-down>
                    <span v-else>{{ statusMap[item.status] }}</span>
                  </div>
                  <span>{{ item.status == 3 ? "已结束" : "去秒杀" }}</span>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { issueGroupBuyingForMiniURL } from "./api.js";
// import {
// } from "./map.js";
import { gloabalCount, toRegister } from "@/utils/common";
// import { handleImg } from "@/utils/utils.js";
export default {
  data() {
    return {
      goodsTitle: "", // 关键字查询
      statusMap: {
        1: "未开始",
        2: "进行中",
        3: "已结束",
      },

      /* v-list */
      finishedText: "没有更多了",
      finished: true,
      listData: [
        // {
        //   title: "【当季现挖】土豆 约500g",
        //   price: 5.13,
        //   groupBookingNeed: "10",
        //   groupCount: "5",
        //   nowCost: "45.44",
        //   originalCost: "21.32",
        //   getEndTime: "2023-06-29",
        //   goodsRemainCount: 10,
        // },
        // {
        //   title: "【本地鲜】千岛湖青甘蓝800g/份",
        //   price: 7.19,
        //   groupBookingNeed: "110",
        //   groupCount: "52",
        //   nowCost: "145.44",
        //   originalCost: "61.32",
        //   getEndTime: "2023-06-25 16:30:00",
        //   goodsRemainCount: 20,
        // },
        // {
        //   title: "【三优蔬菜】香葱 约50g",
        //   price: 0.99,
        //   groupBookingNeed: "120",
        //   groupCount: "15",
        //   nowCost: "345.44",
        //   originalCost: "221.32",
        //   getEndTime: "2023-06-25 15:26:50",
        //   goodsRemainCount: 10,
        // },
      ],
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId", "openId"]),
  },
  async created() {
    gloabalCount("", 100, 1);
    if (await toRegister(this.$route.path, this.$route.query, "新商城")) {
      this.onRefresh();
    }
  },
  methods: {
    // 重置商户列表
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 获取商户列表
    onLoad() {
      let params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        // userId: this.userId,
        buyType: 2, // 1:社区团购 2:限时秒杀
        goodsTitle: this.goodsTitle,
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
      };
      this.$axios
        .get(`${issueGroupBuyingForMiniURL}`, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        });
    },
    // 点击前往详情
    toDetail(item) {
      this.$router.push({
        name: "bcnWelfarOrderDetail",
        query: {
          id: item.id,
          type: 2,
        },
      });
    },
    // 获取时间戳差额
    getTime(time) {
      const curTime = moment(time.replace(/-/g, "/"));
      const nowTime = moment();
      return curTime.diff(nowTime);
    },
  },
};
</script>

<style scoped lang="less">
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.section {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f9f9f9;
  padding-bottom: calc(
    20px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    20px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-search {
    display: flex;
    align-items: center;
    padding: 44px 30px;
    background: #ffffff;
    .search-input {
      flex: 1;
      padding: 0 20px;
      ::v-deep .van-cell {
        padding: 0 36px;
        background: #f7f7f7;
        border-radius: 30px;
        line-height: 64px;
      }
    }
    .search-button {
      width: 60px;
      text-align: center;
      > img {
        width: 36px;
        height: 40px;
        &:extend(.common-img);
      }
    }
  }
  .section-list {
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    padding: 30px 28px 0px;
    .list {
      background: #ffffff;
      border-radius: 16px;
      overflow: hidden;
      box-shadow: 0px 4px 20px 0px rgba(226, 226, 226, 0.5);
      .section-icon {
        width: 200px;
        height: 50px;
        background: linear-gradient(180deg, #fa8d51 0%, #f56b38 100%);
        border-radius: 0px 0px 16px 16px;
        margin-left: 22px;
        font-size: 30px;
        font-family: "BDZYJT--GB1-0", "BDZYJT--GB1";
        font-weight: normal;
        color: #ffeee4;
        line-height: 50px;
        text-align: center;
        margin-bottom: 10px;
      }
      .section-flex-item {
        // box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        // border-radius: 16px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        .item-flex {
          padding: 22px 20px;
          // border-top: 2px solid rgba(0, 0, 0, 0.06);
          display: flex;
          align-items: center;
          .item-picture {
            width: 170px;
            min-width: 170px;
            height: 170px;
            border-radius: 16px;
            overflow: hidden;
            margin-right: 28px;
            > img {
              &:extend(.common-img);
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .item-detail {
            flex: 1;
            width: calc(100% - 198px);
            .item-detail-title {
              display: flex;
              align-items: center;
              font-size: 32px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              line-height: 44px;
              span {
                white-space: nowrap;
                flex: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }
            }
            .item-detail-now {
              font-size: 38px;
              font-weight: bold;
              color: #d9634d;
              line-height: 52px;
              span:last-child {
                margin-left: 18px;
                font-size: 24px;
                font-weight: 400;
                color: #d9634d;
              }
            }
            .item-detail-origin {
              display: flex;
              align-items: center;
              span:first-child {
                flex: 1;
                text-decoration: line-through;
                font-size: 28px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.5);
              }
              span:last-child {
                font-size: 24px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
              }
            }
            .item-detail-time {
              margin-top: 8px;
              font-size: 28px;
              font-weight: 400;
              color: #d9634d;
              line-height: 40px;
              display: flex;
              align-items: center;
              .count-down {
                flex: 1;
                ::v-deep .van-count-down {
                  color: #d9634d;
                  font-size: 28px;
                  line-height: 40px;
                }
              }
              > span {
                background: #d9634d;
                border-radius: 8px;
                font-size: 26px;
                font-weight: 400;
                color: #ffffff;
                line-height: 36px;
                padding: 12px 22px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="donateList">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
      :commonOperaParams="commonOperaParams"
    >
      <div
        v-for="(item, index) in listData"
        :key="index"
        class="oItem"
        @click="toDetail(item)"
      >
        <div class="oItem-t">
          <div class="oItem-headName">
            <div class="oItem-avatar" v-if="item.donateUserPic">
              <img :src="item.donateUserPic" alt="" />
            </div>
            <div class="oItem-avatar" v-else>
              <img src="@/assets/img/default_avatar.png" alt="" />
            </div>
            <div class="oItem-tl oItem-name">
              {{
                item.donateUserName ? formatStrFunc(item.donateUserName) : ""
              }}
            </div>
          </div>
          <!--          <div class="oItem-tl">-->
          <!--            {{ item.donateUserName ? formatStrFunc(item.donateUserName) : "" }}-->
          <!--          </div>-->
          <div class="oItem-tr">
            <div class="oItem-tr oItem-time">{{ item.insertTime }}</div>
            <!--            {{ item.insertTime }}｜<span v-if="item.cancelStatus == 0">{{-->
            <!--              getStatus(item)-->
            <!--            }}</span>-->
            <!--            <span v-else>已取消</span>-->
          </div>
        </div>
        <div class="oItem-b">
          <div class="oItem-bl" v-if="item.donatePic">
            <img :src="item.donatePic" alt="" />
          </div>
          <div class="oItem-br">
            {{ item.donateContent }}
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { donateListUrl } from "./api.js";
import { gloabalCount } from "@/utils/common.js";
import { getOption, formatStr } from "@/utils/utils.js";
import { auditStatusMap } from "./map.js";
import { handleImg } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "donateList",
  data() {
    return {
      commonOperaParams: {
        createText: "去捐赠",
        listText: "我的捐赠",
        imgUrl: "listDonationBtn",
        isCommonOpera: true,
        name: "公益捐赠",
        operaUrl: "donate",
        myUrl: "myDonate",
      },
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // this.getActivityList();
    gloabalCount("", 11, 1);
  },
  methods: {
    formatStrFunc(str) {
      console.log(formatStr(str));
      if (str) {
        return formatStr(str);
      }
    },
    addClass() {},
    getStatus(item) {
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, auditStatusMap, "key").value;
      } else {
        return "";
      }
    },
    onLoad() {
      this.getWishList();
    },
    getWishList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
      };
      this.$axios.get(`${donateListUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.donateUserPic) {
              item.donateUserPic = handleImg(80, 80, item.donateUserPic);
            }
            if (item.donatePic) {
              item.donatePic = handleImg(200, 200, item.donatePic);
            }

            this.listData.push(item);
          });
        } else {
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "donateDetail",
        query: { id: item.donateId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.donateList {
  min-height: 100vh;
  background: #f7f7f7;
  position: relative;
  padding-bottom: 96px;
  box-sizing: border-box;
  ::v-deep .van-list {
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
  //.commonIcons {
  //  position: fixed;
  //  bottom: 40px;
  //  right: 40px;
  //}
  .oItem {
    background: #fff;
    padding: 20px 40px 40px 40px;
    //height: 260px;
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 10px;
    .oItem-t {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 40px;
      margin-bottom: 10px;
      .oItem-tr {
        span {
          color: #8fcbed;
        }
      }
    }

    .oItem-head {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      margin-bottom: 10px;
      align-items: center;
    }
    .oItem-headName {
      display: flex;
      align-items: center;
    }
    .oItem-avatar {
      width: 80px;
      height: 80px;
      /*border-radius:30px;*/
      /*border: 0.5px solid #999;*/
    }
    .oItem-avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      /*border: 0.5px solid #999;*/
    }
    .oItem-name {
      margin-left: 15px;
      font-size: 32px;
      font-weight: bold;
    }
    .oItem-time {
      font-size: 28px !important;
      font-weight: normal !important;
      color: #999999;
    }

    .oItem-b {
      font-size: 32px;
      width: 100%;
      display: flex;
      //align-items: center;
      margin-top: 20px;
      .oItem-bl {
        width: 200px;
        height: 200px;
        border-radius: 20px;
        margin-right: 40px;
        img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
        }
      }
      .oItem-br {
        flex: 1;
        overflow: scroll;
      }
      span {
        color: #f7a500;
      }
    }
  }
}
</style>

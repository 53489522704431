<template>
  <div class="page">
    <div class="tabs">
      <div
        :class="['tab', curTab.value === tab.value ? 'select' : '']"
        v-for="(tab, index) in tabsList"
        :key="index"
        @click="chooseTab(tab)"
      >
        <span>{{ tab.label }}</span>
      </div>
    </div>
    <status-check
      :deviceId="deviceId"
      :curTab="curTab"
      :positionDetail.sync="positionDetail"
      v-if="curTab.value == 1"
    />
    <checking-exceptions
      :deviceId="deviceId"
      :name="name"
      v-if="curTab.value == 2"
    />
    <device-settings
      :deviceId="deviceId"
      :imei="imei"
      :positionDetail="positionDetail"
      v-if="curTab.value == 3"
    />
  </div>
</template>

<script>
import statusCheck from "./statusCheck.vue";
import deviceSettings from "./deviceSettings/deviceSettings.vue";
import checkingExceptions from "./checkingExceptions.vue";

export default {
  components: {
    statusCheck,
    deviceSettings,
    checkingExceptions,
  },
  beforeRouteEnter(to, from, next) {
    console.log(to, from, next);
    next((vm) => {
      if (from.name && from.name != "elderCareBraceletList") {
        vm.curTab = {
          label: "设备设置",
          value: 3,
        };
      } else {
        vm.curTab = {
          label: "状态检测",
          value: 1,
        };
      }
    });
  },
  data() {
    return {
      imei: "", // 手环设备imei
      deviceId: "", // 设备唯一标识
      name: "", // 手表拥有者姓名
      curTab: {
        // label: "状态检测",
        // value: 1,
      },
      tabsList: [
        {
          label: "状态检测",
          value: 1,
        },
        {
          label: "异常查看",
          value: 2,
        },
        {
          label: "设备设置",
          value: 3,
        },
      ],
      positionDetail: {
        // 定位信息
        lon: "",
        lat: "",
      },
    };
  },
  created() {
    this.imei = this.$route.query.imei;
    this.deviceId = this.$route.query.deviceId;
    this.name = this.$route.query.name;
  },
  methods: {
    chooseTab(tab) {
      this.curTab = tab;
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  box-sizing: border-box;
  min-height: 100%;
  background: linear-gradient(
    180deg,
    rgba(220, 236, 220, 1),
    rgba(220, 236, 220, 0.15) 750px,
    rgba(255, 255, 255, 1)
  );
  padding: 20px 0px;
  /* 兼容 iOS < 11.2 */
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  .tabs {
    box-sizing: border-box;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
    background: rgba(255, 255, 255, 0.75);
    border-radius: 30px;
    padding: 0 50px;
    margin: 0 30px;
    .tab {
      &.select {
        font-size: 32px;
        font-weight: bold;
        color: #89ae89;
      }
    }
  }
}
</style>

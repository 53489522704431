<template>
  <div class="bestBeautiful">
    <div class="best-body">
      <div class="box">
        <div class="item">
          <div class="item-l">家长姓名：</div>
          <div class="item-r">
            <v-input
              v-model="form.parentName"
              :maxlength="50"
              placeholder="请填写"
            ></v-input>
          </div>
        </div>
        <div class="item">
          <div class="item-l">联系电话：</div>
          <div class="item-r">
            <v-input
              v-model="form.parentMobile"
              :maxlength="11"
              type="number"
              placeholder="请填写"
            ></v-input>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="item">
          <div class="item-l">子女姓名：</div>
          <div class="item-r">
            <v-input
              v-model="form.childrenName"
              :maxlength="50"
              placeholder="请填写"
            ></v-input>
          </div>
        </div>
        <div class="item">
          <div class="item-l">所在年级：</div>
          <div class="item-r">
            <v-input
              v-model="form.childrenGrade"
              :maxlength="50"
              placeholder="请填写"
            ></v-input>
          </div>
        </div>
        <div class="item">
          <div class="item-l">学号/身份证号：</div>
          <div class="item-r">
            <v-input
              v-model="form.childrenCardNum"
              :maxlength="50"
              placeholder="请填写"
            ></v-input>
          </div>
        </div>
      </div>
    </div>
    <div
      class="submit"
      v-if="oData == '' || oData.auditStatus == 2"
      @click="submit"
    >
      提交
    </div>
    <div class="submit" v-if="oData.auditStatus == 0">审核中</div>
  </div>
</template>

<script>
import store from "@/store";
import { queryAuditInfoUrl, submitAuditInfoUrl } from "./api";
export default {
  name: "bestBeautiful",
  data() {
    return {
      form: {
        parentName: "",
        parentMobile: "",
        childrenName: "",
        childrenGrade: "",
        childrenCardNum: "",
      },
      oData: "",
    };
  },
  async created() {
    this.queryAuditInfo();
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {},
  methods: {
    async submit() {
      if (!this.form.parentName) {
        this.$toast({ message: "请填写家长姓名", duration: 2000 });
        return;
      }
      if (!this.form.parentMobile) {
        this.$toast({ message: "请填写联系电话", duration: 2000 });
        return;
      }
      if (!this.form.childrenName) {
        this.$toast({ message: "请填写子女姓名", duration: 2000 });
        return;
      }
      if (!this.form.childrenGrade) {
        this.$toast({ message: "请填写所在年级", duration: 2000 });
        return;
      }
      if (!this.form.childrenCardNum) {
        this.$toast({ message: "请填写学号/身份证号", duration: 2000 });
        return;
      }
      let params = {
        parentName: this.form.parentName,
        parentMobile: this.form.parentMobile,
        childrenName: this.form.childrenName,
        childrenGrade: this.form.childrenGrade,
        childrenCardNum: this.form.childrenCardNum,
        tenantId: this.tenantId,
        houseId: this.houseId,
        userId: this.userId,
      };
      let res = await this.$axios.post(`${submitAuditInfoUrl}`, params);
      if (res.code == 200) {
        this.$toast({ message: "操作成功", duration: 2000 });
        this.form = {
          parentName: "",
          parentMobile: "",
          childrenName: "",
          childrenGrade: "",
          childrenCardNum: "",
        };
        this.queryAuditInfo();
      }
    },

    // 获取所需参数
    async queryAuditInfo() {
      let params = {
        userId: this.userId,
      };
      let url = `${queryAuditInfoUrl}`;
      let res = await this.$axios.get(`${url}`, { params });
      if (res.code == 200) {
        if (Object.keys(res.data).length == 0) {
        } else {
          this.oData = res.data;
          if (this.oData.auditStatus == 1) {
            this.$router.replace({
              name: "bestBeautifulRes",
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.bestBeautiful {
  min-height: 100vh;
  background: url("./img/back.png") no-repeat;
  background-size: 100% 100%;
  .best-body {
    padding: 80px 30px;
    .box {
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      padding: 0 30px;
      margin-bottom: 20px;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 46px 0 28px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        .item-l {
          font-size: 32px;
          font-weight: 400;
          color: #333333;
          line-height: 44px;
        }
        .item-r {
          /deep/ .van-cell {
            padding: 0;
            .van-field__control {
              text-align: right;
            }
          }
        }
      }
    }
  }

  .submit {
    width: 550px;
    height: 70px;
    background: #3781ff;
    border-radius: 16px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    line-height: 70px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 104px;
    margin: auto;
    color: #fff;
  }
  .submit-r {
    width: 550px;
    height: 70px;
    background: #eee;
    border-radius: 16px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    line-height: 70px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 104px;
    margin: auto;
    color: #fff;
  }
}
</style>

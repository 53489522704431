<template>
  <div class="wechatQrcode">
    <div class="wechatQrcode-box">
      <div class="logo"><img src="./img/logo.jpg" alt="" /></div>
      <div class="intro">
        <p>&nbsp;&nbsp;为人才多角度了解奉化创业政策、熟悉奉化</p>
        <p>创业环境，提供一个多方位的信息服务移动平台</p>
      </div>
      <div class="h3">长按识别二维码</div>
      <div class="h4">关注i创奉化</div>
      <div class="code"><img src="./img/wechat.png" alt="" /></div>
      <div class="title">关注i创奉化可获得以下服务</div>
      <div class="wechatQrcode-box-b">
        <div class="item">
          <div class="item-l">
            <img src="./img/rencaiCe.png" alt="" />
          </div>
          <div class="item-r">获取人才政策</div>
        </div>
        <div class="item">
          <div class="item-l">
            <img src="./img/map.png" alt="" />
          </div>
          <div class="item-r">查看创业地图</div>
        </div>
        <div class="item">
          <div class="item-l">
            <img src="./img/rencaiCode.png" alt="" />
          </div>
          <div class="item-r">申请人才码</div>
        </div>
        <div class="item">
          <div class="item-l">
            <img src="./img/rencai.png" alt="" />
          </div>
          <div class="item-r">人才之家预约</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wechatQrcode",
  data() {
    return {};
  },
  computed: {},
  async mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.wechatQrcode {
  padding: 28px 10px;
  padding-bottom: calc(28px + constant(safe-area-inset-bottom));
  padding-bottom: calc(28px + env(safe-area-inset-bottom));
  background: #f3f8fc;
  height: 100vh;
  box-sizing: border-box;
  .wechatQrcode-box {
    height: 100%;
    background: url("./img/back.png") no-repeat;
    background-size: 100% 100%;
    padding-top: 70px;
    box-sizing: border-box;
    .logo {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin: 0 auto;
      img {
        width: 100%;
        border-radius: 50%;
        height: 100%;
      }
    }
    .intro {
      margin: 16px auto 166px;
      p {
        text-align: center;
        font-size: 26px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 48px;
      }
    }
    .h3 {
      text-align: center;
      font-size: 26px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 48px;
    }
    .h4 {
      text-align: center;
      font-size: 26px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.35);
      line-height: 48px;
      margin: 8px 0 10px;
    }
    .code {
      width: 320px;
      height: 320px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      font-size: 26px;
      text-align: center;
      font-weight: 400;
      color: #5b5b5b;
      margin: 40px 0 16px;
      line-height: 48px;
    }
    .wechatQrcode-box-b {
      width: 578px;
      margin: 0 auto;
      height: 174px;
      background: #f7fafc;
      border-radius: 10px;
      padding: 24px 44px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        display: flex;
        flex-shrink: 1;
        margin-bottom: 28px;
        align-items: center;

        .item-l {
          width: 50px;
          height: 50px;
          margin: 0 10px 0 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-r {
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 48px;
        }
      }
    }
  }
}
</style>

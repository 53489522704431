<template>
  <div class="page">
    <div class="list">
      <div class="item">
        <div class="item-left"></div>
        <div class="item-right">
          <div class="title">面向大海春暖花开</div>
          <div class="info-box">
            <img src="./img/look.png" alt="" />
            <div class="lookNum">4001</div>
            <img src="./img/message.png" alt="" />
            <div class="messageNum">9281</div>
          </div>
        </div>
      </div>
      <div class="item"></div>
      <div class="item"></div>
      <div class="item"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 90px;
  .list {
    padding: 30px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 194px;
      background: #f9f9f9;
      border-radius: 16px;
      margin-bottom: 20px;
      display: flex;
      overflow: hidden;
      .item-right {
        flex: 1;
        padding: 44px 26px 0;
        box-sizing: border-box;
        .info-box {
          display: flex;
          align-items: center;
          .lookNum {
            margin-right: 30px;
          }
          div {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.25);
          }
          img {
            width: 32px;
            margin-right: 8px;
          }
        }
        .title {
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          margin-bottom: 40px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .item-left {
        width: 194px;
        height: 100%;
        background: #000;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
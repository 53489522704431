<template>
  <div class="page">
    <div class="infoBox">
      <div class="infoBox-left">
        <div class="img">
          <img :src="detail.topicPicture" alt="" />
        </div>
      </div>
      <div class="infoBox-right">
        <div class="title">{{ detail.topicTitle || "" }}</div>
        <div class="middle">
          <span>{{ detail.praiseCount || "0" }}</span>
          次点赞
          <span>{{ detail.readCount || "0" }}</span>
          次阅读
        </div>
        <div
          class="bottom"
          v-if="detail.nowStatus && detail.nowStatus.length > 0"
        >
          <span>
            {{ detail.nowStatus || "" }}
          </span>
        </div>
        <div class="time">{{ detail.createTime }}</div>
      </div>
    </div>
    <v-refresh v-model="isLoading" @refresh="onRefresh" class="main">
      <div class="content">
        <div
          class="text-empty"
          v-if="detail.topicContent && detail.topicContent.length === 0"
        >
          暂无文本
        </div>
        <div v-else v-html="detail.topicContent"></div>
        <div v-if="detail.topicInteriorView != ''">
          <img
            style="width: 100%"
            v-for="(item, index) in detail.topicInteriorView.split(',')"
            :src="item"
            :key="index"
          />
        </div>
      </div>
      <div class="greyLine"><div class="greyLineBox"></div></div>
      <div class="comments">
        <header>
          <span>评论</span><span>{{ commentsData.commentCount || "0" }}</span>
        </header>
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          ref="list"
          :finishedText="finishedText"
        >
          <div
            class="comments-main"
            v-for="(comment, index) in commentList"
            :key="index"
          >
            <div class="userPhoto">
              <img
                :src="
                  comment.avatar
                    ? comment.avatar
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
            </div>
            <div class="userInfo">
              <div class="info-title">{{ comment.userName }}</div>
              <div class="info-content">
                {{ comment.commentContent }}
              </div>
              <div class="picList" v-if="comment.commentPic != ''">
                <div
                  v-for="(item, index) in comment.commentPic.split(',')"
                  :key="index"
                  @click="showImgClick(comment.commentPic.split(','), index)"
                >
                  <img style="width: 100%" :src="item" alt="" />
                </div>
              </div>

              <div
                class="info-reply"
                v-if="JSON.stringify(comment.latestReply) != '{}'"
              >
                <div class="info-reply__content">
                  <span class="content-user">{{
                    comment.latestReply.fromUserName
                  }}</span>
                  <span
                    class="content-to-user"
                    v-if="comment.latestReply.toUserName"
                  >
                    回复 @{{ comment.latestReply.toUserName }}</span
                  >
                  <span class="content-colon">：</span>
                  <span class="content-text">
                    {{ comment.latestReply.replyContent }}
                  </span>
                </div>
                <div
                  class="info-reply__btn"
                  v-show="comment.replyCount > 0"
                  @click="toRouteReply(comment)"
                >
                  <span>共 {{ comment.replyCount }} 条回复</span>
                  <img src="./img/icon-arrow.png" alt="" />
                  <!-- <i class="el-icon-arrow-right"></i> -->
                </div>
              </div>
              <div class="info-foot">
                <div class="foot-time">{{ comment.createTime }}</div>
                <div class="foot-wrap">
                  <div class="iconText" @click="toComments(comment)">
                    <div class="icon">
                      <img src="./img/icon-msg.png" />
                    </div>
                  </div>
                  <div class="iconText" @click="toClickLike(comment)">
                    <div class="icon icon-up">
                      <img
                        v-if="!comment.hasPraised"
                        src="./img/icon-like.png"
                      />
                      <img v-else src="./img/icon-like-red.png" alt="" />
                    </div>
                    <div class="text">
                      <span :class="{ hasPraised: comment.hasPraised }">{{
                        comment.praiseCount
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
    <footer>
      <div class="footer-wrap">
        <div class="comment" @click="toComments(null)">
          <img src="./img/footer_edit.png" />
          <div>发表观点</div>
        </div>
        <div class="hot" @click="toClickLike(null)">
          <img v-if="!detail.hasPraised" src="./img/icon-like.png" />
          <img v-else src="./img/icon-like-red.png" alt="" />
          <span>点赞</span>
        </div>
      </div>
    </footer>
    <v-comments
      ref="vComments"
      v-model="text"
      isAutoClose
      :show.sync="isShowComments"
      @acceptSend="acceptSend(arguments)"
      :showUploadImgBtn="showUploadImgBtn"
    ></v-comments>
    <v-picList
      :startPosition="startPosition"
      v-model="showImg"
      :imgList="imgList"
    >
    </v-picList>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getTopicInfoByIdURL,
  cancelPraiseURL,
  addPraiseURL,
  getTopicCommentList,
  addCommentURL,
} from "./api";
import { vComments } from "@/components/control/index";
import { gloabalCount, toRegister } from "@/utils/common";

export default {
  name: "partnerStyleTitle",
  components: {
    vComments,
  },
  data() {
    return {
      startPosition: 0,
      showUploadImgBtn: true,
      showImg: false,
      imgList: [],
      id: "", // 记录详情页id
      isLoading: false,
      finished: false,
      finishedText: "没有更多了",
      curPage: 1,
      pageSize: 10,
      detail: {},
      commentList: [],
      commentsData: {},
      isShowComments: false,
      curReply: null, // 用于记录回复的哪一条评论
      text: "",
      commentPic: "",
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    const { id } = this.$route.query;
    this.id = id;
    this.getDetail();
    // this.onLoad();
  },
  mounted() {
    gloabalCount(this.id, 16, 1);
  },
  methods: {
    showImgClick(list, index) {
      this.showImg = true;
      this.imgList = list;
      this.startPosition = index;
    },
    // 获取详情
    getDetail() {
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast0
        forbidClick: true,
        message: "请求中...",
      });
      this.$axios
        .get(getTopicInfoByIdURL, {
          params: {
            id: this.id,
            userId: this.userId,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.detail = res.data;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicCommentList, {
          params: {
            topicId: this.id,
            userId: this.userId,
            curPage: this.curPage,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            this.finished = false;
            this.$refs.list.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            this.commentsData = res.data;
            if (this.curPage === 1) {
              this.commentList = res.data.records;
            } else {
              const list = this.commentList.map((v) => v.commentId);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.commentId) || this.commentList.push(item);
              });
            }
            this.curPage++;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 点赞和取消点赞
    toClickLike(data) {
      if (this.detail.closePraise === 1) {
        this.$toast({
          duration: 1500,
          message: "点赞功能已关闭",
        });
        return;
      }
      // item 存在则是评论点赞，反之则是话题点赞
      const item = data || this.detail;
      this.$axios
        .post(item.hasPraised ? cancelPraiseURL : addPraiseURL, null, {
          params: {
            targetId: data ? item.commentId : item.id, // commentId 评论主键id
            userId: this.userId,
            tenantId: this.tenantId || undefined,
            communityId: this.communityId || undefined,
            roomId: this.houseId || undefined,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res, "----");
            if (item.hasPraised) {
              // 取消点赞
              item.hasPraised = false;
              item.praiseCount -= 1;
            } else {
              // 点赞
              item.hasPraised = true;
              item.praiseCount += 1;
            }
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          // this.isLoading = false;
        });
    },
    // 点击评论图标跳出评论控件
    toComments(item) {
      if (this.detail.closeComment === 1) {
        this.$toast({
          duration: 1500,
          message: "评论功能已关闭",
        });
        return;
      }
      if (item == null) {
        this.showUploadImgBtn = true;
      } else {
        this.curReply = item;
        this.showUploadImgBtn = false;
      }
      this.isShowComments = true;
      // 获取焦点
      setTimeout(() => {
        this.$refs.vComments.getFocus();
      }, 500);
    },
    // 接受评论组件发送过来的信息
    acceptSend(data) {
      if (data[1].length > 0) {
        this.commentPic = data[1];
      }
      console.log(data);
      console.log(data[0].trim);
      console.log(data[0].trim.length);
      if (data[0].length == 0 && data[1].length == 0) {
        this.$toast({
          message: "请输入内容或上传图片",
          duration: 1500,
        });
        return;
      }
      const item = this.curReply;
      let params = {};
      if (item) {
        // 如果存在则为回复
        params = {
          topicId: item.topicId, // 话题id
          commentId: item.commentId, // 评论id
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 1, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: null, // 被回复人ID
          commentPic: this.commentPic,
        };
      } else {
        // 反之则为评论
        console.log(this.id);
        params = {
          topicId: this.id, // 话题id
          commentId: null, // 评论id
          // parentId: "", // 上级回复id 预留字段，评论类型为2的场合才可能有值，将来如果要实现回复树状展示时可以利用
          commentType: 0, // 评论类型 0：针对话题的评论；1：针对评论的回复；2：针对回复的回复
          commentContent: this.text, // 评论内容
          fromUser: this.userId, // 评论人ID
          toUser: null, // 被回复人ID
          commentPic: this.commentPic,
        };
      }
      // 1. 调接口
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast
        forbidClick: true,
        message: "发送中...",
      });
      this.$axios
        .post(addCommentURL, {
          ...params,
          tenantId: this.tenantId || undefined,
          communityId: this.communityId || undefined,
          roomId: this.houseId || undefined,
        })
        .then((res) => {
          if (res.code === 200) {
            // 2. 关闭弹框
            this.isShowComments = false;
            // 3. 关闭后清楚数据
            this.text = "";
            this.commentPic = "";
            // 4. 调评论接口
            this.onRefresh();
            // item.commentCount += 1;
            return;
          }
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "操作失败，请稍后重试！");
        })
        .finally(() => {
          this.$toast.clear();
        });
    },
    // 跳转回复页面
    toRouteReply(item) {
      this.$router.push({
        name: "replyDetail",
        query: {
          title: `${item.replyCount}条回复`,
          detail: JSON.stringify(item),
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  width: 100%;
  background: #ffffff;
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  .blackBg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  /* 兼容 iOS >= 11.2 */
  .infoBox {
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    .infoBox-right {
      .time {
        margin-top: 15px;
        font-size: 24px;
        color: #646668;
      }
      .bottom {
        font-size: 24px;
        margin-right: 32ux;
        span {
          background: #fff3eb;
          border-radius: 8px;
          border: 2px solid #ffc7a4;
          padding: 4px 12px;
          color: #fe6f16;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .middle {
        margin: 8px 0 8px;
        font-size: 24px;
        font-weight: 400;
        color: #646668;
        line-height: 36px;
        span:nth-last-child(1) {
          margin-left: 32px;
        }
      }
      .title {
        width: 100%;
        // text-overflow: -o-ellipsis-lastline;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // line-clamp: 2;
        // -webkit-box-orient: vertical;
        font-size: 36px;
        font-weight: 600;
        color: #323334;
        line-height: 52px;
        margin-top: 8px;
      }
    }
    .infoBox-left {
      .img {
        width: 192px;
        height: 192px;
        border-radius: 16px;
        margin-right: 24px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .main {
    box-sizing: border-box;
    padding: 0px 0px 128px 0px;
    margin-top: 32px;
    .greyLine {
      width: 100%;
      height: 32px;
      position: relative;
      .greyLineBox {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #f5f5f5;
      }
    }
    .content {
      width: 100%;
      overflow-x: hidden;
      box-sizing: border-box;
      margin-bottom: 80px;
      font-size: 32px;
      line-height: 1.5;
      color: #333333;
      word-wrap: break-word;
      padding: 0 32px;

      > .text-empty {
        height: 96px;
        line-height: 96px;
        background: #eeeeee;
        text-align: center;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      ::v-deep img {
        width: 100%;
      }
      ::v-deep video {
        width: 100%;
      }
    }

    .comments {
      > header {
        font-size: 32px;
        margin-left: 32px;
        padding-top: 20px;
        box-sizing: border-box;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #1a1c34;
        line-height: 44px;

        > span {
          &:nth-of-type(1) {
            margin-right: 8px;
          }

          // &:nth-of-type(2) {
          // }
        }
      }

      .comments-main {
        display: flex;
        margin-top: 32px;
        padding: 0 32px;
        box-sizing: border-box;

        .userPhoto {
          height: 72px;
          width: 72px;
          min-width: 72px;
          overflow: hidden;
          border-radius: 50%;

          > img {
            width: 100%;
            height: 100%;
          }
        }

        .userInfo {
          word-wrap: break-word;
          flex: 1;
          margin-left: 16px;
          max-width: calc(100% - 88px);
          .picList {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            div {
              width: 30%;
              height: 200px;
              margin-bottom: 15px;
              margin-right: 15px;
              background-color: black;
              position: relative;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
          .info-title {
            box-sizing: border-box;
            width: 100%;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .info-content {
            font-size: 32px;
            margin-top: 8px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 44px;
          }

          .info-reply {
            background: #f7f7f7;
            line-height: 40px;
            padding: 24px;
            margin-top: 24px;
            word-wrap: break-word;
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;

            .info-reply__content {
              .content-user,
              .content-colon {
                font-weight: bold;
                color: #333333;
              }

              .content-to-user {
                color: blur;
              }

              .content-text {
                font-weight: 400;
                color: #666666;
                vertical-align: middle;
              }
            }

            .info-reply__btn {
              margin-top: 8px;
              white-space: nowrap;
              font-weight: bold;
              color: #333333;
              display: flex;
              align-items: center;

              > span {
                font-weight: bold;
                color: #333333;
              }

              img {
                width: 24px;
                height: 24px;
              }
            }
          }

          .info-foot {
            margin-top: 24px;
            line-height: 32px;
            display: flex;

            .foot-time {
              color: #a8a8a8;
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #a8a8a8;
            }

            .foot-wrap {
              flex: 1;
              display: flex;
              justify-content: flex-end;

              > .iconText {
                display: flex;
                // margin-right: 32px;
                color: #a8a8a8;
                align-items: center;

                &:not(:last-child) {
                  margin-right: 32px;
                }

                .icon {
                  width: 32px;
                  height: 32px;
                  overflow: hidden;

                  &.icon-up {
                    > img {
                      margin-top: -2px;
                    }
                  }

                  > img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .text {
                  margin-left: 4px;
                  display: flex;
                  align-items: center;
                  height: 32px;

                  span {
                    &.hasPraised {
                      color: #ff853a;
                    }

                    // color: #ff853a;
                    font-size: 24px;
                    line-height: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // display: flex;
    // padding: 24px 0 24px;
    box-shadow: inset 0px 2px 0px 0px rgba(230, 230, 230, 1);
    background: #ffffff;
    padding-bottom: constant(safe-area-inset-bottom);
    /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom);

    /* 兼容 iOS >= 11.2 */
    .footer-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 10px 32px;
      box-sizing: border-box;
      .comment {
        flex: 1;
        margin: 8px 0;
        height: 80px;
        background: #007eff;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 32px;
        img {
          width: 40px;
          height: 40px;
          vertical-align: middle;
        }
        div {
          vertical-align: middle;
          margin-left: 8px;
          line-height: 40px;
        }
      }
      .hot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #323334;
        margin-left: 48px;
        img {
          width: 40px;
          height: 40px;
          margin-bottom: 8px;
        }

        span {
          line-height: 1;
          text-align: center;
          font-size: 24px;
          color: #333333;
        }
      }
    }
  }
}
</style>

<template>
  <div class="section">
    <div class="section-detail">
      <div class="detail-pic">
        <img :src="detail.picture" alt="" />
      </div>
      <div class="detail-title">
        {{ detail.title }}
      </div>
    </div>
    <div class="section-order">
      <div class="order-flex">
        <div class="order-flex-label">订单状态：</div>
        <div class="order-flex-value">{{ statusMap[detail.status] }}</div>
      </div>
      <div class="order-flex">
        <div class="order-flex-label">支付金额：</div>
        <div class="order-flex-value">{{ detail.totalFee }}元</div>
      </div>
      <div class="order-flex">
        <div class="order-flex-label">兑换数量：</div>
        <div class="order-flex-value">{{ detail.orderGoodsNum }}</div>
      </div>
      <div class="order-flex">
        <div class="order-flex-label">兑换时间：</div>
        <div class="order-flex-value">{{ detail.joinTime }}</div>
      </div>
      <div class="order-flex">
        <div class="order-flex-label">订单号：</div>
        <div class="order-flex-value">{{ detail.outTradeNo }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { myOrderDetailsURL } from "./api";

export default {
  data() {
    return {
      id: "",
      statusMap: {
        2: "待发货",
        4: "已完成",
        5: "已退款",
      },
      detail: {
        // id: "",
        // picture: "",
        // title: "",
        // totalFee: "",
        // orderGoodsNum: "",
        // joinTime: "",
        // outTradeNo: "",
        // status: undefined,
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const { id } = this.$route.query;
      this.id = id;
      this.getDetail();
    },
    getDetail() {
      this.$axios
        .get(myOrderDetailsURL, {
          params: {
            id: this.id,
            type: 4,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.detail = res.data;
            return;
          }
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.section {
  font-family: PingFangSC-Regular, PingFang SC;
  padding: 62px 32px 0;
  padding-bottom: calc(
    32px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    32px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  // .section-icon {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   img {
  //     &:extend(.common-img);
  //     width: 252px;
  //     height: 152px;
  //     margin-bottom: 30px;
  //   }
  //   span {
  //     text-align: center;
  //     font-size: 32px;
  //     font-weight: 400;
  //     color: #333333;
  //     line-height: 44px;
  //   }
  // }
  .section-detail {
    display: flex;
    align-items: center;
    background: #f7f7f7;
    padding: 18px 24px;
    border-radius: 16px;
    .detail-pic {
      width: 140px;
      min-width: 140px;
      height: 140px;
      margin-right: 32px;
      border-radius: 16px;
      overflow: hidden;
      img {
        &:extend(.common-img);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .detail-title {
      font-size: 32px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
  }
  .section-order {
    margin-top: 102px;
    font-size: 32px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 48px;
    .order-flex {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      display: flex;
      .order-flex-label,
      .order-flex-value {
        display: inline-block;
      }
      .order-flex-label {
        width: 180px;
        min-width: 180px;
      }
      .order-flex-value {
        flex: 1;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
  // .section-footer {
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   text-align: center;
  //   padding-bottom: calc(
  //     22px + constant(safe-area-inset-bottom)
  //   ); /* 兼容 iOS < 11.2 */
  //   padding-bottom: calc(
  //     22px + env(safe-area-inset-bottom)
  //   ); /* 兼容 iOS >= 11.2 */
  //   .footer-tip {
  //     margin-bottom: 32px;
  //     line-height: 40px;
  //     img {
  //       &:extend(.common-img);
  //       width: 28px;
  //       height: 28px;
  //     }
  //     span {
  //       margin-left: 10px;
  //       font-size: 28px;
  //       font-weight: 400;
  //       color: rgba(0, 0, 0, 0.5);
  //     }
  //   }
  //   .footer-btn {
  //     display: inline-block;
  //     width: 550px;
  //     height: 70px;
  //     line-height: 70px;
  //     background: #db431d;
  //     border-radius: 16px;
  //     font-size: 30px;
  //     font-weight: bold;
  //     color: #ffffff;
  //   }
  // }
}
</style>

<template>
  <div class="main">
    <div class="section-module">
      <div class="module-flex">
        <div
          class="module-location"
          @click="toRoute('elderCareBraceletMapLocation')"
        >
          <img src="../../img/mapLocation.png" alt="" />
          <span>地图定位</span>
        </div>
        <div
          class="module-trajectory"
          @click="toRoute('elderCareBraceletMapTrajectory')"
        >
          <img src="../../img/mapTrajectory.png" alt="" />
          <span>活动轨迹</span>
        </div>
        <div class="module-fence" @click="toRoute('elderCareBraceletMapFence')">
          <img src="../../img/mapFence.png" alt="" />
          <span>安全围栏</span>
        </div>
      </div>
      <div class="module-flex">
        <div
          class="module-number"
          @click="toRoute('elderCareBraceletEmergencyNum')"
        >
          <img src="../../img/emergencyNumber.png" alt="" />
          <span>紧急号码</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    deviceId: [Number, String],
    imei: [Number, String],
    positionDetail: Object,
  },
  data() {
    return {};
  },
  methods: {
    toRoute(name) {
      this.$router.push({
        name,
        query: {
          deviceId: this.deviceId,
          imei: this.imei,
          lon: this.positionDetail.lon,
          lat: this.positionDetail.lat,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  padding: 20px 30px;
  .section-module {
    .module-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
      .module-location,
      .module-trajectory,
      .module-fence {
        &:not(:last-child) {
          margin-right: 20px;
        }
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 0;
        background: rgba(255, 255, 255, 0.75);
        border-radius: 16px;
        box-shadow: 0 4px 16px 0 rgba(228, 228, 228, 0.5);
        > img {
          width: 76px;
          height: 76px;
          margin-bottom: 30px;
        }
        span {
          font-size: 28px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
        }
      }
    }
    .module-flex {
      background: transparent;
      .module-number {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
        background: rgba(255, 255, 255, 0.75);
        border-radius: 16px;
        box-shadow: 0 4px 16px 0 rgba(228, 228, 228, 0.5);
        > img {
          width: 76px;
          height: 76px;
          margin-right: 30px;
        }
        span {
          font-size: 28px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="page">
    <img src="./img/stage.jpg" alt="" />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.page {
  min-height: 100%;
  img {
    width: 100%;
    object-fit: cover;
  }
}
</style>

<template>
  <div class="sureSubmit">
    <div class="participants">
      <div class="title">
        <div class="title-text">参与人</div>
      </div>
      <div class="body">
        <div class="newPeople">
          <div class="item" v-for="(item, index) in peopleList" :key="index">
            <div
              :class="['item-t', { isSelect: item.isSelect }]"
              @click="selectItem(item)"
            >
              <img
                class="avatar"
                :src="
                  item.applicantsPic
                    ? item.applicantsPic
                    : require('@/assets/img/default_avatar.png')
                "
                alt=""
              />
              <!-- <img
                class="family"
                v-if="item.isFamily == 1"
                src="./img/family.png"
                alt=""
              /> -->
              <!-- 自己不能删除 -->
              <div
                class="delete"
                @click.stop="deleteRelation(item)"
                v-if="index !== 0"
              >
                <img src="./img/delete.png" alt="" />
              </div>
              <div v-if="item.isFamily == 1" class="family">家人</div>
              <div class="select">
                <img src="./img/user_select.png" alt="" />
              </div>
            </div>
            <div class="item-c" @click="toEdit(item, index)">
              <div class="name">
                <span>{{ item.applicantsName }}</span
                ><img v-show="index !== 0" src="./img/edit.png" alt="" />
              </div>
              <div class="tel">{{ item.applicantsPhone }}</div>
            </div>
            <div class="item-b" @click.stop="openRemarkDialog(item)">
              <div class="item-bl">
                <img src="./img/edit_blue.png" alt="" />
              </div>
              <div class="item-br">备注</div>
            </div>
          </div>
          <div
            class="item te"
            @click="toAdd"
            v-if="
              activityDetail.activityCategory != 1 &&
              activityDetail.activityCategory != 19
            "
          >
            <div class="item-t"><img src="./img/newAdd.png" alt="" /></div>
            <div class="item-c">
              <div class="name">添加成员</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="partDetail">
      <div class="title">
        <div class="title-text">参与详情</div>
      </div>
      <div class="body">
        <div class="actiName">
          <div class="item">
            <div class="item-l">活动名称</div>
            <div class="item-r">{{ activityDetail.activityName }}</div>
          </div>
        </div>
        <div class="time">
          <div class="item">
            <div class="item-l">报名开始时间</div>
            <div class="item-r">
              {{ activityDetail.applyStartDate }}
            </div>
          </div>
          <div class="item">
            <div class="item-l">活动开始时间</div>
            <div class="item-r">
              {{ activityDetail.startDate }}
            </div>
          </div>
        </div>
        <div class="address">
          <div class="item">
            <div class="item-l">活动地点</div>
            <div class="item-r">
              {{ activityDetail.activityAddress }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnSubmit">
      <div class="submit" @click="sureApply">确认提交</div>
    </div>
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <div class="tips">
        <div class="tips-t">确定要删除这个参与人?</div>
      </div>
    </v-dialog>

    <v-dialog
      v-model="isRemarkDialog"
      title="提交备注"
      @confirm="confirmRemark"
    >
      <div class="textarea">
        <v-input
          v-model="remark"
          placeholder="请填写备注"
          type="textarea"
          :maxlength="150"
          :showWordLimit="true"
        ></v-input>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  getActivityDetailUrl,
  addPeopleListUrl,
  applyActivityUrl,
  userInfoUrl,
  deletePeopleUrl,
  activityDetailUrl,
  applicantsNoteDetailsUrl,
  compileApplicantsNoteUrl,
} from "./api.js";

export default {
  name: "sureSubmit",
  data() {
    return {
      applicantsId: "",
      isDialog: false,
      addSelf: "",
      joinUserCount: 1,
      activityDetail: {},
      peopleList: [],
      // applicantsIdList: [],
      activityId: "",
      scheduleId: "",
      timer: null,
      applyId: "",
      userInfo: {},
      num: "",
      activityCategory: undefined, // 活动类型 20-时间银行 19-幸福学堂 1-热门活动
      /* 弹框 */
      isRemarkDialog: false,
      curRemarkJoin: {},
      remark: "", // 备注信息
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    applicantsIdList() {
      return this.peopleList
        .filter((v) => v.isSelect)
        .map((v) => v.applicantsId);
    },
  },
  async mounted() {
    await this.getActivityDetail();
    this.getPeopleList();
  },
  created() {
    this.activityId = this.$route.query.activityId;
    this.addSelf = this.$route.query.addSelf;
    this.scheduleId = this.$route.query.scheduleId;
    this.activityCategory = Number(this.$route.query.activityCategory);
    if (this.$route.query.applyId) {
      this.applyId = this.$route.query.applyId;
    } else {
      this.applyId = 0;
    }
  },
  methods: {
    // 备注
    openRemarkDialog(item) {
      this.curRemarkJoin = item;
      this.isRemarkDialog = true;
      this.applicantsNoteDetails();
    },
    // 获取备注详情
    applicantsNoteDetails() {
      this.$axios
        .get(applicantsNoteDetailsUrl, {
          params: {
            applicantsId: this.curRemarkJoin.applicantsId,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.remark = res.data;
          } else {
            this.$toast({ message: res.msg, duration: 300 });
          }
        });
    },
    // 提交备注
    confirmRemark() {
      if (!this.remark) {
        this.$toast({ message: "备注不可为空" });
        return;
      }
      this.$axios
        .post(compileApplicantsNoteUrl, {
          note: this.remark,
          applicantsId: this.curRemarkJoin.applicantsId,
        })
        .then((res) => {
          if (res.code === 200) {
            this.isRemarkDialog = false;
          }
          this.$toast({ message: res.msg, duration: 1000 });
        });
    },

    // 删除确认弹框
    deleteRelation(item) {
      this.isDialog = true;
      this.applicantsId = item.applicantsId;
    },
    // 确认删除接口
    confirm() {
      this.addSelf = "";
      let params = {
        userId: this.userId,
        applicantsId: this.applicantsId,
      };
      this.$axios.post(deletePeopleUrl, null, { params }).then((res) => {
        this.isDialog = false;
        if (res.code === 200) {
          this.getPeopleList();
          this.$toast({ message: "操作成功", duration: 300 });
        } else {
          if (res.msg) {
            this.$toast({ message: res.msg, duration: 300 });
          }
        }
      });
    },
    // 确认提交
    sureApply() {
      if (this.applicantsIdList.length === 0) {
        this.$toast({ message: "请选择需要报名参与人", duration: 300 });
        return;
      }
      console.log(this.applicantsIdList, "this.applicantsIdList---");
      let params = {
        activityId: this.activityId,
        applicantsIdList: this.applicantsIdList,
        houseId: this.houseId,
        userId: this.userId,
        scheduleId: this.scheduleId,
      };
      this.$axios.post(applyActivityUrl, params).then((res) => {
        if (res.code === 200) {
          this.$toast({ message: "报名成功", duration: 300 });
          // this.$router.replace({
          //   name: "myActivityList",
          // });
          this.$router.replace({
            name: "tennagersComplete",
          });
        } else {
          if (res.msg) {
            this.$toast({ message: res.msg, duration: 300 });
          }
        }
      });
    },
    selectItem(item) {
      if (item.isSelect) {
        item.isSelect = !item.isSelect;
      } else {
        if (
          this.applicantsIdList.length >= this.activityDetail.perLimitCount &&
          this.activityDetail.perLimitCount != 0
        ) {
          this.$toast({ message: "单次报名参与人已达上限", duration: 300 });
          return;
        }
        if (this.applicantsIdList.length >= this.activityDetail.limitCount) {
          this.$toast({ message: "活动参与人已达上限", duration: 300 });
          return;
        }
        item.isSelect = !item.isSelect;
      }
    },
    toEdit(item, index) {
      // this.$router.replace({
      //   name: "addUser",
      //   query: {
      //     activityId: this.activityId,
      //     applicantsId: item.applicantsId,
      //     isEdit: 1,
      //     scheduleId: this.scheduleId,
      //   },
      // });
      // 自己是不能进行修改的
      if (index === 0) {
        return;
      }
      this.$router.push({
        name: "addUser",
        query: {
          activityId: this.activityId,
          applicantsId: item.applicantsId,
          isEdit: 1,
          scheduleId: this.scheduleId,
          activityCategory: this.activityCategory,
        },
      });
    },
    toAdd() {
      // if (
      //   this.applicantsIdList.length >= this.activityDetail.perLimitCount &&
      //   this.activityDetail.perLimitCount != 0
      // ) {
      //   this.$toast({ message: "单次报名参与人已达上限", duration: 300 });
      //   return;
      // }
      // if (this.applicantsIdList.length >= this.activityDetail.limitCount) {
      //   this.$toast({ message: "活动参与人已达上限", duration: 300 });
      //   return;
      // }
      // this.$router.replace({
      //   name: "addUser",
      //   query: { activityId: this.activityId, scheduleId: this.scheduleId },
      // });
      this.$router.push({
        name: "addUser",
        query: {
          activityId: this.activityId,
          scheduleId: this.scheduleId,
          activityCategory: this.activityCategory,
        },
      });
    },
    // 获取参与人列表
    getPeopleList() {
      let _this = this;
      // this.applicantsIdList = [];
      let params = {
        activityId: this.activityId,
        scheduleId: this.scheduleId,
        userId: this.userId,
        addSelf: this.addSelf,
      };

      this.$axios.get(`${addPeopleListUrl}`, { params }).then((res) => {
        console.log(res, 5555);
        if (res.code === 200) {
          if (this.activityCategory == 20) {
            this.peopleList = res.data.records
              ? res.data.records.map((v) => ({
                  ...v,
                  isSelect: false,
                }))
              : [];
          } else {
            this.peopleList = res.data.records
              ? res.data.records
                  .filter((v) => v.applicantsRelation == 0)
                  .map((v) => ({
                    ...v,
                    isSelect: true,
                  }))
              : [];
          }
          // this.peopleList.forEach((ele) => {
          //   this.applicantsIdList.push(ele.applicantsId);
          // });
        } else {
          if (res.msg) {
            this.$toast({ message: res.msg, duration: 300 });
          }
        }
      });
    },
    // 获取活动详情
    async getActivityDetail() {
      let params = {
        activityId: this.activityId,
        userId: this.userId,
      };

      let res = await this.$axios.get(`${getActivityDetailUrl}`, { params });
      if (res.code === 200) {
        this.activityDetail = res.data;
      } else {
        return res;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.sureSubmit {
  box-sizing: border-box;
  min-height: 100vh;
  padding-bottom: calc(138px + constant(safe-area-inset-bottom));
  padding-bottom: calc(138px + env(safe-area-inset-bottom));
  // margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  // margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .title {
    height: 44px;
    position: relative;
    font-size: 32px;
    font-weight: bold;
    color: #000000d9;
    line-height: 44px;
    .title-text {
      width: 174px;
      text-align: center;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 174px;
      height: 16px;
      background: #e2e2e2;
      border-radius: 7px;
    }
  }
  .participants {
    background: #ffff;
    padding: 32px 32px 0;
    .body {
      padding: 32px 0 82px;
    }
    .newPeople {
      .item {
        position: relative;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        margin: 0 24px;
        width: 160px;
        height: 262px;
        .item-t {
          box-sizing: border-box;
          position: relative;
          width: 112px;
          height: 112px;
          margin: 0 auto;
          border-radius: 50%;
          // overflow: hidden;
          > img {
            width: 100%;
            height: 100%;
            overflow: hidden;
            vertical-align: middle;
          }
          > img.avatar {
            border-radius: 50%;
          }
          > .delete {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 22px;
            height: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            > img {
              vertical-align: middle;
              width: 100%;
              height: 100%;
            }
          }
          > .family {
            position: absolute;
            bottom: 0px;
            right: -66px;
            // width: 28px;
            // height: 28px;
            font-size: 24px;
            line-height: 36px;
            color: #3680ff;
            border: 1px solid #3680ff;
            padding: 0 8px;
            white-space: nowrap;
          }

          .select {
            border: 2px solid #ffffff;
            display: none;
            position: absolute;
            border-radius: 50%;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #ffffff88;
          }
          &.isSelect {
            .select {
              border: 2px solid #3780ff;
              display: flex;
              justify-content: center;
              align-items: center;
              // position: absolute;
              // top: 0;
              // left: 0;S
              // display: flex;
              // justify-content: center;
              // align-items: center;
              // width: 100%;
              // height: 100%;
              img {
                width: 60px;
                height: 45px;
                vertical-align: middle;
                overflow: hidden;
              }
            }
          }
        }
        .item-c {
          .name,
          .tel {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .name {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 32px;
            font-weight: bold;
            color: #323334;
            margin: 8px 0;
            line-height: 44px;
            span {
              max-width: calc(100% - 28px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            img {
              margin-left: 8px;
              width: 20px;
              min-width: 20px;
              height: 20px;
              overflow: hidden;
            }
          }
          .tel {
            font-size: 24px;
            font-weight: 400;
            color: #323334;
            line-height: 36px;
            margin-bottom: 8px;
          }
        }
        .item-b {
          display: flex;
          align-items: center;
          justify-content: center;
          .item-bl {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .item-br {
            font-size: 24px;
            font-weight: 400;
            color: rgba(48, 124, 255, 1);
            line-height: 24px;
          }
        }
      }
      .te {
        .item-c {
          .name {
            font-size: 28px;
            font-weight: bold;
            // color: #cbcccd;
            color: rgba(0, 0, 0, 0.5);
            line-height: 44px;
          }
        }
      }
    }
    .person {
      font-size: 30px;
      padding: 20px 0 0 0;
      .item {
        display: flex;
        margin-bottom: 16px;
        .item-l {
          width: 180px;
        }
      }
      .num {
        display: flex;
        .item-l {
          width: 180px;
        }
        .item-r {
          flex: 1;
        }
      }
    }
    .people {
      .item {
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
        .item-l {
          display: flex;
          align-items: center;
          .headImg {
            width: 48px;
            height: 48px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .name {
            width: 300px;
            font-size: 30px;
            margin: 0 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .tel {
            font-size: 30px;
          }
        }
        .item-r {
          display: flex;
          align-items: center;
          .code {
            display: flex;
            align-items: center;
            .code-l {
              width: 40px;
              height: 40px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .code-r {
              font-size: 30px;
            }
          }
          .arrow {
            width: 40px;
            height: 40px;
            margin-left: 16px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 222px;
      height: 60px;
      border-radius: 4px;
      margin: auto;
      margin-top: 60px;
      border: 2px solid #009aff;
      color: #009aff;
      .btn-l {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .btn-r {
        font-size: 30px;
      }
    }
  }
  .partDetail {
    background: #ffff;
    padding: 0 32px;
    .body {
      padding: 32px 0 0 0;
    }
    .actiName,
    .time,
    .address {
      .item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 32px;
      }
      .item-l {
        width: 168px;
        margin-right: 48px;
        font-size: 28px;
        font-weight: 400;
        color: #323334;
        line-height: 40px;
      }
      .item-r {
        flex: 1;
        font-size: 28px;
        font-weight: 400;
        color: #97999b;
        line-height: 40px;
      }
    }
    .people {
    }
  }
  .btnSubmit {
    width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 40px;
    left: 0;
    display: flex;
    justify-content: center;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
    .submit {
      width: 550px;
      height: 66px;
      font-size: 28px;
      line-height: 66px;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
      border-radius: 10px;
    }
  }
  .tips {
    .tips-t {
      font-size: 34px;
      text-align: center;
      padding: 0 20px;
      margin: 40px 0;
    }
  }
  .textarea {
    margin: 24px;
    border: 2px solid #cccccc;
  }
}
</style>
<style lang="less"></style>

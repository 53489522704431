<template>
  <div class="page">
    <div class="contentBox">
      <div class="title">发起提议</div>
      <div class="item">
        <div class="left"><span>*</span>提议主题</div>
        <div class="right">
          <input
            type="text"
            style="background: #fff"
            :disabled="flag"
            v-model="proposeTitle"
          />
        </div>
      </div>
      <div class="item-content">
        <div class="title">会议内容</div>
        <v-input
          type="textarea"
          placeholder="请输入会议内容"
          :showWordLimit="true"
          :maxlength="200"
          v-model="proposeContent"
          :disabled="flag"
        ></v-input>
      </div>
      <div class="uploadTitle">照片上传</div>
      <div class="upload">
        <v-upload
          class="oItem-b_upload"
          :imgUrl.sync="proposeAnnexFiles"
          :activityPicture.sync="proposeAnnexFiles"
          ref="load"
          :maxCount="9"
          :disabled="flag"
        ></v-upload>
      </div>
    </div>
    <div class="btn" v-if="!flag" @click="submit">提交</div>
  </div>
</template>

<script>
import { proposeEventUrl, getMyProposeEventDetailUrl } from "./api.js";
import { mapState } from "vuex";
export default {
  name: "initiateProposal",
  data() {
    return {
      proposeTitle: null,
      proposeContent: null,
      proposeAnnexFiles: null,
      flag: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    if (this.$route.query.id) {
      this.flag = true;
      this.getDetail(this.$route.query.id);
    }
  },
  methods: {
    getDetail(id) {
      let params = {
        id: id,
        userId: this.userId,
      };
      this.$axios
        .get(`${getMyProposeEventDetailUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            this.proposeTitle = res.data.proposeTitle;
            this.proposeContent = res.data.proposeContent;
            this.proposeAnnexFiles = res.data.proposeAnnexFiles;
          }
        });
    },
    submit() {
      if (!this.proposeTitle) {
        this.$toast("请输入提议主题");
        return;
      }
      if (!this.proposeContent) {
        this.$toast("请输入会议内容");
        return;
      }
      if (this.$refs.load.status == 3) {
        this.$toast("图片正在上传中");
        return;
      }
      if (!this.proposeAnnexFiles) {
        this.$toast("请上传图片");
        return;
      }
      let params = {
        tenantId: this.tenantId,
        proposeUser: this.userId,
        proposeTitle: this.proposeTitle,
        proposeContent: this.proposeContent,
        proposeAnnexFiles: this.proposeAnnexFiles,
      };
      this.$axios.post(`${proposeEventUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.$toast(res.msg);
          this.proposeTitle = null;
          this.proposeContent = null;
          this.proposeAnnexFiles = null;
        }
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
  padding: 52px 30px;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    margin: 84px auto;
  }
  .contentBox {
    background: #ffffff;
    box-shadow: 0px 0px 14px 0px rgba(220, 220, 220, 0.5);
    border-radius: 16px;
    padding: 30px 30px 50px;
    box-sizing: border-box;
    margin-bottom: 22px;
    .uploadTitle {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      padding: 38px 0 20px 0;
    }
    .upload {
      width: 100%;
      display: flex;
      .oItem-b_upload {
        margin: 32px 0 16px;

        ::v-deep .van-uploader__preview {
          border: 2px dashed #eee;
        }

        ::v-deep .van-uploader__preview-image {
          width: 160px !important;
          height: 160px !important;
          box-sizing: border-box;
        }

        ::v-deep .van-uploader__upload {
          width: 160px !important;
          height: 160px !important;
          box-sizing: border-box;
        }

        ::v-deep .van-uploader__upload {
          margin: 0;
        }
      }
    }
    .item-content {
      padding-top: 50px;
      padding-bottom: 38px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);

      .title {
        padding-bottom: 20px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .item {
      display: flex;
      height: 112px;
      border-bottom: 2px solid rgba(0, 0, 0, 0.06);
      line-height: 112px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      .left {
        width: 200px;
        span {
          color: #ff602a;
        }
      }
      .right {
        flex: 1;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        text-align: right;
        input {
          width: 100%;
          height: 100%;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          text-align: right;
        }
      }
    }
    .title {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
}
</style>
import { render, staticRenderFns } from "./stepsFour.vue?vue&type=template&id=bb41d4cc&scoped=true"
import script from "./stepsFour.vue?vue&type=script&lang=js"
export * from "./stepsFour.vue?vue&type=script&lang=js"
import style0 from "./stepsFour.vue?vue&type=style&index=0&id=bb41d4cc&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb41d4cc",
  null
  
)

export default component.exports
<template>
  <van-tabs
    ref="tabs"
    :value="value"
    :type="type"
    :color="color"
    :background="background"
    :duration="duration"
    :line-width="lineWidthRem"
    :line-height="lineHeightRem"
    :animated="animated"
    :border="border"
    :ellipsis="ellipsis"
    :sticky="sticky"
    :swipeable="swipeable"
    :lazy-render="lazyRender"
    :scrollspy="scrollspy"
    :offset-top="offsetTop"
    :swipe-threshold="swipeThreshold"
    :title-active-color="titleActiveColor"
    :title-inactive-color="titleInactiveColor"
    :before-change="beforeChange"
    @input="onInput"
    @click="onClick"
    @change="onChange"
    @rendered="onRendered"
    @scroll="onScroll"
    @disabled="onClickDisabled"
  >
    <slot></slot>
    <template #nav-left>
      <slot name="nav-left"></slot>
    </template>
    <template #nav-right>
      <slot name="nav-right"></slot>
    </template>
  </van-tabs>
</template>

<script>
import { Tabs } from "vant";

export default {
  model: {
    prop: "value",
    event: "input",
  },
  components: {
    vanTabs: Tabs,
  },
  props: {
    // v-model 绑定值
    value: [String, Number],
    // // 标签数据
    // tabs: {
    //   type: Array,
    //   default() {
    //     /*
    //       title  标题
    //       disabled 是否禁用标签 false
    //       dot 是否在标题右上角显示小红点 false
    //       name 标签名称，作为匹配的标识符 标签的索引值
    //       url 点击后跳转的链接地址
    //       to 点击后跳转的目标路由对象，同 vue-router 的 to 属性
    //       replace 是否在跳转时替换当前页面历史 false
    //       title-style 自定义标题样式
    //       title-class 自定义标题类名
    //     */
    //     return [];
    //   },
    // },
    // 样式风格类型，可选值为 card
    type: {
      type: String,
      default: "line",
    },
    // 标签主题色
    color: {
      type: String,
      default: "#FE9616",
    },
    // 标签栏背景色
    background: {
      type: String,
      default: "white",
    },
    // 动画时间，单位秒
    duration: {
      type: [Number, String],
      default: "0.3",
    },
    // 底部条宽度，默认单位为2倍px - 自动转为rem
    lineWidth: {
      type: Number,
      default: 34,
    },
    // 底部条高度，默认单位为2倍px - 自动转为rem
    lineHeight: {
      type: Number,
      default: 6,
    },
    // 是否开启切换标签内容时的转场动画
    animated: {
      type: Boolean,
      default: false,
    },
    // 是否显示标签栏外边框，仅在 type="line" 时有效
    border: {
      type: Boolean,
      default: false,
    },
    // 	是否省略过长的标题文字
    ellipsis: {
      type: Boolean,
      default: true,
    },
    // 是否使用粘性定位布局
    sticky: {
      type: Boolean,
      default: false,
    },
    // 是否开启手势滑动切换
    swipeable: {
      type: Boolean,
      default: false,
    },
    // 是否开启延迟渲染（首次切换到标签时才触发内容渲染）
    lazyRender: {
      type: Boolean,
      default: true,
    },
    // 是否开启滚动导航
    scrollspy: {
      type: Boolean,
      default: false,
    },
    // 粘性定位布局下与顶部的最小距离,支持 px vw vh rem 单位，默认 px
    offsetTop: {
      type: [Number, String],
      default: 0,
    },
    // 滚动阈值，标签数量超过阈值且总宽度超过标签栏宽度时开始横向滚动
    swipeThreshold: {
      type: [Number, String],
      default: 5,
    },
    //标题选中态颜色
    titleActiveColor: {
      type: String,
      default: "#FE9616",
    },
    // 	标题默认态颜色
    titleInactiveColor: {
      type: String,
      default: "#a9a9a9",
    },
    beforeChange: Function,
  },
  data() {
    return {};
  },
  computed: {
    lineWidthRem() {
      return `${this.lineWidth / this.$baseUnit}rem`;
    },
    lineHeightRem() {
      return `${this.lineHeight / this.$baseUnit}rem`;
    },
  },
  methods: {
    onInput(val) {
      this.$emit("input", val);
    },
    // 点击标签时触发
    onClick(name, title) {
      this.$emit("click", name, title);
    },
    // 当前激活的标签改变时触发
    onChange(name, title) {
      // val 当前分值
      this.$emit("change", name, title);
    },
    // 标签内容首次渲染时触发（仅在开启延迟渲染后触发）
    onRendered() {},
    // 滚动时触发，仅在 sticky 模式下生效
    onScroll() {},
    // 监听禁用标签的点击事件
    onClickDisabled(name, title) {
      this.$emit("disabled", name, title);
    },
    // 外层元素大小或组件显示状态变化时，可以调用此方法来触发重绘
    resize() {
      this.$refs.tabs.resize();
    },
  },
};
</script>

<style lang="less" scoped></style>

<template>
  <div class="page">
    <div class="bannerBox">
      <img src="./img/banner.png" alt="" />
    </div>
    <div class="titleBox" @click="toPath('propertyNoticeList')">
      <div class="titleBox-left">物业公告</div>
      <div class="titleBox-center">更多</div>
      <div class="titleBox-right">
        <img src="./img/titile-arrow.png" alt="" />
      </div>
    </div>
    <div class="list" v-if="list.length > 0">
      <div
        class="item"
        @click="toDetail('propertyNoticeDetail', item.id)"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="item-left">
          <img :src="item.topicPicture" alt="" />
        </div>
        <div class="item-right">
          <div class="name">
            {{ item.topicTitle || "" }}
          </div>
          <div class="time">{{ item.createTime }}</div>
          <div class="detailInfo">
            <img src="./img/look.png" />
            <div>{{ item.readCount }}</div>
            <img src="./img/message.png" />
            <div>{{ item.commentCount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="titleBox" v-if="showPoints">
      <div class="titleBox-left">物业评分</div>
    </div>
    <div class="ratBox" @click="toPath('propertyRat')" v-if="showPoints">
      <div class="inRatBox">
        <div class="left">
          <img src="./img/touxiang.png" alt="" />
        </div>
        <div class="right">
          <div class="title">上个月物业评分</div>
          <div class="starList">
            <img
              v-for="(item, index) in starSelectNum"
              :key="index"
              src="./img/starSelect.png"
              alt=""
            />
            <img
              v-for="(item, index) in starNum"
              :key="index"
              src="./img/star.png"
              alt=""
            />
          </div>
          <div class="thisMonthBtn">评价本月</div>
        </div>
      </div>
    </div>
    <div class="titleBox">
      <div class="titleBox-left">工单上报</div>
    </div>
    <div class="optionBox">
      <div class="inOptionBoxBox">
        <div class="inOptionBoxBox-left" @click="toPath('clapCasually')">
          <div class="left">
            <img src="./img/suishoupai.png" alt="" />
          </div>
          <div class="right">
            <div class="txt1">随手拍</div>
            <div class="txt2">上报类工单</div>
          </div>
        </div>
        <div class="inOptionBoxBox-right" @click="toPath('reportForRepair')">
          <div class="left">
            <img src="./img/baoxiu.png" alt="" />
          </div>
          <div class="right">
            <div class="txt1">服务工单</div>
            <div class="txt2">维修类工单</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gloabalCount, toRegister } from "@/utils/common";
import { getTopicListURL, getPropertyInfoByHouseId } from "./api.js";
import { handleImg } from "@/utils/utils.js";
export default {
  name: "propertyServices",
  data() {
    return {
      list: [],
      starNum: 0,
      starSelectNum: 0,
      showPoints: true,
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    gloabalCount("", 84, 1);
    this.getList();
    this.getPoint();
  },
  methods: {
    getPoint() {
      let params = {
        houseId: this.roomId,
      };
      this.$axios
        .get(`${getPropertyInfoByHouseId}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            if (!res.data.evaluateScore) {
              this.showPoints = false;
              return;
            }
            this.starSelectNum = Number(res.data.evaluateScore);
            this.starNum = 5 - this.starSelectNum;
          }
        });
    },
    getList() {
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        curPage: 1,
        pageSize: 3,
        topicType: 23,
        sortField: "NEW", // 排序方式， 最热 or 最新
      };
      this.$axios.get(`${getTopicListURL}`, { params: params }).then((res) => {
        if (res.code == 200) {
          res.data.records.forEach((item) => {
            item.topicPicture = handleImg(220, 220, item.topicPicture);
          });
          this.list = res.data.records;
        }
      });
    },
    toDetail(name, id) {
      this.$router.push({
        name: name,
        query: {
          id: id,
        },
      });
    },
    toPath(path) {
      this.$router.push({
        name: path,
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
  padding-bottom: 70px;
  .bannerBox {
    width: 100%;
    height: 350px;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .titleBox {
    width: 100%;
    padding: 20px 40px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    .titleBox-left {
      font-size: 36px;
      font-weight: 600;
      color: #333333;
    }
    .titleBox-center {
      flex: 1;
      text-align: right;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);
    }
    .titleBox-right {
      margin-left: 10px;
      width: 12px;
      height: 20px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .list {
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 220px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      overflow: hidden;
      display: flex;
      margin-bottom: 20px;
      .item-left {
        width: 220px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-right {
        flex: 1;
        height: 100%;
        padding: 28px 32px;
        box-sizing: border-box;
        .name {
          width: 400px;
          font-size: 32px;
          font-weight: 600;
          color: #333333;
          margin-bottom: 42px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .time {
          font-size: 26px;
          color: rgba(0, 0, 0, 0.25);
          margin-bottom: 10px;
        }
        .detailInfo {
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.25);
          img {
            width: 34px;
          }
          div {
            margin-left: 8px;
            margin-right: 30px;
          }
        }
      }
    }
  }
  .ratBox {
    width: 100%;
    padding: 0 32px 20px;
    box-sizing: border-box;
    .inRatBox {
      width: 100%;
      height: 220px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      display: flex;
      padding: 38px 46px;
      box-sizing: border-box;
      position: relative;
      .left {
        width: 144px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 30px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        flex: 1;
        font-size: 34px;
        color: #333333;
        padding-top: 24px;
        .starList {
          margin-top: 14px;
          display: flex;
          img {
            width: 36px;
            height: 36px;
            margin-right: 14px;
          }
        }
      }
      .thisMonthBtn {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 194px;
        height: 62px;
        background: linear-gradient(180deg, #9793ff 0%, #5e5aff 100%);
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px 0px 16px 0px;
        line-height: 62px;
        text-align: center;
        font-size: 26px;
        color: #ffffff;
      }
    }
  }
  .optionBox {
    width: 100%;
    box-sizing: border-box;
    padding: 0 32px;
    .inOptionBoxBox {
      height: 204px;
      width: 100%;
      display: flex;
      .inOptionBoxBox-left,
      .inOptionBoxBox-right {
        flex: 1;
        height: 100%;
        margin-right: 26px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
        border-radius: 16px;
        display: flex;
        padding: 42px 26px;
        box-sizing: border-box;
        .left {
          width: 120px;
          margin-right: 20px;
          img {
            width: 100%;
          }
        }
        .right {
          padding-top: 20px;
          .txt1 {
            font-size: 32px;
            font-weight: 600;
            color: #0f0e47;
            margin-bottom: 4px;
          }
          .txt2 {
            font-size: 28px;
            color: #0f0e47;
            white-space: nowrap;
          }
        }
      }
      .inOptionBoxBox-right {
        margin-right: 0;
      }
    }
  }
}
</style>

<template>
  <div class="epidemicPrevent">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="header">
        <img src="./img/header_bg.png" alt="图片加载失败..." />
      </div>
      <div class="registerReport">
        <div class="item" @click="toEpidemicRegister(1)">
          <div class="item-l"><img src="./img/inRegister.png" alt="" /></div>
          <div class="item-r">自主申报</div>
        </div>
        <div class="item" @click="toEpidemicRegister(2)">
          <div class="item-l"><img src="./img/outRegister.png" alt="" /></div>
          <div class="item-r">
            <p>外来人口</p>
            <p>登记</p>
          </div>
        </div>
      </div>
      <div class="title" @click="toList">
        <div class="title-l">防疫资讯</div>
        <div class="title-r">
          <div class="more">更多</div>
          <div class="img"><img src="./img/icon-arrow.png" alt="" /></div>
        </div>
      </div>
      <div class="main">
        <div
          class="list-content"
          v-for="(item, index) in listData"
          :key="index"
          @click="toRouteTitle(item)"
        >
          <div class="list-sidebar">
            <img :src="item.topicPicture" />
          </div>
          <div class="list-wrapper">
            <div class="left-title">
              <div class="img" v-if="item.isTop == 1">
                <img src="./img/hot.png" />
              </div>
              <div class="name">{{ item.topicTitle }}</div>
            </div>
            <div class="left-content">
              {{ item.remark || "" }}
            </div>
            <div class="date">
              {{ item.createTime || "" }}
            </div>
            <div class="left-wrap">
              <div class="iconText">
                <div class="icon">
                  <img src="./img/icon-hot.png" />
                </div>
                <div class="text">
                  <span>{{ item.readCount }}</span>
                </div>
              </div>
              <div class="iconText">
                <div class="icon icon-up">
                  <img src="./img/icon-msg.png" />
                </div>
                <div class="text">
                  <span>{{ item.commentCount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-refresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { getTopicListURL } from "./api.js";
import { handleImg } from "@/utils/utils.js";
import { gloabalCount } from "@/utils/common.js";

export default {
  name: "epidemicPrevent",
  components: {},
  data() {
    return {
      navKey: "NEW",
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curData: null,
      curPage: 1,
      pageSize: 5,
      curIndex: undefined, // 当前点击的索引
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {},
  mounted() {
    gloabalCount("", 70, 1);
    this.onLoad();
  },
  methods: {
    toEpidemicRegister(data) {
      this.$router.push({
        name: "epidemicRegister",
        query: {
          formsType: data,
        },
      });
    },
    toList() {
      this.$router.push({
        name: "epidemicNews",
      });
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            tenantId: this.tenantId,
            userId: this.userId,
            houseId: this.houseId || this.communityId || undefined,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 16, // 1. 和伙人风采 2.超话 3. 活动 4.明珠广播站 16.防疫资讯
            curPage: this.curPage,
            pageSize: this.pageSize,
            sortField: this.navKey, // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data && res.data.records) {
            res.data.records.forEach((item) => {
              if (item.topicPicture) {
                item.topicPicture = handleImg(192, 192, item.topicPicture);
              }
            });
            this.listData = res.data.records;
          }
        })
        .catch(() => {})
        .finally(() => {});
    },
    onRefresh() {
      this.curPage = 1;
      // this.listData = [];
      this.onLoad();
    },

    // 点击跳转话题详情页
    toRouteTitle(item) {
      if (item.editType == 0) {
        let params = {
          id: item.id,
        };
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/epidemicNewsDetail/index?params=${JSON.stringify(
              params
            )}`,
          });
        }
      } else if (item.editType == 1) {
        wx.miniProgram.navigateTo({
          url: `/pages/webview/index?url=${item.externalUrl}`,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.epidemicPrevent {
  box-sizing: border-box;
  padding-bottom: constant(safe-area-inset-bottom);
  /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom);
  /* 兼容 iOS >= 11.2 */
  ::v-deep .van-pull-refresh {
    min-height: 100vh;
  }
  .header {
    width: 100%;
    height: 480px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .registerReport {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    margin: -104px 0 54px;
    .item {
      width: 330px;
      height: 204px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      display: flex;
      padding: 40px 24px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      .item-l {
        width: 122px;
        height: 122px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-r {
        font-size: 36px;
        font-weight: 600;
        color: #0f0e47;
        line-height: 50px;
        text-align: center;
      }
    }
  }
  .title {
    padding: 0 30px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-l {
      font-size: 36px;
      padding-left: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 50px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 182px;
        height: 14px;
        background: #e2e2e2;
        border-radius: 7px;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
    }
    .title-r {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .more {
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
      }
      .img {
        width: 14px;
        height: 22px;
        margin-left: 12px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .main {
    box-sizing: border-box;
    margin: 0px 0 0 0;
    .list-content {
      border-radius: 16px;
      background: #ffffff;
      display: flex;
      overflow: hidden;
      margin: 0 32px 32px 32px;
      height: 256px;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      .list-sidebar {
        width: 256px;
        min-width: 256px;
        height: 100%;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .list-wrapper {
        flex: 1;
        max-width: calc(100% - 256px);
        padding: 36px 30px 24px 28px;
        box-sizing: border-box;

        .left-title {
          font-size: 32px;
          font-weight: bold;
          color: #333333;
          line-height: 44px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-bottom: 12px;
        }

        .left-content {
          margin-bottom: 66px;

          > span {
            padding: 4px 10px;
            box-sizing: border-box;
            background: #fff3eb;
            border-radius: 4px;
            border: 2px solid #ffc7a4;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fe6f16;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        .date {
          font-size: 26px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 36px;
        }
        .left-wrap {
          display: flex;
          align-items: center;
          .iconTag {
            flex: 1;
            overflow: hidden;
            margin-right: 8px;
            display: flex;
            align-items: center;

            > span {
              height: 32px;
              display: flex;
              align-items: center;
              max-width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              border-radius: 4px;
              font-size: 24px;
              background: #fff3eb;
              border-radius: 8px;
              border: 2px solid #ffc7a4;
              padding: 0px 8px;
              color: #fe6f16;
            }
          }

          .iconText {
            display: flex;
            color: #a8a8a8;
            align-items: center;

            &:not(:last-child) {
              margin-right: 32px;
            }

            .icon {
              display: flex;
              align-items: center;

              &.icon-up {
                > img {
                  margin-top: 4px;
                }
              }

              > img {
                width: 36px;
                height: 36px;
              }
            }

            .text {
              margin-left: 8px;
              display: flex;
              align-items: center;
              height: 36px;
              line-height: 36px;

              span {
                &.hasPraised {
                  color: #ff853a;
                }

                font-size: 24px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('v-refresh',{on:{"refresh":_vm.onRefresh},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('header',{staticClass:"header"},[_c('img',{attrs:{"src":require("./img/header_bg.png"),"alt":"图片加载失败..."}})]),_c('div',{staticClass:"search-input"},[_c('v-input',{attrs:{"leftIcon":"search","placeholder":"请输入关键字"},on:{"blur":_vm.handelBlurTitle},model:{value:(_vm.topicTitle),callback:function ($$v) {_vm.topicTitle=$$v},expression:"topicTitle"}})],1),_c('div',{staticClass:"main"},[_c('div',{staticClass:"nav"},_vm._l((_vm.navList),function(item){return _c('div',{key:item.key,class:{ 'nav-index': _vm.navKey === item.key },on:{"click":function($event){return _vm.setNavKey(item.key)}}},[_c('span',[_vm._v(_vm._s(item.label))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.navKey === item.key),expression:"navKey === item.key"}],staticClass:"spot"})])}),0),_c('v-list',{ref:"list",attrs:{"finished":_vm.finished,"finishedText":_vm.finishedText},on:{"onLoad":_vm.onLoad}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"list-content",on:{"click":function($event){return _vm.toRouteTitle(item)}}},[_c('div',{staticClass:"list-sidebar"},[_c('img',{attrs:{"src":item.topicPicture}})]),_c('div',{staticClass:"list-wrapper"},[_c('div',{staticClass:"left-title"},[(item.isTop == 1)?_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("./img/hot.png")}})]):_vm._e(),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.topicTitle))])]),_c('div',{staticClass:"left-content"},[_vm._v(" "+_vm._s(item.remark || "")+" ")]),(_vm.calculateTime(item.createTime) && _vm.tenantId != 'P1C2A2')?_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(item.createTime || "")+" ")]):_vm._e(),_c('div',{class:[
                'left-wrap',
                {
                  active:
                    !_vm.calculateTime(item.createTime) || _vm.tenantId == 'P1C2A2',
                },
              ]},[_c('div',{staticClass:"iconText"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("./img/icon-hot.png")}})]),_c('div',{staticClass:"text"},[_c('span',[_vm._v(_vm._s(item.readCount))])])]),_c('div',{staticClass:"iconText"}),(item.signinCount != '')?_c('div',{staticClass:"iconText"},[_c('div',{staticClass:"icon icon-up"},[_c('img',{attrs:{"src":require("./img/icon-signFor.png")}})]),_c('div',{staticClass:"text"},[_c('span',[_vm._v(_vm._s(item.signinCount))])])]):_vm._e()])])])}),0)],1)]),_c('v-comments',{ref:"vComments",attrs:{"isAutoClose":"","show":_vm.isShowComments},on:{"update:show":function($event){_vm.isShowComments=$event},"acceptSend":_vm.acceptSend},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
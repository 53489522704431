// 获取一刻钟商品icon图
const getMallTypeIconListURL = `/gateway/hc-serve/miniapi/quarterMall/getMallTypeIconList`;
// 团购/秒杀列表
const issueGroupBuyingForMiniURL = `/gateway/hc-serve/mini/communityBuy/issueGroupBuyingForMini`;
// 查询商户列表
const getShopInfoListURL = `/gateway/hc-serve/miniapi/quarterMall/getShopInfoList`;
// 校验团长状态
const checkIsLeaderURL = `/gateway/hc-serve/mini/communityBuy/checkIsLeader`;
// 话题列表接口 45 新商城广播
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;

export {
  getMallTypeIconListURL,
  issueGroupBuyingForMiniURL,
  getShopInfoListURL,
  checkIsLeaderURL,
  getTopicListURL,
};

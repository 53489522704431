<template>
  <div class="page">
    <div class="message-box">
      <img src="./img/message-icon.png" alt="" />
      如遇法定节假日，请先与管理员确认场地是否开放
    </div>
    <!-- <div class="tabBox">
      <div class="tab-box">
        <div class="tab-item" v-for="(item, index) in tabList" :key="index">
          <div class="name">{{ item.name }}</div>
          <img src="./img/down-arrow.png" alt="" />
        </div>
      </div>
    </div> -->
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toPath(item)"
        >
          <div class="left">
            <img :src="item.picUrl" alt="" />
          </div>
          <div class="right">
            <div class="top">
              <div class="name">{{ item.name }}</div>
              <img src="./img/order-num-icon.png" alt="" />
              <div class="num">{{ item.totalDegree }}次</div>
            </div>
            <div class="center">
              <div class="size">{{ item.area }}㎡</div>
              <div class="line"></div>
              <div class="num">{{ item.count }}人</div>
            </div>
            <div class="bottom">
              {{ item.address }}
              <div class="order-btn">立即预订</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <div class="btn" @click="toMySpace">我的预约</div>
  </div>
</template>

<script>
import { getAreaListUrl } from "./api.js";
import { handleImg } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "spaceReservation",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      loading: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
        date: moment(new Date()).format("YYYY-MM-DD"),
        type: "类型",
        typeId: "",
        num: "人数",
        districtCode: "", //区域编码
        streetId: "", //街道id
        structuringId: "", //社区id
        placeName: "地点",
        minCount: "", //最小容纳人数
        maxCount: "", //最大容纳人数
      },
      tabList: [
        {
          name: "类型",
        },
        {
          name: "地点",
        },
        {
          name: "人数",
        },
      ],
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    gloabalCount("", 93, 1);
  },
  methods: {
    init() {
      this.requestData.curPage = 1;
      this.listData = [];
      this.$refs.list._data.loading = true;
      this.finished = false;
      this.getAreaList();
    },
    onLoad() {
      this.getAreaList();
    },
    getAreaList() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        date: this.requestData.date,
        minCount: this.requestData.minCount,
        maxCount: this.requestData.maxCount,
        typeId: this.requestData.typeId,
      };
      this.$axios
        .get(`${getAreaListUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.picUrl && item.picUrl.length > 0) {
                item.picUrl = handleImg(220, 220, item.picUrl);
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    toMySpace() {
      this.$router.push({
        name: "myReservation",
      });
    },
    toPath(item) {
      this.$router.push({
        name: "spaceDetail",
        query: {
          placeId: item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 68px;
  background: #fafafa;
  .list {
    padding: 0 30px;
    box-sizing: border-box;
    .item {
      height: 220px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      margin-bottom: 30px;
      display: flex;
      overflow: hidden;
      .right {
        flex: 1;
        padding: 38px 0px 0 22px;
        box-sizing: border-box;
        position: relative;
        .bottom {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          box-sizing: border-box;
          display: flex;
          padding-left: 22px;
          align-items: center;
          justify-content: space-between;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          height: 62px;
          .order-btn {
            width: 194px;
            height: 62px;
            background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
            box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
            border-radius: 16px 0px 16px 0px;
            text-align: center;
            line-height: 62px;
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .center {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          .line {
            width: 2px;
            height: 16px;
            background: #979797;
            margin: 0 16px;
          }
        }
        .top {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          padding-right: 32px;
          box-sizing: border-box;
          .num {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
          }
          img {
            width: 28px;
            margin-right: 4px;
          }
          .name {
            flex: 1;
            font-size: 32px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
      .left {
        width: 224px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .tabBox {
    padding: 0 30px;
    height: 92px;
    margin: 24px 0 22px 0;
    .tab-box {
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      border-radius: 16px;
      display: flex;
      .tab-item {
        width: 100%;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .name {
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.5);
          margin-right: 30px;
        }
        img {
          width: 28px;
        }
      }
    }
  }
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    line-height: 66px;
    text-align: center;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 102px;
  }
  .message-box {
    height: 88px;
    margin-bottom: 22px;
    background: #fffceb;
    line-height: 88px;
    display: flex;
    align-items: center;
    padding: 0 36px;
    box-sizing: border-box;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    img {
      width: 38px;
      height: 36px;
      margin-right: 16px;
    }
  }
}
</style>
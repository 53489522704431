<template>
  <div class="main">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <v-list
        :finished="finished"
        :finishedText="finishedText"
        @onLoad="onLoad"
        ref="list"
      >
        <div
          class="list-content"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="list-flex">
            <div class="flex-wrapper">
              <div class="wrapper-title">
                <span>{{ name }}</span>
              </div>
              <div class="wrapper-date">
                <span>{{ item.createTime }}</span>
              </div>
            </div>
            <div class="flex-content">
              <span class="warning">{{
                `${typeMap[item.type]}${item.abnormalVal}${item.unit}`
              }}</span>
              &nbsp;
              <span class="tips">请及时关注</span>
            </div>
          </div>
        </div>
      </v-list>
    </v-refresh>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { fetchDeviceAbnormalListURL } from "../api.js";

export default {
  props: {
    deviceId: [Number, String],
    name: String,
  },
  data() {
    return {
      isLoading: false,
      finished: true, // 列表是否加载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      typeMap: {
        1: "走出电子围栏范围",
        2: "血压异常",
        3: "血氧异常",
        4: "心率异常",
      },
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.onRefresh();
  },
  methods: {
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },

    // 加载列表数据
    onLoad() {
      let params = {
        deviceId: this.deviceId,
        curPage: this.curPage,
        pageSize: this.pageSize,
      };
      this.$axios
        .get(fetchDeviceAbnormalListURL, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  padding: 20px 30px 20px;
  padding-bottom: calc(
    20px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    20px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  ::v-deep .van-pull-refresh__track {
    min-height: calc(100vh - 240px);
  }

  .list-content {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    padding: 32px 24px 40px;
    overflow: hidden;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(228, 228, 228, 0.5);
    background: rgba(255, 255, 255, 0.75);
    .list-flex {
      .flex-wrapper {
        display: flex;
        align-items: center;
        .wrapper-title {
          flex: 1;
          color: rgba(0, 0, 0, 0.85);
          font-size: 30px;
          line-height: 42px;
        }
        .wrapper-date {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .flex-content {
        margin-top: 28px;
        word-wrap: break-word;
        word-break: break-all;
        font-size: 32px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        .warning {
          color: #e02020;
        }
        // .tips {
        // }
      }
    }
  }
}
</style>

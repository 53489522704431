<template>
  <div class="page">
    <div class="searchBox">
      <div class="search-l">
        <input type="text" v-model="topicTitle" placeholder="请输入内容" />
      </div>
      <div class="search-r" @click="search()">搜索</div>
    </div>
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div class="list">
        <div
          class="item"
          v-for="(t, i) in listData"
          @click="toRouteTitle(t)"
          :key="i"
        >
          <div class="top" v-if="t.topicInteriorView.length > 0">
            <img :src="t.topicInteriorView[0]" alt="" />
          </div>
          <div class="name">{{ t.topicTitle }}</div>
          <div class="bottom">
            <img src="./img/message-icon.png" alt="" />
            <div>{{ t.commentCount }}</div>
          </div>
        </div>
      </div>
    </v-list>
    <div class="btn" @click="isShowComments = true">闲置求购</div>
    <v-comments
      btnColor="#fed661"
      ref="vComments"
      v-model="form.topicTitle"
      isAutoClose
      :show.sync="isShowComments"
      @acceptSend="acceptSend(arguments)"
      :showUploadImgBtn="showUploadImgBtn"
    ></v-comments>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { shoppingListUrl, postBuyingLeadsUrl } from "./api";
import { handleImg } from "@/utils/utils.js";
import { vComments } from "@/components/control/index";
export default {
  name: "ldlePurchaseList",
  components: {
    vComments,
  },
  data() {
    return {
      topicTitle: "",
      nowId: null,
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      //
      isShowComments: false,
      showUploadImgBtn: true,
      text: "",
      form: {
        topicTitle: "",
        topicInteriorView: "",
      },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  mounted() {},
  methods: {
    toRouteTitle(item) {
      this.$router.push({
        name: "ldlePurchaseDetail",
        query: {
          id: item.id,
        },
      });
    },
    acceptSend(data) {
      if (data[1].length > 0) {
        this.form.topicInteriorView = data[1];
      }
      console.log(this.form.topicTitle);
      if (data[0].length == 0 || data[1].length == 0) {
        this.$toast({
          message: "请输入内容和上传图片",
          duration: 1500,
        });
        return;
      }
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
        topicTitle: this.form.topicTitle,
        topicInteriorView: this.form.topicInteriorView,
        closePraise: 1, //关闭点赞,
        topicType: 42,
        status: 1,
      };
      this.$axios.post(`${postBuyingLeadsUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.isShowComments = false;
          this.form.topicTitle = "";
          this.form.topicInteriorView = "";
          this.requestData.curPage = 1;
          this.finished = false;
          this.listData = [];
          this.onLoad();
        }
      });
    },
    search() {
      this.requestData.curPage = 1;
      this.finished = false;
      this.listData = [];
      this.onLoad();
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        topicType: 42,
        curPage: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        topicTitle: this.topicTitle,
      };
      this.$axios
        .get(`${shoppingListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.topicInteriorView) {
                item.topicInteriorView = item.topicInteriorView.split(",");
                item.topicInteriorView[0] = handleImg(
                  332,
                  332,
                  item.topicInteriorView[0]
                );
              }
              this.listData.push(item);
            });
          } else {
            this.finished = true;
          }
        });
    },
    toPath(name) {
      this.$router.push({
        name: name,
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 12px 30px 140px 30px;
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 114px;
    left: 50%;
    transform: translateX(-50%);
  }
  .list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: 332px;
      margin-bottom: 44px;
      .bottom {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        img {
          display: block;
          margin-right: 14px;
          width: 28px;
          height: 26px;
        }
      }
      .name {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 12px;
      }
      .top {
        width: 100%;

        border-radius: 14px;
        overflow: hidden;
        margin-bottom: 14px;
        img {
          width: 100%;
          height: 332px;
        }
      }
    }
  }
  .searchBox {
    width: 100%;
    height: 74px;
    background: #ffffff;
    border-radius: 37px;
    border: 4px solid #fee60f;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 42px;
    .search-r {
      width: 150px;
      height: 100%;
      background: #fee60f;
      border: 2px solid #fee60f;
      text-align: center;
      border-radius: 37px;
      font-size: 28px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 66px;
    }
    .search-l {
      flex: 1;
      height: 100%;
      padding: 0 48px;
      box-sizing: border-box;
      input {
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 66px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
</style>

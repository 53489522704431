// 我的订单-列表
const myOrderListURL = `/gateway/hc-serve/mini/communityBuy/myOrderList`;
// 我的订单-详情
const myOrderDetailsURL = `/gateway/hc-serve/mini/communityBuy/myOrderDetails`;
// 我的订单-下单
const trolleyOrderURL = `/gateway/hc-serve/mini/communityBuy/trolleyOrder`;
// 我的订单-取消订单
const cancelOrderURL = `/gateway/hc-serve/mini/communityBuy/cancelTrolleyOrder`;

// 完成订单-提交
const submitGoodsGetInfoURL = `/gateway/hc-serve/mini/communityBuy/submitGoodsGetInfo`;
// 完成订单-详情
const getLeaderAndShopOrderDetailsURL = `/gateway/hc-serve/mini/communityBuy/getLeaderAndShopOrderDetails`;
// 完成订单-列表
const getLeaderAndShopOrderListURL = `/gateway/hc-serve/mini/communityBuy/getLeaderAndShopOrderList`;

// 订单消息-列表
const myOrderNewListURL = `/gateway/hc-serve/mini/communityBuy/myOrderNewList`;
// 订单消息-详情
const myOrderNewDetailsURL = `/gateway/hc-serve/mini/communityBuy/myOrderNewDetails`;
// 订单消息-数量
const myOrderNewCountURL = `/gateway/hc-serve/mini/communityBuy/myOrderNewCount`;

// 初始化查询表头
const initQueryParamsURL = `/gateway/hc-serve/mini/communityBuy/initQueryParams`;
// 初始化查询表头
const receiveGoodsURL = `/gateway/hc-serve/mini/communityBuy/receiveGoods`;
// 获取已下单的取货地点列表
const getOrderAddressListInfo = `/gateway/hc-serve/mini/communityBuy/getOrderAddressListInfo`;

export {
  myOrderListURL,
  myOrderDetailsURL,
  trolleyOrderURL,
  cancelOrderURL,
  submitGoodsGetInfoURL,
  getLeaderAndShopOrderDetailsURL,
  getLeaderAndShopOrderListURL,
  myOrderNewListURL,
  myOrderNewDetailsURL,
  myOrderNewCountURL,
  initQueryParamsURL,
  receiveGoodsURL,
  getOrderAddressListInfo
};

const routes = [
  //众创空间列表
  {
    path: "/futureCreateWork/workArea",
    name: "workArea",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWork/workArea"], resolve),
  },
  //众创空间审核
  {
    path: "/futureCreateWork/workAreaForm",
    name: "workAreaForm",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWork/workAreaForm"], resolve),
  },
  //众创空间申请列表
  {
    path: "/futureCreateWork/createWork",
    name: "createWork",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWork/index"], resolve),
  },
  //众创空间申请
  {
    path: "/futureCreateWork/workForm",
    name: "workForm",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWork/workForm"], resolve),
  },
  //明珠创业首页
  {
    path: "/futureCreateWork/home",
    name: "futureCreateWork-home",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/home/index"], resolve),
  },
  //创业达人列表
  {
    path: "/futureCreateWork/createWorkTalent",
    name: "createWorkTalent",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWorkTalent/index"], resolve),
  },
  //创业达人详情
  {
    path: "/futureCreateWork/createWorkTalentDetail",
    name: "createWorkTalentDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/createWorkTalent/createWorkTalentDetail",
      ], resolve),
  },
  //创业导师
  {
    path: "/futureCreateWork/createWorkTeacher",
    name: "createWorkTeacher",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/createWorkTeacher/index"], resolve),
  },
  //新增创业导师
  {
    path: "/futureCreateWork/createWorkTeacherForm",
    name: "createWorkTeacherForm",
    meta: {
      title: "",
      echo: "/futureCreateWork/createWorkTeacher",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/createWorkTeacher/createWorkTeacherForm",
      ], resolve),
  },

  //服务联盟
  {
    path: "/futureCreateWork/serviceLeague",
    name: "serviceLeague",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/serviceLeague/index"], resolve),
  },
  //服务联盟
  {
    path: "/futureCreateWork/serviceLeagueForm",
    name: "serviceLeagueForm",
    meta: {
      title: "",
      echo: "/futureCreateWork/serviceLeague",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/serviceLeague/serviceLeagueForm",
      ], resolve),
  },
  //场所租赁
  {
    path: "/futureCreateWork/rentPlace",
    name: "rentPlace",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/index"], resolve),
  },
  //场所租赁详情
  {
    path: "/futureCreateWork/rentPlaceDetail",
    name: "rentPlaceDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/rentPlaceDetail"], resolve),
  },
  //场所租赁申请
  {
    path: "/futureCreateWork/rentPlaceSubmit",
    name: "rentPlaceSubmit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/rentPlaceSubmit"], resolve),
  },
  //场所租赁结果
  {
    path: "/futureCreateWork/rentSuccess",
    name: "rentSuccess",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/rentPlace/rentSuccess"], resolve),
  },
  //奉化党建
  {
    path: "/futureCreateWork/fenghuaPartyInfo",
    name: "fenghuaPartyInfo",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/fenghuaPartyInfo/index.vue"], resolve),
  },
  //海定十法
  {
    path: "/futureCreateWork/tenLawsOfHaiding",
    name: "tenLawsOfHaiding",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureCreateWork/tenLawsOfHaiding/index.vue"], resolve),
  },
  //海定十法介绍
  {
    path: "/futureCreateWork/introduce",
    name: "introduceTab",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/introduce.vue",
      ], resolve),
  },
  //海定十法-资讯发布
  {
    path: "/futureCreateWork/informationReleaseList",
    name: "informationReleaseList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/informationReleaseList.vue",
      ], resolve),
  },
  //海定十法-亮相台
  {
    path: "/futureCreateWork/photostage",
    name: "photostage",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/photostage.vue",
      ], resolve),
  },
  //海定十法-干部信息详情
  {
    path: "/futureCreateWork/cadreInformation",
    name: "cadreInformation",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/cadreInformation.vue",
      ], resolve),
  },
  //海定十法-议事厅
  {
    path: "/futureCreateWork/assemblyHall",
    name: "assemblyHall",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/assemblyHall.vue",
      ], resolve),
  },
  //海定十法-信息栏
  {
    path: "/futureCreateWork/informationBar",
    name: "informationBar",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/informationBar.vue",
      ], resolve),
  },
  //海定十法-身份认证
  {
    path: "/futureCreateWork/identityAuthentication",
    name: "identityAuthenticationTab",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/identityAuthentication.vue",
      ], resolve),
  },
  //海定十法-我的提议
  {
    path: "/futureCreateWork/myProposal",
    name: "myProposal",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/myProposal.vue",
      ], resolve),
  },
  //海定十法-议事详情
  {
    path: "/futureCreateWork/proceedingsDetail",
    name: "proceedingsDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/proceedingsDetail.vue",
      ], resolve),
  },
  //海定十法-发起提议
  {
    path: "/futureCreateWork/initiateProposal",
    name: "initiateProposal",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/initiateProposal.vue",
      ], resolve),
  },
  //海定十法-查看进度
  {
    path: "/futureCreateWork/viewProgress",
    name: "viewProgress",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/tenLawsOfHaiding/viewProgress.vue",
      ], resolve),
  },
  //金钟农贸
  {
    path: "/futureCreateWork/jinzhongAgriculturalTrade",
    name: "jinzhongAgriculturalTrade",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/jinzhongAgriculturalTrade/index.vue",
      ], resolve),
  },
  //松香岙饮
  {
    path: "/futureCreateWork/sAAgriculturalTrade",
    name: "sAAgriculturalTrade",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureCreateWork/sAAgriculturalTrade/index.vue",
      ], resolve),
  },
];
export default routes;

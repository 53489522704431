<template>
  <div class="page">
    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="tips">提醒：最多设10个紧急号码</div>
      <v-list
        :finished="finished"
        :finishedText="finishedText"
        @onLoad="onLoad"
        ref="list"
      >
        <div
          class="list-content"
          v-for="(item, index) in listData"
          :key="`${index}_${item.id}`"
        >
          <div class="list-flex">
            <div class="flex-content">
              <span class="label">紧急联系人：</span>
              &nbsp;
              <span class="value">{{ item.name }}</span>
            </div>
            <div class="flex-content">
              <span class="label">紧急联系电话：</span>
              &nbsp;
              <span class="value">{{ item.num }}</span>
            </div>
          </div>
          <img
            class="remove"
            src="../../img/delete_icon.png"
            alt=""
            @click="toRemoveDialog(item)"
          />
        </div>
      </v-list>
    </v-refresh>

    <div class="section-footer" v-if="listData.length < 10">
      <div class="footer-btn" @click="create">
        <span>新 增</span>
      </div>
    </div>

    <div class="tipsBox" v-if="isRemoveDialog">
      <div class="box">
        <div class="box_t">提示</div>
        <div class="box_c">是否确认删除该条数据？</div>
        <div class="box_b">
          <div class="btn_l" @click="isRemoveDialog = false">取消</div>
          <div @click="remove" class="btn_r border-l">确认</div>
        </div>
      </div>
    </div>

    <v-popup v-model="isPopup">
      <div class="dialog-create">
        <div class="dialog-title">新增紧急联系人</div>

        <div class="dialog-content">
          <div class="section-info--flex">
            <div class="info-flex--label must">
              <span>紧急联系人：</span>
            </div>
            <div class="info-flex--input">
              <v-input
                v-model.trim="form.sosName"
                :maxlength="10"
                placeholder=""
              ></v-input>
            </div>
          </div>

          <div class="section-info--flex">
            <div class="info-flex--label must">
              <span>紧急联系人电话：</span>
            </div>
            <div class="info-flex--input">
              <v-input
                type="number"
                v-model.trim="form.sosPhone"
                :maxlength="11"
                placeholder=""
              ></v-input>
            </div>
          </div>

          <div class="section-info--button">
            <div class="info-button--cancel" @click="isPopup = false">
              <span>取 消</span>
            </div>
            <div class="info-button--confirm" @click="confirm">
              <span>确 定</span>
            </div>
          </div>
        </div>
      </div>
    </v-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  getEmergencyContactListURL,
  addEmergencyContactURL,
  deleteEmergencyContactURL,
} from "../../api.js";

export default {
  data() {
    return {
      deviceId: "",
      isLoading: false,
      finished: true, // 列表是否加载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curPage: 1,
      pageSize: 10,

      isPopup: false,
      form: {
        sosName: "",
        sosPhone: "",
      },

      curData: null,
      isRemoveDialog: false,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    this.deviceId = this.$route.query.deviceId;
    this.onRefresh();
  },
  methods: {
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 加载列表数据
    onLoad() {
      let params = {
        userId: this.userId,
        deviceId: this.deviceId,
      };
      this.$axios
        .get(getEmergencyContactListURL, {
          params,
        })
        .then((res) => {
          if (res.code == 200) {
            this.listData = res.data;
          }
          if (this.listData.length == 0) {
            this.finishedText = "";
          } else {
            this.finishedText = "没有更多了";
          }
          // if (res.code != 200) {
          //   this.finished = true;
          // } else {
          //   if (this.curPage >= res.data.pages) {
          //     this.finished = true;
          //   } else {
          //     this.finished = false;
          //     this.$refs.list.loading = false;
          //   }
          //   this.finishedText = "没有更多了";
          //   if (res.data.records.length === 0) {
          //     this.listData = [];
          //     this.finishedText = "";
          //     return;
          //   }
          //   if (res.code === 200 && res.data && res.data.records) {
          //     if (this.curPage === 1) {
          //       this.listData = res.data.records;
          //     } else {
          //       const list = this.listData.map((v) => v.id);
          //       res.data.records.forEach((item) => {
          //         // 如果请求数据不重复，则添加进listData
          //         list.includes(item.id) || this.listData.push(item);
          //       });
          //     }
          //     this.curPage++;
          //   }
          // }
        })
        .finally(() => {
          this.finished = true;
          this.isLoading = false;
        });
    },

    create() {
      this.form = {
        sosName: "",
        sosPhone: "",
      };
      this.isPopup = true;
    },
    confirm() {
      const phoneRegExp =
        /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
      let tip = "";
      if (!this.form.sosName) {
        tip = "请填写紧急联系人";
      } else if (!this.form.sosPhone) {
        tip = "请输入紧急联系人手机号";
      } else if (!phoneRegExp.test(this.form.sosPhone)) {
        tip = "请输入正确格式的手机号";
      }
      if (tip) {
        this.$toast({
          message: tip,
          duration: 1500,
        });
      } else {
        this.$axios
          .post(`${addEmergencyContactURL}`, {
            tenantId: this.tenantId,
            deviceId: this.deviceId,
            ...this.form,
          })
          .then((res) => {
            if (res.code === 200) {
              this.isPopup = false;
              // this.$toast({
              //   message: "新增成功",
              //   duration: 1500,
              // });
              // setTimeout(() => {
              this.onRefresh();
              // }, 1500);
            }
          });
      }
    },

    // 删除紧急联系人
    remove() {
      let params = {
        deviceId: this.deviceId,
        id: this.curData.id,
        thirdId: this.curData.thirdId,
      };
      this.$axios
        .post(`${deleteEmergencyContactURL}`, { ...params })
        .then((res) => {
          if (res.code === 200) {
            this.isRemoveDialog = false;
            // this.$toast({
            //   message: "删除成功",
            //   duration: 1500,
            // });
            // setTimeout(() => {
            this.onRefresh();
            // }, 1500);
          }
        });
    },
    // 删除
    toRemoveDialog(item) {
      this.curData = item;
      this.isRemoveDialog = true;
    },
  },
};
</script>
<style lang="less" scoped>
// 隐藏所有滚动条
::-webkit-scrollbar {
  display: none;
}

.page {
  box-sizing: border-box;
  background: #f9f9f9;
  padding: 36px 30px 0px;
  padding-bottom: calc(
    124px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    124px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  ::v-deep .van-pull-refresh__track {
    min-height: calc(100vh - 160px);
  }
  .tips {
    font-size: 24px;
    color: #ed1e26;
    line-height: 34px;
  }
  .list-content {
    display: flex;
    align-items: center;
    overflow: auto;
    padding: 16px 0;
    .list-flex {
      box-sizing: border-box;
      // display: flex;
      width: 688px;
      min-width: 688px;
      padding: 36px;
      // margin: 16px 0px 16px;
      border-radius: 16px;
      box-shadow: 0px 4px 16px 0px rgba(227, 277, 277, 0.5);
      background: #fff;
      .flex-content {
        &:not(:last-child) {
          margin-bottom: 26px;
        }
        display: flex;
        align-items: center;
        line-height: 44px;
        font-size: 28px;
        color: rgba(0, 0, 0, 0.85);
        .value {
          font-size: 32px;
          font-weight: bold;
        }
      }
    }
    .remove {
      width: 68px;
      height: 68px;
      margin-left: 20px;
    }
  }

  .section-footer {
    position: fixed;
    right: 0;
    left: 0;
    bottom: 32px;
    background: #bddabd;
    margin: 0px 64px 0px;
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    border-radius: 10px;
    line-height: 72px;
    text-align: center;
  }

  .tipsBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    .box {
      width: 568px;
      height: 290px;
      background: #ffffff;
      border-radius: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .box_t {
        width: 100%;
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        text-align: center;
        padding-top: 50px;
      }
      .box_c {
        width: 100%;
        white-space: nowrap;
        font-size: 30px;
        color: rgba(0, 0, 0, 0.5);
        text-align: center;
        padding-top: 26px;
        box-sizing: border-box;
      }
      .box_b {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 86px;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        display: flex;
        .btn_l,
        .btn_r {
          flex: 1;
          text-align: center;
          line-height: 86px;
          font-size: 36px;
          color: #333333;
        }
        .border-l {
          border-left: 2px solid rgba(0, 0, 0, 0.1);
          font-weight: 600;
          color: #bddabd;
        }
      }
    }
  }

  .dialog-create {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 32px 30px 60px;
    height: 100%;
    .dialog-title {
      color: rgba(0, 0, 0, 0.85);
      font-size: 32px;
      font-weight: bold;
      text-align: center;
    }
    .dialog-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      .section-info--flex {
        margin-top: 90px;
        font-size: 30px;
        color: rgba(0, 0, 0, 0.5);
        .info-flex--label {
          margin-bottom: 12px;
          line-height: 48px;
          &.must {
            &::before {
              content: "*";
              display: inline-block;
              width: 16px;
              color: #ff602a;
              font-size: 28px;
              text-align: center;
            }
          }
        }
        .info-flex--input {
          background: #f7f7f7;
          border-radius: 8px;
          ::v-deep .van-cell {
            background: transparent;
          }
        }
      }
      .section-info--button {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 50px;
        .info-button--cancel,
        .info-button--confirm {
          box-sizing: border-box;
          display: flex;
          width: 230px;
          height: 80px;
          justify-content: center;
          align-items: center;
          font-size: 28px;
          font-weight: bold;
          color: #bddabd;
          border-radius: 10px;
        }
        .info-button--cancel {
          border: 2px solid #bddabd;
        }
        .info-button--confirm {
          background: #bddabd;
          color: #ffffff;
        }
      }
    }
  }
}
</style>

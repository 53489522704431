<template>
  <div class="my-swipe">
    <van-swipe :autoplay="autoplay" indicator-color="white">
      <div class="oSwiper" v-for="(item, index) in swiperData" :key="index">
        <van-swipe-item>
          <slot :rows="item"></slot>
        </van-swipe-item>
      </div>
    </van-swipe>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";

Vue.use(Swipe);
Vue.use(SwipeItem);
export default {
  components: {},
  props: {
    finished: {
      type: Boolean,
      default: false,
    },
    finishedText: {
      type: String,
      default: "没有更多了",
    },
    autoplay: {
      type: Number,
      default: 3000,
    },
    swiperData: {
      type: Array,
      default: function () {
        return [{}];
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onLoad() {
      console.log(111);
      this.$emit("onLoad");
    },
  },
};
</script>

<style lang="less" scoped></style>

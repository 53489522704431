var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"list"},[_c('v-list',{ref:"list",attrs:{"finished":_vm.finished,"finishedText":_vm.finishedText},on:{"onLoad":_vm.onLoad}},_vm._l((_vm.listData),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.toPath(item)}}},[(item.status == 7 || item.status == 9 || item.status == 10)?_c('div',{staticClass:"tag",staticStyle:{"background":"linear-gradient(180deg, #9ee257 0%, #6dd400 100%)"}},[_vm._v(" 被采纳 ")]):_vm._e(),(item.status == 8)?_c('div',{staticClass:"tag",staticStyle:{"background":"linear-gradient(180deg, #fa5c5c 0%, #e02020 100%)"}},[_vm._v(" 未被采纳 ")]):_vm._e(),(
            item.status == 0 ||
            item.status == 1 ||
            item.status == 2 ||
            item.status == 3 ||
            item.status == 4 ||
            item.status == 5 ||
            item.status == 6
          )?_c('div',{staticClass:"tag",staticStyle:{"background":"linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%)"}},[_vm._v(" 进行中 ")]):_vm._e(),_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("./img/user.png"),"alt":""}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.proposeUserName))]),_c('div',{staticClass:"time"},[_vm._v("发起时间："+_vm._s(item.createTime))])])]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.proposeTitle))]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"label"},[_vm._v("提议内容：")]),_vm._v(" "+_vm._s(item.proposeContent)+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"label"},[_vm._v("附议：")]),_c('div',{staticClass:"imgList"},_vm._l((item.proposeAnnexFiles),function(i,x){return _c('img',{key:x,attrs:{"src":i,"alt":""}})}),0)])]),(item.status != 7 && item.status != 9 && item.status != 10)?_c('div',{staticClass:"del"},[_c('div',{staticClass:"delBtn",on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v("删除")])]):_vm._e()])}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
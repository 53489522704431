import { render, staticRenderFns } from "./villageScholarStyleDetail.vue?vue&type=template&id=4f4b207d&scoped=true"
import script from "./villageScholarStyleDetail.vue?vue&type=script&lang=js"
export * from "./villageScholarStyleDetail.vue?vue&type=script&lang=js"
import style0 from "./villageScholarStyleDetail.vue?vue&type=style&index=0&id=4f4b207d&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f4b207d",
  null
  
)

export default component.exports
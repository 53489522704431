<template>
  <div class="page">
    <!-- <div
      class="item"
      @click="toDetail(index, 'headlinesDetail')"
      v-for="(item, index) in list"
      :key="index"
    >
      <img class="cover" :src="item.cover" alt="" />
      <div class="right">
        <img src="./img/item-icon.png" alt="" />
        <div class="line"></div>
        <div class="content">
          <div class="title">{{ item.title }}</div>
          <div class="tag">长汀未来社区</div>
        </div>
      </div>
    </div> -->
     <v-empty description="暂无数据"></v-empty>
  </div>
</template>

<script>
import { list } from "./map.js";
export default {
  name: "intangibleCulturalHeritageHeadlines",
  data() {
    return {
      list,
    };
  },
  methods: {
    toDetail(index, name) {
      this.$router.push({
        name: name,
        query: {
          index: index,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  box-sizing: border-box;
  padding: 30px;
  .item {
    width: 100%;
    height: 190px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .cover {
      display: block;
      width: 258px;
      height: 100%;
    }
    .right {
      padding: 16px 16px 16px 26px;
      box-sizing: border-box;
      width: 416px;
      height: 100%;
      background: rgba(230, 235, 232, 0.68);
      display: flex;
      position: relative;
      .content {
        padding-left: 84px;
        box-sizing: border-box;
        .title {
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #04281a;
          line-height: 40px;
          padding-bottom: 30px;
        }
        .tag {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(4, 40, 26, 0.71);
        }
      }
      img {
        display: block;
        width: 26px;
        height: 24px;
        position: absolute;
        top: 25px;
        left: 26px;
      }
      .line {
        width: 2px;
        height: 32px;
        background: rgba(4, 40, 26, 0.31);
        position: absolute;
        top: 22px;
        left: 74px;
      }
      .icon-box {
        display: flex;
        width: 49px;
        justify-content: space-between;
        img {
          display: block;
          width: 26px;
          height: 24px;
        }
      }
    }
  }
}
</style>

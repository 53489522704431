<template>
  <div class="page">
    <div class="section-steps">
      <div class="section-step">
        <div class="section-step--header">
          <div
            :class="[
              'step-header--circle',
              detail.stepOne.status ? 'complete' : '',
            ]"
          ></div>
          <div class="step-header--label">
            <span>第一步</span>
          </div>
          <div
            :class="[
              'step-header--status',
              detail.stepOne.status ? 'complete' : '',
            ]"
          >
            <span v-if="detail.stepOne.status">已完成</span>
            <span v-else>未完成</span>
          </div>
        </div>
        <div
          :class="[
            'section-step--content',
            detail.stepOne.status ? 'complete' : '',
          ]"
        >
          <div class="step-content--left">打开设备包装，检查准备时间</div>
          <div class="step-content--right">
            <div
              :class="[
                'content-right--button',
                detail.stepOne.status ? 'complete' : '',
              ]"
              @click="toStepsRoute(1)"
            >
              <span>去检查</span>
            </div>
          </div>
        </div>
      </div>

      <div class="section-step">
        <div class="section-step--header">
          <div
            :class="[
              'step-header--circle',
              detail.stepTwo.status ? 'complete' : '',
            ]"
          ></div>
          <div class="step-header--label">
            <span>第二步</span>
          </div>
          <div
            :class="[
              'step-header--status',
              detail.stepTwo.status ? 'complete' : '',
            ]"
          >
            <span v-if="detail.stepTwo.status">已完成</span>
            <span v-else>未完成</span>
          </div>
        </div>
        <div
          :class="[
            'section-step--content',
            detail.stepTwo.status ? 'complete' : '',
          ]"
        >
          <div class="step-content--left">
            <span>装卡</span>
            <img src="./img/arrow.png" alt="" />
            <span>充电</span>
            <img src="./img/arrow.png" alt="" />
            <span>开机</span>
          </div>
          <div class="step-content--right">
            <div
              :class="[
                'content-right--button',
                detail.stepTwo.status ? 'complete' : '',
              ]"
              @click="toStepsRoute(2)"
            >
              <span>去完成</span>
            </div>
          </div>
        </div>
      </div>

      <div class="section-step">
        <div class="section-step--header">
          <div
            :class="[
              'step-header--circle',
              detail.stepThree.status ? 'complete' : '',
            ]"
          ></div>
          <div class="step-header--label">
            <span>第三步</span>
          </div>
          <div
            :class="[
              'step-header--status',
              detail.stepThree.status ? 'complete' : '',
            ]"
          >
            <span v-if="detail.stepThree.status">已完成</span>
            <span v-else>未完成</span>
          </div>
        </div>
        <div
          :class="[
            'section-step--content',
            detail.stepThree.status ? 'complete' : '',
          ]"
        >
          <div class="step-content--left">确认设备已联网</div>
          <div class="step-content--right">
            <div
              :class="[
                'content-right--button',
                detail.stepThree.status ? 'complete' : '',
              ]"
              @click="toStepsRoute(3)"
            >
              <span>去确认</span>
            </div>
          </div>
        </div>
      </div>

      <div class="section-step last">
        <div class="section-step--header">
          <div
            :class="[
              'step-header--circle',
              detail.stepFour.status ? 'complete' : '',
            ]"
          ></div>
          <div class="step-header--label">
            <span>第四步</span>
          </div>
          <div
            :class="[
              'step-header--status',
              detail.stepFour.status ? 'complete' : '',
            ]"
          >
            <span v-if="detail.stepFour.status">已完成</span>
            <span v-else>未完成</span>
          </div>
        </div>
        <div
          :class="[
            'section-step--content',
            detail.stepFour.status ? 'complete' : '',
          ]"
        >
          <div class="step-content--left">扫码绑定</div>
          <div class="step-content--right">
            <div
              :class="[
                'content-right--button',
                detail.stepFour.status ? 'complete' : '',
              ]"
              @click="toStepsRoute(4)"
            >
              <img src="./img/qrcode.png" alt="" />
              <span>扫码绑定</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section-info">
      <div class="section-info--flex">
        <div class="info-flex--label must">
          <span>请输入设备IMEI码</span>
        </div>
        <div class="info-flex--input">
          <v-input
            v-model.trim="detail.stepFour.deviceCode"
            :maxlength="30"
            placeholder="手动输入设备码后自动完成前四步"
            @blur="deviceInputBlur"
          ></v-input>
        </div>
      </div>
    </div>

    <div class="section-info">
      <div class="section-info--flex">
        <div class="info-flex--label must">
          <span>请输入手环拥有者姓名</span>
        </div>
        <div class="info-flex--input">
          <v-input
            :disabled="!detail.stepFour.status"
            v-model.trim="form.userName"
            :maxlength="10"
            :placeholder="detail.stepFour.status ? '' : '请先完成前四步'"
          ></v-input>
        </div>
      </div>

      <div class="section-info--flex">
        <div class="info-flex--label must">
          <span>添加紧急联系人</span>
        </div>
        <div class="info-flex--input">
          <v-input
            :disabled="!detail.stepFour.status"
            v-model.trim="form.sosName"
            :maxlength="10"
            :placeholder="detail.stepFour.status ? '' : '请先完成前四步'"
          ></v-input>
        </div>
      </div>

      <div class="section-info--flex">
        <div class="info-flex--label must">
          <span>添加紧急联系人电话</span>
        </div>
        <div class="info-flex--input">
          <v-input
            :disabled="!detail.stepFour.status"
            type="number"
            v-model.trim="form.sosPhone"
            :maxlength="11"
            :placeholder="detail.stepFour.status ? '' : '请先完成前四步'"
          ></v-input>
        </div>
      </div>
    </div>
    <div class="section-footer">
      <div class="footer-btn" @click="confirm">
        <span>完 成</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { bindURL } from "./api";
import { toRegister } from "@/utils/common";

export default {
  beforeRouteEnter(to, from, next) {
    // console.log(to, from, next(), sessionStorage);
    next((vm) => {
      if (from.name === "elderCareBraceletList") {
        sessionStorage.clear();
        sessionStorage.setItem("stepsDetail", JSON.stringify(vm.detail));
        return;
      } else {
        vm.form = JSON.parse(sessionStorage.getItem("stepsForm")) || vm.form;
        vm.detail =
          JSON.parse(sessionStorage.getItem("stepsDetail")) || vm.detail;
      }
    });
  },
  data() {
    return {
      detail: {
        stepOne: {
          status: false,
        },
        stepTwo: {
          status: false,
        },
        stepThree: {
          status: false,
        },
        stepFour: {
          status: false,
          deviceCode: "",
        },
      },
      stepNameMap: {
        1: "stepOne",
        2: "stepTwo",
        3: "stepThree",
        4: "stepFour",
      },
      routeNameMap: {
        1: "elderCareBraceletStepsOne",
        2: "elderCareBraceletStepsTwo",
        3: "elderCareBraceletStepsThree",
        4: "elderCareBraceletStepsFour",
      },
      form: {
        userName: "",
        sosName: "",
        sosPhone: "",
      },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    await toRegister(this.$route.path, this.$route.query, "养老手环");
  },
  methods: {
    deviceInputBlur() {
      if (this.detail.stepFour.deviceCode) {
        this.detail.stepOne.status = true;
        this.detail.stepTwo.status = true;
        this.detail.stepThree.status = true;
        this.detail.stepFour.status = true;
      } else {
        this.detail.stepFour.status = false;
      }
    },
    toStepsRoute(index) {
      sessionStorage.setItem("stepsDetail", JSON.stringify(this.detail));
      sessionStorage.setItem("stepsForm", JSON.stringify(this.form));
      if (index === 1 || this.detail[this.stepNameMap[index - 1]].status) {
        this.$toast.clear();
        this.$router.push({
          name: this.routeNameMap[index],
        });
      } else {
        this.$toast({ message: "请先完成上一步！", duration: 2000 });
      }
    },
    validForm() {
      let result = true;
      let tip = "";
      const phoneRegExp =
        /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
      const { userName, sosName, sosPhone } = this.form;
      if (!userName) {
        tip = "请输入手环拥有者姓名";
        result = false;
      } else if (!sosName) {
        tip = "请添加紧急联系人";
        result = false;
      } else if (!sosPhone) {
        tip = "请添加紧急联系人电话";
        result = false;
      } else if (!phoneRegExp.test(sosPhone)) {
        tip = "请输入正确格式的电话号码";
        result = false;
      }
      if (tip) {
        this.$toast({ message: tip, duration: 2000 });
      }
      return result;
    },
    confirm() {
      if (this.validForm()) {
        this.$toast.clear();
        /* 调接口 */
        this.$axios
          .post(bindURL, {
            userId: this.userId,
            tenantId: this.tenantId,
            imei: this.detail.stepFour.deviceCode,
            ...this.form,
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              this.$toast({ message: res.msg || "绑定成功", duration: 1500 });
              this.$router.push({
                name: "elderCareBraceletList",
              });
            }
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  box-sizing: border-box;
  min-height: 100%;
  background: #ffffff;
  padding: 32px 30px;
  /* 兼容 iOS < 11.2 */
  padding-bottom: calc(72px + constant(safe-area-inset-bottom));
  /* 兼容 iOS >= 11.2 */
  padding-bottom: calc(72px + env(safe-area-inset-bottom));
  .section-steps {
    .section-step {
      .section-step--header {
        display: flex;
        align-items: center;
        line-height: 48px;
        .step-header--circle {
          box-sizing: border-box;
          background: #ffffff;
          width: 30px;
          height: 30px;
          margin-right: 20px;
          border: 4px solid #979797;
          border-radius: 50%;
          &.complete {
            border: 8px solid #93b393;
          }
        }
        .step-header--label {
          flex: 1;
          font-size: 34px;
          font-weight: bold;
          color: #000000;
        }
        .step-header--status {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
          &.complete {
            color: #89ae89;
          }
        }
      }
      .section-step--content {
        box-sizing: border-box;
        display: flex;
        align-items: flex-start;
        min-height: 156px;
        padding: 36px 0;
        margin: 0 0 0 14px;
        color: rgba(0, 0, 0, 0.5);
        line-height: 40px;
        font-size: 28px;
        &.complete {
          border-left: 2px dashed #93b393;
        }
        .step-content--left {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 10px 32px;
          word-break: break-all;
          word-wrap: break-word;
          > img {
            width: 24px;
            height: 14px;
            margin: 0 20px 0 14px;
          }
        }
        .step-content--right {
          .content-right--button {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 30px;
            border-radius: 8px;
            color: #89ae89;
            border: 2px solid #ecf1ee;
            background: #ecf1ee;
            > img {
              margin-right: 8px;
              width: 24px;
              height: 24px;
            }

            &.complete {
              border: 2px solid #89ae89;
              background: #ffffff;
            }
          }
        }
      }
      &.last {
        .section-step--content {
          border: none;
        }
      }
    }
  }

  .section-info {
    .section-info--flex {
      font-size: 30px;
      color: rgba(0, 0, 0, 0.5);
      .info-flex--label {
        margin-bottom: 12px;
        line-height: 48px;
        &.must {
          &::before {
            content: "*";
            display: inline-block;
            width: 16px;
            color: #ff602a;
            font-size: 28px;
            text-align: center;
          }
        }
      }
      .info-flex--input {
        margin-bottom: 48px;
        background: #f7f7f7;
        border-radius: 8px;
        ::v-deep .van-cell {
          background: transparent;
        }
      }
    }
  }

  .section-footer {
    background: #bddabd;
    margin: 0px 17px 0px;
    color: #fff;
    font-weight: bold;
    font-size: 30px;
    border-radius: 10px;
    line-height: 72px;
    text-align: center;
  }
}
</style>

<template>
  <div class="section">
    <div class="section-banner">
      <img src="./img/banner.png" alt="" />
    </div>
    <div class="section-search">
      <div class="search-select" @click="chooseSortType">
        <span>{{ sortMap[selectType] }}</span>
        <img src="../img/arrow_down.png" alt="" />
      </div>
      <div class="search-input">
        <v-input v-model="title" placeholder="请输入关键字" />
      </div>
      <div class="search-button" @click="onRefresh">
        <img src="./img/search_icon.png" alt="" />
      </div>
    </div>
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="section-flex-item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <div class="item-picture"><img :src="item.imageUrl" alt="" /></div>
          <div class="item-detail">
            <div class="item-detail-title">
              <span>{{ item.title }}</span>
              <v-rate
                class="v-rate"
                readonly
                :value="`${Math.ceil(item.avgScore)}`"
              />
            </div>
            <div class="item-detail-place">
              <img src="../img/place_icon.png" alt="" />
              <span>地址：{{ `${item.street} ${item.address}` }}</span>
            </div>
            <div class="item-detail-active">
              <img src="../img/active_icon.png" alt="" />
              <span>优惠活动：{{ item.mallActivity }}</span>
            </div>
          </div>
        </div>
      </v-list>
    </div>
    <div :class="myStatus | statusCss" @click="clickBtn">
      {{ myStatus | statusTxt }}
    </div>

    <v-picker
      :columns="sortOps"
      :valueShow="showPicker"
      :valueKey="valueKey"
      @clickOverlay="overlayPick"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { checkStatusURL, getShopInfoListURL, initWxJsSdkURL } from "./api.js";
// import {
//   businessTypeopsMap,
//   businessTypeops,
//   manageTypeopsMap,
//   manageTypeops,
// } from "./map.js";
// import { gloabalCount, toRegister } from "@/utils/common";
// import { handleImg } from "@/utils/utils.js";
export default {
  data() {
    return {
      // showPicker: false,
      // businessTypeStr: "商圈类型",
      // businessTypeCode: null,

      // showPicker1: false,
      // manageTypeStr: "经营类型",
      // manageTypeCode: null,

      // businessTypeops,
      // manageTypeops,
      // businessTypeopsMap,
      // manageTypeopsMap,

      myStatus: 5, // 默认显示申请入驻
      selectType: "", // 排序类型
      title: "", // 关键字查询
      sortMap: {
        "": "全部",
        1: "评分最高",
        2: "最新发布",
        3: "距离最近",
      },
      sortOps: [
        {
          text: "全部",
          value: "",
        },
        {
          text: "评分最高",
          value: 1,
        },
        {
          text: "最新发布",
          value: 2,
        },
        {
          text: "距离最近",
          value: 3,
        },
      ],
      latitude: "",
      longitude: "",
      /* v-picker */
      showPicker: false,
      valueKey: "text",

      /* v-list */
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      curPage: 1,
      pageSize: 10,
    };
  },
  filters: {
    statusTxt(val) {
      let statusMap = {
        0: "商铺审核中...",
        1: "我的商铺",
        2: "商铺审核未通过，重新申请",
        3: "已冻结",
        4: "申诉中",
        5: "申请失败，重新申请",
        99: "申请入驻",
      };
      return statusMap[val];
    },

    statusCss(val) {
      let statusMap = {
        0: "btn2",
        1: "btn1",
        2: "btn3",
        3: "btn3",
        4: "btn2",
        5: "btn1",
        99: "btn1",
      };
      return statusMap[val];
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId", "openId"]),
  },
  async created() {
    await this.initWxConfig();
    this.getLocation();
  },
  mounted() {
    // gloabalCount("", 79, 1);
    this.getMyStatus();
  },
  methods: {
    // 微信授权H5可调用的 wx API功能
    async initWxConfig() {
      const url =
        process.env.NODE_ENV == "production"
          ? location.href.split("#")[0]
          : "https://fenghua.zhuneng.cn";
      let res = await this.$axios.post(
        `${initWxJsSdkURL}`,
        this.$qs.stringify({
          url,
        })
      );
      if (res.code == 200) {
        // this.wxData = res.data;
        const result = res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: ["checkJsApi", "getLocation"], // 必填，需要使用的 JS 接口列表
        });
      }
    },
    getLocation() {
      let _this = this;
      wx.ready(() => {
        wx.checkJsApi({
          jsApiList: ["getLocation"],
          success: (rest) => {
            //获取当前位置
            wx.getLocation({
              type: "gcj02", // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
              success: function (res) {
                console.log("res----->", res);
                _this.latitude = res.latitude;
                _this.longitude = res.longitude;
              },
            });
          },
          fail: (res) => {
            console.log("res----->", res);
          },
        });
      });
      wx.error((error) => {
        console.log("error----->", error);
      });
    },
    // 获取我的商户申请状态
    async getMyStatus() {
      if (!this.userId || this.userId == "0") {
        return;
      }
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
      };
      let res = await this.$axios.get(`${checkStatusURL}`, {
        params,
      });
      if (res.code == 200) {
        this.myStatus = res.data;
      }
    },
    // 选择排序类型
    chooseSortType() {
      this.showPicker = true;
    },
    // 点击遮罩层
    overlayPick() {
      this.showPicker = false;
    },
    // 点击关闭
    cancelPick() {
      this.showPicker = false;
    },
    // 点击确认
    confirmPick(val) {
      this.selectType = val.value;
      this.showPicker = false;
      this.onRefresh();
    },
    // 底部按钮
    clickBtn() {
      const status = this.myStatus;
      let query = {};
      let name = "";
      if (status == 2 || status == 5) {
        name = "bcnServiceApply";
        query = { isEdit: 1 };
      } else if (status == 99) {
        name = "bcnServiceApply";
      } else if (status == 1) {
        name = "bcnServiceMybusiness";
      }
      this.$router.push({
        name,
        query,
      });
    },
    // 重置商户列表
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 获取商户列表
    onLoad() {
      let params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        status: 1,
        selectType: this.selectType,
        title: this.title,
        latitude: this.latitude,
        longitude: this.longitude,
        // userId: this.userId,
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
      };
      this.$axios
        .get(`${getShopInfoListURL}`, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
          // if (res.code === 200) {
          //   if (res.data.pages <= res.data.current) {
          //     this.finished = true;
          //   } else {
          //     this.$refs.list._data.loading = false;
          //   }
          //   this.finishedText = "没有更多了";
          //   if (res.data.records.length === 0) {
          //     this.finishedText = "";
          //     return;
          //   }
          //   res.data.records.forEach((item) => {
          //     this.listData.push(item);
          //   });
          //   // this.listData.forEach((ele) => {
          //   //   if (ele.imageUrl) {
          //   //     ele.imageUrl = handleImg(104, 104, ele.imageUrl);
          //   //   }
          //   // });
          // } else {
          //   this.finished = true;
          // }
        });
    },
    // 点击前往详情
    toDetail(item) {
      this.$router.push({
        name: "bcnServiceDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.section {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #ffffff;
  padding-bottom: calc(
    90px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    90px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-banner {
    width: 100%;
    height: 336px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .section-search {
    display: flex;
    align-items: center;
    padding: 0 28px;
    margin: 30px 0;
    .search-select {
      box-sizing: border-box;
      width: 200px;
      height: 58px;
      border-radius: 10px;
      border: 2px solid rgba(0, 0, 0, 0.25);
      display: flex;
      align-items: center;
      padding: 8px 12px 8px 16px;
      > img {
        &:extend(.common-img);
        width: 28px;
        height: 16px;
      }
      span {
        flex: 1;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .search-input {
      flex: 1;
      padding: 0 20px;
      ::v-deep .van-cell {
        padding: 0 36px;
        background: #f7f7f7;
        border-radius: 31px;
        line-height: 58px;
      }
    }
    .search-button {
      width: 60px;
      text-align: center;
      > img {
        width: 36px;
        height: 40px;
        &:extend(.common-img);
      }
    }
  }
  .btn1,
  .btn2,
  .btn3 {
    box-sizing: border-box;
    width: 550px;
    height: 60px;
    border-radius: 10px;
    line-height: 60px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
  .btn1 {
    color: #ffffff;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
  }
  .btn2 {
    color: #fe9717;
    border: 2px solid #fe9717;
  }
  .btn3 {
    color: rgba(0, 0, 0, 0.25);
    background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
  }
  .list {
    box-sizing: border-box;
    width: 100%;
    padding: 0 28px;
    .section-flex-item {
      &:not(:first-child) {
        margin-top: 30px;
      }
      padding: 22px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      display: flex;
      align-items: center;
      .item-picture {
        width: 200px;
        min-width: 200px;
        height: 200px;
        border-radius: 16px;
        overflow: hidden;
        margin-right: 28px;
        > img {
          &:extend(.common-img);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .item-detail {
        flex: 1;
        min-height: 200px;
        .item-detail-title {
          margin-top: 10px;
          font-size: 28px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            width: 180px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
            white-space: nowrap;
          }
          .v-rate {
            // width: 232px;
            // min-width: 232px;
            margin-left: 4px;
          }
          // white-space: normal;
          // word-break: break-all;
          // word-wrap: break-word;
          // overflow: hidden;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // -webkit-box-orient: vertical;
        }
        .item-detail-place,
        .item-detail-active {
          margin-top: 10px;
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          > img {
            width: 34px;
            height: 34px;
            &:extend(.common-img);
            margin-right: 14px;
          }
          > span {
            font-size: 24px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
            line-height: 34px;
          }
        }
      }
    }
    // .item {
    //   width: 100%;
    //   height: 280px;
    //   background: #ffffff;
    //   box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    //   border-radius: 16px;
    //   margin-bottom: 20px;
    //   padding: 36px 20px;
    //   box-sizing: border-box;
    //   display: flex;
    //   overflow: hidden;
    //   .item-right {
    //     width: 100%;
    //     height: 100%;
    //     padding: 0px 20px 0 20px;
    //     box-sizing: border-box;
    //     .activity {
    //       font-size: 24px;
    //       color: rgba(0, 0, 0, 0.5);
    //     }
    //     .bottom {
    //       display: flex;
    //       align-items: center;
    //       margin-bottom: 14px;
    //       img {
    //         width: 21px;
    //         height: 28px;
    //         margin-right: 28px;
    //       }
    //       .text {
    //         font-size: 24px;
    //         color: rgba(0, 0, 0, 0.5);
    //       }
    //     }
    //     .center {
    //       margin-top: 20px;
    //       margin-bottom: 16px;
    //       font-size: 24px;
    //       color: rgba(0, 0, 0, 0.5);
    //       display: flex;
    //       align-items: center;
    //       height: 32px;
    //       line-height: 32px;
    //       span {
    //         color: #fe9615;
    //       }
    //       .line {
    //         width: 3px;
    //         height: 32px;
    //         background-color: rgba(0, 0, 0, 0.1);
    //         margin: 0 44px;
    //       }
    //     }
    //     .top {
    //       width: 100%;
    //       display: flex;
    //       img {
    //         width: 36px;
    //         height: 36px;
    //         margin-right: 14px;
    //       }
    //       .text {
    //         font-size: 28px;
    //         font-weight: 500;
    //         color: rgba(0, 0, 0, 0.85);
    //       }
    //     }
    //   }
    //   .item-left {
    //     width: 208px;
    //     height: 208px;
    //     img {
    //       display: block;
    //       border-radius: 12px;
    //       width: 208px;
    //       height: 208px;
    //     }
    //   }
    // }
  }
  // .navBox {
  //   width: 100%;
  //   height: 138px;
  //   padding: 26px 28px;
  //   box-sizing: border-box;
  //   .nav {
  //     width: 100%;
  //     height: 100%;
  //     display: flex;
  //     background: #ffffff;
  //     box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
  //     border-radius: 16px;
  //     .leftBox,
  //     .rightBox {
  //       flex: 1;
  //       display: flex;
  //       position: relative;
  //       .inbox {
  //         display: flex;
  //         align-items: center;
  //         position: absolute;
  //         top: 50%;
  //         left: 50%;
  //         transform: translate(-50%, -50%);
  //         .text {
  //           font-size: 30px;
  //           font-weight: 600;
  //           color: #fe9515;
  //           margin-right: 28px;
  //           white-space: nowrap;
  //         }
  //         img {
  //           width: 28px;
  //           height: 12px;
  //         }
  //       }
  //     }
  //   }
  // }
  // .bannerBox {
  //   width: 100%;
  //   height: 336px;
  //   img {
  //     width: 100%;
  //     height: 100%;
  //   }
  // }
}
</style>

const goodsListUrl = `/gateway/hc-serve/miniapi/idleGoodThings/goodsList`; //闲置好物列表
const myIssueGoodsListUrl = `/gateway/hc-serve/miniapi/idleGoodThings/myIssueGoodsList`; //我发布的好物列表
const goodThingsDetailsUrl = `/gateway/hc-serve/manageapi/idleGoodThings/goodThingsDetails`; //好物详情
const addGoodsUrl = `/gateway/hc-serve/miniapi/idleGoodThings/addGoods`; //发布好物
const compileGoodsUrl = `/gateway/hc-serve/miniapi/idleGoodThings/compileGoods`; //编辑好物
const goodThingsDeleteUrl = `/gateway/hc-serve/manageapi/idleGoodThings/goodThingsDelete`; //删除好物
const myCollectGoodsListUrl = `/gateway/hc-serve/miniapi/idleGoodThings/myCollectGoodsList`; //我收藏的好物列表
const collectGoodsUrl = `/gateway/hc-serve/miniapi/idleGoodThings/collectGoods`; //收藏好物
const cancelCollectUrl = `/gateway/hc-serve/miniapi/idleGoodThings/cancelCollect`; //取消收藏
const myShoppingListUrl = `/gateway/hc-serve/miniapi/idleGoodThings/myShoppingList`; //我发布的求购列表
const shoppingListUrl = `/gateway/hc-neighbour/topic/getTopicList`; //求购列表
const purchaseDetailsUrl = `/gateway/hc-serve/manageapi/idleGoodThings/purchaseDetails`; //求购详情
const postBuyingLeadsUrl = `/gateway/hc-neighbour/topic/addTopic`; //发布求购
const deleteDestoonUrl = `/gateway/hc-serve/manageapi/idleGoodThings/deleteDestoon`; //删除求购
const destoonCommentsListUrl = `/gateway/hc-serve/miniapi/idleGoodThings/destoonCommentsList`; //求购评论列表
const addCommentUrl = `/gateway/hc-serve/miniapi/idleGoodThings/addComment`; //发布评论
const myGoodsLeaveWordRecordUrl = `/gateway/hc-serve/miniapi/idleGoodThings/myGoodsLeaveWordRecord`; //我的好物的留言记录
const leaveWordsUrl = `/gateway/hc-serve/leaveWords/leaveWord`; //好物留言
const getDetailsListUrl = `/gateway/hc-serve/leaveWords/getDetailsList`; //留言对话框列表
const deleteLeaveWordUrl = `/gateway/hc-serve/leaveWords/deleteLeaveWord`; //留言撤回
const permissionOfSellerUrl = `/gateway/hc-serve/miniapi/idleGoodThings/permissionOfSeller`; //卖家权限判断
//
// 话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
// 查询话题详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
// 点赞
const addPraiseURL = `/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = `/gateway/hc-neighbour/topic/addComment`;
// 话题评论列表分页查询
const getTopicCommentList = `/gateway/hc-neighbour/topic/getTopicCommentList`;
// 评论回复列表分页查询
const getTopicReplyList = `/gateway/hc-neighbour/topic/getTopicReplyList`;
export {
  goodsListUrl,
  myIssueGoodsListUrl,
  goodThingsDetailsUrl,
  addGoodsUrl,
  compileGoodsUrl,
  goodThingsDeleteUrl,
  myCollectGoodsListUrl,
  collectGoodsUrl,
  cancelCollectUrl,
  myShoppingListUrl,
  shoppingListUrl,
  purchaseDetailsUrl,
  postBuyingLeadsUrl,
  deleteDestoonUrl,
  destoonCommentsListUrl,
  addCommentUrl,
  myGoodsLeaveWordRecordUrl,
  leaveWordsUrl,
  deleteLeaveWordUrl,
  permissionOfSellerUrl,
  getDetailsListUrl,
  //
  getTopicListURL,
  getTopicInfoByIdURL,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
};

<template>
  <div class="page">
    <img src="./img/banner.png" alt="" class="banner" />
    <div class="main-title" @click="toPath('teenagersClassList')">
      <span>课程活动</span>
      <div class="more">更多</div>
      <img src="./img/right-arrow.png" alt="" />
    </div>
    <div class="classList">
      <div
        class="class-item"
        v-for="(t, i) in list"
        @click="toDetail(t)"
        :key="i"
      >
        <div class="class-item-top">
          <img :src="t.activityPicture" alt="" />
        </div>
        <div class="class-item-center">
          <div class="title">{{ t.activityName }}</div>
          <div class="content">缓解你内心的焦虑</div>
        </div>
        <div class="line-box"><div class="line"></div></div>
        <div class="class-item-bottom">
          <div class="addres">地点：{{ t.activityAddress }}</div>
          <div class="startTime">开始时间：{{ t.startDate }}</div>
        </div>
        <div class="status-btn blue" v-if="t.activityStatus == 2">
          报名进行中
        </div>
        <div class="status-btn blue" v-else-if="t.activityStatus == 4">
          活动进行中
        </div>
        <div class="status-btn yellow" v-else-if="t.activityStatus == 1">
          报名未开始
        </div>
        <div class="status-btn yellow" v-else-if="t.activityStatus == 3">
          活动未开始
        </div>
        <div class="status-btn grey" v-else-if="t.activityStatus == 5">
          活动结束
        </div>
        <div class="status-btn grey" v-else-if="t.activityStatus == 6">
          活动取消
        </div>
      </div>
    </div>
    <div class="main-title" v-if="questionnaireName">
      <span>心理评估</span>
      <!-- <div class="more">更多</div>
      <img src="./img/right-arrow.png" alt="" /> -->
    </div>
    <div
      class="mind-box"
      @click="toPath('psychologicalAssessmentDetail')"
      v-if="questionnaireName"
    >
      <div class="questionnaireName">
        {{ questionnaireName }}
      </div>
      <img src="./img/xlpg.png" alt="" />
    </div>
    <!-- <div class="main-title" @click="toPath('cureArticleList')"> -->
    <div class="main-title">
      <span>治愈文章</span>
      <!-- <div class="more">更多</div>
      <img src="./img/right-arrow.png" alt="" /> -->
    </div>
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toRouteTitle(item)"
        >
          <div class="item-left">
            <img :src="item.topicPicture" />
          </div>
          <div class="item-right">
            <div class="title">{{ item.topicTitle || "" }}</div>
            <div class="info-box">
              <img src="./img/look.png" alt="" />
              <div class="lookNum">{{ item.readCount }}</div>
              <img src="./img/message.png" alt="" />
              <div class="messageNum">{{ item.commentCount }}</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import wx from "weixin-js-sdk";
import { handleImg } from "@/utils/utils.js";
import {
  getTopicListURL,
  questionnaireDetailsUrl,
  getActivityListUrl,
} from "./api.js";
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  data() {
    return {
      isLoading: false,
      finished: false, // 列表是否记载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      listData: [], // 列表数据集合
      curPage: 1,
      pageSize: 20,
      questionnaireName: null,
      msg: "",
      list: [],
    };
  },
  async mounted() {
    if (await toRegister(this.$route.path, this.$route.query, "青少年关爱")) {
      this.getQudetail();
    }
    this.getList();
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    async toDetail(item) {
      let params = {
        activityId: item.activityId,
      };
      wx.miniProgram.navigateTo({
        url: `/xubPages/activityDetailTennagers/index?params=${JSON.stringify(
          params
        )}`,
      });
    },
    getList() {
      let params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
        activityCategory: 103,
      };
      this.$axios
        .get(`${getActivityListUrl}`, { params: params })
        .then((res) => {
          if (res.code === 200) {
            res.data.records.forEach((item) => {
              if (item.activityPicture) {
                item.activityPicture = handleImg(
                  580,
                  184,
                  item.activityPicture
                );
              }
              this.list.push(item);
            });
          } else {
          }
        });
    },
    getQudetail() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(questionnaireDetailsUrl, { params: params })
        .then((res) => {
          if (res.code == 200) {
            if (res.data.questionnaireName) {
              this.questionnaireName = res.data.questionnaireName;
            }
            this.msg = res.msg;
          }
        });
    },
    // 点击跳转话题详情页
    toRouteTitle(item) {
      let params = {
        id: item.id,
      };
      if (window.location.origin == "https://mapi.xydata.cc") {
        this.$router.push({
          name: "topicTitle",
          query: {
            id: item.id,
          },
        });
      } else {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
          });
        }
      }
    },
    toPath(name) {
      if (name == "psychologicalAssessmentDetail") {
        if (this.msg == "暂无发布问卷") {
          this.$toast({
            message: "暂无发布问卷~",
          });
        } else {
          this.$router.push({
            name: name,
          });
        }
      } else {
        this.$router.push({
          name: name,
        });
      }
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            tenantId: this.tenantId,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 44,
            curPage: this.curPage,
            pageSize: this.pageSize,
            houseId: this.houseId || this.communityId || undefined,
            sortField: "NEW", // 排序方式， 最热 or 最新
          },
        })
        .then((res) => {
          if (this.curPage >= res.data.pages) {
            this.finished = true;
          } else {
            if (res.code === 200) {
              this.finished = false;
              this.$refs.list.loading = false;
            }
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.listData = [];
            this.finishedText = "";
            return;
          }
          if (res.code === 200 && res.data && res.data.records) {
            if (this.curPage === 1) {
              res.data.records.forEach((item) => {
                if (item.topicPicture) {
                  item.topicPicture = handleImg(256, 256, item.topicPicture);
                }
              });
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                if (item.topicPicture) {
                  item.topicPicture = handleImg(194, 194, item.topicPicture);
                }
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.curPage++;
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 0 0 90px 0;
  .list {
    padding: 30px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 194px;
      background: #f9f9f9;
      border-radius: 16px;
      margin-bottom: 20px;
      display: flex;
      overflow: hidden;
      .item-right {
        flex: 1;
        padding: 44px 26px 0;
        box-sizing: border-box;
        .info-box {
          display: flex;
          align-items: center;
          .lookNum {
            margin-right: 30px;
          }
          div {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.25);
          }
          img {
            width: 32px;
            margin-right: 8px;
          }
        }
        .title {
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          margin-bottom: 40px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .item-left {
        width: 194px;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .mind-box {
    padding: 0 30px;
    box-sizing: border-box;
    height: 234px;
    margin-top: 30px;
    margin-bottom: 60px;
    position: relative;
    .questionnaireName {
      position: absolute;
      top: 64px;
      left: 68px;
      width: 400px;
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1a1c34;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .classList::-webkit-scrollbar {
    display: none;
  }
  .classList {
    margin-top: 30px;
    box-sizing: border-box;
    padding: 0 30px;
    height: 394px;
    margin-bottom: 44px;
    flex-wrap: nowrap;
    overflow-x: auto;
    display: flex;
    .class-item {
      margin-right: 20px;
      width: 580px;
      height: 394px;
      background: #ffffff;
      box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 16px;
      flex-shrink: 0;
      overflow: hidden;
      position: relative;
      .status-btn {
        position: absolute;
        bottom: 0;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        right: 0;
        width: 186px;
        height: 58px;
        line-height: 58px;
        text-align: center;
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px 0px 16px 0px;
      }
      .blue {
        background: linear-gradient(180deg, #90cff3 0%, #57a8d6 100%);
      }
      .yellow {
        background: linear-gradient(180deg, #fdc76f 0%, #faae3e 100%);
      }
      .grey {
        color: rgba(0, 0, 0, 0.25);
        background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      }
      .line-box {
        padding: 0 20px;
        box-sizing: border-box;
        .line {
          width: 100%;
          height: 1px;
          background: #ececec;
        }
      }
      .class-item-bottom {
        padding: 13px 20px 0;
        box-sizing: border-box;
        .addres,
        .startTime {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 4px;
        }
      }
      .class-item-center {
        padding: 13px 20px;
        box-sizing: border-box;
        .content {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .title {
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .class-item-top {
        width: 100%;
        height: 184px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .main-title {
    display: flex;
    align-items: center;
    padding: 0 30px 0 50px;
    box-sizing: border-box;
    img {
      width: 12px;
    }
    div {
      flex: 1;
      margin-right: 8px;
      text-align: right;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
    }
    span {
      position: relative;
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1a1c34;
    }
    span::before {
      z-index: -1;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 180px;
      height: 14px;
      background: #e2e2e2;
      border-radius: 7px;
      content: "";
    }
  }
  .banner {
    width: 100%;
    margin-bottom: 60px;
  }
}
</style>
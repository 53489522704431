<template>
  <div class="page">
    <div class="tab-box">
      <div
        :class="['tab-item', { 'item-act': index == tabAct }]"
        v-for="(item, index) in tabList"
        :key="index"
        @click="tabCheak(index)"
      >
        {{ item.name }}
        <div class="line" v-if="index == tabAct"></div>
      </div>
    </div>
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          class="item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toPath(item)"
        >
          <div class="top">工单号：{{ item.serialNumber }}</div>
          <div class="center">
            <div class="center-t">
              <div class="name">{{ item.placeName }}</div>
              <div
                :class="[
                  'status',
                  { orange: item.status == 1 || item.status == 11 },
                ]"
              >
                {{ returnStatus(item.status) }}
              </div>
            </div>
            <div class="place">{{ item.address }}</div>
          </div>
          <div class="bottom">
            <div class="order-time">
              <div class="left">下单时间</div>
              <div class="right">{{ item.referTime }}</div>
            </div>
            <div class="appointTime">
              <div class="left">预约场次</div>
              <div class="right">
                <div
                  class="appointTime-item"
                  v-for="(item, index) in item.scheduleList"
                  :key="index"
                >
                  {{ item.strDateTime }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { myAppointUrl } from "./api";
import wx from "weixin-js-sdk";
var moment = require("moment");
export default {
  name: "myReservation",
  data() {
    return {
      tabAct: 0,
      bstop: 1,
      finishedText: "没有更多了",
      finished: false,
      loading: false,
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      listData: [],
      tabList: [
        {
          name: "已预约",
        },
        {
          name: "已结束",
        },
        {
          name: "已取消",
        },
      ],
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  methods: {
    returnStatus(status) {
      switch (status) {
        case 1:
          return "待支付";
        case 2:
          return "已完成";
        case 3:
          return "已取消";
        case 4:
          return "待接收";
        case 5:
          return "已关闭";
        case 11:
          return "待确认";
        case 12:
          return "未通过";
        case 99:
          return "已结束";
        default:
          return "已关闭";
      }
    },
    getMyAppointList(tabCheak) {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        showStatus: tabCheak,
      };
      this.$axios
        .get(`${myAppointUrl}`, {
          params,
        })
        .then((res) => {
          this.bstop == 1;
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.referTime) {
                item.referTime = moment(item.referTime).format("YYYY-MM-DD");
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    onLoad() {
      this.getMyAppointList(1);
    },
    toPath(item) {
      let params = {
        orderId: item.orderId,
      };
      wx.miniProgram.navigateTo({
        url: `/xubPages/myReservationDetail/index?params=${JSON.stringify(
          params
        )}`,
      });
    },
    tabCheak(i) {
      if (this.bstop == 1) {
        this.bstop == 0;
        this.requestData.curPage = 1;
        this.tabAct = i;
        this.listData = [];
        this.getMyAppointList(this.tabAct + 1);
      }
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 22px 28px 68px 28px;
  background: #fffdf5;
  .list {
    .item {
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
      border-radius: 16px;
      margin-bottom: 28px;
      .bottom {
        padding: 42px 32px 34px 40px;
        .order-time {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            font-size: 32px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
          }
          .right {
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
          }
        }
        .appointTime {
          display: flex;
          margin-top: 8px;
          justify-content: space-between;
          .left {
            font-size: 32px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.5);
          }
          .right {
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
      .center {
        padding: 34px 32px 32px 40px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        .place {
          width: 460px;
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
        }
        .center-t {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .status {
            font-size: 30px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.5);
          }
          .orange {
            color: #ffd033;
          }
          .name {
            width: 460px;
            font-size: 36px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #333333;
          }
        }
      }
      .top {
        padding: 24px 38px 14px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.05);
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
  .tab-box {
    height: 92px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.49);
    border-radius: 16px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    display: flex;
    margin-bottom: 28px;
    .item-act {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .tab-item {
      flex: 1;
      width: 100%;
      height: 100%;
      text-align: center;
      box-sizing: border-box;
      padding-top: 24px;
      position: relative;
      .line {
        width: 24px;
        height: 6px;
        background: #ffd033;
        border-radius: 3px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 18px;
      }
    }
  }
}
</style>
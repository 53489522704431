<template>
  <div class="shippingDetails">
    <div class="detail-content">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div class="list" v-for="(item, index) in listData" :key="index">
          <div class="list-l">
            <div class="info">
              <span class="phone-number">{{ item.receiverPhone }}</span>
              <span class="name">{{ item.receiverName }}</span>
            </div>
            <div class="address">{{ item.getAddress }}</div>
          </div>
          <!-- <div class="list-r active">发货</div> -->
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getOrderAddressListInfo } from "./api.js";
export default {
  name: "shippingDetails",
  components: {},
  props: {},
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
        goodsId: "",
        type: "",
      },
      orderId: "",
    };
  },
  created() {
    const { goodsId, type } = this.$route.query;
    if (goodsId != undefined) {
      this.requestData.goodsId = goodsId;
      this.orderId = goodsId;
      this.requestData.type = type;
    }
  },
  mounted() {},
  methods: {
    // 获取商户列表
    onLoad() {
      let params = {
        ...this.requestData,
      };
      if (this.requestData.type == 3) {
        params.orderId = this.orderId;
        delete params.goodsId;
      }
      this.$axios
        .get(getOrderAddressListInfo, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.requestData.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.requestData.curPage++;
            }
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.shippingDetails {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f9f9f9;
  padding-top: 32px;
  padding-bottom: calc(
    28px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    20px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  font-family: PingFangSC-Regular, PingFang SC;

  .detail-content {
    padding: 22px 28px 28px 28px;
    box-sizing: border-box;
    .list {
      padding: 42px 24px 42px 38px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 30px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      .list-l {
        flex: 1;
        padding-right: 20px;
        .info {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 24px;
          span {
            font-size: 32px;
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 44px;
          }
          .name {
            margin-left: 14px;
            font-weight: 400;
          }
        }
        .address {
          text-align: center;
          font-size: 28px;
          font-weight: 400;
          color: rgba(76, 76, 76, 0.5);
          line-height: 40px;
        }
      }
      .list-r {
        flex-shrink: 0;
        width: 142px;
        height: 54px;
        background: #d9634d;
        border-radius: 8px;
        font-size: 26px;
        color: #ffffff;
        text-align: center;
        line-height: 54px;
        &.active {
          background: #ffffff;
          border: 2px solid #d9634d;
          color: #d9634d;
        }
      }
    }
  }
}
</style>

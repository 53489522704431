<template>
  <div class="v-dialog">
    <van-dialog
      v-model="model"
      :title="title"
      :show-cancel-button="showCancelButton"
      @confirm="confirm"
      @cancel="cancel"
      :before-close="beforeClose"
      :confirmButtonColor="confirmButtonColor"
      :showConfirmButton="showConfirmButton"
      :cancelButtonText="cancelButtonText"
      :confirmButtonText="confirmButtonText"
      :closeOnClickOverlay="closeOnClickOverlay"
    >
      <slot></slot>
    </van-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
export default {
  watch: {
    value(newValue) {
      this.model = newValue;
    },
    model(newValue) {
      this.$emit("input", newValue);
    },
  },
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    confirmButtonText: {
      //确认按钮文案
      type: String,
      default: "确认",
    },
    cancelButtonText: {
      //确认按钮文案
      type: String,
      default: "取消",
    },
    confirmButtonColor: {
      //确认按钮颜色
      type: String,
      default: "",
    },
    showCancelButton: {
      type: Boolean,
      default: true,
    },
    showConfirmButton: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },

    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 是否在点击遮罩层后关闭弹窗
    closeOnClickOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    beforeClose(action, done) {
      if (action === "confirm") {
        return done(false);
      } else {
        return done();
      }
    },
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style></style>

<template>
  <div class="page">southStream</div>
</template>

<script>
export default {
  data() {
    return;
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang ='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 30px 30px 60px;
}
</style>
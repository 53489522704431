<template>
  <div class="pointsList">
    <!-- <div class="nav">
      <div class="left" @click="selectNav(0)">
        <div :class="[{ blue: activeCode == 0 }]">
          明珠值
          <span style="background: #347eff" v-if="activeCode == 0"></span>
        </div>
      </div>
      <div class="right" @click="selectNav(1)">
        <div :class="[{ yellow: activeCode == 1 }]">
          珍珠贝
          <span style="background: #f7b500" v-if="activeCode == 1"></span>
        </div>
      </div>
    </div> -->
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div v-for="(item, index) in listData" :key="index">
        <div
          class="oItem"
          v-if="
            (item.integralPoint != 0 && activeCode == 0) ||
            (item.tokenPoint != 0 && activeCode == 1)
          "
        >
          <div class="oItem-l">
            <div class="title">{{ getType(item) }}</div>
            <div class="time">{{ item.insertTime }}</div>
          </div>
          <div class="oItem-r">
            <div class="receive" style="color: #347eff" v-if="activeCode == 0">
              {{ item.operationType == 0 ? "-" : "+" }}
            </div>
            <div class="receive" style="color: #f7b500" v-if="activeCode == 1">
              {{ item.operationType == 0 ? "-" : "+" }}
            </div>
            <div class="point" style="color: #347eff" v-if="activeCode == 0">
              {{ item.integralPoint }}
            </div>
            <div class="point" style="color: #f7b500" v-if="activeCode == 1">
              {{ item.tokenPoint }}
            </div>
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { pointsList } from "./api.js";
import { getOption, formatStr } from "@/utils/utils.js";
import { pointType } from "./map.js";
var moment = require("moment");
export default {
  name: "pointsList",
  data() {
    return {
      activeCode: 1,
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },

    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  methods: {
    selectNav(code) {
      if (this.activeCode != code) {
        this.activeCode = code;
      }
    },
    formatStrFunc(str) {
      console.log(formatStr(str));
      if (str) {
        return formatStr(str);
      }
    },
    getType(item) {
      if (item.recordType || item.recordType == 0) {
        return getOption(item.recordType, pointType, "key").value;
      } else {
        return "";
      }
    },
    onLoad() {
      this.getPointsList();
    },
    getPointsList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios.get(`${pointsList}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            this.listData.push(item);
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pointsList {
  min-height: 100%;
  box-sizing: border-box;
  padding: 20px 40px 40px 32px;
  background: #ffffff;
  .nav {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    line-height: 150px;
    .left,
    .right {
      flex: 1;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.5);
      div {
        position: relative;
        span {
          position: absolute;
          bottom: 45px;
          left: 50%;
          transform: translateX(-50%);
          width: 34px;
          height: 6px;
          border-radius: 3px;
        }
      }
      .blue {
        color: #347eff;
      }
      .yellow {
        color: #f7b500;
      }
    }
  }
  .oItem {
    background: #fff;
    padding: 28px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    .oItem-l {
      font-size: 28px;
      line-height: 48px;
      color: #333;
      .time {
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .oItem-r {
      display: flex;
      align-items: center;
      color: rgba(0, 126, 255, 1);
      font-size: 36px;
      font-weight: bold;
      .receive {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
        font-family: "Courier New", Courier, monospace;
      }
      .point {
        color: #347eff;
      }
    }
  }
}
</style>

<template>
  <van-tab
    :title="title"
    :disabled="disabled"
    :dot="dot"
    :name="name"
    :url="url"
    :to="to"
    :replace="replace"
    :title-style="titleStyle"
    :title-class="titleClass"
  >
    <slot></slot>
    <template #title>
      <slot name="title"></slot>
    </template>
  </van-tab>
</template>

<script>
import { Tab } from "vant";

export default {
  components: {
    vanTab: Tab,
  },
  props: {
    // 标题
    title: {
      type: String,
      default: "",
    },
    // 是否禁用标签
    disabled: {
      type: Boolean,
      default: false,
    },
    // 是否在标题右上角显示小红点
    dot: {
      type: Boolean,
      default: false,
    },
    // 图标右上角徽标的内容
    ellipsis: {
      type: [Number, String],
      default: "",
    },
    // 标签名称，作为匹配的标识符
    name: [Number, String],
    // 点击后跳转的链接地址
    url: {
      type: String,
      default: "",
    },
    // 点击后跳转的目标路由对象，同 vue-router 的 to 属性
    to: {
      type: [String, Object],
      default: "",
    },
    // 是否在跳转时替换当前页面历史
    replace: {
      type: Boolean,
      default: false,
    },
    // 自定义标题样式
    titleStyle: {
      type: [String, Object],
      default: "",
    },
    // 自定义标题类名
    titleClass: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>

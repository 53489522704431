<template>
  <div :class="['page', { isOld: isOld == 1 ? true : false }]">
    <img src="./img/yellow-bg.png" class="bg" />
    <div class="main">
      <div class="orderDetail">
        <div class="orderDetail-top">
          <div class="title">订单详情</div>
        </div>
        <div class="orderDetail-center">
          <div class="item" v-for="(item, index) in dialogList" :key="index">
            <div class="item-left">
              <img :src="item.image" alt="" />
            </div>
            <div class="item-right">
              <div class="name">
                <div class="name-txt">{{ item.foodName }}</div>
                <div class="price"><span>¥</span>{{ item.countPrice }}</div>
              </div>
              <div class="num">x1</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn" @click="confirmSure">确认预定</div>
  </div>
</template>

<script>
import { handleImg } from "@/utils/utils.js";
import { mapState } from "vuex";
import { getIsBindingPersonsUrl, getMyCartUrl, orderFoodUrl } from "./api";
export default {
  name: "chooseDiningMethod",
  data() {
    return {
      infoList: [],
      dialogList: [],

      isOld: localStorage.getItem("isOld"),
      submitData: {
        countPrice: 0, //总价
        details: [],
        isDiscounts: 0,
        oldPersonId: 0,
        receiverPhone: "",
        receiverName: "",
      },
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    const { foodTime, isDiscounts, mobile } = this.$route.query;
    if (![null, undefined, ""].includes(mobile)) {
      this.submitData.receiverPhone=mobile;
      this.getInfoList(mobile);
    }
    if (![null, undefined, ""].includes(foodTime)) {
      this.submitData.isDiscounts = Number(isDiscounts);
      this.getDialogList(foodTime);
    }
  },
  methods: {
    getDialogList(foodTime) {
      let params = {
        foodTime: foodTime,
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios.get(`${getMyCartUrl}`, { params: params }).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          if (this.submitData.isDiscounts == 1) {
            this.dialogList = data.map((item) => {
              return {
                ...item,
                countPrice: item.discount,
                image: handleImg(136, 136, item.image),
              };
            });
          } else {
            this.dialogList = data.map((item) => {
              return {
                ...item,
                image: handleImg(136, 136, item.image),
              };
            });
          }
          this.submitData.details = this.dialogList;
          this.submitData.countPrice = this.dialogList.reduce(
            (accumulator, currentValue) => {
              return accumulator + Number(currentValue.countPrice);
            },
            0
          );
        }
      });
    },
    getInfoList(mobiles) {
      let params = {
        mobiles,
      };
      this.$axios
        .get(`${getIsBindingPersonsUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            const { data } = res;
            this.submitData.oldPersonId = data.id;
            this.submitData.receiverName = data.personName;
          }
        });
    },
    //确认预定
    confirmSure() {
      this.$axios
        .post(orderFoodUrl, {
          houseId: this.houseId,
          tenantId: this.tenantId,
          userId: this.userId,
          ...this.submitData,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$router.push({
              name: "deliverySuccess",
            });
          }
        });
    },
    toPath(name, type) {
      this.$router.push({
        name: name,
        query: {
          type: type,
          oldPersonId: this.oldPersonId,
          receiverName: this.receiverName,
          receiverPhone: this.receiverPhone,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  min-height: 100vh;
  padding-bottom: 60px;
  padding-top: 22px;
  box-sizing: border-box;
  background: #f9f9f9;
  .main {
    padding: 0 30px;
    box-sizing: border-box;
    .orderDetail {
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      position: relative;
      .orderDetail-center {
        padding: 28px 32px 5px;
        box-sizing: border-box;
        border-bottom: 2px solid #f5f5f5;
        .item {
          height: 136px;
          width: 100%;
          display: flex;
          margin-bottom: 38px;
          .item-right {
            flex: 1;
            padding: 12px 0 0 20px;
            box-sizing: border-box;
            .num {
              font-size: 32px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
            .name {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              .name-txt {
                flex: 1;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .price {
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #e02020;
                span {
                  font-size: 24px;
                }
              }
            }
          }
          .item-left {
            width: 136px;
            height: 136px;
            border-radius: 12px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .orderDetail-top {
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        align-items: center;
        height: 100px;
        border-bottom: 2px solid #f5f5f5;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .chooseMethod {
      width: 100%;
      height: 232px;
      background: #ffffff;
      border-radius: 16px;
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      img {
        width: 88px;
        display: block;
        margin: 0 auto;
      }
      .right {
        text-align: center;
        width: 316px;
        height: 196px;
        background: #e7f2fa;
        border-radius: 16px;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #488cff;
        box-sizing: border-box;
        padding-top: 26px;
      }
      .left {
        text-align: center;
        font-size: 36px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fca315;
        margin-right: 18px;
        width: 316px;
        height: 196px;
        background: #fef8e7;
        border-radius: 16px;
        box-sizing: border-box;
        padding-top: 26px;
      }
    }
    .chooseMen {
      box-sizing: border-box;
      width: 100%;
      padding: 0 30px;
      height: 136px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 20px;
      position: relative;
      .right {
        display: flex;
        width: 400px;
        height: 62px;
        border-radius: 10px;
        border: 2px solid rgba(0, 0, 0, 0.25);
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 0 18px;
        box-sizing: border-box;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        img {
          width: 28px;
        }
      }
      .left {
        flex: 1;
      }
    }
  }
  .btn {
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 36px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 50px;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    padding: 8px 200px;
    box-sizing: border-box;
    white-space: nowrap;
  }

  .bg {
    width: 100%;
    height: 982px;
    position: fixed;
    top: 0;
  }
}
.isOld {
  .main {
    padding: 0 30px;
    box-sizing: border-box;
    .orderDetail {
      background: #ffffff;
      border-radius: 16px;
      margin-bottom: 20px;
      position: relative;
      .title {
        font-size: 46px;
      }
      .orderDetail-center {
        padding: 28px 32px 5px;
        box-sizing: border-box;
        border-bottom: 2px solid #f5f5f5;
        .item {
          height: 136px;
          width: 100%;
          display: flex;
          margin-bottom: 48px;
          .item-right {
            flex: 1;
            padding: 0 0 0 20px;
            box-sizing: border-box;
            .num {
              font-size: 42px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.5);
            }
            .name {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              .name-txt {
                flex: 1;
                font-size: 48px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333333;
              }
              .price {
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #e02020;
                span {
                  font-size: 34px;
                }
              }
            }
          }
          .item-left {
            width: 156px;
            height: 156px;
            border-radius: 12px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .orderDetail-top {
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 30px;
        display: flex;
        align-items: center;
        height: 100px;
        border-bottom: 2px solid #f5f5f5;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .chooseMethod {
      width: 100%;
      height: 262px;
      background: #ffffff;
      border-radius: 16px;
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 20px;
      box-sizing: border-box;
      margin-bottom: 20px;
      img {
        width: 108px;
        display: block;
        margin: 0 auto;
      }
      .right {
        text-align: center;
        width: 316px;
        height: 206px;
        background: #e7f2fa;
        border-radius: 16px;
        font-size: 46px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #488cff;
        box-sizing: border-box;
        padding-top: 26px;
      }
      .left {
        text-align: center;
        font-size: 46px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #fca315;
        margin-right: 18px;
        width: 316px;
        height: 216px;
        background: #fef8e7;
        border-radius: 16px;
        box-sizing: border-box;
        padding-top: 26px;
      }
    }
    .chooseMen {
      box-sizing: border-box;
      width: 100%;
      padding: 0 30px;
      height: 136px;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      font-size: 46px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      margin-bottom: 20px;
      position: relative;
      .right {
        display: flex;
        width: 400px;
        height: 62px;
        border-radius: 10px;
        border: 2px solid rgba(0, 0, 0, 0.25);
        align-items: center;
        justify-content: space-between;
        padding: 0 24px 0 18px;
        box-sizing: border-box;
        font-size: 42px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        img {
          width: 28px;
        }
      }
      .left {
        flex: 1;
      }
    }
  }
  .btn{
    font-size: 42px;
  }
}
</style>

//发布活动
const publishActivityUrl = `/gateway/hc-serve/miniapi/activity/postActivity`;
//场所列表
const areaListUrl = `/gateway/hc-serve/miniapi/activity/reservationPlaceList`;
//获取活动列表
const getActivityListUrl = `/gateway/hc-serve/miniapi/activity/activityList`;
// 阿姐排名列表
const nanxiSisterRankingListUrl = `/gateway/hc-serve/miniapi/nanxiSister/nanxiSisterRankingList`;
// 阿姐申请
const applyForNanxiSisterUrl = `/gateway/hc-serve/miniapi/nanxiSister/applyForNanxiSister`;
// 阿姐申请信息详情
const nanxiSisterDetailsUrl = `/gateway/hc-serve/miniapi/nanxiSister/nanxiSisterDetails`;
// 阿姐报名审核
const nanxiSisterRegistrationReviewUrl = `/gateway/hc-serve/miniapi/nanxiSister/nanxiSisterRegistrationReview`;
//获取活动详情
const getActivityDetailUrl = `/gateway/hc-serve/miniapi/activity/activityDetail`;
//我的活动参与人列表
const addPeopleListUrl = `/gateway/hc-serve/miniapi/activity/myActivityApplicantsList`;
//活动报名
const applyActivityUrl = `/gateway/hc-serve/miniapi/activity/activityApply`;
//删除活动参与人
const deletePeopleUrl = `/gateway/hc-serve/miniapi/activity/myActivityApplicantsDelete`;
// 参与人备注详情接口
const applicantsNoteDetailsUrl = `/gateway/hc-serve/miniapi/activity/applicantsNoteDetails`;
// 参与人备注编辑接口
const compileApplicantsNoteUrl = `/gateway/hc-serve/miniapi/activity/compileApplicantsNote`;
// 南溪阿姐发布活动详情
const publishActiveDetailsUrl = `/gateway/hc-serve/miniapi/activity/publishActiveDetails`;
export {
  publishActivityUrl,
  areaListUrl,
  getActivityListUrl,
  nanxiSisterRankingListUrl,
  applyForNanxiSisterUrl,
  nanxiSisterDetailsUrl,
  nanxiSisterRegistrationReviewUrl,
  getActivityDetailUrl,
  addPeopleListUrl,
  applyActivityUrl,
  deletePeopleUrl,
  applicantsNoteDetailsUrl,
  compileApplicantsNoteUrl,
  publishActiveDetailsUrl,
};

let origin;
if (window.location.origin == "https://mapi.xydata.cc") {
  origin = "https://test.zhuneng.cn"; //研发
  // origin = "https://wantou.zhuneng.cn";//生产
} else {
  origin = "";
}
// 话题列表
const getTopicListURL = `${origin}/gateway/hc-neighbour/topic/getTopicList`;
// 查询话题详情
const getTopicInfoByIdURL = `${origin}/gateway/hc-neighbour/topic/getTopicInfoById`;
// 点赞
const addPraiseURL = `${origin}/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `${origin}/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = `${origin}/gateway/hc-neighbour/topic/addComment`;
// 话题评论列表分页查询
const getTopicCommentList = `${origin}/gateway/hc-neighbour/topic/getTopicCommentList`;
// 评论回复列表分页查询
const getTopicReplyList = `${origin}/gateway/hc-neighbour/topic/getTopicReplyList`;

// 判断用户是否申请过 “和” 伙人
const getUserTalentStatusURL = `${origin}/gateway/hc-neighbour/talent/getUserTalentStatus2`;
//获取活动列表
const getActivityListUrl = `/gateway/hc-serve/miniapi/activity/activityList`;

export {
  getTopicListURL,
  getTopicInfoByIdURL,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
  getUserTalentStatusURL,
  getActivityListUrl
};

<template>
  <div class="rentPlaceDetail">
    <div class="rentPlaceDetail-body">
      <div class="activityDetail-t">
        <div class="introImg">
          <v-swiper :swiperData="areaDetail.pictureBeanList">
            <template #default="{ rows }">
              <img :src="rows" alt="" />
            </template>
          </v-swiper>
        </div>
        <div class="detailBox">
          <div class="actiName">
            <div class="item">
              <div class="item-r">{{ areaDetail.name }}</div>
            </div>
          </div>
          <div class="people rentStatus" v-if="areaDetail.rent">
            <div class="item">
              <div class="item-l">出租状态</div>
              <div class="item-r">{{ areaDetail.rent }}</div>
            </div>
          </div>
          <div class="people">
            <div class="item">
              <div class="item-l">面积</div>
              <div class="item-r">{{ areaDetail.size }} m2</div>
            </div>
          </div>
          <div class="people" v-if="areaDetail.monthPrice">
            <div class="item">
              <div class="item-l">月单价</div>
              <div class="item-r">{{ areaDetail.monthPrice }} 元/月</div>
            </div>
          </div>
          <div class="people" v-if="areaDetail.dayPrice">
            <div class="item">
              <div class="item-l">日单价</div>
              <div class="item-r">{{ areaDetail.dayPrice }} 元/日</div>
            </div>
          </div>
          <div class="people" v-if="areaDetail.hourPrice">
            <div class="item">
              <div class="item-l">时单价</div>
              <div class="item-r">{{ areaDetail.hourPrice }} 元/时</div>
            </div>
          </div>
          <div class="people" v-if="areaDetail.propertyPrice">
            <div class="item">
              <div class="item-l">物业费</div>
              <div class="item-r">{{ areaDetail.propertyPrice }} 元/m2/月</div>
            </div>
          </div>
          <div class="people" v-if="areaDetail.publicPrice">
            <div class="item">
              <div class="item-l">公共耗能费</div>
              <div class="item-r">{{ areaDetail.publicPrice }} 元/m2/月</div>
            </div>
          </div>
          <div class="people" v-if="areaDetail.direction">
            <div class="item">
              <div class="item-l">朝向</div>
              <div class="item-r">{{ areaDetail.direction }}</div>
            </div>
          </div>
          <div class="address" v-if="areaDetail.path">
            <div class="item">
              <div class="item-l">活动地点</div>
              <div class="item-r">{{ areaDetail.path }}</div>
            </div>
          </div>
          <div class="address" v-if="areaDetail.contactName">
            <div class="item">
              <div class="item-l">联系人</div>
              <div class="item-r">{{ areaDetail.contactName }}</div>
            </div>
          </div>
          <div class="address" v-if="areaDetail.contactMobile">
            <div class="item">
              <div class="item-l">联系电话</div>
              <div class="item-r">{{ areaDetail.contactMobile }}</div>
            </div>
          </div>
          <div
            class="address rentStatus"
            @click="getWechat"
            v-if="areaDetail.contactWechatQrcode"
          >
            <div class="item">
              <div class="item-l">联系人微信</div>
              <div class="item-r">点击获取</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="activityDetail-c">
        <div class="title">场所简介</div>
        <div class="body" v-html="areaDetail.description"></div>
      </div> -->
      <div class="activityDetail-b" @click="toSubmit">
        <div class="btn-r">租赁申请</div>
      </div>
    </div>
    <div class="dailog" v-if="isWechat" @click="closeImg">
      <div class="dialog-body" @click.stop="toImg">
        <img :src="areaDetail.contactWechatQrcode" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";
import { toRegister } from "@/utils/common";
import { gloabalCount } from "@/utils/common";
Vue.use(Swipe);
Vue.use(SwipeItem);
import { getRentDetailUrl } from "./api.js";
export default {
  name: "activityDetail",
  data() {
    return {
      isWechat: false,
      spaceId: "",
      areaDetail: {},
    };
  },
  created() {
    this.spaceId = this.$route.query.spaceId;
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
  },
  mounted() {
    this.getAreaDetail();
    gloabalCount(this.spaceId, 35, 1);
  },
  methods: {
    toImg() {},
    closeImg() {
      this.isWechat = false;
    },
    getWechat() {
      this.isWechat = true;
    },
    async toSubmit() {
      if (await toRegister(this.$route.path, this.$route.query, "场馆预约")) {
        this.$router.push({
          name: "rentPlaceSubmit",
          query: {
            spaceId: this.spaceId,
            name: this.areaDetail.name,
          },
        });
      }
    },
    getAreaDetail() {
      let params = {
        spaceId: this.spaceId,
      };

      this.$axios
        .post(`${getRentDetailUrl}?spaceId=${this.spaceId}`)
        .then((res) => {
          console.log(res, 5555);
          if (res.code === 200) {
            this.areaDetail = res.data;
            if (this.areaDetail.picture) {
              this.areaDetail.pictureBeanList =
                this.areaDetail.picture.split(",");
            }
          } else {
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.rentPlaceDetail {
  padding: 0 30px;
  padding-top: 30px;
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  box-sizing: border-box;

  background: #fafafa;
  .dailog {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .dialog-body {
      width: 400px;
      height: 400px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .rentPlaceDetail-body {
    padding-bottom: 100px;
  }
  .activityDetail-t {
    // background: #fff;
  }

  .introImg {
    height: 330px;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    border-radius: 16px;
    overflow: hidden;
    ::v-deep .my-swipe {
      width: 100%;
      height: 100%;
      .oSwiper {
        width: 100%;
        .van-swipe-item {
          img {
            width: 100%;
            height: 100%;
          }
          img[src=""],
          img:not([src]) {
            opacity: 0;
          }
        }
      }
    }
  }
  .detailBox {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    padding: 38px 30px 44px;
    box-sizing: border-box;
  }
  .actiName {
    padding-bottom: 26px;
    .item-r {
      font-size: 36px;
      font-weight: bold;
      color: #323334;
      line-height: 52px;
    }
  }
  .time,
  .people,
  .address {
    .item {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
    }
    .item-l {
      width: 150px;
      margin-right: 32px;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
    }
    .item-r {
      flex: 1;
      font-size: 28px;
      font-weight: 400;
      color: #97999b;
      line-height: 40px;
    }
  }
  .rentStatus {
    .item-r {
      color: #605cff;
    }
  }
  .title {
    position: relative;
    padding-left: 24px;
    font-size: 32px;
    font-weight: bold;
    color: #323334;
    line-height: 44px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 8px;
      height: 32px;
      background: #fe6f16;
      border-radius: 4px;
    }
  }

  .activityDetail-c {
    background: #fff;
    padding: 48px 32px 0;
    .body {
      font-size: 30px !important;
      padding: 32px 0;
      span {
        font-size: 30px !important;
      }
    }
  }
  .activityDetail-b {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #9591ff 0%, #5f5bff 100%);
    border-radius: 10px;
    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 66px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>

<template>
  <div class="section">
    <div class="section-detail">
      <div class="detail-picture">
        <img :src="detail.picture" alt="" />
      </div>
      <div class="detail-flex">
        <div class="flex-title">{{ detail.title }}</div>
        <div class="flex-time">参与时间：{{ detail.joinTime }}</div>
        <div class="flex-type">类型：{{ typeMap[detail.type] }}</div>
      </div>
    </div>
    <div class="section-sku">
      <span>{{ detail.goodsSpecification }}</span>
      <span>已有{{ detail.orderPersons }}人下单</span>
    </div>
    <div class="section-groupman">
      <div class="groupman-flex">
        <div class="groupman-flex-label">商户名字：</div>
        <div class="groupman-flex-value">{{ detail.mallName }}</div>
      </div>
      <div class="groupman-flex">
        <div class="groupman-flex-label">商户联系方式：</div>
        <div class="groupman-flex-value">{{ detail.mobileNumber }}</div>
      </div>
      <div class="groupman-flex">
        <div class="groupman-flex-label">自提点：</div>
        <div class="groupman-flex-value">{{ detail.getAddress }}</div>
      </div>
      <div class="groupman-flex">
        <div class="groupman-flex-label">提货时间：</div>
        <div class="groupman-flex-value">
          {{ detail.getStartTime | formatTime }}&nbsp;~&nbsp;{{
            detail.getEndTime | formatTime
          }}
        </div>
      </div>
    </div>

    <div class="section-item textarea">
      <div class="section-item--label">完成描述：</div>
      <div class="section-item--control">
        <div class="textarea">
          {{ detail.completeDescription }}
        </div>
      </div>
    </div>

    <div class="section-item upload">
      <div class="section-item--label">完成照片：</div>
      <div class="section-item--control">
        <div class="upload">
          <show-list :picList="detail.completePicture.split(',')"></show-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { myOrderNewDetailsURL } from "./api";
import { typeMap, setTypeOps } from "./map";
import showList from "../components/show-list.vue";

export default {
  components: {
    showList,
  },
  data() {
    return {
      typeMap,
      typeOps: setTypeOps,
      detail: {
        id: "",
        type: "",
        mallName: "",
        status: undefined,
        title: "",
        mobileNumber: "",
        joinTime: "",
        goodsSpecification: "",
        orderPersons: 0,
        getAddress: "",
        getStartTime: "",
        getEndTime: "",
        completeDescription: "",
        picture: "",
        completePicture: "",
      },
    };
  },
  filters: {
    formatTime(time) {
      return moment(time.replace(/-/g, "/")).format("YYYY-MM-DD HH:mm");
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const { id, type } = this.$route.query;
      this.detail.id = id;
      this.detail.type = type;
      this.getDetail();
    },
    getDetail() {
      this.$axios
        .get(myOrderNewDetailsURL, {
          params: {
            id: this.detail.id,
            // type: this.detail.type,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.detail = res.data;
            return;
          }
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        });
    },
  },
};
</script>

<style lang="less" scoped>
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.section {
  padding: 38px 30px 0;
  font-family: PingFangSC-Regular, PingFang SC;
  padding-bottom: calc(
    32px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    32px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-detail {
    display: flex;
    align-items: center;
    .detail-picture {
      margin-right: 18px;
      border-radius: 16px;
      overflow: hidden;
      img {
        &:extend(.common-img);
        width: 160px;
        height: 160px;
        object-fit: cover;
      }
    }
    .detail-flex {
      .flex-title {
        font-size: 32px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 44px;
      }
      .flex-time {
        margin-top: 12px;
        font-size: 26px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 36px;
      }
      .flex-type {
        margin-top: 12px;
        font-size: 26px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 36px;
      }
    }
  }
  .section-sku {
    margin-top: 20px;
    font-size: 28px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 40px;
    display: flex;
    span:first-child {
      flex: 1;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
    span:last-child {
      margin-left: 18px;
      white-space: nowrap;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .section-groupman {
    margin: 12px 0 0 0;
    box-sizing: border-box;
    padding: 36px 24px;
    font-size: 28px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 48px;
    background: #f9f9f9;
    border-radius: 10px;
    .groupman-flex {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      display: flex;
      .groupman-flex-label,
      .groupman-flex-value {
        display: inline-block;
      }
      .groupman-flex-label {
        white-space: nowrap;
      }
      .groupman-flex-value {
        flex: 1;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: bold;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
  .section-item {
    display: flex;
    .section-item--label {
      flex: 1;
      line-height: 88px;
      color: #333333;
      font-size: 30px;
      text-align: left;
      font-weight: 400;
      white-space: nowrap;
    }
    .section-item--control {
      max-width: 400px;
    }
    &.textarea {
      display: block;
      .section-item--control {
        box-sizing: border-box;
        max-width: none;
        padding: 36px 24px;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        line-height: 48px;
        background: #f9f9f9;
        border-radius: 10px;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
        ::v-deep .van-cell {
          border-radius: 16px;
          background: rgba(0, 0, 0, 0.05);
        }
      }
    }
    &.upload {
      display: block;
      .section-item--control {
        box-sizing: border-box;
        max-width: none;
        ::v-deep .van-uploader {
          padding-bottom: 28px;
          .van-uploader__preview {
            .van-uploader__preview-image {
              vertical-align: middle;
              width: 160px;
              height: 160px;
              img {
                vertical-align: middle;
                overflow: hidden;
                margin: 0;
                padding: 0;
              }
            }
            .van-uploader__preview-delete {
              width: 28px;
              height: 28px;
              border-radius: 0 0 0 24px;
              i {
                position: absolute;
                vertical-align: middle;
                font-size: 32px;
                top: 45%;
                left: 55%;
                transform: translateX(-50%) translateY(-50%) scale(0.5);
              }
            }
          }
          .van-uploader__upload {
            width: 160px;
            height: 160px;
          }
        }
        p {
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 34px;
          margin-bottom: 28px;
        }
      }
    }
  }
  .section-footer {
    .footer-button {
      width: 550px;
      height: 70px;
      margin: 70px auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #db431d;
      border-radius: 16px;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>

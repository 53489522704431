<template>
  <div class="page">
    <!-- <div class="title">
      {{ detail.title }}
    </div>
    <img :src="detail.banner" alt="" />
    <div class="content" v-html="detail.content"></div> -->
    <v-empty description="暂无数据"></v-empty>
  </div>
</template>

<script>
import { list } from "./map.js";
export default {
  name: "headlinesDetail",
  data() {
    return {
      list,
      detail: {},
    };
  },
  created() {
    this.detail = list[this.$route.query.index];
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  padding: 26px 30px;
  box-sizing: border-box;
  .content {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px;
    text-indent: 2em;
  }
  img {
    width: 100%;
    margin-bottom: 34px;
  }
  .title {
    text-align: center;
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #477f7d;
    line-height: 50px;
    padding-bottom: 38px;
  }
}
</style>

<template>
  <div class="section">
    <div class="section-search">
      <div class="search-input">
        <v-input v-model="title" placeholder="请输入商品名称" />
      </div>
      <div class="search-button" @click="onRefresh">
        <img src="../img/search_icon.png" alt="" />
      </div>
    </div>
    <div class="section-title">
      <span>{{ typeName }}</span>
    </div>
    <div class="list">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="section-flex-item"
          v-for="(item, index) in listData"
          :key="index"
          @click="toDetail(item)"
        >
          <img :src="item.imageUrl" alt="" />
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getGoodListByTypeAndTitleURL } from "./api.js";
// import {
//   businessTypeopsMap,
//   businessTypeops,
//   manageTypeopsMap,
//   manageTypeops,
// } from "./map.js";
// import { gloabalCount, toRegister } from "@/utils/common";
// import { handleImg } from "@/utils/utils.js";
export default {
  data() {
    return {
      title: "", // 关键字查询
      goodsType: "",
      typeName: "",
      /* v-list */
      finishedText: "没有更多了",
      finished: false,
      listData: [
        // {
        //   imageUrl: "",
        //   title: "【当季现挖】土豆 约500g",
        //   time: "2023-5-25 18:38",
        // },
        // {
        //   imageUrl: "",
        //   title: "【本地鲜】千岛湖青甘蓝800g/份",
        //   time: "2023-5-25 18:38",
        // },
        // {
        //   imageUrl: "",
        //   title: "【三优蔬菜】香葱 约50g",
        //   time: "2023-5-25 18:38",
        // },
      ],
      curPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId", "openId"]),
  },
  created() {
    const { goodsType, typeName } = this.$route.query;
    this.goodsType = goodsType;
    this.typeName = typeName;
  },
  methods: {
    // 重置商户列表
    onRefresh() {
      this.curPage = 1;
      this.listData = [];
      this.onLoad();
    },
    // 获取商户列表
    onLoad() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
        curPage: this.curPage,
        pageSize: this.pageSize,
        title: this.title,
        goodsType: this.goodsType,
        // houseId: this.houseId || this.communityId || undefined,
      };
      this.$axios
        .get(getGoodListByTypeAndTitleURL, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        });
    },
    // 点击跳转小程序
    toDetail(item) {
      // this.$router.push({
      //   name: "bcnServiceDetail",
      //   query: {
      //     id: item.id,
      //   },
      // });
    },
  },
};
</script>

<style scoped lang="less">
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.section {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f9f9f9;
  padding-bottom: calc(
    20px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    20px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-search {
    display: flex;
    align-items: center;
    padding: 44px 30px;
    background: #ffffff;
    .search-input {
      flex: 1;
      padding: 0 20px;
      ::v-deep .van-cell {
        padding: 0 36px;
        background: #f7f7f7;
        border-radius: 30px;
        line-height: 64px;
      }
    }
    .search-button {
      width: 60px;
      text-align: center;
      > img {
        width: 36px;
        height: 40px;
        &:extend(.common-img);
      }
    }
  }
  .section-title {
    margin: 40px 30px 0;
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 42px;
  }
  .list {
    box-sizing: border-box;
    width: 100%;
    padding: 0 28px;
    .section-flex-item {
      margin-top: 40px;
      width: 100%;
      height: 250px;
      border-radius: 16px;
      overflow: hidden;
      > img {
        &:extend(.common-img);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>

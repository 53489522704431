<template>
  <div class="questionnaireDetail">
    <div class="scroll-div">
      <div
        class="against"
        v-if="listData.length == 1 && listData[0] && listData[0].quType == 11"
      >
        <div
          class="against-item"
          v-for="(item, index) in listData"
          :key="index"
          :id="`questionnaireDetail_${index}`"
        >
          <div :class="['title', { titlediv: isdiv }]">{{ item.title }}</div>
          <div class="info" v-if="isdiv">感谢您的参与～</div>

          <div class="detail">
            <div v-html="description"></div>
          </div>

          <div class="content">
            <div
              class="item"
              v-for="(opt, oIndex) in item.optionList"
              :key="oIndex"
              :class="{ optionSelect: getIsSelect(opt, item) }"
              @click="setOptAnswer(opt, item)"
            >
              <div class="content-t" v-if="opt.imageUrls">
                <img :src="opt.imageUrls" />
              </div>
              <div class="content-b">{{ opt.optionTitle }}</div>
              <div class="btn" v-if="!isdiv">投票</div>
            </div>
          </div>
          <div class="result" v-if="isdiv">
            <div class="item">
              <div
                class="result-l"
                :style="{
                  width: `${getWidth(item.optionList[0], item.optionList)}px`,
                }"
              >
                <div
                  class="select"
                  v-if="item.answer == item.optionList[0].optionKey"
                >
                  <img :src="require('./img/check.png')" />
                </div>
                <div class="label">
                  {{
                    (item.optionList && item.optionList[0].checkedCount) || 0
                  }}
                </div>
              </div>
              <div class="img">
                <img :src="require('./img/result.png')" />
              </div>
              <div
                class="result-r"
                :style="{
                  width: `${getWidth(item.optionList[1], item.optionList)}px`,
                }"
              >
                <div class="label">
                  {{
                    (item.optionList && item.optionList[1].checkedCount) || 0
                  }}
                </div>
                <div
                  class="select"
                  v-if="item.answer == item.optionList[1].optionKey"
                >
                  <img :src="require('./img/check-right.png')" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="remark">
          <span class="span">{{ answerCount }}</span
          >人参与
          <span v-if="endDay != -1"
            >还有 <span class="span">{{ endDay }}</span> 结束</span
          >
          <span v-else>已结束</span>
        </div>
      </div>

      <template v-else>
        <div class="header">
          <div class="header-title">
            {{ activityName ? `${activityName}小测试` : "" }}
          </div>
          <!-- <div class="header-subtitle">
            <div class="sub-wrap">
              <div class="sub-wrap_icon">
                <img :src="require('./img/people.png')" />
              </div>
              <div class="sub-wrap_text">参与人数</div>
              <div class="sub-wrap_num">{{ answerCount }}</div>
            </div>
            <div
              class="sub-tag"
              :class="{ hasAnswer: hasAnswer !== 1 }"
              v-if="isdiv"
            >
              {{ hasAnswerMap[hasAnswer] }}
            </div>
          </div> -->
          <div class="detail">
            <div v-html="description"></div>
          </div>
          <!-- <div class="introImg">
            <img :src="voteDetail.imageUrls" />
          </div> -->
        </div>

        <div class="main">
          <div
            class="content-wrap"
            v-for="(item, index) in listData"
            :key="index"
            :id="`questionnaireDetail_${index}`"
          >
            <div class="content-wrap_title">
              {{ `${index + 1}、${item.title}` }}
              <template v-if="item.quType === 2">{{
                `${
                  item.minSelection ? `最少选择${item.minSelection}项，` : ""
                }${
                  item.maxSelection ? `最多选择${item.maxSelection}项` : ""
                }（多选）`
              }}</template>
              <template v-if="item.quType === 5">
                {{ "【矩阵量表题】从1-5进行打分" }}
              </template>
            </div>
            <div class="content-wrap_type" v-if="item.quType">
              <!--单选 -->
              <div class="single" v-if="item.quType === 1">
                <div
                  class="option-wrap"
                  v-for="(opt, oIndex) in item.optionList"
                  :key="oIndex"
                  :style="{
                    'background-image': !isdiv ? 'none' : 'none',
                  }"
                  :class="{ optionSelect: getIsSelect(opt, item) }"
                  @click="setOptAnswer(opt, item)"
                >
                  <div
                    v-show="isdiv"
                    class="option-progress"
                    :style="{
                      width: `${100 - getPercent(opt, item)}%`,
                    }"
                  ></div>
                  <div class="option-text_copy">
                    {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                  </div>
                  <div class="option-text">
                    {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                  </div>
                  <div class="option-icon">
                    <img :src="require('./img/check.png')" />
                  </div>
                  <!-- <div class="option-data" v-show="isdiv">
                    <div class="label">{{ opt.checkedCount || 0 }}人</div>
                    <div class="value">
                      {{ `${getPercent(opt, item)}%` }}
                    </div>
                  </div> -->
                </div>
              </div>
              <!-- 多选 -->
              <div class="multi" v-if="item.quType === 2">
                <div
                  class="option-wrap"
                  v-for="(opt, oIndex) in item.optionList"
                  :key="oIndex"
                  :class="{ optionSelect: getIsSelect(opt, item) }"
                  :style="{
                    'background-image': !isdiv ? 'none' : 'none',
                  }"
                  @click="setOptAnswer(opt, item)"
                >
                  <div
                    v-show="isdiv"
                    class="option-progress"
                    :style="{
                      width: `${100 - getPercent(opt, item)}%`,
                    }"
                  ></div>
                  <div class="option-text_copy">
                    {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                  </div>
                  <div class="option-text">
                    {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                  </div>
                  <div class="option-icon">
                    <img :src="require('./img/check.png')" />
                  </div>
                  <!-- <div class="option-data" v-if="isdiv">
                    <div class="label">{{ opt.checkedCount || 0 }}人</div>
                    <div class="value">
                      {{ `${getPercent(opt, item)}%` }}
                    </div>
                  </div> -->
                </div>
              </div>
              <!-- 文本 -->
              <div class="input" v-if="item.quType === 3">
                <v-input
                  type="textarea"
                  :disabled="isdiv"
                  v-model="item.answer"
                  :placeholder="isdiv ? '' : '请输入内容'"
                  :maxlength="200"
                  show-word-limit
                  rows="2"
                ></v-input>
              </div>
              <!-- 矩阵量表题 -->
              <div class="scale" v-if="item.quType === 5">
                <div
                  class="option-wrap"
                  v-for="(opt, oIndex) in item.optionList"
                  :key="oIndex"
                >
                  <div class="option-text">
                    {{ `${opt.optionKey}、${opt.optionTitle || ""}` }}
                  </div>
                  <div class="option-block">
                    <div
                      class="block-color"
                      v-for="(v, i) in scaleColorOps"
                      :key="i"
                      :style="{
                        backgroundColor: opt.point >= i + 1 ? v : '',
                      }"
                      :class="{ 'no-border': opt.point >= i + 1 }"
                      @click="setOptAnswer(opt, item, i)"
                    >
                      <span v-if="!isdiv">{{ i + 1 }}分</span>
                      <div class="option-data" v-if="isdiv">
                        <div class="label">
                          {{
                            opt.checkedCountExt
                              ? opt.checkedCountExt.split(",")[i]
                              : 0
                          }}人
                        </div>
                        <div class="value">
                          {{
                            `${getPercent(
                              opt.checkedCountExt
                                ? opt.checkedCountExt.split(",")[i]
                                : 0,
                              item.optionList,
                              5
                            )}%`
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="footer" @click="submit" v-if="listData.length > 0">
      {{ subLoading ? "提交中..." : "提交" }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getTopicInfo, getSubmitQuestionnaire } from "./api";
import { gloabalCount } from "@/utils/common";
import { showtime, regImg } from "@/utils/utils";
export default {
  components: {},
  data() {
    return {
      isQ: "",
      voteDetail: {},
      name: "questionnaireDetail",
      scrollInfodiv: "",
      isHas: false, // 用于判断调哪个详情接口, 是否参与 true是 false否
      subLoading: false, // 判断接口是否请求中
      title: "", // 问卷标题
      answerCount: 0, // 参与人数
      description: "", // 描述
      endDate: "", // 结束日期
      endDay: "",
      quStatus: undefined, // 状态：1：未开始 2：进行中 3：结束
      hasAnswer: 0, // 是否参与 1 是 2 否
      hasAnswerMap: {
        0: "未知",
        1: "已参与",
        2: "未参与",
      },
      scaleColorOps: [
        "#DCE6F7FF",
        "#CDDEFCFF",
        "#A4C3FAFF",
        "#367BF4B3",
        "#367BF4D1",
      ],
      listData: [],
      id: "",
      isdiv: true,
      activityId: "", //活动id
      activityName: "", // 课堂名称
    };
  },

  created() {
    this.id = this.$route.query.id;
    this.isQ = this.$route.query.isQ;
    this.activityName = this.$route.query.activityName;
    this.activityId = this.$route.query.activityId;
    if (this.isQ == 1) {
      gloabalCount(this.id, 28, 1);
    } else {
      gloabalCount(this.id, 30, 1);
    }

    this.getDetail();
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  mounted() {},
  methods: {
    // 获取详情
    getDetail() {
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast0
        forbidClick: true,
        message: "请求中...",
      });
      this.$axios
        .get(getTopicInfo, {
          params: {
            scheduleId: this.id,
            userId: this.userId,
          },
        })
        .then((res) => {
          const data = res.data || {};
          let onOff = true;
          this.listData = this.dealData(data.questionList, onOff);

          this.title = data.title;
          this.answerCount = data.answerCount;
          this.endDate = data.endDate;
          this.quStatus = data.quStatus;
          this.hasAnswer = data.hasAnswer;
          this.isHas = this.hasAnswer === 1;

          this.voteDetail = data;
          this.endDay = data.endDate ? showtime(data.endDate) : "";
          // this.detail = res.data;

          let { awTimes, hasAnswer, todayAwTimes } = data;
          /*
           * awTimes	回答次数：0-不限定，1-仅可以一次，2-一天一次
           * hasAnswer	是否回答：1:是;2:否
           * todayAwTimes	今天回答次数
           */

          if (awTimes === 0) {
            // 不限定，则不会为true
            onOff = false;
            this.listData = this.dealData(data.questionList, !onOff);
            console.log("=====>", this.listData);
          } else if (awTimes === 1) {
            // 仅可以一次且已参与
            onOff = hasAnswer === 1;
          } else if (awTimes === 2) {
            // 一天一次 且今天已回答
            onOff = todayAwTimes === 1;
            this.listData = this.dealData(data.questionList, !onOff);
            console.log("=====>", this.listData);
          }
          // 当状态不为 进行中，或者 onOff 为 true 时，仅能查看
          // this.isdiv = this.quStatus !== 2 || onOff;
          console.log(res.data, this.isdiv, "res.data");
          // this.detail.topicContent = regImg(this.detail.topicContent);
          // this.onRefresh();
          this.description = data.description;
          this.description = regImg(this.description);
          return;
        });
    },
    getWidth(opt, list) {
      let windowWidth = document.documentElement.clientWidth;
      console.log("windowWidth=====>", windowWidth);
      let ops = windowWidth / 750;
      let oWidth = 56 + 56 + 52;
      const total = this.answerCount;
      const cur = opt.checkedCount;
      if (list[0].checkedCount === list[1].checkedCount || total == 0) {
        return (windowWidth - oWidth * ops) * 0.5;
      }
      console.log(opt.checkedCount, total, "total");
      return (windowWidth - oWidth * ops) * (cur / total);
    },
    // 获取进度条百分比
    getPercent(opt, item, quType) {
      // opt 选项对象 item 题目对象
      if (opt) {
        const total = item.answerCount;
        const cur = quType === 5 ? opt : opt.checkedCount;
        return ((cur / total || 0) * 100).toFixed(2);
      }
      return 0;
    },
    // 获取是否选中状态
    getIsSelect(opt, item) {
      // opt 选项对象 item 题目对象
      if (item.answer) {
        const answerArr = String(item.answer).split(",");
        // console.log(item.answer, answerArr.includes(opt.optionKey));
        return answerArr.includes(opt.optionKey);
      }
      // 如果该字段没有，则需要手动添加
      this.$set(item, "answer", "");
      return false;
    },
    // 处理返回结果数据
    dealData(data, onOff) {
      data.forEach((v) => {
        if (onOff) {
          this.$set(v, "answer", "");
        }
        if (v.quType === 3 && v.answer === undefined) {
          this.$set(v, "answer", "");
        } else if (v.quType === 5) {
          let answer = v.answer ? v.answer.split(",") : [];
          v.optionList.forEach((opt, i) => {
            this.$set(opt, "point", answer ? answer[i] : 0);
          });
        }
      });
      return data;
    },
    // 设置选项答案
    setOptAnswer(opt, item, i) {
      // if (this.isdiv) {
      //   return;
      // }
      // 未参与答题时，该字段不会返回，所以需手动添加
      if (!item.answer) {
        this.$set(item, "answer", "");
      }
      const answerArr = item.answer ? item.answer.split(",") : [];
      const index = answerArr.indexOf(opt.optionKey);
      /**
       * 如果是单选
       */
      if (item.quType === 1) {
        item.answer = opt.optionKey;
      }
      /**
       * 如果是多选
       */
      if (item.quType === 2) {
        const { maxSelection } = item;
        // 如果index存在,表示点击已选中项
        if (index !== -1) {
          answerArr.splice(index, 1);
          item.answer = answerArr.join(",");
        } else {
          if (maxSelection && answerArr.length === maxSelection) {
            this.$toast({
              message: `最多选择${maxSelection}项`,
              duration: 1500,
            });
            return;
          }
          // 反之，表示点击未选中项
          item.answer += item.answer ? `,${opt.optionKey}` : opt.optionKey;
        }
      }
      /**
       * 如果是填空
       */
      if (item.quType === 3) {
        item.answer = opt.content;
      }
      /* 如果是矩阵量表 */
      if (item.quType === 5) {
        opt.point = i + 1;
        item.answer = item.optionList.map((v) => v.point).join(",");
      }
    },
    // 设置字段为可监控状态
    setField(item, str, val = "") {
      if (item[str]) {
        return true;
      }
      this.$set(item, str, val);
      return true;
    },
    // 验证
    valid() {
      const listData = this.listData;
      let scrollInfodiv = "";
      let title = "";
      listData.forEach((v, i) => {
        if ((v.quType === 1 || v.quType == 11) && v.answer === "") {
          // 单选
          scrollInfodiv = `questionnaireDetail_${i}`;
          title = `第${i + 1}题未选择`;
        } else if (v.quType === 2) {
          // 多选
          let answerArr = v.answer === "" ? [] : v.answer.split(",");
          const len = answerArr.length;
          // console.log(answerArr, "answerArr---");
          let minOnOff = v.minSelection ? len < v.minSelection : false;
          let maxOnOff = v.maxSelection ? len > v.maxSelection : false;
          if (v.answer === "" || minOnOff || maxOnOff) {
            scrollInfodiv = `questionnaireDetail_${i}`;
            let tip = "";
            tip = v.answer === "" ? "未选择" : "";
            tip = minOnOff ? `至少选择${v.minSelection}项` : tip;
            tip = maxOnOff ? `至多选择${v.maxSelection}项` : tip;
            title = `第${i + 1}题${tip}`;
          }
        } else if (v.quType === 3 && v.answer === "") {
          // 填空
          scrollInfodiv = `questionnaireDetail_${i}`;
          title = `第${i + 1}题不可为空`;
        } else if (v.quType === 4 && v.imageUrls === "") {
          // 图片上传
          scrollInfodiv = `questionnaireDetail_${i}`;
          title = `第${i + 1}题请上传图片`;
        } else if (v.quType === 5) {
          let result = false;
          v.optionList.forEach((opt) => {
            if (opt.point === 0) {
              result = true;
            }
          });
          if (result) {
            scrollInfodiv = `questionnaireDetail_${i}`;
            title = `第${i + 1}题存在选项未填`;
          }
        }
      });
      this.scrollInfodiv = scrollInfodiv;
      let timer = setTimeout(() => {
        this.scrollInfodiv = "";
        clearTimeout(timer);
      }, 0);
      if (title) {
        this.$toast({
          message: title,
          duration: 1500,
        });
      }
      return title === "";
    },
    submit() {
      console.log("提交", this.valid());
      if (!this.valid() && !this.subLoading) {
        return;
      }
      this.subLoading = true;
      this.$toast.loading({
        // duration: 1500, // 持续展示 toast0
        forbidClick: true,
        message: "请求中...",
      });
      let answer;
      this.listData.forEach((item) => {
        if (typeof item.answer === "string") {
          answer = item.answer;
          answer = answer.split(",");
          answer = answer.sort();
          item.answer = answer.join(",");
        }
      });

      this.$axios
        .post(getSubmitQuestionnaire, {
          answerList: this.listData,
          userId: this.userId,
          houseId: this.houseId || undefined,
          scheduleId: this.id,
          activityId: this.activityId,
        })
        .then((res) => {
          if (res.code === 200) {
            const { score } = res.data;
            // this.getDetail();
            this.$router.push({
              name: "totalPoints",
              params: {
                totalPoints: score,
              },
            });
          }
          this.$toast({
            message: res.msg || "操作失败，请稍后重试！",
            duration: 2000,
          });
        })
        .catch((res) => {
          this.$toast({
            message: res.msg || "操作失败，请稍后重试！",
            duration: 2000,
          });
        })
        .finally(() => {
          this.subLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.questionnaireDetail {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .scroll-div {
    height: calc(100% - 96px);
    overflow-y: scroll;
    &.isdiv {
      height: 100%;
      overflow-y: scroll;
    }
    .against {
      .title {
        font-size: 34px;
        font-weight: 700;
        color: #1a1c34;
        line-height: 44px;
        margin: 38px 0 102px;
        text-align: center;
      }
      .titlediv {
        margin: 38px 0 0;
      }
      .info {
        font-size: 32px;
        font-weight: 400;
        color: #007eff;
        line-height: 44px;
        text-align: center;
        margin: 24px 0 34px;
      }
      .detail {
        padding: 0 32px 32px;
      }
      .content {
        padding: 0 56px;
        display: flex;
        // justify-content: space-between;
        > .item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          .content-t {
            width: 304px;
            height: 230px;
            border-radius: 8px;
            margin-bottom: 12px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }
          .content-b {
            font-size: 32px;
            text-align: center;
            font-weight: 400;
            color: #1a1c34;
            line-height: 44px;
            word-wrap: break-word;
            word-break: break-all;
          }
          .btn {
            width: 304px;
            height: 70px;
            margin-top: 18px;
            background: #007eff;
            border-radius: 16px;
            text-align: center;
            font-size: 28px;
            font-weight: 600;
            color: #ffffff;
            line-height: 70px;
          }
        }
      }
      .remark {
        font-size: 24px;
        margin-top: 36px;
        padding-left: 56px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        line-height: 34px;
        .span {
          color: rgba(0, 126, 255, 1);
        }
      }
      .result {
        padding: 18px 56px;
        .item {
          display: flex;
          align-items: center;
          .select {
            width: 40px;
            height: 40px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .result-l {
            height: 70px;
            padding-left: 80px;
            background: #dbedff;
            border-radius: 16px 0 0 16px;
            border: 2px solid #b7d8fb;
            border-right: 0;
            box-sizing: border-box;
            position: relative;
            .select {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 4px;
            }
            .label {
              left: 50px;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              font-size: 32px;
              font-weight: 400;
              color: #007eff;
              line-height: 70px;
              // padding-left: 26px;
            }
          }

          .img {
            width: 52px;
            height: 70px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .result-r {
            padding-right: 80px;
            box-sizing: border-box;
            height: 70px;
            background: #fff3eb;
            border-radius: 0 16px 16px 0;
            border: 2px solid rgba(254, 111, 22, 0.27);
            border-left: 0;
            position: relative;
            .select {
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              right: 4px;
            }
            .label {
              font-size: 32px;
              position: absolute;
              right: 50px;
              top: 0;
              bottom: 0;
              margin: auto;
              font-weight: 400;
              color: #fe6f16;
              // padding-right: 26px;
              line-height: 70px;
            }
          }
        }
      }
    }

    .header {
      .header-title {
        padding-top: 44px;
        font-size: 32px;
        font-weight: bold;
        color: #1a1c34;
        line-height: 44px;
        text-align: center;
        word-wrap: break-word;
        word-break: break-all;
      }
      .header-subtitle {
        display: flex;
        justify-content: center;
        margin: 32px 0 80px 0;
        .sub-wrap {
          display: flex;
          .sub-wrap_icon {
            height: 40px;
            img {
              width: 40px;
              height: 40px;
            }
          }
          .sub-wrap_text {
            height: 40px;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: 400;
            color: #aaaaaa;
            margin: 0 8px 0 4px;
          }
          .sub-wrap_num {
            height: 40px;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-weight: bold;
            color: #666666;
          }
        }
        .sub-tag {
          box-sizing: border-box;
          height: 40px;
          display: flex;
          align-items: center;
          background: #dbedff;
          border-radius: 4px;
          border: 2px solid #acd5ff;
          font-size: 24px;
          font-weight: 400;
          color: #007eff;
          padding: 0 12px;
          margin-left: 48px;
          &.hasAnswer {
            border: 2px solid #ffc7a4;
            background: #fff3eb;
            color: #fe6f16;
          }
        }
      }
      .detail {
        padding: 0 32px 32px;
      }
      .introImg {
        width: 100%;
        padding: 0 32px;
        box-sizing: border-box;
        margin-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .main {
      box-sizing: border-box;
      width: 100%;
      padding: 0 32px;
      .content-wrap {
        .content-wrap_title {
          font-size: 32px;
          font-weight: bold;
          color: #333333;
          line-height: 44px;
          margin-bottom: 24px;
          word-wrap: break-word;
          word-break: break-all;
        }
        .content-wrap_type {
          .single,
          .multi {
            margin-bottom: 80px;
            .option-wrap {
              position: relative;
              box-sizing: border-box;
              // background: #ffffff;
              background-image: linear-gradient(
                90deg,
                #e7f0fd 0%,
                #accbee 100%
              );
              border-radius: 8px;
              // border: 2px solid #acd5ff;
              border: 2px solid #c9cacb;
              overflow: hidden;
              &:not(:last-child) {
                margin-bottom: 16px;
              }
              .option-progress {
                box-sizing: border-box;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                // width: 40%;
                width: 100%;
                // background: #f5f5f5;
                // border-right: 2px solid #c9cacb;
                // background: #dbedff;
                background: #fff;
                // border-right: 2px solid #acd5ff;
                border-radius: 0px 8px 8px 0px;
              }
              .option-text_copy,
              .option-text {
                font-size: 32px;
                font-weight: 400;
                color: #333333;
                line-height: 52px;
                padding: 14px 164px 14px 32px;
                word-wrap: break-word;
                word-break: break-all;
              }
              .option-text_copy {
                opacity: 0;
              }
              .option-text {
                box-sizing: border-box;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
              }
              .option-icon {
                display: none;
                position: absolute;
                top: 50%;
                right: 30px;
                transform: translateY(-50%);
                width: 40px;
                height: 40px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .option-data {
                font-size: 24px;
                position: absolute;
                top: 50%;
                right: 12px;
                transform: translateY(-50%);
                text-align: center;
                width: 100px;
                .label {
                  // border-bottom: 2px solid #333333;
                }
              }
              &.optionSelect {
                // border: 2px solid #c9cacb;
                border: 2px solid #acd5ff;
                .option-progress {
                  // background: #f5f5f5;
                  // border-right: 2px solid #c9cacb;
                  // background: #dbedff;
                  // border-right: 2px solid #acd5ff;
                }
                .option-icon {
                  display: block;
                }
              }
            }
          }
          .input {
            width: calc(100% - 64px);
            margin-bottom: 80px;
            border: 2px solid #c9cacb;
            padding: 20px 32px;
            color: #333333;
            border-radius: 8px;
            textarea {
              font-size: 32px;
              font-weight: 400;
            }
          }
          .upload {
            margin-bottom: 80px;
            .upload-empty {
              font-size: 28px;
              color: #333333;
            }
          }
          .scale {
            margin-bottom: 40px;
            color: #333333;
            .option-wrap {
              .option-text {
                font-size: 32px;
                font-weight: 400;
                color: #333333;
                line-height: 48px;
                // padding: 7px 40px 7px 16px;
                padding: 14px 0;
                word-wrap: break-word;
                word-break: break-all;
              }
              .option-block {
                display: flex;
                align-items: center;
                margin-bottom: 40px;
                .block-color {
                  box-sizing: border-box;
                  // flex: 1;
                  width: calc(25% - 8px);
                  height: 72px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #43474fff;
                  background: #ffffff;
                  border-radius: 4px;
                  border: 2px solid #cbcbcb;
                  .option-data {
                    font-size: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    .label {
                      word-wrap: break-word;
                      word-break: break-all;
                      // border-bottom: 2px solid #333333;
                    }
                  }
                  &:not(:last-child) {
                    margin-right: 10px;
                  }
                  &:last-child {
                    color: #000000d9;
                  }
                }
                .no-border {
                  border: 0px solid #cbcbcb;
                }
              }
            }
          }
        }
      }
    }
  }
  .footer {
    width: 550px;
    height: 66px;
    margin: auto;
    background: linear-gradient(180deg, #ffde6d 0%, #ffd032 100%);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    letter-spacing: 4px;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="page">
    <div class="tab">
      <div
        :class="['item', { active: curTab.type == item.type }]"
        v-for="(item, index) in tabList"
        :key="index"
        @click="changeTab(item, index)"
      >
        {{ item.name }}
      </div>
    </div>

    <v-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="refresh" v-show="curTab.type == 1">
        <v-list
          :finished="finished"
          :finishedText="finishedText"
          @onLoad="onLoad"
          ref="list"
        >
          <div
            class="list-content"
            v-for="(item, index) in listData"
            :key="index"
            @click="toRouteTitle(item)"
          >
            <div class="list-flex">
              <div class="image">
                <img :src="item.topicPicture" alt="" />
              </div>
              <div class="wrapper">
                <div class="wrapper-title">
                  <span>{{ item.topicTitle }}</span>
                </div>
                <div class="wrapper-date">
                  <span>{{ item.createTime }}</span>
                </div>
                <div class="wrapper-content">
                  <div class="content-tag">
                    <div class="iconText">
                      <div class="icon">
                        <img src="../img/icon-hot.png" />
                      </div>
                      <div class="text">
                        <span>{{ item.readCount }}</span>
                      </div>
                    </div>
                    <div class="iconText">
                      <div class="icon">
                        <img src="../img/icon-msg.png" />
                      </div>
                      <div class="text">
                        <span>{{ item.commentCount }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
      <div class="refresh" v-show="curTab.type == 2">
        <v-list
          :finished="finished2"
          :finishedText="finishedText2"
          @onLoad="getTableData"
          ref="list2"
        >
          <div
            class="list-content"
            v-for="(item, index) in tableData"
            :key="index"
            @click="toRouteDetail(item)"
          >
            <div class="list-flex">
              <div class="image">
                <img :src="item.picture" alt="" />
              </div>
              <div class="wrapper">
                <div class="wrapper-title">
                  <span>{{ item.name }}</span>
                </div>
                <div class="wrapper-description">
                  <span>{{ item.description }}</span>
                </div>
              </div>
            </div>
          </div>
        </v-list>
      </div>
    </v-refresh>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { getTopicListURL, getListURL } from "../api.js";

export default {
  name: "neighborlyPool",
  data() {
    return {
      curTab: {
        name: "睦邻汇公示",
        type: 1,
      },
      tabList: [
        {
          name: "睦邻汇公示",
          type: 1,
        },
        {
          name: "联建单位",
          type: 2,
        },
      ],
      isLoading: false,
      listData: [], // 列表数据集合
      finished: true, // 列表是否加载完成
      finishedText: "没有更多了", // 列表加载完成后的提示语
      curPage: 1,
      pageSize: 10,

      tableData: [], // 联建单位数据
      finishedText2: "没有更多了", // 列表加载完成后的提示语
      finished2: true, // 列表是否加载完成
      curPage2: 1,
      pageSize2: 10,

      /* v-dialog */
      isTipsDialog: false,
      isDialog: false,
      isRemoveDialog: false,
      curData: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  beforeRouteEnter(to, form, next) {
    if (form.name === "batUnitDetail") {
      next((vm) => {
        vm.curTab = {
          name: "联建单位",
          type: 2,
        };
      });
    } else {
      next();
    }
  },
  created() {
    // this.mobile = this.$route.query.mobile;
    this.onLoad();
    this.getTableData();
  },
  methods: {
    changeTab(item) {
      this.curTab = item;
    },
    // 睦邻汇跳转详情页
    toRouteTitle(item) {
      let params = {
        id: item.id,
      };
      if (wx.miniProgram) {
        wx.miniProgram.navigateTo({
          url: `/subPages/topic/topicTitle?params=${JSON.stringify(params)}`,
        });
      }
    },
    // 联建单位详情页
    toRouteDetail(item) {
      this.$router.push({
        name: "batUnitDetail",
        query: {
          id: item.id,
        },
      });
    },
    onRefresh() {
      if (this.curTab.type == 1) {
        this.curPage = 1;
        this.listData = [];
        this.onLoad();
      } else if (this.curTab.type == 2) {
        this.curPage2 = 1;
        this.tableData = [];
        this.getTableData();
      }
    },
    // 加载列表数据
    onLoad() {
      this.$axios
        .get(getTopicListURL, {
          params: {
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            status: 1, // 0：待审核；1：审核通过；2：审核不通过；3：已关闭；4：删除
            topicType: 43, // 43 睦邻汇
            curPage: this.curPage,
            pageSize: this.pageSize,
            // sortField: "NEW", // 排序方式， 最热 or 最新
            // createUserMobile: this.detail.mobile,
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                res.data.records.forEach((item) => {
                  if (item.topicPicture) {
                    item.topicPicture = this.$handleImg(
                      256,
                      256,
                      item.topicPicture
                    );
                  }
                });
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  if (item.topicPicture) {
                    item.topicPicture = this.$handleImg(
                      256,
                      256,
                      item.topicPicture
                    );
                  }
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        })
        .catch(() => {
          this.finished = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 联建单位数据
    getTableData() {
      this.$axios
        .get(getListURL, {
          params: {
            tenantId: this.tenantId,
            houseId: this.houseId || this.communityId || undefined,
            userId: this.userId,
            curPage: this.curPage2,
            pageSize: this.pageSize2,
          },
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished2 = true;
          } else {
            if (this.curPage2 >= res.data.pages) {
              this.finished2 = true;
            } else {
              this.finished2 = false;
              this.$refs.list2.loading = false;
            }
            this.finishedText2 = "没有更多了";
            if (res.data.records.length === 0) {
              this.tableData = [];
              this.finishedText2 = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage2 === 1) {
                res.data.records.forEach((item) => {
                  if (item.picture) {
                    item.picture = this.$handleImg(256, 256, item.picture);
                  }
                });
                this.tableData = res.data.records;
              } else {
                const list = this.tableData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  if (item.picture) {
                    item.picture = this.$handleImg(256, 256, item.picture);
                  }
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.tableData.push(item);
                });
              }
              this.curPage2++;
            }
          }
        })
        .catch(() => {
          this.finished2 = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
// 隐藏所有滚动条
::-webkit-scrollbar {
  display: none;
}
.page {
  position: relative;
  // min-height: 100%;
  background: rgba(249, 249, 249, 1);
  box-sizing: border-box;
  // border: 1px solid rgba(249, 249, 249, 1); // 解决子元素margin-top问题
  // padding-top: 132px;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  // ::v-deep .van-pull-refresh {
  //   box-sizing: border-box;
  //   min-height: 100vh;
  //   padding-top: 132px;
  // }
  .refresh {
    box-sizing: border-box;
    min-height: 100vh;
    padding-top: 132px;
  }

  .tab {
    display: flex;
    justify-content: space-around;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    background: #fff;
    .item {
      padding: 30px 0 30px;
      height: 40px;
      font-size: 32px;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
    }
    .active {
      font-weight: 600;
      color: rgba(254, 149, 20, 1);
      position: relative;
      &::after {
        content: "";
        width: 120px;
        height: 8px;
        background: rgba(254, 149, 20, 1);
        border-radius: 4px;
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  .manager {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 248px;
    margin: 0 32px 64px;
    padding: 0px 48px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    .avatar {
      border-radius: 50%;
      width: 150px;
      height: 150px;
      object-fit: cover;
      overflow: hidden;
    }
    .name {
      margin-left: 32px;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 36px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }
    .mobile {
      width: 40px;
      height: 52px;
    }
    .weixin {
      margin-left: 32px;
      width: 52px;
      height: 52px;
    }
  }

  .title {
    padding: 0 30px;
    font-size: 36px;
    font-weight: bold;
    color: rgba(26, 28, 52, 1);
  }
  .list-content {
    display: flex;
    align-items: center;
    overflow: auto;
    margin: 0 30px;
    .list-flex {
      display: flex;
      width: 688px;
      min-width: 688px;
      margin: 16px 0px 16px;
      border-radius: 16px;
      box-shadow: 0px 4px 16px 0px rgba(227, 277, 277, 0.5);
      background: #fff;
      .wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 40px 0 24px 0;
        .wrapper-title {
          flex: 1;
          width: 100%;
          max-width: 360px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          // word-wrap: break-word;
          // word-break: break-all;
          font-size: 32px;
          color: rgba(0, 0, 0, 0.85);
        }
        .wrapper-date {
          margin-top: 10px;
          display: flex;
          align-items: center;
          font-size: 24px;
          color: rgba(180, 180, 180, 1);
        }
        .wrapper-description {
          display: flex;
          word-wrap: break-word;
          word-break: break-all;
          margin: 10px 32px 24px 0;
          font-size: 24px;
          color: rgba(180, 180, 180, 1);
          /* start webkit 内核支持多行省略 */
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          /*  end */
        }
        .wrapper-content {
          margin-top: 10px;
          font-size: 24px;
          color: rgba(180, 180, 180, 1);
          display: flex;
          .content-tag {
            display: flex;
            .iconText {
              display: flex;
              color: #a8a8a8;
              align-items: center;

              &:not(:last-child) {
                margin-right: 32px;
              }
              .icon {
                display: flex;
                align-items: center;
                &.icon-up {
                  > img {
                    margin-top: 4px;
                  }
                }
                > img {
                  width: 36px;
                  height: 36px;
                }
              }

              .text {
                margin-left: 8px;
                display: flex;
                align-items: center;
                height: 36px;
                line-height: 36px;

                span {
                  &.hasPraised {
                    color: #ff853a;
                  }

                  font-size: 24px;
                  line-height: 1;
                }
              }
            }
          }
        }
      }
      .image {
        box-sizing: border-box;
        // border: 1px solid #000;
        border-radius: 16px 0 0 16px;
        margin-right: 44px;
        width: 256px;
        min-width: 256px;
        // min-height: 256px;
        height: 256px;
        overflow: hidden;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .remove {
      width: 68px;
      height: 68px;
      margin-left: 20px;
    }
  }

  .table {
    margin: 0 32px;
    padding: 44px 36px;
    background: #fff;
    border-radius: 16px;
    box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
    font-size: 24px;
    word-wrap: break-word;
    word-break: break-all;
    .thead {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      background: rgba(216, 216, 216, 1);
      padding: 12px 0;
      text-align: center;
      border-radius: 8px;
      white-space: normal;
    }
    .thead,
    .th {
      display: flex;
      align-items: center;
      padding: 12px 0;
      text-align: center;
      .name,
      .mobile,
      .area {
        box-sizing: border-box;
        padding: 0 10px;
      }
      .name {
        width: 20%;
      }
      .mobile {
        width: 35%;
      }
      .area {
        width: 45%;
      }
    }
    .tbody {
      .th .area {
        text-align: left;
      }
    }
  }

  .addMessage {
    position: absolute;
    right: 0px;
    bottom: 200px;
    width: 200px;
    height: 206px;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  .tipsBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
    .box {
      width: 568px;
      height: 290px;
      background: #ffffff;
      border-radius: 16px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .box_t {
        width: 100%;
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        text-align: center;
        padding-top: 50px;
      }
      .box_c {
        width: 100%;
        white-space: nowrap;
        font-size: 30px;
        color: rgba(0, 0, 0, 0.5);
        text-align: center;
        padding-top: 26px;
        box-sizing: border-box;
      }
      .box_b {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 86px;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        display: flex;
        .btn_l,
        .btn_r {
          flex: 1;
          text-align: center;
          line-height: 86px;
          font-size: 36px;
          color: #333333;
        }
        .border-l {
          border-left: 2px solid rgba(0, 0, 0, 0.1);
          font-weight: 600;
          color: #5e5aff;
        }
      }
    }
  }
  .wechatBox {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);

    .img {
      width: 370px;
      position: absolute;
      height: 368px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      .close_icon {
        width: 76px;
        height: 76px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -126px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>

<template>
  <div class="myCreate">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div
        v-for="(item, index) in listData"
        :key="index"
        class="oItem"
        @click="toDetail(item)"
      >
        <div class="oItem-avatar">
          <img
            :src="
              item.avatar
                ? item.avatar
                : require('@/assets/img/default_avatar.png')
            "
            alt=""
          />
        </div>
        <div class="oItem-wrap">
          <div class="oItem-head">
            <div class="oItem-headName">
              <div class="oItem-tl oItem-name">
                <span>{{
                  item.userName ? formatStrFunc(item.userName) : ""
                }}</span>
              </div>
              <div class="oItem-t">
                <div class="oItem-tr oItem-time">
                  <span>{{ item.insertTime }}</span>
                </div>
              </div>
            </div>
            <div class="oItem-tag" :style="{ background: getColor(item) }">
              <span>{{ getAuditStatus(item) }}</span>
            </div>
          </div>
          <div class="oItem-b">
            <div class="oitem-b_tag">
              <span>#{{ getStatus(item) }}#</span>
            </div>
            <div class="oItem-b_text">
              <span>{{ item.proposalContent }}</span>
            </div>
          </div>
          <div class="oItem-c" v-if="item.proposalPic">
            <img :src="item.proposalPic" alt="" />
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { myWishUrl } from "./api.js";
import { getOption, formatStr } from "@/utils/utils.js";
import { createType } from "./map.js";
import { auditStatusMap } from "@/views/futureNeighbourhood/wishes/map";
import { handleImg } from "@/utils/utils.js";
var moment = require("moment");
export default {
  name: "myCreate",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  mounted() {
    // this.getActivityList();
  },
  methods: {
    getColor(item) {
      const colorMap = [
        { key: 1, value: "#d9220b" },
        { key: 2, value: "#CBCCCD" },
        { key: 0, value: "#fe6f16" },
      ];
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, colorMap, "key").value;
      } else {
        return "";
      }
    },
    formatStrFunc(str) {
      if (str) {
        return formatStr(str);
      }
    },
    getAuditStatus(item) {
      if (item.auditStatus || item.auditStatus == 0) {
        return getOption(item.auditStatus, auditStatusMap, "key").value;
      } else {
        return "";
      }
    },
    getStatus(item) {
      if (item.proposalType) {
        return getOption(item.proposalType, createType, "key").value;
      } else {
        return "";
      }
    },
    onLoad() {
      this.getWishList();
    },
    getWishList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios.get(`${myWishUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            if (item.avatar) {
              item.avatar = handleImg(80, 80, item.avatar);
            }
            if (item.proposalPic && item.proposalPic.length > 0) {
              item.proposalPic = handleImg(
                (document.body.clientWidth - 80) * 2,
                Math.ceil((((document.body.clientWidth - 80) * 2) / 16) * 9),
                item.proposalPic
              );
            }
            this.listData.push(item);
          });
        } else {
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        name: "createDetail",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.myCreate {
  min-height: 100vh;
  background: #f7f7f7;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .oItem {
    background: #fff;
    padding: 48px 32px 46px;
    border-bottom: 2px solid #ebebeb;
    // margin-bottom: 30px;
    display: flex;

    .oItem-avatar {
      width: 72px;
      height: 72px;
      min-width: 72px;
      /*border-radius:30px;*/
      /*border: 0.5px solid #999;*/
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .oItem-wrap {
      flex: 1;
      max-width: calc(100% - 72px);
      box-sizing: border-box;
      padding-left: 16px;
      .oItem-head {
        display: flex;
        // justify-content: space-between;
        font-weight: bold;
        // align-items: center;
        .oItem-headName {
          flex: 1;
          font-size: 28px;
          .oItem-name {
            font-size: 28px !important;
            font-weight: bold;
            line-height: 40px;
          }
          .oItem-t {
            display: flex;
            font-size: 20px;
            margin-top: 4px;
            font-weight: 400;
            color: #97999b;
            line-height: 28px;
            .oItem-time {
              font-size: 28px !important;
              font-weight: normal !important;
              color: #999999;
              display: flex;
            }
          }
        }
        .oItem-tag {
          // background: #fe6f16;
          // background: #d9220b;
          // background: #f4ea2a;
          border-radius: 8px;
          padding: 4px 16px;
          display: flex;
          height: 40px;
          box-sizing: border-box;
          // line-height: 32px;
          span {
            font-weight: 400;
            color: #ffffff;
            font-size: 24px;
            vertical-align: middle;
          }
        }
      }
      .oItem-b {
        font-size: 32px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // margin-left: 30px;
        line-height: 44px;
        .oitem-b_tag {
          font-size: 32px;
          font-weight: 400;
          color: #fe6f16;
          margin-top: 16px;
        }
        .oItem-b_text {
          font-size: 32px;
          font-weight: 400;
          color: #333333;
          margin-top: 8px;
        }
      }
      .oItem-c {
        height: calc(100vw / 16 * 9);
        margin: 8px 0 0;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="section">
    <div class="section-content">
      <div class="content-tel">
        <div class="content-text">
          我们重视您对服务的每一声问询，提供服务查询、咨询，信息反馈、投诉及建议等服务内容。如有需要您可以联系我们。<br />
        </div>
        <div class="text">如商品订单问题请在商品页面先联系商家。</div>
        <div class="tel">热线电话：<span>88585518</span>(工作日)</div>
      </div>
      <div class="content-qrCode">
        <div class="qrCode-title">微信联系：请扫下面的二维码</div>
        <div class="qrCode-subTitle">长按识别二维码</div>
        <div class="qrCode-picture">
          <img src="../img/code.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.section {
  box-sizing: border-box;
  padding: 56px 30px 0px;
  background: #fffaf6;
  min-height: 100vh;
  font-family: PingFangSC-Regular, PingFang SC;
  display: flex;
  flex-direction: column;
  padding-bottom: calc(
    32px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    32px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-content {
    flex: 1;
    box-sizing: border-box;
    border: 4px solid #fee1d1;
    background: #fff;
    border-radius: 16px;
    padding-bottom: 60px;
    overflow: hidden;
    .content-tel {
      padding: 60px 36px 36px;
      background: #fff6f3;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0%;
        display: block;
        width: 100%;
        height: 60px;
        border-radius: 50%;
        transform: translateY(50%);
        background: #fff6f3;
      }
      .content-text {
        font-size: 28px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.65);
        line-height: 48px;
      }
      .text {
        margin-top: 20px;
        font-size: 28px;
        font-weight: bold;
        line-height: 48px;
        color: red;
      }
      .tel {
        margin-top: 104px;
        font-size: 36px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 48px;
        text-align: center;
        span {
          color: #df6449;
          text-decoration: underline;
        }
      }
    }
    .content-qrCode {
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 400;
      line-height: 48px;
      .qrCode-title {
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 48px;
      }
      .qrCode-subTitle {
        font-size: 26px;
        color: rgba(0, 0, 0, 0.5);
      }
      .qrCode-picture {
        margin-top: 14px;
        > img {
          width: 400px;
          height: 400px;
          overflow: hidden;
        }
      }
    }
  }
}
</style>

<template>
  <div class="section">
    <div class="section-swiper">
      <swiper-preview
        :autoplay="2000"
        :swiperData="detail.detailsUrl ? detail.detailsUrl.split(',') : []"
      />
    </div>
    <div class="section-number">
      <div class="number-sell">
        <div class="number-sell-price">
          ￥<span>{{ detail.nowCost }}</span>
        </div>
        <div class="number-sell-sold">
          已有{{ detail.groupCount || 0 }}人{{ buyType == 1 ? "参团" : "秒杀" }}
        </div>
      </div>
      <!-- <div class="number-stock">
        仅剩<span>{{ detail.goodsRemainCount }}</span>
      </div> -->
    </div>
    <div class="section-name">{{ detail.goodsTitle }}</div>
    <div class="section-sku" v-if="detail.goodsSpecification">
      {{ detail.goodsSpecification }}
    </div>

    <div class="section-groupman">
      <div class="groupman-flex">
        <div class="groupman-flex-label">商户名字：</div>
        <div class="groupman-flex-value">{{ detail.legalShopName }}</div>
      </div>
      <div class="groupman-flex" @click="toPhone(detail.mobileNum)">
        <div class="groupman-flex-label">商户联系方式：</div>
        <div class="groupman-flex-img">
          <img src="./img/phone.png" alt="" />
        </div>
      </div>
      <div class="groupman-flex">
        <div class="groupman-flex-label">自提点：</div>
        <div class="groupman-flex-value">{{ detail.getAddress.join(",") }}</div>
      </div>
      <div class="groupman-flex">
        <div class="groupman-flex-label">提货时间：</div>
        <div class="groupman-flex-value" v-if="detail.getEndTime">
          {{ detail.getStartTime | formatTime }}&nbsp;~&nbsp;{{
            detail.getEndTime | formatTime
          }}
        </div>
      </div>
      <div class="groupman-flex" v-if="detail.content">
        <div class="groupman-flex-label">商品介绍：</div>
        <div class="groupman-flex-value">
          {{ detail.content }}
        </div>
      </div>
    </div>
    <!-- <div class="section-detail">
      <div class="detail-nav">
        <div class="common-title"><span>产品详情</span></div>
      </div>
      <div class="detail-content">
        <v-h5Mtml :content="detail.introduce || '暂无'"></v-h5Mtml>
      </div>
    </div> -->

    <div
      class="section-footer"
      v-if="
        detail.status &&
        detail.status != 1 &&
        detail.status != 3 &&
        detail.status != 99
      "
    >
      <div class="footer-button">
        <div class="cart" @click="addShopCart">加入购物车</div>
        <div class="order" @click="orderNow">立即下单</div>
      </div>
    </div>
    <!-- 提货 -->
    <div :class="['popup', { delivery: step == 1 }]" v-if="showPopup">
      <div class="popup-overlary" @click="showPopup = false"></div>
      <div class="popup-content" v-if="step == 1">
        <div class="content-title">请选择提货方式：</div>
        <div class="content-select">
          <div
            :class="['select-option', currentIndex === index ? 'current' : '']"
            v-for="(item, index) in deliveryMethod"
            :key="index"
            @click="chooseType(item, index)"
          >
            <span class="icon"></span>
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="popup-footer">
          <div class="footer-button" @click="nextStep">下一步</div>
        </div>
      </div>
      <div class="popup-content" v-if="step == 2">
        <div class="content-title">选择提货点：</div>
        <div class="content-select">
          <div
            :class="['select-option', curAddress === o ? 'current' : '']"
            v-for="(o, index) in detail.getAddress"
            :key="index"
            @click="changeAddress(o)"
          >
            <span class="icon"></span>
            <span>{{ o }}</span>
          </div>
        </div>
        <div class="popup-footer">
          <div class="footer-button" @click="payNow">立即支付</div>
        </div>
      </div>
      <div class="popup-content" v-if="step == 3">
        <div class="content-title">填写配送地址：</div>
        <div class="content-select search-input">
          <v-input v-model="shippingAddress" placeholder="请输入"></v-input>
        </div>
        <div class="popup-footer">
          <div class="footer-button" @click="payNow">立即支付</div>
        </div>
      </div>
    </div>
    <div
      class="dialog"
      @click.prevent="isShowDialog = false"
      v-if="isShowDialog"
    >
      <div class="dialog-content" @click.stop>
        <div class="title">免责条款</div>
        <div class="text">
          1.1用户明确同意其使用“奉化丨家门口”小程序网络服务所存在的风险及后果将完全由用户本人承担，“奉化丨家门口”对此不承担任何责任。<br />
          1.2对于因不可抗力或“奉化丨家门口”不能控制的原因造成服务中断或其他缺陷，“奉化丨家门口”不承担任何责任，但将尽力减少因此给用户造成的影响。包括天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如小程序服务器环境主机或网络故障、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免、不能克服的客观情况。<br />
          1.3“奉化丨家门口”小程序不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该外部链接指向的内容，也不承担任何责任。<br />
          1.4“奉化丨家门口”小程序可能会调用第三方系统或通过第三方支持用户的使用或访问，“奉化丨家门口”不保证通过第三方提供服务及内容的合法性、安全性、准确性、有效性及其他不确定的风险，由此引发的任何争议及损害，“奉化丨家门口”不承担任何责。<br />
          1.5“奉化丨家门口”不保证加盟企业提供的服务一定能满足用户的要求和期望，也不保证服务不会中断，对服务的合法性、有效性、及时性、安全性、准确性都不作保证。加盟企业向用户提供的产品或者服务的质量问题本身及其引发的任何损失，“奉化丨家门口”无需承担任何责任。“奉化丨家门口”仅提供用户和加盟企业的交往交流等服务，不雇佣员工，也不承揽相关的服务工作，对加盟企业服务人员及用户双方过错不承担任何责任。<br />
          1.6“奉化丨家门口”小程序有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论其通知与否，“奉化丨家门口”对用户和任何第三人均无需承担任何责任。<br />
          1.7、如用户的行为使“奉化丨家门口”遭受损失
          (包括自身的直接经济损失、对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失)，用户应赔偿“奉化丨家门口”的上述全部损失。如用户的行为使“奉化丨家门口”遭受第三人主张权利，“奉化丨家门口”可在对第三人承担金钱给付等义务后就全部损失向用户追偿。
        </div>
        <div class="footer" @click="confirm">我知道了</div>
      </div>
    </div>
    <div class="section-refresh" v-if="isRefresh" @click="payResult">
      <span>点击查询订单状态，更新订单信息</span>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import moment from "moment";
import {
  addTrolleyURL,
  addOrderURL,
  userInfoURL,
  getGroupBuyingDetailsForMiniURL,
} from "./api";
import swiperPreview from "../components/swiper-preview.vue";

export default {
  components: { swiperPreview },
  data() {
    return {
      isShowDialog: false,
      id: "",
      buyType: 1, // 1:社区团购 2:限时秒杀
      currentIndex: 0, //当前提货方式
      //提货方式
      deliveryMethod: [
        {
          name: "提货",
          value: 1,
        },
        {
          name: "配送",
          value: 2,
        },
      ],
      step: 1,
      shippingAddress: "", //配送地址
      detail: {
        detailsUrl: "",
        goodsTitle: "",
        nowCost: "",
        goodsCount: "",
        goodsRemainCount: "",
        legalPersonName: "",
        legalShopName: "",
        mobileNum: "",
        getStartTime: "",
        getEndTime: "",
        getAddress: [
          // "金钟农贸123号摊",
          // "世纪联华超市",
          // "同泰药店",
          // "金钟广场",
          // "菜鸟驿站",
          // "金钟农贸123号摊1",
          // "世纪联华超市2",
          // "同泰药店3",
          // "金钟广场4",
          // "菜鸟驿站5",
          // "金钟农贸123号摊6",
          // "世纪联华超市7",
          // "同泰药店8",
          // "金钟广场9",
          // "菜鸟驿站10",
        ],
        status: "",
        content: "",
      },

      /** v-popup */
      showPopup: false,
      curAddress: "",
      /* 按钮点击 */
      // 立即支付按钮是否可点击
      isClick: true,
      /* 立即下单 */
      isRefresh: false, // 遮罩层，点击刷新数据
      outTradeNo: "", // 订单编号
      receivingWay: 1, // 收货方式
    };
  },
  filters: {
    formatTime(time) {
      return moment(time.replace(/-/g, "/")).format("YYYY-MM-DD HH:mm");
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId", "openId"]),
  },
  created() {
    this.initData();
  },
  methods: {
    toPhone(tel) {
      window.location.href = `tel:${tel}`;
    },
    //确认
    confirm() {
      this.isShowDialog = false;
      if (!this.isClick) {
        return;
      }
      if (this.receivingWay == 1) {
        if (!this.curAddress) {
          this.$toast({ message: "请选择提货点", duration: 2000 });
          return;
        }
      } else {
        if (!this.shippingAddress) {
          this.$toast({ message: "请输入配送地址", duration: 2000 });
          return;
        }
      }

      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
        openId: this.openId,
        // ownerId: this.detail.shopId,
        type: this.buyType, // 普通商品
        goodsId: this.id,
        orderGoodsNum: 1,
        price: this.detail.nowCost,
        receiverName: this.userInfo.userName,
        receiverPhone: this.userInfo.mobile,
        receivingWay: this.receivingWay,
        // skuId: this.curSku.id,
        getAddress: this.curAddress || this.shippingAddress,
      };
      this.isClick = false;
      this.$axios
        .post(addOrderURL, params)
        .then((res) => {
          if (res.code === 200) {
            const result = res.data;
            const path = {
              to: "",
              from: "/futureService/bcnWelfarOrderDetail",
            };
            // this.isClick = true;
            // this.showPopup = false;
            if (wx.miniProgram) {
              wx.miniProgram.navigateTo({
                animationDuration: 10,
                url: `/xubPages/payDetail/index?query=${encodeURIComponent(
                  JSON.stringify(this.$route.query)
                )}&params=${encodeURIComponent(
                  JSON.stringify(result)
                )}&path=${JSON.stringify(path)}`,
                complete: () => {
                  this.isClick = true;
                  this.showPopup = false;
                  this.payResultDialog(res.outTradeNo);
                },
              });
            }
          }
        })
        .finally(() => {
          this.isClick = true;
        });
    },
    //选择提货方式
    chooseType(item, index) {
      this.curAddress = "";
      this.shippingAddress = "";
      this.receivingWay = item.value;
      this.currentIndex = index;
    },
    //下一步
    nextStep() {
      this.currentIndex == 0 ? (this.step = 2) : (this.step = 3);
    },
    //初始化数据
    async initData() {
      const { id, type } = this.$route.query;
      this.id = id;
      this.buyType = type;
      this.getDetail();
      this.getUserInfo();
      // this.initWxConfig();
    },
    // 获取用户信息
    getUserInfo() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(userInfoURL, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
          }
        });
    },
    //获取详情数据
    getDetail() {
      this.$axios
        .get(getGroupBuyingDetailsForMiniURL, {
          params: {
            id: this.id,
            userId: this.userId,
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.detail = res.data;
            return;
          }
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        })
        .catch((res) => {
          this.$toast(res.msg || "服务器错误，请稍后重试！");
        });
    },
    // 添加购物车
    async addShopCart() {
      let res = await this.$axios.post(addTrolleyURL, {
        goodsId: this.id,
        goodsType: this.buyType,
        goodsNum: 1,
        insertUser: this.userId,
      });
      if (res.code == 200) {
        this.$toast({ message: "添加成功！", duration: 2000 });
      }
    },
    // 立即下单
    orderNow() {
      this.step = 1;
      this.showPopup = true;
    },
    // 改变地址
    changeAddress(o) {
      this.curAddress = o;
    },
    // 立即支付
    payNow() {
      this.isShowDialog = true;
    },
    // 查询结果弹框
    payResultDialog(outTradeNo) {
      this.outTradeNo = outTradeNo;
      setTimeout(() => {
        this.isRefresh = true;
      }, 500);
    },
    // 查询结果参数
    async payResult() {
      this.isRefresh = false;
      this.initData();
      // let res = await this.$axios.get(queryOrderURL, {
      //   params: {
      //     outTradeNo: this.outTradeNo,
      //     userId: this.userId,
      //   },
      // });
      // if (res.code == 200 && res.success) {
      //   this.isRefresh = false;
      //   this.initData();
      //   // this.$toast({ message: "支付成功！", duration: 2000 });
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.section {
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  font-family: PingFangSC-Medium, PingFang SC;
  display: flex;
  flex-direction: column;
  padding-bottom: calc(
    132px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    132px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-swiper {
    width: 100%;
    height: 400px;
  }
  .section-number {
    display: flex;
    width: 100%;
    .number-sell {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 20px 30px;
      // background: #c33030;
      .number-sell-price {
        flex: 1;
        font-size: 44px;
        font-weight: bold;
        color: #d9634d;
        line-height: 60px;
      }
      .number-sell-sold {
        white-space: nowrap;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
      }
    }
    .number-stock {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: -40px;
        top: 50%;
        transform: translateY(-50%);
        border-right: 20px solid #fef4d1;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid transparent;
      }
      padding: 30px 30px;
      font-size: 28px;
      font-weight: bold;
      color: #c33030;
      background: linear-gradient(315deg, #fde8a3 0%, #fef4d1 100%);
      span {
        font-size: 62px;
      }
    }
  }
  .section-name {
    padding: 36px 18px;
    font-size: 36px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 48px;
    background: #ffffff;
  }
  .section-sku {
    padding: 0px 24px 24px;
    font-size: 28px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 40px;
  }

  .section-groupman {
    box-sizing: border-box;
    width: 690px;
    margin: 0px 30px 0;
    padding: 36px 24px;
    font-size: 28px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 48px;
    background: #f9f9f9;
    border-radius: 10px;
    .groupman-flex {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      display: flex;
      .groupman-flex-label,
      .groupman-flex-value {
        display: inline-block;
      }
      .groupman-flex-img {
        width: 48px;
        height: 48px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .groupman-flex-label {
        white-space: nowrap;
        // width: 180px;
        // min-width: 180px;
      }
      .groupman-flex-value {
        flex: 1;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.5);
        font-weight: bold;
        white-space: normal;
        word-break: break-all;
        word-wrap: break-word;
      }
    }
  }
  // .section-detail {
  //   flex: 1;
  //   padding: 32px 30px;
  //   padding-bottom: calc(
  //     122px + constant(safe-area-inset-bottom)
  //   ); /* 兼容 iOS < 11.2 */
  //   padding-bottom: calc(
  //     122px + env(safe-area-inset-bottom)
  //   ); /* 兼容 iOS >= 11.2 */
  //   background: #ffffff;
  //   .detail-nav {
  //     display: flex;
  //     font-size: 36px;
  //     font-weight: bold;
  //     color: rgba(0, 0, 0, 0.85);
  //     line-height: 38px;
  //   }
  //   .detail-content {
  //     margin-top: 44px;
  //     font-size: 28px;
  //     font-weight: 400;
  //     color: rgba(0, 0, 0, 0.85);
  //     line-height: 40px;
  //     white-space: normal;
  //     word-break: break-all;
  //     word-wrap: break-word;
  //   }
  // }
  .section-footer {
    position: fixed;
    bottom: 32px;
    right: 30px;
    height: 70px;
    .footer-button {
      display: flex;
      height: 100%;
      font-size: 30px;
      font-weight: 500;
      color: #ffffff;
      border-radius: 16px;
      overflow: hidden;
      .cart,
      .order {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 210px;
      }
      .cart {
        background: #f29983;
      }
      .order {
        background: #db431d;
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    &.delivery {
      .content-select {
        display: flex;
        align-items: center;

        .select-option {
          flex: 1;
          justify-content: center;
          &.current {
            background-color: transparent !important;
          }
        }
      }
    }
    .popup-overlary {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    .popup-content {
      position: absolute;
      background: #fff;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      padding-bottom: calc(
        32px + constant(safe-area-inset-bottom)
      ); /* 兼容 iOS < 11.2 */
      padding-bottom: calc(
        32px + env(safe-area-inset-bottom)
      ); /* 兼容 iOS >= 11.2 */
      .content-title {
        padding: 40px 30px;
        font-size: 30px;
        font-weight: 400;
        color: #333333;
        line-height: 42px;
      }
      .content-select {
        max-height: 450px;
        overflow-y: auto;
        overflow-x: hidden;
        &.search-input {
          flex: 1;
          padding: 0 20px;
          ::v-deep .van-cell {
            padding: 0 36px;
            background: #f7f7f7;
            // border-radius: 30px;
            line-height: 84px;
          }
        }
        .select-option {
          display: flex;
          align-items: center;
          padding: 24px 30px;
          font-size: 30px;
          font-weight: 400;
          color: #333333;
          line-height: 42px;
          .icon {
            margin-right: 14px;
            box-sizing: border-box;
            width: 30px;
            height: 30px;
            border: 2px solid rgba(0, 0, 0, 0.25);
            border-radius: 50%;
          }
          &.current {
            background: #f9f9f9;
            .icon {
              border: 8px solid #db431d;
            }
          }
        }
      }
      .popup-footer {
        display: flex;
        justify-content: flex-end;
        .footer-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 210px;
          height: 70px;
          margin: 30px 30px 0 0;
          background: #db431d;
          border-radius: 16px;
          font-size: 30px;
          font-weight: 500;
          color: #ffffff;
        }
      }
    }
  }
  .dialog {
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .dialog-content {
      width: 100%;
      height: 80%;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      padding: 42px 34px 52px 34px;
      .title {
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.85);
        line-height: 42px;
        margin-bottom: 32px;
      }
      .text {
        flex: 1;
        overflow-y: scroll;
        font-size: 26px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 36px;
      }
      .footer {
        text-align: center;
        width: 550px;
        height: 70px;
        margin: 52px auto auto;
        line-height: 70px;
        background: #db431d;
        border-radius: 16px;
        font-size: 32px;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }

  .section-refresh {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #1989fa;
    font-weight: bold;
  }
}
</style>

<template>
  <div class="section">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <v-swipe-cell
        class="section-list"
        v-for="(item, index) in listData"
        :key="`${index}_${item.id}`"
      >
        <div class="list-item">
          <div class="item-picture">
            <img :src="item.imageUrl" alt="" />
          </div>
          <div class="item-flex">
            <div class="flex-title">
              <div class="title">{{ item.goodsTitle }}</div>
              <div class="status" :style="item.status | statusClass">
                {{ statusMap[item.status] }}
              </div>
            </div>
            <div class="flex-time">
              <span>结束时间：{{ item.buyEndTime }}</span>
            </div>
            <div class="flex-type">
              <span>团购类型：{{ buyTypeMap[item.buyType] }}</span>
            </div>
          </div>
        </div>
        <template #right>
          <div class="list-operate">
            <div class="btn view" @click="view(item)">查看</div>
            <div class="btn edit" v-if="item.status == 1" @click="edit(item)">
              编辑
            </div>
            <div
              class="btn del"
              v-if="item.status == 1 || item.status == 99"
              @click="remove(item)"
            >
              删除
            </div>
          </div>
        </template>
      </v-swipe-cell>
    </v-list>
    <div class="section-footer">
      <div class="footer-button" @click="toRouter">
        <span>发布</span>
      </div>
    </div>

    <v-dialog
      class="section-dialog"
      v-model="isDialog"
      title="提示"
      confirmButtonColor="#FDA02DFF"
      @confirm="removeSubmit"
    >
      <p>{{ dialogTxt }}</p>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { statusMap, buyTypeMap } from "./map";
import { myGroupBuyingListURL, delGroupInfoURL } from "./api";

export default {
  data() {
    return {
      statusMap,
      buyTypeMap,
      /* v-list */
      finishedText: "没有更多了",
      finished: false,
      listData: [
        // {
        //   imageUrl: "",
        //   goodsTitle: "妈妈壹选洗洁精",
        //   buyType: 1,
        //   buyEndTime: "2023-03-12 00:00",
        //   status: "1",
        // },
        // {
        //   imageUrl: "",
        //   goodsTitle:
        //     "妈妈壹选洗洁精妈妈壹选洗洁精妈妈壹选洗洁精妈妈壹选洗洁精妈妈壹选洗洁精",
        //   buyType: 1,
        //   buyEndTime: "2023-03-12 00:00",
        //   status: "2",
        // },
        // {
        //   imageUrl: "",
        //   goodsTitle:
        //     "妈妈壹选洗洁精妈妈壹选洗洁精妈妈壹选洗洁精妈妈壹选洗洁精妈妈壹选洗洁精",
        //   buyType: 1,
        //   buyEndTime: "2023-03-12 00:00",
        //   status: "3",
        // },
        // {
        //   imageUrl: "",
        //   goodsTitle:
        //     "妈妈壹选洗洁精妈妈壹选洗洁精妈妈壹选洗洁精妈妈壹选洗洁精妈妈壹选洗洁精",
        //   buyType: 1,
        //   buyEndTime: "2023-03-12 00:00",
        //   status: "99",
        // },
      ],
      curPage: 1,
      pageSize: 10,
      /** v-dialog */
      isDialog: false,
      dialogTxt: "",
    };
  },
  filters: {
    statusClass(status) {
      let className = {
        1: "color: #DB431D",
        2: "color: #F7B500",
        3: "color: #BABABA",
        99: "color: #000000",
      };
      return className[status];
    },
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  methods: {
    // 重置我的团购/秒杀列表
    onRefresh() {
      this.curPage = 1;
      this.onLoad();
    },
    // 获取我的团购/秒杀列表
    onLoad() {
      let params = {
        curPage: this.curPage,
        pageSize: this.pageSize,
        userId: this.userId,
        // tenantId: this.tenantId,
        // houseId: this.houseId || this.communityId || undefined,
      };
      this.$axios
        .get(myGroupBuyingListURL, {
          params,
        })
        .then((res) => {
          if (res.code != 200) {
            this.finished = true;
          } else {
            if (this.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.listData = [];
              this.finishedText = "";
              return;
            }
            if (res.code === 200 && res.data && res.data.records) {
              if (this.curPage === 1) {
                this.listData = res.data.records;
              } else {
                const list = this.listData.map((v) => v.id);
                res.data.records.forEach((item) => {
                  // 如果请求数据不重复，则添加进listData
                  list.includes(item.id) || this.listData.push(item);
                });
              }
              this.curPage++;
            }
          }
        });
    },
    toDetail(item) {
      this.$router.push({
        name: "bcnWelfarOrderDetail",
        query: {
          id: item.id,
        },
      });
    },
    view(item) {
      this.$router.push({
        name: "bcnWelfarePublishForm",
        query: {
          id: item.id,
          isView: 1,
        },
      });
    },
    edit(item) {
      this.$router.push({
        name: "bcnWelfarePublishForm",
        query: {
          id: item.id,
        },
      });
    },
    remove(item) {
      this.curItem = item;
      this.dialogTxt = "是否确认删除？";
      this.isDialog = true;
    },
    // 删除
    removeSubmit() {
      let params = {
        id: this.curItem.id,
        optUserId: this.userId,
      };
      this.$axios.post(delGroupInfoURL, null, { params }).then((res) => {
        if (res.code === 200) {
          this.isDialog = false;
          // this.$toast({
          //   message: "删除成功",
          //   duration: 1500,
          // });
          // setTimeout(() => {
          //   this.onRefresh();
          // }, 1500);
          this.onRefresh();
        }
      });
    },
    // 发布团购/秒杀
    toRouter() {
      this.$router.push({
        name: "bcnWelfarePublishForm",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.section {
  font-family: PingFangSC-Semibold, PingFang SC;
  padding-bottom: calc(
    122px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(
    122px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-list {
    .list-item {
      display: flex;
      align-items: cemter;
      padding: 28px 30px;
      border-bottom: 2px solid #f6f6f6;
      .item-picture {
        width: 150px;
        min-width: 150px;
        height: 150px;
        margin-right: 20px;
        border-radius: 16px;
        overflow: hidden;
        > img {
          &:extend(.common-img);
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .item-flex {
        flex: 1;
        max-width: calc(100% - 170px);
        .flex-title {
          display: flex;
          align-items: center;
          white-space: nowrap;
          .title {
            flex: 1;
            margin-right: 20px;
            font-size: 32px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            line-height: 44px;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          .status {
            font-size: 26px;
            font-weight: 400;
            color: #f7b500;
            line-height: 36px;
          }
        }
        .flex-time {
          margin-top: 8px;
          font-size: 26px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 36px;
        }
        .flex-type {
          margin-top: 18px;
          font-size: 26px;
          font-weight: 400;
          color: #bababa;
          line-height: 36px;
        }
      }
    }
    .list-operate {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 32px;
        font-size: 28px;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 4px;
        &.view {
          background: #3781ff;
        }
        &.edit {
          background: #f6bb1b;
        }
        &.del {
          background: #d25f5f;
        }
      }
    }
  }

  .section-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: calc(
      32px + constant(safe-area-inset-bottom)
    ); /* 兼容 iOS < 11.2 */
    padding-bottom: calc(
      32px + env(safe-area-inset-bottom)
    ); /* 兼容 iOS >= 11.2 */
    .footer-button {
      width: 550px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #db431d;
      border-radius: 16px;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .section-dialog {
    ::v-deep .van-dialog {
      .van-dialog__header {
        font-weight: bold;
      }
      p {
        margin: 34px 0 74px;
        text-align: center;
      }
    }
  }
}
</style>

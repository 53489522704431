<template>
  <div class="smartParking">
    <div class="map" :id="id"></div>
  </div>
</template>

<script>
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  components: {},
  data() {
    return {
      id: `container_${new Date().getTime()}`,
      map: null,
      polygonList: [
        // 多边形覆盖物点位对象
        {
          path: [
            // ["121.426778", "29.677362"],
            // ["121.427248", "29.677311"],
            // ["121.427203", "29.676938"],
            // ["121.427165", "29.676939"],
            // ["121.426736", "29.677008"],
            // ["121.426756", "29.677131"],
            // ["121.427108", "29.677072"],
            // ["121.427114", "29.677194"],
            // ["121.426763", "29.677237"],
            ["121.428783", "29.680663"],
            ["121.428631", "29.680596"],
            ["121.428469", "29.680509"],
            ["121.428355", "29.680449"],
            ["121.427682", "29.680048"],
            ["121.426958", "29.679607"],
            ["121.426951", "29.679571"],
            ["121.427641", "29.678669"],
            ["121.427658", "29.678621"],
            ["121.427717", "29.678382"],
            ["121.42772", "29.678268"],
            ["121.427458", "29.677045"],
            ["121.427294", "29.676205"],
            ["121.427435", "29.67619"],
            ["121.427843", "29.678236"],
            ["121.427829", "29.678412"],
            ["121.427733", "29.67869"],
            ["121.427049", "29.679572"],
            ["121.428816", "29.680613"],
          ],
        },
      ],
      polygons: [], // 多边形polygon对象
      textList: [
        // 文字点位对象
        {
          text: "停车区域",
          position: [121.427177, 29.679323],
        },
      ],
      texts: [],
      pointList: [
        {
          name: "停车场(金钟小区南)",
          des: "宁波市奉化区斗门北路209-215附近",
          // position: [29.676973, 121.427184],
          position: [121.427184, 29.676973],
        },
        // {
        //   name: "停车场(金钟小区南)",
        //   des: "宁波市奉化区斗门北路金钟小区",
        //   // position: [29.67744, 121.427382],
        //   position: [121.427382, 29.67744],
        // },
        // {
        //   name: "停车场(斗门北路)",
        //   des: "宁波市奉化区斗门北路",
        //   // position: [29.679101, 121.427139],
        //   position: [121.427139, 29.679101],
        // },
        // {
        //   name: "地面停车场",
        //   des: "",
        //   // position: [29.676765, 121.427273],
        //   position: [121.427273, 29.676765],
        // },
        // {
        //   name: "桥东岸路(金钟小区西)路侧停车点",
        //   des: "浙江省宁波市奉化区桥东岸路(金钟小区西)",
        //   // position: [29.677416, 121.424433],
        //   position: [121.424433, 29.677416],
        // },
      ],
      markers: [],
    };
  },
  computed: {},
  mounted() {
    gloabalCount("", 89, 1);
    this.initMap();
  },
  methods: {
    initMap() {
      if (this.map) {
        this.map.destroy();
      }
      console.log(window.AMap);
      if (window.AMap) {
        this.map = new window.AMap.Map(this.id, {
          zoom: "17",
          center: [121.427727, 29.678123],
        });
        // this.pointList.forEach((data, index) => {
        //   this.addMarker(data, index);
        // });

        this.textList.forEach((data, index) => {
          this.addText(data, index);
        });

        this.polygonList.forEach((data, index) => {
          this.addPolygon(data, index);
        });
      }
    },
    addMarker(marker, index) {
      if (window.AMap) {
        const _this = this;
        const icon = new window.AMap.Icon({
          size: new window.AMap.Size(22, 25),
          image: require("./img/place_red.png"),
          imageSize: new window.AMap.Size(22, 26),
        });
        _this.markers[index] = new window.AMap.Marker({
          icon,
          // position: this.bMap2QQMap(marker.position),
          position: marker.position,
          offset: new window.AMap.Pixel(-10, -20),
        });
        _this.markers[index].on("click", () => {
          this.markers.forEach((item) => {
            item.setContent("");
            item.setIcon(icon);
          });
          // _this.markers[index].setContent(_this.createInfoWindow(marker));
        });
        _this.map.add(_this.markers[index]);
      }
    },
    // // 构建自定义信息窗体-地图
    // createInfoWindow(data) {
    //   // const that = this;
    //   let info = document.createElement("div");
    //   // info.innerHTML = `
    //   //   <img class="point" src="${require("./img/place_green.png")}" alt=""/>
    //   //   <div class="point-content" ondblclick="_vue_toRouter(${data.id})">
    //   //     <div class="text-left">${data.label}</div>
    //   //     <div class="icon-right" onclick="_vue_toRouter(${data.id})">
    //   //       <img src="${require("./img/icon_info.png")}" alt=""/>
    //   //     </div>
    //   //   </div>
    //   //   <div class="triangle"></div>
    //   // `;
    //   // info.className = "point";
    //   return info;
    // },
    addText(text, index) {
      this.texts[index] = new window.AMap.Text({
        text: text.text,
        position: text.position,
      });
      this.texts[index].setStyle({
        fontSize: "12px",
        color: "rgba(0,0,0, 0.5)",
        background: "transparent",
        border: "none",
        // textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2)",
      });
      this.map.add(this.texts[index]);
    },
    addPolygon(polygon, index) {
      this.polygons[index] = new window.AMap.Polygon({
        path: polygon.path,
        // 121.426779,29.677365
        // fillColor: "#00b0ff",
        // strokeColor: "80d8ff",
        fillOpacity: 0.35, //填充透明度
        fillColor: "#00b0ff",
        strokeOpacity: 1, //线透明度
        strokeColor: "#80d8ff",
        // strokeColor: "#00b0ff",
      });
      // this.polygons[index].on("click", () => {
      //   alert(1);
      // });
      this.map.add(this.polygons[index]);
    },
  },
};
</script>
<style lang="less" scoped>
.smartParking {
  width: 100%;
  height: 100%;
  .map {
    height: 100%;
    width: 100%;
  }
}
</style>

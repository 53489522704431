import { render, staticRenderFns } from "./shippingDetails.vue?vue&type=template&id=175d38c9&scoped=true"
import script from "./shippingDetails.vue?vue&type=script&lang=js"
export * from "./shippingDetails.vue?vue&type=script&lang=js"
import style0 from "./shippingDetails.vue?vue&type=style&index=0&id=175d38c9&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "175d38c9",
  null
  
)

export default component.exports
<template>
  <div class="section">
    <div class="section-swiper">
      <swiper-preview
        :swiperData="detail.pictures ? detail.pictures.split(',') : []"
      />
    </div>
    <div class="section-main">
      <div class="main-title">{{ detail.title }}</div>
      <div class="main-price">
        <span>{{ detail.price }}</span
        >元
      </div>
      <!-- <div class="main-select">
        <span>选择规格/数量</span>
        <img src="../img/arrow_right_gray.png" alt="" />
      </div> -->
    </div>
    <div class="section-detail">
      <div class="detail-nav">
        <div class="common-title"><span>产品详情</span></div>
      </div>
      <!-- <div class="detail-content">{{ detail.detailContent || "暂无" }}</div> -->
      <div class="detail-content">
        <v-h5Mtml :content="detail.detailContent || '暂无'"></v-h5Mtml>
      </div>
    </div>
    <div class="btn" @click="clickBtn">立即下单</div>

    <div class="popup" v-if="showPopup">
      <div class="popup-overlary" @click="showPopup = false"></div>
      <div class="popup-content">
        <div class="content-detail">
          <div class="detail-picture">
            <img :src="curSku.skuImageUrl" alt="" />
          </div>
          <div class="detail-flex">
            <div class="detail-flex-title">
              <span>{{ detail.title }}</span>
            </div>
            <div class="detail-flex-price">
              <span
                >{{ curSku.price }}元（库存：{{
                  curSku.stock === "" ? "无限量" : curSku.stock
                }}）</span
              >
            </div>
          </div>
        </div>
        <div class="content-size">
          <div
            :class="['size-item', item.id === curSku.id ? 'size-select' : '']"
            v-for="(item, index) in detail.skuList"
            :key="index"
            @click="setCurSku(item)"
          >
            <span>{{ item.skuName }}</span>
          </div>
        </div>
        <div class="content-num">
          <v-stepper
            v-model="count"
            :min="1"
            :max="curSku.stock === '' ? Infinity : curSku.stock"
          />
        </div>
        <div class="content-footer" @click="submit">
          <span>下单</span>
        </div>
      </div>
    </div>

    <div class="section-refresh" v-if="isRefresh" @click="payResult">
      <span>点击查询订单状态，更新订单信息</span>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { mapState } from "vuex";
import { getGoodsDetailURL, userInfoURL, addOrderURL } from "./api.js";
// import { toRegister } from "@/utils/common.js";
import swiperPreview from "../components/swiper-preview.vue";

export default {
  components: { swiperPreview },
  data() {
    return {
      id: "",
      showPopup: false,
      detail: {
        // title: "",
        // price: "",
        // pictures: "",
        // detailContent: "",
        // skuList: [],
      },
      curSku: {
        stock: "",
        skuName: "",
        skuImageUrl: "",
        id: "",
      },
      userInfo: {},
      count: 1, // 商品购买数量，最小为 1
      /* 立即下单 */
      isRefresh: false, // 遮罩层，点击刷新数据
      outTradeNo: "", // 订单编号
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId", "openId"]),
  },
  // created() {
  //   const { id } = this.$route.query;
  //   this.id = id;
  // },
  // mounted() {
  //   this.getUserInfo();
  //   this.getGoodsDetail();
  // },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      const { id } = this.$route.query;
      this.id = id;
      this.getUserInfo();
      this.getGoodsDetail();
    },
    // 获取用户信息
    getUserInfo() {
      let params = {
        userId: this.userId,
      };
      this.$axios
        .get(userInfoURL, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.userInfo = res.data;
          }
        });
    },
    getGoodsDetail() {
      let params = {
        id: this.id,
      };
      this.$axios.get(`${getGoodsDetailURL}`, { params }).then((res) => {
        if (res.code === 200 && res.success) {
          this.detail = res.data;
          // 默认选中第一个规格
          this.curSku =
            res.data.skuList && res.data.skuList.length > 0
              ? res.data.skuList[0]
              : this.curSku;
        }
      });
    },
    // 打开立即下单弹框
    clickBtn() {
      this.showPopup = true;
    },
    // 选择规格
    setCurSku(item) {
      this.count = 1;
      this.curSku = item;
    },
    // 提交订单
    submit() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
        openId: this.openId,
        type: 4,
        orderGoodsNum: this.count,
        price: this.detail.price,
        ownerId: this.detail.shopId,
        goodsId: this.id,
        receiverName: this.userInfo.userName,
        receiverPhone: this.userInfo.mobile,
        skuId: this.curSku.id,
      };
      this.$axios
        .post(`${addOrderURL}`, params)
        .then((res) => {
          if (res.code === 200) {
            const result = res.data;
            const path = {
              to: "/futureService/bcnServiceGoodsOrderFinished",
              from: "/futureService/bcnWelfarOrderDetail",
            };
            // this.isClick = true;
            // this.showPopup = false;
            if (wx.miniProgram) {
              wx.miniProgram.navigateTo({
                animationDuration: 10,
                url: `/xubPages/payDetail/index?query=${encodeURIComponent(
                  JSON.stringify(this.$route.query)
                )}&params=${encodeURIComponent(
                  JSON.stringify(result)
                )}&path=${JSON.stringify(path)}`,
                complete: () => {
                  this.isClick = true;
                  this.showPopup = false;
                  this.payResultDialog(res.outTradeNo);
                },
              });
            }
          }
        })
        .finally(() => {
          this.isClick = true;
        });

      // this.$router.replace({
      //   name: "bcnServiceGoodsOrderFinished",
      //   query: {
      //     // id: res.data.id,
      //   },
      // });
      //   }
      // });
    },
    // 查询结果弹框
    payResultDialog(outTradeNo) {
      this.outTradeNo = outTradeNo;
      setTimeout(() => {
        this.isRefresh = true;
      }, 500);
    },
    // 查询结果参数
    async payResult() {
      this.isRefresh = false;
      this.initData();
      // let res = await this.$axios.get(queryOrderURL, {
      //   params: {
      //     outTradeNo: this.outTradeNo,
      //     userId: this.userId,
      //   },
      // });
      // if (res.code == 200 && res.success) {
      //   this.isRefresh = false;
      //   this.initData();
      //   // this.$toast({ message: "支付成功！", duration: 2000 });
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.common-img {
  line-height: unset;
  vertical-align: middle;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.common-title {
  span {
    display: block;
    padding: 0 22px;
    font-size: 36px;
    font-weight: bold;
    color: #1a1c34;
    line-height: 50px;
  }
  &::after {
    content: "";
    margin-top: -14px;
    display: block;
    width: 100%;
    height: 14px;
    background: #e2e2e2;
    border-radius: 7px;
  }
}
.section {
  font-family: PingFangSC-Regular, PingFang SC;
  padding-bottom: calc(
    90px + constant(safe-area-inset-bottom)
  ); /* 兼容 iOS  >= 11.2 */
  padding-bottom: calc(
    90px + env(safe-area-inset-bottom)
  ); /* 兼容 iOS >= 11.2 */
  .section-swiper {
    height: 400px;
    width: 100%;
  }
  .section-main {
    padding: 32px 0 62px;
    margin: 0 46px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    .main-title {
      font-size: 32px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
    }
    .main-price {
      margin-top: 8px;
      font-size: 32px;
      font-weight: 400;
      color: #fe9616;
      line-height: 44px;
    }
    .main-select {
      margin-top: 48px;
      height: 82px;
      padding: 0 24px 0;
      background: #f7f7f7;
      border-radius: 6px;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.5);
      line-height: 40px;
      display: flex;
      align-items: center;
      span {
        flex: 1;
      }
      > img {
        &:extend(.common-img);
        width: 16px;
        height: 28px;
      }
    }
  }
  .section-detail {
    padding: 100px 46px 32px;

    .detail-nav {
      display: flex;
    }
    .detail-content {
      margin-top: 44px;
      font-size: 28px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 40px;
      white-space: normal;
      word-break: break-all;
      word-wrap: break-word;
    }
  }

  .btn {
    box-sizing: border-box;
    width: 550px;
    height: 60px;
    border-radius: 10px;
    line-height: 60px;
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }

  .popup {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .popup-overlary {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    .popup-content {
      position: absolute;
      background: #fff;
      padding: 40px 32px;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 16px 16px 0 0;
      padding-bottom: calc(
        32px + constant(safe-area-inset-bottom)
      ); /* 兼容 iOS < 11.2 */
      padding-bottom: calc(
        32px + env(safe-area-inset-bottom)
      ); /* 兼容 iOS >= 11.2 */
      .content-detail {
        display: flex;
        align-items: center;
        .detail-picture {
          width: 174px;
          min-width: 174px;
          height: 174px;
          margin-right: 26px;
          img {
            &:extend(.common-img);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .detail-flex {
          flex: 1;
          font-size: 32px;
          font-weight: 400;
          line-height: 44px;
          .detail-flex-title {
            white-space: normal;
            word-break: break-all;
            word-wrap: break-word;
            color: rgba(0, 0, 0, 0.85);
            margin-bottom: 40px;
          }
          .detail-flex-price {
            color: #fe9616;
          }
        }
      }
      .content-size {
        margin: 30px 0 54px;
        .size-item {
          margin: 0 20px 10px 0;
          &:last-child {
            margin-right: 0px;
          }
          display: inline-block;
          box-sizing: border-box;
          font-size: 26px;
          font-weight: 400;
          line-height: 32px;
          padding: 6px 12px;
          border-radius: 4px;
          color: rgba(0, 0, 0, 0.5);
          border: 2px solid rgba(0, 0, 0, 0.5);
          &.size-select {
            color: #fe9616;
            border: 2px solid #fe9616;
          }
        }
      }
      .content-footer {
        margin: 84px auto 0;
        width: 550px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #db431d;
        border-radius: 16px;
        font-size: 30px;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 4px;
      }
    }
  }

  .section-refresh {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    color: #1989fa;
    font-weight: bold;
  }
}
</style>
